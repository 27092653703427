import { Grid, TextField } from '@mui/material'
import { useEffect } from 'react'
import { saveCustomers, saveProducts } from '../../redux/actions/home.actions'
import { ICustomer, IInvoice } from '../../types/types'
import { useDispatch } from 'react-redux'
import DateField from '../date-field'
import SelectorMui from '../selector-mui'
import useAuthorization from '../../helpers/useAuthorization'

interface ICustomerSectionProps {
  customerItems: any
  currentPublicId: string
  handleChangeFields: (e: any, value: string, isDate?: boolean) => void
  setIsNewCustomerDialogOpen: (isNewCustomer: boolean) => void
  newEstimate?: any
  newInvoice?: IInvoice
  chosenCustomer: ICustomer | null
  publicId: string | undefined
  isInvoice?: boolean
}

const CustomerSection = (props: ICustomerSectionProps) => {
  const {
    customerItems,
    currentPublicId,
    handleChangeFields,
    setIsNewCustomerDialogOpen,
    newEstimate,
    newInvoice,
    publicId,
    isInvoice,
  } = props

  const dispatch = useDispatch()
  const { isAuthorized } = useAuthorization()
  const isRecurringInvoice = window.location.href.includes('recurring-invoices')

  useEffect(() => {
    dispatch(saveProducts())
    dispatch(saveCustomers())
  }, [])

  return (
    (newInvoice || newEstimate) && (
      <Grid flexDirection='row' spacing={2} container paddingX='40px'>
        <Grid xs={12} md={6} container item>
          <Grid container flexDirection='column' spacing={2}>
            <Grid item>
              <SelectorMui
                isAddButton={isAuthorized('write_customer')}
                addButtonText='Add new customer'
                options={customerItems}
                value={currentPublicId}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeFields(e, 'customerPublicId')
                }
                onAddIconClick={() => setIsNewCustomerDialogOpen(true)}
                placeholder={isInvoice ? 'Bill to' : 'Estimate To'}
                label={isInvoice ? 'Bill to' : 'Estimate To'}
                name='estimate-manage-customer-selector'
                fullWidth
              />
            </Grid>
            {publicId && (
              <div style={{ paddingLeft: '18px', paddingTop: '18px' }}>
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Estimate ID'
                  placeholder='Enter ID number'
                  disabled
                  value={isInvoice ? newInvoice?.name : newEstimate?.name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeFields(e, 'name')
                  }
                />
              </div>
            )}
            <Grid item>
              <TextField
                InputProps={{
                  sx: {
                    '& fieldset': {
                      borderColor: 'var(--secondary-grey)',
                    },
                  },
                }}
                fullWidth
                label='P.O/S.O number'
                placeholder='Enter number'
                value={isInvoice ? newInvoice?.poso : newEstimate?.poso}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeFields(e, 'poso')}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </Grid>
        {!isRecurringInvoice && (
          <Grid xs={12} md={6} container item>
            <Grid container flexDirection='column' spacing={2}>
              <Grid item>
                <DateField
                  fullWidth
                  label={isInvoice ? 'Invoice date' : 'Estimate Date'}
                  value={isInvoice ? newInvoice?.periodStart : newEstimate?.periodStart}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeFields(e, 'periodStart', true)
                  }
                />
              </Grid>
              <Grid item>
                <DateField
                  startDate={isInvoice ? newInvoice?.periodStart : newEstimate?.periodStart}
                  minDate={isInvoice ? newInvoice?.periodStart : newEstimate?.periodStart}
                  endDate={isInvoice ? newInvoice?.periodEnd : newEstimate?.periodEnd}
                  fullWidth
                  label={isInvoice ? 'Payment due' : 'Expiration Date'}
                  value={isInvoice ? newInvoice?.periodEnd : newEstimate?.periodEnd}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeFields(e, 'periodEnd', true)
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    )
  )
}

export default CustomerSection
