import { Chip, Table, TableBody, TableFooter, TableHead, TableRow, Typography } from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/styles'
import { useSelector } from 'react-redux'
import { AppState } from '../../redux/store'
import { formatDate, formatMoney } from '../../helpers/formatPrice'
import { IOustandingInvoice, IPaymentRecord } from '../../types/types'
import { getDayOrDays } from '../../helpers/utils'

const OutstandingStatementTable = () => {
  const outstandingStatements = useSelector(
    (state: AppState) => state.homeReducer.outstandingStatements,
  )

  const TableHeadTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'var(--background-color)',
      fontWeight: 'bold',
    },
    [`&.${tableCellClasses.footer}`]: {
      backgroundColor: 'var(--background-color)',
      fontWeight: 'bold',
      fontSize: 16,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const getPaymentDate = (invoice: IOustandingInvoice) => {
    if (invoice && invoice.status === 'PARTIAL' && invoice.paymentRecords.length) {
      return invoice.paymentRecords.map((paymentRecord: IPaymentRecord) =>
        formatDate(paymentRecord.date),
      )
    }

    if (invoice && invoice.status === 'SENT') {
      return `sent ${
        invoice.sentTime ? invoice.sentTime + ` ${getDayOrDays(invoice.sentTime)} ago` : 'today'
      }`
    }

    if (invoice && invoice.status === 'OVERDUE') {
      return `${
        invoice.overdueTime
          ? invoice.overdueTime + ` ${getDayOrDays(invoice.overdueTime)} overdue`
          : 'overdue today'
      }`
    }
  }

  const getColorByStatus = (status: string) => {
    switch (status) {
      case 'PARTIAL':
        return 'warning'
      case 'SENT':
        return 'info'
      case 'PAID':
        return 'success'
      case 'OVERDUE':
        return 'error'
      case 'REFUND':
        return 'secondary'
      case 'DRAFT':
      default:
        return
    }
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeadTableCell>Status</TableHeadTableCell>
          <TableHeadTableCell>Invoice ID</TableHeadTableCell>
          <TableHeadTableCell>Amount</TableHeadTableCell>
          <TableHeadTableCell>Created</TableHeadTableCell>
          <TableHeadTableCell>Payment date</TableHeadTableCell>
        </TableRow>
      </TableHead>
      <TableBody
        sx={{
          '& .MuiChip-colorSuccess': {
            backgroundColor: 'var(--success-bg-color)',
            color: 'var(--success-color)',
          },
          '& .MuiChip-colorWarning': {
            backgroundColor: 'var(--warning-bg-color)',
            color: 'var(--warning-color)',
          },
          '& .MuiChip-colorError': {
            backgroundColor: 'var(--error-bg-color)',
            color: 'var(--error-color)',
          },
          '& .MuiChip-colorInfo': {
            backgroundColor: 'var(--info-bg-color)',
            color: 'var(--info-color)',
          },
          '& .MuiChip-colorSecondary': {
            backgroundColor: 'var(--orange-bg-color)',
            color: 'var(--orange-color)',
          },
        }}
      >
        {outstandingStatements &&
          outstandingStatements.invoiceList.map((item) => {
            return (
              <TableRow key={item.publicId}>
                <TableCell>
                  <Chip label={item.status} color={getColorByStatus(item.status)} />
                </TableCell>
                <TableCell># {item.name}</TableCell>
                <TableCell>{formatMoney(item.total)}</TableCell>
                <TableCell>{formatDate(item.periodStart)}</TableCell>
                <TableCell>
                  <Typography>{formatDate(item.periodEnd)}</Typography>
                  <Typography color={item.status === 'OVERDUE' ? 'red' : undefined}>
                    {getPaymentDate(item)}
                  </Typography>
                </TableCell>
              </TableRow>
            )
          })}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableHeadTableCell colSpan={4} sx={{ textAlign: 'right' }}>
            OUTSTANDING BALANCE
          </TableHeadTableCell>
          <TableHeadTableCell>
            {formatMoney(outstandingStatements?.outstandingBalance)}
          </TableHeadTableCell>
        </TableRow>
      </TableFooter>
    </Table>
  )
}

export default OutstandingStatementTable
