import { Button, Grid } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../api/api';
import BaseModal from '../../components/modal/modal';
import { errorHandler } from '../../helpers/errorHandler';
import { formatPrice } from '../../helpers/formatPrice';
import { isUsingMobile } from '../../helpers/utils';
import {
	createNewProduct,
	saveProducts
} from '../../redux/actions/home.actions';
import { IProduct } from '../../types/types';
import ProductView from '../products/productView';
import './product-manage-dialog.css';

interface IProductAddDialog {
	open: boolean;
	product?: IProduct;
	onClose: () => void;
}

interface IFooter {
	isDisabledSaveButton: boolean;
	product?: IProduct;
	handleAcceptClick: () => void;
	handleCancel: () => void;
}

const Footer = ({ handleCancel, isDisabledSaveButton, handleAcceptClick }:IFooter) => {
	return (
		<Grid container>
			<Grid
				container
				justifyContent={{ xs: 'normal', md: 'right' }}
				alignItems={{ xs: 'normal', md: 'center' }}
				flexDirection={{ xs: 'column-reverse', md: 'row' }}
			>
				<Grid item padding={{ xs: '1rem', md: '0rem' }}>
					<Button
						color="primary"
						variant="outlined"
						onClick={handleCancel}
						fullWidth
					>
						Cancel
					</Button>
				</Grid>
				<Grid item padding={{ xs: '1rem 1rem 0 1rem', md: '1rem' }}>
					<Button
						disabled={isDisabledSaveButton}
						color="primary"
						variant="contained"
						onClick={handleAcceptClick}
						fullWidth
					>
						Add new product
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
};

const ProductManageDialog = (props: IProductAddDialog) => {
	const dispatch = useDispatch();
	const { open, product, onClose } = props;
	const [currentProduct, setCurrentProduct] = useState<IProduct>({
		name: '',
		price: '',
		description: '',
	});
		useState<boolean>(false);
	const [formErrors, setFormErrors] = useState({
		name: '',
		price: '',
	});

	const isDisabledSaveButton = useMemo(() => {
		return (
			Object.values(formErrors).some((error) => error !== '') ||
			!currentProduct.name ||
			!currentProduct.price
		);
	}, [formErrors, currentProduct.name, currentProduct.price]);

	const resetErrors = () => {
		setFormErrors({ name: '', price: '' });
	};

	const handleCancel = () => {
		resetErrors();
		onClose();
	};

	useEffect(() => {
		if (product) {
			setCurrentProduct({
				...product,
				name: product.name,
				price: product.price,
				description: product.description,
			});
		} else {
			setCurrentProduct({
				name: '',
				price: '',
				description: '',
			});
		}
	}, [open]);

	const handleAcceptClick = () => {
		if (product) {
			api
				.editProduct(
					{ ...currentProduct, price: formatPrice(currentProduct.price) },
					product.publicId
				)
				.then(() => {
					handleCancel()
					toast.success(`${product.name} was successfully edited`, {
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: true,
						draggable: true,
						progress: undefined,
					});
					dispatch(saveProducts());
				})
				.catch((err) => errorHandler(err));
		} else {
			dispatch(
				createNewProduct({
					...currentProduct,
					price: formatPrice(currentProduct.price),
				})
			);
			handleCancel()
		}
	};

	return (
		<BaseModal
			size={isUsingMobile() ? "xs" : "small"}
			title={'Add a product or service'}
			body={
				<ProductView
					currentProduct={currentProduct}
					setCurrentProduct={setCurrentProduct}
					setFormErrors={setFormErrors}
					formErrors={formErrors}
				/>
			}
			isModalOpen={open}
			handleCloseModal={handleCancel}
			footer={<Footer isDisabledSaveButton={isDisabledSaveButton} handleCancel={handleCancel} handleAcceptClick={handleAcceptClick}/>}
		/>
	);
};

export default ProductManageDialog;
