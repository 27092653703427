import { Box, Button, Typography } from '@mui/material'
import jwt from 'jwt-decode'
import { noop } from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AddBusiness from '../../assets/icons/add-business.png'
import GreenCheck from '../../assets/icons/green-check2.png'
import Subscribe from '../../assets/icons/suscribe.png'
import Verify from '../../assets/icons/verify.png'
import { isUsingMobile } from '../../helpers/utils'
import SubscribeDialog from '../../pages/subscribe-dialog'
import { changeIsOnboarding, saveUser } from '../../redux/actions/auth.actions'
import { AppState } from '../../redux/store'
import BusinessInfoManage from '../profile/business-info-manage'
import './onboarding.css'

const Onboarding = (props: any) => {
  const {
    showBusinessOnBoarding,
    setShowBusinessOnBoarding,
    isFullMenu,
    isDrawerOpen,
    drawerWidth,
  } = props
  const haveSubscription = useSelector((state: AppState) => state.authReducer.haveSubscription)
  const companyDetails = useSelector((state: AppState) => state.homeReducer.company)
  const cantSteps = 2
  const [stepsLeft, setStepsLeft] = useState(cantSteps)
  const isCompleteBusiness = useSelector((state: AppState) => state.authReducer.isCompleteBusiness)
  const [openSubscribeDialog, setIsSubscribeDialogOpen] = useState(false)
  const authReducer = useSelector((state: AppState) => state.authReducer)
  const dispatch = useDispatch()
  const stepsText = isCompleteBusiness && haveSubscription ? '!All done' : ' steps left'

  useEffect(() => {
    if ((haveSubscription || companyDetails?.isRoot) && isCompleteBusiness) {
      const timeoutId = setTimeout(() => {
        dispatch(changeIsOnboarding(false))
      }, 1500)

      // Clear the timeout if the component unmounts or the conditions change
      return () => clearTimeout(timeoutId)
    }
  }, [haveSubscription, isCompleteBusiness, dispatch])

  useEffect(() => {
    const decoded = authReducer.accessToken && jwt(authReducer.accessToken)
    dispatch(saveUser(decoded))
  }, [])

  useEffect(() => {
    if (isCompleteBusiness) {
      setStepsLeft((prevStepsLeft) => prevStepsLeft - 1)
    }
  }, [isCompleteBusiness])

  useEffect(() => {
    if (haveSubscription) {
      setStepsLeft((prevStepsLeft) => prevStepsLeft - 1)
    }
  }, [haveSubscription])

  const addBusiness = () => {
    setShowBusinessOnBoarding(true)
  }

  const getMarginLeft = () => {
    if (isFullMenu && !isMobile) {
      return `${drawerWidth}px`
    }

    if (isDrawerOpen && !isFullMenu) {
      return '64px'
    }

    if (!isMobile) {
      return '64px'
    }

    return '0'
  }

  const getWidth = () => {
    if (isFullMenu && !isMobile) {
      return `calc(100% - ${drawerWidth}px)`
    }

    if (isDrawerOpen && !isFullMenu) {
      return `calc(100% - 64px)`
    }

    if (!isMobile) {
      return `calc(100% - 64px)`
    }

    return undefined
  }

  const addSubscription = () => setIsSubscribeDialogOpen(true)

  const handleDialogClose = () => setIsSubscribeDialogOpen(false)
  const isMobile = isUsingMobile()
  return (
    <>
      {!showBusinessOnBoarding ? (
        <Box width={getWidth()} sx={{ margin: '50px auto 0 auto', marginLeft: getMarginLeft() }}>
          <div className='default-statement__wrapper statement__wrapper'>
            <div className='statement__padding'>
              <div className='default-statement__block'>
                <div className='default-statement__h4'>Getting Started</div>
                <div
                  className='default-statement__text 
              default-statement__block-gap'
                >
                  <Box display='flex'>
                    <Typography mr='8px'>{stepsLeft !== 0 ? stepsLeft : ''}</Typography>
                    <Typography>{stepsText}</Typography>
                  </Box>
                </div>
              </div>
              <div className='divider default-statement__divider'></div>
              <div className='steps-container'>
                <img className='image-icon' src={Verify} />
                <div className='title-container'>
                  <Box display='flex'>
                    <div className='title'>Verify your account</div>
                    {isMobile && (
                      <img
                        style={
                          isMobile
                            ? { width: '30px', height: '30px', marginLeft: '20px' }
                            : { width: '50px', height: '50px', marginLeft: 'auto' }
                        }
                        src={GreenCheck}
                      />
                    )}
                  </Box>
                  <div className='subtitle'>Check the code in your emails</div>
                </div>
                {!isMobile && (
                  <img
                    style={
                      isMobile
                        ? { width: '30px', height: '30px', marginLeft: '20px' }
                        : { width: '50px', height: '50px', marginLeft: 'auto' }
                    }
                    src={GreenCheck}
                  />
                )}
              </div>

              {isMobile ? (
                <>
                  <div className='steps-container'>
                    <img className='image-icon' src={AddBusiness} />
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                      width='100%'
                    >
                      <div className='title-container'>
                        <Box display='flex'>
                          <div
                            className='title'
                            style={{ color: !companyDetails ? '#4D5E80' : 'inherit' }}
                          >
                            Add a business
                          </div>
                          {isCompleteBusiness && (
                            <img
                              style={{ width: '30px', height: '30px', marginLeft: 'auto' }}
                              src={GreenCheck}
                            />
                          )}
                        </Box>
                        <div
                          className='subtitle'
                          style={{ color: !companyDetails ? '#4D5E80' : 'inherit' }}
                        >
                          Fill in your company information to manage payments, invoices, estimates,
                          etc.
                        </div>
                      </div>
                    </Box>
                  </div>
                  {!isCompleteBusiness && (
                    <Box marginLeft={'40px'} marginBottom={'35px'}>
                      <Button
                        className='button-common-style'
                        style={{ height: '45px', width: '174px' }}
                        color='primary'
                        variant='contained'
                        onClick={addBusiness}
                        fullWidth
                      >
                        Add a business
                      </Button>
                    </Box>
                  )}
                </>
              ) : (
                <div className='steps-container'>
                  <img className='image-icon' src={AddBusiness} />
                  <Box
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    width='100%'
                  >
                    <div className='title-container'>
                      <div
                        className='title'
                        style={{ color: !companyDetails ? '#4D5E80' : 'inherit' }}
                      >
                        Add a business
                      </div>
                      <div
                        className='subtitle'
                        style={{ color: !companyDetails ? '#4D5E80' : 'inherit' }}
                      >
                        Fill in your company information to manage payments, invoices, estimates,
                        etc.
                      </div>
                    </div>
                    {!isCompleteBusiness ? (
                      <div>
                        <Button
                          className='button-common-style'
                          style={{ height: '60px', width: '174px' }}
                          color='primary'
                          variant='contained'
                          onClick={addBusiness}
                          fullWidth
                        >
                          Add a business
                        </Button>
                      </div>
                    ) : (
                      <img
                        style={{ width: '50px', height: '50px', marginLeft: 'auto' }}
                        src={GreenCheck}
                      />
                    )}
                  </Box>
                </div>
              )}

              {isMobile ? (
                <>
                  <div className='steps-container'>
                    <img className='image-icon' src={Subscribe} />
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                      width='100%'
                    >
                      <div className='title-container'>
                        <Box display='flex'>
                          <div
                            className='title'
                            style={{ color: !haveSubscription ? '#4D5E80' : 'inherit' }}
                          >
                            Subscribe
                          </div>
                          {haveSubscription && (
                            <img
                              style={{ width: '30px', height: '30px', marginLeft: 'auto' }}
                              src={GreenCheck}
                            />
                          )}
                        </Box>
                        <div
                          className='subtitle'
                          style={{ color: !haveSubscription ? '#4D5E80' : 'inherit' }}
                        >
                          You´ll have unlimited access to all functionality (invoices, estimates,
                          payments, reports, purchases, etc.)
                        </div>
                      </div>
                    </Box>
                  </div>
                  {!haveSubscription && (
                    <Box marginLeft={'40px'} marginBottom={'35px'}>
                      <Button
                        className='button-common-style'
                        style={{ height: '45px', width: '174px' }}
                        color='primary'
                        variant='contained'
                        onClick={addSubscription}
                        fullWidth
                        disabled={!isCompleteBusiness}
                      >
                        Choose your plan
                      </Button>
                    </Box>
                  )}
                </>
              ) : (
                <div className='steps-container'>
                  <img className='image-icon' src={Subscribe} />
                  <Box
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    width='100%'
                  >
                    <div className='title-container'>
                      <div
                        className='title'
                        style={{ color: !haveSubscription ? '#4D5E80' : 'inherit' }}
                      >
                        Subscribe
                      </div>
                      <div
                        className='subtitle'
                        style={{ color: !haveSubscription ? '#4D5E80' : 'inherit' }}
                      >
                        You´ll have unlimited access to all functionality (invoices, estimates,
                        payments, reports, purchases, etc.)
                      </div>
                    </div>
                    {!haveSubscription ? (
                      <div>
                        <Button
                          className='button-common-style'
                          style={{ height: '60px', width: '174px' }}
                          color='primary'
                          variant='contained'
                          onClick={addSubscription}
                          fullWidth
                          disabled={!isCompleteBusiness}
                        >
                          Choose your plan
                        </Button>
                      </div>
                    ) : (
                      <img
                        style={{ width: '50px', height: '50px', marginLeft: 'auto' }}
                        src={GreenCheck}
                      />
                    )}
                  </Box>
                </div>
              )}

 
            </div>
            <SubscribeDialog
              open={openSubscribeDialog}
              setIsSubscribeDialogOpen={setIsSubscribeDialogOpen}
              onClose={handleDialogClose || noop}
            />
          </div>
        </Box>
      ) : (
        <Box
          id='bussinessOnboarding'
          width={getWidth()}
          sx={{ margin: '50px auto 0 auto', marginLeft: getMarginLeft() }}
        >
          <BusinessInfoManage
            ctaCallback={() => setShowBusinessOnBoarding(false)}
            pageHeaderBackNavigation={null}
          />
        </Box>
      )}
    </>
  )
}

export default Onboarding
