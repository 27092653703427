import { useState } from 'react';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import './dashboard-tooltip.css';
import { Typography } from '@mui/material';

interface IDashboardTooltip {
	text: string;
}

const DashboardTooltip = (props: IDashboardTooltip) => {
	const { text } = props;
	const [isHovering, setIsHovering] = useState<boolean>(false);
	const handleMouseOver = () => {
		setIsHovering(true);
	};

	const handleMouseOut = () => {
		setIsHovering(false);
	};
	return (
		<div className="dahboard-tooltip__wrapper">
			<div className="dashboard-tooltip__text">
				<Typography sx={{fontSize: "10px"}}> {text} </Typography>
			</div>
			<div
				className="dashboard-tooltip__cover"
				style={{ animation: isHovering ? 'slide 0.8s forwards' : '' }}
			></div>
			<AnnouncementIcon
				sx={{color: "var(--primary1-color)"}}
				className="dashboard-tooltip__icon"
				onMouseOver={handleMouseOver}
				onMouseOut={handleMouseOut}>
			</AnnouncementIcon>
		</div>
	);
};

export default DashboardTooltip;
