import './table-no-data.css';

interface ITableNoData {
	styles?: string;
	messageText: string;
}

const TableNoData = (props: ITableNoData) => {
	const { styles, messageText } = props;
	return (
		<div className={`table-no-data__wrapper ${styles}`}>
			<div className="table-no-data__content">{messageText}</div>
		</div>
	);
};

export default TableNoData;
