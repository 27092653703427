import {
	SAVE_OVERDUE_INVOICES,
	SAVE_OPEN_INVOICES,
	SAVE_OVERDUE_STATISTIC,
	SAVE_TOTAL_REVENUE,
	SAVE_CASH_INFLOW,
} from '../actions/dashboard.actions.type';

import { Action } from '../actions/actionTypes';
import {
	IInvoice,
	IOutstandingStatistic,
	IDashboardTotalRevenue,
	IDashboardCashInflow,
} from '../../types/types';

export interface IInitialState {
	overdueInvoices: IInvoice[];
	overdueInvoicesSize: number;
	openInvoices: IInvoice[];
	openInvoicesSize: number;
	overdueStatistic: IOutstandingStatistic[];
	totalRevenue: IDashboardTotalRevenue | null;
	cashInflow: IDashboardCashInflow | null;
}

const initialState: IInitialState = {
	overdueInvoices: [],
	overdueInvoicesSize: 0,
	openInvoices: [],
	openInvoicesSize: 0,
	overdueStatistic: [],
	totalRevenue: null,
	cashInflow: null,
};

const dashboardReducer = (state = initialState, action: Action) => {
	switch (action.type) {
		case SAVE_OVERDUE_INVOICES: {
			return {
				...state,
				overdueInvoices: action.payload,
				overdueInvoicesSize: action.size,
			};
		}
		case SAVE_OPEN_INVOICES: {
			return {
				...state,
				openInvoices: action.payload,
				openInvoicesSize: action.size,
			};
		}
		case SAVE_OVERDUE_STATISTIC: {
			return {
				...state,
				overdueStatistic: action.payload,
			};
		}
		case SAVE_TOTAL_REVENUE: {
			return {
				...state,
				totalRevenue: action.payload,
			};
		}
		case SAVE_CASH_INFLOW: {
			return {
				...state,
				cashInflow: action.payload,
			};
		}
		default:
			return state;
	}
};

export default dashboardReducer;
