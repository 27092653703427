import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import HeaderCreate from '../../components/header/headerCreate'
import {
  saveCertainJournal,
  savePurchaseProducts,
  saveVendors,
} from '../../redux/actions/home.actions'
import { useDispatch, useSelector } from 'react-redux'
import { INewJournalEntry } from '../../types/types'
import moment from 'moment'
import FormHeader from './form/header'
import FormItems from './form/items'
import { Box, Button, Card, CardContent, Grid } from '@mui/material'
import api from '../../api/api'
import { successToastNotification } from '../../helpers/toastNotification'
import { AppState } from '../../redux/store'
import { isUsingMobile } from '../../helpers/utils'

const JournalEntriesManage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { publicId } = useParams()
  const chosenJournal = useSelector((state: AppState) => state.homeReducer.chosenJournal)
  const isMobile = isUsingMobile()
  const isAddMode = location.pathname.endsWith('/add')

  const [newJournal, setNewJournal] = useState<INewJournalEntry>({
    description: '',
    date: moment().format('YYYY-MM-DD HH:mm'),
    items: [
      {
        accountPublicId: null,
        description: '',
        debitAmount: 0,
        creditAmount: 0,
      },
      {
        accountPublicId: '',
        description: '',
        debitAmount: 0,
        creditAmount: 0,
      },
    ],
    memo: ' ',
    attachment: undefined,
  })

  const handleSaveClick = () => {
    const adjustedItems: Record<string, string | number> = {}

    newJournal.items.forEach((item, index) => {
      const newItem = {
        [`items[${index}]accountPublicId`]: item.accountPublicId,
        [`items[${index}]description`]: item.description,
        [`items[${index}]debitAmount`]: item.debitAmount,
        [`items[${index}]creditAmount`]: item.creditAmount,
      }

      Object.assign(adjustedItems, newItem)
    })

    const payload = {
      ...newJournal,
      ...adjustedItems,
      items: undefined,
    }

    delete payload.items

    const formData = new FormData()

    formData.append('description', newJournal.description)
    formData.append('date', newJournal.date)
    formData.append('memo', newJournal.memo)

    if (newJournal.attachment) {
      formData.append('attachment', newJournal.attachment)
    }

    newJournal.items.forEach((item, index) => {
      if (item.accountPublicId !== null && item.accountPublicId !== undefined) {
        formData.append(`items[${index}].accountPublicId`, item.accountPublicId)
      }

      if (item.debitAmount !== null && item.debitAmount !== undefined) {
        formData.append(`items[${index}].debitAmount`, String(item.debitAmount))
      } else {
        formData.append(`items[${index}].debitAmount`, '0')
      }

      if (item.creditAmount !== null && item.creditAmount !== undefined) {
        formData.append(`items[${index}].creditAmount`, String(item.creditAmount))
      } else {
        formData.append(`items[${index}].creditAmount`, '0')
      }

      if (item.description !== null && item.description !== undefined) {
        formData.append(`items[${index}].description`, item.description)
      }
    })

    if (publicId) {
      api.editJournal(publicId, formData).then(() => {
        successToastNotification(`Journal was successfully updated`)
        navigate('/journal-entries')
      })
    } else {
      api.createJournal(formData).then(() => {
        successToastNotification(`Journal was successfully created`)
        navigate('/journal-entries')
      })
    }
  }

  const isDisabledSaveButton = useMemo(() => {
    const hasDescription = newJournal.description !== ''
    const hasDate = !!newJournal.date
    const hasAccountPublicIdFirst = !!newJournal.items[0].accountPublicId
    const hasAccountPublicIdSecond = !!newJournal.items[1].accountPublicId
    const hasDebitOrCreditFirst =
      !!newJournal.items[0].debitAmount || !!newJournal.items[0].creditAmount
    const hasDebitOrCreditSecond =
      !!newJournal.items[1].debitAmount || !!newJournal.items[1].creditAmount

    return (
      !hasDescription ||
      !hasDate ||
      !hasAccountPublicIdFirst ||
      !hasAccountPublicIdSecond ||
      !hasDebitOrCreditFirst ||
      !hasDebitOrCreditSecond
    )
  }, [newJournal])

  const clearForm = () => {
    setNewJournal({
      description: '',
      date: moment().format('YYYY-MM-DD HH:mm'),
      items: [
        {
          accountPublicId: null,
          description: '',
          debitAmount: 0,
          creditAmount: 0,
        },
      ],
      memo: ' ',
      attachment: undefined,
    })
  }

  useEffect(() => {
    dispatch(savePurchaseProducts())
    dispatch(saveVendors())
  }, [])

  useEffect(() => {
    if (publicId) {
      dispatch(saveCertainJournal(publicId))
    }
  }, [publicId])

  useEffect(() => {
    if (!chosenJournal) {
      return
    }

    if (isAddMode) {
      clearForm()
    } else {
      setNewJournal({
        description: chosenJournal.description,
        date: moment(chosenJournal.date).format('YYYY-MM-DD HH:mm'),
        items: chosenJournal.items.map((item: any) => {
          return {
            accountPublicId: item.account.publicId,
            description: item.description,
            debitAmount: item.debitAmount,
            creditAmount: item.creditAmount,
          }
        }),
        memo: chosenJournal.memo,
        attachment: chosenJournal?.attachment,
      })
    }
  }, [chosenJournal, isAddMode])

  const handleChangeFields = (e: any, value: string, isDate?: boolean) => {
    setNewJournal({
      ...newJournal,
      [value]: isDate ? moment(e).format('YYYY-MM-DD HH:mm') : e.target.value,
    })
  }

  return (
    <>
      <HeaderCreate
        title={!publicId ? 'Add a journal entry' : 'Edit journal entry'}
        saveTextButton='Save'
        cancelTextButton='Cancel'
        handleCancel={() => navigate(`/journal-entries`)}
        handleAcceptClick={handleSaveClick}
        isDisabledSaveButton={isDisabledSaveButton}
      />
      {isMobile ? (
        <>
          <Card
            sx={{
              my: 2,
              '&.MuiPaper-root': { boxShadow: '0 5px 10px 0 var(--secondary-grey-bg)' },
              '& .MuiInputBase-root fieldset': { borderColor: 'var(--secondary-grey)' },
            }}
          >
            <CardContent>
              <FormHeader journal={newJournal} onChangeField={handleChangeFields} />
            </CardContent>

            <FormItems journal={newJournal} setJournal={setNewJournal} />
          </Card>

          <Grid container>
            <Grid
              container
              justifyContent={{ xs: 'normal', md: 'right' }}
              alignItems={{ xs: 'normal', md: 'center' }}
              flexDirection={{ xs: 'column', md: 'row' }}
            >
              <Grid item pt='1rem'>
                <Button color='primary' variant='contained' onClick={handleSaveClick} fullWidth>
                  Save
                </Button>
              </Grid>
              <Grid item p='1rem 0 0.5rem 0'>
                <Button
                  color='primary'
                  variant='outlined'
                  onClick={() => navigate(`/journal-entries`)}
                  fullWidth
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <Card
          sx={{
            '&.MuiPaper-root': { boxShadow: 'none' },
            '& .MuiInputBase-root fieldset': { borderColor: 'var(--secondary-grey)' },
            mt: 2,
          }}
        >
          <CardContent sx={{ p: 5 }}>
            <FormHeader journal={newJournal} onChangeField={handleChangeFields} />
            <Box mt={3}>
              <FormItems journal={newJournal} setJournal={setNewJournal} />
            </Box>
          </CardContent>
        </Card>
      )}
    </>
  )
}

export default JournalEntriesManage
