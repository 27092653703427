import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material'
import { ICustomer } from '../../../types/types'

interface ShippingAddress {
  customer: ICustomer
}

const ShippingAddress = ({ customer }: ShippingAddress) => {
  return (
    <Card sx={{ '&.MuiPaper-root': { boxShadow: 'none' } }}>
      <CardHeader title='Shipping address'></CardHeader>
      <CardContent>
        {customer && (
          <>
            <Grid container>
              <Grid item sm={6}>
                <Typography variant='subtitle1'>Ship to</Typography>
                <Typography variant='subtitle2' color='text.secondary'>
                  {customer.shippingName ? customer.shippingName : '-'}
                </Typography>
                <Typography variant='subtitle1'>Address line 1</Typography>
                <Typography variant='subtitle2' color='text.secondary'>
                  {customer.shippingAddress ? customer.shippingAddress : '-'}
                </Typography>
                <Typography variant='subtitle1'>City</Typography>
                <Typography variant='subtitle2' color='text.secondary'>
                  {customer.shippingCity ? customer.shippingCity : '-'}
                </Typography>
                <Typography variant='subtitle1'>Postal/Zip code</Typography>
                <Typography variant='subtitle2' color='text.secondary'>
                  {customer.shippingPostalCode ? customer.shippingPostalCode : '-'}
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant='subtitle1'>Phone</Typography>
                <Typography variant='subtitle2' color='text.secondary'>
                  {customer.shippingPhone ? customer.shippingPhone : '-'}
                </Typography>
                <Typography variant='subtitle1'>Address line 2</Typography>
                <Typography variant='subtitle2' color='text.secondary'>
                  {customer.shippingAddressTwo ? customer.shippingAddressTwo : '-'}
                </Typography>
                <Typography variant='subtitle1'>State</Typography>
                <Typography variant='subtitle2' color='text.secondary'>
                  {customer.shippingState ? customer.shippingState : '-'}
                </Typography>
                <Typography variant='subtitle1'>Country</Typography>
                <Typography variant='subtitle2' color='text.secondary'>
                  {customer?.shippingCountry ? customer.shippingCountry : '-'}
                </Typography>
              </Grid>
            </Grid>
            <Typography variant='subtitle1'>Notes</Typography>
            <Typography variant='subtitle2' color='text.secondary'>
              {customer.deliveryInstructions ? customer.deliveryInstructions : '-'}
            </Typography>
          </>
        )}
      </CardContent>
    </Card>
  )
}

export default ShippingAddress
