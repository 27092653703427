import { Button, Grid, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { AppState } from '../../../redux/store'
import { useMemo } from 'react'
import { INewBill, INewBillToProduct, IPurchaseProduct } from '../../../types/types'
import { formatMoney } from '../../../helpers/formatPrice'
import BillFormTable from './table'
import { isUsingMobile } from '../../../helpers/utils'
import BillFormCards from './cards'
import AddIcon from '@mui/icons-material/Add'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import FileIcon from '@mui/icons-material/InsertPhotoOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'

interface FormItemsProps {
  bill: INewBill
  setBill: any
}

const FormItems = ({ bill, setBill }: FormItemsProps) => {
  const isMobile = isUsingMobile()
  const products = useSelector((state: AppState) => state.homeReducer.purchaseProducts)

  const getProductOptions = useMemo(() => {
    if (!products) {
      return []
    }

    return products.map((product: IPurchaseProduct) => {
      return { title: product.name, value: product.publicId || '' }
    })
  }, [products])

  const handleChangeBillItem = (selectedIndex: number, key: string) => (event: any) => {
    let items = []

    if (key === 'purchaseProductPublicId') {
      const selectedProduct = products.find((product) => product.publicId === event.target.value)

      items = bill.items.map((item, index) => {
        if (index === selectedIndex && selectedProduct) {
          return {
            ...item,
            purchaseProductPublicId: event.target.value,
            price: selectedProduct.price,
            amount: selectedProduct.price,
          }
        }

        return item
      })
    } else {
      items = bill.items.map((item, index) => {
        if (index === selectedIndex) {
          return { ...item, [key]: event.target.value }
        }

        return item
      })
    }

    const subTotal = items.reduce((prev, current) => prev + current.price * current.quantity, 0)

    setBill({ ...bill, items, subTotal, total: subTotal })
  }

  const handleDeleteItem = (index: number) => () => {
    setBill({
      ...bill,
      items: bill.items.filter((item: INewBillToProduct, itemIndex: number) => itemIndex !== index),
    })
  }

  const handleAddItem = () => {
    setBill({
      ...bill,
      items: [
        ...bill.items,
        {
          purchaseProductPublicId: '',
          description: '',
          quantity: 1,
          price: 0,
          amount: 0,
        },
      ],
    })
  }

  const handleClearAllItems = () => {
    setBill({
      ...bill,
      items: [
        {
          purchaseProductPublicId: '',
          description: '',
          quantity: 1,
          price: 0,
          amount: 0,
        },
      ],
    })
  }

  const handleFileChange = (file: any) => {
    setBill({
      ...bill,
      attachment: file,
    })
  }
  
  const removeFile = () => {
    setBill({
      ...bill,
      attachment: '',
    })
  }

  return (
    <>
      {isMobile ? (
        <BillFormCards
          bill={bill}
          products={getProductOptions}
          onChangeBillItem={handleChangeBillItem}
          onDeleteItem={handleDeleteItem}
        />
      ) : (
        <BillFormTable
          bill={bill}
          products={getProductOptions}
          onChangeBillItem={handleChangeBillItem}
          onDeleteItem={handleDeleteItem}
        />
      )}

      <Stack flexDirection='row' gap={1} m={isMobile ? '1rem 0' : 2}>
        <Button
          color='primary'
          variant='contained'
          onClick={handleAddItem}
          sx={{ flexGrow: isMobile ? 1 : undefined }}
          startIcon={<AddIcon />}
        >
          Add a line
        </Button>
        <Button
          color='error'
          variant='outlined'
          onClick={handleClearAllItems}
          sx={{ flexGrow: isMobile ? 1 : undefined }}
        >
          Clear all items
        </Button>
      </Stack>

      <hr style={{ border: '1px solid var(--secondary-grey-bg)' }} />

      <Grid container gap={2}>
        <Grid item flexGrow={8}>
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: isMobile?  "10px" : 0 }}>
            <TextField
              multiline
              rows={3}
              style={{ marginTop: '10px', padding: '0 16px' }}
              placeholder='Enter some notes'
              value={bill.memo}
              onChange={(event) => setBill({ ...bill, memo: event.target.value })}
            />
            {!isMobile &&<div
              style={{
                marginTop: '10px',
                marginLeft: '20px',
                display: 'flex',
                alignItems: 'flex-start',
                width: '100%',
              }}
            >
              <input
                accept='image/*'
                style={{ display: 'none' }}
                id='file-input'
                multiple
                type='file'
                onChange={(e) => {
                  const inputElement = e.target as HTMLInputElement
                  if (inputElement && inputElement.files && inputElement.files.length > 0) {
                    handleFileChange(inputElement.files[0])
                  }
                }}
              />
              <label htmlFor='file-input'>
                <div className='custom-button' style={{ height: '46px', width: '46px' }}>
                  <AttachFileIcon />
                </div>
              </label>
              {bill.attachment !== '' && bill.attachment && bill.attachment.name && (
                <div
                  style={{
                    width: '252px',
                    marginLeft: '15px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      background: '#fff',
                      border: '1px solid #DFE3EB',
                      borderRadius: '8px',
                      marginBottom: '10px',
                      padding: '5px',
                      width: '100%',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        marginRight: '15px',
                      }}
                    >
                      <FileIcon />
                    </div>
                    <div style={{ marginRight: '15px', fontSize: '12px', fontWeight: '400' }}>
                      {bill.attachment.name && bill.attachment.name.length > 20 ? (
                        <Tooltip title={bill.attachment.name} arrow>
                          <span>{bill.attachment.name.slice(0, 20)}...</span>
                        </Tooltip>
                      ) : (
                        bill.attachment.name
                      )}
                    </div>
                    <div
                      style={{
                        color: 'red',
                        marginLeft: 'auto',
                        cursor: 'pointer',
                        marginTop: '6px',
                      }}
                      onClick={removeFile}
                    >
                      <DeleteOutlineOutlinedIcon />
                    </div>
                  </div>
                </div>
              )}
            </div>}
          </div>
        </Grid>
       {!isMobile && 
        <Grid item flexGrow={1} style={{ marginTop: '5px' }}>
          <Typography fontSize={16} fontWeight={600} color='var(--secondary-grey)'>
            Subtotal
          </Typography>
          <Typography fontSize={16} fontWeight={600} color='var(--green)' mt={1}>
            TOTAL
          </Typography>
        </Grid>
         }
         {!isMobile && 
        <Grid item flexGrow={1} style={{ marginTop: '5px' }}>
          <Typography fontSize={16} fontWeight={600} color='var(--secondary-grey)'>
            {formatMoney(bill.subTotal)}
          </Typography>
          <Typography fontSize={16} fontWeight={600} color='var(--green)' mt={1}>
            {formatMoney(bill.total)}
          </Typography>
        </Grid>
         }
      </Grid>
      {isMobile &&
      <Grid container gap={2}>
      <Grid item flexGrow={8}></Grid>
      <Grid item flexGrow={1} style={{ marginTop: '5px' }}>
          <Typography fontSize={16} fontWeight={600} color='var(--secondary-grey)'>
            Subtotal
          </Typography>
          <Typography fontSize={16} fontWeight={600} color='var(--green)' mt={1}>
            TOTAL
          </Typography>
        </Grid>
        <Grid item flexGrow={1} style={{ marginTop: '5px' }}>
          <Typography fontSize={16} fontWeight={600} color='var(--secondary-grey)'>
            {formatMoney(bill.subTotal)}
          </Typography>
          <Typography fontSize={16} fontWeight={600} color='var(--green)' mt={1}>
            {formatMoney(bill.total)}
          </Typography>
        </Grid>
      
      </Grid>
      }
    </>
  )
}

export default FormItems
