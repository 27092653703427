import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRowSpacingParams,
  GridTreeNode,
} from '@mui/x-data-grid'
import { useDispatch } from 'react-redux'
import { IAccount } from '../../types/types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import TableStyles from '../../components/table-card/table-styles'
import '../../components/table-card/table-card.css'
import { Box, Chip, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { isUsingMobile } from '../../helpers/utils'
import Loader from '../../components/loader/loader'
import { saveFinancialAccount } from '../../redux/actions/home.actions'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

interface ChartsTableProps {
  financialAccount: IAccount[]
  onChangeStatusClick: () => void
  onRowClick: (account: IAccount) => void
  onsetSelectedAccount: (value: IAccount) => void
  onSelectedAccount: IAccount
  changeStatusText: string
}

const getColorByStatus = (status: string) => {
  switch (status) {
    case 'Active':
      return 'success'
    case 'Canceled':
      return 'error'
  }
}

const ChartsTable = ({
  financialAccount,
  onChangeStatusClick,
  onRowClick,
  changeStatusText,
  onSelectedAccount,
  onsetSelectedAccount,
}: ChartsTableProps) => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [loading, setLoading] = useState(true)

  const openItemMenu = Boolean(anchorEl)
  const isMobile = isUsingMobile()

  const handleMenuButtonClick = useCallback(
    (account: IAccount) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      onsetSelectedAccount(account)
      setAnchorEl(event.currentTarget)
    },
    [onsetSelectedAccount],
  )

  const handleEditClick = (account: IAccount) => (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null)
    onRowClick(account)
  }

  const isfinancialAccountActive = (financialAccount: any) => {
    return (
      financialAccount &&
      financialAccount.length > 0 &&
      financialAccount.active !== undefined &&
      financialAccount.active
    )
  }

  const isActive = isfinancialAccountActive(financialAccount)

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleChangeStatusClick = () => {
    handleCloseMenu()
    onChangeStatusClick && onChangeStatusClick()
  }

  const baseColumns = useMemo<GridColDef<IAccount>[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        flex: !isMobile ? 1 : 2,
        sortable: false,
        renderCell: (param) => {
          return (
            <Box>
              <Typography>{param.row.identifier}</Typography>
            </Box>
          )
        },
      },
      {
        field: 'names',
        headerName: 'Name',
        flex: !isMobile ? 1 : 2,
        sortable: false,
        renderCell: (param) => {
          return (
            <Box>
              <Typography>{param.row.name}</Typography>
            </Box>
          )
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: !isMobile ? 1 : 2,
        sortable: false,
        renderCell: (param) => {
          return !isMobile ? (
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Chip
                sx={{
                  '&.MuiChip-colorSuccess': {
                    backgroundColor: 'var(--success-bg-color)',
                    color: 'var(--success-color)',
                  },
                  '&.MuiChip-colorWarning': {
                    backgroundColor: 'var(--warning-bg-color)',
                    color: 'var(--warning-color)',
                  },
                  '&.MuiChip-colorError': {
                    backgroundColor: 'var(--error-bg-color)',
                    color: 'var(--error-color)',
                  },
                }}
                color={getColorByStatus(param.row.active ? 'Active' : 'Canceled')}
                label={param.row.active ? 'Active' : 'Inactive'}
              />
            </div>
          ) : (
            <FiberManualRecordIcon
              sx={{
                '&.MuiSvgIcon-colorSuccess': {
                  color: 'var(--success-color)',
                  borderRadius: '50%',
                  backgroundColor: 'var(--success-bg-color)',
                },
                '&.MuiSvgIcon-colorError': {
                  color: 'var(--error-color)',
                  borderRadius: '50%',
                  backgroundColor: 'var(--error-bg-color)',
                },
                '&.MuiSvgIcon-root': {
                  margin: 'auto',
                },
              }}
              color={getColorByStatus(param.row.active ? 'Active' : 'Canceled')}
            />
          )
        },
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 4,
        sortable: false,
        renderCell: (param) => {
          return (
            <Box>
              <Typography>{param.row.description}</Typography>
            </Box>
          )
        },
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: !isMobile ? 1 : 2,
        sortable: false,
        renderCell: (param) => (
          <IconButton
            aria-label='more'
            id='long-button'
            aria-controls={openItemMenu ? 'long-menu' : undefined}
            aria-expanded={openItemMenu ? 'true' : undefined}
            aria-haspopup='true'
            onClick={handleMenuButtonClick(param.row)}
            sx={{
              justifyItems: 'center',
            }}
          >
            <MoreVertIcon />
          </IconButton>
        ),
      },
    ],
    [handleMenuButtonClick, openItemMenu, isMobile, isActive],
  )

  const columns = useMemo<GridColDef<IAccount>[]>(() => {
    return isMobile
      ? baseColumns.filter(
          (column) =>
            column.field === 'status' ||
            column.field === 'id' ||
            column.field === 'actions' ||
            column.field === 'names',
        )
      : baseColumns
  }, [isMobile, isActive])

  const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: 5,
    }
  }, [])

  return (
    <>
      <div>
        <DataGrid
          rows={financialAccount}
          columns={columns}
          disableColumnMenu
          getRowId={(financialAccount) => financialAccount.publicId || ''}
          onRowClick={(params) => onRowClick(params.row)}
          hideFooter
          getRowSpacing={getRowSpacing}
          rowHeight={65}
          initialState={{
            columns: {
              columnVisibilityModel: {
                name: !isMobile,
                createdAt: !isMobile,
                customerEmail: !isMobile,
              },
            },
          }}
          getCellClassName={(params: GridCellParams<any, IAccount, IAccount, GridTreeNode>) => {
            return params.field !== 'actions' ? 'rowStyle' : ''
          }}
          sx={TableStyles}
        />
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={openItemMenu}
          onClose={handleCloseMenu}
          sx={{
            '& .MuiMenuItem-root': {
              padding: { xs: '1rem', md: '1rem 4rem 1rem 2rem' },
            },
            '& .MuiMenuItem-root:hover': {
              backgroundColor: 'var(--secondary1-color)',
              color: 'var(--primary1-color)',
            },
          }}
        >
          <MenuItem sx={{ color: '#0039FF' }} onClick={handleEditClick(onSelectedAccount)}>
            Edit
          </MenuItem>
          <MenuItem
            sx={{
              color:
                changeStatusText === 'Set as Inactive'
                  ? 'var(--error-color)'
                  : 'var(--success-color)',
            }}
            onClick={handleChangeStatusClick}
          >
            {changeStatusText}
          </MenuItem>
        </Menu>
      </div>
    </>
  )
}

export default ChartsTable
