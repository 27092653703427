import { Card, CardContent, Grid, Typography } from '@mui/material'
import { styled } from '@mui/styles'
import { useSelector } from 'react-redux'
import { AppState } from '../../../redux/store'
import { formatMoney } from '../../../helpers/formatPrice'

const KpiLabel = styled(Typography)(() => ({
  '&.MuiTypography-root': {
    color: 'var(--secondary-grey)',
    fontSize: 14,
  },
}))

const KpiValue = styled(Typography)(() => ({
  '&.MuiTypography-root': {
    color: 'var(--secondary-grey)',
    fontSize: 24,
    fontWeight: 600,
  },
}))

const KpiContainer = styled(Card)(() => ({
  '&.MuiPaper-root': {
    boxShadow: 'none',
  },
}))

const BalanceSheetKpis = ({ mb }: { mb?: string | number }) => {
  const data = useSelector((state: AppState) => state.reportReducer.balanceSheet)

  return (
    <KpiContainer sx={{ mb }}>
      <CardContent sx={{ px: 10 }}>
        <Grid container justifyContent='space-between'>
          <Grid item>
            <KpiLabel>Cash and Bank</KpiLabel>
            <KpiValue>{formatMoney(data?.totalCashAndBank)}</KpiValue>
          </Grid>
          <Grid item p={2}>
            <Typography fontSize={24} component='span'>
              -
            </Typography>
          </Grid>
          <Grid item>
            <KpiLabel>To be received</KpiLabel>
            <KpiValue>{formatMoney(data?.totalToBeReceived)}</KpiValue>
          </Grid>
          <Grid item p={2}>
            <Typography fontSize={24} component='span'>
              -
            </Typography>
          </Grid>
          <Grid item>
            <KpiLabel>To be paid out</KpiLabel>
            <KpiValue>{formatMoney(data?.totalToBePaidOut)}</KpiValue>
          </Grid>
          <Grid item p={2}>
            <Typography fontSize={24} component='span'>
              =
            </Typography>
          </Grid>
          <Grid item>
            <KpiLabel>Total</KpiLabel>
            <KpiValue
              sx={{
                color:
                  data && data?.totalBalance >= 0
                    ? 'var(--green) !important'
                    : 'var(--error-color) !important',
              }}
            >
              {formatMoney(data?.totalBalance)}
            </KpiValue>
          </Grid>
        </Grid>
      </CardContent>
    </KpiContainer>
  )
}

export default BalanceSheetKpis
