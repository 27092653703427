import { useSelector } from 'react-redux'
import { AppState } from '../../redux/store'
import './sign-in-up-button.css'

interface ISignInUpButton {
  buttonText: string
  disabled?: boolean
  styles?: string
  onClick?: () => void
  name?: string
}

const SignInUpButton = (props: ISignInUpButton) => {
  const { buttonText, disabled, styles, name, onClick } = props
  const isLoading = useSelector((state: AppState) => state.homeReducer.isLoading)
  return (
    <button
      className={`sign-in-up-button__wrapper ${styles} ${isLoading ? 'background-animation' : ''}`}
      disabled={disabled}
      onClick={onClick}
      name={name}
    >
      {buttonText}
    </button>
  )
}

export default SignInUpButton
