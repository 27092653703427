import './filled-green-button.css';

interface IFilledGreenBUtton {
	buttonText: string;
	onClick?: () => void;
	styles?: string;
	disabled?: boolean;
	name?: string;
	wideOption?: boolean;
}

const FilledGreenButton = (props: IFilledGreenBUtton) => {
	const { buttonText, onClick, disabled, styles, name, wideOption } = props;
	return (
		<button
			className={`filled-green-button__wrapper ${styles} ${
				wideOption ? 'filled-green-button__wrapper-large' : ''
			}`}
			onClick={onClick}
			disabled={disabled}
			name={name}
		>
			{buttonText}
		</button>
	);
};

export default FilledGreenButton;
