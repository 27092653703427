import React from 'react';
import BusinessInformation from '../../pages/profile/business-information';

const Business = () => {
	return (
		<div>
			<BusinessInformation />
		</div>
	);
};

export default Business;
