import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { VALID_EMAIL, VALID_PASSWORD } from '../../helpers/constants'
import { saveAccessToken } from '../../redux/actions/auth.actions'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router'
import api from '../../api/api'
import visibilityIcon from '../../assets/icons/visibility.png'
import visibilityOffIcon from '../../assets/icons/visibility-off.png'
import signUpFirstImage from '../../assets/images/signUpFirst.png'
import signUpSecondImage from '../../assets/images/signUpSecond.png'
import signUpThirdImage from '../../assets/images/signUpThird.png'
import RegistrationField from '../../components/registration-field'
import SignInUpButton from '../../components/sign-in-up-button'
import { errorHandler } from '../../helpers/errorHandler'
import { changeIsLoading } from '../../redux/actions/home.actions'
import { SAVE_MCC_TYPES } from '../../redux/actions/auth.actions.type'
import { AppState } from '../../redux/store'
import './style.css'

interface ImagePagination {
  chosenTab: string
}

const ImagePagination = (props: ImagePagination) => {
  const { chosenTab } = props
  return (
    <div className='image-pagination__wrapper'>
      <div className={chosenTab === 'email' ? 'image-tab__active' : 'image-tab__inactive'}></div>
      <div className={chosenTab === 'password' ? 'image-tab__active' : 'image-tab__inactive'}></div>
      <div className={chosenTab === 'confirm' ? 'image-tab__active' : 'image-tab__inactive'}></div>
    </div>
  )
}

const SignUp = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isLoading = useSelector((state: AppState) => state.homeReducer.isLoading)
  const [signUpStep, setSignUpStep] = useState<string>('email')
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    fullName: '',
    companyName: '',
    typeOfBusiness: '',
    sizeOfOrganization: '',
    confirmationCode: '',
  })
  useEffect(() => {
    dispatch(changeIsLoading(false))
  }, [])

  useEffect(() => {
    api
      .getMCCTypes()
      .then((res) => {
        dispatch({
          type: SAVE_MCC_TYPES,
          payload: res.data.body,
        })
      })
      .catch((err) => console.log(err))
  }, [])

  const handleChangeValues = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setInitialValues({
      ...initialValues,
      [value]: e.target.value,
    })
  }

  const resetForm = () => {
    setInitialValues({
      email: '',
      password: '',
      confirmPassword: '',
      fullName: '',
      companyName: '',
      typeOfBusiness: '',
      sizeOfOrganization: '',
      confirmationCode: '',
    })
  }

  async function signUp() {
    try {
      await Auth.signUp({
        username: initialValues.email,
        password: initialValues.password,
        attributes: {
          email: initialValues.email,
          name: initialValues.fullName,
        },
      })
      setSignUpStep('confirm')
    } catch (error: any) {
      toast(error.message, {
        autoClose: 3000,
        hideProgressBar: true,
        className: 'error-toast',
      })
      resetForm()
      setSignUpStep('email')
    } finally {
      dispatch(changeIsLoading(false))
    }
  }

  async function signIn() {
    try {
      const user = await Auth.signIn(initialValues.email, initialValues.password)
      dispatch(saveAccessToken(user.signInUserSession.idToken.jwtToken))
      sessionStorage.setItem('token', user.signInUserSession.idToken.jwtToken)
      dispatch(changeIsLoading(false))
      navigate('/dashboard')
    } catch (error) {
      dispatch(changeIsLoading(false))
      errorHandler(error)
    }
  }

  async function confirmCode() {
    try {
      await Auth.confirmSignUp(initialValues.email, initialValues.confirmationCode)
      signIn()
    } catch (error: any) {
      dispatch(changeIsLoading(false))
      toast(error.message, {
        autoClose: 3000,
        hideProgressBar: true,
        className: 'error-toast',
      })
      setInitialValues({
        ...initialValues,
        confirmationCode: '',
      })
    }
  }

  const handleGetStartedClick = () => {
    signUp()
  }

  const handleConfirmClick = () => {
    dispatch(changeIsLoading(true))
    confirmCode()
  }

  const renderButtonText = (chosenTab: string) => {
    switch (chosenTab) {
      case 'email':
        return 'Continue'
      case 'password':
        return 'Continue'
      default:
        return 'Confirm account'
    }
  }

  const renderSubtitleText = (chosenTab: string) => {
    switch (chosenTab) {
      case 'email':
        return 'To continue create your account'
      case 'password':
        return 'Fill the fields with more details'
      default:
        return 'To continue verify your account'
    }
  }

  const renderImage = (chosenTab: string) => {
    switch (chosenTab) {
      case 'email':
        return signUpFirstImage
      case 'password':
        return signUpSecondImage
      default:
        return signUpThirdImage
    }
  }

  const handleClickVisibilityIcon = () => {
    setShowPassword(!showPassword)
  }

  const handleClickConfirmIcon = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const handleSwitchSingView = () => {
    navigate('/sign-in')
  }

  const handleButtonClick = () => {
    if (signUpStep === 'email') {
      setSignUpStep('password')
    }
    if (signUpStep === 'password') {
      handleGetStartedClick()
      setSignUpStep('confirm')
    }
    if (signUpStep === 'confirm') {
      handleConfirmClick()
    }
  }

  const handleDisableButton = () => {
    if (isLoading) true
    if (signUpStep === 'email') {
      return !VALID_EMAIL.test(initialValues.email)
    } else if (signUpStep === 'password') {
      return (
        !VALID_PASSWORD.test(initialValues.password) ||
        !VALID_PASSWORD.test(initialValues.confirmPassword) ||
        initialValues.password !== initialValues.confirmPassword ||
        initialValues.fullName.length < 3
      )
    } else {
      return !initialValues.confirmationCode
    }
  }
  const handlePressEnter = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter' && !handleDisableButton()) {
      e.preventDefault()
      handleButtonClick()
    }
  }
  return (
    <div className='sign-up-wrapper'>
      <div className='sign-up-content'>
        <div className='sign-up-content__wrapper'>
          <div className='h1-mulish'>
            {signUpStep === 'confirm' ? 'Verify your Account' : 'Welcome to Blue Cimarron!'}
          </div>
          <div className='b1-inter sign-up-subtitle'>{renderSubtitleText(signUpStep)}</div>
          <form onKeyDown={handlePressEnter}>
            {signUpStep === 'email' && (
              <RegistrationField
                placeholder='Email'
                styles='sign-up__input'
                value={initialValues.email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeValues(e, 'email')
                }
                validataionSchema={VALID_EMAIL.test(initialValues.email)}
                errorMessage='Wrong email format'
                name='sign-up-email'
              />
            )}
            {signUpStep === 'password' && (
              <>
                <RegistrationField
                  placeholder='Full name'
                  styles='sign-up__input'
                  value={initialValues.fullName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeValues(e, 'fullName')
                  }
                  validataionSchema={initialValues.fullName.length >= 3}
                  errorMessage='Must be minimum 3 symbols'
                  name='sign-up-fullname'
                />
                <RegistrationField
                  placeholder='Password'
                  styles='sign-up__input'
                  withIcon
                  icon={showPassword ? visibilityOffIcon : visibilityIcon}
                  type={showPassword ? 'text' : 'password'}
                  onIconClick={handleClickVisibilityIcon}
                  iconPosition='end'
                  value={initialValues.password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeValues(e, 'password')
                  }
                  validataionSchema={VALID_PASSWORD.test(initialValues.password)}
                  errorMessage='Must be min 8 chars, uppercase, number, no special'
                  name='sign-up-password'
                />
                <RegistrationField
                  placeholder='Confirm Password'
                  styles='sign-up__input'
                  withIcon
                  icon={showConfirmPassword ? visibilityOffIcon : visibilityIcon}
                  type={showConfirmPassword ? 'text' : 'password'}
                  onIconClick={handleClickConfirmIcon}
                  iconPosition='end'
                  value={initialValues.confirmPassword}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeValues(e, 'confirmPassword')
                  }
                  validataionSchema={VALID_PASSWORD.test(initialValues.confirmPassword)}
                  errorMessage='Must be min 8 chars, uppercase, number, no special'
                  name='sign-up-confirm-password'
                />
              </>
            )}
            {signUpStep === 'confirm' && (
              <RegistrationField
                placeholder='Confirmation code'
                styles='sign-up__input'
                value={initialValues.confirmationCode}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeValues(e, 'confirmationCode')
                }
                validataionSchema={!!initialValues.confirmationCode}
                errorMessage='Is required'
                name='sign-up-confirmation-code'
              />
            )}
          </form>
          <SignInUpButton
            buttonText={renderButtonText(signUpStep)}
            onClick={handleButtonClick}
            disabled={handleDisableButton()}
            name='sign-up-button'
          />
          <div className='b2-inter' style={{ textAlign: 'center', marginTop: 105 }}>
            Already have an Account?
            <span className='highlighted-text' onClick={() => handleSwitchSingView()}>
              {' '}
              Log in here
            </span>
          </div>
        </div>
      </div>
      <div className='sign-up-img__wrapper'>
        <img src={renderImage(signUpStep)} className='sign-up-img' />
        <ImagePagination chosenTab={signUpStep} />
      </div>
    </div>
  )
}

export default SignUp
