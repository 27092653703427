import {
  Box,
  Button,
  Grid,
  MenuItem,
  Tooltip,
  Typography,
  IconButton,
  Menu,
  Card,
  Chip,
  TextField,
} from '@mui/material'
import { useDispatch } from 'react-redux'
import BaseModal from '../../components/modal/modal'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import { useEffect, useState } from 'react'
import { isUsingMobile } from '../../helpers/utils'
import { formatDate } from '../../helpers/formatPrice'
import { IReferralsData } from '../../types/types'
import api from '../../api/api'
import { saveReferrals } from '../../redux/actions/home.actions'
import { successToastNotification } from '../../helpers/toastNotification'

interface IReferralsModalProps {
  showModal: string
  setShowModal: (value: string) => void
  resendEmail: (value: string) => void
  referral: IReferralsData
  handleDeleteClick: () => void
}

const ReferralsModal = ({
  showModal,
  setShowModal,
  resendEmail,
  referral,
  handleDeleteClick,
}: IReferralsModalProps) => {
  const handleCloseModal = () => {
    setShowModal('')
  }

  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [initialValues, setInitialValues] = useState({
    firstName: referral.firstName ?? '', // Usa una cadena vacía si firstName es undefined
    lastName: referral.lastName ?? '',
    companyName: referral.companyName ?? '',
    emailAddress: referral.emailAddress ?? '',
    phoneNumber: referral.phoneNumber ?? '',
    additionalComments: referral.additionalComments ?? '',
  })
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
  })

  useEffect(() => {
    setInitialValues({
      firstName: referral.firstName ?? '',
      lastName: referral.lastName ?? '',
      companyName: referral.companyName ?? '',
      emailAddress: referral.emailAddress ?? '',
      phoneNumber: referral.phoneNumber ?? '',
      additionalComments: referral.additionalComments ?? '',
    })
  }, [referral])

  const isMoreActionsMenuOpen = Boolean(anchorEl)

  const handleChangeValues = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setInitialValues({
      ...initialValues,
      [value]: e.target.value,
    })

    let regex: RegExp

    switch (value) {
      case 'firstName':
        setErrors((prevErrors) => ({
          ...prevErrors,
          firstName: e.target.value.trim() !== '' ? '' : 'First name cannot be empty',
        }))
        break
      case 'lastName':
        setErrors((prevErrors) => ({
          ...prevErrors,
          lastName: e.target.value.trim() !== '' ? '' : 'Last name cannot be empty',
        }))
        break
      case 'emailAddress':
        regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        setErrors((prevErrors) => ({
          ...prevErrors,
          emailAddress:
            e.target.value.trim() !== '' && regex.test(e.target.value)
              ? ''
              : 'Please enter a valid email address',
        }))
        break
      case 'phoneNumber':
        regex = /^\d+$/
        setErrors((prevErrors) => ({
          ...prevErrors,
          phoneNumber:
            regex.test(e.target.value) && e.target.value.length === 10
              ? ''
              : 'Please enter a valid phone number (10 digits)',
        }))
        break
      default:
        break
    }
  }

  const getColorByStatus = (status: string) => {
    switch (status) {
      case 'SENT':
        return 'warning'
      case 'PENDING':
        return 'error'
      case 'SUBSCRIBED':
        return 'success'
    }
  }

  const isMobile = isUsingMobile()

  const handleResendClick = () => {
    resendEmail(referral.publicId || '')
    setShowModal('')
  }

  const handleSaveModal = () => {
    api
      .updateReferral(referral.publicId || '', initialValues)
      .then(() => {
        setShowModal('')
        successToastNotification(`Referral updated`)
        const fetchData = async () => {
          try {
            await dispatch(saveReferrals())
          } catch (error) {
            console.error('Error fetching Referrals data', error)
          }
        }

        fetchData()
      })
      .catch((error: any) => {
        console.error('Error:', error)
      })
  }

  const handleMoreActionsMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <BaseModal
      title='Referral'
      isModalOpen={showModal !== ''}
      size='large'
      headerButtons={
        <>
          <Tooltip title='More options'>
            <IconButton
              aria-label='more'
              onClick={(event) => setAnchorEl(event.currentTarget)}
              sx={{
                color: 'var(--primary1-color)',
                background: 'var(--secondary1-color)',
              }}
            >
              <MoreVertOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Menu
            id='more-actions-menu'
            anchorEl={anchorEl}
            open={isMoreActionsMenuOpen}
            onClose={handleMoreActionsMenuClose}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            transformOrigin={{
              vertical: -12,
              horizontal: 'right',
            }}
            sx={{
              '& .MuiMenuItem-root': {
                padding: { xs: '1rem', md: '1rem 4rem 1rem 2rem' },
              },
              '& .MuiMenuItem-root:hover': {
                backgroundColor: 'var(--secondary1-color)',
                color: 'var(--primary1-color)',
              },
            }}
          >
            <MenuItem onClick={handleResendClick}>Resend email</MenuItem>
            <MenuItem style={{ color: 'red' }} onClick={handleDeleteClick}>
              Delete
            </MenuItem>
          </Menu>
        </>
      }
      body={
        <>
          <Box m={2}>
            <Card
              sx={{
                color: 'var(--secondary-grey)',
                mb: 1,
                '& .MuiTypography-root': { fontSize: '14px' },
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  height: '70px',
                  padding: '0 20px',
                  flexDirection: 'row',
                  marginTop: !isMobile ? '0' : '10px',
                }}
              >
                <div
                  style={{ marginRight: '15px', marginBottom: '5px' }}
                >{`${referral.firstName} ${referral.lastName}`}</div>
                <div>
                  <Chip
                    sx={{
                      '&.MuiChip-colorSuccess': {
                        backgroundColor: 'var(--success-bg-color)',
                        color: 'var(--success-color)',
                      },
                      '&.MuiChip-colorWarning': {
                        backgroundColor: 'var(--warning-bg-color)',
                        color: 'var(--warning-color)',
                      },
                      '&.MuiChip-colorError': {
                        backgroundColor: 'var(--secondary-grey-bg)',
                        color: 'var(--secondary-grey)',
                      },
                    }}
                    color={getColorByStatus(referral.status || '')}
                    label={referral.status}
                  />
                </div>

                {referral.status === 'SUBSCRIBED' && (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography style={{ color: '#99A6BF', fontSize: '14px' }}>
                      Subscribed on
                    </Typography>
                    <Typography>{formatDate(referral.subscriptionDate || '')}</Typography>
                  </div>
                )}
                {referral.status === 'SENT' && (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography style={{ color: '#99A6BF', fontSize: '14px' }}>
                      Invited on
                    </Typography>
                    <Typography>{formatDate(referral.sentDate || '')}</Typography>
                  </div>
                )}
                {referral.status === 'PENDING' && (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography style={{ color: '#99A6BF', fontSize: '14px' }}>
                      Created at
                    </Typography>
                    <Typography>{formatDate(referral.createdAt || '')}</Typography>
                  </div>
                )}
              </div>
            </Card>
            <div style={{ marginTop: '40px' }}>
              <TextField
                placeholder='Enter First name'
                style={{ marginBottom: '20px' }}
                required
                type='firstName'
                id='outlined-required'
                label='First Name'
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                value={initialValues.firstName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangeValues(e, 'firstName')
                }}
                error={Boolean(errors.firstName)}
                helperText={errors.firstName}
              />
              <TextField
                placeholder='Enter Last name'
                style={{ marginBottom: '20px' }}
                required
                type='lastName'
                id='outlined-required'
                label='Last Name'
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                value={initialValues.lastName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangeValues(e, 'lastName')
                }}
                error={Boolean(errors.lastName)}
                helperText={errors.lastName}
              />
              <TextField
                placeholder='Enter Company name'
                style={{ marginBottom: '20px' }}
                type='companyName'
                id='outlined-required'
                label='Company Name'
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                value={initialValues.companyName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangeValues(e, 'companyName')
                }}
              />
              <TextField
                placeholder='Enter Email address'
                style={{ marginBottom: '20px' }}
                required
                type='emailAddress'
                id='outlined-required'
                label='Email address'
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                value={initialValues.emailAddress}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangeValues(e, 'emailAddress')
                }}
                error={Boolean(errors.emailAddress)}
                helperText={errors.emailAddress}
              />
              <TextField
                placeholder='Enter Phone number'
                style={{ marginBottom: '20px' }}
                type='phoneNumber'
                label='Phone number'
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                value={initialValues.phoneNumber}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangeValues(e, 'phoneNumber')
                }}
                error={Boolean(errors.phoneNumber)}
                helperText={errors.phoneNumber}
              />
              <TextField
                placeholder='Enter an account description'
                multiline
                rows={4}
                style={{ marginBottom: '20px', height: '60px' }}
                type='additionalComments'
                label='Aditional comments'
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                value={initialValues.additionalComments}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangeValues(e, 'additionalComments')
                }}
              />
            </div>
          </Box>
        </>
      }
      footer={
        <Grid
          container
          justifyContent={{ xs: 'normal', md: 'right' }}
          alignItems={{ xs: 'normal', md: 'center' }}
          flexDirection={{ xs: 'column-reverse', md: 'row' }}
        >
          <Grid item padding={{ xs: '1rem', md: '0rem' }}>
            <Button color='primary' variant='outlined' fullWidth onClick={handleCloseModal}>
              Cancel
            </Button>
          </Grid>
          <Grid item padding={{ xs: '1rem 1rem 0 1rem', md: '1rem' }}>
            <Button color='primary' variant='contained' onClick={handleSaveModal} fullWidth>
              Save changes
            </Button>
          </Grid>
        </Grid>
      }
      handleCloseModal={handleCloseModal}
    />
  )
}

export default ReferralsModal
