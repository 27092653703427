import { useSelector } from 'react-redux'
import { AppState } from '../../redux/store'
import { formatMoney } from '../../helpers/formatPrice'
import useWindowDimensions from '../../helpers/useWindowDimentions'
import { IBill, INewBillPayment } from '../../types/types'
import moment from 'moment'
import { toWords } from 'number-to-words'

function convertNumberToWords(amount: number): string {
  const [integerPart, decimalPart] = amount.toString().split(/[.,]/);
  const integerWords = toWords(Number(integerPart));

  let decimalWords = '';
  if (decimalPart) {
    const decimalDigits = decimalPart.split('').map(Number);
    decimalWords = decimalDigits.map(toWords).join(' ');
  }

  return decimalWords
    ? `${integerWords} and ${decimalPart.padEnd(2, '0')}/100`
    : integerWords;
}

interface IPreviewBill {
  item?: any
  customer?: any
  preview?: any
  bill?: IBill
  newPayment: INewBillPayment 
}

const PreviewBill = ({ preview, bill, newPayment }: IPreviewBill) => {
  const { width } = useWindowDimensions()
  const vendorDetails = bill?.vendor

  const getPreviewWidth = () => {
    if (width && width < 1400) {
      return 700
    } else if (width && width < 1600 && width > 1400) {
      return 750
    } else if (width && width < 1800 && width > 1600) {
      return 900
    } else if (width && width < 2000 && width > 1800) {
      return 1050
    } else {
      return 1250
    }
  }

  return (
    <div ref={preview} 
      style={{
        width: getPreviewWidth(),
        fontFamily: 'Open Sans, sans-serif',
        padding: '45px',
        boxSizing: 'border-box',
        borderRadius: 5,
        backgroundColor: '#f7f8fa',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
      }}>

      <div style={{
          display: 'flex',
          flexDirection: 'column',
          height: '33.33%',
          justifyContent: 'flex-start',
        }}>
        <div style={{ textAlign: 'right', fontSize: 11, marginBottom: '20px' }}>
          <p style={{ margin: 0 }}>{moment(newPayment?.date).format('MM/DD/YYYY')}</p>
        </div>
        <div style={{ fontSize: 11 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
            <p style={{ margin: 0 }}>{vendorDetails?.name}</p>
            <p style={{ margin: 0 }}>**{formatMoney(newPayment?.amount)}</p>
          </div>
          <p style={{
            margin: 0,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
            {convertNumberToWords(newPayment?.amount)}{'*'.repeat(100)}
          </p>
          <p style={{ margin: 0 }}>{vendorDetails?.name}</p>
          <p style={{ margin: 0 }}>{vendorDetails?.addressOne}</p>
          <p style={{ margin: 0 }}>{vendorDetails?.addressTwo}</p>
          <p style={{ margin: 0 }}>
            {vendorDetails?.city} {vendorDetails?.state} {vendorDetails?.postalCode}
          </p>
          <p style={{ margin: 0 }}>{vendorDetails?.country}</p>
        </div>
      </div>

      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginBottom: '20px' }}>
        <div style={{ fontSize: 11 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
            <p style={{ margin: 0 }}>{vendorDetails?.name}</p>
            <p style={{ margin: 0 }}>{bill?.name}</p>
          </div>
        </div>
        <div style={{ fontSize: 11 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
            <p style={{ margin: 0 }}>{formatMoney(newPayment?.amount)}</p>
            <p style={{ margin: 0 }}>{newPayment?.checkNumber}</p>
          </div>
        </div>
      </div>

      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <div style={{ fontSize: 11 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
            <p style={{ margin: 0 }}>{vendorDetails?.name}</p>
            <p style={{ margin: 0 }}>{bill?.name}</p>
          </div>
        </div>
        <div style={{ fontSize: 11 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
            <p style={{ margin: 0 }}>{formatMoney(newPayment?.amount)}</p>
            <p style={{ margin: 0 }}>{newPayment?.checkNumber}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PreviewBill
