import { Box } from '@mui/material'
import moment from 'moment'
import { ReactInstance, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import api from '../../api/api'
import ButtonsFooter from '../../components/buttons-footer/buttons-footer'
import FooterInput from '../../components/footer/footer-desktop'
import FooterMobile from '../../components/footer/footer-mobile'
import HeaderCreate from '../../components/header/headerCreate'
import { AddItemButton } from '../../components/plus-button/add-item-button'
import ClearItemsButtons from '../../components/plus-button/clear-items-button'
import PreviewNew from '../../components/preview-new'
import TotalSection from '../../components/total-section/total-section'
import TotalSectionMobile from '../../components/total-section/total-section-mobile'
import { errorHandler } from '../../helpers/errorHandler'
import { formatPrice } from '../../helpers/formatPrice'
import { isUsingMobile } from '../../helpers/utils'
import {
  createNewEstimate,
  saveCertainCustomer,
  saveCertainEstimate,
  saveCustomers,
  saveProducts,
} from '../../redux/actions/home.actions'
import { AppState } from '../../redux/store'
import { ICustomer, IInvoiceProductList } from '../../types/types'
import CustomerManageDialog from './customer-manage-dialog'
import ManageSectionMobile from './estimage-manage-mobile'
import ManageSection from './manage-section'
import './estimate-manage.css'
import EstimateTable from './estimate-table'
import { successToastNotification } from '../../helpers/toastNotification'

const EstimateManage = () => {
  const navigate = useNavigate()
  const { publicId } = useParams()
  const dispatch = useDispatch()
  const preview = useRef<ReactInstance | null>(null)

  const chosenEstimate = useSelector((state: AppState) => state.homeReducer.chosenEstimate)
  const customers = useSelector((state: AppState) => state.homeReducer.customers)
  const chosenCustomer = useSelector((state: AppState) => state.homeReducer.chosenCustomer)
  const estimates = useSelector((state: AppState) => state.homeReducer.estimates)

  const products = useSelector((state: AppState) => state.homeReducer.products)
  const [isNewCustomerDialogOpen, setIsNewCustomerDialogOpen] = useState<boolean>(false)
  const [isProductDialogOpen, setIsProductDialogOpen] = useState<boolean>(false)
  const [customerItems, setCustomerItems] = useState<any>([])
  const [newEstimate, setNewEstimate] = useState<any>({
    name: '',
    customerPublicId: '',
    companyId: 0,
    estimateToProductList: [
      {
        product: {
          publicId: '',
        },
        description: '',
        quantity: 1,
        price: 0,
        amount: 0,
      },
    ],
    currency: 'USD',
    periodStart: moment().format('YYYY-MM-DD HH:mm'),
    periodEnd: moment().format('YYYY-MM-DD HH:mm'),
    subTotal: null,
    total: null,
    poso: null,
    footer: null,
    memo: null,
    type: null,
  })

  const subtotal = newEstimate.estimateToProductList
    .filter((letter: string) => letter)
    .reduce((acc: any, item: any, index: number) => {
      acc += item.price * item.quantity
      return acc
    }, 0)

  useEffect(() => {
    dispatch(saveProducts())
    dispatch(saveCustomers())
  }, [])

  useEffect(() => {
    if (publicId) {
      dispatch(saveCertainEstimate(publicId))
    }
  }, [publicId, estimates])

  useEffect(() => {
    if (publicId && chosenEstimate) {
      setNewEstimate(chosenEstimate)
    }
  }, [chosenEstimate])

  useEffect(() => {
    if (customers.length) {
      const menuItemsArray = customers.map((customer: ICustomer) => {
        return {
          value: customer.publicId,
          title: customer.name,
          key: customer.publicId,
        }
      })
      setCustomerItems(menuItemsArray)
    }
  }, [customers])

  const handleChangeFields = (e: any, value: string, isDate?: boolean) => {
    console.log('event', e.target)

    if (value === 'customerPublicId' && e.target.value) {
      dispatch(saveCertainCustomer(e.target.value))
    }

    setNewEstimate({
      ...newEstimate,
      [value]: isDate ? moment(e).format('YYYY-MM-DD HH:mm') : e.target.value,
    })
  }

  const handleChangeEstimateTable = (
    e: React.ChangeEvent<HTMLInputElement>,
    productIndex: number,
    value: string,
  ) => {
    const changedProductsList = [...newEstimate.estimateToProductList]
    if (value === 'productName') {
      const chosenProduct = products.find((product) => product.publicId === e.target.value)
      changedProductsList.splice(productIndex, 1, {
        ...newEstimate.estimateToProductList[productIndex],
        product: {
          publicId: chosenProduct?.publicId,
        },
        description: chosenProduct?.description,
        price: chosenProduct?.price,
      })
    } else {
      changedProductsList.splice(productIndex, 1, {
        ...newEstimate.estimateToProductList[productIndex],
        [value]: value === 'price' ? formatPrice(e.target.value) : e.target.value,
      })
    }
    setNewEstimate({
      ...newEstimate,
      estimateToProductList: changedProductsList,
    })
  }

  const handleAddLine = () => {
    setNewEstimate({
      ...newEstimate,
      estimateToProductList: [
        ...newEstimate.estimateToProductList,
        {
          productName: '',
          description: '',
          quantity: 1,
          price: 0,
          tax: '-',
        },
      ],
    })
  }

  const handleDeleteLine = (productIndex: number) => {
    setNewEstimate({
      ...newEstimate,
      estimateToProductList: newEstimate.estimateToProductList.filter(
        (item: IInvoiceProductList, itemIndex: number) => itemIndex !== productIndex,
      ),
    })
  }

  const handleSaveClick = () => {
    const incompleteEstimate = {
      ...newEstimate,
      total: subtotal,
      subTotal: subtotal,
      customerPublicId: currentPublicId,
    }
    if (publicId) {
      api
        .editEstimate(incompleteEstimate, chosenEstimate?.publicId)
        .then(() => {
          successToastNotification('Estimate was successfully edited')
          dispatch(saveCustomers())
          navigate('/estimates/')
        })
        .catch((err) => {
          errorHandler(err)
        })
    } else {
      dispatch(createNewEstimate(incompleteEstimate, navigate))
    }
  }

  const handleCustomerDialogSave = () => {
    setIsNewCustomerDialogOpen(false)
  }

  const currentPublicId = newEstimate.customerPublicId

  const handleClearAllItems = () => {
    setNewEstimate({
      ...newEstimate,
      estimateToProductList: [
        {
          product: {
            publicId: '',
          },
          description: '',
          quantity: 1,
          price: 0,
          amount: 0,
        },
      ],
    })
  }

  const handleCancel = () => {
    navigate(`/estimates`)
  }

  const handleDialogClose = () => {
    setIsProductDialogOpen(false)
  }

  const isMobile = isUsingMobile()

  const isDisabledSaveButton = useMemo(() => {
    const hasEmptyCustomer = !newEstimate.customerPublicId
    const hasEmptyPeriodStart = !newEstimate.periodStart
    const hasEmptyPeriodEnd = !newEstimate.periodEnd
    const isEmptyProductList = newEstimate.estimateToProductList.length === 0
    const hasEmptyProductPublicId = newEstimate.estimateToProductList.some(
      (product: any) =>
        !product.product || !product.product.publicId || !product.quantity || product.quantity <= 0 || !product.price,
    )
    const isWrongDates = newEstimate.periodEnd < newEstimate.periodStart

    return (
      hasEmptyCustomer ||
      hasEmptyPeriodStart ||
      hasEmptyPeriodEnd ||
      isEmptyProductList ||
      hasEmptyProductPublicId ||
      isWrongDates
    )
  }, [
    newEstimate.customerPublicId,
    newEstimate.periodStart,
    newEstimate.periodEnd,
    newEstimate.estimateToProductList,
    newEstimate.periodEnd,
    newEstimate.periodStart,
  ])

  return (
    <>
      <HeaderCreate
        title='Create Estimate'
        saveTextButton='Save'
        cancelTextButton='Cancel'
        handleCancel={handleCancel}
        handleAcceptClick={handleSaveClick}
        isDisabledSaveButton={isDisabledSaveButton}
      />
      <div className={!isMobile ? 'estimate-manage__content' : 'estimate-manage__content_mobile'}>
        <div className={!isMobile ? 'estimate-manage__create' : ''}>
          {!isMobile ? (
            <ManageSection
              handleDeleteLine={handleDeleteLine}
              customerItems={customerItems}
              currentPublicId={currentPublicId}
              handleChangeFields={handleChangeFields}
              setIsNewCustomerDialogOpen={setIsNewCustomerDialogOpen}
              newEstimate={newEstimate}
              chosenCustomer={chosenCustomer}
              publicId={publicId}
              subtotal={subtotal}
              setIsProductDialogOpen={setIsProductDialogOpen}
              isProductDialogOpen={isProductDialogOpen}
              handleDialogClose={handleDialogClose}
              startDate={newEstimate.periodStart}
              endDate={newEstimate.periodEnd}
              table={
                <EstimateTable
                  productsList={newEstimate?.estimateToProductList}
                  onChange={handleChangeEstimateTable}
                  handleDeleteLine={handleDeleteLine}
                  setIsProductDialogOpen={setIsProductDialogOpen}
                  isProductDialogOpen={isProductDialogOpen}
                  handleDialogClose={handleDialogClose}
                />
              }
            />
          ) : (
            <ManageSectionMobile
              handleDeleteLine={handleDeleteLine}
              customerItems={customerItems}
              currentPublicId={currentPublicId}
              handleChangeFields={handleChangeFields}
              setIsNewCustomerDialogOpen={setIsNewCustomerDialogOpen}
              newEstimate={newEstimate}
              chosenCustomer={chosenCustomer}
              publicId={publicId}
              subtotal={subtotal}
              setIsProductDialogOpen={setIsProductDialogOpen}
              isProductDialogOpen={isProductDialogOpen}
              handleDialogClose={handleDialogClose}
              table={
                <EstimateTable
                  productsList={newEstimate?.estimateToProductList}
                  onChange={handleChangeEstimateTable}
                  handleDeleteLine={handleDeleteLine}
                  setIsProductDialogOpen={setIsProductDialogOpen}
                  isProductDialogOpen={isProductDialogOpen}
                  handleDialogClose={handleDialogClose}
                />
              }
            />
          )}

          <div
            className={
              isMobile ? 'estimate-manage__buttons-mobile' : 'estimate-manage__table-buttons'
            }
          >
            <Box mr='10px'>
              <AddItemButton
                handleOnClick={handleAddLine}
                text='Add a line'
                sx={{ marginRight: '10px' }}
              ></AddItemButton>
            </Box>
            <ClearItemsButtons
              handleOnClick={handleClearAllItems}
              text='Clear all items'
            ></ClearItemsButtons>
          </div>
          {!isMobile && (
            <div className='estimate-manage__padding'>
              <div className='divider estimate-manage__divider'></div>
            </div>
          )}
          {isMobile ? (
            <TotalSectionMobile subtotal={subtotal} />
          ) : (
            <TotalSection subtotal={subtotal} />
          )}
          {!isMobile && (
            <div className='estimate-manage__padding'>
              <div className='divider estimate-manage__divider'></div>
            </div>
          )}
          {isMobile ? (
            <FooterMobile
              footer={newEstimate.footer}
              handleOnChange={handleChangeFields}
              placeholderText='Enter a footer for current estimates (some discount, tax, thank you notes)'
            />
          ) : (
            <FooterInput
              footer={newEstimate.footer}
              handleOnChange={handleChangeFields}
              placeholderText='Enter a footer for current estimates (some discount, tax, thank you notes)'
            />
          )}
          {isMobile && (
            <ButtonsFooter
              saveTextButton='Save'
              cancelTextButton='Cancel'
              handleCancel={handleCancel}
              handleAcceptClick={handleSaveClick}
              isDisabledSaveButton={isDisabledSaveButton}
            />
          )}
        </div>
      </div>
      <div style={{ display: 'none' }}>
        <PreviewNew item={chosenEstimate} preview={preview} customer={chosenCustomer} />
      </div>
      <CustomerManageDialog
        open={isNewCustomerDialogOpen}
        onClose={() => handleCustomerDialogSave()}
      />
    </>
  )
}

export default EstimateManage
