import React, { useMemo } from 'react'

import { IPermission } from '../../../../types/types'
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRowSpacingParams,
  GridTreeNode,
} from '@mui/x-data-grid'
import DeleteButtonTable from '../../../../components/delete-icon/deleteIcon'
import { isUsingMobile } from '../../../../helpers/utils'
import TableStyles from '../../../../components/table-card/table-styles'
import useRootState from '../../../../helpers/useRootAuthorization'

export interface IRole {
  publicId: string
  name: string
  description: string
  isDefault: boolean
  permissions: IPermission[]
}

interface RolesTableProps {
  roles: IRole[]
  onEditClick: (role: IRole) => void
  onDeleteClick: (evt: Event, publicId: string, roleName: string) => void
}

const RolesTable: React.FC<RolesTableProps> = ({ roles, onEditClick, onDeleteClick }) => {
  const isMobile = isUsingMobile()
  const { isRootAccount } = useRootState()

  const columns = useMemo<GridColDef<IRole>[]>(
    () => [
      { field: 'name', headerName: 'Name', flex: 3, sortable: false },
      {
        field: 'description',
        headerName: 'Description',
        flex: isMobile ? 4 : 5,
        sortable: false,
      },
      {
        field: 'permissions',
        headerName: 'Permissions',
        flex: 2,
        sortable: false,
        valueGetter: (param) => param.value.length,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: isMobile ? 3 : 2,
        sortable: false,
        renderCell: (param) => {
          if (!param.row.isDefault) {
            return (
              <DeleteButtonTable
                handleOnDeleted={(evt: Event) =>
                  onDeleteClick(evt, param.row.publicId, param.row.name)
                }
              />
            )
          }

          if (isRootAccount()) {
            return (
              <DeleteButtonTable
                handleOnDeleted={(evt: Event) =>
                  onDeleteClick(evt, param.row.publicId, param.row.name)
                }
              />
            )
          }
        },
      },
    ],
    [onDeleteClick],
  )

  const getRowSpacing = React.useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: 5,
    }
  }, [])

  return (
    <DataGrid
      rows={roles}
      columns={columns}
      disableColumnMenu
      getRowId={(role) => role.publicId}
      onRowClick={(params) => onEditClick(params.row)}
      hideFooter
      getRowSpacing={getRowSpacing}
      rowHeight={65}
      getCellClassName={(params: GridCellParams<any, IRole, IRole, GridTreeNode>) => {
        return params.field !== 'actions' ? 'rowStyle' : ''
      }}
      sx={TableStyles}
    />
  )
}

export default RolesTable
