import { Box, Card, Grid, TextField, Typography } from '@mui/material'
import { noop } from 'lodash'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import DeleteButtonTable from '../../../components/delete-icon/deleteIcon'
import SelectorMui from '../../../components/selector-mui'
import { formatPrice, thousandPrice } from '../../../helpers/formatPrice'
import { isUsingMobile } from '../../../helpers/utils'
import { AppState } from '../../../redux/store'
import { IProduct } from '../../../types/types'
import ProductManageDialog from '../../product-manage-dialog'
import './estimate-table.css'
import useAuthorization from '../../../helpers/useAuthorization'

interface ITableBodyRow {
  product: any
  productIndex: number
  onChange?: any
  handleDeleteLine: any
  handleDialogClose?: () => void
  setIsProductDialogOpen: (open: boolean) => void | undefined
}

interface IEstimateTable {
  productsList: any
  onChange?: any
  handleDeleteLine: any
  isProductDialogOpen?: boolean
  handleDialogClose?: () => void
  setIsProductDialogOpen: (open: boolean) => void
}

const TableBodyRow = (props: ITableBodyRow) => {
  const { product, productIndex, onChange, handleDeleteLine, setIsProductDialogOpen } = props
  const { isAuthorized } = useAuthorization()
  const products = useSelector((state: AppState) => state.homeReducer.products)
  const [productsItems, setProductsItems] = useState<any>([])

  useEffect(() => {
    if (products.length) {
      const menuItemsArray = products.map((product) => {
        return { value: product.publicId, title: product.name }
      })
      setProductsItems(menuItemsArray)
    }
  }, [products])

  const amount = product.quantity * Number(formatPrice(product.price))

  return (
    <>
      <tr>
        <td className='estimate-table__body-cell'>
          <SelectorMui
            isAddButton={isAuthorized('write_product')}
            addButtonText='Add new product'
            options={productsItems}
            value={product?.product?.publicId}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange(e, productIndex, 'productName')
            }
            onAddIconClick={() => setIsProductDialogOpen(true)}
            placeholder='Add new product'
            label='Add new product'
            name='products-table-selector'
            fullWidth
          />
        </td>
        <td className='estimate-table__body-cell'>
          <TextField
            sx={{ color: 'var--gray3-color', width: '100%' }}
            InputProps={{
              sx: {
                '& fieldset': {
                  borderColor: 'var(--secondary-grey)',
                  fontSize: '14px',
                },
              },
            }}
            label='Enter a description'
            value={product.footer}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange(e, productIndex, 'description')
            }
          />
        </td>
        <td className='estimate-table__body-cell' width='100px'>
          <TextField
            sx={{ color: 'var--gray3-color', width: '100%' }}
            type='number'
            InputProps={{
              sx: {
                '& fieldset': {
                  borderColor: 'var(--secondary-grey)',
                  fontSize: '14px',
                },
              },
            }}
            value={product.quantity}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange(e, productIndex, 'quantity')
            }
          />
        </td>
        <td className='estimate-table__body-cell' width='100px'>
          <TextField
            sx={{ color: 'var--gray3-color', width: '100%' }}
            type='number'
            InputProps={{
              sx: {
                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                  color: 'var--gray3-color',
                },
                '& fieldset': {
                  borderColor: 'var(--secondary-grey)',
                  fontSize: '14px',
                },
              },
            }}
            placeholder='$0.00'
            value={product.price}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.preventDefault()
              e.stopPropagation()
              onChange(e, productIndex, 'price')
            }}
          />
        </td>
        <td className='estimate-table__body-cell estimate-table__gray-cell'>
          {`$ ${amount ? thousandPrice(amount) : '0.00'}`}
        </td>
        <td className='estimate-table__body-cell '>
          <DeleteButtonTable
            handleOnDeleted={handleDeleteLine.bind(null, productIndex)}
          ></DeleteButtonTable>
        </td>
      </tr>
    </>
  )
}

const TableDesktopVersion = ({
  productsList,
  onChange,
  handleDeleteLine,
  setIsProductDialogOpen,
}: IEstimateTable) => {
  return (
    <div style={{ width: '100%' }}>
      <table className='estimate-table'>
        <thead>
          <tr className='estimates-table__head'>
            <td className='estimate-table__cell' width='30%'>
              Item<span className='estimates__required-star'>*</span>
            </td>
            <td className='estimate-table__cell' width='40%'>
              Description
            </td>
            <td className='estimate-table__cell'>Quantity</td>
            <td className='estimate-table__cell'>Price</td>
            <td className='estimate-table__cell'>Amount</td>
            <td className='estimate-table__cell'></td>
          </tr>
        </thead>
        <tbody className='estimates-list-table__tbody'>
          {productsList.map((product: IProduct, productIndex: number) => {
            return (
              <TableBodyRow
                product={product}
                key={productIndex}
                productIndex={productIndex}
                onChange={onChange}
                handleDeleteLine={handleDeleteLine}
                setIsProductDialogOpen={setIsProductDialogOpen}
              />
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const EstimateProductRow = (props: ITableBodyRow) => {
  const { product, productIndex, onChange, handleDeleteLine, setIsProductDialogOpen } = props
  const { isAuthorized } = useAuthorization()
  const products = useSelector((state: AppState) => state.homeReducer.products)
  const [productsItems, setProductsItems] = useState<any>([])

  useEffect(() => {
    if (products.length) {
      const menuItemsArray = products.map((product) => {
        return { value: product.publicId, title: product.name }
      })
      setProductsItems(menuItemsArray)
    }
  }, [products])

  const amount = product.quantity * Number(formatPrice(product.price))

  return (
    <Card
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px',
        paddingBottom: '20px',
        paddingTop: '20px',
        boxShadow: '0px 5px 20px rgba(70, 101, 137, 0.35)',
      }}
    >
      <Grid color={'var(--grey-fonts)'} flexDirection='column' margin='20px'>
        <SelectorMui
          sx={{ marginBottom: '20px' }}
          isAddButton={isAuthorized('write_product')}
          addButtonText='Add new product'
          options={productsItems}
          value={product?.product?.publicId}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange(e, productIndex, 'productName')
          }
          onAddIconClick={() => setIsProductDialogOpen(true)}
          placeholder='Add new product'
          label='Add new product'
          name='products-table-selector'
        />
        <TextField
          sx={{ color: 'var(--gray3-color)', width: '100%', marginBottom: '20px' }}
          InputProps={{
            sx: {
              '& fieldset': {
                borderColor: 'var(--secondary-grey)',
              },
            },
          }}
          label='Description'
          value={product.footer}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange(e, productIndex, 'description')
          }
        />
        <Box display='flex'>
          <TextField
            sx={{
              color: 'var(--gray3-color)',
              width: '100%',
              marginBottom: '20px',
              marginRight: '20px',
            }}
            type='number'
            label='Quantity'
            InputProps={{
              sx: {
                '& fieldset': {
                  borderColor: 'var(--secondary-grey)',
                },
              },
            }}
            defaultValue={1}
            value={product.quantity}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange(e, productIndex, 'quantity')
            }
          />
          <TextField
            sx={{ color: 'var(--gray3-color)', width: '100%', marginBottom: '20px' }}
            type='number'
            label='Price'
            InputProps={{
              sx: {
                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                  color: 'var(--gray3-color)',
                },
                '& fieldset': {
                  borderColor: 'var(--secondary-grey)',
                },
              },
            }}
            placeholder='$0.00'
            value={product.price}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.preventDefault()
              e.stopPropagation()
              onChange(e, productIndex, 'price')
            }}
          />
        </Box>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography>{`Amount $ ${amount ? thousandPrice(amount) : '0.00'}`}</Typography>
          <DeleteButtonTable
            handleOnDeleted={handleDeleteLine.bind(null, productIndex)}
          ></DeleteButtonTable>
        </Box>
      </Grid>
    </Card>
  )
}

const TableMobileVersion = ({
  productsList,
  onChange,
  handleDeleteLine,
  setIsProductDialogOpen,
}: IEstimateTable) => {
  return (
    <>
      {productsList.map((product: IProduct, productIndex: number) => (
        <EstimateProductRow
          product={product}
          key={productIndex}
          productIndex={productIndex}
          onChange={onChange}
          handleDeleteLine={handleDeleteLine}
          setIsProductDialogOpen={setIsProductDialogOpen}
        />
      ))}
    </>
  )
}

const EstimateTable = (props: IEstimateTable) => {
  const {
    productsList,
    onChange,
    handleDeleteLine,
    isProductDialogOpen = false,
    handleDialogClose,
    setIsProductDialogOpen,
  } = props
  const isMobile = isUsingMobile()

  return (
    <>
      {isMobile ? (
        <TableMobileVersion
          productsList={productsList}
          onChange={onChange}
          handleDeleteLine={handleDeleteLine}
          setIsProductDialogOpen={setIsProductDialogOpen}
        />
      ) : (
        <TableDesktopVersion
          productsList={productsList}
          onChange={onChange}
          handleDeleteLine={handleDeleteLine}
          setIsProductDialogOpen={setIsProductDialogOpen}
        />
      )}
      <ProductManageDialog open={isProductDialogOpen} onClose={handleDialogClose || noop} />
    </>
  )
}

export default EstimateTable
