import { Card, Divider, Grid, Typography } from '@mui/material'
import { formatMoney } from '../../../helpers/formatPrice'
import { IPayment } from '../../../types/types'

interface PaymentCardProps {
  payment: IPayment
}

const PaymentCard = ({ payment }: PaymentCardProps) => {
  return (
    <Card sx={{ px: 2, m: 2 }}>
      <Grid container my={2}>
        <Grid item flexGrow={1}>
          <Typography fontWeight='bold'>Amount</Typography>
        </Grid>
        <Grid item>
          <Typography>{formatMoney(payment.amount)}</Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container my={2}>
        <Grid item flexGrow={1}>
          <Typography fontWeight='bold' color='var(--success-color)'>
            Received
          </Typography>
        </Grid>
        <Grid item>
          <Typography fontWeight='bold'>{formatMoney(payment.amount)}</Typography>
        </Grid>
      </Grid>
    </Card>
  )
}

export default PaymentCard
