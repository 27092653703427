import { Backdrop, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { getIsLoading } from '../../redux/selectors/homeSelector';
import { AppState } from '../../redux/store';

const Loader = () => {
	const isLoading = useSelector((state: AppState) => getIsLoading(state));
	return (
		<Backdrop
			sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
			open={isLoading}
		>
			<CircularProgress color="inherit" />
		</Backdrop>
	);
};

export default Loader;
