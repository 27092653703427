import { Box, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NoDataDisplay from '../../components/noDataDisplay/noDataDisplay'
import PlusButton from '../../components/plus-button/plus-button'
import plusImage from '../../assets/images/plus.png'
import {
  saveDashboardCashInflow,
  saveDashboardTotalRevenue,
  saveOpenInvoices,
  saveOverdueInvoices,
  saveOverdueStatistic,
} from '../../redux/actions/dashboard.actions'
import { saveCustomersReportDetailed } from '../../redux/actions/report.actions'
import { AppState } from '../../redux/store'
import './dashboard.css'
import CashFlowCard from './cash-flow-card'
import DashboardKpis from './kpis'
import OverdueInvoicesByCustomer from './overdue-invoices-by-customer'
import DashboardInvoices from './invoices'
import DashboardOptions from './options'

function useClickOutside(ref: any, onClickOutside: any) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, onClickOutside])
}

const Dashboard = () => {
  const [showDashboardOptions, setShowDashboardOptions] = useState(false)
  const ref = useRef()
  const dispatch = useDispatch()
  const overdueInvoices = useSelector((state: AppState) => state.dashboardReducer.overdueInvoices)
  const openInvoices = useSelector((state: AppState) => state.dashboardReducer.openInvoices)
  const overdueStatistics = useSelector(
    (state: AppState) => state.dashboardReducer.overdueStatistic,
  )
  const user = useSelector((state: AppState) => state.authReducer.user)
  const totalRevenue = useSelector((state: AppState) => state.dashboardReducer.totalRevenue)

  const company = useSelector((state: AppState) => state.homeReducer.company)

  const totalAmount =
    overdueStatistics.length &&
    overdueStatistics.reduce((acc: number, data: any) => acc + data?.customerAmount, 0).toFixed(2)

  const getFirstName = (name: string) => {
    return name.split(' ').shift()
  }

  const userFirstName = getFirstName(user.name)

  useClickOutside(ref, () => setShowDashboardOptions(false))

  useEffect(() => {
    if (company) {
      dispatch(saveOverdueInvoices())
      dispatch(saveOpenInvoices())
      dispatch(saveOverdueStatistic())
      dispatch(saveDashboardTotalRevenue())
      dispatch(saveDashboardCashInflow())
      dispatch(saveCustomersReportDetailed())
    }
  }, [company])

  const handleGoToClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setShowDashboardOptions(true)
  }

  const hasNoValues =
    totalAmount === '0.00' &&
    totalRevenue?.totalRevenue === 0 &&
    overdueInvoices.length === 0 &&
    openInvoices.length === 0

  return (
    <>
      <Box>
        <Typography color='var(--secondary-grey)' variant='h4' fontWeight='600'>
          Dashboard
        </Typography>
        <Typography
          color='var(--grey-fonts)'
          variant='h6'
          fontWeight='600'
        >{`Hello ${userFirstName}, here's your most recent activity.`}</Typography>
      </Box>

      {hasNoValues ? (
        <NoDataDisplay
          mt='10rem'
          title={
            <Box>
              To get started, you should first add a product or
              <br />
              create a customer with the{' '}
              <img
                src={plusImage}
                alt='Plus Icon'
                style={{
                  marginLeft: '1px',
                  marginRight: '1px',
                  verticalAlign: 'middle',
                  width: '22px',
                }}
              />{' '}
              button below.
              <br />
              Afterward, proceed to create an invoice.
            </Box>
          }
        />
      ) : (
        <Box>
          <DashboardKpis totalAmount={Number(totalAmount)} />
          <CashFlowCard />
          <OverdueInvoicesByCustomer totalAmount={Number(totalAmount)} />
          <DashboardInvoices />
        </Box>
      )}

      <Box ref={ref} display='flex' justifyContent='flex-end'>
        {showDashboardOptions && <DashboardOptions />}

        <PlusButton
          handleOnClick={(e: React.ChangeEvent<HTMLInputElement>) => handleGoToClick(e)}
        />
      </Box>
    </>
  )
}

export default Dashboard
