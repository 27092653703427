import { IMccType } from '../../types/types';
import { Action } from '../actions/actionTypes';
import {
	SAVE_USER,
	SAVE_ACCESS_TOKEN,
	SAVE_ALL_USERS,
	SAVE_MCC_TYPES,
	SAVE_USIO_API_KEY,
	SHOW_USIO_NOTIFICATION,
	IS_COMPANY_LOADED,
	HAVE_SUBSCRIPTION,
	SUBSCRIPTION_DATA,
	REFERRALS_DATA,
	SAVE_FAQ,
	SAVE_USIO_LINK,
	SAVE_CURRENT_USER,
	IS_ONBOARDING,
	IS_COMPLETE_BUSINESS,
} from '../actions/auth.actions.type';

export interface IInitialState {
	user: any;
	accessToken: string;
	users: any;
	mccTypes: IMccType[];
	usioApiKey: string;
	showUSIONotification: boolean;
	isCompanyLoaded: boolean;
	haveSubscription: boolean;
	usioLink: string;
	cheddarUser: any;
	isOnBoarding: boolean;
	isCompleteBusiness: boolean;
	subscriptionData: any;
	referralsData: any;
	faqData: any;
}

const initialState: IInitialState = {
	user: {},
	accessToken: '',
	users: [],
	mccTypes: [],
	usioApiKey: '',
	showUSIONotification: true,
	isCompanyLoaded: false,
	haveSubscription: false,
	usioLink: '',
	cheddarUser: null,
	isOnBoarding: true,
	isCompleteBusiness: false,
	subscriptionData: {},
	referralsData: {},
	faqData: {},
};

const authReducer = (state = initialState, action: Action) => {
	switch (action.type) {
		case SAVE_USER:
			return {
				...state,
				user: action.payload,
			};
		case SAVE_ACCESS_TOKEN:
			return {
				...state,
				accessToken: action.payload,
			};
		case SAVE_ALL_USERS:
			return {
				...state,
				users: action.payload,
			};
		case SAVE_MCC_TYPES:
			return {
				...state,
				mccTypes: action.payload,
			};
		case SAVE_USIO_API_KEY:
			return {
				...state,
				usioApiKey: action.payload,
			};
		case SHOW_USIO_NOTIFICATION:
			return {
				...state,
				showUSIONotification: action.payload,
			};
		case IS_COMPANY_LOADED:
			return {
				...state,
				isCompanyLoaded: action.payload,
			};
		case HAVE_SUBSCRIPTION:
			return {
				...state,
				haveSubscription: action.payload,
			};
			case SUBSCRIPTION_DATA:
				return {
					...state,
					subscriptionData: action.payload,
				};
			case REFERRALS_DATA:
				return {
						...state,
						referralsData: action.payload,
					};
		case SAVE_FAQ:
				return {
					...state,
					faqData: action.payload,
				};
		case SAVE_USIO_LINK:
			return {
				...state,
				usioLink: action.payload,
			};
		case SAVE_CURRENT_USER:
			return {
				...state,
				cheddarUser: action.payload,
			};
		case IS_ONBOARDING:
			return {
				...state,
				isOnBoarding: action.payload,
			};
		case IS_COMPLETE_BUSINESS:
			return {
				...state,
				isCompleteBusiness: action.payload,
			};
		default:
			return state;
	}
};

export default authReducer;
