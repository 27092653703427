import { Card, Typography } from "@mui/material"
import { thousandPrice } from "../../helpers/formatPrice"

interface ITotalSection {
  subtotal?: any;
}

const TotalSectionMobile = ({subtotal}: ITotalSection) => {
  return (
    <Card sx={{
      width: '100%',
      boxShadow: '0px 5px 20px rgba(70, 101, 137, 0.35)',
      paddingTop: '20px',
      marginBottom: '20px',
      marginTop: '20px'
    }}>
      <div className="estimate-manage__root-amount">
				<div className="estimate-manage__total-sum-block">
					<div className="estimate-manage__amount-item">
					<Typography color='var(--secondary-grey)'>Subtotal</Typography>
						<div className="estimate-manage__amount-value">
							{`$ ${thousandPrice(subtotal)}`}
						</div>
					</div>
					<div className="estimate-manage__amount-item">
						<Typography fontWeight={600} color='var(--secondary-grey)'>Estimated Total</Typography>
						<div
							className="estimate-manage__amount-value 
						estimate-manage__bold-text"
						>
							{`$ ${thousandPrice(subtotal)}`}
						</div>
					</div>
				</div>
			</div>
    </Card>
    
  )
}

export default TotalSectionMobile
