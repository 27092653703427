import { Box, Typography } from '@mui/material'
import noData from '../../assets/images/noData.svg'
import './noDataDisplay.css'

interface INodataDisplay {
  title: string | any
  description?: string
  mt?: number | string
}

const NoDataDisplay = (props: INodataDisplay) => {
  const { title, description, mt } = props

  return (
    <Box className='container' mt={mt}>
      <img style={{ width: 325, height: 250 }} src={noData} alt={''} loading='lazy' />
      <Typography className='title-noData' gutterBottom>
        {title}
      </Typography>
      {description && (
        <Typography
          className='description-noData'
          variant='subtitle1'
          gutterBottom
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
    </Box>
  )
}

export default NoDataDisplay
