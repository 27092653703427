import { Card, CardContent, Typography } from '@mui/material'
import { formatDate } from '../../../helpers/formatPrice'
import { IInvoice, IScheduledInvoice } from '../../../types/types'

interface CustomerCardProps {
  invoice: IInvoice | IScheduledInvoice
}

const CustomerCard = ({ invoice }: CustomerCardProps) => {
  return (
    <Card
      sx={{
        mb: 1,
        color: 'var(--secondary-grey)',
        '& .MuiTypography-root': { fontSize: '14px' },
      }}
    >
      <CardContent>
        <Typography>Invoiced to</Typography>
        <Typography mb={1} color='var(--grey-fonts)'>
          {invoice.customerName}
        </Typography>
        <Typography>Invoice ID</Typography>
        <Typography mb={1} color='var(--grey-fonts)'>
          {'name' in invoice ? invoice.name : 'Auto-generated'}
        </Typography>
        <Typography>P.O./S.O. Number</Typography>
        <Typography mb={1} color='var(--grey-fonts)'>
          {invoice.poso}
        </Typography>
        {
          <>
            <Typography>Invoice Date</Typography>
            <Typography mb={1} color='var(--grey-fonts)'>
              {'periodStart' in invoice ? formatDate(invoice.periodStart) : 'Auto-generated'}
            </Typography>
          </>
        }
        {(
          <>
            <Typography>Expiration Date</Typography>
            <Typography color='var(--grey-fonts)'>
              {'periodEnd' in invoice ? formatDate(invoice.periodEnd) : 'Auto-generated'}
            </Typography>
          </>
        )}
      </CardContent>
    </Card>
  )
}

export default CustomerCard
