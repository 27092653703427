import { Button, Grid, Box, TextField } from '@mui/material'
import Card from '@mui/material/Card'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
import api from '../../api/api'
import CheckSolo from '../../assets/icons/check-solo.png'
import BaseModal from '../../components/modal/modal'
import { errorHandler, generalErrorHandler } from '../../helpers/errorHandler'
import { isFormValid, isUsingMobile } from '../../helpers/utils'
import { saveAccountSubscription } from '../../redux/actions/home.actions'
import { AppState } from '../../redux/store'
import { ICardCustomerInformation, INewCreditCard, IProduct } from '../../types/types'
import CardHoldInformations from '../invoices/record-payment/cardHoldInformations'
import NewCreditCardForm from '../invoices/record-payment/newCreditCardForm'
import './subscribe-dialog.css'

interface ISubscribeDialog {
  open: boolean
  product?: IProduct
  onClose: () => void
  setIsSubscribeDialogOpen: (value: boolean) => void
}

interface IFooter {
  handleAcceptClick: () => void
  isDisabled?: boolean
}

const Footer = ({ handleAcceptClick, isDisabled }: IFooter) => {
  return (
    <Grid container>
      <Grid
        container
        justifyContent={{ xs: 'normal', md: 'right' }}
        alignItems={{ xs: 'normal', md: 'center' }}
        flexDirection={{ xs: 'column-reverse', md: 'row' }}
      >
        <Grid item padding={{ xs: '1rem 1rem 0 1rem', md: '1rem 2rem' }}>
          <Button
            color='primary'
            variant='contained'
            onClick={handleAcceptClick}
            fullWidth
            disabled={isDisabled}
          >
            Subscribe
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

const SubscribeDialog = ({ open, onClose, setIsSubscribeDialogOpen }: ISubscribeDialog) => {
  const dispatch = useDispatch()
  const [hasEmptyValues, setHasEmptyValues] = useState(false)

  const [newCreditCard, setNewCreditCard] = useState<INewCreditCard>({
    number: '',
    expirationDate: '',
    cvv: '',
    name: '',
    saveCard: false,
    customerPublicId: '',
  })

  const [customerCardInfo, setCustomerCardInfo] = useState<ICardCustomerInformation>({
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    referral: '',
  })

  const [formErrors, setFormErrors] = useState<any>({
    cardId: '',
  })

  const authReducer = useSelector((state: AppState) => state.authReducer)

  const handleAcceptClick = async () => {
    try {
      const expDate = `${newCreditCard.expirationDate.slice(
        0,
        2,
      )}20${newCreditCard.expirationDate.slice(3)}`

      const usioRequest = {
        merchantKey: process.env.REACT_APP_MERCHANT_KEY,
        paymentType: 'CreditCard',
        emailAddress: authReducer.user.email,
        cardNumber: newCreditCard.number.split(' ').join(''),
        expDate,
        cvv: newCreditCard.cvv,
      }

      const usioResponse = await api.generateUSIOToken(usioRequest)

      if (usioResponse.data.status === 'failure') {
        if (usioResponse.data.message.includes('5011')) {
          generalErrorHandler('Card not valid. Please try another one.')
        } else {
          generalErrorHandler(usioResponse.data.message)
        }
      } else {
        const accountSubscriptionBody = {
          subscriptionPlanId: process.env.REACT_APP_SUBSCRIPTION_PLAN_ID,
          creditCard: {
            cardType: usioResponse.data.cardBrand,
            expirationDate: usioResponse.data.expDate,
            lastDigits: usioResponse.data.last4,
            name: newCreditCard.name,
            cardToken: usioResponse.data.token,
          },
          firstName: customerCardInfo.firstName,
          lastName: customerCardInfo.lastName,
          billingAddress: customerCardInfo.address1,
          billingAddressTwo: customerCardInfo.address2,
          billingCity: customerCardInfo.city,
          billingState: customerCardInfo.state,
          billingPostalCode: customerCardInfo.zip,
          referralCode: customerCardInfo.referral,
        }
        await api.createAccountSubscription(accountSubscriptionBody)
        dispatch(saveAccountSubscription())
      }
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsSubscribeDialogOpen(false)
    }
  }

  const resetErrors = () => {
    setFormErrors({ name: '', price: '' })
  }

  const handleCancel = () => {
    resetErrors()
    onClose()
  }

  useEffect(() => {
    setHasEmptyValues(
      !customerCardInfo.firstName ||
        !customerCardInfo.lastName ||
        !customerCardInfo.address1 ||
        !customerCardInfo.city ||
        !customerCardInfo.state ||
        !customerCardInfo.zip ||
        !newCreditCard.name ||
        !newCreditCard.expirationDate ||
        !newCreditCard.cvv ||
        !newCreditCard.number,
    )
  }, [
    customerCardInfo.firstName,
    customerCardInfo.lastName,
    customerCardInfo.address1,
    customerCardInfo.city,
    customerCardInfo.state,
    customerCardInfo.zip,
    newCreditCard.name,
    newCreditCard.expirationDate,
    newCreditCard.cvv,
    newCreditCard.number,
  ])

  return (
    <BaseModal
      size={isUsingMobile() ? 'xs' : 'small'}
      title={'Monthly Subscription'}
      body={
        <div style={{ margin: '0 20px' }}>
          <Card variant='outlined' style={{ marginTop: '30px' }}>
            <div className='card-container'>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'baseline',
                  marginTop: '20px',
                  marginBottom: '40px',
                }}
              >
                <div className='price'>$1.99</div>
                <div className='period'>/month</div>
              </div>
              <div className='check-container'>
                <img
                  style={{ height: '24px', width: '24px', marginRight: '3px' }}
                  src={CheckSolo}
                />
                <div className='detail-info'>
                  Every month you will receive an invoice to the indicated email with monthly
                  payment you need to confirm{' '}
                </div>
              </div>
              <div className='check-container'>
                <img
                  style={{ height: '24px', width: '24px', marginRight: '3px' }}
                  src={CheckSolo}
                />
                <div className='detail-info'>
                  You have unlimited access to all functionality (invoices, estimates, payments,
                  reports, purchases, etc.){' '}
                </div>
              </div>
            </div>
          </Card>
          <div className='subtitle-info'>Referral code</div>
          <Box mt={2}>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  label='Referral code'
                  fullWidth
                  value={customerCardInfo.referral}
                  inputProps={{ inputMode: 'text'}}
                  placeholder='Enter the referral code if you received it in your email'
                  sx={{ mb: 2 }}
                  InputLabelProps={{ shrink: true }}
                  error={!!formErrors['referral']}
                  helperText={formErrors['referral']}
                  onChange={(e) => {
                    setCustomerCardInfo({ ...customerCardInfo, referral: e.target.value })
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <div className='subtitle-info'>Card holder information</div>
          <CardHoldInformations
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            setCustomerCardInfo={setCustomerCardInfo}
            customerCardInfo={customerCardInfo}
          />

          <div className='subtitle-info'>Card details</div>
          <NewCreditCardForm
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            creditCard={newCreditCard}
            setCreditCard={setNewCreditCard}
            showSaveFuturePayments={false}
          />
        </div>
      }
      isModalOpen={open}
      handleCloseModal={handleCancel}
      footer={
        <Footer
          handleAcceptClick={handleAcceptClick}
          isDisabled={!isFormValid(formErrors) || hasEmptyValues}
        />
      }
    />
  )
}

export default SubscribeDialog
