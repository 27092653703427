import {
  FormControl,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { Box } from '@mui/system'
import { ICreditCard, ICustomer, IFinancialAccount, INewCreditCard, INewPayment } from '../../../types/types'
import { ChangeEvent, ReactNode, useEffect, useState } from 'react'
import SelectorMui from '../../../components/selector-mui'
import NewCreditCardForm from './newCreditCardForm'
import { validateMinValue } from '../../../helpers/utils'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../redux/store'
import { saveFinancialAccount } from '../../../redux/actions/home.actions'
import DateField from '../../../components/date-field'

interface AutoPaymentFormProps {
  customer: ICustomer
  onChangeSelectedCard?: (e: SelectChangeEvent, child: ReactNode) => void
  onChangeAmount: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChangeNewCreditCard: (newCreditCard: INewCreditCard | undefined) => void
  payment: INewPayment
  formErrors: any
  setFormErrors: (formErrors: any) => void
  onChangeDate: any
  onChangeAccount?: (e: SelectChangeEvent<any>, child: ReactNode) => void
}

const AutoPaymentForm = ({
  customer,
  onChangeSelectedCard,
  payment,
  onChangeNewCreditCard,
  onChangeAmount,
  onChangeDate,
  onChangeAccount,
  formErrors,
  setFormErrors,
}: AutoPaymentFormProps) => {
  const dispatch = useDispatch()
  const [isNewCard, setIsNewCard] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [options, setOptions] = useState<any[]>([])

  const financialAccount = useSelector((state: AppState) => state.homeReducer.financialAccount)

  useEffect(() => {
    dispatch(saveFinancialAccount())
  }, [dispatch])

  useEffect(() => {
    if (financialAccount && financialAccount.content) {
      const categorizedAccounts = financialAccount.content
        .filter((account: IFinancialAccount) => account.type?.name === 'Cash')
        .reduce((acc: any, account: any) => {
          if (!acc[account.type.category]) {
            acc[account.type.category] = []
          }
          acc[account.type.category].push(account)
          return acc
        }, {})

      const newOptions = Object.entries(categorizedAccounts).map(([category, accounts]) => ({
        label: category,
        options: Array.isArray(accounts)
          ? accounts.map((acc) => ({
              value: acc.publicId,
              label: acc.name,
            }))
          : [],
      }))

      setOptions(newOptions)
      setIsLoading(false)
    }
  }, [financialAccount])

  useEffect(() => {
    if (isNewCard) {
      onChangeNewCreditCard({
        number: '',
        expirationDate: '',
        cvv: '',
        name: '',
        saveCard: true,
        customerPublicId: customer.publicId,
      })
      setFormErrors({
        number: '',
        expirationDate: '',
        cvv: '',
        name: '',
      })
    } else {
      onChangeNewCreditCard(undefined)
      setFormErrors({ cardId: '' })
    }
  }, [isNewCard])

  return (
    <Box m={3}>
      <TextField
        label='Invoice amount'
        type='number'
        fullWidth
        value={payment.amount}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          validateMinValue(payment.amount, 'amount', setFormErrors, 'Invoice amount', 0.01)
          onChangeAmount(e)
        }}
        error={!!formErrors['amount']}
        helperText={formErrors['amount']}
        onBlur={() =>
          validateMinValue(payment.amount, 'amount', setFormErrors, 'Invoice amount', 0.01)
        }
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        sx={{ mb: 2 }}
        InputProps={{
          startAdornment: <InputAdornment position='start'>$</InputAdornment>,
        }}
        InputLabelProps={{ shrink: true }}
      />
      <SelectorMui
        label='Payment method'
        placeholder='Select a Card'
        error={!!formErrors['cardId']}
        helperText={formErrors['cardId']}
        defaultItem='None'
        fullWidth
        options={
          customer?.creditCardList?.map((card: ICreditCard) => {
            return {
              value: card.publicId || '',
              title: `**** **** **** ${card.lastDigits} - ${card.name}`,
            }
          }) || []
        }
        onBlur={() => {
          setFormErrors((prevState: any) => {
            return {
              ...prevState,
              cardId: !payment.cardId ? 'Should select at least one card' : undefined,
            }
          })
        }}
        onChange={(e: SelectChangeEvent, child: ReactNode) => {
          setFormErrors((prevState: any) => {
            return {
              ...prevState,
              cardId: !e.target.value ? 'Should select at least one card' : undefined,
            }
          })
          onChangeSelectedCard && onChangeSelectedCard(e, child)
        }}
        value={payment.cardId}
        sx={{ mb: 1 }}
      />
      <Link
        component='button'
        underline='none'
        onClick={() => {
          setIsNewCard(!isNewCard)
        }}
      >
        {`${isNewCard ? '-' : '+'} Add a new card`}
      </Link>

      {isNewCard && payment.card && (
        <NewCreditCardForm
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          creditCard={payment.card}
          setCreditCard={onChangeNewCreditCard}
          mb={2}
        />
      )}

      <div className='payment-dialog__input-wrapper'>
        <div style={{ margin: '16px 0' }}>
          <DateField
            fullWidth
            label='Payment date'
            value={payment?.date}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeDate(e)}
          />
        </div>
      </div>

      {isLoading ? (
        <div></div>
      ) : (
        <>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id='account-label'>Select an account</InputLabel>
            <Select
              labelId='account-label'
              label='Select an account'
              required
              fullWidth
              value={payment.financialAccountPublicId ? payment.financialAccountPublicId : ''}
              onChange={(e: SelectChangeEvent<any>, child: ReactNode) =>
                onChangeAccount && onChangeAccount(e, child)
              }
            >
              {options.map((category) => [
                <MenuItem
                  key={category.label}
                  disabled
                  style={{ fontWeight: 'bold', color: 'black', opacity: 1 }}
                >
                  {category.label}
                </MenuItem>,
                ...category.options.map((option: any) => (
                  <MenuItem key={option.label} value={option.value} style={{ marginLeft: '5px' }}>
                    {option.label}
                  </MenuItem>
                )),
              ])}
            </Select>
          </FormControl>
        </>
      )}
    </Box>
  )
}

export default AutoPaymentForm
