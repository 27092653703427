import AccountTransactionTable from './table'

const AccountTransaction = ({ from, to }: { from: string; to: string }) => {
  return (
    <>
      <AccountTransactionTable from={from} to={to} />
    </>
  )
}

export default AccountTransaction
