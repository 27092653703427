import { Box } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import api from '../../../api/api'
import BaseModal from '../../../components/modal/modal'
import { errorHandler } from '../../../helpers/errorHandler'
import { isUsingMobile } from '../../../helpers/utils'
import { saveVendors } from '../../../redux/actions/home.actions'
import { SAVE_MCC_TYPES } from '../../../redux/actions/auth.actions.type'
import VendorTypeStep from './type-step'
import PersonalInfoStep from './personal-info-step'
import BillingStep from './billing-step'
import { IEditVendor } from '../../../types/types'
import DialogEditFooter from './edit-footer'

interface IVendorsEditDialog {
  open: boolean
  vendor?: any
  onClose: () => void
}

const VendorsEditDialog = (props: IVendorsEditDialog) => {
  const dispatch = useDispatch()
  const { open, vendor, onClose } = props

  const [currentVendor, setCurrentVendor] = useState<IEditVendor>({
    publicId: '',
    name: '',
    firstName: '',
    lastName: '',
    country: 'United States',
    state: '',
    city: '',
    postalCode: '',
    addressOne: '',
    addressTwo: '',
    phone: '',
    mobile: '',
    website: '',
    mccPublicId: '',
    contractorType: 'INDIVIDUAL',
    type: 'REGULAR',
    emailAddress: '',
  })

  const [formErrors, setFormErrors] = useState({
    name: '',
    firstName: '',
    lastName: '',
    country: '',
    state: '',
    city: '',
    postalCode: '',
    addressOne: '',
    addressTwo: '',
    phone: '',
    mobile: '',
    website: '',
    mccPublicId: '',
    emailAddress: '',
  })

  const handleChangeNewVendor = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setCurrentVendor({
      ...currentVendor,
      [value]: e.target.value,
    })
  }

  const isDisabledSaveButton = useMemo(() => {
    const requiredFields = [
      'name',
      'mccPublicId',
      'type',
      'firstName',
      'lastName',
      'emailAddress',
      'phone',
      'addressOne',
      'city',
      'state',
      'postalCode',
    ]

    const areFieldsEmpty = requiredFields.some((field) => !(currentVendor as any)[field])

    return Object.values(formErrors).some((error) => error !== '') || areFieldsEmpty
  }, [formErrors, currentVendor])

  const resetErrors = () => {
    setFormErrors({
      name: '',
      firstName: '',
      lastName: '',
      country: '',
      state: '',
      city: '',
      postalCode: '',
      addressOne: '',
      addressTwo: '',
      phone: '',
      mobile: '',
      website: '',
      mccPublicId: '',
      emailAddress: '',
    })
  }

  const handleCloseModal = () => {
    resetErrors()
    onClose()
  }

  useEffect(() => {
    vendor &&
      setCurrentVendor({
        ...vendor,
        mccPublicId: vendor.mccCode.publicId,
      })
  }, [open])

  useEffect(() => {
    api
      .getMCCTypes()
      .then((res) => {
        dispatch({
          type: SAVE_MCC_TYPES,
          payload: res.data.body,
        })
      })
      .catch((err) => console.log(err))
  }, [])

  const handleAcceptClick = () => {
    const payload = {
      ...currentVendor,
      addressTwo:
        currentVendor.addressTwo && currentVendor.addressTwo?.length > 0
          ? currentVendor.addressTwo
          : null,
    }

    vendor &&
      api
        .updateVendor(payload, currentVendor.publicId)
        .then(() => {
          handleCloseModal()
          toast.success(`Vendor was successfully edited`, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          })
          dispatch(saveVendors())
        })
        .catch((err) => errorHandler(err))
  }

  return (
    <BaseModal
      size={isUsingMobile() ? 'xs' : 'small'}
      title='Edit vendor'
      body={
        <>
          <Box display='flex' flexDirection='column' margin='auto' gap={2} p={4} pb={10}>
            <VendorTypeStep
              currentVendor={currentVendor}
              setCurrentVendor={setCurrentVendor}
              handleChangeNewVendor={handleChangeNewVendor}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
            />

            <PersonalInfoStep
              currentVendor={currentVendor}
              handleChangeNewVendor={handleChangeNewVendor}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
            />

            <BillingStep
              currentVendor={currentVendor}
              handleChangeNewVendor={handleChangeNewVendor}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
            />
          </Box>
        </>
      }
      isModalOpen={open}
      handleCloseModal={handleCloseModal}
      footer={
        <DialogEditFooter
          isDisabledSaveButton={isDisabledSaveButton}
          handleCancel={handleCloseModal}
          handleAcceptClick={handleAcceptClick}
        />
      }
    />
  )
}

export default VendorsEditDialog
