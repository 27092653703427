import { useEffect, useState } from 'react';
import './text-field.css';

interface ITextField {
	styles?: string;
	iconStyles?: string;
	placeholder?: string;
	withIcon?: boolean;
	iconPosition?: string;
	icon?: string;
	disabled?: boolean;
	readonly?: boolean;
	onIconClick?: () => void;
	value?: string | number;
	type?: string;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	isValid?: boolean;
	errorMessage?: string;
	withValidation?: boolean;
	name?: string;
	autoFocus?: boolean;
	title?: string;
}

const TextField = (props: ITextField) => {
	const {
		styles,
		iconStyles,
		placeholder,
		withIcon,
		icon,
		iconPosition,
		onIconClick,
		value,
		readonly,
		onChange,
		type,
		isValid,
		errorMessage,
		withValidation,
		name,
		autoFocus,
		disabled,
		title,
	} = props;
	const [isChanged, setIsChanged] = useState<boolean>(false);

	useEffect(() => {
		if (value) {
			setIsChanged(true);
		}
	}, [value]);

	const renderFieldsStyles = () => {
		if (withIcon) {
			if (iconPosition === 'start') {
				return 'text-field__root-start';
			} else {
				return 'text-field__root-end';
			}
		} else {
			return 'text-field__without-icon';
		}
	};

	return (
		<label className="text-field__label">
			{title && <div className="text-field__title">{title}</div>}
			<input
				className={`text-field__root ${renderFieldsStyles()} ${styles || ''}  ${
					isChanged && withValidation && !isValid
						? 'text-field__wrapper-error'
						: ''
				}`}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				disabled={disabled}
				readOnly={readonly}
				name={name}
				type={type}
				autoFocus={autoFocus}
			/>
			{withIcon ? (
				<img
					src={icon}
					onClick={onIconClick}
					className={`text-field__icon ${
						iconPosition === 'start'
							? 'text-field__icon-start'
							: 'text-field__icon-end'
					} ${iconStyles || ''}`}
				/>
			) : null}
			{isChanged && withValidation && !isValid ? (
				<div
					className="text-input-message payment-dialog__error-message"
					style={{
						color: isValid ? 'rgba(64, 129, 51, 1)' : '#ba0000',
					}}
				>
					{errorMessage}
				</div>
			) : null}
		</label>
	);
};

export default TextField;
