import { Grid, TextField } from '@mui/material'
import DateField from '../../../components/date-field'
import { INewJournalEntry } from '../../../types/types'

interface ManageHeaderProps {
  onChangeField: any
  journal: INewJournalEntry
}
const FormHeader = ({ onChangeField, journal }: ManageHeaderProps) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid container item md={6} gap={2} flexDirection='column'>
          <Grid item>
            <DateField
              fullWidth
              label='Journal date'
              value={journal?.date}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, 'date', true)}
            />
          </Grid>
          <Grid item></Grid>
        </Grid>
        <Grid container item md={6} gap={2} flexDirection='column' pt='0 !important'>
          <Grid item flexGrow={2} m={0} p={'0 !important'}></Grid>

          <Grid item>
            <TextField
              fullWidth
              label='Description'
              placeholder='Enter a description'
              value={journal?.description}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, 'description')}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item></Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default FormHeader
