import { useEffect, useState } from 'react'
import { IFinancialAccount } from '../../types/types'
import api from '../../api/api'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { isUsingMobile } from '../../helpers/utils'
import SelectorMui from '../../components/selector-mui'
import DownloadButton from '../../components/filters/download-button'

interface JournalEntryFiltersProps {
  onSearchClick: (account: any, status: any) => () => void
  onDownloadClick: any
}

const JournalEntryFilters = ({ onSearchClick, onDownloadClick }: JournalEntryFiltersProps) => {
  const isMobile = isUsingMobile()

  const [showFilters, setShowFilters] = useState(false)
  const [filters, setFilters] = useState({
    account: null,
    status: 'ALL',
  })

  return (
    <>
      {!isMobile && (
        <Card sx={{ '&.MuiPaper-root': { boxShadow: 'none' }, mb: '16px' }}>
          <CardContent sx={{ pb: '16px !important' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
              <FilterInputs isMobile={isMobile} filters={filters} setFilters={setFilters} />
              <Button
                variant='contained'
                size='medium'
                onClick={onSearchClick(filters.account, filters.status)}
                sx={{ px: 4, py: 2 }}
              >
                Search
              </Button>
              <div style={{ display: 'flex', cursor: 'pointer', marginLeft: 'auto' }}>
                <DownloadButton onDownloadClick={onDownloadClick} title='Export journal entries' />
              </div>
            </div>
            <div style={{ display: 'flex', cursor: 'pointer' }}></div>
          </CardContent>
        </Card>
      )}

      {isMobile && (
        <>
          <div
            onClick={() => setShowFilters(!showFilters)}
            style={{ color: '#0039FF', fontWeight: '500', marginBottom: '10px' }}
          >
            {showFilters ? 'Hide filters' : 'Show filters'}
          </div>
          {showFilters && (
            <Card sx={{ '&.MuiPaper-root': { boxShadow: 'none' }, mb: '16px' }}>
              <CardContent sx={{ pb: '16px !important' }}>
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <FilterInputs isMobile={isMobile} filters={filters} setFilters={setFilters} />
                  <Button
                    variant='contained'
                    onClick={onSearchClick(filters.account, filters.status)}
                    style={{ marginLeft: !isMobile ? '0px' : 'auto' }}
                  >
                    Search
                  </Button>
                  <div style={{ display: 'flex', cursor: 'pointer', marginLeft: 'auto' }}>
                    <DownloadButton
                      onDownloadClick={onDownloadClick}
                      title='Export journal entries'
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
          )}
        </>
      )}
    </>
  )
}

interface FilterInputsProps {
  filters: any
  setFilters: (filters: any) => void
  isMobile: boolean
}

const FilterInputs = ({ filters, setFilters, isMobile }: FilterInputsProps) => {
  const [isAccountsListOpen, setIsAccountsListOpen] = useState(false)
  const [accountsList, setAccountsList] = useState<readonly IFinancialAccount[]>([])
  const loading = isAccountsListOpen && accountsList.length === 0

  useEffect(() => {
    if (!loading) {
      return undefined
    }

    api.getFinancialAccount(0, 999).then((res) => {

      const activeAccounts = res.data.body.content
      .filter((account: IFinancialAccount) => account.active)
      .sort((a: IFinancialAccount, b: IFinancialAccount) => 
        a.name?.localeCompare(b.name || '') || 0
      )
      
      setAccountsList(activeAccounts);
    })
  }, [loading])

  return (
    <>
      <FormControl fullWidth={isMobile}>
        <Autocomplete
          open={isAccountsListOpen}
          onOpen={() => {
            setIsAccountsListOpen(true)
          }}
          onClose={() => {
            setIsAccountsListOpen(false)
          }}
          isOptionEqualToValue={(option, value) => option.publicId === value.publicId}
          getOptionLabel={(option) => option.name || ''}
          options={accountsList}
          loading={loading}
          onChange={(e, account) => setFilters({ ...filters, account: account?.publicId })}
          sx={{ width: { sm: 350 } }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label='Accounts'
              InputLabelProps={{ shrink: true }}
              placeholder='Select account'
            />
          )}
        />
      </FormControl>

      <Box sx={{ width: { xs: '100%', sm: 350 } }}>
        <SelectorMui
          fullWidth
          label='Status'
          options={[
            { value: 'ALL', title: 'All' },
            { value: 'REVIEWED', title: 'Reviewed' },
            { value: 'NOT_REVIEWED', title: 'Not Reviewed' },
          ]}
          value={filters.status}
          placeholder='Select status'
          onChange={(e: SelectChangeEvent) => {
            setFilters({ ...filters, status: e.target.value })
          }}
        />
      </Box>
    </>
  )
}

export default JournalEntryFilters
