import { Autocomplete, Button, Card, CardContent, FormControl, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { isUsingMobile } from '../../helpers/utils'

import { IVendor } from '../../types/types'
import api from '../../api/api'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

interface BillFiltersProps {
  onSearchClick: any
}

const BillFilters = ({ onSearchClick }: BillFiltersProps) => {
  const isMobile = isUsingMobile()
  const [showFilters, setShowFilters] = useState(false)

  const [filters, setFilters] = useState({
    vendor: null,
    from: null,
    to: null,
  })

  const changeFilter = () => {
    setShowFilters(!showFilters)
  }

  return (
    <>
      {!isMobile && (
        <Card sx={{ '&.MuiPaper-root': { boxShadow: 'none' }, mb: '16px' }}>
          <CardContent sx={{ pb: '16px !important' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
              <FilterInputs isMobile={isMobile} filters={filters} setFilters={setFilters} />

              <Button
                variant='contained'
                size='medium'
                onClick={onSearchClick(filters.from, filters.to, filters.vendor)}
                sx={{ px: 4, py: 2 }}
              >
                Search
              </Button>
            </div>
            <div style={{ display: 'flex', cursor: 'pointer' }}></div>
          </CardContent>
        </Card>
      )}

      {isMobile && (
        <>
          <div
            onClick={changeFilter}
            style={{ color: '#0039FF', fontWeight: '500', marginBottom: '10px' }}
          >
            {showFilters ? 'Hide filters' : 'Show filters'}
          </div>
          {showFilters && (
            <Card sx={{ '&.MuiPaper-root': { boxShadow: 'none' }, mb: '16px' }}>
              <CardContent sx={{ pb: '16px !important' }}>
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <FilterInputs isMobile={isMobile} filters={filters} setFilters={setFilters} />

                  <Button
                    variant='contained'
                    onClick={onSearchClick(filters.from, filters.to, filters.vendor)}
                    style={{ marginLeft: !isMobile ? '0px' : 'auto' }}
                  >
                    Search
                  </Button>
                </div>
              </CardContent>
            </Card>
          )}
        </>
      )}
    </>
  )
}

interface FilterInputsProps {
  filters: any
  setFilters: any
  isMobile: boolean
}

const FilterInputs = ({ filters, setFilters, isMobile }: FilterInputsProps) => {
  const [isVendorsListOpen, setIsVendorsListOpen] = useState(false)
  const [vendorsList, setVendorsList] = useState<readonly IVendor[]>([])
  const loading = isVendorsListOpen && vendorsList.length === 0

  useEffect(() => {
    if (!loading) {
      return undefined
    }

    api.getVendors(0, 999).then((res) => {
      setVendorsList([...res.data.body.content])
    })
  }, [loading])

  return (
    <>
      <FormControl fullWidth={isMobile}>
        <Autocomplete
          open={isVendorsListOpen}
          onOpen={() => {
            setIsVendorsListOpen(true)
          }}
          onClose={() => {
            setIsVendorsListOpen(false)
          }}
          isOptionEqualToValue={(option, value) => option.publicId === value.publicId}
          getOptionLabel={(option) => option.name || ''}
          options={vendorsList}
          loading={loading}
          onChange={(e, vendor) => setFilters({ ...filters, vendor })}
          sx={{ width: { sm: 200 } }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label='Vendor'
              InputLabelProps={{ shrink: true }}
              placeholder='Select vendor'
            />
          )}
        />
      </FormControl>

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          value={filters.from}
          onChange={(from) => setFilters({ ...filters, from })}
          renderInput={(params) => <TextField {...params} fullWidth={isMobile} placeholder='Select From Date' />}
        />
        <DatePicker
          value={filters.to}
          onChange={(to) => setFilters({ ...filters, to })}
          renderInput={(params) => <TextField {...params} fullWidth={isMobile} placeholder='Select To Date'/>}
        />
      </LocalizationProvider>
    </>
  )
}

export default BillFilters
