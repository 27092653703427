import { SyntheticEvent, useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  changeIsLoading,
  saveAllFinancialAccountTypes,
  saveFinancialAccount,
  saveFinancialAccountTypes,
} from '../../redux/actions/home.actions'
import { AppState } from '../../redux/store'
import ContentHeader from '../../components/content-header'
import PlusButton from '../../components/plus-button/plus-button'
import ListFilters from '../../components/list-filters'
import NoDataDisplay from '../../components/noDataDisplay/noDataDisplay'
import PrintIcon from '@mui/icons-material/PrintOutlined'
import UploadIcon from '@mui/icons-material/UploadOutlined'
import DownloadIcon from '@mui/icons-material/GetAppOutlined'
import SearchIcon from '@mui/icons-material/Search'
import { useReactToPrint } from 'react-to-print'
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
} from '@mui/material'

import ChartsTable from './chart-table'
import { IAccount } from '../../types/types'
import { toast } from 'react-toastify'
import { errorHandler } from '../../helpers/errorHandler'
import api from '../../api/api'
import { isUsingMobile } from '../../helpers/utils'
import useAuthorization from '../../helpers/useAuthorization'
import ReferenceMenu from '../chart-accounts/reference-menu'
import ChartManageDialog from './chart-manage-dialog'
import saveAs from 'file-saver'
import SuccessDialog from '../../components/assitance-dialog/success-dialog'
import ImportConfirmDialog from './import-confirm-dialog/import-confirm-dialog'
import ImportDialog from '../../components/import-dialog/import-dialog'

import './chart-accounts.css'

const CharAccounts = () => {
  const dispatch = useDispatch()
  const [selectedFilter, setSelectedFilter] = useState('ASSETS')
  const [selectedAccount, setSelectedAccount] = useState<any>()
  const [selectedStatus, setSelectedStatus] = useState('')
  const [isCreate, setIsCreate] = useState(true)
  const [isOpenExportDialog, setIsOpenImportDialog] = useState(false)
  const [accountNames, setAccountNames] = useState<any>([])
  const [isAccountDialogOpen, setIsAccountDialogOpen] = useState<boolean>(false)
  const [assistanceSuccess, setAssistanceSuccess] = useState(false)
  const [showFilters, setShowFilters] = useState(false)
  const [search, setSearch] = useState('')
  const [groupedData, setGroupedData] = useState<{ [key: string]: any[] } | null>(null)
  const [isImportFormatCorrect, setIsImportFormatCorrect] = useState<boolean | null>(null)
  const financialAccount = useSelector((state: AppState) => state.homeReducer.financialAccount)
  const financialAccountTypes = useSelector(
    (state: AppState) => state.homeReducer.financialAccountTypes,
  )
  const allFinancialAccountTypes = useSelector(
    (state: AppState) => state.homeReducer.allFinancialAccountTypes,
  )
  const isMobile = isUsingMobile()
  const { isAuthorized } = useAuthorization()
  const preview = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (financialAccount) {
      const uniqueAccountNamesMap = new Map<string, any>()
      financialAccount.content.forEach((account: any) => {
        const accountTypeName = account.type.name
        const accountTypeId = account.type.publicId
        if (!uniqueAccountNamesMap.has(accountTypeName)) {
          uniqueAccountNamesMap.set(accountTypeName, {
            name: accountTypeName,
            publicId: accountTypeId,
          })
        }
      })
      const uniqueAccountNames = Array.from(uniqueAccountNamesMap.values())
      setAccountNames(uniqueAccountNames)
    }
  }, [financialAccount])

  const handleAccountDialogClose = () => {
    setIsAccountDialogOpen(false)
  }

  useEffect(() => {
    dispatch(saveFinancialAccount())
    dispatch(saveFinancialAccountTypes(undefined, undefined, selectedFilter))
    dispatch(saveAllFinancialAccountTypes(undefined, undefined, undefined))
  }, [])

  const searchData = () => {
    dispatch(
      saveFinancialAccount(
        undefined,
        undefined,
        search,
        selectedStatus === 'ALL' ? undefined : selectedStatus === 'ACTIVE' ? true : false,
      ),
    )
  }

  const handleExportClick = () => {
    setIsOpenImportDialog(true)
  }

  const handleDownloadClick = () => {
    api
      .exportFinancialAccount()
      .then((response) => {
        const blob = response.data

        const fetchData = async () => {
          try {
            saveAs(blob, 'account_data.xlsx')
          } catch (error) {
            console.error('Error while fetching account data', error)
          }
        }

        fetchData()
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  const handleCreateClick = () => {
    setIsCreate(true)
    setIsAccountDialogOpen(true)
  }

  const handleSelectedFilterChange = (event: SyntheticEvent, filter: string) => {
    setSelectedStatus('')
    setSearch('')
    setSelectedFilter(filter)
    dispatch(saveFinancialAccountTypes(undefined, undefined, filter))
    dispatch(saveFinancialAccount())
  }

  const handleEditClick = (accountData: IAccount) => {
    setIsCreate(false)
    setSelectedAccount(accountData)
    setIsAccountDialogOpen(true)
  }

  const handleChangeStatusClick = () => {
    if (!selectedAccount) {
      return
    }

    const responseText =
      selectedAccount.active === true
        ? `Account was successfully disabled`
        : `Account was successfully activated`

    const payload = {
      identifier: selectedAccount.identifier,
      name: selectedAccount.name,
      description: selectedAccount.description,
      financialAccountTypePublicId: selectedAccount.type.publicId,
      active: !selectedAccount.active,
    }
    api
      .updateFinancialAccount(payload, selectedAccount.publicId)
      .then(() => {
        toast.success(responseText, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        })
        dispatch(
          saveFinancialAccount(
            undefined,
            undefined,
            search,
            selectedStatus === 'ALL' ? undefined : selectedStatus === 'ACTIVE' ? true : false,
          ),
        )
      })
      .catch((err) => errorHandler(err))
  }

  const printInvoice = useReactToPrint({
    content: () => preview?.current,
    onBeforePrint: () => {
      dispatch(changeIsLoading(true))
    },
    onAfterPrint: () => {
      dispatch(changeIsLoading(false))
    },
  })

  const changeFilter = () => {
    setShowFilters(!showFilters)
  }

  const processImportData = (dataRows: string[]) => {
    const grouped: { [key: string]: any[] } = {}

    dataRows.forEach((row) => {
      const values = row.split(',')

      if (values.length === 5) {
        const type = values[0].trim().replaceAll('"', '')

        if (!grouped[type]) {
          grouped[type] = []
        }

        grouped[type].push({
          ID: values[1].trim(),
          Name: values[2].trim().replaceAll('"', ''),
          Status: values[3].trim().replaceAll('"', ''),
          Description: values[4].trim().replaceAll('"', ''),
        })
      } else {
        console.error('Invalid row format:', row)
      }
    })

    setGroupedData(grouped)
  }

  return (
    <>
      <ContentHeader title={<Box>Chart of Accounts {isMobile && <ReferenceMenu />} </Box>} />

      <ListFilters
        filters={[
          { title: 'Assets', value: 'ASSETS' },
          { title: 'Liabilities & Credit Cards', value: 'LIABILITIES', large: true },
          { title: 'Income', value: 'REVENUE' },
          { title: 'Expenses', value: 'EXPENSES' },
          { title: 'Equity', value: 'EQUITY' },
        ]}
        selectedFilter={selectedFilter}
        onSelectedFilterChange={handleSelectedFilterChange}
      />

      {!isMobile && (
        <Card sx={{ '&.MuiPaper-root': { boxShadow: 'none' }, mb: '16px' }}>
          <CardContent sx={{ pb: '16px !important' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Select
                value={selectedStatus}
                onChange={(e: SelectChangeEvent) => setSelectedStatus(e.target.value)}
                style={{ width: '200px', marginRight: '10px' }}
                displayEmpty
              >
                <MenuItem disabled value=''>
                  Select status
                </MenuItem>
                <MenuItem value='ALL'>All</MenuItem>
                <MenuItem value='ACTIVE'>Active</MenuItem>
                <MenuItem value='INACTIVE'>Inactive</MenuItem>
              </Select>
              <TextField
                style={{ width: '300px', marginRight: '10px' }}
                value={search}
                placeholder='Search by account name or ID... '
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearch(e.target.value)
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ shrink: true }}
              />

              <Button variant='contained' onClick={searchData}>
                Search
              </Button>

              <div style={{ display: 'flex', cursor: 'pointer', marginLeft: 'auto' }}>
                {/* <div className='icon'>
                  <Tooltip title='Print' arrow>
                    <PrintIcon onClick={handlePrint} />
                  </Tooltip>
                </div> */}
                <div className='icon'>
                  <Tooltip title='Export financial account' arrow>
                    <DownloadIcon
                      style={{ backgroundColor: '#E5EBFF', color: '#0039FF', borderRadius: '50%' }}
                      onClick={handleDownloadClick}
                    />
                  </Tooltip>
                </div>
                <div className='icon'>
                  <Tooltip title='Import financial account' arrow>
                    <UploadIcon
                      style={{ backgroundColor: '#E5EBFF', color: '#0039FF', borderRadius: '50%' }}
                      onClick={handleExportClick}
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', cursor: 'pointer' }}></div>
          </CardContent>
        </Card>
      )}

      {isMobile && (
        <>
          <div
            onClick={changeFilter}
            style={{ color: '#0039FF', fontWeight: '500', marginBottom: '10px' }}
          >
            {showFilters ? 'Hide filters' : 'Show filters'}
          </div>
          {showFilters && (
            <Card sx={{ '&.MuiPaper-root': { boxShadow: 'none' }, mb: '16px' }}>
              <CardContent sx={{ pb: '16px !important' }}>
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  <Select
                    value={selectedStatus}
                    onChange={(e: SelectChangeEvent) => setSelectedStatus(e.target.value)}
                    style={{
                      width: !isMobile ? '200px' : '100%',
                      marginRight: !isMobile ? '10px' : '0',
                      marginBottom: !isMobile ? '0' : '10px',
                    }}
                    displayEmpty
                  >
                    <MenuItem disabled value=''>
                      Select status
                    </MenuItem>
                    <MenuItem value='ALL'>All</MenuItem>
                    <MenuItem value='ACTIVE'>Active</MenuItem>
                    <MenuItem value='INACTIVE'>Inactive</MenuItem>
                  </Select>
                  <TextField
                    style={{
                      width: !isMobile ? '300px' : '100%',
                      marginRight: !isMobile ? '10px' : '0',
                      marginBottom: !isMobile ? '0' : '10px',
                    }}
                    value={search}
                    placeholder='Search by account name or ID... '
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setSearch(e.target.value)
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{ shrink: true }}
                  />

                  <Button
                    variant='contained'
                    onClick={searchData}
                    style={{ marginLeft: !isMobile ? '0px' : 'auto' }}
                  >
                    Search
                  </Button>
                </div>
              </CardContent>
            </Card>
          )}
        </>
      )}

      {financialAccountTypes &&
      financialAccount &&
      financialAccountTypes.content.length > 0 &&
      financialAccount.content.length > 0 ? (
        <div ref={preview}>
          {financialAccountTypes.content.map((account: any) => {
            const matchingAccounts = financialAccount.content.filter(
              (value: IAccount) => value.type.name === account.name,
            )
            if (matchingAccounts.length > 0) {
              return (
                <Box key={account.publicId} pb={4}>
                  <Alert
                    style={{ backgroundColor: '#E5EBFF', color: '#0039FF', fontWeight: 600 }}
                    icon={false}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div>{account.name}</div>
                      {/* <HelpOutlineIcon style={{ color: '#0039FF', marginLeft: '8px' }} /> */}
                    </div>
                  </Alert>
                  <ChartsTable
                    financialAccount={matchingAccounts}
                    onRowClick={handleEditClick}
                    onChangeStatusClick={handleChangeStatusClick}
                    onsetSelectedAccount={setSelectedAccount}
                    onSelectedAccount={selectedAccount}
                    changeStatusText={
                      selectedAccount?.active === false ? 'Set as Active' : 'Set as Inactive'
                    }
                  />
                </Box>
              )
            } else {
              return null
            }
          })}
        </div>
      ) : (
        <NoDataDisplay
          title='No data to display'
          description='Add recurring invoices and schedule dates and automatic payments.'
        />
      )}

      {isAuthorized('write_invoice') && (
        <PlusButton tooltipText='Add Account' handleOnClick={handleCreateClick} />
      )}

      <ChartManageDialog
        open={isAccountDialogOpen}
        onClose={handleAccountDialogClose}
        accountNames={accountNames}
        product={selectedAccount}
        isCreate={isCreate}
        financialAccountTypes={allFinancialAccountTypes}
      />
      <ImportDialog
        isOpen={isOpenExportDialog}
        dialogTitle='Upload a file you want to import'
        dialogBodyContent='Fill in the fields in the sample file, save it and upload this file to the system.'
        handleClose={() => setIsOpenImportDialog(false)}
        setAssistanceSuccess={setAssistanceSuccess}
        csvData={
          'Type,ID,Name,Status,Description\nInventory,1002,"Account B",Inactive,Description\n"Accounts Payable",1003,"Account C",Active,Description\n"Cash and Bank",1004,"Account D",Inactive,Description'
        }
        expectedHeader={'Type,ID,Name,Status,Description'}
        processImportData={processImportData}
        setIsFormatCorrect={setIsImportFormatCorrect}
        confirmDialog={
          <ImportConfirmDialog
            isOpen={
              isImportFormatCorrect ? groupedData !== null && groupedData !== undefined : false
            }
            dialogTitle='Confirm import'
            dialogBodyContent='Please confirm that the columns match the entered values.'
            handleClose={() => setGroupedData(null)}
            groupedData={groupedData}
            handleCloseExport={() => setIsOpenImportDialog(false)}
          />
        }
      />
      {assistanceSuccess && (
        <SuccessDialog isOpen={assistanceSuccess} setAssistanceSuccess={setAssistanceSuccess} />
      )}
    </>
  )
}

export default CharAccounts
