import { ReactInstance, SyntheticEvent, useEffect, useRef, useState } from 'react'
import ContentHeader from '../../components/content-header'
import {
  changeIsLoading,
  saveInvoices,
  saveInvoicesStatistics,
} from '../../redux/actions/home.actions'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { AppState } from '../../redux/store'
import './invoices.css'
import PaginationNew from '../../components/pagination-new'
import '../estimates/estimates-list-table/estimates-list-table.css'
import PlusButton from '../../components/plus-button/plus-button'
import InvoiceStats from './invoice-stats'
import ListFilters from '../../components/list-filters'
import NoDataDisplay from '../../components/noDataDisplay/noDataDisplay'
import { Box, Typography } from '@mui/material'
import InvoicesTable from './invoicesTable'
import { IInvoice, INewPayment } from '../../types/types'
import { toast } from 'react-toastify'
import api from '../../api/api'
import { errorHandler, generalErrorHandler } from '../../helpers/errorHandler'
import { useReactToPrint } from 'react-to-print'
import PreviewNew from '../../components/preview-new'
import { downloadPDF } from '../../helpers/downloadPdf'
import ConfirmDialog from '../../components/dialog/dialog'
import ReferenceMenu from './reference-menu'
import { isUsingMobile } from '../../helpers/utils'
import InvoiceModal from './modal/invoiceModal'
import PaymentModal from './record-payment/paymentModal'
import moment from 'moment'
import { AxiosResponse } from 'axios'
import useAuthorization from '../../helpers/useAuthorization'
import { successToastNotification } from '../../helpers/toastNotification'

const Invoices = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const selectedFilterFromQuery = queryParams.get('selectedFilter')

  const invoices = useSelector((state: AppState) => state.homeReducer.invoices)
  const [invoicesPage, setInvoicesPage] = useState(1)
  const [selectedFilter, setSelectedFilter] = useState('')
  const [selectedInvoice, setSelectedInvoice] = useState<IInvoice>()
  const [isEnrolAlertOpen, setIsEnrolAlertOpen] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const company = useSelector((state: AppState) => state.homeReducer.company)
  const preview = useRef<ReactInstance | null>(null)
  const [showModal, setShowModal] = useState(false)
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const merchantKey = useSelector((state: AppState) => state.authReducer.usioApiKey)

  const isMobile = isUsingMobile()
  const { isAuthorized } = useAuthorization()

  const invoicesSize = useSelector((state: AppState) => state.homeReducer.invoicesSize)
  const invoicesStatistics = useSelector((state: AppState) => state.homeReducer.invoicesStatistics)

  const chosenInvoice = useSelector((state: AppState) => state.homeReducer.chosenInvoice)

  const usioLink = useSelector((state: AppState) => state.authReducer.usioLink)

  useEffect(() => {
    if (selectedFilterFromQuery) {
      selectedFilterFromQuery === 'Open Invoices'
        ? setSelectedFilter('SENT')
        : setSelectedFilter('OVERDUE')
    }
  }, [selectedFilterFromQuery])

  useEffect(() => {
    dispatch(saveInvoices())
    dispatch(saveInvoicesStatistics())
  }, [dispatch])

  useEffect(() => {
    if (chosenInvoice) {
      setSelectedInvoice(chosenInvoice)
      setShowModal(true)
    }
  }, [chosenInvoice])

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setInvoicesPage(value)
    dispatch(saveInvoices(value - 1, selectedFilter))
  }

  const handleSelectedFilterChange = (event: SyntheticEvent, filter: string) => {
    setSelectedFilter(filter)
    setInvoicesPage(1)
    dispatch(saveInvoices(0, filter))
  }

  const handleViewClick = (invoice: IInvoice) => {
    setShowModal(true)
    setSelectedInvoice(invoice)
  }

  const handleDuplicateClick = () => {
    if (!selectedInvoice) {
      return
    }

    api
      .duplicateInvoice(selectedInvoice.publicId)
      .then(() => {
        dispatch(saveInvoices())
        toast.success(`Invoice was successfully duplicated`, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        })
      })
      .catch((err) => errorHandler(err))
  }

  const handleEditClick = () => {
    selectedInvoice && navigate(`/invoices/add/${selectedInvoice.publicId}/edit`)
  }

  const handleRecordPaymentClick = () => {
    setShowPaymentModal(true)
  }

  const handleSendClick = () => {
    if (!selectedInvoice || !company) {
      return
    }

    if (company.merchantStatus !== 'Onboarded') {
      setIsEnrolAlertOpen(true)
    } else {
      api
        .sendInvoice(selectedInvoice.publicId, {
          email: selectedInvoice.customerEmail,
        })
        .then(() => {
          dispatch(saveInvoices())
          setSelectedFilter('')
          toast.success(`Invoice was successfully sent to ${selectedInvoice.customerEmail}`, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          })
        })
        .catch((err) => errorHandler(err))
    }
  }

  const printInvoice = useReactToPrint({
    content: () => preview?.current,
    onBeforePrint: () => {
      dispatch(changeIsLoading(true))
    },
    onAfterPrint: () => {
      dispatch(changeIsLoading(false))
    },
  })

  const handlePrintClick = () => {
    printInvoice()
  }

  const getCustomerData = () => {
    if (!selectedInvoice) {
      return
    }

    return {
      name: selectedInvoice.customerName,
      primaryFirstName: selectedInvoice.primaryFirstName,
      primaryLastName: selectedInvoice.primaryLastName,
      primaryPhoneNumber: selectedInvoice.primaryPhoneNumber,
      billingAddress: selectedInvoice.billingAddress,
      billingAddressTwo: selectedInvoice.billingAddressTwo,
      billingCity: selectedInvoice.billingCity,
      billingCountry: selectedInvoice.billingCountry,
      billingPostalCode: selectedInvoice.billingPostalCode,
      billingState: selectedInvoice.billingState,
      website: selectedInvoice.website,
    }
  }

  const handleExportAsPDFClick = () => {
    downloadPDF(preview, 'invoice')
  }

  const handleDeleteClick = () => {
    setIsDeleteDialogOpen(true)
  }

  const handleDeleteConfirm = () => {
    if (!selectedInvoice) {
      return
    }

    setIsDeleteDialogOpen(false)

    api
      .deleteInvoice(selectedInvoice.publicId)
      .then(() => {
        successToastNotification(`${selectedInvoice.name} was successfully deleted`)
        dispatch(saveInvoices(invoicesPage - 1, selectedFilter))
      })
      .catch((err) => errorHandler(err))

    setSelectedInvoice(undefined)
  }

  const handleShareLinkClick = () => {
    if (!selectedInvoice) {
      return
    }

    navigator.clipboard.writeText(
      `${
        window.location.host.includes('localhost')
          ? 'http://localhost:3000'
          : 'https://app.bluecimarron.com'
      }/payment/${selectedInvoice.publicId}`,
    )

    toast.success(`Pay now link for invoice #${selectedInvoice.name} was copied to the clipboard`, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
    })
  }

  const handlePaymentConfirm = (payment: INewPayment) => () => {
    if (!selectedInvoice) {
      return
    }

    if (payment.processing === 'MANUAL') {
      createManualPayment(payment, selectedInvoice)
    } else {
      if (payment.card) {
        createNewCardPayment(payment, selectedInvoice)
      } else {
        createStoredCardPayment(payment, selectedInvoice)
      }
    }
  }

  const createManualPayment = (payment: INewPayment, selectedInvoice: IInvoice) => {
    api
      .recordPayment(selectedInvoice.publicId, {
        date: moment().format('YYYY-MM-DD HH:mm'),
        amount: payment.amount,
        method: payment.method,
        memo: '',
      })
      .then((res: AxiosResponse) => {
        if (res.data.body.status === 'FAILED') {
          generalErrorHandler(res.data.body.message)
        } else {
          setSelectedFilter('')
          dispatch(saveInvoices())
          setShowPaymentModal(false)
          setSelectedInvoice(undefined)
        }
      })
      .catch((err) => errorHandler(err))
  }

  const createNewCardPayment = (payment: INewPayment, selectedInvoice: IInvoice) => {
    const expDate = `${payment.card?.expirationDate.slice(
      0,
      2,
    )}20${payment.card?.expirationDate.slice(3)}`

    api
      .generateUSIOToken({
        merchantKey,
        paymentType: 'CreditCard',
        emailAddress: selectedInvoice.customerEmail || '',
        cardNumber: payment.card?.number.split(' ').join(''),
        expDate: expDate,
        cvv: payment.card?.cvv,
      })
      .then((res) => {
        if (res.data.status === 'FAILED') {
          toast(res.data.message, {
            autoClose: 3000,
            hideProgressBar: true,
            className: 'error-toast',
          })
        } else {
          api
            .makePayment({
              customerPublicId: payment.customerPublicId,
              amount: payment.amount,
              invoicePublicId: selectedInvoice.publicId,
              paymentType: 'CREDIT_CARD',
              usioToken: res.data.token,
              cardType: res.data.cardBrand,
              lastDigits: res.data.last4,
              expirationDate: res.data.expDate,
              save: payment.card?.saveCard,
              cardName: payment.card?.name,
              date: payment.date,
              financialAccountPublicId: payment.financialAccountPublicId
            })
            .then((res: any) => {
              if (res.data.body.status === 'FAILED') {
                toast(res.data.body.message, {
                  autoClose: 3000,
                  hideProgressBar: true,
                  className: 'error-toast',
                })
              } else {
                setSelectedFilter('')
                dispatch(saveInvoices())
                setShowPaymentModal(false)
                setSelectedInvoice(undefined)
              }
            })
            .catch((err) => errorHandler(err))
        }
      })
  }

  const createStoredCardPayment = (payment: INewPayment, selectedInvoice: IInvoice) => {
    api
      .makeTokenizedPayment({
        customerPublicId: payment.customerPublicId,
        amount: payment.amount,
        invoicePublicId: selectedInvoice.publicId,
        paymentType: 'CREDIT_CARD',
        cardId: payment.cardId,
        date: payment.date,
        financialAccountPublicId: payment.financialAccountPublicId
      })
      .then((res: AxiosResponse) => {
        if (res.data.body.status === 'FAILED') {
          toast(res.data.body.message, {
            autoClose: 3000,
            hideProgressBar: true,
            className: 'error-toast',
          })
        } else {
          setSelectedFilter('')
          dispatch(saveInvoices())
          setShowPaymentModal(false)
          setSelectedInvoice(undefined)
        }
      })
      .catch((err) => errorHandler(err))
  }

  const getDisabledItems = () => {
    if (!selectedInvoice || !selectedInvoice.status) {
      return []
    }

    switch (selectedInvoice.status) {
      case 'PAID':
        return ['edit', 'payment', 'send', 'export', 'share']
      case 'OVERDUE':
        return ['edit']
      case 'DRAFT':
        return ['payment', 'share']
    }

    return []
  }

  return (
    <>
      <ContentHeader title={<Box>Invoices {isMobile && <ReferenceMenu />}</Box>} />

      <InvoiceStats stats={invoicesStatistics} />

      <ListFilters
        filters={[
          { title: 'All', value: '' },
          { title: 'Partial', value: 'PARTIAL' },
          { title: 'Sent', value: 'SENT' },
          { title: 'Paid', value: 'PAID' },
          { title: 'Overdue', value: 'OVERDUE' },
          { title: 'Draft', value: 'DRAFT' },
          { title: 'Refund', value: 'REFUND' },
        ]}
        selectedFilter={selectedFilter}
        onSelectedFilterChange={handleSelectedFilterChange}
      />

      <Box pb={4}>
        <>
          {invoices.length > 0 ? (
            <InvoicesTable
              invoices={invoices}
              onRowClick={handleViewClick}
              onDuplicateClick={handleDuplicateClick}
              onEditClick={handleEditClick}
              onRecordPaymentClick={handleRecordPaymentClick}
              onSendClick={handleSendClick}
              onPrintClick={handlePrintClick}
              onExportAsPDFClick={handleExportAsPDFClick}
              onDeleteClick={handleDeleteClick}
              onShareLinkClick={handleShareLinkClick}
              onSelectedInvoice={setSelectedInvoice}
              disabledItems={getDisabledItems()}
              sendLabel={selectedInvoice && selectedInvoice.status === 'DRAFT' ? 'Send' : 'Resend'}
            />
          ) : (
            <NoDataDisplay mt='10rem' title='No data to display' />
          )}
          {invoicesSize > 15 || invoicesPage > 1 ? (
            <PaginationNew
              size={15}
              quantity={invoicesSize}
              page={invoicesPage}
              handleChange={handleChangePage}
            />
          ) : null}
        </>
      </Box>

      {isAuthorized('write_invoice') && (
        <PlusButton tooltipText='Add Invoice' handleOnClick={() => navigate('/invoices/add')} />
      )}

      <ConfirmDialog
        isOpen={isEnrolAlertOpen}
        dialogTitle='Invoice can not be sent'
        dialogBodyContent={
          <>
            <Typography>
              You must fill out the{' '}
              <a href={usioLink} rel='noreferrer' target='_blank'>
                USIO form
              </a>{' '}
              to receive credit card payments.
            </Typography>
            <Typography>
              Please use legal names and the registered business address for instant approval.
            </Typography>
          </>
        }
        buttonConfirmText='Ok'
        confirmColor='var(--primary1-color)'
        handleClose={() => setIsEnrolAlertOpen(false)}
        handleConfirm={() => setIsEnrolAlertOpen(false)}
      />

      <Box sx={{ display: 'none' }}>
        {selectedInvoice && (
          <PreviewNew item={selectedInvoice} preview={preview} customer={getCustomerData()} />
        )}
      </Box>

      <ConfirmDialog
        isOpen={isDeleteDialogOpen}
        dialogTitle='Sure you want to delete?'
        dialogBodyContent='Deleting this item will permanently remove it from your account, and it cannot be undone.'
        buttonConfirmText='Yes, Delete'
        buttonCancelText='Cancel'
        handleClose={() => setIsDeleteDialogOpen(false)}
        handleConfirm={handleDeleteConfirm}
      />

      {selectedInvoice && (
        <InvoiceModal
          showModal={showModal}
          onEditClick={handleEditClick}
          setShowModal={setShowModal}
          onDuplicateClick={handleDuplicateClick}
          onRecordPaymentClick={handleRecordPaymentClick}
          onShareLinkClick={handleShareLinkClick}
          onDeleteClick={handleDeleteClick}
          onPrintClick={handlePrintClick}
          onExportAsPDFClick={handleExportAsPDFClick}
          onSendClick={handleSendClick}
          invoice={selectedInvoice}
        />
      )}

      {selectedInvoice && (
        <PaymentModal
          showModal={showPaymentModal}
          setShowModal={setShowPaymentModal}
          invoice={selectedInvoice}
          onPaymentConfirm={handlePaymentConfirm}
        />
      )}
    </>
  )
}

export default Invoices
