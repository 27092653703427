import { Button } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';


interface IAddItemButton {
  handleOnClick: any;
  text: string;
  sx?: any;
  children?: React.ReactNode;
}

export const AddItemButton = (props: IAddItemButton) => {
  const {handleOnClick, text, sx} = props;
  return (
    <Button
      sx={{...sx, fontWeight:600}}
      variant="contained"
      startIcon={<AddIcon />}
      onClick={handleOnClick}
      color="primary"
    >
      {text}
    </Button>
  );
}

