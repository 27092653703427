import api from '../api/api';
import { errorHandler } from './errorHandler';

export const downloadPDF = (reference: any, pdfName: string) => {
	api
		.generatePDF({
			html: reference.current.innerHTML,
		})
		.then((res) => {
			const retypedBlob = new Blob([res.data], { type: 'application/pdf' });
			const url = window.URL.createObjectURL(retypedBlob);
			const a = document.createElement('a');
			document.body.appendChild(a);
			a.setAttribute('style', 'display: none');
			a.href = url;
			a.download = `${pdfName}.pdf`;
			a.click();
			window.URL.revokeObjectURL(url);
			a.remove();
		})
		.catch((err) => errorHandler(err));
};
