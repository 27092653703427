import { Box, Grid, InputAdornment, TextField } from '@mui/material'
import FlagIcon from '@mui/icons-material/Flag'

const PhysicalAddressForm = (props: any) => {
  const { company, setCompany } = props

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <Box mb={3}>
            <TextField
              label='Address line 1'
              placeholder='Building Number, Street Address, Apartment...'
              InputLabelProps={{
                shrink: true,
              }}
              value={company.addressOne}
              onChange={(e) =>
                setCompany({
                  ...company,
                  addressOne: e.target.value,
                })
              }
              required={true}
              fullWidth
            />
          </Box>
          <Box mb={3}>
            <TextField
              label='City'
              placeholder='Enter your city or locality'
              InputLabelProps={{
                shrink: true,
              }}
              value={company.city}
              onChange={(e) => setCompany({ ...company, city: e.target.value })}
              required={true}
              fullWidth
            />
          </Box>
          <Box>
            <TextField
              type='number'
              label='Postal/Zip code'
              placeholder='Enter your zip code'
              InputLabelProps={{
                shrink: true,
              }}
              value={company.postalCode}
              onChange={(e) =>
                setCompany({
                  ...company,
                  postalCode: e.target.value,
                })
              }
              required={true}
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Box mb={3}>
            <TextField
              label='Address line 2'
              placeholder='Building Number, Street Address, Apartment...'
              InputLabelProps={{
                shrink: true,
              }}
              value={company.addressTwo || ''}
              onChange={(e) =>
                setCompany({
                  ...company,
                  addressTwo: e.target.value,
                })
              }
              fullWidth
            />
          </Box>
          <Box mb={3}>
            <TextField
              label='State'
              placeholder='Enter your State abbreviation'
              InputLabelProps={{
                shrink: true,
              }}
              value={company.state}
              onChange={(e) => setCompany({ ...company, state: e.target.value })}
              required={true}
              fullWidth
            />
          </Box>
          <Box>
            <TextField
              label='Country'
              value={company.country}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <FlagIcon />
                  </InputAdornment>
                ),
              }}
              fullWidth
              required={true}
              disabled
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default PhysicalAddressForm
