import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import api from '../../api/api'
import { getAccountSubscription } from '../../redux/actions/home.actions'
import { useDispatch } from 'react-redux'
import { successToastNotification } from '../../helpers/toastNotification'

interface IResumeSubscriptionDialog {
  isOpen: boolean
  dialogTitle?: string
  buttonConfirmText?: string
  buttonCancelText?: string
  handleConfirm: () => void
  handleClose: () => void
}

export default function ResumeSubscriptionDialog(props: IResumeSubscriptionDialog) {
  const { isOpen, dialogTitle, buttonConfirmText, buttonCancelText, handleClose } = props

  const dispatch = useDispatch()

  const handleConfirmClick = () => {
    api
      .activateAccountSubscription()
      .then(() => {
        handleClose()
        successToastNotification(`Your subscription was successfully resumed`)
        const fetchData = async () => {
          try {
            await dispatch(getAccountSubscription())
          } catch (error) {
            console.error('Error fetching Subscription data', error)
          }
        }

        fetchData()
      })
      .catch((error: any) => {
        console.error('Error:', error)
      })
  }

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose} aria-labelledby='responsive-dialog-title'>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '30px 80px',
          }}
        >
          <DialogTitle id='responsive-dialog-title'>{dialogTitle}</DialogTitle>
          <div style={{ marginTop: '5px' }}>
            <DialogActions>
              <Button
                onClick={handleClose}
                style={{
                  borderColor: 'red',
                  borderWidth: 2,
                  borderStyle: 'solid',
                  background: 'red',
                }}
              >
                <Typography sx={{ color: 'white', fontWeight: '500' }}>
                  {buttonCancelText}
                </Typography>
              </Button>
              <Button
                onClick={handleConfirmClick}
                style={{ borderColor: 'blue', borderWidth: 2, borderStyle: 'solid' }}
              >
                <Typography sx={{ fontWeight: '500' }}>{buttonConfirmText}</Typography>
              </Button>
            </DialogActions>
          </div>
        </div>
      </Dialog>
    </div>
  )
}
