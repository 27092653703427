import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { Button, Grid, IconButton, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { isUsingMobile } from '../../helpers/utils'
import './page-header.css'

interface IPageHeader {
  title: string
  confirmText: string
  backText?: string
  backNavigation?: string
  onConfirm: () => void
  ctaCallback?: () => void
  disabled?: any
}

const PageHeader = (props: IPageHeader) => {
  const navigate = useNavigate()
  const { title, backNavigation, confirmText, backText, onConfirm, ctaCallback, disabled } = props

  const handleCancelClick = () => {
    backNavigation && navigate(backNavigation)
    if (ctaCallback) {
      ctaCallback()
    }
  }

  return (
    <Grid container mb={2} mt={2}>
      <Grid item flexGrow={2}>
        <Typography variant='h1' component='h1'>
          {title}
        </Typography>
      </Grid>
      <Grid item>
        {backText && !isUsingMobile() && (
          <Button
            color='primary'
            variant='outlined'
            onClick={handleCancelClick}
            sx={{ marginRight: 1 }}
          >
            {backText}
          </Button>
        )}
        {backText && backNavigation && isUsingMobile() && (
          <IconButton onClick={handleCancelClick}>
            <ArrowBackIosNewIcon />
          </IconButton>
        )}
        {confirmText && (
          <Button variant='contained' color='primary' onClick={onConfirm} disabled={disabled}>
            {confirmText}
          </Button>
        )}
      </Grid>
    </Grid>
  )
}

export default PageHeader
