import { Box, Grid, TextField } from '@mui/material'
import { ICustomer } from '../../../types/types'

interface AccountInfoFormProps {
  customer: ICustomer
  setCustomer: (customer: ICustomer) => void
}

const AccountInfoForm = ({ customer, setCustomer }: AccountInfoFormProps) => {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextField
            value={customer.name}
            label='Customer / Company name'
            placeholder='Enter your Customer / Company name'
            fullWidth
            required
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              setCustomer({
                ...customer,
                name: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            value={customer.primaryEmail}
            label='Email'
            placeholder="Enter your customer's Email"
            onChange={(e) =>
              setCustomer({
                ...customer,
                primaryEmail: e.target.value,
              })
            }
            required
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            value={customer.primaryFirstName}
            label='First name'
            placeholder="Enter your customer's First Name"
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              setCustomer({
                ...customer,
                primaryFirstName: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            value={customer.primaryLastName}
            label='Last name'
            placeholder='Enter your customer Last Name'
            fullWidth
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              setCustomer({
                ...customer,
                primaryLastName: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            label='Phone number'
            placeholder="Enter your customer's Last name"
            value={customer.primaryPhoneNumber}
            fullWidth
            multiline
            required
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              setCustomer({
                ...customer,
                primaryPhoneNumber: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            label='Website'
            placeholder="Enter your customer's Website URL"
            value={customer.website}
            fullWidth
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              setCustomer({
                ...customer,
                website: e.target.value,
              })
            }
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default AccountInfoForm
