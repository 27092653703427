import { Card, CardContent, Typography } from '@mui/material'
import { IActivityPayment, IActivityStatement } from '../../../types/types'
import { isUsingMobile } from '../../../helpers/utils'
import { formatDate, formatMoney } from '../../../helpers/formatPrice'
import { formatTime } from '../../../helpers/formatDate'

interface ActivityPaymentCardsProps {
  activityStatements: IActivityStatement | null
}

const ActivityPaymentCards = ({ activityStatements }: ActivityPaymentCardsProps) => {
  const isMobile = isUsingMobile()

  const getColorByStatus = (status: string) => {
    switch (status) {
      case 'PARTIAL':
        return 'var(--warning-color)'
      case 'SENT':
        return 'var(--info-color)'
      case 'PAID':
        return 'var(--success-color)'
      case 'OVERDUE':
        return 'var(--error-color)'
      case 'REFUNDED':
        return 'var(--orange-color)'
      case 'DRAFT':
      default:
        return
    }
  }

  const renderPaymentMethod = (payment: any) => {
    switch (payment.method) {
      case 'CASH':
        return 'Cash'
      case 'CHECK':
        return 'Check'
      case 'CREDIT_CARD':
        return payment.cardType
    }
  }

  const renderPaymentDetails = (payment: IActivityPayment) => {
    if (payment.method === 'CREDIT_CARD') {
      return `**** **** **** ${payment?.lastDigits}`
    }

    if (payment.method === 'CHECK') {
      return payment.checkNumber
    }
  }

  return (
    <>
      <Typography variant='h5' textAlign={isMobile ? 'center' : undefined}>
        Invoices
      </Typography>
      {activityStatements &&
        activityStatements.payments.map((payment) => {
          return (
            <Card
              key={payment.publicId}
              sx={{
                color: 'var(--secondary-grey)',
                mb: 1,
                '& .MuiTypography-root': { fontSize: '14px' },
              }}
            >
              <CardContent>
                <Typography>Inv. ID/date</Typography>
                <Typography color='var(--grey-fonts)' mb={1}>
                  <Typography>#{payment.invoice.name}</Typography>
                  <Typography>{formatDate(payment.invoice.periodStart)}</Typography>
                </Typography>
                <Typography>Status</Typography>
                <Typography color={getColorByStatus(payment.status)} mb={1}>
                  {payment.status}
                </Typography>
                <Typography>Amount</Typography>
                <Typography color='var(--grey-fonts)' mb={1}>
                  {formatMoney(payment.amount)}
                </Typography>
                <Typography>Method</Typography>
                <Typography color='var(--grey-fonts)' mb={1}>
                  {renderPaymentMethod(payment)}
                </Typography>
                <Typography>Payment details</Typography>
                <Typography color='var(--grey-fonts)' mb={1}>
                  {renderPaymentDetails(payment)}
                </Typography>
                <Typography>Payment date</Typography>
                <Typography color='var(--grey-fonts)'>
                  <Typography>{formatDate(payment.date)}</Typography>
                  <Typography color={getColorByStatus(payment.status)}>
                    {formatTime(payment.date)}
                  </Typography>
                </Typography>
              </CardContent>
            </Card>
          )
        })}
    </>
  )
}

export default ActivityPaymentCards
