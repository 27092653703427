import { Box, Chip, InputAdornment, Stack, TextField } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import { useEffect } from 'react'
import { INewPayment } from '../../../types/types'

interface ManualPaymentFormProps {
  payment: INewPayment
  setPaymentMethod: (paymentMethod: string) => (e: React.MouseEvent<HTMLElement>) => void
  onChangeAmount: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const ManualPaymentForm = ({
  payment,
  setPaymentMethod,
  onChangeAmount,
}: ManualPaymentFormProps) => {
  useEffect(() => {
    setPaymentMethod('CASH')
  }, [])

  return (
    <Box m={3}>
      <TextField
        label='Invoice amount'
        type='number'
        fullWidth
        value={payment.amount}
        onChange={onChangeAmount}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        sx={{ mb: 2 }}
        InputProps={{
          startAdornment: <InputAdornment position='start'>$</InputAdornment>,
        }}
        InputLabelProps={{ shrink: true }}
      />

      <Stack
        direction='row'
        spacing={1}
        sx={{
          '& .MuiChip-colorSuccess': { backgroundColor: 'var(--success-bg-color)' },
          '& .MuiChip-colorSuccess:hover': {
            backgroundColor: 'var(--secondary-grey)',
            color: '#fff',
          },
        }}
      >
        <Chip
          label='Cash'
          icon={payment.method === 'CASH' ? <CheckIcon /> : undefined}
          color={payment.method === 'CASH' ? 'success' : undefined}
          onClick={setPaymentMethod('CASH')}
        />
        <Chip
          label='Check'
          icon={payment.method === 'CHECK' ? <CheckIcon /> : undefined}
          color={payment.method === 'CHECK' ? 'success' : undefined}
          onClick={setPaymentMethod('CHECK')}
        />
        <Chip
          label='Other'
          icon={payment.method === 'OTHER' ? <CheckIcon /> : undefined}
          color={payment.method === 'OTHER' ? 'success' : undefined}
          onClick={setPaymentMethod('OTHER')}
        />
      </Stack>
    </Box>
  )
}

export default ManualPaymentForm
