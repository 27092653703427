import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NoDataDisplay from '../../../components/noDataDisplay/noDataDisplay';
import UserTableGrid from '../../../components/table-card/userTable';
import { saveAllUsers } from '../../../redux/actions/auth.actions';
import { saveRoles } from '../../../redux/actions/settings.actions';
import { AppState } from '../../../redux/store';
import './users.css';
import ContentHeader from '../../../components/content-header';

const Users = () => {
	const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState<boolean>(false);
	const users = useSelector((state: AppState) => state.authReducer.users);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(saveAllUsers());
		dispatch(saveRoles());
	}, []);

	return (
		<>
			{users.length > 0 ? (
				<div>
					<ContentHeader title="Users" />
					<UserTableGrid
						isOpenDeleteDialog={isOpenDeleteDialog}
						setIsOpenDeleteDialog={setIsOpenDeleteDialog}
					/>
				</div>
			) : (
				<NoDataDisplay
					title="No data to display"
					description="Add users and manage who have permissions<br /> and access to your business account."
				/>
			)}
		</>
	);
};

export default Users;
