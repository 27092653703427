import {
  Box,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import BaseModal from '../../../components/modal/modal'
import { IJournalEntry } from '../../../types/types'
import JournalEntryModalFooter from './footer'
import JournalEntryModalHeaderButtons from './headerButtons'
import { formatDate, formatMoney } from '../../../helpers/formatPrice'
import DownloadAttachment from '../../../components/attachment/download'
import { getCdnUrl } from '../../../helpers/utils'

interface IJournalEntryModalProps {
  journalEntry: IJournalEntry
  showModal: boolean
  setShowModal: (showModal: boolean) => void
  onEditClick?: () => void
  onDeleteClick?: () => void
  onDuplicateClick: () => void
}

const JournalEntryViewModal = ({
  journalEntry,
  showModal,
  setShowModal,
  onEditClick,
  onDuplicateClick,
  onDeleteClick,
}: IJournalEntryModalProps) => {
  const totalDebit = journalEntry.items.reduce((prev, item) => prev + item.debitAmount, 0)
  const totalCredit = journalEntry.items.reduce((prev, item) => prev + item.creditAmount, 0)
  const difference = totalCredit - totalDebit

  const handleDownloadAttachment = () => {
    const attachmentFileName = journalEntry.attachmentFileName as string
    const downloadUrl = getCdnUrl(`journal_entries/${journalEntry.publicId}/${attachmentFileName}`)
    const link = document.createElement('a')

    if (downloadUrl !== undefined) {
      link.href = downloadUrl
      link.download = attachmentFileName
      link.click()
    }
  }

  return (
    <BaseModal
      title='View'
      isModalOpen={showModal}
      handleCloseModal={() => setShowModal(false)}
      body={
        <Box m={1}>
          <Card sx={{ color: 'var(--secondary-grey)' }}>
            <CardContent>
              <Grid container>
                <Grid item xs={4}>
                  <Typography fontWeight={600}>Journal Date</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>{formatDate(journalEntry.date)}</Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  <Typography fontWeight={600}>Description</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>{journalEntry.description}</Typography>
                </Grid>
              </Grid>
              <TableContainer>
                <Table sx={{ my: 2 }}>
                  <TableHead>
                    <TableRow
                      sx={{
                        backgroundColor: 'var(--background-color)',
                        '.MuiTableCell-root': {
                          color: 'var(--secondary-grey)',
                        },
                      }}
                    >
                      <TableCell>Account</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Debit</TableCell>
                      <TableCell>Credit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {journalEntry.items.map((item) => (
                      <TableRow key={item.publicId}>
                        <TableCell>{item.account.name}</TableCell>
                        <TableCell>{item.description}</TableCell>
                        <TableCell>
                          {item.debitAmount ? formatMoney(item.debitAmount) : ''}
                        </TableCell>
                        <TableCell>
                          {item.creditAmount ? formatMoney(item.creditAmount) : ''}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter
                    sx={{ '.MuiTableCell-root': { border: 'none', py: 0, textWrap: 'nowrap' } }}
                  >
                    <TableRow sx={{ '.MuiTableCell-root': { pt: '16px !important' } }}>
                      <TableCell colSpan={2}></TableCell>
                      <TableCell>Total Debits</TableCell>
                      <TableCell>{formatMoney(totalDebit)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}></TableCell>
                      <TableCell>Total Credits</TableCell>
                      <TableCell>{formatMoney(totalCredit)}</TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        '.MuiTableCell-root': {
                          color: difference === 0 ? 'var(--green)' : 'var(--error-color)',
                          fontWeight: 600,
                        },
                      }}
                    >
                      <TableCell colSpan={2}></TableCell>
                      <TableCell>DIFFERENCE</TableCell>
                      <TableCell>{formatMoney(difference)}</TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
              <Typography fontWeight={600}>Notes</Typography>
              <Typography>{journalEntry.memo}</Typography>
              {journalEntry.attachmentFileName && (
                <DownloadAttachment
                  filename={journalEntry.attachmentFileName}
                  handleDownloadClick={handleDownloadAttachment}
                />
              )}
            </CardContent>
          </Card>
        </Box>
      }
      headerButtons={
        <JournalEntryModalHeaderButtons actions={{ onDuplicateClick, onDeleteClick }} />
      }
      footer={
        <JournalEntryModalFooter
          onEditClick={onEditClick}
          onCancelClick={() => setShowModal(false)}
        />
      }
      size='large'
    />
  )
}

export default JournalEntryViewModal
