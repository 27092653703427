import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRowSpacingParams,
  GridTreeNode,
} from '@mui/x-data-grid'
import { IEstimate } from '../../types/types'
import { useCallback, useMemo, useState } from 'react'
import TableStyles from '../../components/table-card/table-styles'
import '../../components/table-card/table-card.css'
import { Box, Chip, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { formatDate, formatDateFromNow, formatMoney } from '../../helpers/formatPrice'
import { isUsingMobile } from '../../helpers/utils'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import useAuthorization from '../../helpers/useAuthorization'

interface EstimatesTableProps {
  estimates: IEstimate[]
  onSelectedEstimate: (estimate: IEstimate | undefined) => void
  onEditClick?: () => void
  onDuplicateClick?: () => void
  onPrintClick?: () => void
  onConvertClick?: () => void
  onSendClick?: () => void
  onExportAsPDFClick?: () => void
  onDeleteClick?: () => void
  onRowClick: (estimate: IEstimate) => void
}

const EstimatesTable = ({
  estimates,
  onEditClick,
  onDuplicateClick,
  onPrintClick,
  onConvertClick,
  onSendClick,
  onExportAsPDFClick,
  onDeleteClick,
  onSelectedEstimate,
  onRowClick,
}: EstimatesTableProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openItemMenu = Boolean(anchorEl)
  const isMobile = isUsingMobile()
  const { isAuthorized } = useAuthorization()

  const handleMenuButtonClick = useCallback(
    (estimate: IEstimate) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      onSelectedEstimate(estimate)
      setAnchorEl(event.currentTarget)
    },
    [onSelectedEstimate],
  )

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleSendClick = () => {
    handleCloseMenu()
    onSendClick && onSendClick()
  }

  const handleDuplicateClick = () => {
    handleCloseMenu()
    onDuplicateClick && onDuplicateClick()
  }

  const handlePrintClick = () => {
    handleCloseMenu()
    onPrintClick && onPrintClick()
  }

  const handleDeleteClick = () => {
    handleCloseMenu()
    onDeleteClick && onDeleteClick()
  }

  const handleConvertClick = () => {
    handleCloseMenu()
    onConvertClick && onConvertClick()
  }

  const handleExportAsPDFClick = () => {
    handleCloseMenu()
    onExportAsPDFClick && onExportAsPDFClick()
  }

  const getColorByStatus = (status: string) => {
    switch (status) {
      case 'SENT':
        return 'success'
      case 'SAVED':
        return 'warning'
      case 'EXPIRED':
        return 'error'
    }
  }

  const columns = useMemo<GridColDef<IEstimate>[]>(
    () => [
      {
        field: 'name',
        headerName: 'ID',
        flex: 1,
        sortable: false,
        renderCell: (param) => `#${param.row.name}`,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        sortable: false,
        renderCell: (param) => {
          return isMobile ? (
            <FiberManualRecordIcon
              sx={{
                '&.MuiSvgIcon-colorSuccess': {
                  color: 'var(--success-color)',
                  borderRadius: '50%',
                  backgroundColor: 'var(--success-bg-color)',
                },
                '&.MuiSvgIcon-colorWarning': {
                  color: 'var(--warning-color)',
                  borderRadius: '50%',
                  backgroundColor: 'var(--warning-bg-color)',
                },
                '&.MuiSvgIcon-colorError': {
                  color: 'var(--error-color)',
                  borderRadius: '50%',
                  backgroundColor: 'var(--error-bg-color)',
                },
                '&.MuiSvgIcon-root': {
                  margin: 'auto',
                },
              }}
              color={getColorByStatus(param.row.status || '')}
            />
          ) : (
            <Chip
              sx={{
                '&.MuiChip-colorSuccess': {
                  backgroundColor: 'var(--success-bg-color)',
                  color: 'var(--success-color)',
                },
                '&.MuiChip-colorWarning': {
                  backgroundColor: 'var(--warning-bg-color)',
                  color: 'var(--warning-color)',
                },
                '&.MuiChip-colorError': {
                  backgroundColor: 'var(--error-bg-color)',
                  color: 'var(--error-color)',
                },
              }}
              color={getColorByStatus(param.row.status || '')}
              label={param.row.status}
            />
          )
        },
      },
      {
        field: 'customerName',
        headerName: 'Customer Name',
        flex: isMobile ? 3 : 2,
        sortable: false,
      },
      {
        field: 'customerEmail',
        headerName: 'Customer Email',
        flex: 3,
        sortable: false,
      },
      {
        field: 'total',
        headerName: 'Amount',
        flex: 2,
        sortable: false,
        renderCell: (param) => {
          return param.row.total ? formatMoney(param.row.total) : ''
        },
      },
      {
        field: 'createdAt',
        headerName: 'Created At',
        flex: 2,
        sortable: false,
        renderCell: (param) => {
          return (
            <Box>
              <Typography>{formatDate(param.row.createdAt)}</Typography>
              {param.row.updatedAt && param.row.updatedAt !== param.row.createdAt && (
                <Typography
                  component='small'
                  variant='subtitle2'
                  sx={{ color: 'var(--gray3-color)' }}
                >
                  Updated: {formatDateFromNow(param.row.updatedAt)}
                </Typography>
              )}
            </Box>
          )
        },
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1.5,
        sortable: false,
        renderCell: (param) => (
          <IconButton
            aria-label='more'
            id='long-button'
            aria-controls={openItemMenu ? 'long-menu' : undefined}
            aria-expanded={openItemMenu ? 'true' : undefined}
            aria-haspopup='true'
            onClick={handleMenuButtonClick(param.row)}
            sx={{
              justifyItems: 'right',
            }}
          >
            <MoreVertIcon />
          </IconButton>
        ),
      },
    ],
    [handleMenuButtonClick, openItemMenu, isMobile],
  )

  const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: 5,
    }
  }, [])

  return (
    <>
      <DataGrid
        rows={estimates}
        columns={columns}
        disableColumnMenu
        getRowId={(estimate) => estimate.publicId || ''}
        hideFooter
        getRowSpacing={getRowSpacing}
        rowHeight={65}
        initialState={{
          columns: {
            columnVisibilityModel: {
              name: !isMobile,
              createdAt: !isMobile,
              customerEmail: !isMobile,
            },
          },
        }}
        getCellClassName={(params: GridCellParams<any, IEstimate, IEstimate, GridTreeNode>) => {
          return params.field !== 'actions' ? 'rowStyle' : ''
        }}
        onRowClick={(params) => onRowClick(params.row)}
        sx={TableStyles}
      />
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={openItemMenu}
        onClose={handleCloseMenu}
        sx={{
          '& .MuiMenuItem-root': {
            padding: { xs: '1rem', md: '1rem 4rem 1rem 2rem' },
          },
          '& .MuiMenuItem-root:hover': {
            backgroundColor: 'var(--secondary1-color)',
            color: 'var(--primary1-color)',
          },
        }}
      >
        {isAuthorized('write_estimate') && <MenuItem onClick={onEditClick}>Edit</MenuItem>}
        {isAuthorized('write_estimate') && (
          <MenuItem onClick={handleDuplicateClick}>Duplicate</MenuItem>
        )}
        <MenuItem onClick={handlePrintClick}>Print</MenuItem>
        {isAuthorized('write_invoice') && (
          <MenuItem onClick={handleConvertClick}>Convert to invoice</MenuItem>
        )}
        {isAuthorized('write_estimate') && <MenuItem onClick={handleSendClick}>Send</MenuItem>}
        <MenuItem onClick={handleExportAsPDFClick}>Export as PDF</MenuItem>
        {isAuthorized('write_estimate') && (
          <MenuItem sx={{ color: 'var(--error-color)' }} onClick={handleDeleteClick}>
            Delete
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

export default EstimatesTable
