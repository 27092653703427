import checkWhiteIcon from '../../../assets/icons/check-white.svg'

const DialogSteps = ({ dialogStep }: { dialogStep: number }) => {
  return (
    <>
      <div className='customer-manage-dialog__tabs'>
        <div className='customer-manage-dialog__tab customer-manage-dialog__tab-done'>
          Vendor <br /> Type
        </div>
        <div
          className={`customer-manage-dialog__tab ${
            dialogStep ? 'customer-manage-dialog__tab-done' : ''
          }`}
        >
          Personal <br /> info
        </div>
        <div
          className={`customer-manage-dialog__tab ${
            dialogStep > 1 ? 'customer-manage-dialog__tab-done' : ''
          }`}
        >
          Billing <br /> info
        </div>
      </div>
      <div className='customer-manage-dialog__tabs-stepper'>
        <div
          className={`customer-manage-dialog__stepper-circle ${
            dialogStep ? 'customer-manage-dialog__stepper-active' : ''
          }`}
        >
          <img src={checkWhiteIcon} />
        </div>
        <div className='customer-manage-dialog__stepper-line'></div>
        <div
          className={`customer-manage-dialog__stepper-circle ${
            dialogStep > 1 ? 'customer-manage-dialog__stepper-active' : ''
          }`}
        >
          <img src={checkWhiteIcon} />
        </div>
        <div className='customer-manage-dialog__stepper-line'></div>
        <div
          className={`customer-manage-dialog__stepper-circle ${
            dialogStep > 2 ? 'customer-manage-dialog__stepper-active' : ''
          }`}
        >
          <img src={checkWhiteIcon} />
        </div>
      </div>
    </>
  )
}

export default DialogSteps
