import { Button } from '@mui/material'

interface IClearItems {
  handleOnClick: any
  text: string
}

const ClearItemsButtons = (props: IClearItems) => {
  const { handleOnClick, text } = props
  return (
    <Button
      sx={{
        width: 'auto',
        border: '2px solid',
        borderColor: 'var(--error-color)',
        color: 'var(--error-color)',
        fontWeight: 600,
        '&:hover': {
          borderColor: 'var(--error-color)',
          border: '2px solid',
          backgroundColor: 'var(--error-bg-color)',
        },
      }}
      variant='outlined'
      onClick={handleOnClick}
      fullWidth
    >
      {text}
    </Button>
  )
}

export default ClearItemsButtons
