import { Button, Grid } from '@mui/material'
import BaseModal from '../../../components/modal/modal'
import RoleForm from './role-form'
import { useState, useMemo } from 'react'
import { IRole } from '../../../types/types'
import { isUsingMobile } from '../../../helpers/utils'
import useRootState from '../../../helpers/useRootAuthorization'

interface IRoleModalProps {
  showModal: boolean
  setShowModal: (showModal: boolean) => void
  onCancelClick: React.MouseEventHandler<HTMLElement>
  onConfirmClick: (role: IRole) => void
  role: IRole | undefined
  setRole: (role: IRole | undefined) => void
  resetRole: () => void
  isEdit: boolean
}

const RoleModal = ({
  showModal,
  onCancelClick,
  onConfirmClick,
  setShowModal,
  role,
  setRole,
  resetRole,
  isEdit,
}: IRoleModalProps) => {
  const { isRootAccount } = useRootState()
  const isEditable = (role?.isDefault && isRootAccount()) || !role?.isDefault
  const [formErrors, setFormErrors] = useState({
    name: '',
    description: '',
    permissions: '',
  })

  const handleCloseModal = () => {
    resetRole()
    setShowModal(false)
  }

  const handleConfirmClick = () => {
    role && onConfirmClick(role)
    resetRole()
  }

  const isDisabledSaveButton = useMemo(() => {
    return (
      Object.values(formErrors).some((error) => error !== '') ||
      !role?.name ||
      !role?.description ||
      role?.permissions.length === 0
    )
  }, [formErrors, role])

  return (
    <BaseModal
      title={isEdit ? (isEditable ? 'Edit role' : 'View role') : 'Add a new role'}
      isModalOpen={showModal}
      body={
        <RoleForm
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          role={role || { name: '', description: '', permissions: [] }}
          setRole={setRole}
          isEditable={isEditable}
        />
      }
      handleCloseModal={handleCloseModal}
      size={isUsingMobile() ? 'xs' : 'small'}
      footer={
        isEditable ? (
          <Grid
            container
            justifyContent={{ xs: 'normal', md: 'right' }}
            alignItems={{ xs: 'normal', md: 'center' }}
            flexDirection={{ xs: 'column-reverse', md: 'row' }}
          >
            <Grid item padding={{ xs: '1rem', md: '0rem' }}>
              <Button color='primary' variant='outlined' onClick={onCancelClick} fullWidth>
                Cancel
              </Button>
            </Grid>
            <Grid item padding={{ xs: '1rem 1rem 0 1rem', md: '1rem' }}>
              <Button
                disabled={isDisabledSaveButton}
                color='primary'
                variant='contained'
                onClick={handleConfirmClick}
                fullWidth
              >
                {isEdit ? 'Edit role' : 'Add new role'}
              </Button>
            </Grid>
          </Grid>
        ) : undefined
      }
    />
  )
}

export default RoleModal
