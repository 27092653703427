import { DialogContent, DialogContentText, IconButton, Typography } from '@mui/material'
import greenCheck from '../../assets/images/green-check-box.png'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { isUsingMobile } from '../../helpers/utils'
import CloseIcon from '@mui/icons-material/Close';


interface ISuccessDialog {
  isOpen: boolean
  setAssistanceSuccess: (value:boolean) => void
}



export default function SuccessDialog(props: ISuccessDialog) {
  const {
    isOpen,
    setAssistanceSuccess,
  } = props

  const isMobile = isUsingMobile()

  return (
    <div>
      <Dialog open={isOpen} onClose={()=> setAssistanceSuccess(false)} aria-labelledby='responsive-dialog-title'>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            padding: !isMobile ? '30px 80px' : '30px 0px',
            borderRadius: "10px"
          }}
        >
           <IconButton
            aria-label="close"
            style={{
              position: 'absolute',
              right: 0,
              top: 0,
            }}
            onClick={() => setAssistanceSuccess(false)}
          >
            <CloseIcon />
          </IconButton>
               <img
                        src={greenCheck}
                      />
          <DialogTitle style={{ textAlign: 'center' }}>Thank you for your feedback</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div style={{ textAlign: 'center' }}>We’ve recorded your feedback, our team will carefully review it and may get back to you for additional information. </div>
            </DialogContentText>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  )
}
