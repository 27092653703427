import CircleIcon from '@mui/icons-material/Circle';
import { Button, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import {
	DataGrid,
	GridCellParams,
	gridClasses,
	GridColDef,
	GridTreeNode,
} from '@mui/x-data-grid';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import api from '../../api/api';
import { errorHandler } from '../../helpers/errorHandler';
import { isUsingMobile } from '../../helpers/utils';
import { saveAllUsers } from '../../redux/actions/auth.actions';
import { AppState } from '../../redux/store';
import { IRole, IUser } from '../../types/types';
import DeleteButtonTable from '../delete-icon/deleteIcon';
import ConfirmDialog from '../dialog/dialog';
import BaseModal from '../modal/modal';
import PlusButton from '../plus-button/plus-button';
import './table-card.css';
import UserView from './userView';

export default function UserTableGrid(props: any) {
	const { isOpenDeleteDialog, setIsOpenDeleteDialog } = props;
	const users: IUser[] = useSelector(
		(state: AppState) => state.authReducer.users
	);
	const initialRole: IRole = {
		name: '',
		description: '',
		permissions: [],
	};
	const [userPublicId, setUserPublicId] = useState<string>('');
	const [editedUser, setEditedUser] = useState<IUser>({
		name: '',
		email: '',
		role: initialRole,
		confirmed: '',
	});
	const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);
	const [isEdit, setIsEdit] = useState(false);
	const dispatch = useDispatch();
	const [formErrors, setFormErrors] = useState({
		name: '',
		email: '',
		role: '',
	});

	const handleDeleteUser = () => {
		api
			.deleteUser(userPublicId)
			.then(() => {
				toast.success(`User deleted`, {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					draggable: true,
					progress: undefined,
				});

				dispatch(saveAllUsers());
			})
			.catch((err: any) => errorHandler(err));
		setIsOpenDeleteDialog(false);
		setUserPublicId('');
		resetUser();
	};

	const handleSaveUser = () => {
		const newUser: IUser = {
			email: editedUser.email,
			name: editedUser.name,
			rolePublicId: editedUser.role?.publicId,
			confirmed: editedUser.confirmed,
		};

		const apiCall = isEdit
			? api.updateUser(userPublicId, newUser)
			: api.inviteUser(newUser);
		apiCall
			.then(() => {
				const successMessage = isEdit
					? 'User updated successfully'
					: `The temporary password was sent to the ${newUser.email}`;
				toast.success(successMessage, {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					draggable: true,
					progress: undefined,
				});
				dispatch(saveAllUsers());
				setIsOpenEditModal(false);
			})
			.catch((error) => errorHandler(error));
		setUserPublicId('');
		resetUser();
	};

	const handleDeleted = (e: Event, publicId: any) => {
		e.preventDefault();
		e.stopPropagation();
		setUserPublicId(publicId);
		setIsOpenDeleteDialog(true);
	};

	const handleAddUser = () => {
		setIsOpenEditModal(true);
		setIsEdit(false);
	};

	const getBoxStyles = (status: string) => {
		const backgroundColor = status === 'Invited' ? '#FFFAEB' : '#E5F7E7';
		const color = status === 'Invited' ? '#E6B117' : '#00B211';

		return {
			backgroundColor,
			color,
			borderRadius: '10px',
			padding: '4px',
		};
	};

	const isMobile = isUsingMobile();

	const columns = useMemo<GridColDef<IUser>[]>(
		() => [
			{
				field: 'confirmed',
				headerName: 'Status',
				flex: 0.6,
				sortable: false,
				valueGetter: (param) => (param.value ? 'Confirmed' : 'Invited'),
				renderCell: (param) => {
					if (!isMobile) {
						return (
							<Box
								sx={{
									...getBoxStyles(
										param.row.confirmed ? 'Confirmed' : 'Invited'
									),
								}}
							>
								<Typography sx={{ fontSize: '14px !important' }}>
									{param.row.confirmed ? 'Confirmed' : 'Invited'}
								</Typography>
							</Box>
						);
					} else {
						return (
							<Box>
								<CircleIcon
									sx={{ color: param.row.confirmed ? '#00B211' : '#E6B117' }}
								/>
							</Box>
						);
					}
				},
			},
			{
				field: 'name',
				headerName: 'Name',
				flex: 1,
				sortable: false,
			},
			{
				field: 'role',
				headerName: 'Role',
				flex: 0.5,
				sortable: false,
				valueGetter: (param) => param.value.name,
			},
			{
				field: 'email',
				headerName: 'Email',
				flex: 1,
				sortable: false,
			},
			{
				field: 'actions',
				headerName: 'Actions',
				flex: 0.5,
				sortable: false,
				renderCell: (param) => (
					<DeleteButtonTable
						handleOnDeleted={(e: Event) => {
							handleDeleted(e, param.row.publicId);
						}}
					/>
				),
			},
		],
		[handleDeleted, getBoxStyles, isMobile]
	);

	const getRowSpacing = useCallback((params: any) => {
		return {
			top: params.isFirstVisible ? 0 : 5,
			bottom: params.isLastVisible ? 0 : 5,
		};
	}, []);

	const resetUser = () => {
		setEditedUser({ name: '', email: '', role: initialRole });
		setUserPublicId('');
	};

	const resetErrors = () => {
		setFormErrors({ name: '', email: '', role: '' });
	};

	const handleCancel = () => {
		setIsOpenEditModal(false);
		resetUser();
		resetErrors();
	};

	const handleConfirm = () => {
		handleSaveUser();
	};

	const isDisabledSaveButton = useMemo(() => {
		return (
			Object.values(formErrors).some((error) => error !== '') ||
			!editedUser.name ||
			!editedUser.email ||
			!editedUser.role?.publicId
		);
	}, [formErrors, editedUser]);

	const Footer = () => {
		return (
			<Grid container>
				<Grid
					container
					justifyContent={{ xs: 'normal', md: 'right' }}
					alignItems={{ xs: 'normal', md: 'center' }}
					flexDirection={{ xs: 'column-reverse', md: 'row' }}
				>
					<Grid item padding={{ xs: '1rem', md: '0rem' }}>
						<Button
							color="primary"
							variant="outlined"
							onClick={handleCancel}
							fullWidth
						>
							Cancel
						</Button>
					</Grid>
					<Grid item padding={{ xs: '1rem 1rem 0 1rem', md: '1rem' }}>
						<Button
							disabled={isDisabledSaveButton}
							color="primary"
							variant="contained"
							onClick={handleConfirm}
							fullWidth
						>
							{isEdit ? 'Edit user' : 'Add new user'}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		);
	};

	const handleOnClickRow = (params: any) => {
		setUserPublicId(params.row.publicId);
		setIsOpenEditModal(true);
		setIsEdit(true);
	};

	return (
		<Box sx={{ width: '100%' }}>
			<DataGrid
				onRowClick={(params) => handleOnClickRow(params)}
				disableColumnMenu
				getRowSpacing={getRowSpacing}
				rowHeight={65}
				hideFooter
				getRowId={(user) => user.rolePublicId || user.email}
				rows={users}
				columns={columns}
				initialState={{
					columns: {
						columnVisibilityModel: {
							email: !isMobile,
						},
					},
				}}
				getCellClassName={(
					params: GridCellParams<any, IUser, IUser, GridTreeNode>
				) => {
					return params.field !== 'actions' && params.field !== 'confirmed'
						? 'rowStyle'
						: '';
				}}
				sx={{
					border: 'none !important',
					'& .MuiDataGrid-columnHeadersInner': {
						color: 'var(--grey-fonts)',
					},
					[`& .${gridClasses.row}`]: {
						bgcolor: 'white',
						borderRadius: '10px',
						boxShadow: '0px 2px 6px rgba(77, 94, 128, 0.15)',
						alignItems: 'center',
					},
					[`& .${gridClasses.virtualScroller}`]: {
						overflow: 'visible',
						overflowX: 'visible !important',
					},
					[`& .${gridClasses.main}`]: {
						overflow: 'visible',
					},
					[`& .${gridClasses.cell}:last-child`]: {
						padding: '0px',
					},
					[`& .${gridClasses.cell}`]: {
						border: 'none',
						cursor: 'pointer',
					},
					[`& .${gridClasses.columnHeaders}`]: {
						border: 'none',
					},
					[`& .${gridClasses.footerContainer}`]: {
						border: 'none',
					},
					[`& .${gridClasses.columnSeparator}`]: {
						display: 'none',
					},
					[`& .${gridClasses.cell}:focus`]: {
						outline: 'none',
					},
				}}
			/>
			<ConfirmDialog
				isOpen={isOpenDeleteDialog}
				dialogTitle="Sure you want to delete?"
				dialogBodyContent="Deleting this item will permanently remove it from your account, and it cannot be undone."
				buttonConfirmText="Yes, Delete"
				buttonCancelText="Cancel"
				handleClose={() => setIsOpenDeleteDialog(false)}
				handleConfirm={handleDeleteUser}
			/>
			<BaseModal
				size={isUsingMobile() ? "xs" : "small"}
				title={
					isEdit ? 'Edit User Permissions' : 'Add a new user and choose a role'
				}
				body={
					<UserView
						setFormErrors={setFormErrors}
						formErrors={formErrors}
						publicId={userPublicId}
						setEditedUser={setEditedUser}
						editedUser={editedUser}
						isEdit={isEdit}
					/>
				}
				isModalOpen={isOpenEditModal}
				handleCloseModal={handleCancel}
				footer={<Footer />}
			/>
			<PlusButton tooltipText="Add User" handleOnClick={handleAddUser} />
		</Box>
	);
}
