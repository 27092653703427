import { Paper, Tooltip, Typography } from '@mui/material'
import { IBill } from '../../types/types'
import { getCdnUrl, isUsingMobile } from '../../helpers/utils'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { formatDate } from '../../helpers/formatDate'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import FileIcon from '@mui/icons-material/InsertPhotoOutlined'
import { useState } from 'react'
import { formatMoney } from '../../helpers/formatPrice'
import DownloadAttachment from '../../components/attachment/download'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F7F8FA',
    color: '#4D5E80',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const ScrollableContent = styled('div')({
  overflowY: 'auto',
  maxHeight: 'calc(100vh - 200px)',
})

interface BillPreviewProps {
  bill: IBill
}

const InvoicePreview = ({ bill }: BillPreviewProps) => {
  const [showFullDescription, setShowFullDescription] = useState(false)
  const isMobile = isUsingMobile()

  const handleMouseEnter = () => {
    setShowFullDescription(true)
  }

  const handleMouseLeave = () => {
    setShowFullDescription(false)
  }

  const handleDownloadAttachment = () => {
    const attachmentFileName = bill.attachmentFileName as string
    const downloadUrl = getCdnUrl(`bills/${bill.publicId}/${attachmentFileName}`)
    const link = document.createElement('a')
    if (downloadUrl !== undefined) {
      link.href = downloadUrl
      link.download = attachmentFileName
      link.click()
    }
  }

  return (
    <Paper sx={{ m: 1, p: 1, bgcolor: '#F7F8FA', boxShadow: 'none' }}>
      <ScrollableContent>
        <Paper sx={{ m: 1 }} style={{ padding: '8px 0 0' }}>
          {' '}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '15px',
              padding: '0 15px',
            }}
          >
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  color: '#4D5E80',
                  marginRight: '10px',
                  fontWeight: '600',
                  fontSize: '12px',
                }}
              >
                Bill to
              </div>
              <div
                style={{ color: '#4D5E80', fontSize: '12px' }}
              >{`${bill.vendor.firstName} ${bill.vendor.lastName}`}</div>
            </div>
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  color: '#4D5E80',
                  marginRight: '10px',
                  fontWeight: '600',
                  fontSize: '12px',
                }}
              >
                Bill Date
              </div>
              <div style={{ color: '#4D5E80', fontSize: '12px' }}>{formatDate(bill.date)}</div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '15px',
              padding: '0 15px',
            }}
          >
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  color: '#4D5E80',
                  marginRight: '10px',
                  fontWeight: '600',
                  fontSize: '12px',
                }}
              >
                Bill ID
              </div>
              <div style={{ color: '#4D5E80', fontSize: '12px' }}>{bill.name}</div>
            </div>
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  color: '#4D5E80',
                  marginRight: '10px',
                  fontWeight: '600',
                  fontSize: '12px',
                }}
              >
                Due Date
              </div>
              <div style={{ color: '#4D5E80', fontSize: '12px' }}>{formatDate(bill.dueDate)}</div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '15px',
              padding: '0 15px',
            }}
          >
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  color: '#4D5E80',
                  marginRight: '10px',
                  fontWeight: '600',
                  fontSize: '12px',
                }}
              >
                P.O/S.O
              </div>
              <div style={{ color: '#4D5E80', fontSize: '12px' }}>{bill.poso}</div>
            </div>
          </div>
          <TableContainer component={Paper} style={{ marginTop: '15px' }}>
            <Table aria-label='customized table'>
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{ fontSize: '12px' }}>Items *</StyledTableCell>
                  {!isMobile && (
                    <StyledTableCell sx={{ fontSize: '12px' }}>Description</StyledTableCell>
                  )}
                  <StyledTableCell sx={{ fontSize: '12px' }}>Quantity</StyledTableCell>
                  <StyledTableCell sx={{ fontSize: '12px' }}>Price</StyledTableCell>
                  <StyledTableCell sx={{ fontSize: '12px' }}>Amount</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bill.items.map((row) => (
                  <StyledTableRow key={row.product.name}>
                    <StyledTableCell style={{ fontSize: '11px' }}>
                      {row.product.name}
                    </StyledTableCell>
                    {!isMobile && (
                      <Tooltip title={showFullDescription ? row.description : ''}>
                        <StyledTableCell
                          style={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            maxWidth: '200px',
                            cursor: 'pointer',
                            fontSize: '11px',
                          }}
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        >
                          {row.description}
                        </StyledTableCell>
                      </Tooltip>
                    )}
                    <StyledTableCell style={{ fontSize: '11px' }}>{row.quantity}</StyledTableCell>
                    <StyledTableCell style={{ fontSize: '11px' }}>
                      {formatMoney(row.price)}
                    </StyledTableCell>
                    <StyledTableCell style={{ fontSize: '11px' }}>
                      {formatMoney(row.amount)}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '35px',
              padding: '0 15px',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', paddingRight: '80px' }}>
              <div
                style={{
                  color: '#4D5E80',
                  marginRight: '10px',
                  fontWeight: '600',
                  fontSize: '12px',
                }}
              >
                Notes
              </div>
              <div style={{ color: '#4D5E80', fontSize: '11px' }}>{bill.memo}</div>
            </div>
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  style={{
                    color: '#4D5E80',
                    marginRight: '10px',
                    fontWeight: '600',
                    fontSize: '12px',
                  }}
                >
                  Subtotal
                </div>
                <div style={{ color: '#4D5E80', fontSize: '12px' }}>
                  {formatMoney(bill.subTotal)}
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  style={{
                    color: 'green',
                    marginRight: '10px',
                    marginTop: '10px',
                    marginBottom: '10px',
                    fontWeight: '600',
                    fontSize: '12px',
                  }}
                >
                  Total
                </div>
                <div style={{ color: 'green', marginTop: '10px', fontSize: '12px' }}>
                  {formatMoney(bill.total)}
                </div>
              </div>
            </div>
          </div>
          {bill.attachmentFileName && (
            <DownloadAttachment
              filename={bill.attachmentFileName}
              handleDownloadClick={handleDownloadAttachment}
            />
          )}
        </Paper>
      </ScrollableContent>
    </Paper>
  )
}

export default InvoicePreview
