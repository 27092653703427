import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { AppState } from '../../redux/store'
import ContentHeader from '../../components/content-header'
import {
  saveAgedReportsDetailed,
  saveAgedReportsGeneral,
  saveCustomersReportDetailed,
  saveCustomersReportGeneral,
  saveSettledDeposit,
} from '../../redux/actions/report.actions'
import ReportsTabs from './tabs'
import AgedReceivables from './aged-receivables/agedReceivables'
import MerchantFees from './merchantFees'
import CustomersRevenue from './customers-revenue/customersRevenue'
import CustomersRevenueFiltersMenu from './customers-revenue/filtersMenu'
import { DEFAULT_DATE_FORMAT } from '../../helpers/constants'
import { isUsingMobile } from '../../helpers/utils'

const Reports = () => {
  const dispatch = useDispatch()
  const [selectedTab, setSelectedTab] = useState('AGED_RECEIVABLES')
  const agedReceivablesData = useSelector(
    (state: AppState) => state.reportReducer.agedReportGeneral,
  )
  const merchantFeesData = useSelector((state: AppState) => state.reportReducer.settledDeposit)
  const customersRevenueData = useSelector(
    (state: AppState) => state.reportReducer.customersReportGeneral,
  )
  const [filterPeriod, setFilterPeriod] = useState({
    from: '',
    to: '',
  })

  useEffect(() => {
    handleUpdateCustomersRevenue()
  }, [filterPeriod])

  useEffect(() => {
    const currentDate = moment().format(DEFAULT_DATE_FORMAT)
    dispatch(saveAgedReportsGeneral(currentDate))
    dispatch(saveAgedReportsDetailed(currentDate))
    dispatch(saveSettledDeposit())
  }, [])

  const handleUpdateCustomersRevenue = () => {
    dispatch(saveCustomersReportGeneral(filterPeriod.from, filterPeriod.to))
    dispatch(saveCustomersReportDetailed(filterPeriod.from, filterPeriod.to))
  }

  return (
    <>
      <ContentHeader title='Customers Reports' />
      <Grid container>
        <Grid item>
          <ReportsTabs
            selectedTab={selectedTab}
            onSelectedTabChange={(evt, value) => setSelectedTab(value)}
          />
        </Grid>
        <Grid item flexGrow={2} mb={isUsingMobile() ? 2 : undefined}>
          {selectedTab === 'CUSTOMERS_REVENUE' && (
            <CustomersRevenueFiltersMenu
              setFilterPeriod={setFilterPeriod}
              filterPeriod={filterPeriod}
              onUpdateClick={handleUpdateCustomersRevenue}
            />
          )}
        </Grid>
      </Grid>
      {selectedTab === 'AGED_RECEIVABLES' && agedReceivablesData && <AgedReceivables />}
      {selectedTab === 'MERCHANT_FEES' && merchantFeesData && <MerchantFees />}
      {selectedTab === 'CUSTOMERS_REVENUE' && customersRevenueData && (
        <CustomersRevenue filterPeriod={filterPeriod} />
      )}
    </>
  )
}

export default Reports
