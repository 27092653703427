import { Box, Grid, TextField } from '@mui/material'

const ContactDetailsForm = (props: any) => {
  const { company, setCompany } = props

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <Box mb={3}>
            <TextField
              type='number'
              label='Phone number'
              placeholder='Enter your Phone Number'
              value={company.phone}
              onChange={(e) =>
                setCompany({
                  ...company,
                  phone: e.target.value,
                })
              }
              required={true}
              fullWidth
            />
          </Box>
          <Box>
            <TextField
              type='number'
              label='Mobile Number'
              placeholder='Enter your Mobile Number'
              value={company.mobile}
              onChange={(e) => setCompany({ ...company, mobile: e.target.value })}
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Box mb={3}>
            <TextField
              type='number'
              label='Fax'
              placeholder='Enter your Fax Number'
              value={company.fax}
              onChange={(e) =>
                setCompany({
                  ...company,
                  fax: e.target.value,
                })
              }
              fullWidth
            />
          </Box>
          <Box>
            <TextField
              label='Website'
              placeholder='Enter your website'
              value={company.website}
              onChange={(e) => setCompany({ ...company, website: e.target.value })}
              fullWidth
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ContactDetailsForm
