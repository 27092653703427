import { Action } from '../actions/actionTypes'
import {
  SAVE_AGED_GENERAL,
  SAVE_AGED_DETAILED,
  SAVE_CUSTOMERS_REPORTS_GENERAL,
  SAVE_CUSTOMERS_REPORTS_DETAILED,
  SAVE_SETTLED_DEPOSIT_REPORTS,
  SAVE_PROFIT_LOSS_REPORTS,
  SAVE_BALANCE_SHEET_REPORTS,
  SAVE_ACCOUNT_TRANSACTION,
} from '../actions/report.action.type'
import {
  IAgedReceivableGeneral,
  IAgedReceivablesDetailed,
  ISettledDepositReport,
  ICustomerReportDetailed,
  ICustomerReportGeneral,
  ISettledDepositReportSummary,
  IProfitAndLossReport,
  IBalanceSheetReport,
  IAccountTransactionReport,
} from '../../types/types'

export interface IInitialState {
  agedReportGeneral: IAgedReceivableGeneral | null
  agedReportDetailed: IAgedReceivablesDetailed[]
  agedReportDetailedSize: number
  customersReportGeneral: ICustomerReportGeneral | null
  customersReportDetailed: ICustomerReportDetailed[]
  customersDetailedSize: number
  settledDeposit: ISettledDepositReport[]
  settledDepositSummary: ISettledDepositReportSummary | null
  settledDepositSize: number
  profitAndLoss: IProfitAndLossReport | null
  balanceSheet: IBalanceSheetReport | null
  accountTransaction: IAccountTransactionReport | null
}

const initialState: IInitialState = {
  agedReportGeneral: null,
  agedReportDetailed: [],
  agedReportDetailedSize: 0,
  customersReportGeneral: null,
  customersReportDetailed: [],
  customersDetailedSize: 0,
  settledDeposit: [],
  settledDepositSummary: null,
  settledDepositSize: 0,
  profitAndLoss: null,
  balanceSheet: null,
  accountTransaction: null,
}

const reportReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case SAVE_AGED_GENERAL:
      return {
        ...state,
        agedReportGeneral: action.payload,
      }
    case SAVE_AGED_DETAILED:
      return {
        ...state,
        agedReportDetailed: action.payload,
        agedReportDetailedSize: action.size,
      }
    case SAVE_CUSTOMERS_REPORTS_GENERAL:
      return {
        ...state,
        customersReportGeneral: action.payload,
      }
    case SAVE_CUSTOMERS_REPORTS_DETAILED:
      return {
        ...state,
        customersReportDetailed: action.payload,
        customersDetailedSize: action.size,
      }
    case SAVE_SETTLED_DEPOSIT_REPORTS:
      return {
        ...state,
        settledDeposit: action.payload,
        settledDepositSummary: {
          totalPaid: action.totalPaid,
          totalRefunded: action.totalRefunded,
          totalFee: action.totalFee,
          totalNetDeposit: action.totalNetDeposit,
        },
        settledDepositSize: action.size,
      }
    case SAVE_PROFIT_LOSS_REPORTS:
      return {
        ...state,
        profitAndLoss: action.payload,
      }
      case SAVE_BALANCE_SHEET_REPORTS:
      return {
        ...state,
        balanceSheet: action.payload,
      }
      case SAVE_ACCOUNT_TRANSACTION:
        return {
          ...state,
          accountTransaction: action.payload,
        }
    default:
      return state
  }
}

export default reportReducer
