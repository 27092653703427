/* eslint-disable max-len */
import ContentHeader from '../../components/content-header'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { saveRoles, getPermissions } from '../../redux/actions/settings.actions'
import { AppState } from '../../redux/store'
import RolesTable from '../../pages/settings/roles/roles-table'

import './roles.css'
import NoDataDisplay from '../noDataDisplay/noDataDisplay'
import { IRole } from '../../types/types'
import api from '../../api/api'
import { toast } from 'react-toastify'
import RoleModal from '../../pages/settings/roles/role-modal'
import { errorHandler } from '../../helpers/errorHandler'
import PlusButton from '../plus-button/plus-button'
import ConfirmDialog from '../dialog/dialog'
import { successToastNotification } from '../../helpers/toastNotification'

const Roles = () => {
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState<boolean>(false)
  const [rolePublicId, setRolePublicId] = useState<string>('')
  const [role, setRole] = useState<IRole | undefined>({
    name: '',
    description: '',
    permissions: [],
    isDefault: false,
  })
  const [isEdit, setIsEdit] = useState(false)

  const roles = useSelector((state: AppState) => state.settingsReducer.roles)

  useEffect(() => {
    dispatch(saveRoles())
    dispatch(getPermissions())
  }, [dispatch])

  const handleAddRoleClick = () => {
    setShowModal(true)
  }

  const handleEditClick = (role: IRole) => {
    setIsEdit(true)
    setRole(role)
    setShowModal(true)
  }

  const handleDeleteClick = (evt: Event, publicId: string, roleName: string) => {
    evt.preventDefault()
    evt.stopPropagation()
    setRolePublicId(publicId)
    setRole({
      name: roleName,
      description: '',
      permissions: [],
      isDefault: false,
    })
    setIsOpenDeleteDialog(true)
  }

  const handleDeleteConfirm = () => {
    if (!rolePublicId) {
      return
    }

    api
      .deleteRole(rolePublicId)
      .then(() => {
        successToastNotification(`${role?.name} was successfully deleted`)
        dispatch(saveRoles())
        setIsOpenDeleteDialog(false)
      })
      .catch((error: any) => {
        console.error('Error fetching role:', error)
      })
    resetRole()
  }

  const resetRole = () => {
    setRole({ name: '', description: '', permissions: [] })
    setIsEdit(false)
    setRolePublicId('')
  }

  const handleCancelClick = () => {
    resetRole()
    setShowModal(false)
  }

  const handleConfirmClick = (role: IRole) => {
    if (!role) {
      return
    }

    const apiCall = isEdit ? api.updateRole(role) : api.createRole(role)

    const successMessage = isEdit ? 'Role updated successfully' : 'Role created successfully'

    setShowModal(false)

    apiCall
      .then(() => {
        successToastNotification(successMessage)
        dispatch(saveRoles())
        dispatch(getPermissions())
      })
      .catch((err) => errorHandler(err))
    resetRole()
  }

  const handleCancelDialog = () => {
    resetRole()
    setIsOpenDeleteDialog(false)
  }

  return (
    <>
      {roles.length > 0 ? (
        <>
          <ContentHeader title='Roles &amp; Permissions' />
          <RolesTable
            roles={roles}
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
          />
        </>
      ) : (
        <NoDataDisplay
          title='No data to display'
          description='Define customized Roles and assign varied permissions to the Users.'
        />
      )}
      <RoleModal
        showModal={showModal}
        role={role}
        setRole={setRole}
        onCancelClick={handleCancelClick}
        onConfirmClick={handleConfirmClick}
        setShowModal={setShowModal}
        resetRole={resetRole}
        isEdit={isEdit}
      />
      {/* <PlusButton tooltipText='Add Role' handleOnClick={handleAddRoleClick} /> */}
      <ConfirmDialog
        isOpen={isOpenDeleteDialog}
        dialogTitle='Sure you want to delete?'
        dialogBodyContent='Deleting this item will permanently remove it from your account, and it cannot be undone.'
        buttonConfirmText='Yes, Delete'
        buttonCancelText='Cancel'
        handleClose={handleCancelDialog}
        handleConfirm={handleDeleteConfirm}
      />
    </>
  )
}

export default Roles
