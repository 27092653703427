import { Box, Button, Card, CardContent, Grid } from '@mui/material'
import ContentHeader from '../../components/content-header'
import { useEffect, useState } from 'react'
import { ICustomer } from '../../types/types'
import { useDispatch, useSelector } from 'react-redux'
import {
  saveActivityStatements,
  saveCustomers,
  saveOutstandingStatements,
} from '../../redux/actions/home.actions'
import { AppState } from '../../redux/store'
import StatementView from './statementView'
import api from '../../api/api'
import { successToastNotification } from '../../helpers/toastNotification'
import { errorHandler } from '../../helpers/errorHandler'
import CustomerStatementFilters from './filters'
import moment from 'moment'
import {
  SAVE_ACTIVITY_STATEMENTS,
  SAVE_OUTSTANDING_STATEMENTS,
} from '../../redux/actions/home.actions.type'
import ConfirmDialog from '../../components/dialog/dialog'
import { isUsingMobile } from '../../helpers/utils'

const CustomerStatements = () => {
  const dispatch = useDispatch()
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomer | null>(null)
  const [statementType, setStatementType] = useState('OUTSTANDING')
  const [fromDate, setFromDate] = useState<string | null>(null)
  const [toDate, setToDate] = useState<string | null>(null)
  const customers = useSelector((state: AppState) => state.homeReducer.customers)
  const company = useSelector((state: AppState) => state.homeReducer.company)
  const activityStatements = useSelector((state: AppState) => state.homeReducer.activityStatements)
  const outstandingStatements = useSelector(
    (state: AppState) => state.homeReducer.outstandingStatements,
  )
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false)
  const isMobile = isUsingMobile()

  useEffect(() => {
    dispatch(saveCustomers())
  }, [])

  const handleSendConfirm = () => {
    if (!selectedCustomer) {
      return
    }

    if (statementType === 'OUTSTANDING') {
      api
        .sendOutstandingStatement(selectedCustomer.publicId)
        .then(() => successToastNotification(`Outstanding statements was successfully sent`))
        .catch((err) => errorHandler(err))
    }

    if (statementType === 'ACTIVITY' && fromDate && toDate) {
      api
        .sendActivityStatements(
          selectedCustomer.publicId,
          moment(fromDate).format('YYYY-MM-DD'),
          moment(toDate).format('YYYY-MM-DD'),
        )
        .then(() => successToastNotification(`Activity statements was successfully sent`))
        .catch((err) => errorHandler(err))
    }

    setIsOpenConfirmDialog(false)
  }

  const handleCreateStatementClick = () => {
    if (selectedCustomer && statementType === 'OUTSTANDING') {
      dispatch(saveOutstandingStatements(selectedCustomer.publicId))
    }

    if (selectedCustomer && statementType === 'ACTIVITY' && fromDate && toDate) {
      dispatch(
        saveActivityStatements(
          selectedCustomer.publicId,
          moment(fromDate).format('YYYY-MM-DD'),
          moment(toDate).format('YYYY-MM-DD'),
        ),
      )
    }
  }

  const showActivity = () => {
    return statementType === 'ACTIVITY' && !!activityStatements
  }

  const showOutstanding = () => {
    return statementType === 'OUTSTANDING' && !!outstandingStatements
  }

  const handleStatementTypeChange = (value: string) => {
    setStatementType(value)
    dispatch({
      type: SAVE_OUTSTANDING_STATEMENTS,
      payload: null,
    })
    dispatch({
      type: SAVE_ACTIVITY_STATEMENTS,
      payload: null,
    })
  }

  return (
    <>
      <ContentHeader title='Customer Statements' />
      <Box mb={1}>
        <Card sx={{ '&.MuiPaper-root': { boxShadow: 'none' } }}>
          <CardContent sx={{ pb: '16px !important' }}>
            <CustomerStatementFilters
              customers={customers}
              selectedCustomer={selectedCustomer}
              setSelectedCustomer={setSelectedCustomer}
              statementType={statementType}
              setStatementType={handleStatementTypeChange}
              onCreateStatementClick={handleCreateStatementClick}
              fromDate={fromDate}
              toDate={toDate}
              setFromDate={setFromDate}
              setToDate={setToDate}
              showOutstanding={showOutstanding()}
              showActivity={showActivity()}
            />
          </CardContent>
        </Card>
      </Box>
      {company && selectedCustomer && (showOutstanding() || showActivity()) && (
        <Card>
          <CardContent>
            <StatementView customer={selectedCustomer} type={statementType} />
            <Grid
              container
              justifyContent={isMobile ? 'center' : 'right'}
              flexDirection={isMobile ? 'column' : 'row'}
            >
              <Grid item>
                <Button
                  variant='contained'
                  onClick={() => setIsOpenConfirmDialog(true)}
                  fullWidth={isMobile}
                >
                  Send Statement
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
      <ConfirmDialog
        isOpen={isOpenConfirmDialog}
        dialogTitle='Sure you want to send statement?'
        dialogBodyContent='The customer will receive the statement via email.'
        buttonConfirmText='Yes, Send'
        buttonCancelText='Cancel'
        handleClose={() => setIsOpenConfirmDialog(false)}
        handleConfirm={handleSendConfirm}
      />
    </>
  )
}

export default CustomerStatements
