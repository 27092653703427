import { Paper } from '@mui/material'
import { ICustomer, IInvoice, IScheduledInvoice } from '../../../types/types'
import { isUsingMobile } from '../../../helpers/utils'
import CompanyCard from './companyCard'
import CustomerCard from './customerCard'
import InvoicePreviewHeader from './header'
import InvoicePreviewDetailCards from './detailCards'
import InvoicePreviewDetailTable from './detailTable'
import InvoicePreviewFooter from './footer'
import InvoicePreviewFooterCard from './footerCard'

interface InvoicePreviewProps {
  invoice: IInvoice | IScheduledInvoice
  company: any
  customer?: any
}

const InvoicePreview = ({ invoice, company, customer }: InvoicePreviewProps) => {
  const isMobile = isUsingMobile()

  return (
    <Paper sx={{ m: 1, p: 1 }}>
      {isMobile ? (
        <>
          <CompanyCard company={company} />
          <CustomerCard invoice={invoice} />
        </>
      ) : (
        <InvoicePreviewHeader company={company} invoice={invoice} customer={customer} />
      )}
      {isMobile ? (
        <InvoicePreviewDetailCards invoice={invoice} />
      ) : (
        <InvoicePreviewDetailTable invoice={invoice} />
      )}
      {isMobile ? (
        <InvoicePreviewFooterCard invoice={invoice} />
      ) : (
        <InvoicePreviewFooter invoice={invoice} />
      )}
    </Paper>
  )
}

export default InvoicePreview
