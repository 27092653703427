import { Tab, Tabs } from '@mui/material'
import { isUsingMobile } from '../../helpers/utils'
import { SyntheticEvent } from 'react'

interface ReportsTabsProps {
  selectedTab: string
  onSelectedTabChange: (event: SyntheticEvent, value: string) => void
}

const ReportsTabs = ({ selectedTab, onSelectedTabChange }: ReportsTabsProps) => {
  const isMobile = isUsingMobile()
  return (
    <Tabs
      aria-label='report types'
      onChange={onSelectedTabChange}
      sx={{
        backgroundColor: '#fff',
        borderRadius: '0.5rem',
        display: 'inline-flex',
        mb: 2,
        maxWidth: '100%',
        overflowX: 'auto',
      }}
      variant={isMobile ? 'fullWidth' : 'scrollable'}
      value={selectedTab}
      scrollButtons='auto'
      allowScrollButtonsMobile
    >
      <Tab value='PROFIT_LOSS' label='Profit & Loss' />
      <Tab value='BALANCE_SHEET' label='Balance Sheet' />
      <Tab value='CASH_FLOW' label='Cash Flow' />
    </Tabs>
  )
}

export default ReportsTabs
