import { Box, Card, CardContent, Grid, TextField } from '@mui/material'
import SelectorMui from '../../../components/selector-mui'
import DeleteButtonTable from '../../../components/delete-icon/deleteIcon'
import { INewBill, INewBillToProduct } from '../../../types/types'
import useAuthorization from '../../../helpers/useAuthorization'

interface BillFormCardsProps {
  bill: INewBill
  onChangeBillItem: any
  onDeleteItem: any
  products: any
}

const BillFormCards = ({ bill, onChangeBillItem, onDeleteItem, products }: BillFormCardsProps) => {
  const { isAuthorized } = useAuthorization()

  return (
    <>
      {bill.items.map((item: INewBillToProduct, index: number) => {
        return (
          <Card
            key={index}
            sx={{ '&.MuiPaper-root': { boxShadow: '0 5px 10px 0 var(--secondary-grey-bg)' } }}
          >
            <CardContent>
              <Grid container spacing={2} flexDirection='column'>
                <Grid item>
                  <SelectorMui
                    isAddButton={isAuthorized('write_purchase_product')}
                    addButtonText='Add new product'
                    label='Item'
                    options={products}
                    value={item?.purchaseProductPublicId || ''}
                    onChange={onChangeBillItem(index, 'purchaseProductPublicId')}
                    onAddIconClick={() => console.log('Create new product')}
                    placeholder='Select a Service/Product'
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label='Description'
                    placeholder='Enter a description'
                    value={item.description}
                    onChange={onChangeBillItem(index, 'description')}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label='Quantity'
                    placeholder='Enter quantity'
                    type='number'
                    value={item.quantity}
                    onChange={onChangeBillItem(index, 'quantity')}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label='Price'
                      placeholder='Enter price'
                      type='number'
                      value={item.price}
                      onChange={onChangeBillItem(index, 'price')}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label='Amount'
                      placeholder='Enter amount'
                      type='number'
                      value={item.price * item.quantity}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid item container flexDirection='row-reverse'>
                  <DeleteButtonTable handleOnDeleted={onDeleteItem(index)}></DeleteButtonTable>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )
      })}
    </>
  )
}

export default BillFormCards
