import BaseModal from '../../../components/modal/modal'
import { IEstimate } from '../../../types/types'
// eslint-disable-next-line max-len
import EstimatePreview from '../preview/estimatePreview'
import EstimateModalHeaderButtons from './header-buttons'
import EstimateModalFooter from './footer'
import { isUsingMobile } from '../../../helpers/utils'
import useAuthorization from '../../../helpers/useAuthorization'

interface IEstimateModalProps {
  estimate: IEstimate
  showModal: boolean
  setShowModal: (showModal: boolean) => void
  onEditClick?: () => void
  onDuplicateClick?: () => void
  onConvertToInvoiceClick?: () => void
  onDeleteClick?: () => void
  onPrintClick?: () => void
  onSendClick?: () => void
  onExportAsPDFClick?: () => void
}

const EstimateModal = ({
  estimate,
  showModal,
  setShowModal,
  onEditClick,
  onDuplicateClick,
  onConvertToInvoiceClick,
  onDeleteClick,
  onPrintClick,
  onSendClick,
  onExportAsPDFClick,
}: IEstimateModalProps) => {
  const { isAuthorized } = useAuthorization()
  const handleCloseModal = () => {
    setShowModal(false)
  }

  return (
    <BaseModal
      title={`Estimate #${estimate.name}`}
      isModalOpen={showModal}
      handleCloseModal={handleCloseModal}
      body={<EstimatePreview estimate={estimate} />}
      headerButtons={
        <EstimateModalHeaderButtons
          actions={{
            onPrintClick,
            onExportAsPDFClick,
            onEditClick,
            onDuplicateClick,
            onConvertToInvoiceClick,
            onDeleteClick,
          }}
        />
      }
      footer={
        (isAuthorized('write_estimate') || isUsingMobile()) && (
          <EstimateModalFooter onCancelClick={handleCloseModal} onSendClick={onSendClick} />
        )
      }
      size='large'
    />
  )
}

export default EstimateModal
