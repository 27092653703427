import { toast } from 'react-toastify'

export const successToastNotification = (message: string) => {
  toast.success(message, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    draggable: true,
    progress: undefined,
  })
}
