import { useSelector } from 'react-redux'
import { AppState } from '../../../redux/store'
import './business-information.css'
import { Avatar, Card, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material'
import ContactDetails from './contact-details'
import PhysicalAddress from './physical-address'
import AccountInfo from './account-info'
import { getCdnUrl } from '../../../helpers/utils'
import { useNavigate } from 'react-router-dom'
import PageHeader from '../../../components/page-header'
import useAuthorization from '../../../helpers/useAuthorization'

const BusinessInformation = () => {
  const navigate = useNavigate()
  const { isAuthorized } = useAuthorization()

  const companyDetails = useSelector((state: AppState) => state.homeReducer.company)

  return (
    <>
      <PageHeader
        title='Business Info'
        confirmText={isAuthorized('write_company') && 'Edit'}
        onConfirm={() => navigate('/business/edit')}
      />

      <Grid container spacing={2}>
        <Grid item sm={4} xs={12}>
          <Card className='businessInfoCard'>
            <CardContent>
              <Avatar
                sx={{ width: 100, height: 100, margin: '0.5em auto' }}
                src={getCdnUrl(companyDetails?.logoFileName)}
              />
              <Typography sx={{ textAlign: 'center' }} component='h2' variant='h5' mb={1}>
                {companyDetails?.name}
              </Typography>
              <Divider />
              <Typography variant='h6' component='h2' mt={2}>
                Contact Details
              </Typography>
              {companyDetails && <ContactDetails company={companyDetails} />}
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={8} xs={12}>
          <Grid container direction='column' flexWrap='nowrap' gap='1rem'>
            <Grid item>
              <Card className='businessInfoCard'>
                <CardHeader title='Physical address'></CardHeader>
                <CardContent>
                  {companyDetails && <PhysicalAddress company={companyDetails} />}
                </CardContent>
              </Card>
            </Grid>
            <Grid item>
              <Card className='businessInfoCard'>
                <CardHeader title='Account info'></CardHeader>
                <CardContent>
                  {companyDetails && <AccountInfo company={companyDetails} />}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default BusinessInformation
