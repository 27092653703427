import {
  SAVE_ACCESS_TOKEN,
  SAVE_USER,
  SAVE_ALL_USERS,
  SAVE_USIO_API_KEY,
  SAVE_USIO_LINK,
  SAVE_CURRENT_USER,
  IS_ONBOARDING,
  IS_COMPLETE_BUSINESS,
} from '../actions/auth.actions.type'
import { Dispatch } from 'redux'
import { Action } from '../actions/actionTypes'
import { errorHandler } from '../../helpers/errorHandler'
import api from '../../api/api'
import { PAY_NOW_UNAVAILABLE } from './home.actions.type'

export const saveUser = (user: any) => {
  return {
    type: SAVE_USER,
    payload: user,
  }
}

export const saveAccessToken = (accessToken: any) => {
  return {
    type: SAVE_ACCESS_TOKEN,
    payload: accessToken,
  }
}

export const saveAllUsers = () => (dispatch: Dispatch<Action>) => {
  api
    .getAllUsers()
    .then((res) => {
      dispatch({
        type: SAVE_ALL_USERS,
        payload: res.data.body.content,
      })
    })
    .catch((err) => errorHandler(err))
}

export const saveCurrentUser = () => (dispatch: Dispatch<Action>) => {
  api
    .saveCurrentUser()
    .then((res) => {
      dispatch({
        type: SAVE_CURRENT_USER,
        payload: res.data.body,
      })
    })
    .catch((err) => errorHandler(err))
}

export const saveUSIOApiKey = (invoiceId?: string) => (dispatch: Dispatch<Action>) => {
  if (invoiceId) {
    api
      .getUSIOUnauthorizeApiKey(invoiceId)
      .then((res) => {
        dispatch({
          type: SAVE_USIO_API_KEY,
          payload: res.data.body,
        })
      })
      .catch((err) => {
        if (err.response.data.errors[0].code.includes('STATUS_IS_NOT_ALLOWED')) {
          dispatch({
            type: PAY_NOW_UNAVAILABLE,
            payload: true,
          })
        } else {
          errorHandler(err)
        }
      })
  } else {
    api
      .getUSIOApiKey()
      .then((res) => {
        dispatch({
          type: SAVE_USIO_API_KEY,
          payload: res.data.body,
        })
      })
      .catch((err) => errorHandler(err))
  }
}

export const saveUSIOLink = () => (dispatch: Dispatch<Action>) => {
  api
    .getUSIOLink()
    .then((res) => {
      dispatch({
        type: SAVE_USIO_LINK,
        payload: res.data.body,
      })
    })
    .catch((err) => errorHandler(err))
}

export const changeIsOnboarding = (state: boolean) => ({
  type: IS_ONBOARDING,
  payload: state,
})

export const changeIsCompleteBusiness = (state: boolean) => ({
  type: IS_COMPLETE_BUSINESS,
  payload: state,
})
