import { isUsingMobile } from '../../../helpers/utils'
import checkWhiteIcon from '../../../assets/icons/check-white.svg'
import { Box, TextField } from '@mui/material'
import CustomerAccountInfoTab from './account-info'
import CustomerBillingInfoTab from './billing-info'
import CustomerShippingInfoTab from './shipping-info'

interface IModalBody {
  dialogStep: number
  newCustomer: any
  handleChangeNewCustomer: (e: any, value: string, additionalValue?: string) => void
  handleIsShippingSameClick: any
  isShippingTheSame: boolean
  handlePreviousClick: () => void
  handleSaveCustomer: () => void
  handleEnableNextButton: () => any
  handleClickNextButton: () => void
  formErrors: any
  setFormErrors: (param: any) => void
}

const CustomerModalBody = ({
  formErrors,
  setFormErrors,
  dialogStep,
  newCustomer,
  handleChangeNewCustomer,
  handleIsShippingSameClick,
  isShippingTheSame,
}: IModalBody) => {
  const isMobile = isUsingMobile()

  return (
    <>
      <div className='customer-manage-dialog__wrapper'>
        <div className='customer-manage-dialog__tabs'>
          <div
            className='customer-manage-dialog__tab
					 customer-manage-dialog__tab-done'
          >
            Account
            <br /> info
          </div>
          <div
            className={`customer-manage-dialog__tab ${
              dialogStep ? 'customer-manage-dialog__tab-done' : ''
            }`}
          >
            Billing <br />
            info
          </div>
          <div
            className={`customer-manage-dialog__tab ${
              dialogStep > 1 ? 'customer-manage-dialog__tab-done' : ''
            }`}
          >
            Shipping
            <br /> info
          </div>
          <div
            className={`customer-manage-dialog__tab ${
              dialogStep > 2 ? 'customer-manage-dialog__tab-done' : ''
            }`}
          >
            Extra <br />
            info
          </div>
        </div>
        <div className='customer-manage-dialog__tabs-stepper'>
          <div
            className={`customer-manage-dialog__stepper-circle ${
              dialogStep ? 'customer-manage-dialog__stepper-active' : ''
            }`}
          >
            <img src={checkWhiteIcon} />
          </div>
          <div className='customer-manage-dialog__stepper-line'></div>
          <div
            className={`customer-manage-dialog__stepper-circle ${
              dialogStep > 1 ? 'customer-manage-dialog__stepper-active' : ''
            }`}
          >
            <img src={checkWhiteIcon} />
          </div>
          <div className='customer-manage-dialog__stepper-line'></div>
          <div
            className={`customer-manage-dialog__stepper-circle ${
              dialogStep > 2 ? 'customer-manage-dialog__stepper-active' : ''
            }`}
          >
            <img src={checkWhiteIcon} />
          </div>
          <div className='customer-manage-dialog__stepper-line'></div>
          <div className='customer-manage-dialog__stepper-circle'>
            <img src={checkWhiteIcon} />
          </div>
        </div>
        <Box
          display='flex'
          flexDirection='column'
          width={isMobile ? 'auto' : '478px'}
          margin='auto'
        >
          {dialogStep === 0 && (
            <CustomerAccountInfoTab
              newCustomer={newCustomer}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              onChangeNewCustomer={handleChangeNewCustomer}
            />
          )}
          {dialogStep === 1 && (
            <CustomerBillingInfoTab
              newCustomer={newCustomer}
              onChangeNewCustomer={handleChangeNewCustomer}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              isShippingTheSame={isShippingTheSame}
              onIsShippingSameClick={handleIsShippingSameClick}
            />
          )}
          {dialogStep === 2 && (
            <CustomerShippingInfoTab
              newCustomer={newCustomer}
              onChangeNewCustomer={handleChangeNewCustomer}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
            />
          )}
          {dialogStep === 3 && (
            <TextField
              sx={{ marginBottom: '25px' }}
              InputProps={{
                sx: {
                  '& fieldset': {
                    borderColor: 'var(--secondary-grey)',
                  },
                },
              }}
              multiline
              rows={8}
              label='Notes'
              placeholder='Enter some notes (delivery details, some issues, etc.)'
              value={newCustomer?.notes}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeNewCustomer(e, 'notes')
              }
            />
          )}
        </Box>
      </div>
    </>
  )
}

export default CustomerModalBody
