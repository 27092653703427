import { Paper } from '@mui/material';
import { IEstimate } from '../../../types/types';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux/store';
import EstimatePreviewFooter from './footer';
import EstimatePreviewHeader from './header';
import { isUsingMobile } from '../../../helpers/utils';
import EstimatePreviewDetailCards from './detailCards';
import EstimatePreviewDetailTable from './detailTable';
import CompanyCard from './companyCard';
import CustomerCard from './customerCard';
import EstimatePreviewFooterCard from './footerCard';

interface EstimatePreviewProps {
	estimate: IEstimate;
}

const EstimatePreview = ({ estimate }: EstimatePreviewProps) => {
	const company = useSelector((state: AppState) => state.homeReducer.company);
	const isMobile = isUsingMobile();

	return (
		<Paper sx={{ m: 1, p: 1 }}>
			{isMobile ? (
				<>
					<CompanyCard company={company} />
					<CustomerCard estimate={estimate} />
				</>
			) : (
				<EstimatePreviewHeader company={company} estimate={estimate} />
			)}
			{isMobile ? (
				<EstimatePreviewDetailCards estimate={estimate} />
			) : (
				<EstimatePreviewDetailTable estimate={estimate} />
			)}
			{isMobile ? (
				<EstimatePreviewFooterCard estimate={estimate} />
			) : (
				<EstimatePreviewFooter estimate={estimate} />
			)}
		</Paper>
	);
};

export default EstimatePreview;
