import { ReactInstance, useEffect, useRef, useState } from 'react'
import {
  Button,
  Chip,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material'

import CheckIcon from '@mui/icons-material/Check'

import { IBill, IFinancialAccount, INewBillPayment } from '../../../types/types'
import BaseModal from '../../../components/modal/modal'
import { formatDate } from '../../../helpers/formatPrice'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../redux/store'
import { changeIsLoading, saveFinancialAccount } from '../../../redux/actions/home.actions'
import DateField from '../../../components/date-field'
import { useReactToPrint } from 'react-to-print'
import PreviewBill from '../../../components/preview-bill'
import ConfirmDialog from '../../../components/dialog/dialog'

interface BillPaymentModalProps {
  showModal: boolean
  setShowModal: (showModal: boolean) => void
  bill: IBill
  onPaymentConfirm: (newPayment: INewBillPayment) => () => void
}

const BillPaymentModal = ({
  showModal,
  bill,
  setShowModal,
  onPaymentConfirm,
}: BillPaymentModalProps) => {
  const [newPayment, setNewPayment] = useState<INewBillPayment>({
    date: moment().toISOString(),
    method: 'CASH',
    amount: Number(bill.total) - Number(bill.amountPaid),
    checkNumber: '',
    financialAccountPublicId: '',
  });

  const [formErrors, setFormErrors] = useState({
    amount: '',
  });
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [flag, setFlag] = useState(false);
  const [options, setOptions] = useState<any[]>([]);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const preview = useRef<ReactInstance | null>(null);

  const printBills = useReactToPrint({
    content: () => preview?.current,
    onBeforePrint: () => {
      dispatch(changeIsLoading(true));
    },
    onAfterPrint: () => {
      dispatch(changeIsLoading(false));
    },
  });

  const confirmAndPrint = (newPayment: INewBillPayment) => {
    if (newPayment.method === 'CHECK') {
      setFlag(true);
    }

    setConfirmDialogOpen(true);
  };

  const handleConfirm = () => {
    const confirmFunc = onPaymentConfirm(newPayment);

    if (typeof confirmFunc === 'function') {
      confirmFunc(); 
    }

    if (flag && preview.current) {
      printBills();
      setFlag(false);
    }
    setConfirmDialogOpen(false); 
  };

  const financialAccount = useSelector((state: AppState) => state.homeReducer.financialAccount);

  const isValidForm = (): boolean => {
    if (!newPayment.financialAccountPublicId) {
      return false;
    }

    if (newPayment.method === 'CHECK' && !newPayment.checkNumber) {
      return false;
    }

    return !!newPayment.amount && !!newPayment.method;
  };

  useEffect(() => {
    dispatch(saveFinancialAccount());
  }, [dispatch]);

  useEffect(() => {
    if (financialAccount && financialAccount.content) {
      const categorizedAccounts = financialAccount.content
        .filter((account: IFinancialAccount) => account.type?.name === 'Cash')
        .reduce((acc: any, account: any) => {
          if (!acc[account.type.category]) {
            acc[account.type.category] = [];
          }
          acc[account.type.category].push(account);
          return acc;
        }, {});

      const newOptions = Object.entries(categorizedAccounts).map(([category, accounts]) => ({
        label: category,
        options: Array.isArray(accounts)
          ? accounts.map((acc) => ({
              value: acc.publicId,
              label: acc.name,
            }))
          : [],
      }));

      setOptions(newOptions);
      setIsLoading(false);
    }
  }, [financialAccount]);

  useEffect(() => {
    if (bill) {
      setNewPayment({
        date: moment().toISOString(),
        method: 'CASH',
        amount: Number(bill.total) - Number(bill.amountPaid),
        checkNumber: '',
      })
    } else {
      setNewPayment({
        date: moment().toISOString(),
        method: 'CASH',
        amount: 0,
        checkNumber: '',
      })
    }
  }, [showModal, bill]);

  return (
    <>
      <BaseModal
        title={`Record a payment for Bill ${bill.name ? `#${bill.name}` : '<Unknown ID>'}`}
        isModalOpen={showModal}
        size='small'
        body={
          <Grid container p={3} spacing={3} flexDirection='column'>
            <Grid item>
              <div className='payment-dialog__input-wrapper'>
                <DateField
                  fullWidth
                  label='Payment date'
                  value={newPayment?.date}
                  onChange={(date: Date | null) => {
                    if (date) {
                      const formattedDate = moment(date).format('YYYY-MM-DD HH:mm');
                      setNewPayment({ ...newPayment, date: formattedDate });
                    }
                  }}
                />
              </div>
            </Grid>
            <Grid item>
              <TextField
                label='Payment amount'
                type='number'
                value={newPayment.amount}
                onChange={(e) => setNewPayment({ ...newPayment, amount: Number(e.target.value) })}
                fullWidth
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                InputProps={{
                  startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item>
              {isLoading ? (
                <div></div>
              ) : (
                <>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel 
                      shrink 
                      id='account-label'
                      sx={{
                        backgroundColor: 'white', 
                        padding: '0 4px', 
                      }}
                      >Select an account</InputLabel>
                    <Select
                      required
                      fullWidth
                      labelId='account-label'
                      placeholder='Select an Account'
                      label='Select an account'
                      value={
                        newPayment.financialAccountPublicId !== ''
                          ? newPayment.financialAccountPublicId
                          : ''
                      }
                      onChange={(e: SelectChangeEvent<any>) =>
                        setNewPayment({ ...newPayment, financialAccountPublicId: e.target.value })
                      }
                    >
                      {options.map((category) => [
                        <MenuItem
                          key={category.label}
                          disabled
                          style={{ fontWeight: 'bold', color: 'black', opacity: 1 }}
                        >
                          {category.label}
                        </MenuItem>,
                        ...category.options.map((option: any) => (
                          <MenuItem
                            key={option.label}
                            value={option.value}
                            style={{ marginLeft: '5px' }}
                          >
                            {option.label}
                          </MenuItem>
                        )),
                      ])}
                    </Select>
                  </FormControl>
                </>
              )}
            </Grid>
            <Grid item>
              <Typography mb={1} fontWeight={600} color='var(--secondary-grey)'>
                Payment method
              </Typography>
              <Stack
                direction='row'
                spacing={1}
                mb={1}
                sx={{
                  '& .MuiChip-colorSuccess': { backgroundColor: 'var(--success-bg-color)' },
                  '& .MuiChip-colorSuccess:hover': {
                    backgroundColor: 'var(--secondary-grey)',
                    color: '#fff',
                  },
                }}
              >
                <Chip
                  label='Cash'
                  icon={newPayment.method === 'CASH' ? <CheckIcon /> : undefined}
                  color={newPayment.method === 'CASH' ? 'success' : undefined}
                  onClick={() => setNewPayment({ ...newPayment, method: 'CASH' })}
                />
                <Chip
                  label='Check'
                  icon={newPayment.method === 'CHECK' ? <CheckIcon /> : undefined}
                  color={newPayment.method === 'CHECK' ? 'success' : undefined}
                  onClick={() => setNewPayment({ ...newPayment, method: 'CHECK' })}
                />
                <Chip
                  label='Other'
                  icon={newPayment.method === 'OTHER' ? <CheckIcon /> : undefined}
                  color={newPayment.method === 'OTHER' ? 'success' : undefined}
                  onClick={() => setNewPayment({ ...newPayment, method: 'OTHER' })}
                />
              </Stack>
            </Grid>
            {newPayment.method === 'CHECK' && (
              <Grid item>
                <TextField
                  label='Check number'
                  type='text'
                  value={newPayment.checkNumber}
                  onChange={(e) => {
                    const value = e.target.value;
                    const numericValue = value.replace(/[^0-9]/g, '');
                    setNewPayment({ ...newPayment, checkNumber: numericValue });
                  }}
                  fullWidth
                  placeholder='Enter check number'
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            )}
          </Grid>
        }
        footer={
          <Grid
            container
            justifyContent={{ xs: 'normal', md: 'right' }}
            alignItems={{ xs: 'normal', md: 'center' }}
            flexDirection={{ xs: 'column-reverse', md: 'row' }}
          >
            <Grid item padding={{ xs: '1rem', md: '0rem' }}>
              <Button
                color='primary'
                variant='outlined'
                onClick={() => setShowModal(false)}
                fullWidth
              >
                Cancel
              </Button>
            </Grid>
            <Grid item padding={{ xs: '1rem 1rem 0 1rem', md: '1rem' }}>
              <Button
                color='primary'
                variant='contained'
                fullWidth
                onClick={() => confirmAndPrint(newPayment)}
                disabled={!isValidForm()}
              >
                Confirm payment
              </Button>
            </Grid>
          </Grid>
        }
        handleCloseModal={() => setShowModal(false)}
      />
      <ConfirmDialog
        isOpen={confirmDialogOpen}
        dialogTitle="Confirm Payment"
        dialogBodyContent="Are you sure you want to confirm this payment?"
        buttonConfirmText="Yes, Confirm"
        buttonCancelText="Cancel"
        handleClose={() => setConfirmDialogOpen(false)}
        handleConfirm={handleConfirm}
      />
      {confirmDialogOpen && flag && (
        <div style={{ display: 'none' }}>
          <PreviewBill
            preview={preview}
            newPayment={newPayment}
            bill={bill} 
          />
        </div>
      )}
    </>
  );
};

export default BillPaymentModal;
