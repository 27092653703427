import { Box, Button, Card, Grid, TextField, Typography } from '@mui/material'
import { validateEmail } from '../../helpers/utils'
import { ILoginValues } from '../../pages/sign-in/sign-in'

interface IResetEmail {
  formErrors: any
  setFormErrors: any
  initialValues: ILoginValues
  handleChangeValues: (e: React.ChangeEvent<HTMLInputElement>, value: string) => void
  handleButtonClick: any
  handleRegisterClick: any
}

const ResetEmail = ({
  formErrors,
  initialValues,
  handleChangeValues,
  setFormErrors,
  handleButtonClick,
  handleRegisterClick,
}: IResetEmail) => {
  return (
    <>
      <>
        <div style={{ flexDirection: 'column' }}>
          <Typography
            mt={2}
            id='modal-modal-title'
            variant='h3'
            fontWeight={700}
            color='var(--dark-grey-title)'
          >
            Reset Password
          </Typography>
          <Typography
            mt={3}
            id='modal-modal-subtitle'
            fontWeight={400}
            color='var(--light-grey-title)'
            style={{ marginBottom: '20px' }}
          >
            Please enter your email address to receive a verification code
          </Typography>
        </div>
        <div>
          <div>
            <TextField
              style={{ marginBottom: '20px' }}
              error={!!formErrors['email']}
              helperText={formErrors['email']}
              required
              type='email'
              id='outlined-required'
              label='Email'
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              value={initialValues.resetEmail}
              onBlur={() => validateEmail(initialValues.resetEmail, setFormErrors)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChangeValues(e, 'resetEmail')
                // validateEmail(e.target.value, setFormErrors)
              }}
            />
          </div>
          <div>
            <div>
              <Button
                sx={{ fontWeight: 600 }}
                color='primary'
                variant='contained'
                onClick={handleButtonClick}
                fullWidth
              >
                Send code
              </Button>
            </div>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
              <Typography>{"Don't have an Account?"}</Typography>
              <span
                className='highlighted-text'
                onClick={() => handleRegisterClick()}
                style={{ marginLeft: '8px' }}
                id='register-now__link'
              >
                <Typography>Sign up</Typography>
              </span>
            </Box>
          </div>
        </div>
      </>
    </>
  )
}

export default ResetEmail
