import { Box, Divider } from '@mui/material'
import CompanySection from '../customers/company-section'
import StatementHeader from './header'
import { ICustomer } from '../../types/types'
import OutstandingStatementTable from './outstandingTable'
import ActivityStatementTable from './activity-table/activityTable'
import { isUsingMobile } from '../../helpers/utils'
import OutstandingStatementCards from './outstandingCards'
import ActivityStatementCards from './activity-cards/activityCards'

interface StatementViewProps {
  customer: ICustomer
  type: string
}

const StatementView = ({ customer, type }: StatementViewProps) => {
  const isMobile = isUsingMobile()

  return (
    <Box m={{ xs: 0, md: 2 }}>
      <Box
        sx={{
          boxShadow: '0 5px 20px #ddd',
          p: 2,
          maxHeight: { sm: 'calc(100vh - 400px)' },
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <CompanySection mb={2} />
        <Divider variant='middle' />
        <StatementHeader customer={customer} type={type} />
        {type === 'OUTSTANDING' && isMobile && <OutstandingStatementCards />}
        {type === 'OUTSTANDING' && !isMobile && <OutstandingStatementTable />}
        {type === 'ACTIVITY' && isMobile && <ActivityStatementCards />}
        {type === 'ACTIVITY' && !isMobile && <ActivityStatementTable />}
      </Box>
    </Box>
  )
}

export default StatementView
