import { Table, TableBody, TableHead, TableRow } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { IEstimate } from '../../../types/types';
import { styled } from '@mui/styles';
import { formatMoney } from '../../../helpers/formatPrice';

interface IEstimatePreviewTableProps {
	estimate: IEstimate;
}

const EstimatePreviewDetailTable = ({
	estimate,
}: IEstimatePreviewTableProps) => {
	const TableHeadTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: 'var(--background-color)',
			fontWeight: 'bold',
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14,
		},
	}));

	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableHeadTableCell>Items</TableHeadTableCell>
					<TableHeadTableCell>Description</TableHeadTableCell>
					<TableHeadTableCell>Quantity</TableHeadTableCell>
					<TableHeadTableCell>Price</TableHeadTableCell>
					<TableHeadTableCell>Amount</TableHeadTableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{estimate.estimateToProductList.map((item) => {
					return (
						<TableRow key={item.publicId}>
							<TableCell>{item.product.name}</TableCell>
							<TableCell>{item.description}</TableCell>
							<TableCell>{item.quantity}</TableCell>
							<TableCell>{formatMoney(item.price)}</TableCell>
							<TableCell>{formatMoney(item.amount)}</TableCell>
						</TableRow>
					);
				})}
			</TableBody>
		</Table>
	);
};

export default EstimatePreviewDetailTable;
