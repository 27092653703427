import { SxProps } from '@mui/material'
import { gridClasses } from '@mui/x-data-grid'

const TableStyles: SxProps = {
  '& .MuiDataGrid-columnHeadersInner': {
    color: 'var(--grey-fonts)',
  },
  [`&.${gridClasses.root}`]: {
    border: 'none',
  },
  [`& .${gridClasses.cell}`]: {
    cursor: 'pointer',
    paddingX: 1,
    paddingY: { xs: 1, sm: 0 },
    color: 'var(--secondary-grey)',
  },
  [`& .${gridClasses.cell}:last-child`]: {
		marginRight: '-1rem',
    paddingRight: 0,
  },
  [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
		outline: 'none',
  },
	[`& .${gridClasses.cellContent}`]: {
		whiteSpace: 'normal',
	},
  [`& .${gridClasses.columnHeaderTitle}`]: {
		fontSize: { xs: '12px', md: '14px' },
  },
  [`& .${gridClasses.withBorderColor}`]: {
		borderColor: 'transparent',
  },
  [`& .${gridClasses.virtualScroller}`]: {
		overflow: 'hidden',
  },
	[`& .${gridClasses.footerContainer}`]: {
		border: 'none',
	},
	[`& .${gridClasses.columnHeaders}`]: {
		border: 'none',
	},
	[`& .${gridClasses.columnHeader}`]: {
		padding: { xs: '0 5px', sm: '0 10px 10px 10px' },
	},
  [`& .${gridClasses.columnHeader}:focus`]: {
		outline: 'none',
  },
	[`& .${gridClasses.columnSeparator}`]: {
		display: 'none',
	},
  [`& .${gridClasses.row}`]: {
    bgcolor: 'white',
    borderRadius: '10px',
    boxShadow: '0px 2px 6px rgba(77, 94, 128, 0.15)',
    alignItems: 'center',
  },
}

export default TableStyles
