import {
  Alert,
  Autocomplete,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { useSelector } from 'react-redux'
import { AppState } from '../../../redux/store'
import { FocusEvent, useMemo } from 'react'
import { validateName, validateSelectedItem } from '../../../helpers/utils'
import { IDField } from '../../../components/amount-field/amount-field'
import useAuthorization from '../../../helpers/useAuthorization'

interface VendorStepProps {
  currentVendor: any
  handleChangeNewVendor: any
  setCurrentVendor: any
  formErrors: any
  setFormErrors: any
}

const VendorTypeStep = ({
  currentVendor,
  setCurrentVendor,
  handleChangeNewVendor,
  formErrors,
  setFormErrors,
}: VendorStepProps) => {
  const mccTypes = useSelector((state: AppState) => state.authReducer.mccTypes)
  const { isAuthorized } = useAuthorization()

  const selectedValue = useMemo(
    () => (mccTypes ? mccTypes.find((v) => v.publicId === currentVendor.mccPublicId) : null),
    [currentVendor.mccPublicId, mccTypes],
  )

  return (
    <Grid container gap={2}>
      <Grid item xs={12} pl={1} pr={1}>
        <TextField
          label='Business name'
          placeholder='Enter a business name'
          fullWidth
          InputLabelProps={{ shrink: true }}
          required
          disabled={!isAuthorized('write_vendor')}
          value={currentVendor.name}
          onBlur={(e) => {
            validateName(e.target.value, 'name', setFormErrors, 'Business Name')
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleChangeNewVendor(e, 'name')
            validateName(e.target.value, 'name', setFormErrors, 'Business Name')
          }}
          error={!!formErrors['name']}
          helperText={formErrors['name']}
        />
      </Grid>
      <Grid item xs={12} pl={1} pr={1}>
        <Autocomplete
          value={selectedValue || null}
          disablePortal
          options={mccTypes}
          onBlur={(e: FocusEvent) => {
            validateSelectedItem(
              currentVendor.mccPublicId,
              'mccPublicId',
              setFormErrors,
              'Service type',
            )
          }}
          onChange={(event: any, selected: any | null) => {
            setCurrentVendor({
              ...currentVendor,
              mccPublicId: selected ? selected.publicId : null,
            })
            validateSelectedItem(
              selected ? selected.publicId : null,
              'mccPublicId',
              setFormErrors,
              'Service type',
            )
          }}
          disabled={!isAuthorized('write_vendor')}
          getOptionLabel={(option) => option?.name || ''}
          renderInput={(params) => (
            <TextField
              required={true}
              {...params}
              label='Service Type'
              InputLabelProps={{ shrink: true }}
              placeholder='Select a service type'
              error={!!formErrors['mccPublicId']}
              helperText={formErrors['mccPublicId']}
            />
          )}
        />
      </Grid>
      {!currentVendor.publicId && (
        <Grid item xs={12} pl={1} pr={1}>
          <FormControl fullWidth>
            <InputLabel id='vendor-type-label' shrink>
              Vendor Type *
            </InputLabel>
            <Select
              fullWidth
              displayEmpty
              value={currentVendor.type}
              label='Vendor Type'
              labelId='vendor-type-label'
              placeholder='Select Vendor Type'
              required
              onChange={(e: SelectChangeEvent) =>
                setCurrentVendor({
                  ...currentVendor,
                  type: e.target.value,
                })
              }
            >
              <MenuItem disabled value=''>
                Select Vendor Type
              </MenuItem>
              <MenuItem value='REGULAR'>Regular</MenuItem>
              <MenuItem value='CONTRACTOR_1099_NEC'>1099-NEC Contractor</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}
      {(currentVendor.type === 'REGULAR' || currentVendor.type === 'CONTRACTOR_1099_NEC') &&
        !currentVendor.publicId && (
          <>
            <Alert
              variant='outlined'
              severity='info'
              sx={{
                border: 'none',
                color: 'var(--green)',
                width: '100%',
                padding: '0 16px',
                '.MuiAlert-icon': {
                  color: 'var(--green)',
                },
              }}
            >
              {currentVendor.type === 'REGULAR'
                ? 'Individuals or companies that supply goods and services to your business.'
                : 'Contractors that provide services, for which compensation is provided, typically documented through a 1099-NEC form.'}
            </Alert>
            <hr
              style={{
                width: '100%',
                borderTop: 0,
                margin: '0 1rem',
                borderColor: 'var(--secondary-grey-bg)',
              }}
            />
          </>
        )}
      {currentVendor.type === 'CONTRACTOR_1099_NEC' && !currentVendor.publicId && (
        <>
          <Grid item xs={12} pl={1} pr={1} mt={2}>
            <FormControl fullWidth>
              <InputLabel id='contractor-type-label' shrink>
                Contractor type *
              </InputLabel>
              <Select
                labelId='contractor-type-label'
                label='Contractor Type'
                required
                placeholder='Select a contractor type'
                fullWidth
                displayEmpty
                value={currentVendor.contractorType}
                onChange={(e: SelectChangeEvent) =>
                  setCurrentVendor({
                    ...currentVendor,
                    contractorType: e.target.value,
                  })
                }
              >
                <MenuItem disabled value=''>
                  Select a contractor type
                </MenuItem>
                <MenuItem key={'Individual'} value={'INDIVIDUAL'}>
                  Individual
                </MenuItem>
                <MenuItem key={'Business'} value={'BUSINESS'}>
                  Business
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} pl={1} pr={1}>
            <IDField
              label={
                currentVendor.contractorType === 'BUSINESS'
                  ? 'Employer identification number'
                  : 'Social security number'
              }
              placeholder={
                currentVendor.contractorType === 'BUSINESS'
                  ? 'Enter Employer Identification Number (EIN)'
                  : 'Enter Social Security Number (SSN)'
              }
              fullWidth
              InputLabelProps={{ shrink: true }}
              required
              value={
                currentVendor.contractorType === 'BUSINESS' ? currentVendor.ein : currentVendor.ssn
              }
              format={currentVendor.contractorType === 'BUSINESS' ? '##-#######' : '###-##-####'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (currentVendor.contractorType === 'BUSINESS') {
                  handleChangeNewVendor(e, 'ein')
                } else {
                  handleChangeNewVendor(e, 'ssn')
                }
              }}
            />
          </Grid>
          {currentVendor.contractorType === 'BUSINESS' && (
            <Alert
              variant='outlined'
              severity='info'
              sx={{
                border: 'none',
                color: 'var(--green)',
                width: '100%',
                padding: '0 16px',
                '.MuiAlert-icon': {
                  color: 'var(--green)',
                },
              }}
            >
              To ensure accurate generation of 1099-NEC forms, please verify that the business name
              and EIN are correct.
            </Alert>
          )}
        </>
      )}
    </Grid>
  )
}

export default VendorTypeStep
