import { Divider, Grid, Typography } from '@mui/material'
import { ICompany, IEstimate } from '../../../types/types'
import { formatDate, formatPhoneNumber } from '../../../helpers/formatPrice'
import { getCdnUrl } from '../../../helpers/utils'

interface IEstimatePreviewHeaderProps {
  company?: ICompany | null
  estimate: IEstimate
}

const EstimatePreviewHeader = ({ company, estimate }: IEstimatePreviewHeaderProps) => {
  return (
    <>
      <Grid container p={2} sx={{ color: 'var(--gray3-color)' }}>
        <Grid item sx={{ '& .MuiTypography-body1': { fontSize: '14px' } }} sm={8}>
          {company && (
            <>
              <Typography fontWeight='bold' fontSize='16px !important'>
                {company.name}
              </Typography>
              <Typography>{company.addressOne}</Typography>
              <Typography>{company.addressTwo}</Typography>
              <Typography mb={1}>
                {company.city}, {company.state} {company.postalCode}, {company.country}
              </Typography>
              <Typography>{formatPhoneNumber(company.phone)}</Typography>
              <Typography>{company.website}</Typography>
            </>
          )}
        </Grid>
        <Grid item sm={4}>
          {company?.templateLogoFileName && (
            <img src={getCdnUrl(company?.templateLogoFileName)} alt='company logo' style={{ width: 220 }} />
          )}
        </Grid>
      </Grid>
      <Divider />
      <Grid container p={2} sx={{ color: 'var(--secondary-grey)' }}>
        <Grid item md={7} sx={{ '& .MuiTypography-body1': { fontSize: '14px' } }}>
          <Typography fontWeight='bold' fontSize='16px !important'>
            Estimated to
          </Typography>
          <Typography>{estimate.customerName}</Typography>
          <Typography>{`${estimate.primaryFirstName} ${estimate.primaryLastName}`}</Typography>
          <Typography>{estimate.billingAddress}</Typography>
          <Typography mb={1}>
            {`${estimate.billingCity}, ${estimate.billingState}	${estimate.billingPostalCode}, ${estimate.billingCountry}`}
          </Typography>
          <Typography>{formatPhoneNumber(estimate.primaryPhoneNumber)}</Typography>
          <Typography>{estimate.customerEmail}</Typography>
        </Grid>
        <Grid item md={3} pr={1}>
          <Typography textAlign='right' fontWeight='bold'>
            Estimate ID
          </Typography>
          <Typography textAlign='right' fontWeight='bold'>
            Estimate Date
          </Typography>
          <Typography textAlign='right' fontWeight='bold'>
            Expiration Date
          </Typography>
        </Grid>
        <Grid
          item
          md={2}
          sx={{
            '& .MuiTypography-body1': { fontSize: '14px', lineHeight: '24px' },
          }}
        >
          <Typography>#{estimate.name}</Typography>
          <Typography>{formatDate(estimate.periodStart)}</Typography>
          <Typography>{formatDate(estimate.periodEnd)}</Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default EstimatePreviewHeader
