import { Tooltip } from '@mui/material'

import AttachFileIcon from '@mui/icons-material/AttachFile'
import FileIcon from '@mui/icons-material/InsertPhotoOutlined'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'

const DownloadAttachment = ({
  filename,
  handleDownloadClick,
}: {
  filename: string
  handleDownloadClick: any
}) => {
  return (
    <div
      style={{
        marginTop: '20px',
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        width: '252px',
        marginLeft: '15px',
      }}
    >
      <label htmlFor='file-input'>
        <div style={{ height: '46px', width: '46px', color: '#4D5E80' }}>
          <AttachFileIcon />
        </div>
      </label>
      <div
        style={{
          display: 'flex',
          background: '#fff',
          border: '1px solid #DFE3EB',
          borderRadius: '8px',
          marginBottom: '10px',
          padding: '5px',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            marginRight: '15px',
          }}
        >
          <FileIcon />
        </div>
        <div style={{ marginRight: '15px', fontSize: '12px', fontWeight: '400' }}>
          {filename && filename.length > 20 ? (
            <Tooltip title={filename} arrow>
              <span>{filename.slice(0, 20)}...</span>
            </Tooltip>
          ) : (
            filename
          )}
        </div>
        <div
          style={{
            color: '#0039FF',
            marginLeft: 'auto',
            cursor: 'pointer',
            marginTop: '6px',
          }}
          onClick={handleDownloadClick}
        >
          <DownloadOutlinedIcon />
        </div>
      </div>
    </div>
  )
}

export default DownloadAttachment
