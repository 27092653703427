import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { ReactElement } from 'react'

interface IDialog {
  isOpen: boolean
  dialogTitle?: string
  dialogBodyContent?: string | ReactElement
  buttonConfirmText?: string
  buttonCancelText?: string
  handleConfirm: () => void
  handleClose: () => void
  confirmColor?: string
}

export default function ConfirmDialog(props: IDialog) {
  const {
    isOpen,
    dialogTitle,
    dialogBodyContent,
    buttonConfirmText,
    buttonCancelText,
    handleClose,
    handleConfirm,
    confirmColor = 'red',
  } = props

  const handleConfirmClick = () => {
    handleConfirm()
  }

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose} aria-labelledby='responsive-dialog-title'>
        <DialogTitle id='responsive-dialog-title'>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogBodyContent}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {buttonCancelText && (
            <Button autoFocus onClick={handleClose}>
              <Typography sx={{ fontWeight: '500' }}>{buttonCancelText}</Typography>
            </Button>
          )}
          <Button onClick={handleConfirmClick} autoFocus>
            <Typography sx={{ color: confirmColor, fontWeight: '500' }}>
              {buttonConfirmText}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
