import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import { useState } from 'react'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import { IScheduledInvoice } from '../../../types/types'

interface SubscriptionModalHeaderButtonsProps {
  scheduledInvoice: IScheduledInvoice
  actions: any
}

const SubscriptionModalHeaderButtons = ({
  scheduledInvoice,
  actions,
}: SubscriptionModalHeaderButtonsProps) => {
  const { onStopClick, onActivateClick, onDeleteClick } = actions
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isMoreActionsMenuOpen = Boolean(anchorEl)

  const handleMoreActionsMenuClose = () => {
    setAnchorEl(null)
  }

  const handleStopClick = () => {
    handleMoreActionsMenuClose()
    onStopClick && onStopClick()
  }

  const handleActivateClick = () => {
    handleMoreActionsMenuClose()
    onActivateClick && onActivateClick()
  }

  const handleDeleteClick = () => {
    handleMoreActionsMenuClose()
    onDeleteClick && onDeleteClick()
  }

  return (
    <>
      <Tooltip title='More options'>
        <IconButton
          aria-label='more'
          onClick={(event) => setAnchorEl(event.currentTarget)}
          sx={{
            color: 'var(--primary1-color)',
            background: 'var(--secondary1-color)',
          }}
        >
          <MoreVertOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id='more-actions-menu'
        anchorEl={anchorEl}
        open={isMoreActionsMenuOpen}
        onClose={handleMoreActionsMenuClose}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{
          vertical: -12,
          horizontal: 'right',
        }}
        sx={{
          '& .MuiMenuItem-root': {
            padding: { xs: '1rem', md: '1rem 4rem 1rem 2rem' },
          },
          '& .MuiMenuItem-root:hover': {
            backgroundColor: 'var(--secondary1-color)',
            color: 'var(--primary1-color)',
          },
        }}
      >
        {scheduledInvoice.status === 'ACTIVE' && (
          <MenuItem onClick={handleStopClick}>Stop Subscription</MenuItem>
        )}
        {['STOPPED', 'DRAFT'].includes(scheduledInvoice.status || '')  && (
          <MenuItem onClick={handleActivateClick}>Activate Subscription</MenuItem>
        )}
        <MenuItem onClick={handleDeleteClick} sx={{ color: 'var(--error-color)' }}>
          Delete
        </MenuItem>
      </Menu>
    </>
  )
}

export default SubscriptionModalHeaderButtons
