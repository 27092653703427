import { IInvoice } from '../types/types'
import { LONG_FIELD_VALID, PHONE_VALID, URL_VALID, VALID_EMAIL } from './constants'
import { successToastNotification } from './toastNotification'

export function isUsingMobile() {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
}

export function getCdnUrl(path: string | undefined) {
  if (path) {
    return `${process.env.REACT_APP_CDN_URL}/${path}`
  }
}

export function capitalizeFirst(str: string | undefined) {
  if (!str) {
    return ''
  }

  const lowerCase = str.toLowerCase()
  return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1)
}

// ////////////////////// Validation functions ////////////////////////
export const validateName = (name: string, prop: string, setFunc: any, labelName: string) => {
  const minLengthName = 3
  setFunc((prevState: any) => {
    const updatedErrors = { ...prevState }
    if (name.length === 0) {
      updatedErrors[prop] = `${labelName} is required`
    } else if (name.length < minLengthName) {
      updatedErrors[prop] = `The ${labelName} must be at least 3 characters`
    } else {
      updatedErrors[prop] = ''
    }

    return updatedErrors
  })
}

export const validatePhoneNumber = (
  phoneNumber: string,
  setFunc: any,
  prop = 'primaryPhoneNumber',
) => {
  if (!PHONE_VALID.test(phoneNumber)) {
    setFunc((prevState: any) => ({
      ...prevState,
      [prop]: 'Invalid phone number format',
    }))
  } else if (phoneNumber.length === 0) {
    setFunc((prevState: any) => ({
      ...prevState,
      [prop]: 'phone number is required',
    }))
  } else {
    setFunc((prevState: any) => ({ ...prevState, [prop]: '' }))
  }
}

export const validateCommonField = (
  commonField: string,
  prop: string,
  setFunc: any,
  labelName: string,
  minLength: number,
) => {
  setFunc((prevState: any) => {
    const updatedErrors = { ...prevState }
    if (!commonField || commonField.length === 0) {
      updatedErrors[prop] = `${labelName} is required`
    } else if (commonField.length < minLength) {
      updatedErrors[prop] = `The ${labelName} must be at least ${minLength} characters`
    } else {
      delete updatedErrors[prop]
    }
    return updatedErrors
  })
}

export const validateState = (state: string, setFunc: any, prop = 'billingState') => {
  setFunc((prevState: any) => {
    const updatedErrors = { ...prevState }
    if (state.length === 0) {
      updatedErrors[prop] = 'State is required'
    } else if (state.length < 2 || state.length > 2) {
      updatedErrors[prop] = 'The state should have 2 characters'
    } else {
      updatedErrors[prop] = ''
    }
    return updatedErrors
  })
}

export const validateAddress = (address: string, prop: string, setFunc: any, labelName: string) => {
  setFunc((prevState: any) => {
    const updatedErrors = { ...prevState }
    if (address.length === 0) {
      updatedErrors[prop] = `${labelName} is required`
    } else if (!LONG_FIELD_VALID.test(address)) {
      updatedErrors[prop] = `The ${labelName} must be at least 5 characters`
    } else {
      updatedErrors[prop] = ''
    }

    return updatedErrors
  })
}

export const validateCanEmptyField = (
  field: string,
  prop: string,
  setFunc: any,
  labelName: string,
) => {
  setFunc((prevState: any) => {
    const updatedErrors = { ...prevState }
    if (field.length === 0) {
      updatedErrors[prop] = ''
    } else if (!LONG_FIELD_VALID.test(field)) {
      updatedErrors[prop] = `The ${labelName} must be at least 5 characters`
    } else {
      updatedErrors[prop] = ''
    }

    return updatedErrors
  })
}

export const validateWebsite = (website: string, setFunc: any) => {
  if (!URL_VALID.test(website)) {
    setFunc((prevState: any) => ({
      ...prevState,
      website: 'Invalid website format',
    }))
  } else {
    setFunc((prevState: any) => ({ ...prevState, website: '' }))
  }
}

export const validateEmail = (email: string, setFunc: any, property = 'email') => {
  if (!VALID_EMAIL.test(email)) {
    setFunc((prevState: any) => ({
      ...prevState,
      [property]: 'Invalid email address',
    }))
  } else {
    setFunc((prevState: any) => ({ ...prevState, [property]: '' }))
  }
}

export const validateDescription = (description: string, setFunc: any) => {
  if (!description) {
    setFunc((prevState: any) => ({
      ...prevState,
      description: 'Description is required',
    }))
  } else {
    setFunc((prevState: any) => ({ ...prevState, description: '' }))
  }
}

export const validatePrice = (price: number | string, setFunc: any) => {
  if (!price) {
    setFunc((prevState: any) => ({ ...prevState, price: 'Price is required' }))
  } else if (Number(price) <= 0) {
    setFunc((prevState: any) => ({
      ...prevState,
      price: 'Price has to be greater than 0',
    }))
  } else {
    setFunc((prevState: any) => ({ ...prevState, price: '' }))
  }
}

export const validateMinValue = (
  value: number,
  prop: string,
  setFunc: any,
  labelName: string,
  minValue: number,
) => {
  setFunc((prevState: any) => {
    const updatedErrors = { ...prevState }

    if (value < minValue) {
      updatedErrors[prop] = `${labelName} should be greater than ${minValue}`
    } else {
      delete updatedErrors[prop]
    }

    return updatedErrors
  })
}

export const validateSelectedItem = (
  value: number,
  prop: string,
  setFunc: any,
  labelName: string,
) => {
  setFunc((prevState: any) => {
    const updatedErrors = { ...prevState }

    if (!value) {
      updatedErrors[prop] = `${labelName} should be selected`
    } else {
      delete updatedErrors[prop]
    }

    return updatedErrors
  })
}

export const getDayOrDays = (number: number) => (number === 1 ? 'day' : 'days')

export const isFormValid = (values: any) => {
  return Object.values(values).every((value) => value === '')
}

export const handleShareLinkClick = (invoice: IInvoice) => {
  navigator.clipboard.writeText(`${window.location.origin}/payment/${invoice?.publicId}`)

  successToastNotification(`Pay now link for invoice #${invoice?.name} was copied to the clipboard`)
}
