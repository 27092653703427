import { Box } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
import api from '../../../api/api'
import BaseModal from '../../../components/modal/modal'
import { errorHandler } from '../../../helpers/errorHandler'
import { isUsingMobile } from '../../../helpers/utils'
import { saveVendors } from '../../../redux/actions/home.actions'
import { SAVE_MCC_TYPES } from '../../../redux/actions/auth.actions.type'
import DialogSteps from './steps'
import VendorTypeStep from './type-step'
import PersonalInfoStep from './personal-info-step'
import BillingStep from './billing-step'
import { INewVendor } from '../../../types/types'
import { successToastNotification } from '../../../helpers/toastNotification'
import DialogCreateFooter from './create-footer'

interface IVendorsCreateDialog {
  open: boolean
  onClose: () => void
}

const VendorsCreateDialog = (props: IVendorsCreateDialog) => {
  const dispatch = useDispatch()
  const { open, onClose } = props
  const [dialogStep, setDialogStep] = useState<number>(0)

  const [currentVendor, setCurrentVendor] = useState<INewVendor>({
    publicId: '',
    name: '',
    firstName: '',
    lastName: '',
    country: 'United States',
    state: '',
    city: '',
    postalCode: '',
    addressOne: '',
    addressTwo: '',
    phone: '',
    mobile: '',
    website: '',
    mccPublicId: '',
    contractorType: 'INDIVIDUAL',
    type: 'REGULAR',
    emailAddress: '',
    ssn: '',
    ein: '',
  })

  const [formErrors, setFormErrors] = useState({
    name: '',
    firstName: '',
    lastName: '',
    country: '',
    state: '',
    city: '',
    postalCode: '',
    addressOne: '',
    addressTwo: '',
    phone: '',
    mobile: '',
    website: '',
    mccPublicId: '',
    emailAddress: '',
    ssn: '',
    ein: '',
  })

  const handleChangeNewVendor = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setCurrentVendor({
      ...currentVendor,
      [value]: e.target.value,
    })
  }

  const isDisabledSaveButton = useMemo(() => {
    let requiredFields: string[] = []

    if (dialogStep === 0) {
      requiredFields = ['name', 'mccPublicId', 'type']
    } else if (dialogStep === 1) {
      requiredFields = ['firstName', 'lastName', 'emailAddress', 'phone']
    } else if (dialogStep === 2) {
      requiredFields = ['addressOne', 'city', 'state', 'postalCode']
    }

    const areFieldsEmpty = requiredFields.some((field) => !(currentVendor as any)[field])

    return Object.values(formErrors).some((error) => error !== '') || areFieldsEmpty
  }, [formErrors, currentVendor, dialogStep])

  const resetErrors = () => {
    setFormErrors({
      name: '',
      firstName: '',
      lastName: '',
      country: '',
      state: '',
      city: '',
      postalCode: '',
      addressOne: '',
      addressTwo: '',
      phone: '',
      mobile: '',
      website: '',
      mccPublicId: '',
      emailAddress: '',
      ssn: '',
      ein: '',
    })
  }

  const handleCloseModal = () => {
    resetErrors()
    onClose()
  }

  useEffect(() => {
    setCurrentVendor({
      name: '',
      publicId: '',
      firstName: '',
      lastName: '',
      country: 'United States',
      state: '',
      city: '',
      postalCode: '',
      addressOne: '',
      addressTwo: '',
      phone: '',
      mobile: '',
      website: '',
      mccPublicId: '',
      emailAddress: '',
      contractorType: 'INDIVIDUAL',
      type: 'REGULAR',
      ssn: '',
      ein: '',
    })
  }, [open])

  useEffect(() => {
    api
      .getMCCTypes()
      .then((res) => {
        dispatch({
          type: SAVE_MCC_TYPES,
          payload: res.data.body,
        })
      })
      .catch((err) => console.log(err))
  }, [])

  const handleAcceptClick = () => {
    if (dialogStep === 0 || dialogStep === 1) {
      setDialogStep(dialogStep + 1)
      return
    }

    const payload = {
      ...currentVendor,
      addressTwo:
        currentVendor.addressTwo && currentVendor.addressTwo?.length > 0
          ? currentVendor.addressTwo
          : null,
      contractorType:
        currentVendor.type === 'CONTRACTOR_1099_NEC' ? currentVendor.contractorType : null,
      ein:
        currentVendor.type === 'CONTRACTOR_1099_NEC' && currentVendor.contractorType === 'BUSINESS'
          ? currentVendor.ein.replaceAll('-', '')
          : null,
      ssn:
        currentVendor.type === 'CONTRACTOR_1099_NEC' &&
        currentVendor.contractorType === 'INDIVIDUAL'
          ? currentVendor.ssn.replaceAll('-', '')
          : null,
    }

    api
      .createVendor(payload)
      .then(() => {
        handleCloseModal()
        successToastNotification('Vendor was successfully created')
        dispatch(saveVendors())
        setDialogStep(0)
      })
      .catch((err) => errorHandler(err))
  }

  return (
    <BaseModal
      size={isUsingMobile() ? 'xs' : 'small'}
      title='Add an vendor'
      body={
        <>
          <div className='customer-manage-dialog__wrapper'>
            <DialogSteps dialogStep={dialogStep} />
            <Box display='flex' flexDirection='column' margin='auto'>
              {dialogStep === 0 && (
                <VendorTypeStep
                  currentVendor={currentVendor}
                  setCurrentVendor={setCurrentVendor}
                  handleChangeNewVendor={handleChangeNewVendor}
                  formErrors={formErrors}
                  setFormErrors={setFormErrors}
                />
              )}
              {dialogStep === 1 && (
                <PersonalInfoStep
                  currentVendor={currentVendor}
                  handleChangeNewVendor={handleChangeNewVendor}
                  formErrors={formErrors}
                  setFormErrors={setFormErrors}
                />
              )}
              {dialogStep === 2 && (
                <BillingStep
                  currentVendor={currentVendor}
                  handleChangeNewVendor={handleChangeNewVendor}
                  formErrors={formErrors}
                  setFormErrors={setFormErrors}
                />
              )}
            </Box>
          </div>
        </>
      }
      isModalOpen={open}
      handleCloseModal={handleCloseModal}
      footer={
        <DialogCreateFooter
          isDisabledSaveButton={isDisabledSaveButton}
          handleCancel={handleCloseModal}
          handleAcceptClick={handleAcceptClick}
          dialogStep={dialogStep}
        />
      }
    />
  )
}

export default VendorsCreateDialog
