import { useSelector } from 'react-redux'
import { AppState } from '../redux/store'

export default function useAuthorization() {
  const currentUser = useSelector((state: AppState) => state.authReducer.cheddarUser)

  const isAuthorized = (expected: string) => {
    return (
      currentUser &&
      currentUser.role.permissions.some((permission: any) => permission.name === expected)
    )
  }

  const isAuthorizedByAtLeastOne = (expected: string[]) => {
    const found = expected.find((item) =>
      currentUser && currentUser.role.permissions.some((permission: any) => permission.name === item),
    )

    return currentUser && found
  }

  return { isAuthorized, isAuthorizedByAtLeastOne }
}
