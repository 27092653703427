import { Grid, Typography } from '@mui/material'
import { formatMoney } from '../../../helpers/formatPrice'
import { IInvoice, IScheduledInvoice } from '../../../types/types'

interface IInvoicePreviewFooter {
  invoice: IInvoice | IScheduledInvoice
}

const InvoicePreviewFooter = ({ invoice }: IInvoicePreviewFooter) => {
  return (
    <>
      <Grid container p={2} spacing={2}>
        <Grid item md={6}>
          <Typography fontWeight='bold'>Notes</Typography>
          <Typography sx={{ fontSize: '14px' }}>{invoice.memo}</Typography>
        </Grid>
        <Grid item md={4}>
          <Typography textAlign='right' fontWeight='bold'>
            Subtotal
          </Typography>
        </Grid>
        <Grid item md={2}>
          <Typography>{formatMoney(Number(invoice.subTotal))}</Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ backgroundColor: 'var(--background-color)' }} spacing={1}>
        <Grid item md={6}></Grid>
        <Grid item md={4}>
          <Typography fontWeight='bold'>INVOICE TOTAL</Typography>
        </Grid>
        <Grid item md={2}>
          <Typography fontWeight='bold'>{formatMoney(Number(invoice.total))}</Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default InvoicePreviewFooter
