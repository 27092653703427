import { Box, Grid, Typography } from '@mui/material'

import CallMadeIcon from '@mui/icons-material/CallMade'
import CallReceivedIcon from '@mui/icons-material/CallReceived'
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant'

import { formatMoney } from '../../helpers/formatPrice'
import { useSelector } from 'react-redux'
import { AppState } from '../../redux/store'
import { useMemo } from 'react'

const DashboardKpis = ({ totalAmount }: { totalAmount: number }) => {
  const customersDetailed = useSelector(
    (state: AppState) => state.reportReducer.customersReportDetailed,
  )

  const totalInvoicesIncomes = () => {
    const totalInvoices = useMemo(() => {
      return customersDetailed.reduce(
        (accumulator, customerDetailed) => {
          return {
            invoiceTotal: accumulator.invoiceTotal + customerDetailed.invoiceTotal,
            paidTotal: accumulator.paidTotal + customerDetailed.paidTotal,
          }
        },
        { invoiceTotal: 0, paidTotal: 0 },
      )
    }, [customersDetailed])

    return totalInvoices
  }

  const totals = totalInvoicesIncomes()

  return (
    <Grid container justifyContent='space-between' mt='30px' mb='20px'>
      <DashboardMoneyFlow
        total={formatMoney(totals.invoiceTotal)}
        arrowColor='var(--green)'
        title={'Total Income'}
        icon={<CallReceivedIcon fontSize='large' />}
      />
      <DashboardMoneyFlow
        total={formatMoney(totals.paidTotal)}
        arrowColor='var(--error-color)'
        title={'Total Paid Income'}
        icon={<CallMadeIcon fontSize='large' />}
      />
      <DashboardMoneyFlow
        total={formatMoney(Number(totalAmount))}
        arrowColor='var(--error-color)'
        title={'Current Overdue'}
        icon={<NotificationImportantIcon fontSize='large' />}
      />
    </Grid>
  )
}

interface IDashboardMoneyFlow {
  total?: number | string
  arrowColor?: string
  arrowRotation?: string
  icon?: any
  title?: any
}

const DashboardMoneyFlow = ({ total, arrowColor, icon, title }: IDashboardMoneyFlow) => {
  return (
    <Grid
      item
      display='flex'
      justifyContent='center'
      sm={3.5}
      xs={12}
      alignItems='center'
      paddingX='10px'
      sx={{
        backgroundColor: 'white',
        padding: '20px',
        boxSizing: 'border-box',
        borderRadius: '10px',
      }}
    >
      <Box
        sx={{
          backgroundColor: arrowColor,
          color: 'white',
          borderRadius: '50%',
          padding: '8px',
          margin: '10px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {icon}
      </Box>
      <Box>
        <Typography color='var(--secondary-grey)' fontWeight='400'>
          {title}
        </Typography>
        <Box display='flex' alignItems='center'>
          <Typography color='var(--secondary-grey)' variant='h4' fontWeight='400'>
            {total}
          </Typography>
        </Box>
      </Box>
    </Grid>
  )
}

export default DashboardKpis
