import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const DashboardOptions = () => {
  const navigate = useNavigate()

  return (
    <Box
      width='300px'
      sx={{
        bottom: 0,
        position: 'fixed',
        backgroundColor: 'white',
        boxShadow: '0px 5px 20px rgba(70, 101, 137, 0.35)',
        borderRadius: '10px',
        marginBottom: '80px',
      }}
    >
      <Typography
        sx={{
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'var(--secondary1-color)',
            color: 'var(--primary1-color)',
            transform: 'scale(1.01)',
            transition: '0.3s all',
          },
        }}
        py='15px'
        pl='20px'
        color='var(--secondary-grey)'
        onClick={() => navigate('/products/')}
      >
        Product or Service
      </Typography>
      <Typography
        sx={{
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'var(--secondary1-color)',
            color: 'var(--primary1-color)',
            transform: 'scale(1.01)',
            transition: '0.3s all',
          },
        }}
        py='15px'
        pl='20px'
        color='var(--secondary-grey)'
        onClick={() => navigate('/customers/')}
      >
        Customer
      </Typography>
      <Typography
        sx={{
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'var(--secondary1-color)',
            color: 'var(--primary1-color)',
            transform: 'scale(1.01)',
            transition: '0.3s all',
          },
        }}
        py='15px'
        pl='20px'
        color='var(--secondary-grey)'
        onClick={() => navigate('/estimates/')}
      >
        Estimate
      </Typography>
      <Typography
        sx={{
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'var(--secondary1-color)',
            color: 'var(--primary1-color)',
            transform: 'scale(1.01)',
            transition: '0.3s all',
          },
        }}
        py='15px'
        pl='20px'
        color='var(--secondary-grey)'
        onClick={() => navigate('/invoices/')}
      >
        Invoice
      </Typography>
    </Box>
  )
}

export default DashboardOptions
