import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../../api/api';
import editIcon from '../../../assets/icons/editUnderline.svg';
import visibilityOffIcon from '../../../assets/icons/visibility-off.png';
import visibilityIcon from '../../../assets/icons/visibility.png';
import FilledGreenButton from '../../../components/filled-green-button';
import TextField from '../../../components/text-field/text-field';
import { errorHandler } from '../../../helpers/errorHandler';
import { saveCurrentUser } from '../../../redux/actions/auth.actions';
import { AppState } from '../../../redux/store';
import './personal-information.css';

const PersonalInformation = () => {
	const currentUser = useSelector(
		(state: AppState) => state.authReducer.cheddarUser
	);
	const dispatch = useDispatch();
	const [state, setState] = useState({
		name: '',
		currentPassword: '',
		newPassword: '',
		confirmNewPassword: '',
	});
	const [showCurrentPassword, setShowCurrentPassword] =
		useState<boolean>(false);
	const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
	const [showConfirmNewPassword, setShowConfirmNewPassword] =
		useState<boolean>(false);
	const handleCurrentPasswordClick = () => {
		setShowCurrentPassword(!showCurrentPassword);
	};

	const handleNewPasswordClick = () => setShowNewPassword(!showNewPassword);

	const handleConfirmNewClick = () =>
		setShowConfirmNewPassword(!showConfirmNewPassword);

	const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
		setState({ ...state, name: e.target.value });

	useEffect(() => {
		dispatch(saveCurrentUser());
	}, []);

	useEffect(() => {
		if (currentUser) {
			setState({
				...state,
				name: currentUser.name,
			});
		}
	}, [currentUser]);

	const handeChangePasswords = (
		e: React.ChangeEvent<HTMLInputElement>,
		value: string
	) => {
		setState({
			...state,
			[value]: e.target.value,
		});
	};

	const handleUpdateUser = () => {
		if (currentUser) {
			api
				.updateUser(currentUser.publicId, {
					email: currentUser?.email,
					name: state.name,
					rolePublicId: currentUser?.role.publicId,
				})
				.then(() => {
					toast.success(`User name was successfully edited`, {
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: true,
						draggable: true,
						progress: undefined,
					});
					dispatch(saveCurrentUser());
				})
				.catch((err) => errorHandler(err));
		}
	};

	return (
		<>
			<div className="h4-mulish-semibold business-information__title">
				Personal information
			</div>
			<div>
				<div className="personal-information-field__label">Full name</div>
				<TextField
					value={state.name}
					onChange={handleNameChange}
					withIcon
					icon={editIcon}
					iconPosition="end"
				/>
			</div>
			<div className="personal-information-field__gap">
				<div className="personal-information-field__label">Email</div>
				<TextField readonly value={currentUser?.email} />
			</div>
			<FilledGreenButton
				buttonText="Save changes"
				styles="personal-information__change-button"
				disabled={!state.name || state.name === currentUser?.name}
				onClick={handleUpdateUser}
			/>
			<div className="divider personal-information-field__divider"></div>
			<div className="h4-mulish-semibold business-information__title">
				Password settings
			</div>
			<div>
				<div className="personal-information-field__label">
					Current password
				</div>
				<TextField
					value={state.currentPassword}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handeChangePasswords(e, 'currentPassword')
					}
					withIcon
					icon={showCurrentPassword ? visibilityOffIcon : visibilityIcon}
					onIconClick={handleCurrentPasswordClick}
					iconPosition="end"
					type={showCurrentPassword ? 'text' : 'password'}
				/>
			</div>
			<div className="personal-information-field__gap">
				<div className="personal-information-field__label">New password</div>
				<TextField
					value={state.newPassword}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handeChangePasswords(e, 'newPassword')
					}
					withIcon
					icon={showNewPassword ? visibilityOffIcon : visibilityIcon}
					onIconClick={handleNewPasswordClick}
					iconPosition="end"
					type={showNewPassword ? 'text' : 'password'}
				/>
			</div>
			<div className="personal-information-field__gap">
				<div className="personal-information-field__label">
					Confirm new password
				</div>
				<TextField
					value={state.confirmNewPassword}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handeChangePasswords(e, 'confirmNewPassword')
					}
					withIcon
					icon={showConfirmNewPassword ? visibilityOffIcon : visibilityIcon}
					onIconClick={handleConfirmNewClick}
					iconPosition="end"
					type={showConfirmNewPassword ? 'text' : 'password'}
				/>
			</div>
			<FilledGreenButton
				buttonText="Change password"
				disabled
				styles="personal-information__change-button"
			/>
		</>
	);
};

export default PersonalInformation;
