import {
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  FormControl,
} from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import api from '../../api/api'
import BaseModal from '../../components/modal/modal'
import { errorHandler } from '../../helpers/errorHandler'
import { isUsingMobile } from '../../helpers/utils'
import { saveFinancialAccount } from '../../redux/actions/home.actions'
import { IAccount } from '../../types/types'

interface IAccountType {
  publicId: string
  name: string
  category: string
}

interface IFinancialAccountTypes {
  content: IAccountType[]
}

interface IChartDialog {
  open: boolean
  product?: IAccount
  onClose: () => void
  accountNames: any
  isCreate: boolean
  financialAccountTypes: IFinancialAccountTypes
}

interface ICategorizedAccounts {
  [category: string]: IAccountType[];
}

interface IFooter {
  isDisabledSaveButton: boolean
  product?: any
  handleAcceptClick: () => void
  handleCancel: () => void
  isCreate: boolean
}

const Footer = ({ handleCancel, isDisabledSaveButton, handleAcceptClick, isCreate }: IFooter) => {
  return (
    <Grid container>
      <Grid
        container
        justifyContent={{ xs: 'normal', md: 'right' }}
        alignItems={{ xs: 'normal', md: 'center' }}
        flexDirection={{ xs: 'column-reverse', md: 'row' }}
      >
        <Grid item padding={{ xs: '1rem', md: '0rem' }}>
          <Button color='primary' variant='outlined' onClick={handleCancel} fullWidth>
            Cancel
          </Button>
        </Grid>
        <Grid item padding={{ xs: '1rem 1rem 0 1rem', md: '1rem' }}>
          <Button
            disabled={isDisabledSaveButton}
            color='primary'
            variant='contained'
            onClick={handleAcceptClick}
            fullWidth
          >
            {!isCreate ? 'Edit account' : 'Add new account'}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

const ChartManageDialog = (props: IChartDialog) => {
  const dispatch = useDispatch()
  const { open, product, onClose, accountNames, isCreate, financialAccountTypes } = props
  const [currentAccount, setCurrentAccount] = useState<any>({
    identifier: '',
    name: '',
    description: '',
    type: '',
    publicId: '',
  })
  const [formErrors, setFormErrors] = useState({
    name: '',
    price: '',
  })
  const [options, setOptions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (financialAccountTypes && Array.isArray(financialAccountTypes.content)) {
      const categorizedAccounts: ICategorizedAccounts = (financialAccountTypes.content as IAccountType[]).reduce(
        (acc: ICategorizedAccounts, account: IAccountType) => {
          if (!acc[account.category]) {
            acc[account.category] = [];
          }
          acc[account.category].push(account);
          return acc;
        },
        {}
      );

      const newOptions = Object.entries(categorizedAccounts).map(([category, accounts]) => ({
        label: category,
        options: (accounts as IAccountType[]).map((acc: IAccountType) => ({
          value: acc.publicId,
          label: acc.name,
        })),
      }));

      setOptions(newOptions);
      setIsLoading(false);
    }
  }, [financialAccountTypes]);

  const handleChangeNewAccount = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setCurrentAccount({
      ...currentAccount,
      [value]: e.target.value,
    })
  }

  const isDisabledSaveButton = useMemo(() => {
    return (
      Object.values(formErrors).some((error) => error !== '') ||
      !currentAccount.identifier ||
      !currentAccount.name ||
      !currentAccount.description ||
      !currentAccount.type
    )
  }, [
    formErrors,
    currentAccount.identifier,
    currentAccount.name,
    currentAccount.description,
    currentAccount.type,
  ])

  const resetErrors = () => {
    setFormErrors({ name: '', price: '' })
  }

  const handleCancel = () => {
    resetErrors()
    onClose()
  }

  useEffect(() => {
    if (!isCreate && product) {
      setCurrentAccount({
        ...product,
        name: product.name,
        identifier: product.identifier,
        description: product.description,
        publicId: product.publicId,
        type: product.type.publicId,
      })
    } else {
      setCurrentAccount({
        name: '',
        identifier: '',
        description: '',
        publicId: '',
        type: '',
      })
    }
  }, [open])

  const handleAcceptClick = () => {
    const payload = {
      identifier: currentAccount.identifier,
      name: currentAccount.name,
      description: currentAccount.description,
      financialAccountTypePublicId: financialAccountTypes.content.find(
        (item: IAccountType) => item.publicId === currentAccount.type,
      )?.publicId,
    }
    if (!isCreate) {
      product &&
        api
          .updateFinancialAccount(payload, currentAccount.publicId)
          .then(() => {
            handleCancel()
            toast.success(`Account was successfully edited`, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
            })
            dispatch(saveFinancialAccount())
          })
          .catch((err) => errorHandler(err))
    } else {
      api
        .createFinancialAccount(payload)
        .then(() => {
          handleCancel()
          toast.success(`Account was successfully created`, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          })
          dispatch(saveFinancialAccount())
        })
        .catch((err) => errorHandler(err))
    }
  }

  return (
    <BaseModal
      size={isUsingMobile() ? 'xs' : 'small'}
      title={!isCreate ? 'Edit account' : 'Add an account'}
      body={
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12} pl={1} pr={1}>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id='account-type-label'>Select an account type</InputLabel>
              <Select
                labelId='account-type-label'
                label='Select an account type'
                fullWidth
                value={currentAccount.type}
                onChange={(e: SelectChangeEvent) =>
                  setCurrentAccount({
                    ...currentAccount,
                    type: e.target.value,
                  })
                }
              >
                {isLoading ? (
                  <MenuItem disabled>Loading...</MenuItem>
                ) : (
                  options.map((category) => [
                    <MenuItem
                      key={category.label}
                      disabled
                      style={{ fontWeight: 'bold', color: 'black', opacity: 1 }}
                    >
                      {category.label}
                    </MenuItem>,
                    ...category.options.map((option: any) => (
                      <MenuItem
                        key={option.label}
                        value={option.value}
                        style={{ marginLeft: '5px' }}
                      >
                        {option.label}
                      </MenuItem>
                    )),
                  ])
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} pl={1} pr={1}>
            <TextField
              label='Account name'
              placeholder='Enter an account name'
              fullWidth
              InputLabelProps={{ shrink: true }}
              required
              value={currentAccount.name}
              sx={{ mb: 2 }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChangeNewAccount(e, 'name')
              }}
            />
          </Grid>
          <Grid item xs={12} pl={1} pr={1}>
            <TextField
              label='Account ID'
              placeholder='Enter an account ID'
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              value={currentAccount.identifier}
              sx={{ mb: 2 }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChangeNewAccount(e, 'identifier')
              }}
            />
          </Grid>
          <Grid item xs={12} pl={1} pr={1}>
            <TextField
              label='Description'
              placeholder='Enter an account description'
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              multiline
              rows={4}
              value={currentAccount.description}
              sx={{ mb: 2 }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChangeNewAccount(e, 'description')
              }}
            />
          </Grid>
        </Grid>
      }
      isModalOpen={open}
      handleCloseModal={handleCancel}
      footer={
        <Footer
          isDisabledSaveButton={isDisabledSaveButton}
          handleCancel={handleCancel}
          handleAcceptClick={handleAcceptClick}
          isCreate={isCreate}
        />
      }
    />
  )
}

export default ChartManageDialog
