import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { styled } from '@mui/system'
import { Fragment, useEffect, useState } from 'react'
import { formatDate, formatMoney } from '../../../helpers/formatPrice'
import { useSelector } from 'react-redux'
import { AppState } from '../../../redux/store'
import { isUsingMobile } from '../../../helpers/utils'
import { IAccountTransactionReport } from '../../../types/types'
import NoDataDisplay from '../../../components/noDataDisplay/noDataDisplay'

const AccountTransactionTable = ({ from, to }: { from: string; to: string }) => {
  const data = useSelector((state: AppState) => state.reportReducer.accountTransaction)
  const isMobile = isUsingMobile()

  interface IGroupedData {
    [key: string]: IAccountTransactionReport[]
  }

  const [groupedData, setGroupedData] = useState<IGroupedData>({})

  function adjustBalance(balance: number, transaction: IAccountTransactionReport): number {
    if (transaction?.accountPayable) {
      if (transaction.type === 'DEBIT') {
        balance += transaction.amount
      } else if (transaction.type === 'CREDIT') {
        balance -= transaction.amount
      }
    } else if (transaction?.accountReceivable) {
      if (transaction.type === 'DEBIT') {
        balance -= transaction.amount
      } else if (transaction.type === 'CREDIT') {
        balance += transaction.amount
      }
    } else {
      switch (transaction?.account.type.category) {
        case 'Assets':
        case 'Expenses':
          if (transaction.type === 'DEBIT') {
            balance -= transaction.amount
          } else if (transaction.type === 'CREDIT') {
            balance += transaction.amount
          }
          break
        case 'Liabilities':
        case 'Equity':
        case 'Revenue':
          if (transaction.type === 'DEBIT') {
            balance += transaction.amount
          } else if (transaction.type === 'CREDIT') {
            balance -= transaction.amount
          }
          break
      }
    }
    return balance
  }

  function calculateBalanceChange(items: IAccountTransactionReport[]) {
    if (items.length === 0) return { totalDebits: 0, totalCredits: 0, balanceChange: 0 }

    let totalDebits = 0
    let totalCredits = 0

    items.forEach((item) => {
      if (item.type === 'DEBIT') {
        totalDebits += item.amount
      } else if (item.type === 'CREDIT') {
        totalCredits += item.amount
      }
    })

    const firstItem = items[0]
    let shouldCreditBePositive = firstItem.accountPayable

    if (
      firstItem.account &&
      firstItem.account.type &&
      ['Equity', 'Revenue'].includes(firstItem.account.type.category)
    ) {
      shouldCreditBePositive = true
    }

    const minus = shouldCreditBePositive ? totalCredits - totalDebits : totalDebits - totalCredits

    return { totalDebits, totalCredits, minus }
  }

  useEffect(() => {
    const filteredData = data.filter((item: IAccountTransactionReport) => item.account)
    const groupedDataAccount = filteredData.reduce((acc: any, item: any) => {
      const category = item.account.type.category
      const name = item.account.type.name
      const key = `${category}/${name}`

      if (!acc[key]) {
        acc[key] = []
      }

      acc[key].push(item)

      return acc
    }, {})
    setGroupedData(groupedDataAccount)
  }, [data])

  return (
    <>
      {data.length > 0 ? (
        <ReportsTable component={Paper} style={{ marginTop: '20px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width={isMobile ? undefined : 300} sx={{ pl: { xs: 4, sm: 8 } }}>
                  Accounts
                </TableCell>
                <TableCell>Description</TableCell>
                <TableCell></TableCell>
                <TableCell>Debit</TableCell>
                <TableCell>Credit</TableCell>
                <TableCell>Balance</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groupedData &&
                Object.entries(groupedData).map(([key, items]) => (
                  <Fragment key={key}>
                    <OutstandingRow
                      style={{ borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }}
                    >
                      <OutstandingCell
                        style={{ backgroundColor: '#f7f8fa' }}
                        sx={{ pl: { xs: 4, sm: 8 } }}
                      >
                        <Typography variant='h1'>{key}</Typography>
                      </OutstandingCell>
                      <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
                      <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
                      <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
                      <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
                      <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
                    </OutstandingRow>
                    <OutstandingRow>
                      <OutstandingCell sx={{ pl: { xs: 4, sm: 8 } }} width={isMobile ? '60%' : 300}>
                        <Typography variant='h1'>Starting Balance</Typography>
                      </OutstandingCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <OutstandingCell width={isMobile ? undefined : 200} sx={{ pl: 2 }}>
                        <Typography variant='h1'>
                          {items.length > 0
                            ? formatMoney(adjustBalance(items[0].balance, items[0]))
                            : formatMoney(0)}
                        </Typography>
                      </OutstandingCell>
                    </OutstandingRow>
                    {items?.map((item: any, index: any) => (
                      <TableRow
                        key={item.publicId}
                        sx={
                          index === data?.length - 1
                            ? { '& .MuiTableCell-root': { border: 'none' } }
                            : undefined
                        }
                      >
                        <TableCell
                          style={{ backgroundColor: '#f7f8fa' }}
                          width={isMobile ? '60%' : 300}
                          sx={{ pl: { xs: 4, sm: 8 } }}
                        >
                          {formatDate(item.date)}
                        </TableCell>

                        <TableCell style={{ backgroundColor: '#f7f8fa' }}>
                          {item.description}
                        </TableCell>
                        <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
                        <TableCell style={{ backgroundColor: '#f7f8fa' }}>
                          {item.type === 'DEBIT' && formatMoney(item.amount)}
                        </TableCell>
                        <TableCell style={{ backgroundColor: '#f7f8fa' }}>
                          {item.type === 'CREDIT' && formatMoney(item.amount)}
                        </TableCell>
                        <TableCell style={{ backgroundColor: '#f7f8fa' }}>
                          {formatMoney(item.balance)}
                        </TableCell>
                      </TableRow>
                    ))}
                    <OutstandingRow>
                      <OutstandingCell width={isMobile ? '60%' : 300} sx={{ pl: { xs: 4, sm: 8 } }}>
                        <Typography variant='h1'>Total and Ending Balance</Typography>
                      </OutstandingCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <OutstandingCell width={isMobile ? undefined : 200} sx={{ pl: 2 }}>
                        <Typography variant='h1'>
                          {formatMoney(calculateBalanceChange(items).totalDebits)}
                        </Typography>
                      </OutstandingCell>
                      <OutstandingCell width={isMobile ? undefined : 200} sx={{ pl: 2 }}>
                        <Typography variant='h1'>
                          {formatMoney(calculateBalanceChange(items).totalCredits)}
                        </Typography>
                      </OutstandingCell>
                      <OutstandingCell width={isMobile ? undefined : 200} sx={{ pl: 2 }}>
                        <Typography variant='h1'>
                          {formatMoney(items[items.length - 1].balance)}
                        </Typography>
                      </OutstandingCell>
                    </OutstandingRow>
                    <OutstandingRow>
                      <OutstandingCell
                        style={{ backgroundColor: '#DFE3EB' }}
                        width={isMobile ? '60%' : 300}
                        sx={{ pl: { xs: 4, sm: 8 } }}
                      >
                        <Typography variant='h1'>Balance Change</Typography>
                      </OutstandingCell>
                      <TableCell style={{ backgroundColor: '#DFE3EB' }}></TableCell>
                      <TableCell style={{ backgroundColor: '#DFE3EB' }}></TableCell>
                      <TableCell style={{ backgroundColor: '#DFE3EB' }}></TableCell>
                      <OutstandingCell
                        style={{ backgroundColor: '#DFE3EB' }}
                        width={isMobile ? undefined : 200}
                        sx={{ pl: 2 }}
                      ></OutstandingCell>
                      <OutstandingCell
                        style={{ backgroundColor: '#DFE3EB' }}
                        width={isMobile ? undefined : 200}
                        sx={{ pl: 2 }}
                      >
                        <Typography variant='h1'>
                          {formatMoney(calculateBalanceChange(items).minus)}
                        </Typography>
                      </OutstandingCell>
                    </OutstandingRow>
                  </Fragment>
                ))}
              {data?.filter((item: any) => item.accountPayable) !== null && (
                <>
                  <OutstandingRow
                    style={{ borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }}
                  >
                    <OutstandingCell
                      style={{ backgroundColor: '#f7f8fa' }}
                      sx={{ pl: { xs: 4, sm: 8 } }}
                    >
                      <Typography variant='h1'>Liabilities / Account Payable</Typography>
                    </OutstandingCell>
                    <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
                    <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
                    <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
                    <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
                    <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
                  </OutstandingRow>
                  <OutstandingRow>
                    <OutstandingCell sx={{ pl: { xs: 4, sm: 8 } }} width={isMobile ? '60%' : 300}>
                      <Typography variant='h1'>Starting Balance</Typography>
                    </OutstandingCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <OutstandingCell width={isMobile ? undefined : 200} sx={{ pl: 2 }}>
                      <Typography variant='h1'>
                        {formatMoney(
                          adjustBalance(
                            data?.filter((item: any) => item.accountPayable)[0]?.balance,
                            data?.filter((item: any) => item.accountPayable)[0],
                          ),
                        )}
                      </Typography>
                    </OutstandingCell>
                  </OutstandingRow>
                  {data &&
                    data
                      ?.filter((item: any) => item.accountPayable)
                      .map((item: any, index: any) => (
                        <TableRow
                          key={item.publicId}
                          sx={
                            index === data?.length - 1
                              ? { '& .MuiTableCell-root': { border: 'none' } }
                              : undefined
                          }
                        >
                          <TableCell
                            style={{ backgroundColor: '#f7f8fa' }}
                            width={isMobile ? '60%' : 300}
                            sx={{ pl: { xs: 4, sm: 8 } }}
                          >
                            {formatDate(item.date)}
                          </TableCell>

                          <TableCell style={{ backgroundColor: '#f7f8fa' }}>
                            {item.description}
                          </TableCell>
                          <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
                          <TableCell style={{ backgroundColor: '#f7f8fa' }}>
                            {item.type === 'DEBIT' && formatMoney(item.amount)}
                          </TableCell>
                          <TableCell style={{ backgroundColor: '#f7f8fa' }}>
                            {item.type === 'CREDIT' && formatMoney(item.amount)}
                          </TableCell>

                          <TableCell style={{ backgroundColor: '#f7f8fa' }}>
                            {formatMoney(item.balance)}
                          </TableCell>
                        </TableRow>
                      ))}
                  <OutstandingRow>
                    <OutstandingCell width={isMobile ? '60%' : 300} sx={{ pl: { xs: 4, sm: 8 } }}>
                      <Typography variant='h1'>Total and Ending Balance</Typography>
                    </OutstandingCell>

                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <OutstandingCell width={isMobile ? undefined : 200} sx={{ pl: 2 }}>
                      <Typography variant='h1'>
                        {' '}
                        {formatMoney(
                          calculateBalanceChange(data?.filter((item: any) => item.accountPayable))
                            .totalDebits,
                        )}
                      </Typography>
                    </OutstandingCell>
                    <OutstandingCell width={isMobile ? undefined : 200} sx={{ pl: 2 }}>
                      <Typography variant='h1'>
                        {' '}
                        {formatMoney(
                          calculateBalanceChange(data?.filter((item: any) => item.accountPayable))
                            .totalCredits,
                        )}
                      </Typography>
                    </OutstandingCell>

                    <OutstandingCell width={isMobile ? undefined : 200} sx={{ pl: 2 }}>
                      <Typography variant='h1'>
                        {formatMoney(
                          data?.filter((item: any) => item.accountPayable)[
                            data?.filter((item: any) => item.accountPayable).length - 1
                          ]?.balance,
                        )}
                      </Typography>
                    </OutstandingCell>
                  </OutstandingRow>
                  <OutstandingRow>
                    <OutstandingCell
                      style={{ backgroundColor: '#DFE3EB' }}
                      width={isMobile ? '60%' : 300}
                      sx={{ pl: { xs: 4, sm: 8 } }}
                    >
                      <Typography variant='h1'>Balance Change</Typography>
                    </OutstandingCell>
                    <TableCell style={{ backgroundColor: '#DFE3EB' }}></TableCell>
                    <TableCell style={{ backgroundColor: '#DFE3EB' }}></TableCell>
                    <TableCell style={{ backgroundColor: '#DFE3EB' }}></TableCell>
                    <OutstandingCell
                      style={{ backgroundColor: '#DFE3EB' }}
                      width={isMobile ? undefined : 200}
                      sx={{ pl: 2 }}
                    ></OutstandingCell>
                    <OutstandingCell
                      style={{ backgroundColor: '#DFE3EB' }}
                      width={isMobile ? undefined : 200}
                      sx={{ pl: 2 }}
                    >
                      <Typography variant='h1'>
                        {formatMoney(
                          calculateBalanceChange(data?.filter((item: any) => item.accountPayable))
                            .minus,
                        )}
                      </Typography>
                    </OutstandingCell>
                  </OutstandingRow>
                </>
              )}
              {data?.filter((item: any) => item.accountPayable) !== null && (
                <>
                  <OutstandingRow
                    style={{ borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }}
                  >
                    <OutstandingCell
                      style={{ backgroundColor: '#f7f8fa' }}
                      sx={{ pl: { xs: 4, sm: 8 } }}
                    >
                      <Typography variant='h1'>Assets / Account Receivable</Typography>
                    </OutstandingCell>
                    <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
                    <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
                    <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
                    <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
                    <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
                  </OutstandingRow>
                  <OutstandingRow>
                    <OutstandingCell sx={{ pl: { xs: 4, sm: 8 } }} width={isMobile ? '60%' : 300}>
                      <Typography variant='h1'>Starting Balance</Typography>
                    </OutstandingCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <OutstandingCell width={isMobile ? undefined : 200} sx={{ pl: 2 }}>
                      <Typography variant='h1'>
                        {formatMoney(
                          adjustBalance(
                            data?.filter((item: any) => item.accountReceivable)[0]?.balance,
                            data?.filter((item: any) => item.accountReceivable)[0],
                          ),
                        )}
                      </Typography>
                    </OutstandingCell>
                  </OutstandingRow>
                  {data &&
                    data
                      ?.filter((item: any) => item.accountReceivable)
                      .map((item: any, index: any) => (
                        <TableRow
                          key={item.publicId}
                          sx={
                            index === data?.length - 1
                              ? { '& .MuiTableCell-root': { border: 'none' } }
                              : undefined
                          }
                        >
                          <TableCell
                            style={{ backgroundColor: '#f7f8fa' }}
                            width={isMobile ? '60%' : 300}
                            sx={{ pl: { xs: 4, sm: 8 } }}
                          >
                            {formatDate(item.date)}
                          </TableCell>
                          <TableCell style={{ backgroundColor: '#f7f8fa' }}>
                            {item.description}
                          </TableCell>
                          <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
                          <TableCell style={{ backgroundColor: '#f7f8fa' }}>
                            {item.type === 'DEBIT' && formatMoney(item.amount)}
                          </TableCell>
                          <TableCell style={{ backgroundColor: '#f7f8fa' }}>
                            {item.type === 'CREDIT' && formatMoney(item.amount)}
                          </TableCell>

                          <TableCell style={{ backgroundColor: '#f7f8fa' }}>
                            {formatMoney(item.balance)}
                          </TableCell>
                        </TableRow>
                      ))}
                  <OutstandingRow>
                    <OutstandingCell width={isMobile ? '60%' : 300} sx={{ pl: { xs: 4, sm: 8 } }}>
                      <Typography variant='h1'>Total and Ending Balance</Typography>
                    </OutstandingCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <OutstandingCell width={isMobile ? undefined : 200} sx={{ pl: 2 }}>
                      <Typography variant='h1'>
                        {' '}
                        {formatMoney(
                          calculateBalanceChange(
                            data?.filter((item: any) => item.accountReceivable),
                          ).totalDebits,
                        )}
                      </Typography>
                    </OutstandingCell>
                    <OutstandingCell width={isMobile ? undefined : 200} sx={{ pl: 2 }}>
                      <Typography variant='h1'>
                        {' '}
                        {formatMoney(
                          calculateBalanceChange(
                            data?.filter((item: any) => item.accountReceivable),
                          ).totalCredits,
                        )}
                      </Typography>
                    </OutstandingCell>
                    <OutstandingCell width={isMobile ? undefined : 200} sx={{ pl: 2 }}>
                      <Typography variant='h1'>
                        {formatMoney(
                          data?.filter((item: any) => item.accountReceivable)[
                            data?.filter((item: any) => item.accountReceivable).length - 1
                          ]?.balance,
                        )}
                      </Typography>
                    </OutstandingCell>
                  </OutstandingRow>
                  <OutstandingRow>
                    <OutstandingCell
                      style={{ backgroundColor: '#DFE3EB' }}
                      width={isMobile ? '60%' : 300}
                      sx={{ pl: { xs: 4, sm: 8 } }}
                    >
                      <Typography variant='h1'>Balance Change</Typography>
                    </OutstandingCell>
                        <TableCell style={{ backgroundColor: '#DFE3EB' }}></TableCell>
                        <TableCell style={{ backgroundColor: '#DFE3EB' }}></TableCell>
                        <TableCell style={{ backgroundColor: '#DFE3EB' }}></TableCell>
                    <OutstandingCell
                      style={{ backgroundColor: '#DFE3EB' }}
                      width={isMobile ? undefined : 200}
                      sx={{ pl: 2 }}
                    >
                    </OutstandingCell>
                    <OutstandingCell
                      style={{ backgroundColor: '#DFE3EB' }}
                      width={isMobile ? undefined : 200}
                      sx={{ pl: 2 }}
                    >
                      <Typography variant='h1'>
                        {formatMoney(
                          calculateBalanceChange(
                            data?.filter((item: any) => item.accountReceivable),
                          ).minus,
                        )}
                      </Typography>
                    </OutstandingCell>
                  </OutstandingRow>
                </>
              )}
            </TableBody>
          </Table>
        </ReportsTable>
      ) : (
        <div style={{ marginTop: '10px' }}>
          <NoDataDisplay title='No data to display' />
        </div>
      )}
    </>
  )
}

const ReportsTable = (props: any) => (
  <TableContainer
    sx={{
      '& .MuiTableHead-root .MuiTableCell-root': { fontWeight: 600, fontSize: 16 },
      '& .MuiTableBody-root .MuiTableCell-root': { fontSize: 14 },
      '& .MuiTableCell-root': { color: 'var(--secondary-grey)' },
      boxShadow: '0 4px 8px rgba(77, 94, 128, 0.08)',
      borderRadius: '8px',
      border: 'solid 1px var(--secondary-grey-bg)',
    }}
    {...props}
  >
    {props.children}
  </TableContainer>
)

const OutstandingCell = styled(TableCell)((props) => ({
  '&.MuiTableCell-root h1': {
    fontWeight: 600,
    fontSize: 16,
    color: props.color,
  },
  '&.MuiTableCell-root .MuiTypography-body1': {
    color: 'var(--gray3-color)',
    fontSize: 12,
  },
}))

const OutstandingRow = styled(TableRow)(() => ({
  '& .MuiTableCell-root': {
    border: 'none',
    backgroundColor: 'white',
  },
}))

export default AccountTransactionTable
