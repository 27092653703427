import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import LanguageIcon from '@mui/icons-material/Language';
import { ICompany } from '../../../types/types';

interface IContactDetails {
	company: ICompany;
}

const ContactDetails = (props: IContactDetails) => {
	return (
		<>
			<List>
				{props.company?.ownerEmail && (
					<ListItem>
						<ListItemIcon>
							<EmailIcon />
						</ListItemIcon>
						<ListItemText primary={props.company.ownerEmail} />
					</ListItem>
				)}
				{props.company?.phone && (
					<ListItem>
						<ListItemIcon>
							<PhoneIcon />
						</ListItemIcon>
						<ListItemText primary={props.company.phone} />
					</ListItem>
				)}
				{props.company?.mobile && (
					<ListItem>
						<ListItemIcon>
							<SmartphoneIcon />
						</ListItemIcon>
						<ListItemText primary={props.company.mobile} />
					</ListItem>
				)}
				{props.company?.website && (
					<ListItem>
						<ListItemIcon>
							<LanguageIcon />
						</ListItemIcon>
						<ListItemText primary={props.company.website} />
					</ListItem>
				)}
			</List>
		</>
	);
};

export default ContactDetails;
