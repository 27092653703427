import { Box, Button, FormControlLabel, Grid, LinearProgress, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import api from '../../api/api'
import { errorHandler } from '../../helpers/errorHandler'
import { getCompany } from '../../redux/actions/home.actions'
import { AppState } from '../../redux/store'
import { ICompany } from '../../types/types'
import ContentHeader from '../../components/content-header'

import { IOSSwitch } from '../../components/toogle/toogle'
import './preferences.css'
import { successToastNotification } from '../../helpers/toastNotification'
import LogoPreferences from './logoPreferences'
import ColorPreferences from './colorPreferences'
import TemplatePreview from './templatePreview'
import defaultLogo from '../../assets/images/logo-bc.png'
import { getCdnUrl, isUsingMobile } from '../../helpers/utils'

const Preferences = () => {
  const company = useSelector<AppState, ICompany | null>(
    (state: AppState) => state.homeReducer.company,
  )
  const dispatch = useDispatch()
  const [hasToAddBlueCimarronBanner, setHasToAddBlueCimarronBanner] = useState<boolean>(
    company?.showBanners ?? true,
  )
  const [selectedColor, setSelectedColor] = useState<string>(company?.accentColor ?? '#0039ff')
  const [logoURL, setLogoURL] = useState(getCdnUrl(company?.templateLogoFileName))
  const [logoFile, setLogoFile] = useState<File | null>()
  const isMobile = isUsingMobile()

  useEffect(() => {
    if (company?.showBanners) {
      setHasToAddBlueCimarronBanner(true)
    }
  }, [company?.showBanners])

  if (company === null) {
    return <Typography>Company not found</Typography>
  }

  const handleSaveClick = () => {
    const formData = new FormData()
    formData.append('accentColor', selectedColor)
    formData.append('showBanners', hasToAddBlueCimarronBanner ? 'true' : 'false')

    if (logoFile) {
      formData.append('logo', logoFile)
    }

    if (!logoURL) {
      formData.append('removeLogo', 'true')
    }

    api
      .editCompanyPreferences(formData)
      .then(() => {
        successToastNotification('Your preferences were successfully updated.')
        dispatch(getCompany())
      })
      .catch((err) => errorHandler(err))
  }

  return (
    <Box>
      <ContentHeader
        title={'Preferences'}
        component={
          <Button variant='contained' onClick={handleSaveClick}>
            Save
          </Button>
        }
      />

      <Box
        sx={{
          backgroundColor: 'white',
          color: 'var(--secondary-grey)',
          borderRadius: '10px',
          marginTop: '20px',
          paddingBottom: '40px',
          padding: '15px 15px 40px 15px',
        }}
      >
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Grid container flexDirection='column' spacing={1}>
              <Grid item>
                <Typography fontWeight='bold' mb={1}>
                  Logo
                </Typography>
                <LogoPreferences
                  logoDataURL={logoURL}
                  setLogoFile={setLogoFile}
                  setLogo={(dataURL) => setLogoURL(dataURL || '')}
                />
              </Grid>
              <Grid item>
                <Typography fontWeight='bold' mb={1}>
                  Accent color
                </Typography>
                <ColorPreferences hexColor={selectedColor} setHexColor={setSelectedColor} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6} sx={{ position: 'relative' }} width='100%'>
            <Typography fontWeight='bold'>Template for Invoices and Estimates</Typography>
            <Box
              style={{
                position: 'absolute',
                margin: isMobile ? '10px 0 0 10px' : '0 0 0 15px',
                width: isMobile ? '130px' : '204px',
                maxHeight: isMobile ? '90px' : '150px',
                overflow: 'hidden',
              }}
            >
              <img
                src={logoURL ? logoURL : defaultLogo}
                style={{
                  background: 'transparent',
                  maxWidth: '100%',
                }}
              />
            </Box>
            <TemplatePreview showBanner={hasToAddBlueCimarronBanner} hexColor={selectedColor} />
            <FormControlLabel
              control={<IOSSwitch sx={{ m: 1 }} />}
              checked={hasToAddBlueCimarronBanner}
              onChange={(event, checked) => setHasToAddBlueCimarronBanner(checked)}
              label={'Add Blue Cimarron banner'}
              labelPlacement='start'
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default Preferences
