import { TextField } from '@mui/material'
import {
  validateAddress,
  validateCanEmptyField,
  validateCommonField,
  validatePhoneNumber,
  validateState,
} from '../../../helpers/utils'
import { LabelPhoneField } from '../../../components/amount-field/amount-field'
import { deformatPhoneNumber } from '../../../helpers/formatPrice'
import { PHONE_VALID } from '../../../helpers/constants'

interface CustomerShippingInfoTabProps {
  newCustomer: any
  onChangeNewCustomer: any
  formErrors: any
  setFormErrors: any
}

const CustomerShippingInfoTab = ({
  newCustomer,
  onChangeNewCustomer,
  formErrors,
  setFormErrors,
}: CustomerShippingInfoTabProps) => {
  return (
    <>
      <TextField
        label='Ship to'
        sx={{ marginBottom: '25px' }}
        InputProps={{
          sx: {
            '& fieldset': {
              borderColor: 'var(--secondary-grey)',
            },
          },
        }}
        placeholder='Enter name of a business or person'
        value={newCustomer?.shippingName}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          validateCanEmptyField(e.target.value, 'shippingName', setFormErrors, 'ship to')
          onChangeNewCustomer(e, 'shippingName')
        }}
        error={!!formErrors['shippingName']}
        helperText={formErrors['shippingName']}
      />
      <LabelPhoneField
        label='Phone number'
        InputProps={{
          sx: {
            '& fieldset': {
              borderColor: 'var(--secondary-grey)',
            },
          },
        }}
        sx={{ marginBottom: '25px' }}
        styles='business-info__field'
        value={newCustomer?.shippingPhone}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          validatePhoneNumber(e.target.value, setFormErrors)
          onChangeNewCustomer(e, 'shippingPhone')
        }}
        isValid={
          !newCustomer?.shippingPhone ||
          PHONE_VALID.test(deformatPhoneNumber(newCustomer?.shippingPhone))
        }
      />
      <TextField
        label='Country'
        value='United States'
        sx={{ marginBottom: '25px' }}
        InputProps={{
          sx: {
            '& fieldset': {
              borderColor: 'var(--secondary-grey)',
            },
          },
        }}
      />
      <TextField
        sx={{ marginBottom: '25px' }}
        InputProps={{
          sx: {
            '& fieldset': {
              borderColor: 'var(--secondary-grey)',
            },
          },
        }}
        label='State'
        placeholder='Enter your state abbreviation'
        value={newCustomer?.shippingState}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          validateState(e.target.value, setFormErrors)
          onChangeNewCustomer(e, 'shippingState')
        }}
        error={!!formErrors['shippingState']}
        helperText={formErrors['shippingState']}
      />
      <TextField
        sx={{ marginBottom: '25px' }}
        InputProps={{
          sx: {
            '& fieldset': {
              borderColor: 'var(--secondary-grey)',
            },
          },
        }}
        label='City'
        placeholder='Enter your city'
        value={newCustomer?.shippingCity}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          validateCommonField(e.target.value, 'shippingCity', setFormErrors, 'city', 3)
          onChangeNewCustomer(e, 'shippingCity')
        }}
        error={!!formErrors['shippingCity']}
        helperText={formErrors['shippingCity']}
      />
      <TextField
        sx={{ marginBottom: '25px' }}
        InputProps={{
          sx: {
            '& fieldset': {
              borderColor: 'var(--secondary-grey)',
            },
          },
        }}
        label='Address line 1'
        placeholder='Build number, Street Address, Apartment...'
        value={newCustomer?.shippingAddress}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          validateAddress(e.target.value, 'shippingAddress', setFormErrors, 'address')
          onChangeNewCustomer(e, 'shippingAddress')
        }}
        error={!!formErrors['shippingAddress']}
        helperText={formErrors['shippingAddress']}
      />
      <TextField
        sx={{ marginBottom: '25px' }}
        InputProps={{
          sx: {
            '& fieldset': {
              borderColor: 'var(--secondary-grey)',
            },
          },
        }}
        label='Address line 2'
        placeholder='Build number, Street Address, Apartment...'
        value={newCustomer?.shippingAddressTwo}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          validateCanEmptyField(e.target.value, 'shippingAddressTwo', setFormErrors, 'address')
          onChangeNewCustomer(e, 'shippingAddressTwo')
        }}
        error={!!formErrors['shippingAddressTwo']}
        helperText={formErrors['shippingAddressTwo']}
      />
      <TextField
        sx={{ marginBottom: '25px' }}
        InputProps={{
          sx: {
            '& fieldset': {
              borderColor: 'var(--secondary-grey)',
            },
          },
        }}
        label='Postal/Zip code'
        placeholder='Enter your zip code'
        value={newCustomer?.shippingPostalCode}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          validateCommonField(
            e.target.value,
            'shippingPostalCode',
            setFormErrors,
            'Postal/Zip code',
            5,
          )
          onChangeNewCustomer(e, 'shippingPostalCode')
        }}
        error={!!formErrors['shippingPostalCode']}
        helperText={formErrors['shippingPostalCode']}
      />
    </>
  )
}

export default CustomerShippingInfoTab
