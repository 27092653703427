import {  SyntheticEvent, useEffect, useState } from 'react'
import { useDispatch} from 'react-redux'
import { saveEstimates } from '../../redux/actions/home.actions'
import ContentHeader from '../../components/content-header'
import './subscription.css'
import ListFilters from '../../components/list-filters'
import SubscriptionsTable from './subscriptions-table'
import UnsubscribeDialog from '../../components/unsubscribe-dialog/unsubscribe-dialog'
import { Box, Button } from '@mui/material'
import { isUsingMobile } from '../../helpers/utils'
import SubscriptionModal from './modal/subscriptionModal'
import Chat from '../../assets/images/chat.png'
import FAQ from './FAQ/FAQ'
import AssistanceDialog from '../../components/assitance-dialog/assistance-dialog'
import ResumeSubscriptionDialog from '../../components/resume-dialog/resume-dialog'
import SuccessDialog from '../../components/assitance-dialog/success-dialog'

const Subscription = () => {
  const dispatch = useDispatch()
  const [selectedFilter, setSelectedFilter] = useState('SUBS')
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false)
  const [isOpenResumDialog, setIsOpenResumeDialog] = useState(false)
  const [isOpenHelpDialog, setIsOpenHelpDialog] = useState(false)
  const [viewSub, setViewSub] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [assistanceSuccess, setAssistanceSuccess] = useState(false)
  const isMobile = isUsingMobile()

  const handleSelectedFilterChange = (event: SyntheticEvent, filter: string) => {
    setSelectedFilter(filter)
  }

  useEffect(() => {
    dispatch(saveEstimates())
  }, [dispatch])

  const handleDeleteClick = () => {
    setIsOpenDeleteDialog(true)
  }

  const handleResumeClick = () => {
    setIsOpenResumeDialog(true)
  }

  const handleDeleteConfirm = () => {
    setIsOpenDeleteDialog(false)
  }

  const handleResumeConfirm = () => {
    setIsOpenResumeDialog(false)
  }

  const handleViewClick = () => {
    setViewSub(true)
    setShowModal(true)
  }

  const handleHelpClick = () => {
    setIsOpenHelpDialog(true)
  }

  return (
    <>
      <div style={{ display: 'flex', marginTop: !isMobile ? '0px' : '10px' }}>
        <ContentHeader title={<Box>Subscription</Box>} />
        {selectedFilter === 'SUPPORT' && !isMobile && (
          <Button
            variant='contained'
            color='primary'
            style={{ width: '220px', height: '42px', marginTop: '4px' }}
            onClick={handleHelpClick}
          >
            <img src={Chat} alt='chat' style={{ marginRight: '8px' }} />
            <span>Need assistance?</span>
          </Button>
        )}
      </div>
      <ListFilters
        filters={[
          { title: 'Subscription', value: 'SUBS' },
          { title: 'Support', value: 'SUPPORT' },
        ]}
        selectedFilter={selectedFilter}
        onSelectedFilterChange={handleSelectedFilterChange}
      />

      {selectedFilter === 'SUPPORT' && <FAQ />}
      {selectedFilter === 'SUBS' && (
        <Box pb={4}>
          <SubscriptionsTable
            onRowClick={handleViewClick}
            onDeleteClick={handleDeleteClick}
            onResumeClick={handleResumeClick}
          />
        </Box>
      )}

      <UnsubscribeDialog
        isOpen={isOpenDeleteDialog}
        dialogTitle='We’re sorry to see you go'
        dialogBodyContent='Please, let us know the reason you are leaving. 
				Your feedback helps us improve.'
        buttonConfirmText='Keep Subscription'
        buttonCancelText='Cancel Subscription'
        handleClose={() => setIsOpenDeleteDialog(false)}
        handleConfirm={handleDeleteConfirm}
      />
      <ResumeSubscriptionDialog
        isOpen={isOpenResumDialog}
        dialogTitle='Resume subscription?'
        buttonConfirmText='Yes'
        buttonCancelText='Back'
        handleClose={() => setIsOpenResumeDialog(false)}
        handleConfirm={handleResumeConfirm}
      />
      <AssistanceDialog
        isOpen={isOpenHelpDialog}
        dialogTitle='How can we help you?'
        dialogBodyContent='It looks like you are experiencing problems. We are here to help so please get in touch with us.'
        handleClose={() => setIsOpenHelpDialog(false)}
        setAssistanceSuccess={setAssistanceSuccess}
      />
      {viewSub && (
        <SubscriptionModal
          showModal={showModal}
          setShowModal={setShowModal}
          setIsOpenDeleteDialog={setIsOpenDeleteDialog}
          setIsOpenResumeDialog={setIsOpenResumeDialog}
        />
      )}
      {assistanceSuccess && (
        <SuccessDialog
          isOpen={assistanceSuccess}
          setAssistanceSuccess={setAssistanceSuccess}
        />
      )}
      {selectedFilter === 'SUPPORT' && isMobile && (
        <Button
          variant='contained'
          color='primary'
          style={{ position: "fixed",
            bottom: "0",
            left: "0",
            width: "100%",
            height: "42px",
            marginTop: "4px" }}
          onClick={handleHelpClick}
        >
          <img src={Chat} alt='chat' style={{ marginRight: '8px' }} />
          <span>Need assistance?</span>
        </Button>
      )}
    </>
  )
}

export default Subscription
