import { Dialog, Grid, TextField, IconButton, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useEffect, useState } from 'react'
import { PatternFormat } from 'react-number-format'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import api from '../../api/api'
import crossBlackIcon from '../../assets/icons/cross-black.svg'
import { errorHandler } from '../../helpers/errorHandler'
import { validateCommonField } from '../../helpers/utils'
import { saveCertainCustomer } from '../../redux/actions/home.actions'
import { AppState } from '../../redux/store'
import { ICustomer } from '../../types/types'
import { CardDateInput, CardNumberInput } from '../card-inputs/card-inputs'
import FilledGreenButton from '../filled-green-button'
import './add-card-dialog.css'

interface ICardDateInput {
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  styles?: string
}

export const CardDateInputOld = (props: ICardDateInput) => {
  return (
    <PatternFormat
      customInput={TextField}
      format='##/##'
      placeholder='MM / YY'
      mask={['M', 'M', 'Y', 'Y']}
      {...props}
      name='customer-add-credit-card-exp-date'
    />
  )
}

interface INewCard {
  number: string
  expDate: string
  cvv: string
  cardName: string
}

const useStyles = makeStyles({
  paper: {
    maxWidth: '580px !important',
    width: 580,
  },
})

interface IAddCardDialog {
  open: boolean
  onClose: () => void
	customer?: ICustomer
}

const AddCardDialog = (props: IAddCardDialog) => {
  const { open, onClose, customer } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const merchantKey = useSelector((state: AppState) => state.authReducer.usioApiKey)

  const [newCard, setNewCard] = useState<INewCard>({
    number: '',
    expDate: '',
    cvv: '',
    cardName: '',
  })
	const [formErrors, setFormErrors] = useState({
		number: '',
		expDate: '', 
		cvv: '',
		name: '',
	});
  useEffect(() => {
    if (open) {
      setNewCard({
        number: '',
        expDate: '',
        cvv: '',
        cardName: '',
      })
    }
  }, [open])

  const handleChangeNewCard = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setNewCard({
      ...newCard,
      [value]: e.target.value,
    })
  }

  const handleSaveCardClick = () => {
    const reformattedDate = `${newCard.expDate.slice(0, 2)}20${newCard.expDate.slice(3)}`
    const cardData = {
      merchantKey,
      paymentType: 'CreditCard',
      emailAddress: customer?.primaryEmail,
      cardNumber: newCard.number.split(' ').join(''),
      expDate: reformattedDate,
      cvv: newCard.cvv,
    }
    api.generateUSIOToken(cardData).then((res) => {
      if (res.data.status === 'failure') {
        toast(res.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
          className: 'error-toast',
        })
      } else {
        const creditCardData = {
          customerId: customer?.publicId,
          cardType: res.data.cardBrand,
          lastDigits: res.data.last4,
          expirationDate: res.data.expDate,
          cardToken: res.data.token,
          name: newCard.cardName,
        }
        api
          .createCreditCard(creditCardData)
          .then((res) => {
            toast.success(`The credit card ${newCard.cardName} was successfully added`, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
            })
            dispatch(saveCertainCustomer(customer?.publicId))
            onClose()
          })
          .catch((err) => errorHandler(err))
      }
    })
  }

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={onClose}
      classes={{
        paper: classes.paper,
      }}
    >
      <div className='product-manage__wrapper'>
        <div className='product-manage__header' style={{marginBottom: "30px"}}>
          <div className='product-manage__header-title'>Add new card</div>
					<IconButton
						onClick={onClose}
            sx={{
              justifyItems: 'right',
            }}
          >
            <img src={crossBlackIcon} />
          </IconButton>
        </div>

        <CardNumberInput
          label='Card number'
          required
          error={!!formErrors['number']}
          helperText={formErrors['number']}
          value={newCard.number}
          onBlur={() =>
            validateCommonField(
              newCard.number.replaceAll(' ', ''),
              'number',
              setFormErrors,
              'Card number',
              8,
            )
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeNewCard(e, 'number')}
          sx={{ mb: 2 }}
        />

        <Grid container style={{padding: "70px"}}>
          <Grid item xs={6} pr={1}>
            <CardDateInput
              label='Expiry date'
              required
              value={newCard.expDate}
              error={!!formErrors['expDate']}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>{
                handleChangeNewCard(e, 'expDate');
								validateCommonField(
									e.target.value.replaceAll(' ', ''),
									'expirationDate',
									setFormErrors,
									'Expiry date',
									4,
								)
							}}
              helperText={formErrors['expDate']}
            />
          </Grid>
          <Grid item xs={6} pl={1}>
            <TextField
              label='CVV code'
              fullWidth
              required
              value={newCard.cvv}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 4 }}
              placeholder='000'
              sx={{ mb: 2 }}
              InputLabelProps={{ shrink: true }}
              error={!!formErrors['cvv']}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								handleChangeNewCard(e, 'cvv');
								validateCommonField(
									e.target.value.replaceAll(' ', ''),
									'cvv',
									setFormErrors,
									'CVV code',
									3
								);
							}}
              helperText={formErrors['cvv']}
            />
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <TextField
            label='Name on the card'
            required
            fullWidth
            value={newCard.cardName}
            placeholder='Insert the name on the card'
            error={!!formErrors['name']}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							handleChangeNewCard(e, 'cardName');
							validateCommonField(
								e.target.value.replaceAll(' ', ''),
								'name',
								setFormErrors,
								'Name on the card',
								3,
							);
						}}
            helperText={formErrors['name']}
            sx={{ mb: 2 }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        {/* <FilledGreenButton
          buttonText='Add new card'
          styles='add-card-dialog__button'
          onClick={handleSaveCardClick}
        /> */}
					<Button onClick={handleSaveCardClick} variant="contained">
					Add new card
				</Button>
      </div>
    </Dialog>
  )
}

export default AddCardDialog
