import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import api from '../../../api/api'
import { toast } from 'react-toastify'
import { isUsingMobile } from '../../../helpers/utils'
import { Alert } from '@mui/material'

import './import-confirm-dialog.css'

interface IImportConfirmDialog {
  isOpen: boolean
  dialogTitle?: string
  dialogBodyContent?: string
  buttonConfirmText?: string
  buttonCancelText?: string
  handleClose: () => void
  groupedData: any
  handleCloseExport: () => void
}

export default function ImportConfirmDialog(props: IImportConfirmDialog) {
  const { isOpen, dialogTitle, dialogBodyContent, handleClose, groupedData, handleCloseExport } =
    props

  const isMobile = isUsingMobile()

  const transformGroupedData = (groupedData: any) => {
    const transformedData = Object.keys(groupedData).map((type) => {
      return groupedData[type].map((item: any) => ({
        identifier: item.ID,
        name: item.Name,
        description: item.Description,
        type: type,
        status: item.Status === 'Active',
      }))
    })

    return { items: [].concat(...transformedData) }
  }

  const handleConfirmClick = () => {
    const transformedData = transformGroupedData(groupedData)
    api
      .importFinancialAccount(transformedData)
      .then(() => {
        toast.success(`Accounts imported successfuly`, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        })
      })
      .catch((err: any) =>
        toast.error('Something went wrong, please try again', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        }),
      )
    handleClose()
    handleCloseExport()
  }

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
        maxWidth={isMobile ? 'sm' : 'lg'}
        fullWidth
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            padding: !isMobile ? '30px 80px' : '30px 10px',
            width: '100%',
          }}
        >
          <DialogTitle id='responsive-dialog-title'>{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div style={{ textAlign: 'center' }}>{dialogBodyContent}</div>
            </DialogContentText>
          </DialogContent>
          <DialogContent style={{ width: '100%' }}>
            {Object.keys(groupedData || {}).map((type) => (
              <div key={type}>
                <Alert
                  style={{ backgroundColor: '#E5EBFF', color: '#0039FF', fontWeight: 600 }}
                  icon={false}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>{type}</div>
                  </div>
                </Alert>
                <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
                  <thead>
                    <tr>
                      <th style={{ color: '#4D5E80', textAlign: 'center', fontWeight: 600 }}>ID</th>
                      <th style={{ color: '#4D5E80', textAlign: 'center', fontWeight: 600 }}>
                        Name
                      </th>
                      <th style={{ color: '#4D5E80', textAlign: 'center', fontWeight: 600 }}>
                        Status
                      </th>
                      <th style={{ color: '#4D5E80', textAlign: 'center', fontWeight: 600 }}>
                        Description
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupedData &&
                      groupedData[type].map((item: any, index: number) => (
                        <tr
                          key={index}
                          style={{
                            backgroundColor: index % 2 === 0 ? '#F7F8FA' : '#fff',
                          }}
                        >
                          <td style={{ textAlign: 'center', fontWeight: 400 }}>{item.ID}</td>
                          <td style={{ textAlign: 'center', fontWeight: 400 }}>{item.Name}</td>
                          <td style={{ textAlign: 'center', fontWeight: 400 }}>{item.Status}</td>
                          <td style={{ textAlign: 'center', fontWeight: 400 }}>
                            {item.Description}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ))}
          </DialogContent>
          <div
            style={{
              marginTop: '5px',
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <div>
              <Button
                sx={{ ':hover': { borderWidth: '2px' } }}
                color='primary'
                variant='contained'
                onClick={handleConfirmClick}
                fullWidth
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  )
}
