import { Box, Button, Grid, SelectChangeEvent, Typography } from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import BaseModal from '../modal/modal'
import SelectorMui from '../selector-mui'
import QuarterSelector from '../quarter-selector/quarter-selector'
import DateField from '../date-field'

interface ReportFiltersModalProps {
  isModalOpen: boolean
  fromDate?: string
  toDate?: string
  onClose: () => void
  onConfirm: (from: string, to: string) => void
}

const getYearsList = () => {
  const YEARS_COUNT = 10
  return new Array(YEARS_COUNT + 1).fill(null).map((item: null, index: number) => {
    const year = moment()
      .subtract(index - 1, 'year')
      .year()
      .toString()

    return {
      value: year,
      title: year,
    }
  })
}

type FormDataType = {
  quarter: number | null
  year: string
  fromDate: string
  toDate: string
}

const ReportFiltersModal = ({ isModalOpen, fromDate, toDate, onClose, onConfirm }: ReportFiltersModalProps) => {
  const yearsList = getYearsList()
  const [formData, setFormData] = useState<FormDataType>({
    quarter: null,
    year: moment().year().toString(),
    fromDate: fromDate ? fromDate : moment().startOf('year').toISOString(),
    toDate: toDate ? toDate : moment().endOf('year').toISOString(),
  })

  useEffect(() => {
    if (formData.quarter) {
      setFormData({
        ...formData,
        fromDate: moment()
          .year(Number(formData.year))
          .quarter(Number(formData.quarter))
          .date(1)
          .toISOString(),
        toDate: moment()
          .year(Number(formData.year))
          .quarter(Number(formData.quarter))
          .endOf('quarter')
          .toISOString(),
      })
    } else {
      setFormData({
        ...formData,
        fromDate: fromDate ? fromDate : moment().startOf('year').toISOString(),
        toDate: toDate ? toDate : moment().endOf('year').toISOString(),
      })
    }
  }, [formData.quarter, formData.year])

  const clearFilters = () => {
    setFormData({
      quarter: null,
      year: String(moment().year()),
      fromDate: fromDate ? fromDate : moment().startOf('year').toISOString(),
      toDate: toDate ? toDate : moment().endOf('year').toISOString(),
    })
  }

  const handleConfirm = () => {
    onConfirm(formData.fromDate, formData.toDate)
    clearFilters()
    onClose()
  }

  return (
    <BaseModal
      title='Filters'
      isModalOpen={isModalOpen}
      body={
        <Box m={3}>
          <Typography fontSize={16} fontWeight={600} mb={2}>
            By year / quarter
          </Typography>
          <Box p={2} border='solid 1px var(--secondary-grey-bg)' borderRadius={2} mb={2}>
            <SelectorMui
              label='Year'
              placeholder='Select a year'
              options={yearsList}
              fullWidth
              value={formData.year}
              onChange={(e: SelectChangeEvent) =>
                setFormData({ ...formData, year: e.target.value })
              }
              sx={{ mb: 2 }}
            />
            <QuarterSelector
              value={formData.quarter}
              onChange={(quarter) => setFormData({ ...formData, quarter })}
            />
          </Box>
          <Typography fontSize={16} fontWeight={600} mb={2}>
            Custom
          </Typography>
          <Box p={2} border='solid 1px var(--secondary-grey-bg)' borderRadius={2} mb={2}>
            <Grid container spacing={2}>
              <Grid item flexGrow={1}>
                <DateField
                  value={formData.fromDate}
                  fullWidth
                  placeholder='Select a date'
                  label='From'
                  onChange={(date: string | null) => {
                    setFormData({ ...formData, fromDate: date || '', quarter: null })
                  }}
                />
              </Grid>
              <Grid item flexGrow={1}>
                <DateField
                  value={formData.toDate}
                  fullWidth
                  placeholder='Select a date'
                  label='To'
                  endDate={formData.fromDate}
                  onChange={(date: string | null) => {
                    setFormData({ ...formData, toDate: date || '', quarter: null })
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      }
      footer={
        <Grid
          container
          justifyContent={{ xs: 'normal', md: 'right' }}
          alignItems={{ xs: 'normal', md: 'center' }}
          flexDirection={{ xs: 'column-reverse', md: 'row' }}
        >
          <Grid item padding={{ xs: '1rem', md: '0rem' }}>
            <Button color='error' variant='outlined' onClick={() => onClose()} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item padding={{ xs: '1rem 1rem 0 1rem', md: '1rem' }}>
            <Button color='primary' variant='contained' onClick={handleConfirm} fullWidth>
              Apply filter
            </Button>
          </Grid>
        </Grid>
      }
      handleCloseModal={onClose}
    />
  )
}

export default ReportFiltersModal
