import moment from 'moment';

export const formatDate = (date: string) => {
	return moment(date).format('MMMM DD, YYYY');
};

export const formatShortenDate = (date: string | undefined) => {
	return moment(date).format('MMM DD, YYYY');
};

export const formatTime = (date: string | undefined) => {
	return moment(date).format('hh:mm A');
};
