import {
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { styled } from '@mui/system'

import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { ReactNode, useState } from 'react'
import { formatDate, formatMoney } from '../../../helpers/formatPrice'
import { useSelector } from 'react-redux'
import { AppState } from '../../../redux/store'
import { isUsingMobile } from '../../../helpers/utils'

const ProfitLossTable = ({ from, to }: { from: string; to: string }) => {
  const data = useSelector((state: AppState) => state.reportReducer.profitAndLoss)
  const isMobile = isUsingMobile()

  return (
    <ReportsTable component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={isMobile ? undefined : 300}>Accounts</TableCell>
            {!isMobile && (
              <>
                <TableCell>-</TableCell>
                <TableCell>-</TableCell>
                <TableCell>-</TableCell>
                <TableCell>-</TableCell>
              </>
            )}
            <TableCell width={isMobile ? undefined : 200}>
              {formatDate(from)} <br /> to {formatDate(to)}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <CollapsibleRow title='Income' totalAmount={data?.totalIncome}>
            <Table>
              <TableBody>
                {data?.incomeByAccount.map((item, index) => (
                  <TableRow
                    key={item.account}
                    sx={
                      index === data?.incomeByAccount.length - 1
                        ? { '& .MuiTableCell-root': { border: 'none' } }
                        : undefined
                    }
                  >
                    <TableCell width={isMobile ? '60%' : 300} sx={{ pl: { xs: 4, sm: 8 } }}>
                      {item.account}
                    </TableCell>
                    {!isMobile && (
                      <>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </>
                    )}
                    <TableCell width={isMobile ? undefined : 200} sx={{ pl: 2 }}>
                      {formatMoney(item.totalAmount)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CollapsibleRow>

          <CollapsibleRow title='Cost of Goods Sold' totalAmount={data?.totalCostOfGoodsSold}>
            <Table>
              <TableBody>
                {data?.costOfGoodsSoldByAccount.map((item, index) => (
                  <TableRow
                    key={item.account}
                    sx={
                      index === data?.costOfGoodsSoldByAccount.length - 1
                        ? { '& .MuiTableCell-root': { border: 'none' } }
                        : undefined
                    }
                  >
                    <TableCell width={isMobile ? '60%' : 300} sx={{ pl: { xs: 4, sm: 8 } }}>
                      {item.account}
                    </TableCell>
                    {!isMobile && (
                      <>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </>
                    )}
                    <TableCell width={isMobile ? undefined : 200} sx={{ pl: 2 }}>
                      {formatMoney(item.totalAmount)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CollapsibleRow>
        </TableBody>
      </Table>
      <Table>
        <TableBody>
          <OutstandingRow>
            <OutstandingCell
              width={isMobile ? '60%' : 300}
              color={data && data.grossProfit > 0 ? 'var(--green)' : 'var(--error-color)'}
            >
              <Typography variant='h1'>Gross Profit</Typography>
              <Typography variant='body1'>As a percentage of Total Income</Typography>
            </OutstandingCell>
            {!isMobile && (
              <>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </>
            )}
            <OutstandingCell
              width={isMobile ? undefined : 200}
              sx={{ pl: 2 }}
              color={data && data.grossProfit > 0 ? 'var(--green)' : 'var(--error-color)'}
            >
              <Typography variant='h1'>{formatMoney(data?.grossProfit)}</Typography>
              <Typography variant='body1'>{`${data?.grossProfitPercent}%`}</Typography>
            </OutstandingCell>
          </OutstandingRow>
        </TableBody>
      </Table>

      <Table>
        <TableBody>
          <CollapsibleRow title='Operating Expenses' totalAmount={data?.totalOperatingExpenses}>
            <Table>
              <TableBody>
                {data?.operatingExpensesByAccount.map((item, index) => (
                  <TableRow
                    key={item.account}
                    sx={
                      index === data?.operatingExpensesByAccount.length - 1
                        ? { '& .MuiTableCell-root': { border: 'none' } }
                        : undefined
                    }
                  >
                    <TableCell width={isMobile ? '60%' : 300} sx={{ pl: { xs: 4, sm: 8 } }}>
                      {item.account}
                    </TableCell>
                    {!isMobile && (
                      <>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </>
                    )}
                    <TableCell width={isMobile ? undefined : 200} sx={{ pl: 2 }}>
                      {formatMoney(item.totalAmount)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CollapsibleRow>
        </TableBody>
      </Table>

      <Table>
        <TableBody>
          <OutstandingRow>
            <OutstandingCell
              width={isMobile ? '60%' : 300}
              color={data && data.netProfit > 0 ? 'var(--green)' : 'var(--error-color)'}
            >
              <Typography variant='h1'>Net Profit</Typography>
              <Typography variant='body1'>As a percentage of Total Income</Typography>
            </OutstandingCell>
            {!isMobile && (
              <>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </>
            )}
            <OutstandingCell
              width={isMobile ? undefined : 200}
              sx={{ pl: 2 }}
              color={data && data.netProfit > 0 ? 'var(--green)' : 'var(--error-color)'}
            >
              <Typography variant='h1'>{formatMoney(data?.netProfit)}</Typography>
              <Typography variant='body1'>{`${data?.netProfitPercent}%`}</Typography>
            </OutstandingCell>
          </OutstandingRow>
        </TableBody>
      </Table>
    </ReportsTable>
  )
}

const ReportsTable = (props: any) => (
  <TableContainer
    sx={{
      '& .MuiTableHead-root .MuiTableCell-root': { fontWeight: 600, fontSize: 16 },
      '& .MuiTableBody-root .MuiTableCell-root': { fontSize: 14 },
      '& .MuiTableCell-root': { color: 'var(--secondary-grey)' },
      boxShadow: '0 4px 8px rgba(77, 94, 128, 0.08)',
      borderRadius: '8px',
      border: 'solid 1px var(--secondary-grey-bg)',
    }}
    {...props}
  >
    {props.children}
  </TableContainer>
)

const OutstandingCell = styled(TableCell)((props) => ({
  '&.MuiTableCell-root h1': {
    fontWeight: 600,
    fontSize: 16,
    color: props.color,
  },
  '&.MuiTableCell-root .MuiTypography-body1': {
    color: 'var(--gray3-color)',
    fontSize: 12,
  },
}))

const OutstandingRow = styled(TableRow)(() => ({
  '& .MuiTableCell-root': {
    border: 'none',
    backgroundColor: 'white',
  },
}))

const CollapsibleRow = ({
  title,
  totalAmount = 0,
  children,
}: {
  title: string
  totalAmount?: number
  children: ReactNode
}) => {
  const [open, setOpen] = useState(false)
  const isMobile = isUsingMobile()

  return (
    <>
      <TableRow sx={{ '& .MuiTableCell-root': { backgroundColor: 'var(--background-color)' } }}>
        <TableCell width={isMobile ? '60%' : 300}>
          {totalAmount !== 0 && (
            <IconButton size='small' onClick={() => setOpen(!open)} sx={{ mr: 1 }}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
          {title}
        </TableCell>
        {!isMobile && (
          <>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </>
        )}
        <TableCell width={isMobile ? undefined : 200}>{formatMoney(totalAmount)}</TableCell>
      </TableRow>
      <TableRow sx={{ '& .MuiTableCell-root': { backgroundColor: 'var(--background-color)' } }}>
        <TableCell sx={{ p: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            {children}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default ProfitLossTable
