import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material'
import { validateEmail } from '../../helpers/utils'
import { ILoginValues } from '../../pages/sign-in/sign-in'

interface ILogin {
  formErrors: any
  setFormErrors: any
  initialValues: ILoginValues
  handleChangeValues: (e: React.ChangeEvent<HTMLInputElement>, value: string) => void
  showPassword: boolean
  handleClickShowPassword: any
  handleMouseDownPassword: any
  handleForgotPasswordClick: any
  handleButtonClick: any
  handleSwitchSingView: any
  handleRegisterClick: any
}

const Login = ({
  formErrors,
  initialValues,
  setFormErrors,
  handleChangeValues,
  showPassword,
  handleClickShowPassword,
  handleMouseDownPassword,
  handleForgotPasswordClick,
  handleButtonClick,
  handleSwitchSingView,
  handleRegisterClick,
}: ILogin) => {
  return (
    <>
      <>
        <div style={{ flexDirection: 'column' }}>
          <Typography
            mt={2}
            id='modal-modal-title'
            variant='h3'
            fontWeight={700}
            color='var(--dark-grey-title)'
          >
            Sign In
          </Typography>
          <Typography
            mt={3}
            id='modal-modal-subtitle'
            fontWeight={400}
            color='var(--light-grey-title)'
            style={{ marginBottom: '20px' }}
          >
            Please fill your detail to access your account.
          </Typography>
        </div>
        <div>
          <div>
            <TextField
              placeholder='Enter your email'
              style={{ marginBottom: '20px' }}
              error={!!formErrors['email']}
              helperText={formErrors['email']}
              required
              type='email'
              id='outlined-required'
              label='Email'
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              value={initialValues.email}
              onBlur={() => validateEmail(initialValues.email, setFormErrors)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChangeValues(e, 'email')
                validateEmail(e.target.value, setFormErrors)
              }}
            />
          </div>
          <div>
            <FormControl variant='outlined' fullWidth>
              <InputLabel shrink required htmlFor='password'>
                Password
              </InputLabel>
              <OutlinedInput
                label='Password'
                sx={{ '& legend': { maxWidth: '100%' } }}
                error={!!formErrors['password']}
                placeholder='Enter your password'
                required
                id='password'
                type={showPassword ? 'text' : 'password'}
                value={initialValues.password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangeValues(e, 'password')
                }}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText>{formErrors['password']}</FormHelperText>
            </FormControl>
          </div>
        </div>
        <div>
          <div>
            <Typography
              className='sign-in__reset-password-text'
              onClick={handleForgotPasswordClick}
              style={{ marginBottom: '20px' }}
            >
              Forgot password?
            </Typography>
          </div>
        </div>
        <div>
          <div>
            <Button
              sx={{ fontWeight: 600 }}
              color='primary'
              variant='contained'
              onClick={handleButtonClick}
              fullWidth
              disabled={!!formErrors['email'] || !initialValues.email || !initialValues.password}
            >
              Sign in
            </Button>
          </div>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
            <Typography>{"Don't have an Account?"}</Typography>
            <span
              className='highlighted-text'
              onClick={() => handleRegisterClick()}
              id='register-now__link'
              style={{ marginLeft: '8px' }}
            >
              <Typography>Sign up</Typography>
            </span>
          </Box>
        </div>
      </>
    </>
  )
}

export default Login
