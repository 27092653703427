export const chartMonths = new Map();

chartMonths.set('01', 'Jan');
chartMonths.set('02', 'Feb');
chartMonths.set('03', 'Mar');
chartMonths.set('04', 'Apr');
chartMonths.set('05', 'May');
chartMonths.set('06', 'Jun');
chartMonths.set('07', 'Jul');
chartMonths.set('08', 'Aug');
chartMonths.set('09', 'Sep');
chartMonths.set('10', 'Oct');
chartMonths.set('11', 'Nov');
chartMonths.set('12', 'Dec');
