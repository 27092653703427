import { Card, CardContent, Grid, Typography } from '@mui/material'
import { formatDate, formatMoney } from '../../../helpers/formatPrice'
import { IPayment } from '../../../types/types'
import { formatTime } from '../../../helpers/formatDate'

interface RefundCardProps {
  payment: IPayment
}

const RefundCard = ({ payment }: RefundCardProps) => {
  return (
    <Card
      sx={{
        m: 2,
        '& .MuiTypography-root': { color: 'var(--grey-fonts)', fontSize: '14px' },
        '& .MuiTypography-subtitle1': { color: 'var(--secondary-grey)' },
        '& .MuiChip-colorSuccess': {
          backgroundColor: 'var(--success-bg-color)',
          color: 'var(--success-color)',
        },
        '& .MuiChip-colorWarning': {
          backgroundColor: 'var(--warning-bg-color)',
          color: 'var(--warning-color)',
        },
        '& .MuiChip-colorError': {
          backgroundColor: 'var(--error-bg-color)',
          color: 'var(--error-color)',
        },
        '& .MuiChip-colorInfo': {
          backgroundColor: 'var(--info-bg-color)',
          color: 'var(--info-color)',
        },
        '& .MuiChip-colorSecondary': {
          backgroundColor: 'var(--orange-bg-color)',
          color: 'var(--orange-color)',
        },
      }}
    >
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={6} sm={2}>
            <Typography>Refund amount</Typography>
            <Typography variant='subtitle1' sx={{ color: 'var(--success-color) !important' }}>
              {formatMoney(payment.amount)}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography>Customer Name</Typography>
            <Typography variant='subtitle1'>{payment.customerName}</Typography>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Typography>Refund method</Typography>
            <Typography variant='subtitle1'>
              {payment.cardType} {payment.lastDigits}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography>Refund date</Typography>
            <Typography variant='subtitle1'>
              {formatDate(payment.date)} {formatTime(payment.date)}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Typography>Paid invoice #</Typography>
            <Typography variant='subtitle1'>{payment.invoice.name}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default RefundCard
