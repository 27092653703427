import {
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from '@mui/material'
import { styled } from '@mui/styles'
import { IActivityPayment, IActivityStatement } from '../../../types/types'
import { formatDate, formatMoney } from '../../../helpers/formatPrice'
import { formatTime } from '../../../helpers/formatDate'

interface ActivityPaymentsProps {
  activityStatements: IActivityStatement | null
  mb?: number | string
}

const ActivityPayments = ({ activityStatements, mb }: ActivityPaymentsProps) => {
  const TableHeadTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'var(--background-color)',
      fontWeight: 'bold',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const getChipColorByStatus = (status: string) => {
    switch (status) {
      case 'PARTIAL':
        return 'warning'
      case 'SENT':
        return 'info'
      case 'PAID':
        return 'success'
      case 'OVERDUE':
        return 'error'
      case 'REFUNDED':
        return 'secondary'
      case 'DRAFT':
      default:
        return
    }
  }

  const renderPaymentMethod = (payment: any) => {
    switch (payment.method) {
      case 'CASH':
        return 'Cash'
      case 'CHECK':
        return 'Check'
      case 'CREDIT_CARD':
        return payment.cardType
    }
  }

  const renderPaymentDetails = (payment: IActivityPayment) => {
    if (payment.method === 'CREDIT_CARD') {
      return `**** **** **** ${payment?.lastDigits}`
    }

    if (payment.method === 'CHECK') {
      return payment.checkNumber
    }
  }

  const getTextColorByStatus = (status: string) => {
    switch (status) {
      case 'PARTIAL':
        return 'var(--warning-color)'
      case 'SENT':
        return 'var(--info-color)'
      case 'PAID':
        return 'var(--success-color)'
      case 'OVERDUE':
        return 'var(--error-color)'
      case 'REFUNDED':
        return 'var(--orange-color)'
      case 'DRAFT':
      default:
        return
    }
  }

  return (
    <Box mb={mb}>
      <Typography variant='h5' pb={1}>
        Payments
      </Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableHeadTableCell>Status</TableHeadTableCell>
            <TableHeadTableCell>Amount</TableHeadTableCell>
            <TableHeadTableCell>Method</TableHeadTableCell>
            <TableHeadTableCell>Payment details</TableHeadTableCell>
            <TableHeadTableCell>Inv. ID/date</TableHeadTableCell>
            <TableHeadTableCell>Payment date</TableHeadTableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            '& .MuiChip-colorSuccess': {
              backgroundColor: 'var(--success-bg-color)',
              color: 'var(--success-color)',
            },
            '& .MuiChip-colorWarning': {
              backgroundColor: 'var(--warning-bg-color)',
              color: 'var(--warning-color)',
            },
            '& .MuiChip-colorError': {
              backgroundColor: 'var(--error-bg-color)',
              color: 'var(--error-color)',
            },
            '& .MuiChip-colorInfo': {
              backgroundColor: 'var(--info-bg-color)',
              color: 'var(--info-color)',
            },
            '& .MuiChip-colorSecondary': {
              backgroundColor: 'var(--orange-bg-color)',
              color: 'var(--orange-color)',
            },
          }}
        >
          {activityStatements &&
            activityStatements.payments.map((payment) => {
              return (
                <TableRow key={payment.publicId}>
                  <TableCell>
                    <Chip label={payment.status} color={getChipColorByStatus(payment.status)} />
                  </TableCell>
                  <TableCell>{formatMoney(payment.amount)}</TableCell>
                  <TableCell>{renderPaymentMethod(payment)}</TableCell>
                  <TableCell>{renderPaymentDetails(payment)}</TableCell>
                  <TableCell>
                    <Typography>#{payment.invoice.name}</Typography>
                    <Typography>{formatDate(payment.invoice.periodStart)}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{formatDate(payment.date)}</Typography>
                    <Typography color={getTextColorByStatus(payment.status)}>
                      {formatTime(payment.date)}
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    </Box>
  )
}

export default ActivityPayments
