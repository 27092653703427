import { Box, Fab, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface IPlusButton {
	tooltipText?: string;
	handleOnClick: any;
	children?: React.ReactNode;
}

const PlusButton = (props: IPlusButton) => {
	const { tooltipText, handleOnClick } = props;

	return (
		<Box
			onClick={handleOnClick}
			sx={{
				display: 'flex',
				justifyContent: 'flex-end',
			}}
		>
			<Tooltip title={tooltipText}>
				<Fab
					style={{
						position: 'fixed',
						bottom: 0,
						marginBottom: '1rem',
						backgroundColor: 'var(--primary1-color)',
					}}
					aria-label="add"
				>
					<AddIcon style={{ color: 'white' }} fontSize={'large'} />
				</Fab>
			</Tooltip>
		</Box>
	);
};

export default PlusButton;
