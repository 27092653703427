import { Checkbox, FormControlLabel, FormGroup, Grid, TextField } from '@mui/material'
import { INewCreditCard } from '../../../types/types'
import { Box } from '@mui/system'
import { CardDateInput, CardNumberInput } from '../../../components/card-inputs/card-inputs'
import { validateCommonField } from '../../../helpers/utils'

interface NewCreditCardFormProps {
  creditCard: INewCreditCard
  customerEmail?: string | null
  setCreditCard: (creditCard: INewCreditCard) => void
  formErrors?: any
  setFormErrors?: (formErrors: any) => void
  mb?: number
  showSaveFuturePayments?: boolean
}

const NewCreditCardForm = ({
  creditCard,
  setCreditCard,
  formErrors,
  setFormErrors,
  mb = 10,
  showSaveFuturePayments = true,
}: NewCreditCardFormProps) => {
    return (
    <Box mt={2} mb={mb}>
      <CardNumberInput
        label='Card number'
        required
        error={!!formErrors['number']}
        helperText={formErrors['number']}
        value={creditCard.number}
        onBlur={() =>
          validateCommonField(
            creditCard.number.replaceAll(' ', ''),
            'number',
            setFormErrors,
            'Card number',
            8,
          )
        }
        onChange={(e) => {
          setCreditCard({ ...creditCard, number: e.target.value })
          validateCommonField(
            e.target.value.replaceAll(' ', ''),
            'number',
            setFormErrors,
            'Card number',
            8,
          )
        }}
        sx={{ mb: 2 }}
      />
      <Grid container>
        <Grid item xs={6} pr={1}>
          <CardDateInput
            label='Expiry date'
            required
            value={creditCard.expirationDate}
            error={!!formErrors['expirationDate']}
            onChange={(e) => {
              setCreditCard({ ...creditCard, expirationDate: e.target.value })
              validateCommonField(
                e.target.value.replaceAll(' ', ''),
                'expirationDate',
                setFormErrors,
                'Expiry date',
                4,
              )
            }}
            helperText={formErrors['expirationDate']}
          />
        </Grid>
        <Grid item xs={6} pl={1}>
          <TextField
            label='CVV code'
            fullWidth
            required
            value={creditCard.cvv}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 4 }}
            placeholder='000'
            sx={{ mb: 2 }}
            InputLabelProps={{ shrink: true }}
            error={!!formErrors['cvv']}
            onChange={(e) => {
              setCreditCard({ ...creditCard, cvv: e.target.value })
              validateCommonField(
                e.target.value.replaceAll(' ', ''),
                'cvv',
                setFormErrors,
                'CVV code',
                3,
              )
            }}
            helperText={formErrors['cvv']}
          />
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <TextField
          label='Name on the card'
          required
          fullWidth
          value={creditCard.name}
          placeholder='Insert the name on the card'
          error={!!formErrors['name']}
          onChange={(e) => {
            setCreditCard({ ...creditCard, name: e.target.value })
            validateCommonField(
              e.target.value.replaceAll(' ', ''),
              'name',
              setFormErrors,
              'Name on the card',
              3,
            )
          }}
          helperText={formErrors['name']}
          sx={{ mb: 2 }}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      {showSaveFuturePayments && (
        <FormGroup>
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label='Save this card for future payments'
            onChange={(e, value) => setCreditCard({ ...creditCard, saveCard: value })}
            value={creditCard.saveCard}
          />
        </FormGroup>
      )}
    </Box>
  )
}

export default NewCreditCardForm
