import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'

interface IDeleteReferralDialog {
  isOpen: boolean
  dialogTitle?: string
  buttonConfirmText?: string
  buttonCancelText?: string
  handleDelete: () => void
  handleClose: () => void
}

export default function DeleteReferralDialog(props: IDeleteReferralDialog) {
  const { isOpen, dialogTitle, buttonConfirmText, buttonCancelText, handleDelete,  handleClose } = props

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose} aria-labelledby='responsive-dialog-title'>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '30px 80px',
          }}
        >
          <DialogTitle id='responsive-dialog-title'>{dialogTitle}</DialogTitle>
          <div style={{ marginTop: '5px' }}>
            <DialogActions>
              <Button
                onClick={handleClose}
                style={{
                  borderColor: 'red',
                  borderWidth: 2,
                  borderStyle: 'solid',
                  background: 'red',
                }}
              >
                <Typography sx={{ color: 'white', fontWeight: '500' }}>
                  {buttonCancelText}
                </Typography>
              </Button>
              <Button
                onClick={handleDelete}
                style={{ borderColor: 'blue', borderWidth: 2, borderStyle: 'solid' }}
              >
                <Typography sx={{ fontWeight: '500' }}>{buttonConfirmText}</Typography>
              </Button>
            </DialogActions>
          </div>
        </div>
      </Dialog>
    </div>
  )
}
