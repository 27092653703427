import Box from '@mui/material/Box'
import { DataGrid, GridCellParams, gridClasses, GridColDef, GridTreeNode } from '@mui/x-data-grid'
import * as React from 'react'
import { useMemo } from 'react'
import DeleteButtonTable from '../../components/delete-icon/deleteIcon'
import { IProduct } from '../../types/types'
import { formatMoney } from '../../helpers/formatPrice'
import useAuthorization from '../../helpers/useAuthorization'

interface IProductTableProps {
  products: IProduct[]
  setIsProductManageDialogOpen: (bool: boolean) => void
  setSelectedProduct: any
  selectedProduct: IProduct | null
  setIsOpenDeleteDialog: any
}

const ProductTable: React.FC<IProductTableProps> = (props) => {
  const { setIsOpenDeleteDialog, setIsProductManageDialogOpen, products, setSelectedProduct } =
    props

  const { isAuthorized } = useAuthorization()

  const handleDeleted = (e: Event, publicId: any) => {
    e.preventDefault()
    e.stopPropagation()
    const productFounded = products.find((product) => product.publicId === publicId)
    setSelectedProduct(productFounded)
    setIsOpenDeleteDialog(true)
  }

  const columns = useMemo<GridColDef<IProduct>[]>(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        sortable: false,
      },
      {
        field: 'account',
        headerName: 'Account',
        flex: 1,
        sortable: false,
        renderCell: (param) => {
          return param.row.account?.name
        },
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 1,
        sortable: false,
      },
      {
        field: 'price',
        headerName: 'Price',
        flex: 0.5,
        sortable: false,
        renderCell: (param) => {
          return formatMoney(Number(param.row.price))
        },
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 0.5,
        sortable: false,
        renderCell: (param) => (
          <DeleteButtonTable handleOnDeleted={(e: Event) => handleDeleted(e, param.row.publicId)} />
        ),
      },
    ],
    [handleDeleted],
  )

  const getRowSpacing = React.useCallback((params: any) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 0 : 5,
    }
  }, [])

  const handleOnClickRow = (params: any) => {
    const productFounded = products.find((product) => product.publicId === params.id)
    setSelectedProduct(productFounded)
    setIsProductManageDialogOpen(true)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <DataGrid
        onRowClick={
          isAuthorized('write_product') ? (params) => handleOnClickRow(params) : undefined
        }
        disableColumnMenu
        getRowSpacing={getRowSpacing}
        rowHeight={65}
        hideFooter
        getRowId={(product) => product.publicId || product.name}
        rows={products}
        columns={columns}
        getCellClassName={(params: GridCellParams<any, IProduct, IProduct, GridTreeNode>) => {
          return params.field !== 'actions' && params.field !== 'confirmed' ? 'rowStyle' : ''
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              actions: isAuthorized('write_product'),
            },
          },
        }}
        sx={{
          border: 'none !important',
          '& .MuiDataGrid-columnHeadersInner': {
            color: 'var(--grey-fonts)',
          },
          [`& .${gridClasses.row}`]: {
            bgcolor: 'white',
            borderRadius: '10px',
            boxShadow: '0px 2px 6px rgba(77, 94, 128, 0.15)',
            alignItems: 'center',
          },
          [`& .${gridClasses.virtualScroller}`]: {
            overflow: 'visible',
            overflowX: 'visible !important',
          },
          [`& .${gridClasses.main}`]: {
            overflow: 'visible',
          },
          [`& .${gridClasses.cell}:last-child`]: {
            padding: '0px',
          },
          [`& .${gridClasses.cell}`]: {
            border: 'none',
            cursor: isAuthorized('write_product') ? 'pointer' : 'normal',
          },
          [`& .${gridClasses.columnHeaders}`]: {
            border: 'none',
          },
          [`& .${gridClasses.footerContainer}`]: {
            border: 'none',
          },
          [`& .${gridClasses.columnSeparator}`]: {
            display: 'none',
          },
          [`& .${gridClasses.cell}:focus`]: {
            outline: 'none',
          },
        }}
      />
    </Box>
  )
}

export default ProductTable
