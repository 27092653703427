interface ItemList {
	[key: string]: string;
}

export const typeOptions = [
	{
		title: 'Daily',
		value: 'DAILY',
	},
	{
		title: 'Weekly',
		value: 'WEEKLY',
	},
	{
		title: 'Monthly',
		value: 'MONTHLY',
	},
	{
		title: 'Yearly',
		value: 'YEARLY',
	},
]

export const endTypeOptions = [
	{
		title: 'Never',
		value: 'NEVER',
	},
	{
		title: 'After',
		value: 'AFTER',
	},
	{
		title: 'On',
		value: 'ON',
	},
]

export const dayOfWeekMenuItems = [
	{ value: 1, title: 'Monday' },
	{ value: 2, title: 'Tuesday' },
	{ value: 3, title: 'Wednesday' },
	{ value: 4, title: 'Thursday' },
	{ value: 5, title: 'Friday' },
	{ value: 6, title: 'Saturday' },
	{ value: 7, title: 'Sunday' },
];

export const daysOfWeek: ItemList = {
	1: 'Monday',
	2: 'Tuesday',
	3: 'Wednesday',
	4: 'Thursday',
	5: 'Friday',
	6: 'Saturday',
	7: 'Sunday',
};

export const daysOfMonth: ItemList = {
	1: '1st',
	2: '2nd',
	3: '3rd',
	4: '4th',
	5: '5th',
	6: '6th',
	7: '7th',
	8: '8th',
	9: '9th',
	10: '10th',
	11: '11th',
	12: '12th',
	13: '13th',
	14: '14th',
	15: '15th',
	16: '16th',
	17: '17th',
	18: '18th',
	19: '19th',
	20: '20th',
	21: '21th',
	22: '22th',
	23: '23th',
	24: '24th',
	25: '25th',
	26: '26th',
	27: '27th',
	28: '28th',
	29: '29th',
	30: '30th',
	31: '31th',
};

export const dayOfMonthOptions = [
	{ value: 1, title: '1st' },
	{ value: 2, title: '2nd' },
	{ value: 3, title: '3rd' },
	{ value: 4, title: '4th' },
	{ value: 5, title: '5th' },
	{ value: 6, title: '6th' },
	{ value: 7, title: '7th' },
	{ value: 8, title: '8th' },
	{ value: 9, title: '9th' },
	{ value: 10, title: '10th' },
	{ value: 11, title: '11th' },
	{ value: 12, title: '12th' },
	{ value: 13, title: '13th' },
	{ value: 14, title: '14th' },
	{ value: 15, title: '15th' },
	{ value: 16, title: '16th' },
	{ value: 17, title: '17th' },
	{ value: 18, title: '18th' },
	{ value: 19, title: '19th' },
	{ value: 20, title: '20th' },
	{ value: 21, title: '21th' },
	{ value: 22, title: '22th' },
	{ value: 23, title: '23th' },
	{ value: 24, title: '24th' },
	{ value: 25, title: '25th' },
	{ value: 26, title: '26th' },
	{ value: 27, title: '27th' },
	{ value: 28, title: '28th' },
	{ value: 29, title: '29th' },
	{ value: 30, title: '30th' },
	{ value: 31, title: '31th' },
];

export const months: ItemList = {
	1: 'January',
	2: 'February',
	3: 'March',
	4: 'April',
	5: 'May',
	6: 'June',
	7: 'July',
	8: 'August',
	9: 'September',
	10: 'October',
	11: 'November',
	12: 'December',
};

export const monthOptions = [
	{ value: 1, title: 'January' },
	{ value: 2, title: 'February' },
	{ value: 3, title: 'March' },
	{ value: 4, title: 'April' },
	{ value: 5, title: 'May' },
	{ value: 6, title: 'June' },
	{ value: 7, title: 'July' },
	{ value: 8, title: 'August' },
	{ value: 9, title: 'September' },
	{ value: 10, title: 'October' },
	{ value: 11, title: 'November' },
	{ value: 12, title: 'December' },
];

export const paymentDueList = new Map();
paymentDueList.set('0', 'On Receipt');
paymentDueList.set('1', 'Within 1 Day');
paymentDueList.set('7', 'Within 7 Days');
paymentDueList.set('14', 'Within 14 Days');
paymentDueList.set('30', 'Within 30 Days');
paymentDueList.set('45', 'Within 45 Days');
paymentDueList.set('60', 'Within 60 Days');
paymentDueList.set('90', 'Within 90 Days');
