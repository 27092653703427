import { Box, Typography } from '@mui/material'
import { formatMoney } from '../../../helpers/formatPrice'

interface AgedReceivableKpiProps {
  title: string
  amount: number | string
  subtitle: number
  periodIndex: number
  maxInvoicesAmount: number
  isDisabled?: boolean
}

const AgedReceivableKpi = (props: AgedReceivableKpiProps) => {
  const { title, amount, periodIndex, maxInvoicesAmount, isDisabled } = props
  const itemHeight = isDisabled ? 5 : (Number(amount) * 20) / maxInvoicesAmount

  const colors = ['#17A5E6', '#7919FF', '#D91668', '#FFCB33']

  const disabledColors = [
    'rgba(23, 165, 230, 0.5)',
    'rgba(121, 25, 255, 0.5)',
    'rgba(217, 22, 104, 0.5)',
    'rgba(255, 203, 51, 0.5)',
  ]

  const bgColor = amount ? colors[periodIndex] : disabledColors[periodIndex]

  return (
    <Box>
      <Typography
        sx={{
          textAlign: 'center',
          fontSize: 24,
          fontWeight: 600,
          color: isDisabled ? 'var(--primary-grey)' : undefined,
        }}
      >
        {formatMoney(Number(amount))}
      </Typography>
      <Box sx={{ background: bgColor, height: itemHeight, mb: 2 }}></Box>
      <Typography textAlign='center' color='var(--secondary-grey)'>
        {title}
      </Typography>
    </Box>
  )
}

export default AgedReceivableKpi
