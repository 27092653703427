import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { formatMoney } from '../../../helpers/formatPrice';
import { IInvoice, IScheduledInvoice } from '../../../types/types';

interface IInvoicePreviewFooterCardProps {
	invoice: IInvoice | IScheduledInvoice;
}

const InvoicePreviewFooterCard = ({
	invoice,
}: IInvoicePreviewFooterCardProps) => {
	return (
		<Card
			sx={{
				color: 'var(--secondary-grey)',
				'& .MuiTypography-root': { fontSize: '14px' },
			}}
		>
			<CardContent>
				<Box mb={2}>
					<Typography>Notes</Typography>
					<Typography sx={{ fontSize: '14px' }}>{invoice.memo}</Typography>
				</Box>

				<Grid container mb={2}>
					<Grid item xs={8}>
						<Typography>Subtotal</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography textAlign="right">
							{formatMoney(Number(invoice.subTotal))}
						</Typography>
					</Grid>
				</Grid>

				<Grid
					container
					sx={{ backgroundColor: 'var(--background-color)', py: 2, px: 1 }}
				>
					<Grid item xs={8}>
						<Typography fontWeight="bold">TOTAL</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography fontWeight="bold" textAlign="right">
							{formatMoney(Number(invoice.total))}
						</Typography>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

export default InvoicePreviewFooterCard;
