import { Grid } from '@mui/material';
import InvoiceCard from './invoice-card';
import { thousandPrice } from '../../helpers/formatPrice';

interface IInvoiceStats {
	stats: any;
}

const InvoiceStats = ({ stats }: IInvoiceStats) => {
	return (
		<Grid container spacing={1} mb={2} flexDirection="row">
			<Grid item xs={6} sm={3}>
				<InvoiceCard
					title="Overdue"
					value={`$${thousandPrice(stats?.totalOverdue)}`}
				/>
			</Grid>
			<Grid item xs={6} sm={3}>
				<InvoiceCard
					title="Within 30 days"
					value={`$${thousandPrice(stats?.totalReceivedThisMonth)}`}
				/>
			</Grid>
			<Grid item xs={6} sm={3}>
				<InvoiceCard
					title="Avg. time to get paid"
					value={`${
						stats?.averagePaymentDays
							? Math.floor(stats?.averagePaymentDays)
							: 0
					} ${stats?.averagePaymentDays === 1 ? 'day' : 'days'}`}
				/>
			</Grid>
			<Grid item xs={6} sm={3}>
				<InvoiceCard
					title="Total received"
					value={`$${thousandPrice(stats?.totalReceived)}`}
				/>
			</Grid>
		</Grid>
	);
};

export default InvoiceStats;
