import { MouseEventHandler } from 'react';
import './page-tab.css';

interface IPageTab {
	title: string;
	description: string;
	isChosen: boolean;
	width?: number;
	onClick: MouseEventHandler<HTMLDivElement> | undefined;
}

const PageTab = (props: IPageTab) => {
	const { title, description, isChosen, width, onClick } = props;
	return (
		<div
			onClick={onClick}
			className={`profile-tab__wrapper ${
				isChosen ? 'profile-tab__active' : ''
			}`}
			style={{ width: width || '' }}
		>
			<div className="profile-tab__title">{title}</div>
			<div
				className="profile-tab__description"
				style={{ color: isChosen ? '#fff' : 'var(--gray2-color)' }}
			>
				{description}
			</div>
		</div>
	);
};

export default PageTab;
