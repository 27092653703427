import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material'
import { IInvoice } from '../../types/types'
import { formatDate, formatMoney } from '../../helpers/formatPrice'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import { formatTime } from '../../helpers/formatDate'
import { useSelector } from 'react-redux'
import { AppState } from '../../redux/store'
import { isUsingMobile } from '../../helpers/utils'
import useAuthorization from '../../helpers/useAuthorization'

interface InvoiceDetailsProps {
  invoice: IInvoice
  onEditClick?: () => void
  onSendClick?: () => void
  onRecordPaymentClick?: () => void
}

const InvoiceDetails = ({
  invoice,
  onEditClick,
  onSendClick,
  onRecordPaymentClick,
}: InvoiceDetailsProps) => {
  const company = useSelector((state: AppState) => state.homeReducer.company)
  const isMobile = isUsingMobile()
  const { isAuthorized } = useAuthorization()
  const creationDetails = invoice?.invoiceHistory?.find((item) => item.action === 'CREATED')

  const reminderDetails = invoice?.invoiceHistory?.find((item) => item.action === 'REMINDER_SENT')

  const partialPaymentDetails = invoice?.invoiceHistory?.find(
    (item) => item.action === 'PARTIALLY_PAID',
  )

  const fullPaymentDetails = invoice?.invoiceHistory?.find((item) => item.action === 'PAID')

  const sendingDetails = invoice?.invoiceHistory?.find((item) => item.action === 'SENT')

  const getColorByStatus = (status: string) => {
    switch (status) {
      case 'PARTIAL':
        return 'warning'
      case 'SENT':
        return 'info'
      case 'PAID':
        return 'success'
      case 'OVERDUE':
        return 'error'
      case 'REFUND':
        return 'secondary'
      case 'DRAFT':
      default:
        return
    }
  }

  return (
    <>
      {!isMobile && (
        <Box m={2}>
          <Card
            sx={{
              '& .MuiTypography-root': { color: 'var(--grey-fonts)' },
              '& .MuiTypography-subtitle1': { color: 'var(--secondary-grey)' },
              '& .MuiChip-colorSuccess': {
                backgroundColor: 'var(--success-bg-color)',
                color: 'var(--success-color)',
              },
              '& .MuiChip-colorWarning': {
                backgroundColor: 'var(--warning-bg-color)',
                color: 'var(--warning-color)',
              },
              '& .MuiChip-colorError': {
                backgroundColor: 'var(--error-bg-color)',
                color: 'var(--error-color)',
              },
              '& .MuiChip-colorInfo': {
                backgroundColor: 'var(--info-bg-color)',
                color: 'var(--info-color)',
              },
              '& .MuiChip-colorSecondary': {
                backgroundColor: 'var(--orange-bg-color)',
                color: 'var(--orange-color)',
              },
            }}
          >
            <CardContent>
              <Grid container>
                <Grid item xs={6} sm={2}>
                  <Typography>Status</Typography>
                  <Chip color={getColorByStatus(invoice.status || '')} label={invoice.status} />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Typography>Customer Name</Typography>
                  <Typography variant='subtitle1'>{invoice.customerName}</Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Typography>Amount</Typography>
                  <Typography variant='subtitle1'>{formatMoney(Number(invoice.total))}</Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Typography>Due on</Typography>
                  <Typography variant='subtitle1'>{formatDate(invoice.periodEnd)}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
      <Box m={2}>
        <Stepper
          orientation='vertical'
          sx={{
            '& .MuiStepLabel-label, & .MuiStepLabel-label.Mui-active': {
              color: 'var(--success-color)',
              fontWeight: 'bold',
            },
          }}
        >
          <Step key='creation' expanded={true}>
            <StepLabel
              icon={
                creationDetails ? (
                  <CheckCircleIcon color='success' />
                ) : (
                  <CircleOutlinedIcon color='success' />
                )
              }
            >
              Invoice creation
            </StepLabel>
            <StepContent>
              <Grid container spacing={!isMobile ? 1 : undefined}>
                <Grid item sm={9}>
                  {creationDetails && (
                    <Typography>
                      <Typography component='span' fontWeight='bold'>
                        Invoice created:
                      </Typography>{' '}
                      on {formatDate(creationDetails.date)} at {formatTime(creationDetails?.date)}
                    </Typography>
                  )}
                </Grid>
                <Grid item sm={3}>
                  {isAuthorized('write_invoice') && (
                    <Button
                      variant='outlined'
                      onClick={onEditClick}
                      fullWidth
                      disabled={invoice.status === 'OVERDUE' || invoice.status === 'PAID'}
                    >
                      Edit invoice
                    </Button>
                  )}
                </Grid>
              </Grid>
            </StepContent>
          </Step>
          <Step key='sending' expanded={true}>
            <StepLabel
              icon={
                sendingDetails ? (
                  <CheckCircleIcon color='success' />
                ) : (
                  <CircleOutlinedIcon color='success' />
                )
              }
            >
              Invoice sending
            </StepLabel>
            <StepContent>
              <Grid container spacing={!isMobile ? 1 : undefined}>
                <Grid item sm={9}>
                  {sendingDetails && (
                    <Typography>
                      <Typography component='span' fontWeight='bold'>
                        Invoice sent:
                      </Typography>{' '}
                      on {formatDate(sendingDetails.date)} at {formatTime(sendingDetails.date)}
                    </Typography>
                  )}
                  {reminderDetails && (
                    <Typography>
                      <Typography component='span' fontWeight='bold'>
                        Reminder sent:
                      </Typography>{' '}
                      on {formatDate(reminderDetails.date)} at {formatTime(reminderDetails.date)}
                    </Typography>
                  )}
                </Grid>
                <Grid item sm={3}>
                  {isAuthorized('write_invoice') && (
                    <Button
                      variant='outlined'
                      onClick={onSendClick}
                      disabled={invoice.status === 'PAID'}
                      fullWidth
                    >
                      {invoice.status !== 'DRAFT' ? 'Resend invoice' : 'Send invoice'}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </StepContent>
          </Step>
          <Step key='payment' expanded={true}>
            <StepLabel
              icon={
                fullPaymentDetails ? (
                  <CheckCircleIcon color='success' />
                ) : (
                  <CircleOutlinedIcon color='success' />
                )
              }
            >
              Invoice payment
            </StepLabel>
            <StepContent>
              <Grid container spacing={!isMobile ? 1 : undefined}>
                <Grid item sm={9}>
                  {fullPaymentDetails && (
                    <Typography>
                      <Typography component='span' fontWeight='bold'>
                        Amount due:
                      </Typography>{' '}
                      on {formatDate(fullPaymentDetails?.date)} at{' '}
                      {formatTime(fullPaymentDetails?.date)}
                    </Typography>
                  )}
                  {partialPaymentDetails && (
                    <Typography>
                      <Typography component='span' fontWeight='bold'>
                        Amount due:
                      </Typography>{' '}
                      on {formatDate(partialPaymentDetails?.date)} at{' '}
                      {formatTime(partialPaymentDetails?.date)}
                    </Typography>
                  )}
                </Grid>
                <Grid item sm={3}>
                  {company?.merchantStatus === 'Onboarded' && isAuthorized('write_invoice') && (
                    <Button
                      variant='outlined'
                      onClick={onRecordPaymentClick}
                      disabled={['DRAFT', 'PAID'].includes(invoice.status || '')}
                      fullWidth
                    >
                      Record payment
                    </Button>
                  )}
                </Grid>
              </Grid>
            </StepContent>
          </Step>
        </Stepper>
      </Box>
    </>
  )
}

export default InvoiceDetails
