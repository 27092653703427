import { Button, Grid } from '@mui/material'

interface QuarterSelectorProps {
  value: number | null
  onChange: (value: number | null) => void
}

const QuarterSelector = ({ value, onChange }: QuarterSelectorProps) => {
  const quarters = [
    { label: 'Q1', value: 1 },
    { label: 'Q2', value: 2 },
    { label: 'Q3', value: 3 },
    { label: 'Q4', value: 4 },
  ]

  const handleClick = (quarter: number) => () => {
    if (quarter === value) {
      onChange(null)
    } else {
      onChange(quarter)
    }
  }

  return (
    <Grid container justifyContent='stretch' direction='row' spacing={1}>
      {quarters.map((quarter) => (
        <Grid item flexGrow={1} key={quarter.value}>
          <Button
            variant='outlined'
            color={quarter.value === value ? 'primary' : 'secondary'}
            fullWidth
            onClick={handleClick(quarter.value)}
            sx={{
              '&.MuiButton-outlinedSecondary': {
                color: 'var(--secondary-grey)',
                backgroundColor: 'var(--background-color)',
              },
              '&.MuiButton-outlinedPrimary': {
                backgroundColor: 'var(--secondary1-color)',
              },
            }}
          >
            {quarter.label}
          </Button>
        </Grid>
      ))}
    </Grid>
  )
}

export default QuarterSelector
