import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material'
import { ICustomer } from '../../../types/types'

interface BillingAddressProps {
  customer: ICustomer
}

const BillingAddress = ({ customer }: BillingAddressProps) => {
  return (
    <Card sx={{ '&.MuiPaper-root': { boxShadow: 'none' } }}>
      <CardHeader title='Billing address'></CardHeader>
      <CardContent>
        {customer && (
          <Grid container>
            <Grid item sm={6}>
              <Typography variant='subtitle1'>Address line 1</Typography>
              <Typography variant='subtitle2' color='text.secondary'>
                {customer.billingAddress ? customer.billingAddress : '-'}
              </Typography>
              <Typography variant='subtitle1'>City</Typography>
              <Typography variant='subtitle2' color='text.secondary'>
                {customer.billingCity ? customer.billingCity : '-'}
              </Typography>
              <Typography variant='subtitle1'>Postal/Zip code</Typography>
              <Typography variant='subtitle2' color='text.secondary'>
                {customer.billingPostalCode ? customer.billingPostalCode : '-'}
              </Typography>
            </Grid>
            <Grid item sm={6}>
              <Typography variant='subtitle1'>Address line 2</Typography>
              <Typography variant='subtitle2' color='text.secondary'>
                {customer.billingAddressTwo ? customer.billingAddressTwo : '-'}
              </Typography>
              <Typography variant='subtitle1'>State</Typography>
              <Typography variant='subtitle2' color='text.secondary'>
                {customer.billingState ? customer.billingState : '-'}
              </Typography>
              <Typography variant='subtitle1'>Country</Typography>
              <Typography variant='subtitle2' color='text.secondary'>
                {customer?.billingCountry ? customer.billingCountry : '-'}
              </Typography>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  )
}

export default BillingAddress
