export const SAVE_PRODUCTS = 'SAVE_PRODUCTS';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';
export const SAVE_CUSTOMERS = 'SAVE_CUSTOMERS';
export const SAVE_CERTAIN_CUSTOMER = 'SAVE_CERTAIN_CUSTOMER';
export const SAVE_ESTIMATES = 'SAVE_ESTIMATES';
export const CREATE_ESTIMATE = 'CREATE_ESTIMATE';
export const SAVE_CERTAIN_ESTIMATE = 'SAVE_CERTAIN_ESTIMATE';
export const SAVE_INVOICES = 'SAVE_INVOICES';
export const CREATE_INVOICE = 'CREATE_INVOICE';
export const SAVE_CERTAIN_INVOICE = 'SAVE_CERTAIN_INVOICE';
export const SAVE_COMPANY = 'SAVE_COMPANY';
export const SAVE_PAYMENTS = 'SAVE_PAYMENTS';
export const SAVE_OUTSTANDING_DATA = 'SAVE_OUTSTANDING_DATA';
export const SAVE_OUTSTANDING_STATEMENTS = 'SAVE_OUTSTANDING_STATEMENTS';
export const SAVE_ACTIVITY_STATEMENTS = 'SAVE_ACTIVITY_STATEMENTS';
export const SAVE_SCHEDULED_TEMPLATES = 'SAVE_SCHEDULED_TEMPLATES';
export const CREATE_SCHEDULED_TEMPLATE = 'CREATE_SCHEDULED_TEMPLATE';
export const SAVE_CERTAIN_TEMPLATE = 'SAVE_CERTAIN_TEMPLATE';
export const CHANGE_IS_LOADING = 'CHANGE_IS_LOADING';
export const HAVE_SUBSCRIPTION = 'HAVE_SUBSCRIPTION';
export const SUBSCRIPTION_DATA = 'SUBSCRIPTION_DATA';
export const REFERRALS_DATA = 'REFERRALS_DATA';
export const SAVE_FAQ  = 'SAVE_FAQ';
export const PAY_NOW_UNAVAILABLE = 'PAY_NOW_UNAVAILABLE';
export const SAVE_INVOICES_STATISTICS = 'SAVE_INVOICES_STATISTICS';
export const SAVE_PURCHASE_PRODUCTS = 'SAVE_PURCHASE_PRODUCTS';
export const SAVE_EXPENSES_ACCOUNTS = 'SAVE_EXPENSES_ACCOUNTS';
export const CREATE_PURCHASE_PRODUCT = 'CREATE_PURCHASE_PRODUCT';
export const SAVE_FINANCIAL_ACCOUNT = 'SAVE_FINANCIAL_ACCOUNT';
export const SAVE_FINANCIAL_ACCOUNT_TYPES = 'SAVE_FINANCIAL_ACCOUNT_TYPES';
export const SAVE_ALL_FINANCIAL_ACCOUNT_TYPES = 'SAVE_ALL_FINANCIAL_ACCOUNT_TYPES';
export const SAVE_VENDORS = "SAVE_VENDORS";
export const SAVE_REVENUE_ACCOUNTS = 'SAVE_REVENUE_ACCOUNTS';
export const SAVE_BILLS = "SAVE_BILL";
export const SAVE_CERTAIN_BILL = 'SAVE_CERTAIN_BILL';
export const SAVE_JOURNAL_ENTRIES = 'SAVE_JOURNAL_ENTRIES';
export const SAVE_CERTAIN_JOURNAL = 'SAVE_CERTAIN_JOURNAL';
