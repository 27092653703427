import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import { useState } from 'react'
import { isUsingMobile } from '../../../helpers/utils'
import { IInvoice } from '../../../types/types'
import useAuthorization from '../../../helpers/useAuthorization'

interface InvoiceModalHeaderButtonsProps {
  actions: any
  invoice: IInvoice
}

const InvoiceModalHeaderButtons = ({ actions, invoice }: InvoiceModalHeaderButtonsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isMoreActionsMenuOpen = Boolean(anchorEl)
  const isMobile = isUsingMobile()
  const { isAuthorized } = useAuthorization()

  const {
    onPrintClick,
    onExportAsPDFClick,
    onEditClick,
    onDuplicateClick,
    onRecordPaymentClick,
    onShareLinkClick,
    onDeleteClick,
  } = actions

  const handleMoreActionsMenuClose = () => {
    setAnchorEl(null)
  }

  const handleEditClick = () => {
    handleMoreActionsMenuClose()
    onEditClick && onEditClick()
  }

  const handleDuplicateClick = () => {
    handleMoreActionsMenuClose()
    onDuplicateClick && onDuplicateClick()
  }

  const handleRecordPaymentClick = () => {
    handleMoreActionsMenuClose()
    onRecordPaymentClick && onRecordPaymentClick()
  }

  const handleShareLinkClick = () => {
    handleMoreActionsMenuClose()
    onShareLinkClick && onShareLinkClick()
  }

  const handleDeleteClick = () => {
    handleMoreActionsMenuClose()
    onDeleteClick && onDeleteClick()
  }

  const handleExportAsPDFClick = () => {
    handleMoreActionsMenuClose()
    onExportAsPDFClick && onExportAsPDFClick()
  }

  const handlePrintClick = () => {
    handleMoreActionsMenuClose()
    onPrintClick && onPrintClick()
  }

  return (
    <>
      {!isMobile && (
        <Tooltip title='Print'>
          <IconButton
            aria-label='print'
            onClick={onPrintClick}
            sx={{
              color: 'var(--primary1-color)',
              background: 'var(--secondary1-color)',
            }}
          >
            <LocalPrintshopOutlinedIcon />
          </IconButton>
        </Tooltip>
      )}
      {!isMobile && (
        <Tooltip title='Export as PDF'>
          <IconButton
            aria-label='export as pdf'
            onClick={onExportAsPDFClick}
            sx={{
              color: 'var(--primary1-color)',
              background: 'var(--secondary1-color)',
            }}
          >
            <DownloadOutlinedIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title='More options'>
        <IconButton
          aria-label='more'
          onClick={(event) => setAnchorEl(event.currentTarget)}
          sx={{
            color: 'var(--primary1-color)',
            background: 'var(--secondary1-color)',
          }}
        >
          <MoreVertOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id='more-actions-menu'
        anchorEl={anchorEl}
        open={isMoreActionsMenuOpen}
        onClose={handleMoreActionsMenuClose}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{
          vertical: -12,
          horizontal: 'right',
        }}
        sx={{
          '& .MuiMenuItem-root': {
            padding: { xs: '1rem', md: '1rem 4rem 1rem 2rem' },
          },
          '& .MuiMenuItem-root:hover': {
            backgroundColor: 'var(--secondary1-color)',
            color: 'var(--primary1-color)',
          },
        }}
      >
        {isMobile && <MenuItem onClick={handlePrintClick}>Print</MenuItem>}
        {isMobile && <MenuItem onClick={handleExportAsPDFClick}>Export as PDF</MenuItem>}
        {isAuthorized('write_invoice') && (
          <MenuItem
            onClick={handleEditClick}
            disabled={['PAID', 'DRAFT'].includes(invoice.status || '')}
          >
            Edit
          </MenuItem>
        )}
        {isAuthorized('write_invoice') && (
          <MenuItem onClick={handleDuplicateClick}>Duplicate</MenuItem>
        )}
        {isAuthorized('write_invoice') && (
          <MenuItem
            onClick={handleRecordPaymentClick}
            disabled={['PAID', 'DRAFT'].includes(invoice.status || '')}
          >
            Record payment
          </MenuItem>
        )}
        <MenuItem
          onClick={handleShareLinkClick}
          disabled={['PAID', 'DRAFT'].includes(invoice.status || '')}
        >
          Share link
        </MenuItem>
        {isAuthorized('write_invoice') && (
          <MenuItem onClick={handleDeleteClick} sx={{ color: 'red' }}>
            Delete
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

export default InvoiceModalHeaderButtons
