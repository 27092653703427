import DownloadIcon from '@mui/icons-material/GetAppOutlined'
import { Tooltip } from '@mui/material'

interface DownloadButtonProps {
  title: string
  onDownloadClick: () => void
}

const DownloadButton = ({ title, onDownloadClick }: DownloadButtonProps) => {
  return (
    <div className='icon'>
      <Tooltip title={title} arrow>
        <DownloadIcon
          style={{
            backgroundColor: '#E5EBFF',
            color: '#0039FF',
            borderRadius: '50%',
          }}
          onClick={onDownloadClick}
        />
      </Tooltip>
    </div>
  )
}

export default DownloadButton
