import { Dispatch } from 'redux'
import api from '../../api/api'
import { errorHandler } from '../../helpers/errorHandler'
import { Action } from '../actions/actionTypes'
import moment from 'moment'
import {
  SAVE_AGED_DETAILED,
  SAVE_AGED_GENERAL,
  SAVE_CUSTOMERS_REPORTS_DETAILED,
  SAVE_CUSTOMERS_REPORTS_GENERAL,
  SAVE_PROFIT_LOSS_REPORTS,
  SAVE_BALANCE_SHEET_REPORTS,
  SAVE_ACCOUNT_TRANSACTION,
  SAVE_SETTLED_DEPOSIT_REPORTS,
} from './report.action.type'

export const saveAgedReportsGeneral = (tillDate: string) => (dispatch: Dispatch) => {
  api
    .getAgedReportGeneral(tillDate)
    .then((res) => {
      dispatch({
        type: SAVE_AGED_GENERAL,
        payload: res.data.body,
      })
    })
    .catch((err) => errorHandler(err))
}

export const saveAgedReportsDetailed =
  (tillDate: string, page = 0) =>
  (dispatch: Dispatch) => {
    api
      .getDetailedAgedReport(tillDate, page)
      .then((res) => {
        dispatch({
          type: SAVE_AGED_DETAILED,
          payload: res.data.body.content,
          size: res.data.body.totalElements,
        })
      })
      .catch((err) => errorHandler(err))
  }

export const saveCustomersReportGeneral =
  (fromDate = '', toDate = '') =>
  (dispatch: Dispatch) => {
    api
      .getCustomersGeneralReport(
        fromDate ? moment(fromDate).format('YYYY-MM-DD') : '',
        toDate ? moment(toDate).format('YYYY-MM-DD') : '',
      )
      .then((res) => {
        dispatch({
          type: SAVE_CUSTOMERS_REPORTS_GENERAL,
          payload: res.data.body,
        })
      })
      .catch((err) => errorHandler(err))
  }

export const saveCustomersReportDetailed =
  (fromDate = '', toDate = '', page = 0) =>
  (dispatch: Dispatch) => {
    api
      .getCustomersDetailesReport(
        fromDate ? moment(fromDate).format('YYYY-MM-DD') : '',
        toDate ? moment(toDate).format('YYYY-MM-DD') : '',
        page,
      )
      .then((res) => {
        dispatch({
          type: SAVE_CUSTOMERS_REPORTS_DETAILED,
          payload: res.data.body.content,
          size: res.data.body.totalElements,
        })
      })
      .catch((err) => errorHandler(err))
  }

export const saveSettledDeposit =
  (page = 0) =>
  (dispatch: Dispatch<Action>) => {
    api
      .getSettledDepositReport(page)
      .then((res) => {
        dispatch({
          type: SAVE_SETTLED_DEPOSIT_REPORTS,
          payload: res.data.body.content,
          size: res.data.body.totalElements,
          totalPaid: res.data.body.totalPaid,
          totalRefunded: res.data.body.totalRefunded,
          totalFee: res.data.body.totalFee,
          totalNetDeposit: res.data.body.totalNetDeposit,
        })
      })
      .catch((err) => errorHandler(err))
  }

export const saveProfitAndLoss =
  (from = '', to = '') =>
  (dispatch: Dispatch<Action>) => {
    api
      .getProfitAndLossReport(from, to)
      .then((res) => {
        dispatch({
          type: SAVE_PROFIT_LOSS_REPORTS,
          payload: res.data.body,
        })
      })
      .catch((err) => errorHandler(err))
  }

  export const saveBalanceSheet =
  (from = '', to = '') =>
  (dispatch: Dispatch<Action>) => {
    api
      .getBalanceSheetReport(from, to)
      .then((res) => {
        dispatch({
          type: SAVE_BALANCE_SHEET_REPORTS,
          payload: res.data.body,
        })
      })
      .catch((err) => errorHandler(err))
  }
          
  export const saveAccountTransaction =
  (page = 0, size = "10", from = "", to = "", financialAccountPublicId = "", isAccountPayable = "", sort= "") =>
  (dispatch: Dispatch<Action>) => {
    api
      .getAccountTransaction(page, size, from, to, financialAccountPublicId, isAccountPayable, sort)
      .then((res) => {
        dispatch({
          type: SAVE_ACCOUNT_TRANSACTION,
          payload: res.data.body.content,
        })
      })
      .catch((err) => errorHandler(err))
  }

