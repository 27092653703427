import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import HeaderCreate from '../../../components/header/headerCreate'
import {
  saveBills,
  saveCertainBill,
  savePurchaseProducts,
  saveVendors,
} from '../../../redux/actions/home.actions'
import { useDispatch, useSelector } from 'react-redux'
import { INewBill } from '../../../types/types'
import moment from 'moment'
import FormHeader from './header'
import FormItems from './items'
import { Box, Button, Card, CardContent, Grid } from '@mui/material'
import api from '../../../api/api'
import { successToastNotification } from '../../../helpers/toastNotification'
import { AppState } from '../../../redux/store'
import { isUsingMobile } from '../../../helpers/utils'

const BillForm = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { publicId } = useParams()
  const chosenBill = useSelector((state: AppState) => state.homeReducer.chosenBill)
  const isMobile = isUsingMobile()
  const isAddMode = location.pathname.endsWith('/add');

  const [newBill, setNewBill] = useState<INewBill>({
    vendorPublicId: null,
    name: '',
    poso: '',
    memo: ' ',
    attachment: undefined,
    date: moment().format('YYYY-MM-DD HH:mm'),
    dueDate: moment().format('YYYY-MM-DD HH:mm'),
    items: [
      {
        purchaseProductPublicId: null,
        quantity: 1,
        price: 0,
        amount: 0,
        description: '',
      },
    ],
    subTotal: 0,
    total: 0,
  })

  const handleSaveClick = () => {
    const formData = new FormData();
    newBill.vendorPublicId && formData.append('vendorPublicId', newBill.vendorPublicId);
    formData.append('name', newBill.name);
    formData.append('poso', newBill.poso);
    formData.append('memo', newBill.memo);
    formData.append('date', newBill.date);
    formData.append('dueDate', newBill.dueDate);
    formData.append('subTotal', newBill.subTotal.toString());
    formData.append('total', newBill.total.toString());
  
    newBill.items.forEach((item, index) => {
      formData.append(`items[${index}].purchaseProductPublicId`, item.purchaseProductPublicId || '');
      formData.append(`items[${index}].quantity`, item.quantity.toString());
      formData.append(`items[${index}].price`, item.price.toString());
      formData.append(`items[${index}].amount`, item.amount.toString());
      formData.append(`items[${index}].description`, item.description);
    });
    if (newBill.attachment) {
      formData.append('attachment', newBill.attachment);
    }
  
    if (publicId) {
      api.editBill(publicId, formData).then(() => {
        successToastNotification(`Bill ${newBill.name} was successfully updated`);
        saveBills();
        navigate('/bills');
      }).catch(error => {
        console.error('Error updating bill:', error);
      });
    } else {
      api.createBill(formData).then(() => {
        successToastNotification(`Bill was successfully created`);
        saveBills();
        navigate('/bills');
      }).catch(error => {
        console.error('Error creating bill:', error);
      });
    }
  };
  

  const isDisabledSaveButton = useMemo(() => {
    const isVendorSelected = !!newBill.vendorPublicId
    const hasItems = newBill.items.length > 0
    const hasCompletedItems = newBill.items.every(
      (item) => item.amount > 0 && item.purchaseProductPublicId,
    )

    return !isVendorSelected || !hasItems || !hasCompletedItems
  }, [newBill])

  useEffect(() => {
    dispatch(savePurchaseProducts())
    dispatch(saveVendors())
  }, [])

  useEffect(() => {
    if (publicId) {
      dispatch(saveCertainBill(publicId))
    }
  }, [publicId])

  const clearForm = () => {
    setNewBill({
      vendorPublicId: null,
      name: '',
      poso: '',
      memo: ' ',
      attachment: undefined,
      date: moment().format('YYYY-MM-DD HH:mm'),
      dueDate: moment().format('YYYY-MM-DD HH:mm'),
      items: [
        {
          purchaseProductPublicId: null,
          quantity: 1,
          price: 0,
          amount: 0,
          description: '',
        },
      ],
      subTotal: 0,
      total: 0,
    });
  };

  useEffect(() => {
    if (!chosenBill) {
      return;
    }
  
    if (isAddMode) {
      clearForm();
    } else {
      setNewBill({
        vendorPublicId: chosenBill.vendor.publicId,
        name: chosenBill.name,
        poso: chosenBill.poso,
        memo: chosenBill.memo,
        attachment: chosenBill.attachment,
        date: moment(chosenBill.date).format('YYYY-MM-DD HH:mm'),
        dueDate: moment(chosenBill.dueDate).format('YYYY-MM-DD HH:mm'),
        items: chosenBill.items.map((item) => {
          return {
            purchaseProductPublicId: item.product.publicId,
            quantity: item.quantity,
            price: item.price,
            amount: item.amount,
            description: item.description,
          }
        }),
        subTotal: chosenBill?.subTotal,
        total: chosenBill.total,
      });
    }
  }, [chosenBill, isAddMode]);

  const handleChangeFields = (e: any, value: string, isDate?: boolean) => {
    setNewBill({
      ...newBill,
      [value]: isDate ? moment(e).format('YYYY-MM-DD HH:mm') : e.target.value,
    })
  }
  
  return (
    <>
      <HeaderCreate
        title={!publicId ? 'Create bill' : 'Edit bill'}
        saveTextButton='Save'
        cancelTextButton='Cancel'
        handleCancel={() => navigate(`/bills`)}
        handleAcceptClick={handleSaveClick}
        isDisabledSaveButton={isDisabledSaveButton}
      />
      {isMobile ? (
        <>
          <Card
            sx={{
              my: 2,
              '&.MuiPaper-root': { boxShadow: '0 5px 10px 0 var(--secondary-grey-bg)' },
              '& .MuiInputBase-root fieldset': { borderColor: 'var(--secondary-grey)' },
            }}
          >
            <CardContent>
              <FormHeader bill={newBill} onChangeField={handleChangeFields} />
            </CardContent>
          </Card>
          <FormItems bill={newBill} setBill={setNewBill} />
          <Grid container>
            <Grid
              container
              justifyContent={{ xs: 'normal', md: 'right' }}
              alignItems={{ xs: 'normal', md: 'center' }}
              flexDirection={{ xs: 'column', md: 'row' }}
            >
              <Grid item pt='1rem'>
                <Button
                  disabled={isDisabledSaveButton}
                  color='primary'
                  variant='contained'
                  onClick={handleSaveClick}
                  fullWidth
                >
                  Save
                </Button>
              </Grid>
              <Grid item p='1rem 0 0.5rem 0'>
                <Button
                  color='primary'
                  variant='outlined'
                  onClick={() => navigate(`/bills`)}
                  fullWidth
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <Card
          sx={{
            '&.MuiPaper-root': { boxShadow: 'none' },
            '& .MuiInputBase-root fieldset': { borderColor: 'var(--secondary-grey)' },
            mt: 2,
          }}
        >
          <CardContent sx={{ p: 5 }}>
            <FormHeader bill={newBill} onChangeField={handleChangeFields} />
            <Box mt={3}>
              <FormItems bill={newBill} setBill={setNewBill} />
            </Box>
          </CardContent>
        </Card>
      )}
    </>
  )
}

export default BillForm
