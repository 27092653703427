import { Box, Button, Grid, Typography } from '@mui/material';
import { isUsingMobile } from '../../helpers/utils';

interface IHeaderCreateProps {
	title: string;
	saveTextButton?: string;
	cancelTextButton?: string;
	handleCancel?: () => void;
	handleAcceptClick?: () => void;
	isDisabledSaveButton?: boolean;
}

const HeaderCreate = (props: IHeaderCreateProps) => {
	const {
		title,
		saveTextButton,
		cancelTextButton,
		handleAcceptClick,
		handleCancel,
		isDisabledSaveButton,
	} = props;

	const isMobile = isUsingMobile();

	return (
		<Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: "3px" }}>
			<Typography variant="h5" sx={{ fontWeight: 600 }}>
				{title}
			</Typography>
			{!isMobile && (
				<Box sx={{ display: 'flex', fontWeight:600 }}>
					<Grid mr={2}>
						<Button
							sx={{ border: '2px solid', fontWeight:600, ':hover': {borderWidth : '2px'}}}
							color="primary"
							variant="outlined"
							onClick={handleCancel}
							fullWidth
						>
							{cancelTextButton}
						</Button>
					</Grid>
					<Grid>
						<Button
							sx={{ fontWeight:600 }}
							disabled={isDisabledSaveButton}
							color="primary"
							variant="contained"
							onClick={handleAcceptClick}
							fullWidth
						>
							{saveTextButton}
						</Button>
					</Grid>
				</Box>
			)}
		</Box>
	);
};

export default HeaderCreate;
