import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
} from '@mui/material'
import { useState } from 'react'
import { isUsingMobile } from '../../helpers/utils'

import UploadIcon from '@mui/icons-material/UploadOutlined'
import SearchIcon from '@mui/icons-material/Search'
import useAuthorization from '../../helpers/useAuthorization'
import DownloadButton from '../../components/filters/download-button'

interface VendorFiltersProps {
  onSearchClick: any
  onDownloadClick: any
  onExportClick: any
}

const VendorFilters = ({ onSearchClick, onDownloadClick, onExportClick }: VendorFiltersProps) => {
  const isMobile = isUsingMobile()
  const [showFilters, setShowFilters] = useState(false)
  const [selectedType, setSelectedType] = useState('')
  const [search, setSearch] = useState('')

  const { isAuthorized } = useAuthorization()

  const changeFilter = () => {
    setShowFilters(!showFilters)
  }

  return (
    <>
      {!isMobile && (
        <Card sx={{ '&.MuiPaper-root': { boxShadow: 'none' }, mb: '16px' }}>
          <CardContent sx={{ pb: '16px !important' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormControl>
                <InputLabel id='vendor-type-filter-label' shrink>
                  Type
                </InputLabel>
                <Select
                  sx={{
                    '& legend': { maxWidth: '100%' },
                  }}
                  value={selectedType}
                  placeholder='Type'
                  label='Type'
                  labelId='vendor-type-filter-label'
                  onChange={(e: SelectChangeEvent) => setSelectedType(e.target.value)}
                  style={{ width: '200px', marginRight: '10px' }}
                  displayEmpty
                >
                  <MenuItem disabled value=''>
                    Select type
                  </MenuItem>
                  <MenuItem value=''>All</MenuItem>
                  <MenuItem value='CONTRACTOR_1099_NEC'>1099 NEC Contractor</MenuItem>
                  <MenuItem value='REGULAR'>Regular</MenuItem>
                </Select>
              </FormControl>
              <TextField
                style={{ width: '300px', marginRight: '10px' }}
                value={search}
                placeholder='Search by name or business '
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearch(e.target.value)
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ shrink: true }}
              />

              <Button
                variant='contained'
                size='medium'
                onClick={onSearchClick(search, selectedType)}
                sx={{ px: 4, py: 2 }}
              >
                Search
              </Button>

              <div style={{ display: 'flex', cursor: 'pointer', marginLeft: 'auto' }}>
                <DownloadButton title='Export vendors' onDownloadClick={onDownloadClick} />
                {isAuthorized('write_vendor') && (
                  <div className='icon'>
                    <Tooltip title='Import vendors' arrow>
                      <UploadIcon
                        style={{
                          backgroundColor: '#E5EBFF',
                          color: '#0039FF',
                          borderRadius: '50%',
                        }}
                        onClick={onExportClick}
                      />
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
            <div style={{ display: 'flex', cursor: 'pointer' }}></div>
          </CardContent>
        </Card>
      )}

      {isMobile && (
        <>
          <div
            onClick={changeFilter}
            style={{ color: '#0039FF', fontWeight: '500', marginBottom: '10px' }}
          >
            {showFilters ? 'Hide filters' : 'Show filters'}
          </div>
          {showFilters && (
            <Card sx={{ '&.MuiPaper-root': { boxShadow: 'none' }, mb: '16px' }}>
              <CardContent sx={{ pb: '16px !important' }}>
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <FormControl fullWidth>
                    <InputLabel id='vendor-type-filter-label' shrink>
                      Type
                    </InputLabel>
                    <Select
                      sx={{ '& legend': { maxWidth: '100%' } }}
                      value={selectedType}
                      placeholder='Type'
                      label='Type'
                      labelId='vendor-type-filter-label'
                      onChange={(e: SelectChangeEvent) => setSelectedType(e.target.value)}
                      displayEmpty
                    >
                      <MenuItem disabled value=''>
                        Select type
                      </MenuItem>
                      <MenuItem value=''>All</MenuItem>
                      <MenuItem value='CONTRACTOR_1099_NEC'>1099 NEC Contractor</MenuItem>
                      <MenuItem value='REGULAR'>Regular</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    style={{
                      width: !isMobile ? '300px' : '100%',
                      marginRight: !isMobile ? '10px' : '0',
                      marginBottom: !isMobile ? '0' : '10px',
                    }}
                    value={search}
                    placeholder='Search by name or business'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setSearch(e.target.value)
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{ shrink: true }}
                  />

                  <Button
                    variant='contained'
                    onClick={onSearchClick(search, selectedType)}
                    style={{ marginLeft: !isMobile ? '0px' : 'auto' }}
                  >
                    Search
                  </Button>
                </div>
              </CardContent>
            </Card>
          )}
        </>
      )}
    </>
  )
}

export default VendorFilters
