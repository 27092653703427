import { Box } from '@mui/system'
import Sketch from '@uiw/react-color-sketch'

interface ColorPreferencesProps {
  hexColor: string
  setHexColor: (hexColor: string) => void
}

const ColorPreferences = ({ hexColor, setHexColor }: ColorPreferencesProps) => {
  return (
    <>
      <Box border='1px solid #333' p={0.5} display='inline-block' borderRadius={1}>
        <Box width={30} height={30} bgcolor={hexColor}></Box>
      </Box>
      <Sketch
        color={hexColor}
        disableAlpha={true}
        presetColors={false}
        onChange={(color) => {
          setHexColor(color.hexa)
        }}
      />
    </>
  )
}

export default ColorPreferences
