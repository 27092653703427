import { DataGrid, GridColDef, GridRowSpacingParams } from '@mui/x-data-grid'
import { ICustomer } from '../../types/types'
import { useCallback, useMemo, useState } from 'react'
import { isUsingMobile } from '../../helpers/utils'
import TableStyles from '../../components/table-card/table-styles'
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { formatMoney, formatPhoneNumber } from '../../helpers/formatPrice'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import useAuthorization from '../../helpers/useAuthorization'
import { useSelector } from 'react-redux'
import { AppState } from '../../redux/store'

interface CustomersTableProps {
  customers: ICustomer[]
  onSelectedCustomer: (customer: ICustomer | undefined) => void
  onEditClick?: () => void
  onAddCreditCardClick?: () => void
  onCreateInvoiceClick?: () => void
  onDeleteClick?: () => void
  onRowClick: (customer: ICustomer) => void
  disabledItems?: string[]
  setCustomer: (value: ICustomer) => void
}
const CustomersTable = ({
  customers,
  onSelectedCustomer,
  onEditClick,
  onAddCreditCardClick,
  onCreateInvoiceClick,
  onDeleteClick,
  onRowClick,
  disabledItems = [],
  setCustomer,
}: CustomersTableProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomer | undefined>(undefined)
  const openItemMenu = Boolean(anchorEl)
  const isMobile = isUsingMobile()
  const { isAuthorized } = useAuthorization()
  const company = useSelector((state: AppState) => state.homeReducer.company)

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleMenuButtonClick = useCallback(
    (customer: ICustomer) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      onSelectedCustomer(customer)
      setSelectedCustomer(customer)
      setAnchorEl(event.currentTarget)
    },
    [onSelectedCustomer],
  )

  const handleAddCreditCardClick = () => {
    handleCloseMenu()
    onAddCreditCardClick && onAddCreditCardClick()
    if (selectedCustomer && selectedCustomer !== null) {
      setCustomer(selectedCustomer)
    }
  }

  const handleDeleteClick = () => {
    handleCloseMenu()
    onDeleteClick && onDeleteClick()
  }

  const columns = useMemo<GridColDef<ICustomer>[]>(
    () => [
      {
        field: 'customerName',
        headerName: 'Customer name',
        flex: 2,
        sortable: false,
        renderCell: (param) => (
          <Box>
            <Typography>{param.row.name}</Typography>
            <Typography component='small' variant='subtitle2' sx={{ color: 'var(--gray3-color)' }}>
              {`${param.row.primaryFirstName} ${param.row.primaryLastName}`}
            </Typography>
          </Box>
        ),
      },
      {
        field: 'email',
        headerName: 'Email',
        flex: 2,
        sortable: false,
        renderCell: (param) => param.row.primaryEmail,
      },
      {
        field: 'primaryPhoneNumber',
        headerName: 'Phone number',
        flex: 2,
        sortable: false,
        renderCell: (param) => formatPhoneNumber(param.row.primaryPhoneNumber),
      },
      {
        field: 'address',
        headerName: 'Address',
        flex: 2,
        sortable: false,
        renderCell: (param) => (
          <Box>
            <Typography>{`${param.row.billingPostalCode} ${param.row.billingAddress} ${param.row.billingAddressTwo}`}</Typography>
            <Typography component='small' variant='subtitle2' sx={{ color: 'var(--gray3-color)' }}>
              {`${param.row.billingCity}, ${param.row.billingState}`}
            </Typography>
          </Box>
        ),
      },
      {
        field: 'creditCards',
        headerName: 'Credit Cards',
        flex: 1,
        sortable: false,
        renderCell: (param) => {
          const selectedCard = param.row.creditCardList?.find((card) => card.isDefault)

          if (!selectedCard && param.row.creditCardList && param.row.creditCardList.length > 0) {
            return `${param.row.creditCardList[0].cardType} ${param.row.creditCardList[0].lastDigits}`
          }

          return selectedCard && `${selectedCard.cardType} ${selectedCard.lastDigits}`
        },
      },
      {
        field: 'overdueBalance',
        headerName: 'Overdue Balance',
        flex: 2,
        sortable: false,
        renderCell: (param) => (
          <Box>
            <Typography>{formatMoney(param.row.overdueBalance)}</Typography>
            <Typography component='small' variant='subtitle2' sx={{ color: 'var(--gray3-color)' }}>
              {`Overdue invoices: ${param.row.unpaidInvoices} item${
                param.row.unpaidInvoices && param.row.unpaidInvoices > 1 ? 's' : ''
              }`}
            </Typography>
          </Box>
        ),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1.5,
        sortable: false,
        renderCell: (param) => (
          <IconButton
            aria-label='more'
            id='long-button'
            aria-controls={openItemMenu ? 'long-menu' : undefined}
            aria-expanded={openItemMenu ? 'true' : undefined}
            aria-haspopup='true'
            onClick={handleMenuButtonClick(param.row)}
            sx={{
              justifyItems: 'right',
            }}
          >
            <MoreVertIcon />
          </IconButton>
        ),
      },
    ],
    [openItemMenu, handleMenuButtonClick],
  )

  const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: 5,
    }
  }, [])

  return (
    <>
      <DataGrid
        rows={customers}
        columns={columns}
        disableColumnMenu
        getRowId={(customer) => customer.publicId || ''}
        hideFooter
        getRowSpacing={getRowSpacing}
        rowHeight={65}
        columnHeaderHeight={24}
        initialState={{
          columns: {
            columnVisibilityModel: {
              email: !isMobile,
              primaryPhoneNumber: !isMobile,
              address: !isMobile,
              creditCards: !isMobile,
              actions: isAuthorized('write_customer'),
            },
          },
        }}
        onRowClick={(params) => onRowClick(params.row)}
        sx={TableStyles}
      />
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={openItemMenu}
        onClose={handleCloseMenu}
        sx={{
          '& .MuiMenuItem-root': {
            padding: { xs: '1rem', md: '1rem 4rem 1rem 2rem' },
          },
          '& .MuiMenuItem-root:hover': {
            backgroundColor: 'var(--secondary1-color)',
            color: 'var(--primary1-color)',
          },
        }}
      >
        <MenuItem disabled={disabledItems.includes('edit')} onClick={onEditClick}>
          Edit
        </MenuItem>
        {company?.merchantStatus === 'Onboarded' && (
          <MenuItem onClick={handleAddCreditCardClick}>Add credit card</MenuItem>
        )}
        <MenuItem onClick={onCreateInvoiceClick}>Create invoice</MenuItem>
        <MenuItem sx={{ color: 'var(--error-color)' }} onClick={handleDeleteClick}>
          Delete
        </MenuItem>
      </Menu>
    </>
  )
}

export default CustomersTable
