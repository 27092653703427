import {
  Autocomplete,
  Button,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { ICustomer } from '../../types/types'
import DateField from '../../components/date-field'
import { isUsingMobile } from '../../helpers/utils'

interface CustomerStatementFiltersProps {
  customers: ICustomer[]
  selectedCustomer: ICustomer | null
  setSelectedCustomer: (customer: ICustomer | null) => void
  statementType: string
  setStatementType: (statementType: string) => void
  onCreateStatementClick: () => void
  fromDate: string | null
  toDate: string | null
  setFromDate: (fromDate: string | null) => void
  setToDate: (toDate: string | null) => void
  showOutstanding: boolean
  showActivity: boolean
}

const CustomerStatementFilters = ({
  customers,
  selectedCustomer,
  setSelectedCustomer,
  statementType,
  setStatementType,
  onCreateStatementClick,
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  showOutstanding,
  showActivity,
}: CustomerStatementFiltersProps) => {
  const isMobile = isUsingMobile()

  const isDisabled = () => {
    if (statementType === 'ACTIVITY') {
      return !selectedCustomer || !fromDate || !toDate
    }

    if (statementType === 'OUTSTANDING') {
      return !selectedCustomer
    }
  }

  return (
    <Grid container spacing={1} flexDirection={isMobile ? 'column' : 'row'}>
      <Grid item flexGrow={3}>
        <Autocomplete
          renderInput={(params) => <TextField {...params} label='Customer' />}
          options={customers}
          onChange={(event: any, newValue: ICustomer | null) => {
            setSelectedCustomer(newValue)
          }}
          getOptionKey={(customer) => `${customer.publicId}`}
          getOptionLabel={(customer) => customer.name}
          isOptionEqualToValue={(option, value) => option.publicId === value.publicId}
          fullWidth
        />
      </Grid>
      <Grid item flexGrow={1}>
        <Select
          value={statementType}
          onChange={(e: SelectChangeEvent) => setStatementType(e.target.value)}
          fullWidth
        >
          <MenuItem value='OUTSTANDING'>Outstanding summary</MenuItem>
          <MenuItem value='ACTIVITY'>Account activity</MenuItem>
        </Select>
      </Grid>
      {statementType === 'ACTIVITY' && (
        <Grid item flexGrow={2}>
          <Grid container spacing={1}>
            <Grid item flexGrow={1}>
              <DateField
                label='From'
                fullWidth
                value={fromDate || ''}
                onChange={(date: string | null) => setFromDate(date)}
              />
            </Grid>
            <Grid item flexGrow={1}>
              <DateField
                label='To'
                fullWidth
                value={toDate || ''}
                onChange={(date: string | null) => setToDate(date)}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item flexGrow={1}>
        <Button
          variant='contained'
          fullWidth
          size='medium'
          sx={{ p: 2 }}
          onClick={onCreateStatementClick}
          disabled={isDisabled()}
        >
          {showOutstanding || showActivity ? 'Reload' : 'Create statement'}
        </Button>
      </Grid>
    </Grid>
  )
}

export default CustomerStatementFilters
