import { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import ContentHeader from '../../components/content-header'
import ReportsTabs from './tabs'
import AccountTransactions from './account-transaction/account-transaction'
import { useDispatch, useSelector } from 'react-redux'
import { saveAccountTransaction } from '../../redux/actions/report.actions'
import { AppState } from '../../redux/store'
import moment from 'moment'
import DetailedReportingFilters from './detailed-reporting-filters'

const DetailedReporting = () => {
  const dispatch = useDispatch()

  const accountTransactionData = useSelector(
    (state: AppState) => state.reportReducer.accountTransaction,
  )

  const handleSearchClick = (account: string) => () => {
    dispatch(
      saveAccountTransaction(
        0,
        '',
        moment(filterPeriod.from).format('YYYY-MM-DD'),
        moment(filterPeriod.to).format('YYYY-MM-DD'),
        account
      ),
    )
  }

  const handleDownloadClick = () => {
    console.log("File downloaded")
  }

  // const [selectedTab, setSelectedTab] = useState('PROFIT_LOSS')
  const [selectedTab, setSelectedTab] = useState('ACCOUNT_TRANSACTIONS')
  const [filterPeriod, setFilterPeriod] = useState({
    from: moment().subtract(1, 'month').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  })

  useEffect(() => {
    dispatch(
      saveAccountTransaction(
        0,
        '',
        moment(filterPeriod.from).format('YYYY-MM-DD'),
        moment(filterPeriod.to).format('YYYY-MM-DD'),
      ),
    )
  }, [])

  return (
    <>
      {/* <ContentHeader title='Detailed Reporting' /> */}
      <ContentHeader title='Account Transactions' />

      <Grid container>
        <Grid item>
          {/* <ReportsTabs
            selectedTab={selectedTab}
            onSelectedTabChange={(evt: any, value: any) => setSelectedTab(value)}
          /> */}
        </Grid>
        <DetailedReportingFilters
          onSearchClick={handleSearchClick}
          onDownloadClick={handleDownloadClick}
          setFilterPeriod={setFilterPeriod}
            filterPeriod={filterPeriod}
            performRequest={(from: any, to: any) =>
              dispatch(
                saveAccountTransaction(
                  0,
                  '',
                  moment(from).format('YYYY-MM-DD'),
                  moment(to).format('YYYY-MM-DD'),
                ),
              )
            }
        />
      </Grid>

      {selectedTab === 'ACCOUNT_TRANSACTIONS' && accountTransactionData && (
        <AccountTransactions from={filterPeriod.from} to={filterPeriod.to} />
      )}
    </>
  )
}

export default DetailedReporting
