import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import { useState } from 'react'
import useAuthorization from '../../../helpers/useAuthorization'

interface PaymentModalHeaderButtonsProps {
  actions: any
  showRefund?: boolean
  showResend?: boolean
}

const PaymentModalHeaderButtons = ({
  actions,
  showRefund,
  showResend,
}: PaymentModalHeaderButtonsProps) => {
  const { isAuthorized } = useAuthorization()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isMoreActionsMenuOpen = Boolean(anchorEl)
  const { onViewInvoiceClick, onResendReceiptClick, onRefundClick } = actions

  const handleMoreActionsMenuClose = () => {
    setAnchorEl(null)
  }

  const handleViewInvoiceClick = () => {
    handleMoreActionsMenuClose()
    onViewInvoiceClick && onViewInvoiceClick()
  }

  const handleResendReceiptClick = () => {
    handleMoreActionsMenuClose()
    onResendReceiptClick && onResendReceiptClick()
  }

  const handleRefundClick = () => {
    handleMoreActionsMenuClose()
    onRefundClick && onRefundClick()
  }

  return (
    <>
      <Tooltip title='More options'>
        <IconButton
          aria-label='more'
          onClick={(event) => setAnchorEl(event.currentTarget)}
          sx={{
            color: 'var(--primary1-color)',
            background: 'var(--secondary1-color)',
          }}
        >
          <MoreVertOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id='more-actions-menu'
        anchorEl={anchorEl}
        open={isMoreActionsMenuOpen}
        onClose={handleMoreActionsMenuClose}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{
          vertical: -12,
          horizontal: 'right',
        }}
        sx={{
          '& .MuiMenuItem-root': {
            padding: { xs: '1rem', md: '1rem 4rem 1rem 2rem' },
          },
          '& .MuiMenuItem-root:hover': {
            backgroundColor: 'var(--secondary1-color)',
            color: 'var(--primary1-color)',
          },
        }}
      >
        {showRefund && <MenuItem onClick={handleRefundClick}>Make a refund</MenuItem>}
        {showResend && <MenuItem onClick={handleResendReceiptClick}>Resend Receipt</MenuItem>}
        {isAuthorized('read_invoice') && (
          <MenuItem onClick={handleViewInvoiceClick}>View invoice</MenuItem>
        )}
      </Menu>
    </>
  )
}

export default PaymentModalHeaderButtons
