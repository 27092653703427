import { Action } from '../actions/actionTypes';
import { SAVE_ROLES, GET_PERMISSIONS } from '../actions/settings.action.type';

interface IInitialState {
	roles: any;
	permissions: any;
}

const initialState: IInitialState = {
	roles: [],
	permissions: [],
};

const settingsReducer = (state = initialState, action: Action) => {
	switch (action.type) {
		case SAVE_ROLES:
			return {
				...state,
				roles: action.payload,
			};
		case GET_PERMISSIONS:
			return {
				...state,
				permissions: action.payload,
			};
		default:
			return state;
	}
};

export default settingsReducer;
