import { Button, Grid, Stack, Typography, TextField, Tooltip } from '@mui/material'
import { useEffect, useState } from 'react'
import { INewJournalEntryItem, INewJournalEntry } from '../../../types/types'
import { formatMoney } from '../../../helpers/formatPrice'
import JournalFormTable from './table'
import { isUsingMobile } from '../../../helpers/utils'
import JournalFormCards from './cards'
import AddIcon from '@mui/icons-material/Add'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import FileIcon from '@mui/icons-material/InsertPhotoOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'

interface FormItemsProps {
  journal: INewJournalEntry
  setJournal: any
}

const FormItems = ({ journal, setJournal }: FormItemsProps) => {
  const isMobile = isUsingMobile()
  const [debit, setDebit] = useState<number>(0)
  const [credit, setCredit] = useState<number>(0)
  const [total, setTotal] = useState<number>(0)

  useEffect(() => {
    let totalDebit = 0
    let totalCredit = 0

    journal.items.forEach((item) => {
      if (typeof item.debitAmount === 'number') {
        totalDebit += item.debitAmount
      }
      if (typeof item.creditAmount === 'number') {
        totalCredit += item.creditAmount
      }
    })

    const newTotal = totalCredit - totalDebit

    setDebit(totalDebit)
    setCredit(totalCredit)
    setTotal(newTotal)
  }, [journal.items])

  const handleFileChange = (file: any) => {
    setJournal({
      ...journal,
      attachment: file,
    })
  }

  const removeFile = () => {
    setJournal({
      ...journal,
      attachment: '',
    })
  }

  const handleChangeJournalItem = (selectedIndex: number, key: string) => (event: any) => {
    let updatedValue = event.target.value

    if (key === 'debitAmount' || key === 'creditAmount') {
      updatedValue = parseFloat(event.target.value)
      updatedValue = isNaN(updatedValue) ? 0 : updatedValue
    }

    const updatedItems = journal.items.map((item: any, index: number) => {
      if (index === selectedIndex) {
        return { ...item, [key]: updatedValue }
      }

      return item
    })

    setJournal({
      ...journal,
      items: updatedItems,
    })
  }

  const handleDeleteItem = (index: number) => () => {
    setJournal({
      ...journal,
      items: journal.items.filter(
        (item: INewJournalEntryItem, itemIndex: number) => itemIndex !== index,
      ),
    })
  }

  const handleAddItem = () => {
    setJournal({
      ...journal,
      items: [
        ...journal.items,
        {
          accountPublicId: '',
          description: '',
          debitAmount: undefined,
          creditAmount: undefined,
        },
      ],
    })
  }

  const handleClearAllItems = () => {
    setJournal({
      ...journal,
      items: [
        {
          accountPublicId: '',
          description: '',
          debitAmount: undefined,
          creditAmount: undefined,
        },
      ],
    })
  }

  return (
    <>
      {isMobile ? (
        <JournalFormCards
          journal={journal}
          onChangeAccountItem={handleChangeJournalItem}
          onDeleteItem={handleDeleteItem}
        />
      ) : (
        <JournalFormTable
          journal={journal}
          onChangeAccountItem={handleChangeJournalItem}
          onDeleteItem={handleDeleteItem}
        />
      )}

      <Stack flexDirection='row' gap={1} m={isMobile ? '1rem 0' : 2}>
        <Button
          color='primary'
          variant='contained'
          onClick={handleAddItem}
          sx={{ flexGrow: isMobile ? 1 : undefined }}
          startIcon={<AddIcon />}
        >
          Add a line
        </Button>
        <Button
          color='error'
          variant='outlined'
          onClick={handleClearAllItems}
          sx={{ flexGrow: isMobile ? 1 : undefined }}
        >
          Clear all items
        </Button>
      </Stack>

      <hr style={{ border: '1px solid var(--secondary-grey-bg)' }} />

      <Grid container gap={2}>
        <Grid item flexGrow={8}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: isMobile ? '10px' : 0,
            }}
          >
            <TextField
              multiline
              rows={3}
              style={{ marginTop: '10px', padding: '0 16px' }}
              placeholder='Enter some notes'
              value={journal.memo}
              onChange={(event) => setJournal({ ...journal, memo: event.target.value })}
            />
            {!isMobile && (
              <div
                style={{
                  marginTop: '10px',
                  marginLeft: '20px',
                  display: 'flex',
                  alignItems: 'flex-start',
                  width: '100%',
                }}
              >
                <input
                  accept='image/*'
                  style={{ display: 'none' }}
                  id='file-input'
                  multiple
                  type='file'
                  onChange={(e) => {
                    const inputElement = e.target as HTMLInputElement
                    if (inputElement && inputElement.files && inputElement.files.length > 0) {
                      handleFileChange(inputElement.files[0])
                    }
                  }}
                />
                <label htmlFor='file-input'>
                  <div className='custom-button' style={{ height: '46px', width: '46px' }}>
                    <AttachFileIcon />
                  </div>
                </label>
                {journal.attachment && journal.attachment.name && (
                  <div
                    style={{
                      width: '252px',
                      marginLeft: '15px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        background: '#fff',
                        border: '1px solid #DFE3EB',
                        borderRadius: '8px',
                        marginBottom: '10px',
                        padding: '5px',
                        width: '100%',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          marginRight: '15px',
                        }}
                      >
                        <FileIcon />
                      </div>
                      <div style={{ marginRight: '15px', fontSize: '12px', fontWeight: '400' }}>
                        {journal.attachment.name && journal.attachment.name.length > 20 ? (
                          <Tooltip title={journal.attachment.name} arrow>
                            <span>{journal.attachment.name.slice(0, 20)}...</span>
                          </Tooltip>
                        ) : (
                          journal.attachment.name
                        )}
                      </div>
                      <div
                        style={{
                          color: 'red',
                          marginLeft: 'auto',
                          cursor: 'pointer',
                          marginTop: '6px',
                        }}
                        onClick={removeFile}
                      >
                        <DeleteOutlineOutlinedIcon />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </Grid>
        {!isMobile && (
          <Grid item flexGrow={1} style={{ marginTop: '5px' }}>
            <Typography fontSize={16} fontWeight={600} color='var(--secondary-grey)'>
              Total Debits
            </Typography>
            <Typography fontSize={16} fontWeight={600} color='var(--secondary-grey)'>
              Total Credits
            </Typography>
            <Typography
              fontSize={16}
              fontWeight={600}
              color={total >= 0 ? 'var(--green)' : 'var(--error-color)'}
              mt={1}
            >
              DIFFERENCE
            </Typography>
          </Grid>
        )}
        {!isMobile && (
          <Grid item flexGrow={1} style={{ marginTop: '5px' }}>
            <Typography fontSize={16} fontWeight={600} color='var(--secondary-grey)'>
              {formatMoney(debit)}
            </Typography>
            <Typography fontSize={16} fontWeight={600} color='var(--secondary-grey)'>
              {formatMoney(credit)}
            </Typography>
            <Typography
              fontSize={16}
              fontWeight={600}
              color={total >= 0 ? 'var(--green)' : 'var(--error-color)'}
              mt={1}
            >
              {formatMoney(total)}
            </Typography>
          </Grid>
        )}
      </Grid>
      {isMobile && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: total >= 0 ? 'var(--green)' : 'var(--red)',
            padding: '0 10px',
            marginTop: '15px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              height: '30px',
              alignItems: 'center',
              fontWeight: 400,
              font: '14px',
            }}
          >
            <div>
              <Typography fontSize={14} fontWeight={400} color='#ffffff'>
                Total Debits
              </Typography>
            </div>
            <div
              style={{
                marginLeft: 'auto',
              }}
            >
              <Typography fontSize={14} fontWeight={400} color='#ffffff'>
                {formatMoney(debit)}
              </Typography>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              height: '30px',
              alignItems: 'center',
              fontWeight: 400,
              font: '14px',
            }}
          >
            <div>
              <Typography fontSize={14} fontWeight={400} color='#ffffff'>
                Total Credits
              </Typography>
            </div>
            <div
              style={{
                marginLeft: 'auto',
              }}
            >
              <Typography fontSize={14} fontWeight={400} color='#ffffff'>
                {formatMoney(credit)}
              </Typography>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              height: '30px',
              alignItems: 'center',
              fontWeight: 600,
              font: '16px',
            }}
          >
            <div>
              <Typography fontSize={16} fontWeight={600} color='#ffffff'>
                DIFFERENCE
              </Typography>
            </div>
            <div
              style={{
                marginLeft: 'auto',
              }}
            >
              <Typography fontSize={16} fontWeight={600} color='#ffffff'>
                {formatMoney(total)}
              </Typography>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default FormItems
