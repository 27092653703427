import { useState } from 'react'
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import { isUsingMobile } from '../../../helpers/utils'

import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'

const JournalEntryModalHeaderButtons = ({ actions }: { actions: any }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isMoreActionsMenuOpen = Boolean(anchorEl)
  const isMobile = isUsingMobile()

  const { onDeleteClick, onDuplicateClick } = actions

  const handleMoreActionsMenuClose = () => {
    setAnchorEl(null)
  }

  const handleDeleteClick = () => {
    handleMoreActionsMenuClose()
    onDeleteClick && onDeleteClick()
  }

  const handleDuplicateClick = () => {
    handleMoreActionsMenuClose()
    onDuplicateClick && onDuplicateClick()
  }

  return (
    <>
      <Tooltip title='More options'>
        <IconButton
          aria-label='more'
          onClick={(event) => setAnchorEl(event.currentTarget)}
          sx={{
            color: 'var(--primary1-color)',
            background: 'var(--secondary1-color)',
          }}
        >
          <MoreVertOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id='more-actions-menu'
        anchorEl={anchorEl}
        open={isMoreActionsMenuOpen}
        onClose={handleMoreActionsMenuClose}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{
          vertical: -12,
          horizontal: 'right',
        }}
        sx={{
          '& .MuiMenuItem-root': {
            padding: { xs: '1rem', md: '1rem 4rem 1rem 2rem' },
          },
          '& .MuiMenuItem-root:hover': {
            backgroundColor: 'var(--secondary1-color)',
            color: 'var(--primary1-color)',
          },
        }}
      >
        <MenuItem onClick={handleDuplicateClick}>Duplicate</MenuItem>
        <MenuItem onClick={handleDeleteClick} sx={{ color: 'red' }}>
          Delete
        </MenuItem>
      </Menu>
    </>
  )
}

export default JournalEntryModalHeaderButtons
