import { Grid } from '@mui/material'
import ActivityCard from './activityCard'
import { thousandPrice } from '../../helpers/formatPrice'

interface CustomerStatsProps {
  stats: any
}

const CustomerStats = ({ stats }: CustomerStatsProps) => {
  return (
    <Grid container spacing={1} mb={2} flexDirection='row'>
      <Grid item xs={6} sm={3}>
        <ActivityCard title='Total overdue' value={`$${thousandPrice(stats?.totalOverdue)}`} />
      </Grid>
      <Grid item xs={6} sm={3}>
        <ActivityCard
          title='Paid last 12 months'
          value={`$${thousandPrice(stats?.overdueToPaidLast12Month)}`}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <ActivityCard
          title='Average overdue time'
          value={`${stats?.averageOverdueDays ? Math.floor(stats?.averageOverdueDays) : 0} ${
            Math.floor(stats?.averageOverdueDays) === 1 ? 'day' : 'days'
          }`}
        />
      </Grid>
    </Grid>
  )
}

export default CustomerStats
