import { useSelector } from 'react-redux'
import { AppState } from '../../../redux/store'
import ActivityInvoices from './activityInvoices'
import ActivityPayments from './activityPayments'
import ActivityRefunds from './activityRefunds'

const ActivityStatementTable = () => {
  const activityStatements = useSelector((state: AppState) => state.homeReducer.activityStatements)

  return (
    <>
      <ActivityInvoices activityStatements={activityStatements} mb={1} />
      <ActivityPayments activityStatements={activityStatements} mb={1} />
      <ActivityRefunds activityStatements={activityStatements} />
    </>
  )
}

export default ActivityStatementTable
