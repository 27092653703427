import { Avatar, Box, FormLabel, IconButton, Input } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { ChangeEventHandler, useRef } from 'react';

interface IUploadPicture {
	accept?: string;
	avatarSrc?: string;
	onChange: ChangeEventHandler<HTMLInputElement>;
}

const UploadPicture = (props: IUploadPicture) => {
	const { accept, avatarSrc, onChange } = props;
	const inputRef = useRef<HTMLInputElement>(null);

	const handleUploadPictureClick = () => {
		inputRef.current?.click();
	};

	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<FormLabel
				sx={{
					overflow: 'visible',
					position: 'relative',
					display: 'inline-flex',
					margin: '0 auto',
				}}
			>
				<Input
					type="file"
					sx={{ display: 'none' }}
					ref={inputRef}
					inputProps={{ accept }}
					onChange={onChange}
				/>
				<IconButton
					color="primary"
					onClick={handleUploadPictureClick}
					aria-label="upload-picture"
					sx={{
						backgroundColor: 'var(--secondary1-color)',
						position: 'absolute',
						right: 0,
						zIndex: 1,
						'&:hover': {
							backgroundColor: 'white',
						},
					}}
				>
					<EditIcon />
				</IconButton>
				<Avatar
					sx={{ width: 100, height: 100, margin: '0.5em auto' }}
					src={avatarSrc}
				/>
			</FormLabel>
		</Box>
	);
};

export default UploadPicture;
