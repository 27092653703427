import { ReactElement } from 'react';
import { isUsingMobile } from '../../helpers/utils'
import './content-header.css';

interface IContentHeader {
	title: any;
	component?: ReactElement;
}

const isMobile = isUsingMobile()

const ContentHeader = (props: IContentHeader) => {
	const { title, component } = props;
	return (
		<div className="content-header__content" style={{marginTop: !isMobile ? "3px" : "15px"}}>
			<div className="content-header__title">{title}</div>
			{component}
		</div>
	);
};

export default ContentHeader;
