import CustomerSection from "../../components/customer/customer-section";
import { ICustomer, IInvoice } from "../../types/types";
import CompanySection from "../customers/company-section";

interface IManageSection {
  customerItems: any;
  currentPublicId: string;
  handleChangeFields: (e: any, value: string, isDate?: boolean) => void;
  setIsNewCustomerDialogOpen: (isNewCustomer: boolean) => void;
  newEstimate?: any;
  newInvoice?: IInvoice;
  chosenCustomer: ICustomer | null;
  publicId: string | undefined;
  handleDeleteLine: any;
  subtotal?: any;
  setIsProductDialogOpen: ((open: boolean) => void | undefined);
  isProductDialogOpen: boolean;
  handleDialogClose: () => void;
  table: any;
  isInvoice?: boolean;
  startDate?: string;
  endDate?: string
}

export const ManageSection = (props: IManageSection) => {
  const {
    customerItems,
    currentPublicId,
    handleChangeFields,
    setIsNewCustomerDialogOpen,
    newEstimate,
    newInvoice,
    chosenCustomer,
    publicId,
    table,
    isInvoice = false,
  } = props;

  return (
    <>
      <CompanySection />
      <div className="estimate-manage__padding">
        <div className="divider estimate-manage__divider"></div>
      </div>
      <CustomerSection
        customerItems={customerItems}
        currentPublicId={currentPublicId}
        handleChangeFields={handleChangeFields}
        setIsNewCustomerDialogOpen={setIsNewCustomerDialogOpen}
        newEstimate={newEstimate}
        newInvoice={newInvoice}
        chosenCustomer={chosenCustomer}
        publicId={publicId}
        isInvoice={isInvoice}
      />
      <div className="estimate-manage__padding">
        <div className="divider estimate-manage__divider"></div>
      </div>
      {table}
    </>
  );
}

export default ManageSection;
