import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRowSpacingParams,
  GridTreeNode,
} from '@mui/x-data-grid'
import { useDispatch, useSelector } from 'react-redux'
import { ISubscriptionData } from '../../types/types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import TableStyles from '../../components/table-card/table-styles'
import '../../components/table-card/table-card.css'
import { Box, Chip, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { formatDate } from '../../helpers/formatPrice'
import { isUsingMobile } from '../../helpers/utils'
import { AppState } from '../../redux/store'
import Loader from '../../components/loader/loader'
import { getAccountSubscription } from '../../redux/actions/home.actions'

interface SubscriptionsTableProps {
  onDeleteClick?: () => void
  onResumeClick?: () => void
  onRowClick: () => void
}

const getColorByStatus = (status: string) => {
  switch (status) {
    case 'Active':
      return 'success'
    case 'Canceled':
      return 'error'
  }
}

const SubscriptionsTable = ({
  onDeleteClick,
  onResumeClick,
  onRowClick,
}: SubscriptionsTableProps) => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [loading, setLoading] = useState(true)

  const subscriptionData = useSelector((state: AppState) => state.authReducer.subscriptionData)

  const openItemMenu = Boolean(anchorEl)
  const isMobile = isUsingMobile()

  const isSubscriptionActive = (subscription: any) => {
    return (
      subscription &&
      subscription.length > 0 &&
      subscription[0].scheduler?.executable !== undefined &&
      subscription[0].scheduler?.executable
    )
  }

  const isActive = isSubscriptionActive(subscriptionData)

  const handleMenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleDeleteClick = () => {
    handleCloseMenu()
    onDeleteClick && onDeleteClick()
  }

  const handleResumeClick = () => {
    handleCloseMenu()
    onResumeClick && onResumeClick()
  }

  const handleView = () => {
    handleCloseMenu()
    onRowClick && onRowClick()
  }

  const baseColumns = useMemo<GridColDef<ISubscriptionData>[]>(
    () => [
      {
        field: 'subscription',
        headerName: ' ',
        flex: !isMobile ? 2 : 8,
        sortable: false,
        renderCell: () => (
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <div style={{ marginRight: '10px' }}>Current Subscription</div>
            <Chip
              sx={{
                '&.MuiChip-colorSuccess': {
                  backgroundColor: 'var(--success-bg-color)',
                  color: 'var(--success-color)',
                },
                '&.MuiChip-colorWarning': {
                  backgroundColor: 'var(--warning-bg-color)',
                  color: 'var(--warning-color)',
                },
                '&.MuiChip-colorError': {
                  backgroundColor: 'var(--error-bg-color)',
                  color: 'var(--error-color)',
                },
              }}
              color={getColorByStatus(isActive ? 'Active' : 'Canceled')}
              label={isActive ? 'Active' : 'Canceled'}
            />
          </div>
        ),
      },
      {
        field: 'date',
        headerName: ' ',
        flex: 2,
        sortable: false,
        renderCell: () => {
          return (
            <Box>
              <Typography>Start date: {formatDate(subscriptionData.firstExecutionDate)}</Typography>
            </Box>
          )
        },
      },
      {
        field: 'other date',
        headerName: ' ',
        flex: 2,
        sortable: false,
        renderCell: () => {
          return (
            <Box>
              {isActive ? (
                <Typography>
                  Next billing date: {formatDate(subscriptionData.firstExecutionDate)}
                </Typography>
              ) : (
                <Typography>End date: {formatDate(subscriptionData.endDate)}</Typography>
              )}
            </Box>
          )
        },
      },
      {
        field: 'actions',
        headerName: ' ',
        flex: !isMobile ? 1 : 2,
        sortable: false,
        renderCell: () => (
          <IconButton
            aria-label='more'
            id='long-button'
            aria-controls={openItemMenu ? 'long-menu' : undefined}
            aria-expanded={openItemMenu ? 'true' : undefined}
            aria-haspopup='true'
            onClick={handleMenuButtonClick}
            sx={{
              justifyItems: 'center',
            }}
          >
            <MoreVertIcon />
          </IconButton>
        ),
      },
    ],
    [handleMenuButtonClick, openItemMenu, isMobile, isActive],
  )

  const columns = useMemo<GridColDef<ISubscriptionData>[]>(() => {
    return isMobile
      ? baseColumns.filter(
          (column) => column.field === 'subscription' || column.field === 'actions',
        )
      : baseColumns
  }, [isMobile, isActive])

  const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: 5,
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        await dispatch(getAccountSubscription())
        setLoading(false)
      } catch (error) {
        console.error('Error fetching Subscription data', error)
        setLoading(false)
      }
    }

    fetchData()
  }, [dispatch])

  return (
    <>
      {!loading ? (
        <>
          <DataGrid
            rows={subscriptionData}
            columns={columns}
            disableColumnMenu
            getRowId={(subscriptionData) => subscriptionData.publicId || ''}
            onRowClick={() => handleView()}
            hideFooter
            getRowSpacing={getRowSpacing}
            rowHeight={65}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  name: !isMobile,
                  createdAt: !isMobile,
                  customerEmail: !isMobile,
                },
              },
            }}
            getCellClassName={(
              params: GridCellParams<any, ISubscriptionData, ISubscriptionData, GridTreeNode>,
            ) => {
              return params.field !== 'actions' ? 'rowStyle' : ''
            }}
            sx={TableStyles}
          />
          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={openItemMenu}
            onClose={handleCloseMenu}
            sx={{
              '& .MuiMenuItem-root': {
                padding: { xs: '1rem', md: '1rem 4rem 1rem 2rem' },
              },
              '& .MuiMenuItem-root:hover': {
                backgroundColor: 'var(--secondary1-color)',
                color: 'var(--primary1-color)',
              },
            }}
          >
            <MenuItem
              sx={{ color: isActive ? 'var(--error-color)' : 'var(--success-color)' }}
              onClick={isActive ? handleDeleteClick : handleResumeClick}
            >
              {isActive ? 'Cancel Subscription' : 'Resume Subscription'}
            </MenuItem>
          </Menu>
        </>
      ) : (
        <Loader />
      )}
    </>
  )
}

export default SubscriptionsTable
