import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from '@mui/material'
import BaseModal from '../../../components/modal/modal'
import { IPayment } from '../../../types/types'
import PaymentModalHeaderButtons from './headerButtons'
import { formatExpirationDate } from '../../../helpers/formatPrice'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useState } from 'react'
import PaymentCard from './paymentCard'
import RefundCard from './refundCard'

interface IPaymentModalProps {
  payment: IPayment
  showModal: boolean
  setShowModal: (showModal: boolean) => void
  onViewInvoiceClick?: () => void
  onResendReceiptClick?: () => void
  onRefundClick?: () => void
}

const PaymentModal = ({
  payment,
  showModal,
  setShowModal,
  onViewInvoiceClick,
  onResendReceiptClick,
  onRefundClick,
}: IPaymentModalProps) => {
  const handleCloseModal = () => {
    setShowModal(false)
  }

  const [bankInformationExpanded, setBankInformationExpanded] = useState(true)
  const [isRefund, setIsRefund] = useState(false)

  const handleRefundClick = () => {
    setIsRefund(true)
  }

  const title = `${payment.status === 'REFUNDED' ? 'Refund' : 'Payment'} ${
    payment.status === 'FAILED' ? 'failed' : 'successful'
  } for invoice ${payment.invoice.name}`

  return (
    <BaseModal
      title={isRefund ? 'Make a refund' : title}
      isModalOpen={showModal}
      size='large'
      headerButtons={
        !isRefund ? (
          <PaymentModalHeaderButtons
            showRefund={
              payment?.method === 'CREDIT_CARD' &&
              payment?.status !== 'FAILED' &&
              payment?.status !== 'REFUNDED'
            }
            showResend={
              payment?.method === 'CREDIT_CARD' &&
              payment?.status !== 'FAILED' &&
              payment?.status !== 'REFUNDED'
            }
            actions={{
              onViewInvoiceClick,
              onResendReceiptClick,
              onRefundClick: handleRefundClick,
            }}
          />
        ) : (
          <></>
        )
      }
      body={
        <>
          {isRefund ? <RefundCard payment={payment} /> : <PaymentCard payment={payment} />}
          <Box m={2}>
            <Accordion
              expanded={bankInformationExpanded}
              onChange={(evt, value) => setBankInformationExpanded(value)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Typography fontWeight='bold' fontSize={'1.1rem'}>
                  Client banking information
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid item flexGrow={2} mb={2}>
                    <Typography fontWeight='bold'>Customer name</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>{payment.customerName}</Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item flexGrow={2} mb={2}>
                    <Typography fontWeight='bold'>Customer email</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>{payment.customerEmail}</Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item flexGrow={2}>
                    <Typography fontWeight='bold'>Payment Method</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      {payment.method === 'CREDIT_CARD' &&
                        `${payment.cardType} **** **** **** ${
                          payment.lastDigits
                        } Exp. date ${formatExpirationDate(payment.expirationDate)}`}
                      {payment.method === 'CASH' && 'Cash'}
                      {payment.method === 'OTHER' && 'Other'}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        </>
      }
      footer={
        isRefund ? (
          <Grid
            container
            justifyContent={{ xs: 'normal', md: 'right' }}
            alignItems={{ xs: 'normal', md: 'center' }}
            flexDirection={{ xs: 'column-reverse', md: 'row' }}
          >
            <Grid item padding={{ xs: '1rem', md: '0rem' }}>
              <Button
                color='primary'
                variant='outlined'
                onClick={() => setIsRefund(false)}
                fullWidth
              >
                Cancel
              </Button>
            </Grid>
            <Grid item padding={{ xs: '1rem 1rem 0 1rem', md: '1rem' }}>
              <Button color='primary' variant='contained' onClick={onRefundClick} fullWidth>
                Submit refund
              </Button>
            </Grid>
          </Grid>
        ) : (
          <></>
        )
      }
      handleCloseModal={handleCloseModal}
    />
  )
}

export default PaymentModal
