import { Chip } from '@mui/material';
import { IPermission, IRole } from '../../../types/types';
import CheckIcon from '@mui/icons-material/Check';
import { MouseEventHandler } from 'react';

interface PermissionChipProps {
	permission: IPermission;
	role: IRole;
	onClick?: (permission: IPermission) => MouseEventHandler<HTMLElement>;
}

const PermissionChip = ({ role, permission, onClick }: PermissionChipProps) => {
	return (
		<Chip
			key={permission?.publicId}
			label={permission?.description}
			sx={
				role?.permissions?.find((p) => p.publicId === permission.publicId)
					? {
							padding: '0',
							'&.MuiChip-colorSuccess': {
								backgroundColor: 'var(--success-bg-color)',
							},
							'&.MuiChip-clickable.MuiChip-colorSuccess:hover': {
                backgroundColor: 'var(--secondary-grey)',
                color: '#fff',
              },
					  }
					: { padding: '0 11px 0 10px' }
			}
			variant={
				role?.permissions?.find((p) => p.publicId === permission.publicId)
					? 'filled'
					: 'outlined'
			}
			color={
				role?.permissions?.find((p) => p.publicId === permission.publicId)
					? 'success'
					: 'default'
			}
			icon={
				role?.permissions?.find((p) => p.publicId === permission.publicId) ? (
					<CheckIcon
						sx={{
							position: { xs: 'absolute', sm: 'static' },
							left: { xs: '0rem', sm: 'auto' },
						}}
					/>
				) : undefined
			}
			onClick={onClick && onClick(permission)}
		/>
	);
};

export default PermissionChip;
