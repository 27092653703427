import { Tab, Tabs } from '@mui/material'
import { isUsingMobile } from '../../helpers/utils'
import { SyntheticEvent } from 'react'

interface ReportsTabsProps {
  selectedTab: string
  onSelectedTabChange: (event: SyntheticEvent, value: string) => void
}

const ReportsTabs = ({ selectedTab, onSelectedTabChange }: ReportsTabsProps) => {
  const isMobile = isUsingMobile()
  return (
    <Tabs
      aria-label='report types'
      onChange={onSelectedTabChange}
      sx={{
        backgroundColor: '#fff',
        borderRadius: '0.5rem',
        display: 'inline-flex',
        mb: 2,
        maxWidth: '100%',
        overflowX: 'auto',
      }}
      scrollButtons='auto'
      variant={isMobile ? 'fullWidth' : 'scrollable'}
      value={selectedTab}
      allowScrollButtonsMobile
    >
      <Tab value='AGED_RECEIVABLES' label='Aged receivables' />
      <Tab value='MERCHANT_FEES' label='Estimated merchant fees' />
      <Tab value='CUSTOMERS_REVENUE' label='Revenue by customer' />
    </Tabs>
  )
}

export default ReportsTabs
