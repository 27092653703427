import { Button, IconButton, Stack } from '@mui/material'
import { useState } from 'react'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import ReportFiltersModal from '../../components/report-filters/modal'

interface ReportsFiltersProps {
  onUpdateClick: () => void
  performRequest: (from: string, to: string) => void
  setFilterPeriod: (filterPeriod: any) => void
  filterPeriod: any
}

const ReportsFilters = ({
  onUpdateClick,
  performRequest,
  setFilterPeriod,
  filterPeriod,
}: ReportsFiltersProps) => {
  const [isModalOpen, setIsFiltersModalOpen] = useState<boolean>(false)

  return (
    <>
      <Stack direction='row' spacing={2}>
        <Button size='large' variant='contained' onClick={onUpdateClick}>
          Update report
        </Button>
        <IconButton
          style={{ marginLeft: 'auto' }}
          aria-label='download'
          onClick={() => setIsFiltersModalOpen(true)}
          color='primary'
          size='large'
          sx={{ display: 'flex' }}
        >
          <FilterAltOutlinedIcon />
        </IconButton>
      </Stack>
      <ReportFiltersModal
        fromDate={filterPeriod.from}
        toDate={filterPeriod.to}
        isModalOpen={isModalOpen}
        onClose={() => setIsFiltersModalOpen(false)}
        onConfirm={(from, to) => {
          setFilterPeriod(() => {
            return { from, to }
          })
          performRequest(from, to)
        }}
      />
    </>
  )
}

export default ReportsFilters
