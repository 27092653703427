import { Card, CardContent, Typography } from '@mui/material';
import { IEstimate } from '../../../types/types';
import { formatMoney } from '../../../helpers/formatPrice';

interface IEstimatePreviewTableProps {
	estimate: IEstimate;
}

const EstimatePreviewDetailCards = ({
	estimate,
}: IEstimatePreviewTableProps) => {
	return (
		<>
			{estimate.estimateToProductList.map((item) => {
				return (
					<Card
						key={item.publicId}
						sx={{
							color: 'var(--secondary-grey)',
							mb: 1,
							'& .MuiTypography-root': { fontSize: '14px' },
						}}
					>
						<CardContent>
							<Typography>Item</Typography>
							<Typography color="var(--grey-fonts)" mb={1}>
								{item.product.name}
							</Typography>
							<Typography>Description</Typography>
							<Typography color="var(--grey-fonts)" mb={1}>
								{item.description}
							</Typography>
							<Typography>Quantity</Typography>
							<Typography color="var(--grey-fonts)" mb={1}>
								{item.quantity}
							</Typography>
							<Typography>Price</Typography>
							<Typography color="var(--grey-fonts)" mb={1}>
								{formatMoney(item.price)}
							</Typography>
							<Typography>Amount</Typography>
							<Typography color="var(--grey-fonts)">
								{formatMoney(item.amount)}
							</Typography>
						</CardContent>
					</Card>
				);
			})}
		</>
	);
};

export default EstimatePreviewDetailCards;
