import { useSelector } from 'react-redux'
import { AppState } from '../../redux/store'
import { formatShortenDate } from '../../helpers/formatDate'
import useWindowDimensions from '../../helpers/useWindowDimentions'
import { getCdnUrl } from '../../helpers/utils'
import PreviewDetails from './details'
import { formatMoney } from '../../helpers/formatPrice'

interface IPreviewNew {
  item: any
  customer?: any
  preview?: any
}

const PreviewNew = (props: IPreviewNew) => {
  const { item, customer, preview } = props
  const { width } = useWindowDimensions()
  const companyDetails = useSelector((state: AppState) => state.homeReducer.company)

  const accentColor = companyDetails?.accentColor ? companyDetails?.accentColor : '#0039ff'

  const isEstimate = !!item?.estimateToProductList

  const getPreviewWidth = () => {
    if (width && width < 1400) {
      return 700
    } else if (width && width < 1600 && width > 1400) {
      return 750
    } else if (width && width < 1800 && width > 1600) {
      return 900
    } else if (width && width < 2000 && width > 1800) {
      return 1050
    } else {
      return 1250
    }
  }

  return (
    <div ref={preview}>
      <div
        style={{
          width: getPreviewWidth(),
          fontFamily: 'Open Sans, sans-serif',
          padding: '45px',
          boxSizing: 'border-box',
          borderRadius: 5,
          backgroundColor: '#f7f8fa',
        }}
      >
        <div
          style={{
            fontWeight: 400,
            fontSize: 20,
            padding: '0 0 0 30px',
            lineHeight: 2,
            display: 'flex',
          }}
        >
          <div style={{ flexGrow: 1 }}>
            {companyDetails?.templateLogoFileName ? (
              <img src={getCdnUrl(companyDetails?.templateLogoFileName)} width='300px' />
            ) : (
              companyDetails?.name
            )}
          </div>
          <div style={{ flexGrow: 1, textAlign: 'right', fontSize: 11 }}>
            <p style={{ margin: 0, color: accentColor }}>{companyDetails?.website}</p>
            <p style={{ margin: 0, color: accentColor }}>{companyDetails?.ownerEmail}</p>
            <p style={{ margin: 0, color: accentColor }}>{companyDetails?.phone}</p>
            <p style={{ margin: 0, color: accentColor }}>{companyDetails?.addressOne}</p>
            <p style={{ margin: 0, color: accentColor }}>
              {companyDetails?.city}, {companyDetails?.state} {companyDetails?.postalCode},{' '}
              {companyDetails?.country}
            </p>
          </div>
        </div>
        <div
          style={{
            backgroundColor: 'white',
            border: 'solid 1px #dfe3eb',
            borderRadius: '8px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between ',
              marginTop: 30,
              padding: '0 30px',
            }}
          >
            <div
              className='previewHeader'
              style={{ color: '#4d5e80', fontSize: '12px', lineHeight: '24px' }}
            >
              <div
                style={{
                  color: accentColor,
                  fontWeight: 600,
                  fontSize: 14,
                }}
              >
                {isEstimate ? 'Estimated to' : 'Bill to'}
              </div>
              <div>{customer?.name || ''}</div>
              <div>{`${customer?.primaryFirstName || ''} ${customer?.primaryLastName || ''}`}</div>
              <div>{customer?.billingAddress || ''}</div>
              <div>{customer?.billingAddressTwo || ''}</div>
              <div>
                {`${customer?.billingCity || ''}, ${customer?.billingState || ''}, ${
                  customer?.billingPostalCode || ''
                } ${customer?.billingCountry || ''}`}
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  textAlign: 'end',
                  marginRight: 25,
                  color: accentColor,
                  fontFamily: '(--inter-font)',
                  fontSize: 12,
                  lineHeight: 2,
                  fontWeight: 600,
                }}
              >
                <div>{isEstimate ? 'Estimate ID' : 'Invoice ID'}</div>
                <div>{isEstimate ? 'Estimate Date' : 'Invoice Date'}</div>
                <div>{isEstimate ? 'Expiration Date' : 'Payment Due'}</div>
              </div>
              <div
                style={{
                  textAlign: 'start',
                  color: '#4d5e80',
                  fontSize: 12,
                  lineHeight: 2,
                }}
              >
                <div>
                  {`${item?.scheduledInvoiceProductList ? 'Auto-generated' : `#${item?.name}`}`}
                </div>
                <div>
                  {item?.scheduledInvoiceProductList
                    ? 'Auto-generated'
                    : formatShortenDate(item?.periodStart)}
                </div>
                <div>
                  {item?.scheduledInvoiceProductList
                    ? 'Auto-generated'
                    : formatShortenDate(item?.periodEnd)}
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: 10 }}>
            {item?.invoiceProductList ||
            item?.estimateToProductList ||
            item?.scheduledInvoiceProductList ? (
              <PreviewDetails
                items={
                  isEstimate
                    ? item?.estimateToProductList
                    : item?.invoiceProductList || item?.scheduledInvoiceProductList
                }
                accentColor={accentColor}
              />
            ) : null}
          </div>
          <div
            className='previewFooter'
            style={{
              marginTop: '48px',
              padding: '0 30px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ flexGrow: 8 }}>
              <>
                <div
                  style={{
                    color: accentColor,
                    fontSize: 12,
                    fontWeight: 600,
                  }}
                >
                  Notes
                </div>
                <div
                  style={{
                    width: 380,
                    height: 60,
                    overflowY: 'auto',
                    flexWrap: 'wrap',
                  }}
                >
                  {item?.memo}
                </div>
              </>
            </div>
            <div
              className='subtotal'
              style={{
                color: accentColor,
                flexGrow: 3,
                fontSize: '14px',
                fontWeight: 600,
                textAlign: 'end',
              }}
            >
              Subtotal
            </div>
            <div style={{ flexGrow: 1, fontSize: 12, lineHeight: 2, textAlign: 'end' }}>
              {formatMoney(item?.total)}
            </div>
          </div>
          <div
            className='previewTotal'
            style={{
              fontSize: '20px',
              fontWeight: 700,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              padding: '10px 20px',
              color: 'white',
              borderRadius: '0 0 8px 8px',
              backgroundColor: accentColor,
            }}
          >
            <div style={{ marginRight: 50 }}>{isEstimate ? 'ESTIMATED TOTAL' : 'TOTAL'}</div>
            <div>{formatMoney(item?.total)}</div>
          </div>
        </div>
        {companyDetails?.showBanners ? (
          <div style={{ display: 'flex' }}>
            <div style={{ width: '50%' }}>
              <img src={getCdnUrl('logo-bc.png')} width='150' />
            </div>
            <div
              style={{
                color: accentColor,
                width: '50%',
                textAlign: 'right',
                padding: '1rem 0',
                fontSize: '11px',
              }}
            >
              <strong>Invoicing to Insight: Your Financial Journey Simplified.</strong>
              <br />
              www.bluecimarron.com
            </div>
          </div>
        ) : (
          <div
            style={{ color: accentColor, textAlign: 'center', padding: '1rem 0', fontSize: '11px' }}
          >
            www.bluecimarron.com
          </div>
        )}
      </div>
    </div>
  )
}

export default PreviewNew
