import { useEffect, useState } from 'react'
import BaseModal from '../../../components/modal/modal'
import { IInvoice, INewCreditCard, INewPayment } from '../../../types/types'
import AutoPaymentForm from './autoPaymentForm'
import PaymentMethodSelect from './select'
import ManualPaymentForm from './manualPaymentForm'
import { AppState } from '../../../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { saveCertainCustomer } from '../../../redux/actions/home.actions'
import { Button, FormControl, Grid, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { isUsingMobile } from '../../../helpers/utils'

interface PaymentModalProps {
  showModal: boolean
  setShowModal: (showModal: boolean) => void
  invoice: IInvoice
  onPaymentConfirm: (newPayment: INewPayment) => () => void
}

const PaymentModal = ({
  showModal,
  invoice,
  setShowModal,
  onPaymentConfirm,
}: PaymentModalProps) => {
  const dispatch = useDispatch()
  const chosenCustomer = useSelector((state: AppState) => state.homeReducer.chosenCustomer)
  const isMobile = isUsingMobile()
  const [newPayment, setNewPayment] = useState<INewPayment>({
    method: 'CREDIT_CARD',
    processing: 'AUTO',
    cardId: '',
    amount: Number(invoice.total) - Number(invoice.amountPaid),
    customerPublicId: invoice?.customerPublicId,
  })
  const [formErrors, setFormErrors] = useState({
    cardId: '',
  })

  useEffect(() => {
    dispatch(saveCertainCustomer(invoice?.customerPublicId))
  }, [invoice])

  useEffect(() => {
    setNewPayment({
      ...newPayment,
      method: newPayment.processing === 'AUTO' ? 'CREDIT_CARD' : 'CASH',
    })
  }, [newPayment.processing])

  const isValidForm = (): boolean => {
    if (newPayment.processing === 'AUTO') {
      if (newPayment.card) {
        return (
          !!newPayment.card.number &&
          !!newPayment.card.expirationDate &&
          !!newPayment.card.cvv &&
          !!newPayment.card.name &&
          !!newPayment.amount
        )
      }

      return newPayment.amount > 0 && !!newPayment.cardId
    }

    return !!newPayment.amount && !!newPayment.method
  }

  return (
    <BaseModal
      title={`Record a payment for Invoice ${invoice.name}`}
      isModalOpen={showModal}
      size='large'
      body={
        <>
          {isMobile ? (
            <FormControl fullWidth>
              <Select
                sx={{ mx: 3 }}
                value={newPayment.processing}
                onChange={(e) => setNewPayment({ ...newPayment, processing: e.target.value })}
              >
                <MenuItem value='AUTO'>Credit Card</MenuItem>
                <MenuItem value='MANUAL'>Manual Payment</MenuItem>
              </Select>
            </FormControl>
          ) : (
            <PaymentMethodSelect
              value={newPayment.processing}
              onChange={(e: React.ChangeEvent, value: string) =>
                setNewPayment({ ...newPayment, processing: value })
              }
            />
          )}
          {newPayment.processing === 'AUTO' && chosenCustomer && (
            <AutoPaymentForm
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              customer={chosenCustomer}
              onChangeSelectedCard={(e) => setNewPayment({ ...newPayment, cardId: e.target.value })}
              payment={newPayment}
              onChangeNewCreditCard={(card: INewCreditCard | undefined) => {
                setNewPayment({ ...newPayment, card, cardId: '' })
              }}
              onChangeAmount={(e) =>
                setNewPayment({ ...newPayment, amount: Number(e.target.value) })
              }
              onChangeAccount={(e: SelectChangeEvent<any>) =>
                setNewPayment({ ...newPayment, financialAccountPublicId: e.target.value })
              }
              onChangeDate={(date: any) =>
                setNewPayment({
                    ...newPayment,
                    date: `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`
                })
            }
            />
          )}
          {newPayment.processing === 'MANUAL' && (
            <ManualPaymentForm
              setPaymentMethod={(method) => () => setNewPayment({ ...newPayment, method })}
              payment={newPayment}
              onChangeAmount={(e) =>
                setNewPayment({ ...newPayment, amount: Number(e.target.value) })
              }
            />
          )}
        </>
      }
      footer={
        <Grid
          container
          justifyContent={{ xs: 'normal', md: 'right' }}
          alignItems={{ xs: 'normal', md: 'center' }}
          flexDirection={{ xs: 'column-reverse', md: 'row' }}
        >
          <Grid item padding={{ xs: '1rem', md: '0rem' }}>
            <Button
              color='primary'
              variant='outlined'
              onClick={() => setShowModal(false)}
              fullWidth
            >
              Cancel
            </Button>
          </Grid>
          <Grid item padding={{ xs: '1rem 1rem 0 1rem', md: '1rem' }}>
            <Button
              color='primary'
              variant='contained'
              fullWidth
              onClick={onPaymentConfirm(newPayment)}
              disabled={!isValidForm()}
            >
              Confirm payment
            </Button>
          </Grid>
        </Grid>
      }
      handleCloseModal={() => setShowModal(false)}
    />
  )
}

export default PaymentModal
