import { Box, Button, Grid, TextField } from '@mui/material'
import { useDispatch } from 'react-redux'
import BaseModal from '../../components/modal/modal'
import { useState } from 'react'
import api from '../../api/api'
import { saveReferrals } from '../../redux/actions/home.actions'
import { successToastNotification } from '../../helpers/toastNotification'

interface IReferralsModalProps {
  showModal: string
  setShowModal: (value: string) => void
}

const CreateReferralsModal = ({ showModal, setShowModal }: IReferralsModalProps) => {
  const handleCloseModal = () => {
    setShowModal('')
  }

  const dispatch = useDispatch()
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    emailAddress: '',
    phoneNumber: '',
    additionalComments: '',
  })
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
  })

  const handleChangeValues = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setInitialValues({
      ...initialValues,
      [value]: e.target.value,
    })

    let regex: RegExp

    switch (value) {
      case 'firstName':
        setErrors((prevErrors) => ({
          ...prevErrors,
          firstName: e.target.value.trim() !== '' ? '' : 'First name cannot be empty',
        }))
        break
      case 'lastName':
        setErrors((prevErrors) => ({
          ...prevErrors,
          lastName: e.target.value.trim() !== '' ? '' : 'Last name cannot be empty',
        }))
        break
      case 'emailAddress':
        regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        setErrors((prevErrors) => ({
          ...prevErrors,
          emailAddress:
            e.target.value.trim() !== '' && regex.test(e.target.value)
              ? ''
              : 'Please enter a valid email address',
        }))
        break
      case 'phoneNumber':
        regex = /^\d+$/
        setErrors((prevErrors) => ({
          ...prevErrors,
          phoneNumber:
            regex.test(e.target.value) && e.target.value.length === 10
              ? ''
              : 'Please enter a valid phone number (10 digits)',
        }))
        break
      default:
        break
    }
  }

  const isFormValid = () => {
    return (
      initialValues.firstName.trim() !== '' &&
      initialValues.lastName.trim() !== '' &&
      initialValues.emailAddress.trim() !== '' &&
      Object.values(errors).every((error) => error === '')
    )
  }

  const handleSaveModal = () => {
    api
      .createReferral(initialValues)
      .then(() => {
        setShowModal('')
        successToastNotification(`Referral created`)
        const fetchData = async () => {
          try {
            await dispatch(saveReferrals())
          } catch (error) {
            console.error('Error fetching Referrals data', error)
          }
        }

        fetchData()
      })
      .catch((error: any) => {
        console.error('Error:', error)
      })
  }

  return (
    <BaseModal
      title='Referral'
      isModalOpen={showModal !== ''}
      size='large'
      body={
        <>
          <Box m={2}>
            <div style={{ marginTop: '40px' }}>
              <TextField
                placeholder='Enter First name'
                style={{ marginBottom: '20px' }}
                required
                type='firstName'
                id='outlined-required'
                label='First Name'
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                value={initialValues.firstName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangeValues(e, 'firstName')
                }}
                error={Boolean(errors.firstName)}
                helperText={errors.firstName}
              />
              <TextField
                placeholder='Enter Last name'
                style={{ marginBottom: '20px' }}
                required
                type='lastName'
                id='outlined-required'
                label='Last Name'
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                value={initialValues.lastName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangeValues(e, 'lastName')
                }}
                error={Boolean(errors.lastName)}
                helperText={errors.lastName}
              />
              <TextField
                placeholder='Enter Email address'
                style={{ marginBottom: '20px' }}
                required
                type='emailAddress'
                id='outlined-required'
                label='Email address'
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                value={initialValues.emailAddress}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangeValues(e, 'emailAddress')
                }}
                error={Boolean(errors.emailAddress)}
                helperText={errors.emailAddress}
              />
              <TextField
                placeholder='Enter Company name'
                style={{ marginBottom: '20px' }}
                type='companyName'
                id='outlined-required'
                label='Company Name'
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                value={initialValues.companyName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangeValues(e, 'companyName')
                }}
              />
              <TextField
                placeholder='Enter Phone number'
                style={{ marginBottom: '20px' }}
                type='phoneNumber'
                label='Phone number'
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                value={initialValues.phoneNumber}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangeValues(e, 'phoneNumber')
                }}
                error={Boolean(errors.phoneNumber)}
                helperText={errors.phoneNumber}
              />
              <TextField
                placeholder='Enter an account description'
                multiline
                rows={4}
                style={{ marginBottom: '20px', height: '60px' }}
                type='additionalComments'
                label='Aditional comments'
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                value={initialValues.additionalComments}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangeValues(e, 'additionalComments')
                }}
              />
            </div>
          </Box>
        </>
      }
      footer={
        <Grid
          container
          justifyContent={{ xs: 'normal', md: 'right' }}
          alignItems={{ xs: 'normal', md: 'center' }}
          flexDirection={{ xs: 'column-reverse', md: 'row' }}
        >
          <Grid item padding={{ xs: '1rem', md: '0rem' }}>
            <Button color='primary' variant='outlined' fullWidth onClick={handleCloseModal}>
              Cancel
            </Button>
          </Grid>
          <Grid item padding={{ xs: '1rem 1rem 0 1rem', md: '1rem' }}>
            <Button
              color='primary'
              variant='contained'
              onClick={handleSaveModal}
              fullWidth
              disabled={!isFormValid()}
            >
              Send
            </Button>
          </Grid>
        </Grid>
      }
      handleCloseModal={handleCloseModal}
    />
  )
}

export default CreateReferralsModal
