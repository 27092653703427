import { useMediaQuery } from '@mui/material'
import { Auth } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// import visibilityOffIcon from '../../assets/icons/visibility-off.png'
// import visibilityIcon from '../../assets/icons/visibility.png'
import Logo from '../../assets/images/blue-cimarron.png'
import changePasswordImage from '../../assets/images/changePassword.png'
import loginImage from '../../assets/images/LoginTemp.png'
import loginImages1 from '../../assets/images/LoginImage1.jpg'
import loginImages2 from '../../assets/images/LoginImage2.jpg'
import loginImages3 from '../../assets/images/LoginImage3.jpg'
import logoWhite from '../../assets/images/logoWhite.png'
import verifyAccountImage from '../../assets/images/verifyAccount.png'
import Login from '../../components/login/login'
import SignUp from '../../components/sign-up/sing-up'
// import RegistrationField from '../../components/registration-field'
import OTP from '../../components/otp/otp'
import ResetEmail from '../../components/reset-email/reset-email'
import ChangePassword from '../../components/change-pass/change-pass'
import { errorHandler, generalErrorHandler } from '../../helpers/errorHandler'
import { saveAccessToken } from '../../redux/actions/auth.actions'
import { changeIsLoading } from '../../redux/actions/home.actions'
import { AppState } from '../../redux/store'
import './sign-in.css'

export interface ILoginValues {
  email: string
  password: string
  newPassword: string
  repeatNewPassword: string
  confirmationCode: string
  resetEmail: string
  otp: string
  resetPassword: string
  confirmPassword: string
}

const SignIn = () => {
  const navigate = useNavigate()
  const companyDetails = useSelector((state: AppState) => state.homeReducer.company)
  const [isVerified, setIsVerified] = useState<boolean>(false)
  const isLoading = useSelector((state: AppState) => state.homeReducer.isLoading)
  const [initialValues, setInitialValues] = useState({
    email: '',
    password: '',
    newPassword: '',
    repeatNewPassword: '',
    confirmationCode: '',
    resetEmail: '',
    otp: '',
    resetPassword: '',
    confirmPassword: '',
  })
  const [formErrors, setFormErrors] = useState({
    name: '',
    email: '',
    password: '',
  })
  const dispatch = useDispatch()
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false)
  // const [showResetPassword, setShowResetPassword] = useState<boolean>(false)
  // const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)
  const [showRepeatNewPassword, setShowRepeatNewPassword] = useState<boolean>(false)
  const [displayingPage, setDisplayingPage] = useState<string>('sign-in')
  const [verifyingUser, setVerifyingUser] = useState<any>()
  const [imageIndex, setImageIndex] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState(false);



  useEffect(() => {
    const interval = setInterval(() => {
      setTimeout(() => {
        setImageIndex((prevIndex) => (prevIndex + 1) % 3);
      }, 1000); 
    }, 5000); 

    return () => clearInterval(interval);
  }, [imageIndex]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const isMobile = useMediaQuery('(max-width:767px)')

  useEffect(() => {
    if (sessionStorage.getItem('token')) {
      navigate('/dashboard')
    }
    dispatch(changeIsLoading(false))
    resetValues()
  }, [])

  useEffect(() => {
    dispatch(changeIsLoading(false))
  }, [displayingPage])

  // const handleClickVisibilityIcon = () => {
  //   setShowPassword(!showPassword)
  // }

  // const handleClickIconResetPass = () => {
  //   setShowResetPassword(!showResetPassword)
  // }

  // const handleClickIconConfirmPass = () => {
  //   setShowConfirmPassword(!showConfirmPassword)
  // }

  const handleChangeValues = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setInitialValues({
      ...initialValues,
      [value]: e.target.value,
    })
  }

  // const handleShowNewPassword = () => {
  //   setShowNewPassword(!showNewPassword)
  // }

  // const handleShowRepeatNewPassword = () => {
  //   setShowRepeatNewPassword(!showRepeatNewPassword)
  // }

  const handleConfirmClick = () => {
    dispatch(changeIsLoading(true))
    confirmCode()
  }

  async function confirmCode() {
    // try {
    //   await Auth.confirmSignUp(initialValues.email, initialValues.confirmationCode)
    //   signIn()
    // } catch (error: any) {
    //   dispatch(changeIsLoading(false))
    //   generalErrorHandler(error.message)
    //   setInitialValues({
    //     ...initialValues,
    //     confirmationCode: '',
    //   })
    // }
  }

  async function resendConfirmationCode() {
    try {
      await Auth.resendSignUp(initialValues.email)
    } catch (err) {
      errorHandler(err)
    }
  }

  const resetValues = () => {
    setInitialValues({
      email: '',
      password: '',
      newPassword: '',
      repeatNewPassword: '',
      confirmationCode: '',
      resetEmail: '',
      otp: '',
      resetPassword: '',
      confirmPassword: '',
    })
  }

  async function signIn() {
    try {
      const user = await Auth.signIn(initialValues.email, initialValues.password)
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setDisplayingPage('password')
        setVerifyingUser(user)
      } else {
        dispatch(saveAccessToken(user.signInUserSession.idToken.jwtToken))
        sessionStorage.setItem('token', user.signInUserSession.idToken.jwtToken)
        navigate('/dashboard', {
          state: {
            login: true,
          },
        })
      }
    } catch (error: any) {
      if (error.name === 'UserNotConfirmedException') {
        resendConfirmationCode()
        setDisplayingPage('confirm')
      }
      toast(error.message, {
        autoClose: 3000,
        hideProgressBar: true,
        className: 'error-toast',
      })
    }
  }

  const handleChangePasswordClick = () => {
    dispatch(changeIsLoading(true))
    Auth.completeNewPassword(verifyingUser, initialValues.confirmPassword)
      .then((user) => {
        dispatch(saveAccessToken(user.signInUserSession.idToken.jwtToken))
        sessionStorage.setItem('token', user.signInUserSession.idToken.jwtToken)
        navigate('/dashboard')
        dispatch(changeIsLoading(true))
      })
      .catch((error) => {
        dispatch(changeIsLoading(true))
        toast(error.message, {
          autoClose: 3000,
          hideProgressBar: true,
          className: 'error-toast',
        })
      })
  }

  const handleSignInClick = () => {
    dispatch(changeIsLoading(true))
    signIn()
  }

  const handleSwitchSingView = () => {
    navigate('/sign-up')
    resetValues()
  }

  // const renderButtonText = (page: string) => {
  //   switch (page) {
  //     case 'sign-in':
  //       return 'Sign in'
  //     case 'password':
  //       return 'Change password and Sign in'
  //     case 'reset-email':
  //       return 'Send reset instructions'
  //     case 'otp':
  //       return 'Reset password'
  //     default:
  //       return 'Confirm'
  //   }
  // }

  // const renderTitle = (page: string) => {
  //   switch (page) {
  //     case 'sign-in':
  //       return 'Nice to see you again'
  //     case 'password':
  //       return 'Change password'
  //     case 'confirm':
  //       return 'Verify your account'
  //     case 'reset-email':
  //       return 'Forgot password?'
  //     case 'otp':
  //       return 'Reset Password'
  //   }
  // }

  // const renderSubtitle = (page: string) => {
  //   switch (page) {
  //     case 'sign-in':
  //       return 'Please, sign in your account'
  //     case 'password':
  //       return 'Please, change your password before Sign In by the invitation'
  //     case 'confirm':
  //       return 'You have not verified your account yet.'
  //     case 'reset-email':
  //       // eslint-disable-next-line max-len
  //       return "Enter your email address and we'll send you instructions on how to reset your password."
  //     case 'otp':
  //       return 'We have sent an verification code to your email'
  //   }
  // }

  const handleResetEmailClick = () => {
    dispatch(changeIsLoading(true))
    Auth.forgotPassword(initialValues.resetEmail)
      .then((res) => {
        dispatch(changeIsLoading(false))
        setDisplayingPage('otp')
      })
      .catch((error) => {
        dispatch(changeIsLoading(false))
        generalErrorHandler(error.message)
      })
  }

  const handleResetPasswordClick = () => {
    dispatch(changeIsLoading(true))
    Auth.forgotPasswordSubmit(
      initialValues.resetEmail,
      initialValues.otp,
      initialValues.resetPassword,
    )
      .then((res) => {
        dispatch(changeIsLoading(false))
        toast.success(`The password was reset successfully`, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        })
        resetValues()
        setDisplayingPage('sign-in')
      })
      .catch((error) => {
        dispatch(changeIsLoading(false))
        generalErrorHandler(error.message)
      })
  }

  const handleButtonClick = () => {
    if (displayingPage === 'sign-in') {
      handleSignInClick()
    }
    if (displayingPage === 'password') {
      handleChangePasswordClick()
    }
    if (displayingPage === 'confirm') {
      handleConfirmClick()
    }
    if (displayingPage === 'reset-email') {
      handleResetEmailClick()
    }
    if (displayingPage === 'otp') {
      handleResetPasswordClick()
    }
  }

  // const handlePressEnter = (e: React.KeyboardEvent<HTMLFormElement>) => {
  //   if (e.key === 'Enter' && !handleDisableButton()) {
  //     e.preventDefault()
  //     handleButtonClick()
  //   }
  // }

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  // const handleDisableButton = () => {
  //   if (isLoading) true
  //   if (displayingPage === 'sign-in') {
  //     return (
  //       !VALID_EMAIL.test(initialValues.email) ||
  //       !initialValues.password ||
  //       initialValues.password.length < 8
  //     )
  //   } else if (displayingPage === 'password') {
  //     return (
  //       !VALID_PASSWORD.test(initialValues.newPassword) ||
  //       !VALID_PASSWORD.test(initialValues.repeatNewPassword) ||
  //       initialValues.newPassword !== initialValues.repeatNewPassword
  //     )
  //   } else if (displayingPage === 'reset-email') {
  //     return !VALID_EMAIL.test(initialValues.resetEmail)
  //   } else if (displayingPage === 'otp') {
  //     return (
  //       !VALID_PASSWORD.test(initialValues.confirmPassword) ||
  //       !VALID_PASSWORD.test(initialValues.resetPassword) ||
  //       initialValues.resetPassword !== initialValues.confirmPassword ||
  //       initialValues.otp.length !== 6
  //     )
  //   } else {
  //     return !initialValues.confirmationCode
  //   }
  // }

  const renderLogInScreens = () => {
    switch (displayingPage) {
      case 'sign-in':
        //formErrors, initialValues, setFormErrors, handleChangeValues, showPassword, handleClickShowPassword, handleMouseDownPassword, handleForgotPasswordClick, handleButtonClick, handleSwitchSingView
        return (
          <Login
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            initialValues={initialValues}
            handleChangeValues={handleChangeValues}
            showPassword={showPassword}
            handleClickShowPassword={handleClickShowPassword}
            handleMouseDownPassword={handleMouseDownPassword}
            handleForgotPasswordClick={handleForgotPasswordClick}
            handleButtonClick={handleButtonClick}
            handleSwitchSingView={handleSwitchSingView}
            handleRegisterClick={handleRegisterClick}
          />
        )
      case 'sign-up':
        return (
          <SignUp
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            handleMouseDownPassword={handleMouseDownPassword}
            handleSignInClickPage={handleSignInClickPage}
            isVerified={isVerified}
            setIsVerified={setIsVerified}
          />
        )
      case 'password':
        return <ChangePassword 
                  initialValues={initialValues}
                  handleChangeValues={handleChangeValues}
                  handleButtonClick={handleButtonClick}
                  handleSignInClickPage={handleSignInClickPage}
            />
      case 'reset-email':
        return (
          <ResetEmail
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            initialValues={initialValues}
            handleChangeValues={handleChangeValues}
            handleButtonClick={handleButtonClick}
            handleRegisterClick={handleRegisterClick}
            // handleSwitchSingView={handleSwitchSingView}
          />
        )
      case 'otp':
        return (
          <OTP
            initialValues={initialValues}
            handleChangeValues={handleChangeValues}
            handleButtonClick={handleButtonClick}
            handleForgotPasswordClick={handleForgotPasswordClick}
          />
        )
      default:
        return verifyAccountImage
    }
  }

  const handleSignInClickPage = () => {
    setDisplayingPage('sign-in')
  }

  const handleForgotPasswordClick = () => {
    setDisplayingPage('reset-email')
  }

  const handleRegisterClick = () => {
    setDisplayingPage('sign-up')
  }

  const handleDotClick  = (index: any) => {
    setImageIndex(index);
  };

  useEffect(() => {
    // Preload images
    const preloadImages = [loginImages1, loginImages2, loginImages3];
    const images: HTMLImageElement[] = [];
    let loadedImages = 0;

    const handleImageLoad = () => {
      loadedImages++;
      if (loadedImages === preloadImages.length) {
        setImagesLoaded(true);
      }
    };

    preloadImages.forEach((src) => {
      const img = new Image();
      img.src = src;
      img.onload = handleImageLoad;
      images.push(img);
    });

    return () => {
      images.forEach((img) => img.removeEventListener('load', handleImageLoad));
    };
  }, []);

  return (
    <div className={!isMobile ? 'sign-in-wrapper' : 'sign-in-wrapper-mobile'}>
      {!isMobile && (
     <div className='sign-in-img__wrapper'>
        {imageIndex === 0 && <img src={loginImages1} className={`sign-in-img`}  alt={`Slide ${imageIndex + 1}`}/>}
        {imageIndex === 1 && <img src={loginImages2} className={`sign-in-img`}  alt={`Slide ${imageIndex + 1}`}/>}
        {imageIndex === 2 && <img src={loginImages3} className={`sign-in-img`}  alt={`Slide ${imageIndex + 1}`}/>}
       <div className='sign-in-footer-container'>
          <div className='sign-in-dots'>
            <div className={`dot ${imageIndex === 0 ? 'active' : ''}`} onClick={() => handleDotClick(0)}></div>
            <div className={`dot ${imageIndex === 1 ? 'active' : ''}`} onClick={() => handleDotClick(1)}></div>
            <div className={`dot ${imageIndex === 2 ? 'active' : ''}`} onClick={() => handleDotClick(2)}></div>
          </div>
          <img src={logoWhite} className='sign-in-footer-logo' alt='Logo White' />
        </div>
      </div>
      )}

      {isMobile && displayingPage === 'sign-in' && (
        <div className='sign-in-img__wrapper-mobile'>
          <img src={Logo} className='sign-in-img-mobile' />
        </div>
      )}

      <div style={{ marginLeft: !isMobile ? '50px' : '', width: !isMobile ? '30%' : '90%' }}>
        {renderLogInScreens()}
      </div>
    </div>
  )
}

export default SignIn
