import { useState } from 'react'
import AgedReceivablesFilters from './filters'
import AgedReceivablesKpis from './kpis'
import AgedReceivablesTable from './table'
import moment from 'moment'

const AgedReceivables = () => {
  const [filterData, setFilterData] = useState({
    date: moment().toISOString(),
  })

  return (
    <>
      <AgedReceivablesFilters mb={2} filterData={filterData} setFilterData={setFilterData} />
      <AgedReceivablesKpis mb={2} />
      <AgedReceivablesTable filterDate={filterData.date} />
    </>
  )
}

export default AgedReceivables
