import { SxProps, TextField } from '@mui/material'
import { FocusEventHandler, ReactNode } from 'react'
import { PatternFormat } from 'react-number-format'

interface CardDateInputProps {
  value: string
  label: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  styles?: string
  sx?: any
  error?: boolean
  helperText?: ReactNode
  onBlur?: FocusEventHandler
  required?: boolean
}

export const CardDateInput = (props: CardDateInputProps) => {
  return (
    <PatternFormat
      customInput={TextField}
      format='##/##'
      placeholder='MM / YY'
      mask={['M', 'M', 'Y', 'Y']}
      InputLabelProps={{ shrink: true }}
      fullWidth
      {...props}
    />
  )
}

interface CardNumberInputProps {
  value: string
  label: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  styles?: string
  sx?: SxProps
  error?: boolean
  helperText?: ReactNode
  onBlur?: FocusEventHandler
  required?: boolean
}

export const CardNumberInput = (props: CardNumberInputProps) => {
  const symbols = ['e', 'E', '+', '-', '.']

  return (
    <PatternFormat
      customInput={TextField}
      placeholder='0000 0000 0000 0000'
      format='#### #### #### ####'
      InputLabelProps={{ shrink: true }}
      onKeyDown={(e: any) => symbols.includes(e.key) && e.preventDefault()}
      fullWidth
      {...props}
    />
  )
}
