import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from '@mui/material'
import { IActivityStatement } from '../../../types/types'
import styled from '@emotion/styled'
import { formatDate, formatMoney } from '../../../helpers/formatPrice'
import { formatTime } from '../../../helpers/formatDate'

interface ActivityRefundsProps {
  activityStatements: IActivityStatement | null
  mb?: number | string
}

const ActivityRefunds = ({ activityStatements, mb }: ActivityRefundsProps) => {
  const TableHeadTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'var(--background-color)',
      fontWeight: 'bold',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const renderPaymentMethod = (refund: any) => {
    switch (refund.method) {
      case 'CASH':
        return 'Cash'
      case 'CHECK':
        return 'Check'
      case 'CREDIT_CARD':
        return refund.cardType
    }
  }

  const renderPaymentDetails = (refund: any) => {
    if (refund.method === 'CREDIT_CARD') {
      return `**** **** **** ${refund?.lastDigits}`
    }

    if (refund.method === 'CHECK') {
      return refund.checkNumber
    }
  }

  return (
    <Box mb={mb}>
      <Typography variant='h5' pb={1}>
        Refunds
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadTableCell>Amount</TableHeadTableCell>
            <TableHeadTableCell>Method</TableHeadTableCell>
            <TableHeadTableCell>Payment details</TableHeadTableCell>
            <TableHeadTableCell>Refund date</TableHeadTableCell>
            <TableHeadTableCell>Invoice ID</TableHeadTableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            '& .MuiChip-colorSuccess': {
              backgroundColor: 'var(--success-bg-color)',
              color: 'var(--success-color)',
            },
            '& .MuiChip-colorWarning': {
              backgroundColor: 'var(--warning-bg-color)',
              color: 'var(--warning-color)',
            },
            '& .MuiChip-colorError': {
              backgroundColor: 'var(--error-bg-color)',
              color: 'var(--error-color)',
            },
            '& .MuiChip-colorInfo': {
              backgroundColor: 'var(--info-bg-color)',
              color: 'var(--info-color)',
            },
            '& .MuiChip-colorSecondary': {
              backgroundColor: 'var(--orange-bg-color)',
              color: 'var(--orange-color)',
            },
          }}
        >
          {activityStatements &&
            activityStatements.refunds.map((refund) => {
              return (
                <TableRow key={refund.publicId}>
                  <TableCell>{formatMoney(refund.amount)}</TableCell>
                  <TableCell>{renderPaymentMethod(refund)}</TableCell>
                  <TableCell>{renderPaymentDetails(refund)}</TableCell>
                  <TableCell>
                    <Typography>{formatDate(refund.date)}</Typography>
                    <Typography color={refund.status === 'OVERDUE' ? 'red' : undefined}>
                      {formatTime(refund.date)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>#{refund.invoice.name}</Typography>
                    <Typography>{formatDate(refund.invoice.date)}</Typography>
                  </TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    </Box>
  )
}

export default ActivityRefunds
