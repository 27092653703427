import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
	menuList: {
		height: 500,
	},
	menuListBlock: {
		padding: '0 !important',
	},
	paperProps: {
		boxShadow: 'none !important',
		border: '1px solid #D9D9D9',
		marginTop: 2,
	},
	menuItem: {
		padding: '9px !important',
		fontFamily: 'var(--inter-font) !important',
		fontSize: '14px !important',
		color: 'var(--secondary-grey)',
	},
	selectedMuiItem: {
		backgroundColor: 'transparent !important',
	},
	selectInput: {
		height: '23px !important',
	},
});

export const buttonStyles = {
	backgroundColor: 'var(--secondary1-color)',
	width: '100%',
	color: 'var(--primary1-color)',
	borderRadius: '0 0 3px 3px',
	padding: '14px 15px 14px 9px',
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
	fontFamily: 'var(--inter-font)',
	fontSize: 14,
	cursor: 'pointer',
	textTransform: 'none',
};
