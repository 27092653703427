import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  TextField,
} from '@mui/material'
import { ICustomer } from '../../../types/types'
import FlagIcon from '@mui/icons-material/Flag'
import { SyntheticEvent, useState } from 'react'

interface BillingAddressFormProps {
  customer: ICustomer
  setCustomer: (customer: any) => void
}

const BillingAddressForm = ({ customer, setCustomer }: BillingAddressFormProps) => {
  const [copyToShippingAddress, setCopyToShippingAddress] = useState(false)

  const handleCopyToShippingAddressChange = (event: SyntheticEvent, isChecked: boolean) => {
    setCopyToShippingAddress(isChecked)

    if (isChecked) {
      setCustomer({
        ...customer,
        shippingAddress: customer.billingAddress,
        shippingAddressTwo: customer.billingAddressTwo,
        shippingPostalCode: customer.billingPostalCode,
        shippingCity: customer.billingCity,
        shippingState: customer.billingState,
        shippingCountry: customer.billingCountry,
      })
    } else {
      setCustomer({
        ...customer,
        shippingAddress: '',
        shippingAddressTwo: '',
        shippingPostalCode: '',
        shippingCity: '',
        shippingState: '',
        shippingCountry: '',
      })
    }
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextField
            label='Address line 1'
            placeholder='Building Number, Street Address, Apartment...'
            InputLabelProps={{
              shrink: true,
            }}
            value={customer.billingAddress}
            onChange={(e) =>
              setCustomer({
                ...customer,
                billingAddress: e.target.value,
              })
            }
            required
            fullWidth
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            label='Address line 2'
            placeholder='Building Number, Street Address, Apartment...'
            InputLabelProps={{
              shrink: true,
            }}
            value={customer.billingAddressTwo || ''}
            onChange={(e) =>
              setCustomer({
                ...customer,
                billingAddressTwo: e.target.value,
              })
            }
            fullWidth
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            label='City'
            placeholder='Enter your city or locality'
            InputLabelProps={{
              shrink: true,
            }}
            value={customer.billingCity}
            onChange={(e) => setCustomer({ ...customer, billingCity: e.target.value })}
            required
            fullWidth
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            label='State'
            placeholder='Enter your State abbreviation'
            InputLabelProps={{
              shrink: true,
            }}
            value={customer.billingState}
            onChange={(e) => setCustomer({ ...customer, billingState: e.target.value })}
            required
            fullWidth
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            type='number'
            label='Postal/Zip code'
            placeholder='Enter your zip code'
            InputLabelProps={{
              shrink: true,
            }}
            value={customer.billingPostalCode}
            onChange={(e) =>
              setCustomer({
                ...customer,
                billingPostalCode: e.target.value,
              })
            }
            required
            fullWidth
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            label='Country'
            value={customer.billingCountry}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <FlagIcon />
                </InputAdornment>
              ),
            }}
            fullWidth
            required
            disabled
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label='My Shipping address is the same as Billing address'
              onChange={handleCopyToShippingAddressChange}
              value={copyToShippingAddress}
            />
          </FormGroup>
        </Grid>
      </Grid>
    </Box>
  )
}

export default BillingAddressForm
