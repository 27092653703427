import { Box, Button, LinearProgress, Typography } from '@mui/material'
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined'
import Dropzone from 'react-dropzone'
import { useState } from 'react'

interface LogoPreferencesProps {
  logoDataURL?: string
  setLogo: (dataURL: string | null | undefined) => void
  setLogoFile: (file: File | null) => void
}

const LogoPreferences = ({ logoDataURL, setLogoFile, setLogo }: LogoPreferencesProps) => {
  const [showProgress, setShowProgress] = useState(false)

  const handleDrop = (files: File[]) => {
    const reader = new FileReader()
    const firstFile = files[0]
    setLogoFile(firstFile)
    reader.readAsDataURL(firstFile)
    setShowProgress(true)
    reader.onload = (e) => {
      e.preventDefault()
      setLogo(e.target?.result?.toString())
      setShowProgress(false)
    }
  }

  const handleRemoveLogoClick = () => {
    setLogo(null)
    setLogoFile(null)
  }

  return (
    <>
      {showProgress && <LinearProgress />}

      {logoDataURL ? (
        <Box>
          <Box>
            <img
              src={logoDataURL}
              style={{ border: 'solid 1px var(--gray3-color)', borderRadius: 10, maxWidth: '100%' }}
            />
          </Box>
          <Button variant='text' color='primary' onClick={handleRemoveLogoClick}>
            Remove Logo
          </Button>
        </Box>
      ) : (
        <Dropzone onDrop={handleDrop} noClick={true}>
          {({ getRootProps, getInputProps }) => (
            <Box
              {...getRootProps()}
              sx={{
                border: 'dashed 2px var(--primary1-color)',
                p: 5,
                textAlign: 'center',
                backgroundColor: 'var(--secondary1-color)',
                borderRadius: 2,
              }}
            >
              <Typography fontWeight='bold' mb={1}>
                Drop your logo here or
              </Typography>
              <Button
                component='label'
                variant='contained'
                sx={{ mb: 2 }}
                startIcon={<UploadOutlinedIcon />}
              >
                <input {...getInputProps()} />
                Choose a file
              </Button>
              <Typography>Maximum 2MB in size.</Typography>
              <Typography>JPG, PNG or GIF formats.</Typography>
              <Typography>Recommended size: 300 x 200 pixels</Typography>
            </Box>
          )}
        </Dropzone>
      )}
    </>
  )
}

export default LogoPreferences
