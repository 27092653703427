import { useSelector } from 'react-redux'
import { IBill } from '../../types/types'
import { AppState } from '../../redux/store'
import { DataGrid, GridColDef, GridRowSpacingParams } from '@mui/x-data-grid'
import { MouseEvent, useCallback, useMemo, useState } from 'react'
import { Box, Button, Chip, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { isUsingMobile } from '../../helpers/utils'
import { formatDate, formatMoney } from '../../helpers/formatPrice'
import TableStyles from '../../components/table-card/table-styles'

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import useAuthorization from '../../helpers/useAuthorization'

interface BillsTableProps {
  selectedBill?: IBill | null
  setSelectedBill: (bill: IBill) => void
  onRowClick: (bill: IBill) => void
  onDeleteClick: () => void
  onEditClick: () => void
  onDuplicateClick: () => void
  onRecordPaymentClick: () => void
  disabledItems?: string[]
}

const BillsTable = ({
  selectedBill,
  setSelectedBill,
  onRowClick,
  onEditClick,
  onDeleteClick,
  onDuplicateClick,
  onRecordPaymentClick,
  disabledItems = [],
}: BillsTableProps) => {
  const bills = useSelector((state: AppState) => state.homeReducer.bills)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openItemMenu = Boolean(anchorEl)
  const isMobile = isUsingMobile()
  const { isAuthorized } = useAuthorization()

  const handleMenuButtonClick = useCallback(
    (bill: IBill) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      setSelectedBill(bill)
      setAnchorEl(event.currentTarget)
    },
    [setSelectedBill],
  )

  const getColorByStatus = (status: string) => {
    switch (status) {
      case 'PARTIAL':
        return 'warning'
      case 'SENT':
      case 'OPEN':
        return 'info'
      case 'PAID':
        return 'success'
      case 'OVERDUE':
        return 'error'
      case 'REFUND':
        return 'secondary'
      case 'DRAFT':
      default:
        return
    }
  }

  const columns = useMemo<GridColDef<IBill>[]>(
    () => [
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        sortable: false,
        renderCell: (param) => {
          return isMobile ? (
            <FiberManualRecordIcon
              sx={{
                '&.MuiSvgIcon-root.MuiSvgIcon-colorSuccess': {
                  color: 'var(--success-color)',
                  borderRadius: '50%',
                  backgroundColor: 'var(--success-bg-color)',
                },
                '&.MuiSvgIcon-root.MuiSvgIcon-colorInfo': {
                  color: 'var(--info-color)',
                  borderRadius: '50%',
                  backgroundColor: 'var(--info-bg-color)',
                },
                '&.MuiSvgIcon-root.MuiSvgIcon-colorWarning': {
                  color: 'var(--warning-color)',
                  borderRadius: '50%',
                  backgroundColor: 'var(--warning-bg-color)',
                },
                '&.MuiSvgIcon-root.MuiSvgIcon-colorError': {
                  color: 'var(--error-color)',
                  borderRadius: '50%',
                  backgroundColor: 'var(--error-bg-color)',
                },
                '&.MuiSvgIcon-root.MuiSvgIcon-colorSecondary': {
                  color: 'var(--orange-color)',
                  borderRadius: '50%',
                  backgroundColor: 'var(--orange-bg-color)',
                },
                '&.MuiSvgIcon-root': {
                  backgroundColor: 'var(--secondary-grey-bg)',
                  borderRadius: '50%',
                  margin: 'auto',
                },
              }}
              color={getColorByStatus(param.row.status || 'DRAFT')}
            />
          ) : (
            <Chip
              sx={{
                '&.MuiChip-colorSuccess': {
                  backgroundColor: 'var(--success-bg-color)',
                  color: 'var(--success-color)',
                },
                '&.MuiChip-colorWarning': {
                  backgroundColor: 'var(--warning-bg-color)',
                  color: 'var(--warning-color)',
                },
                '&.MuiChip-colorError': {
                  backgroundColor: 'var(--error-bg-color)',
                  color: 'var(--error-color)',
                },
                '&.MuiChip-colorInfo': {
                  backgroundColor: 'var(--info-bg-color)',
                  color: 'var(--info-color)',
                },
                '&.MuiChip-colorSecondary': {
                  backgroundColor: 'var(--orange-bg-color)',
                  color: 'var(--orange-color)',
                },
              }}
              color={getColorByStatus(param.row.status || 'DRAFT')}
              label={param.row.status}
            />
          )
        },
      },
      {
        field: 'name',
        headerName: 'ID',
        flex: 1,
        sortable: false,
        renderCell: (param) => {
          return <Typography>{param.row.name}</Typography>
        },
      },
      {
        field: 'vendor',
        headerName: 'Vendor',
        flex: 2,
        sortable: false,
        renderCell: (param) => {
          return (
            <Box>
              <Typography>{param.row.vendor?.name}</Typography>
              <Typography fontSize={14} color='var(--gray3-color)'>
                {param.row.vendor?.emailAddress}
              </Typography>
            </Box>
          )
        },
      },
      {
        field: 'date',
        headerName: 'Date',
        flex: 2,
        sortable: false,
        renderCell: (param) => {
          return <Typography>{formatDate(param.row.date)}</Typography>
        },
      },
      {
        field: 'dueDate',
        headerName: 'Due Date',
        flex: 2,
        sortable: false,
        renderCell: (param) => {
          return <Typography>{formatDate(param.row.dueDate)}</Typography>
        },
      },
      {
        field: 'amount',
        headerName: 'Amount',
        flex: isMobile ? 1 : 2,
        sortable: false,
        renderCell: (param) => {
          return <Typography>{formatMoney(param.row.total)}</Typography>
        },
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 2,
        sortable: false,
        renderCell: (param) => {
          return (
            <Button
              variant='outlined'
              disabled={!!param.row && param.row.status === 'PAID'}
              onClick={handleRecordPaymentClick(param.row)}
            >
              Record Payment
            </Button>
          )
        },
      },
      {
        field: 'moreActions',
        headerName: '',
        flex: 1,
        sortable: false,
        renderCell: (param) => (
          <IconButton
            aria-label='more'
            id='long-button'
            aria-controls={openItemMenu ? 'long-menu' : undefined}
            aria-expanded={openItemMenu ? 'true' : undefined}
            aria-haspopup='true'
            onClick={handleMenuButtonClick(param.row)}
            sx={{
              justifyItems: 'center',
            }}
          >
            <MoreVertIcon />
          </IconButton>
        ),
      },
    ],
    [isMobile, openItemMenu, handleMenuButtonClick],
  )

  const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: 5,
    }
  }, [])

  const handleDeleteClick = () => {
    setAnchorEl(null)
    onDeleteClick()
  }

  const handleDuplicateClick = () => {
    setAnchorEl(null)
    onDuplicateClick()
  }

  const handleRecordPaymentClick = (bill?: IBill) => (event: MouseEvent<HTMLElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(null)
    onRecordPaymentClick && onRecordPaymentClick()

    if (bill && setSelectedBill) {
      setSelectedBill(bill)
    }

  }

  return (
    <>
      <DataGrid
        rows={bills}
        columns={columns}
        disableColumnMenu
        getRowId={(vendorsInTable) => vendorsInTable.publicId || ''}
        onRowClick={(params) => onRowClick(params.row)}
        hideFooter
        getRowSpacing={getRowSpacing}
        rowHeight={65}
        initialState={{
          columns: {
            columnVisibilityModel: {
              name: !isMobile,
              date: !isMobile,
              dueDate: !isMobile,
              actions: !isMobile && isAuthorized('write_bill'),
              moreActions: isAuthorized('write_bill'),
            },
          },
        }}
        sx={TableStyles}
      />
      <Menu
        id='actions-menu'
        anchorEl={anchorEl}
        open={openItemMenu}
        onClose={() => setAnchorEl(null)}
        sx={{
          '& .MuiMenuItem-root': {
            padding: { xs: '1rem', md: '1rem 4rem 1rem 2rem' },
          },
          '& .MuiMenuItem-root:hover': {
            backgroundColor: 'var(--secondary1-color)',
            color: 'var(--primary1-color)',
          },
        }}
      >
        <MenuItem 
        onClick={onEditClick}
        disabled={disabledItems.includes('edit')}>Edit</MenuItem>
        <MenuItem onClick={handleDuplicateClick}>Duplicate</MenuItem>
        {isMobile && (
          <MenuItem
            onClick={handleRecordPaymentClick()}
            disabled={!!selectedBill && selectedBill.status === 'PAID'}
          >
            Record Payment
          </MenuItem>
        )}
        <MenuItem sx={{ color: 'var(--error-color)' }} onClick={handleDeleteClick}>
          Delete
        </MenuItem>
      </Menu>
    </>
  )
}

export default BillsTable
