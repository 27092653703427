import { Box, Typography } from '@mui/material'
import { MouseEventHandler, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../../redux/store'
import { useNavigate } from 'react-router-dom'
import InvoicesTable from '../invoices/invoicesTable'
import NoDataDisplay from '../../components/noDataDisplay/noDataDisplay'
import TableNoData from '../../components/table-no-data'
import { IInvoice } from '../../types/types'

const DashboardInvoices = () => {
  const navigate = useNavigate()

  const [chosenTableTab, setChosenTableTab] = useState<string>('Overdue Invoices')
  const overdueInvoices = useSelector((state: AppState) => state.dashboardReducer.overdueInvoices)
  const overdueInvoicesSize = useSelector((state: AppState) => state.dashboardReducer.overdueInvoicesSize)
  const openInvoices = useSelector((state: AppState) => state.dashboardReducer.openInvoices)
  const openInvoicesSize = useSelector((state: AppState) => state.dashboardReducer.openInvoicesSize)

  const handleTabClick = (tab: string) => {
    setChosenTableTab(tab)
  }

  const handleClickInvoiceView = (invoice: IInvoice) => {
    navigate(`/invoices?selectedFilter=${chosenTableTab}`)
  }

  return (
    <>
      <div className='dashboard__block'>
        <div className='dashboard-table__tabs'>
          <TableTab
            tabAmount={`${overdueInvoices.length}/${overdueInvoicesSize}`}
            isChosen={chosenTableTab === 'Overdue Invoices'}
            tabText='Overdue Invoices'
            onClick={handleTabClick.bind(null, 'Overdue Invoices')}
          />
          <div className='dashboard-table__tabs-divider'></div>
          <TableTab
            tabAmount={`${openInvoices.length}/${openInvoicesSize}`}
            isChosen={chosenTableTab === 'Open Invoices'}
            tabText='Sent Invoices'
            onClick={handleTabClick.bind(null, 'Open Invoices')}
          />
        </div>
        <div></div>
      </div>
      <div className='dashboard-table__wrapper'>
        {(chosenTableTab === 'Open Invoices' && openInvoices.length > 0) ||
        (chosenTableTab === 'Overdue Invoices' && overdueInvoices.length > 0) ? (
          <Box pb={4}>
            <InvoicesTable
              isDashboardInvoiceTable
              invoices={chosenTableTab === 'Open Invoices' ? openInvoices : overdueInvoices}
              onRowClick={handleClickInvoiceView}
            />
          </Box>
        ) : (
          <NoDataDisplay title='No data to display' />
        )}
        {(chosenTableTab === 'Open Invoices' && !openInvoices.length) ||
        (chosenTableTab === 'Overdue Invoices' && !overdueInvoices.length) ? (
          <TableNoData
            messageText='Not enough data to display in the list'
            styles='dashoard-no-data__tables'
          />
        ) : null}
      </div>
    </>
  )
}

interface ITabTab {
  tabAmount: string
  isChosen: boolean
  tabText: string
  onClick: MouseEventHandler<HTMLDivElement> | undefined
}

const TableTab = (props: ITabTab) => {
  const { tabAmount, isChosen, tabText, onClick } = props
  return (
    <div className='dashboard-table__tab' onClick={onClick}>
      <div className={`h4-mulish-semibold ${isChosen ? 'dashboard-table__tabs-green' : ''}`}>
        <Typography color={isChosen ? 'var(--primary1-color)' : 'var(--secondary-grey)'}>
          {tabText}
        </Typography>
      </div>
      <div className='dashboard-table__tabs-amount'>{`(${tabAmount})`}</div>
    </div>
  )
}

export default DashboardInvoices
