import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { CHANGE_IS_LOADING, SAVE_COMPANY } from '../redux/actions/home.actions.type'
import store from '../redux/store'
import {
  ICompany,
  ICreditCard,
  ICustomer,
  IEstimate,
  IInvoice,
  INewBill,
  INewJournalEntry,
  IPaymentData,
  IPDFData,
  IProduct,
  IPurchaseProduct,
  IRefundData,
  IRole,
  IScheduledInvoice,
  IScheduler,
  ISupport,
  IUser,
} from '../types/types'
const { dispatch } = store

export const fullDomain = process.env.REACT_APP_BASE_URL || 'https://app-stage.cheddartop.com'

const UNAUTHORIZED_CONNECTION = axios.create({
  baseURL: fullDomain,
})

const BASE_CONNECTION = axios.create({
  baseURL: fullDomain,
})

BASE_CONNECTION.interceptors.request.use(function (response: any) {
  dispatch({ type: CHANGE_IS_LOADING, payload: true })
  return response
})

BASE_CONNECTION.interceptors.response.use(
  function (response: AxiosResponse) {
    dispatch({ type: CHANGE_IS_LOADING, payload: false })
    return response
  },
  function (error: AxiosError) {
    dispatch({ type: CHANGE_IS_LOADING, payload: false })
    return Promise.reject(error)
  },
)

BASE_CONNECTION.interceptors.request.use(function (config: AxiosRequestConfig) {
  const accessToken = store.getState().authReducer.accessToken
  if (!config.headers) {
    config.headers = {}
  } else {
    config.headers.Authorization = accessToken
  }
  return config
})

BASE_CONNECTION.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error?.response?.status === 403 || error?.response?.status === 401) {
      sessionStorage.removeItem('token')
      window.location.href = '/sign-in'
      dispatch({
        type: SAVE_COMPANY,
        payload: null,
      })
    } else {
      return Promise.reject(error)
    }
  },
)

const api = {
  getProducts: (page: number) =>
    BASE_CONNECTION.get(`/api/v1/products?size=15&page=${page}&sort=id,desc`),
  createProduct: (productData: IProduct) =>
    BASE_CONNECTION.post(`/api/v1/products/create`, productData),
  editProduct: (productData: IProduct, productId: string | undefined) =>
    BASE_CONNECTION.put(`/api/v1/products/${productId}`, productData),
  deleteProduct: (productId: string | undefined) =>
    BASE_CONNECTION.delete(`/api/v1/products/${productId}`),
  getCertainCustomer: (publicId: string | undefined) =>
    BASE_CONNECTION.get(`/api/v1/customers/${publicId}`),
  getCustomers: (page: number) =>
    BASE_CONNECTION.get(`/api/v1/customers?size=15&page=${page}&sort=id,desc`),
  createCustomer: (customerData: ICustomer) =>
    BASE_CONNECTION.post('/api/v1/customers/create', customerData),
  editCustomer: (customerData: ICustomer, publicId: string | undefined) =>
    BASE_CONNECTION.put(`/api/v1/customers/${publicId}`, customerData),
  deleteCustomer: (publicId: string | undefined) =>
    BASE_CONNECTION.delete(`/api/v1/customers/${publicId}`),
  getCompany: () => BASE_CONNECTION.get('/api/v1/company'),
  getAccountSubscription: () =>
    BASE_CONNECTION.get('/api/v1/scheduled-invoice/account-subscription'),
  createAccountSubscription: (accountSubscription: any) =>
    BASE_CONNECTION.post('/api/v1/scheduled-invoice/account-subscription', accountSubscription),
  cancelAccountSubscription: (reason: any) =>
    BASE_CONNECTION.put('/api/v1/scheduled-invoice/account-subscription/cancel', {
      reason: reason,
    }),
  activateAccountSubscription: () =>
    BASE_CONNECTION.put('/api/v1/scheduled-invoice/account-subscription/activate'),

  getFinancialAccount: (
    page: number,
    size?: number,
    raw?: string,
    isActive?: boolean,
    typePublicId?: string,
    category?: string,
  ) => {
    const params = []

    if (page) {
      params.push(`page=${page}`)
    }

    if (size) {
      params.push(`size=${size}`)
    }

    if (raw) {
      params.push(`raw=${raw}`)
    }

    if (isActive !== undefined) {
      params.push(`isActive=${isActive}`)
    }

    if (typePublicId) {
      params.push(`typePublicId=${typePublicId}`)
    }

    if (category) {
      params.push(`category=${category}`)
    }

    return BASE_CONNECTION.get(`/api/v1/financial-account?${params.join('&')}`)
  },
  getFinancialAccountById: (publicId: string | undefined) =>
    BASE_CONNECTION.get(`/api/v1/financial-account/${publicId}`),
  updateFinancialAccount: (accountData: any, publicId: string | undefined) =>
    BASE_CONNECTION.put(`/api/v1/financial-account/${publicId}`, accountData),
  exportFinancialAccount: () =>
    BASE_CONNECTION.get(`/api/v1/financial-account/export`, {
      responseType: 'blob',
    }),
  importFinancialAccount: (newAccountData: any) =>
    BASE_CONNECTION.post(`/api/v1/financial-account/import`, newAccountData),
  deleteFinancialAccount: (publicId: string | undefined) =>
    BASE_CONNECTION.delete(`/api/v1/financial-account/${publicId}`),
  createFinancialAccount: (accountData: any) =>
    BASE_CONNECTION.post(`/api/v1/financial-account/`, accountData),
  getFinancialAccountTypes: (page: number, size?: string, category?: string) =>
    BASE_CONNECTION.get(
      `/api/v1/financial-account-type?size=15&page=${page}&size=${size}&category=${category}&sort=name,asc`,
    ),
  getFinancialAccountTypeById: (publicId: string | undefined) =>
    BASE_CONNECTION.get(`/api/v1/financial-account-type/${publicId}`),
  createFinancialAccountTypes: (FinancialAccountType: any) =>
    BASE_CONNECTION.post('/api/v1/financial-account-type', FinancialAccountType),
  updateFinancialAccountType: (FinancialAccountType: any, publicId: string | undefined) =>
    BASE_CONNECTION.put(`/api/v1/financial-account-type/${publicId}`, FinancialAccountType),
  deleteFinancialAccountType: (publicId: string | undefined) =>
    BASE_CONNECTION.delete(`/api/v1/financial-account-type/${publicId}`),
  getVendors: (page: number, size: number, search?: string, type?: string) => {
    const params = []

    if (page) params.push(`page=${page}`)
    if (size) params.push(`size=${size}`)
    if (search) params.push(`raw=${search}`)
    if (type) params.push(`type=${type}`)

    return BASE_CONNECTION.get(`/api/v1/vendor?${params.join('&')}`)
  },
  getVendorsById: (publicId: string | undefined) =>
    BASE_CONNECTION.get(`/api/v1/vendor/${publicId}`),
  createVendor: (vendor: any) => BASE_CONNECTION.post('/api/v1/vendor', vendor),
  updateVendor: (vendor: any, publicId: string | undefined) =>
    BASE_CONNECTION.put(`/api/v1/vendor/${publicId}`, vendor),
  deleteVendor: (publicId: string | undefined) =>
    BASE_CONNECTION.delete(`/api/v1/vendor/${publicId}`),
  importVendors: (data: any) => BASE_CONNECTION.post(`/api/v1/vendor/import`, data),
  exportVendors: () =>
    BASE_CONNECTION.get(`/api/v1/vendor/export`, {
      responseType: 'blob',
    }),
  getFaq: (page: number, size?: string, showFaqs?: string) =>
    BASE_CONNECTION.get(`/api/v1/faq-topic?size=15&page=${page}&size=${size}&showFaqs=${showFaqs}`),
  getRefferrals: (page: number, size?: number) =>
    BASE_CONNECTION.get(`/api/v1/referral?page=${page}&size=${size}`),
  resendEmail: (id: string) => BASE_CONNECTION.post(`/api/v1/referral/${id}/send`),
  deleteReferral: (id: string) => BASE_CONNECTION.delete(`/api/v1/referral/${id}`),
  updateReferral: (id: string, payload: any) =>
    BASE_CONNECTION.put(`/api/v1/referral/${id}`, payload),
  createReferral: (payload: any) => BASE_CONNECTION.post(`/api/v1/referral`, payload),
  createExcel: () =>
    BASE_CONNECTION.post(
      `/api/v1/referral/excel`,
      {},
      {
        responseType: 'blob',
      },
    ),
  sendSupport: (supportInfo: ISupport): Promise<AxiosResponse> => {
    const formData = new FormData()
    supportInfo.attachments.forEach((file, index) => {
      formData.append(`attachments[${index}]`, file)
    })
    formData.append('description', supportInfo.description)
    formData.append('type', supportInfo.type)
    return BASE_CONNECTION.post('/api/v1/user-support', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: BASE_CONNECTION.defaults.headers.common['Authorization'],
      },
    })
  },

  editCompany: (companyData: ICompany) => BASE_CONNECTION.put('/api/v1/company', companyData),
  editCompanyPreferences: (companyPreferences: FormData) =>
    BASE_CONNECTION.put('/api/v1/company/preferences', companyPreferences, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),
  createCompany: (company: any) => BASE_CONNECTION.post('/api/v1/company/create', company),
  getEstimates: (page: number, statuses?: string) =>
    BASE_CONNECTION.get(`/api/v1/estimates?size=15&page=${page}&sort=id,desc&statuses=${statuses}`),
  getCertainEstimate: (publicId: string | undefined) =>
    BASE_CONNECTION.get(`/api/v1/estimates/${publicId}`),
  createEstimate: (estimateData: IEstimate) =>
    BASE_CONNECTION.post('/api/v1/estimates/create', estimateData),
  editEstimate: (estimate: IEstimate, publicId: string | undefined) =>
    BASE_CONNECTION.put(`/api/v1/estimates/${publicId}`, estimate),
  deleteEstimate: (publicId: string | undefined) =>
    BASE_CONNECTION.delete(`/api/v1/estimates/${publicId}`),
  createInvoice: (invoice: IInvoice) => BASE_CONNECTION.post('/api/v1/invoices/create', invoice),
  getInvoices: (
    page: number,
    statuses?: string,
    invoiceName?: number | string,
    customerName?: string,
    fromDate?: string,
    toDate?: string,
    fromAmount?: string,
    toAmount?: string,
    customerId?: string,
  ) =>
    BASE_CONNECTION.get(
      // eslint-disable-next-line max-len
      `/api/v1/invoices?size=15&page=${page}&sort=id,desc&invoiceName=${invoiceName}&statuses=${statuses}&customerName=${customerName}&dateCreation.from=${fromDate}&dateCreation.to=${toDate}&total.from=${fromAmount}&total.to=${toAmount}&customerId=${customerId}`,
    ),
  editInvoice: (publicId: string | undefined, invoice: IInvoice) =>
    BASE_CONNECTION.put(`/api/v1/invoices/${publicId}`, invoice),
  deleteInvoice: (publicId: string | undefined) =>
    BASE_CONNECTION.delete(`/api/v1/invoices/${publicId}`),
  getCertainInvoice: (publicId: string | undefined) =>
    BASE_CONNECTION.get(`/api/v1/invoices/${publicId}`),
  getRoles: () => BASE_CONNECTION.get(`/api/v1/roles`),
  createRole: (role: IRole) => BASE_CONNECTION.post('/api/v1/roles/create', role),
  updateRole: (role: IRole) => BASE_CONNECTION.put(`/api/v1/roles/${role.publicId}`, role),
  getRoleByPublicId: (rolePublicId: string | undefined) =>
    axios.get(`${fullDomain}/api/v1/roles/${rolePublicId}`),
  deleteRole: (publicId: string | undefined) => BASE_CONNECTION.delete(`/api/v1/roles/${publicId}`),
  getPermissions: () => BASE_CONNECTION.get(`/api/v1/permissions`),
  getPayments: (
    page: number,
    customerId: string,
    statuses: string,
    methods: string,
    invoiceName: string,
    fromDate: string,
    toDate: string,
    creditCardType: string,
    raw: string,
  ) => {
    const params = [
      'size=15',
      `page=${page}`,
      `sort=id,desc`,
      `customerId=${customerId}`,
      `statuses=${statuses}`,
      `methods=${methods}`,
      `invoiceName=${invoiceName}`,
      `date.from=${fromDate}`,
      `date.to=${toDate}`,
      `creditCardTypes=${creditCardType}`,
      `raw=${raw}`,
    ].join('&')

    return BASE_CONNECTION.get(
      // eslint-disable-next-line max-len
      `/api/v1/payments?${params}`,
    )
  },
  recordPayment: (publicId: string | undefined, recordData: any) =>
    BASE_CONNECTION.post(`/api/v1/payments/record/${publicId}`, recordData),
  inviteUser: (user: IUser) => BASE_CONNECTION.post(`/api/v1/users/create`, user),
  getOutstandingInvoices: (customerId: string) =>
    BASE_CONNECTION.get(`/api/v1/statements/${customerId}`),

  getOutstandingStatements: (customerPublicId: string | undefined) =>
    BASE_CONNECTION.get(`api/v1/statements/${customerPublicId}`),
  getActivityStatements: (customerPublicId: string | undefined, fromDate: string, toDate: string) =>
    BASE_CONNECTION.get(
      `api/v1/statements/activity/${customerPublicId}?from=${fromDate}&to=${toDate}`,
    ),
  sendOutstandingStatement: (customerPublicId: string | undefined) =>
    BASE_CONNECTION.post(`api/v1/statements/${customerPublicId}`),
  sendActivityStatements: (
    customerPublicId: string | undefined,
    fromDate: string,
    toDate: string,
  ) =>
    BASE_CONNECTION.post(
      `api/v1/statements/activity/${customerPublicId}?from=${fromDate}&to=${toDate}`,
    ),
  convertEstimateToInvoice: (publicId: string | undefined) =>
    BASE_CONNECTION.post(`api/v1/estimates/convertToInvoice/${publicId}`),
  convertInvoiceToSubscription: (invoicePublicId: string | undefined) =>
    BASE_CONNECTION.post(`/api/v1/scheduled-invoice/template/fromInvoice/${invoicePublicId}`),
  duplicateInvoice: (publicId: string | undefined) =>
    BASE_CONNECTION.post(`api/v1/invoices/?originalId=${publicId}`),
  duplicateEstimate: (publicId: string | undefined) =>
    BASE_CONNECTION.post(`api/v1/estimates/?originalId=${publicId}`),
  uploadCompanyLogo: (image: FormData) =>
    BASE_CONNECTION.post(`/api/v1/images`, image, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),
  generateUSIOToken: (cardData: any) =>
    axios.post('https://checkout.usiopay.com/2.0/GenerateToken', cardData),
  getScheduledInvoiceTemplate: (page: number, statuses?: string) =>
    BASE_CONNECTION.get(
      `/api/v1/scheduled-invoice/template?size=15&page=${page}&sort=id,desc&statuses=${statuses}`,
    ),
  getScheduledInvoiceByCustomer: (customerId: string | undefined) =>
    BASE_CONNECTION.get(`/api/v1/scheduled-invoice/template/customer/${customerId}`),
  getCertainScheduledInvoiceTemplate: (publicId: string | undefined) =>
    BASE_CONNECTION.get(`/api/v1/scheduled-invoice/template/${publicId}`),
  createScheduledInvoiceTemplate: (template: IScheduledInvoice) =>
    BASE_CONNECTION.post('/api/v1/scheduled-invoice/template', template),
  editScheduledInvoiceTemplate: (
    template: any,
    publicId: string | undefined,
    chosenCard?: ICreditCard,
  ) =>
    BASE_CONNECTION.put(
      `/api/v1/scheduled-invoice/template/${publicId}${
        chosenCard ? `?creditCardId=${chosenCard.publicId}` : ''
      }`,
      template,
    ),
  addCardToScheduledTempalte: (publicId: string | undefined, chosenCard: ICreditCard) =>
    BASE_CONNECTION.patch(
      `/api/v1/scheduled-invoice/template/${publicId}?creditCardId=${chosenCard.publicId}`,
    ),
  deleteScheduledInvoiceTemplates: (publicId: string | undefined) =>
    BASE_CONNECTION.delete(`/api/v1/scheduled-invoice/template/${publicId}`),
  createScheduler: (scheduler: IScheduler) =>
    BASE_CONNECTION.post('/api/v1/scheduled-invoice/scheduler', scheduler),
  editScheduler: (schedulerId: string | undefined, scheduler: IScheduler) =>
    BASE_CONNECTION.put(`/api/v1/scheduled-invoice/scheduler/${schedulerId}`, scheduler),
  activateRecurringInvoice: (schedulerId: string | undefined | null) =>
    BASE_CONNECTION.put(`/api/v1/scheduled-invoice/scheduler/activate/${schedulerId}`),
  stopRecurringInvoice: (publicId: string | undefined) =>
    BASE_CONNECTION.put(`/api/v1/scheduled-invoice/${publicId}`),
  duplicateScheduledInvoiceTemplate: (publicId: string | undefined) =>
    BASE_CONNECTION.post(`api/v1/scheduled-invoice/template/duplicate?originalId=${publicId}`),
  makePayment: (tokenData: IPaymentData) =>
    BASE_CONNECTION.post(`/api/v1/payments/temp-token`, tokenData),
  makeUnathorizedPayment: (tokenData: IPaymentData) =>
    UNAUTHORIZED_CONNECTION.post(`/api/v1/payments/temp-token`, tokenData),
  makeTokenizedPayment: (tokenData: IPaymentData) =>
    BASE_CONNECTION.post(`/api/v1/payments/perm-token`, tokenData),
  getCreditCards: (customerPublicId: string | undefined) =>
    BASE_CONNECTION.get(`/api/v1/credit-cards/${customerPublicId}`),
  createCreditCard: (cardData: any) => BASE_CONNECTION.post(`/api/v1/credit-cards`, cardData),
  deleteCreditCard: (publicId: string | undefined) =>
    BASE_CONNECTION.delete(`/api/v1/credit-cards/${publicId}`),
  refundUSIOPayment: (refundData: IRefundData) =>
    BASE_CONNECTION.post(`api/v1/payments/refund`, refundData),
  generatePDF: (pdfData: IPDFData) =>
    BASE_CONNECTION.post(`api/v1/jsreport/pdf`, pdfData, {
      responseType: 'blob',
    }),
  generateExcel: (toDate: string) =>
    BASE_CONNECTION.post(
      `api/v1/jsreport/excel/aged?to=${toDate}`,
      {},
      {
        responseType: 'blob',
      },
    ),
  getOverdueInvoices: () => BASE_CONNECTION.get('/api/v1/dashboard/outstanding'),
  getOpenInvoices: () => BASE_CONNECTION.get(`/api/v1/dashboard/open`),
  getOverdueStatistic: () => BASE_CONNECTION.get('/api/v1/dashboard'),
  getAllUsers: () => BASE_CONNECTION.get(`/api/v1/users`),
  sendInvoice: (invoiceId: string | undefined, emailData: any) =>
    BASE_CONNECTION.post(`api/v1/invoices/send/${invoiceId}`, emailData),
  sendEstimate: (estimateId: string | undefined, email: string | undefined | null) =>
    BASE_CONNECTION.post(`api/v1/estimates/send/${estimateId}`, { email }),
  sendReceipt: (paymentPublicId: string | undefined) =>
    BASE_CONNECTION.post(`api/v1/payments/receipt/${paymentPublicId}`),
  getCustomerByPublicId: (customerId: string | undefined) =>
    axios.get(`${fullDomain}/api/v1/pay/customer?customerId=${customerId}`),
  getInvoiceByPublicId: (invoiceId: string | undefined) =>
    axios.get(`${fullDomain}/api/v1/pay/invoice?invoiceId=${invoiceId}`),
  getInvoicesStatistics: () => BASE_CONNECTION.get(`/api/v1/invoices/statistics`),
  getMCCTypes: () => axios.get(`${fullDomain}/api/v1/static/mcc`),
  getDetailedAgedReport: (tillDate: string, page: number) =>
    BASE_CONNECTION.get(
      // eslint-disable-next-line max-len
      `/api/v1/reports/aged/detailed?to=${tillDate}&sort=id&size=10&page=${page}`,
    ),
  getAgedReportGeneral: (tillDate: string) =>
    BASE_CONNECTION.get(`/api/v1/reports/aged/general?to=${tillDate}`),
  getCustomersGeneralReport: (fromDate?: string, toDate?: string) =>
    BASE_CONNECTION.get(`/api/v1/reports/customers/general?from=${fromDate}&to=${toDate}`),
  getCustomersDetailesReport: (fromDate: string, toDate: string, page: number) =>
    BASE_CONNECTION.get(
      // eslint-disable-next-line max-len
      `/api/v1/reports/customers/detailed?from=${fromDate}&to=${toDate}&sort=id&size=15&page=${page}`,
    ),
  getDashboardTotalRevenue: () => BASE_CONNECTION.get(`api/v1/dashboard/totalRevenue`),
  getDashboardCashInflow: () => BASE_CONNECTION.get(`api/v1/dashboard/cashInflow`),
  getSettledDepositReport: (page: number) =>
    BASE_CONNECTION.get(`/api/v1/reports/settledDeposit?size=15&page=${page}`),
  getUSIOApiKey: () => BASE_CONNECTION.get(`api/v1/usio/key`),
  getUSIOUnauthorizeApiKey: (invoiceId: string | undefined) =>
    axios.get(`${fullDomain}/api/v1/usio/key/pay?invoiceId=${invoiceId}`),
  getUSIOLink: () => BASE_CONNECTION.get(`api/v1/usio/enrollment-page-url`),
  saveCurrentUser: () => BASE_CONNECTION.get(`api/v1/users/current`),
  updateUser: (publicId: string | undefined, user: IUser) =>
    BASE_CONNECTION.put(`/api/v1/users/${publicId}`, user),
  deleteUser: (publicId: string | undefined) => BASE_CONNECTION.delete(`/api/v1/users/${publicId}`),
  getUserByPublicId: (publicId: string | undefined) =>
    BASE_CONNECTION.get(`/api/v1/users/${publicId}`),
  setCreditCardAsDefault: (creditCardPublicId: string | undefined) =>
    BASE_CONNECTION.put(`/api/v1/credit-cards/${creditCardPublicId}/default`),
  getPurchaseProducts: (page: number) =>
    BASE_CONNECTION.get(`/api/v1/purchase-product?size=15&page=${page}&sort=id,desc`),
  createPurchaseProduct: (data: IPurchaseProduct) =>
    BASE_CONNECTION.post('/api/v1/purchase-product', data),
  editPurchaseProduct: (data: IPurchaseProduct, productId: string | undefined) =>
    BASE_CONNECTION.put(`/api/v1/purchase-product/${productId}`, data),
  deletePurchaseProduct: (productId: string | undefined) =>
    BASE_CONNECTION.delete(`/api/v1/purchase-product/${productId}`),
  getBills: (
    page: number,
    size = 15,
    status?: string,
    fromDate?: string,
    toDate?: string,
    vendorId?: string,
  ) => {
    const params = []

    if (page) params.push(`page=${page}`)
    if (size) params.push(`size=${size}`)
    if (fromDate) params.push(`date.from=${fromDate}`)
    if (toDate) params.push(`date.to=${toDate}`)
    if (vendorId) params.push(`vendorPublicId=${vendorId}`)
    if (status) params.push(`statuses=${status}`)

    return BASE_CONNECTION.get(`/api/v1/bill?${params.join('&')}`)
  },
  duplicateBill: (publicId: string | undefined) =>
    BASE_CONNECTION.post(`api/v1/bill/${publicId}/duplicate`),
  deleteBill: (publicId: string | undefined) => BASE_CONNECTION.delete(`/api/v1/bill/${publicId}`),
  getCertainBill: (publicId: string | undefined) => BASE_CONNECTION.get(`/api/v1/bill/${publicId}`),
  createBill: (bill: any) =>
    BASE_CONNECTION.post('/api/v1/bill', bill, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: BASE_CONNECTION.defaults.headers.common['Authorization'],
      },
    }),
  editBill: (publicId: string | undefined, bill: any) =>
    BASE_CONNECTION.put(`/api/v1/bill/${publicId}`, bill, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: BASE_CONNECTION.defaults.headers.common['Authorization'],
      },
    }),
  recordBillPayment: (publicId: string | undefined, data: any) =>
    BASE_CONNECTION.post(`/api/v1/bill/${publicId}/payment`, data),
  getProfitAndLossReport: (from: string, to: string) =>
    BASE_CONNECTION.get(`/api/v1/reports/profitLoss?from=${from}&to=${to}`),
    getBalanceSheetReport: (from: string, to: string) =>
    BASE_CONNECTION.get(`/api/v1/reports/balanceSheet?from=${from}&to=${to}`),
  getAccountTransaction: (page: number, size: string, from: string, to: string, financialAccountPublicId: string, isAccountPayable: string, sort: string) =>{
      const params = []
  
      if (page) params.push(`page=${page}`)
      if (size) params.push(`size=${size}`)
      if (financialAccountPublicId) params.push(`financialAccountPublicId=${financialAccountPublicId}`)
      if (from) params.push(`date.from=${from}T00:00:00.000`)
      if (to) params.push(`date.to=${to}T23:59:59.000`)
      if (isAccountPayable) params.push(`isAccountPayable=${isAccountPayable}`)
      params.push(`sort=date,asc`)
      return BASE_CONNECTION.get(`/api/v1/account-transaction?${params.join('&')}`)
    },
  getJournalEntries: (page: number, size = 15, account: string, status: string) => {
    const params = []

    if (page) params.push(`page=${page}`)
    if (size) params.push(`size=${size}`)
    if (account) params.push(`accountPublicId=${account}`)
    if (status && status !== 'ALL') params.push(`reviewed=${status === 'REVIEWED'}`)

    return BASE_CONNECTION.get(`/api/v1/journal-entry?${params.join('&')}`)
  },
  deleteJournalEntry: (publicId: string | undefined) =>
    BASE_CONNECTION.delete(`/api/v1/journal-entry/${publicId}`),
  duplicateJournalEntry: (publicId: string | undefined) =>
    BASE_CONNECTION.post(`api/v1/journal-entry/${publicId}/duplicate`),
  reviewJournalEntry: (publicId: string | undefined) =>
    BASE_CONNECTION.put(`api/v1/journal-entry/${publicId}/reviewed`),
  undoReviewJournalEntry: (publicId: string | undefined) =>
    BASE_CONNECTION.put(`api/v1/journal-entry/${publicId}/not-reviewed`),
  getCertainJournal: (publicId: string | undefined) =>
    BASE_CONNECTION.get(`/api/v1/journal-entry/${publicId}`),
  exportJournalEntries: () =>
    BASE_CONNECTION.get(`/api/v1/journal-entry/export`, {
      responseType: 'blob',
    }),
  createJournal: (journal: any) =>
    BASE_CONNECTION.post('/api/v1/journal-entry', journal, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: BASE_CONNECTION.defaults.headers.common['Authorization'],
      },
    }),
  editJournal: (publicId: string | undefined, journal: any) =>
    BASE_CONNECTION.put(`/api/v1/journal-entry/${publicId}`, journal, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: BASE_CONNECTION.defaults.headers.common['Authorization'],
      },
    }),
}

export default api
