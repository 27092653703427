import { TextField } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import BugReportIcon from '@mui/icons-material/BugReport'
import ChatIcon from '@mui/icons-material/Chat'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import CloseIcon from '@mui/icons-material/Close'
import { useState } from 'react'
import './assistance-dialog.css'
import api from '../../api/api'
import { toast } from 'react-toastify'
import { isUsingMobile } from '../../helpers/utils'

interface IAssistanceDialog {
  isOpen: boolean
  dialogTitle?: string
  dialogBodyContent?: string
  buttonConfirmText?: string
  buttonCancelText?: string
  handleClose: () => void
  setAssistanceSuccess: (value: boolean) => void
}

export default function AssistanceDialog(props: IAssistanceDialog) {
  const { isOpen, dialogTitle, dialogBodyContent, handleClose, setAssistanceSuccess } = props

  const [otherReason, setOtherReason] = useState('')
  const [selectedButton, setSelectedButton] = useState(null)
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([])

  const isMobile = isUsingMobile()

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files) {
      const newFiles: File[] = Array.from(files)
      setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles])
    }
  }

  const removeFile = (index: number) => {
    setUploadedFiles((prevFiles) => {
      const updatedFiles = [...prevFiles]
      updatedFiles.splice(index, 1)
      return updatedFiles
    })
  }

  const handleButtonClick = (buttonId: any) => {
    setSelectedButton(buttonId === selectedButton ? null : buttonId)
  }

  const handleOtherReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtherReason(event.target.value)
  }

  const handleConfirmClick = () => {
    const choice = selectedButton === 1 ? 'BUG' : selectedButton === 2 ? 'FEEDBACK' : 'OTHER'
    api
      .sendSupport({ attachments: uploadedFiles, description: otherReason, type: choice })
      .then(() => {
        handleClose()
        setAssistanceSuccess(true)
      })
      .catch((error: any) => {
        console.error('Error:', error)
      })
  }

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose} aria-labelledby='responsive-dialog-title'>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            padding: !isMobile ? '30px 80px' : '30px 10px',
          }}
        >
          {/* <img src={SadFace} style={{ height: '100px', width: '100px' }} alt='Sad' /> */}
          <DialogTitle id='responsive-dialog-title'>{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div style={{ textAlign: 'center' }}>{dialogBodyContent}</div>
            </DialogContentText>
          </DialogContent>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              className={`custom-button ${selectedButton === 1 ? 'selected' : ''}`}
              onClick={() => handleButtonClick(1)}
            >
              <BugReportIcon />
              <span className='custom-span'>Problem</span>
            </div>
            <div
              className={`custom-button ${selectedButton === 2 ? 'selected' : ''}`}
              onClick={() => handleButtonClick(2)}
            >
              <LightbulbIcon />
              <span className='custom-span'>Idea</span>
            </div>
            <div
              className={`custom-button ${selectedButton === 3 ? 'selected' : ''}`}
              onClick={() => handleButtonClick(3)}
            >
              <ChatIcon />
              <span className='custom-span'>Other</span>
            </div>
          </div>
          {selectedButton && (
            <TextField
            placeholder={
              selectedButton === 1
                ? `Is something not working right? Let us know what's going on, and we'll fix it... `
                : selectedButton === 2
                ? `Do you have an idea? We'd love to hear more about it!`
                : `Your voice matters, and we're eager to hear what you have to say!`
            }
              InputLabelProps={{ shrink: true }}
              label="Description"
              variant='outlined'
              multiline
              rows={2}
              fullWidth
              value={otherReason}
              onChange={handleOtherReasonChange}
              style={{ fontSize: '14px' }}
            />
          )}
          {selectedButton && (
            <div
              style={{
                marginTop: '20px',
                display: 'flex',
                alignItems: 'flex-start',
                width: '100%',
              }}
            >
              <input
                accept='image/*'
                style={{ display: 'none' }}
                id='file-input'
                multiple
                type='file'
                onChange={handleFileChange}
              />
              <label htmlFor='file-input'>
                <div className='custom-button' style={{ height: '46px', width: '46px' }}>
                  <AttachFileIcon />
                </div>
              </label>
              {uploadedFiles.map((file, index) => (
                <div
                  key={index}
                  style={{ position: 'relative', marginLeft: '10px', marginRight: '5px' }}
                >
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`thumbnail-${index}`}
                    style={{ width: '46px', height: '46px', objectFit: 'cover' }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      top: '0',
                      right: '0',
                      marginRight: '-12px',
                      marginTop: '-12px',
                      cursor: 'pointer',
                      padding: '4px',
                      background: '#0039FF',
                      borderRadius: '50%',
                      height: '25px',
                      width: '25px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={() => removeFile(index)}
                  >
                    <CloseIcon fontSize='small' style={{ color: 'white' }} />
                  </div>
                </div>
              ))}
            </div>
          )}
          {selectedButton && (
            <div
              style={{
                marginTop: '5px',
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              <div>
                <Button
                  sx={{ ':hover': { borderWidth: '2px' } }}
                  color='primary'
                  variant='contained'
                  onClick={handleConfirmClick}
                  disabled={otherReason === ""}
                  fullWidth
                >
                  Send message
                </Button>
              </div>
            </div>
          )}
        </div>
      </Dialog>
    </div>
  )
}
