import {
	SAVE_OVERDUE_INVOICES,
	SAVE_OPEN_INVOICES,
	SAVE_OVERDUE_STATISTIC,
	SAVE_TOTAL_REVENUE,
	SAVE_CASH_INFLOW,
} from './dashboard.actions.type';
import { Dispatch } from 'redux';
import { Action } from '../actions/actionTypes';
import api from '../../api/api';
import { errorHandler } from '../../helpers/errorHandler';

export const saveDashboardTotalRevenue = () => (dispatch: Dispatch<Action>) => {
	api
		.getDashboardTotalRevenue()
		.then((res) => {
			dispatch({
				type: SAVE_TOTAL_REVENUE,
				payload: res.data.body,
			});
		})
		.catch((err) => errorHandler(err));
};

export const saveDashboardCashInflow = () => (dispatch: Dispatch<Action>) => {
	api
		.getDashboardCashInflow()
		.then((res) => {
			dispatch({
				type: SAVE_CASH_INFLOW,
				payload: res.data.body,
			});
		})
		.catch((err) => errorHandler(err));
};

export const saveOverdueInvoices = () => (dispatch: Dispatch<Action>) => {
	api
		.getOverdueInvoices()
		.then((res) => {
			dispatch({
				type: SAVE_OVERDUE_INVOICES,
				payload: res.data.body.content,
				size: res.data.body.totalElements
			});
		})
		.catch((err) => errorHandler(err));
};

export const saveOpenInvoices = () => (dispatch: Dispatch<Action>) => {
	api
		.getOpenInvoices()
		.then((res) => {
			dispatch({
				type: SAVE_OPEN_INVOICES,
				payload: res.data.body.content,
				size: res.data.body.totalElements,
			});
		})
		.catch((err) => errorHandler(err));
};
export const saveOverdueStatistic = () => (dispatch: Dispatch<Action>) => {
	api
		.getOverdueStatistic()
		.then((res) => {
			dispatch({
				type: SAVE_OVERDUE_STATISTIC,
				payload: res.data.body,
			});
		})
		.catch((err) => errorHandler(err));
};
