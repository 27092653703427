import { Box, SelectChangeEvent, TextField } from '@mui/material'
import { ReactNode, useEffect } from 'react'
import { validateName, validatePrice } from '../../helpers/utils'
import { IFinancialAccount, INewPurchaseProduct, IPurchaseProduct } from '../../types/types'
import SelectorMui from '../../components/selector-mui'
import { useDispatch, useSelector } from 'react-redux'
import { saveExpensesAccounts } from '../../redux/actions/home.actions'
import { AppState } from '../../redux/store'

interface IProductViewProps {
  selectedProduct?: IPurchaseProduct | null
  currentProduct: INewPurchaseProduct
  setCurrentProduct: (product: INewPurchaseProduct) => void
  formErrors: any
  setFormErrors: (formErrors: any) => void
}

const ProductView = (props: IProductViewProps) => {
  const { selectedProduct, currentProduct, setCurrentProduct, setFormErrors, formErrors } = props
  const dispatch = useDispatch()
  const expensesAccounts = useSelector((state: AppState) => state.homeReducer.expensesAccounts)

  useEffect(() => {
    if (selectedProduct) {
      setCurrentProduct({
        ...selectedProduct,
        name: selectedProduct.name,
        price: selectedProduct.price,
        description: selectedProduct.description,
        accountPublicId: selectedProduct.account?.publicId,
      })
    } else {
      setCurrentProduct({
        name: '',
        price: 0,
        description: '',
        accountPublicId: '',
      })
    }
  }, [selectedProduct])

  useEffect(() => {
    dispatch(saveExpensesAccounts())
  }, [])

  return (
    <Box component='form' my={2} mx={3}>
      <Box mb={3}>
        <TextField
          error={!!formErrors['name']}
          helperText={formErrors['name']}
          required
          id='outlined-required'
          label='Name'
          placeholder='Enter the product or service name'
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          value={currentProduct?.name}
          onChange={(e) => {
            setCurrentProduct({ ...currentProduct, name: e.target.value })
            validateName(e.target.value, 'name', setFormErrors, 'Product Name')
          }}
        />
      </Box>
      <Box mb={3}>
        <TextField
          id='outlined-required'
          label='Description'
          placeholder='Enter a description'
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          multiline
          rows={4}
          value={currentProduct?.description}
          onChange={(e) =>
            setCurrentProduct({
              ...currentProduct,
              description: e.target.value,
            })
          }
        />
      </Box>
      <Box mb={3}>
        <TextField
          error={!!formErrors['price']}
          helperText={formErrors['price']}
          type='number'
          InputProps={{
            inputProps: { min: 0 },
          }}
          onKeyPress={(event) => {
            if (event?.key === '-' || event?.key === '+') {
              event.preventDefault()
            }
          }}
          required
          placeholder='$ 0.00'
          id='outlined-required'
          label='Price'
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          value={currentProduct?.price}
          onChange={(e) => {
            setCurrentProduct({ ...currentProduct, price: Number(e.target.value) })
            validatePrice(e.target.value, setFormErrors)
          }}
        />
      </Box>
      <Box mb={3}>
        <SelectorMui
          label='Expenses account'
          placeholder='Select an account'
          defaultItem='None'
          fullWidth
          error={!!formErrors['accountPublicId']}
          helperText={formErrors['accountPublicId']}
          options={
            expensesAccounts
            ?.filter((account: IFinancialAccount) => account.active)
            .map((account: IFinancialAccount) => {
              return {
                value: account.publicId || '',
                title: account.name,
              }
            }) || []
          }
          onBlur={() => {
            setFormErrors((prevState: any) => {
              return {
                ...prevState,
                accountPublicId: !currentProduct.accountPublicId
                  ? 'Should select at least one account'
                  : '',
              }
            })
          }}
          onChange={(e: SelectChangeEvent, child: ReactNode) => {
            setFormErrors((prevState: any) => {
              return {
                ...prevState,
                accountPublicId: !e.target.value ? 'Should select at least one account' : '',
              }
            })
            setCurrentProduct({ ...currentProduct, accountPublicId: e.target.value })
          }}
          value={currentProduct.accountPublicId}
          sx={{ mb: 1 }}
        />
      </Box>
    </Box>
  )
}

export default ProductView
