import {
  Alert,
  IconButton,
  Link,
  Tooltip,
  TooltipProps,
  Typography,
  tooltipClasses,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { SHOW_USIO_NOTIFICATION } from '../../redux/actions/auth.actions.type'
import { AppState } from '../../redux/store'
import { useEffect } from 'react'
import { saveUSIOLink } from '../../redux/actions/auth.actions'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { styled } from '@mui/styles'

const USIONotification = ({ closable = true, message = '' }: { closable?: boolean, message? : string }) => {
  const dispatch = useDispatch()
  const link = useSelector((state: AppState) => state.authReducer.usioLink)
  const show = useSelector((state: AppState) => state.authReducer.showUSIONotification)

  const handleCloseNotification = () => {
    dispatch({
      type: SHOW_USIO_NOTIFICATION,
      payload: false,
    })

    setTimeout(() => {
      dispatch({
        type: SHOW_USIO_NOTIFICATION,
        payload: true,
      })
    }, 900000)
  }

  useEffect(() => {
    dispatch(saveUSIOLink())
  }, [])

  const DarkerTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'var(--secondary-grey)',
    },
  }))

  return show ? (
    <Alert
      severity='warning'
      onClose={closable ? handleCloseNotification : undefined}
      sx={{
        fontWeight: 600,
        '& .MuiAlert-message, & .MuiAlert-action': { color: 'var(--warning-color)' },
        '& .MuiAlert-icon': { alignItems: 'center', color: 'var(--warning-color)' },
      }}
    >
      {message} {' '}
      <Link href={link} rel='noreferrer' target='_blank' underline='none'>
        USIO Enrollment Form
      </Link>
      .
      <DarkerTooltip
        title={
          <>
            <Typography>
              <strong>Usio</strong> is a technology designed to streamline payment acceptance and
              secure funds disbursement. To start receiving payments, you must first register on
              their platform.
            </Typography>
            <Typography>
              Please use legal names and the registered business address for instant approval.
            </Typography>
          </>
        }
      >
        <HelpOutlineIcon color='primary' />
      </DarkerTooltip>
    </Alert>
  ) : null
}

export default USIONotification
