import { ReactInstance, SyntheticEvent, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { changeIsLoading, saveEstimates } from '../../redux/actions/home.actions'
import ContentHeader from '../../components/content-header'
import PaginationNew from '../../components/pagination-new'
import { AppState } from '../../redux/store'
import './estimates.css'
import NoDataDisplay from '../../components/noDataDisplay/noDataDisplay'
import ListFilters from '../../components/list-filters'
import EstimatesTable from './estimatesTable'
import { IEstimate } from '../../types/types'
import api from '../../api/api'
import { errorHandler } from '../../helpers/errorHandler'
import ConfirmDialog from '../../components/dialog/dialog'
import PlusButton from '../../components/plus-button/plus-button'
import { Box } from '@mui/material'
import PreviewNew from '../../components/preview-new'
import { useReactToPrint } from 'react-to-print'
import { downloadPDF } from '../../helpers/downloadPdf'
import { isUsingMobile } from '../../helpers/utils'
import ReferenceMenu from './reference-menu'
import EstimateModal from './modal/estimateModal'
import useAuthorization from '../../helpers/useAuthorization'
import { successToastNotification } from '../../helpers/toastNotification'

const Estimates = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isAuthorized } = useAuthorization()
  const [selectedFilter, setSelectedFilter] = useState('')
  const [estimatesPage, setEstimatesPage] = useState(1)
  const [selectedEstimate, setSelectedEstimate] = useState<IEstimate>()
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false)
  const [isOpenConvertDialog, setIsOpenConvertDialog] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const preview = useRef<ReactInstance | null>(null)
  const isMobile = isUsingMobile()

  const estimatesAmount = useSelector((state: AppState) => state.homeReducer.estimatesSize)

  const estimates = useSelector((state: AppState) => state.homeReducer.estimates)

  useEffect(() => {
    dispatch(saveEstimates())
  }, [dispatch])

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setEstimatesPage(value)
    dispatch(saveEstimates(value - 1))
  }

  const handleSelectedFilterChange = (event: SyntheticEvent, filter: string) => {
    setSelectedFilter(filter)
    setEstimatesPage(1)
    dispatch(saveEstimates(0, filter))
  }

  const handleEditClick = () => {
    if (!selectedEstimate) {
      return
    }

    navigate(`/estimates/add/${selectedEstimate.publicId}/edit`)
    setSelectedEstimate(undefined)
  }

  const handleDuplicateClick = () => {
    if (!selectedEstimate) {
      return
    }

    api
      .duplicateEstimate(selectedEstimate.publicId)
      .then(() => {
        successToastNotification('Estimate was successfully duplicated')
        dispatch(saveEstimates(estimatesPage - 1, selectedFilter))
      })
      .catch((err) => errorHandler(err))

    setSelectedEstimate(undefined)
  }

  const handlePrintClick = () => {
    print()
  }

  const handleConvertClick = () => {
    setIsOpenConvertDialog(true)
  }

  const handleSendClick = () => {
    if (!selectedEstimate) {
      return
    }

    api
      .sendEstimate(selectedEstimate.publicId, selectedEstimate.customerEmail)
      .then(() => {
        dispatch(saveEstimates(estimatesPage - 1, selectedFilter))
        successToastNotification(
          `Estimate successfully sent on the ${selectedEstimate.customerEmail}`,
        )
      })
      .catch((err) => errorHandler(err))
  }

  const handleExportAsPDFClick = () => {
    downloadPDF(preview, 'estimate')
  }

  const print = useReactToPrint({
    content: () => preview?.current,
    onBeforePrint: () => {
      dispatch(changeIsLoading(true))
    },
    onAfterPrint: () => {
      dispatch(changeIsLoading(false))
    },
  })

  const handleDeleteClick = () => {
    setIsOpenDeleteDialog(true)
  }

  const handleDeleteConfirm = () => {
    if (!selectedEstimate) {
      return
    }

    setIsOpenDeleteDialog(false)

    api
      .deleteEstimate(selectedEstimate.publicId)
      .then(() => {
        successToastNotification('Estimate deleted')
        dispatch(saveEstimates(estimatesPage - 1, selectedFilter))
      })
      .catch((err: any) => errorHandler(err))

    setSelectedEstimate(undefined)
  }

  const handleConvertConfirm = () => {
    if (!selectedEstimate) {
      return
    }

    setIsOpenConvertDialog(false)

    api
      .convertEstimateToInvoice(selectedEstimate.publicId)
      .then((res) => {
        setIsOpenConvertDialog(false)
        successToastNotification('An estimate was successfully converted to an invoice')
        navigate('/invoices')
      })
      .catch((err) => {
        setIsOpenConvertDialog(false)
        errorHandler(err)
      })

    setSelectedEstimate(undefined)
  }

  const handleViewClick = (estimate: IEstimate) => {
    setShowModal(true)
    setSelectedEstimate(estimate)
  }

  const getCustomerData = () => {
    if (!selectedEstimate) {
      return
    }

    return {
      name: selectedEstimate.customerName,
      primaryFirstName: selectedEstimate.primaryFirstName,
      primaryLastName: selectedEstimate.primaryLastName,
      primaryPhoneNumber: selectedEstimate.primaryPhoneNumber,
      billingAddress: selectedEstimate.billingAddress,
      billingAddressTwo: selectedEstimate.billingAddressTwo,
      billingCity: selectedEstimate.billingCity,
      billingCountry: selectedEstimate.billingCountry,
      billingPostalCode: selectedEstimate.billingPostalCode,
      billingState: selectedEstimate.billingState,
      website: selectedEstimate.website,
    }
  }

  return (
    <>
      <ContentHeader title={<Box>Estimates {isMobile && <ReferenceMenu />}</Box>} />

      <ListFilters
        filters={[
          { title: 'All', value: '' },
          { title: 'Saved', value: 'SAVED' },
          { title: 'Sent', value: 'SENT' },
          { title: 'Expired', value: 'EXPIRED' },
        ]}
        selectedFilter={selectedFilter}
        onSelectedFilterChange={handleSelectedFilterChange}
      />

      <Box pb={4}>
        {estimates.length > 0 ? (
          <EstimatesTable
            estimates={estimates}
            onRowClick={handleViewClick}
            onSelectedEstimate={setSelectedEstimate}
            onEditClick={handleEditClick}
            onDuplicateClick={handleDuplicateClick}
            onPrintClick={handlePrintClick}
            onConvertClick={handleConvertClick}
            onSendClick={handleSendClick}
            onExportAsPDFClick={handleExportAsPDFClick}
            onDeleteClick={handleDeleteClick}
          />
        ) : (
          <NoDataDisplay mt='10rem' title='No data to display' />
        )}
        {estimatesAmount > 15 || estimatesPage > 1 ? (
          <PaginationNew
            size={15}
            quantity={estimatesAmount}
            page={estimatesPage}
            handleChange={handleChangePage}
          />
        ) : null}
      </Box>

      <div style={{ display: 'none' }}>
        {selectedEstimate && (
          <PreviewNew item={selectedEstimate} preview={preview} customer={getCustomerData()} />
        )}
      </div>
      {isAuthorized('write_estimate') && (
        <PlusButton tooltipText='Add Estimate' handleOnClick={() => navigate('/estimates/add')} />
      )}
      <ConfirmDialog
        isOpen={isOpenDeleteDialog}
        dialogTitle='Sure you want to delete?'
        dialogBodyContent='Deleting this item will permanently remove it from your account, and it cannot be undone.'
        buttonConfirmText='Yes, Delete'
        buttonCancelText='Cancel'
        handleClose={() => setIsOpenDeleteDialog(false)}
        handleConfirm={handleDeleteConfirm}
      />
      <ConfirmDialog
        isOpen={isOpenConvertDialog}
        dialogTitle='Convert an estimate to an invoice'
        dialogBodyContent='Convert this estimate to a draft invoice?'
        buttonConfirmText='Yes, Convert'
        buttonCancelText='Cancel'
        handleClose={() => setIsOpenConvertDialog(false)}
        handleConfirm={handleConvertConfirm}
      />

      {selectedEstimate && (
        <EstimateModal
          showModal={showModal}
          onEditClick={handleEditClick}
          setShowModal={setShowModal}
          onDuplicateClick={handleDuplicateClick}
          onConvertToInvoiceClick={handleConvertClick}
          onDeleteClick={handleDeleteClick}
          onPrintClick={handlePrintClick}
          onExportAsPDFClick={handleExportAsPDFClick}
          onSendClick={handleSendClick}
          estimate={selectedEstimate}
        />
      )}
    </>
  )
}

export default Estimates
