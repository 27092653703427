import { useEffect, useState } from 'react'
import { saveCustomers } from '../../redux/actions/home.actions'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AppState } from '../../redux/store'
import PaginationNew from '../../components/pagination-new'
import ContentHeader from '../../components/content-header'
import PlusButton from '../../components/plus-button/plus-button'
import CustomersTable from './customersTable'
import { ICustomer } from '../../types/types'
import AddCardDialog from '../../components/add-card-dialog'
import ConfirmDialog from '../../components/dialog/dialog'
import { toast } from 'react-toastify'
import { errorHandler } from '../../helpers/errorHandler'
import api from '../../api/api'
import NoDataDisplay from '../../components/noDataDisplay/noDataDisplay'
import { Box } from '@mui/material'
import CustomerManageDialog from '../estimate-manage/customer-manage-dialog'
import useAuthorization from '../../helpers/useAuthorization'

const Customers = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isAuthorized } = useAuthorization()
  const [customersPage, setCustomersPage] = useState(1)
  const [isAddCardDialogOpen, setIsAddCardDialogOpen] = useState<boolean>(false)
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomer>()
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [customer, setCustomer] = useState<ICustomer>()

  const customers = useSelector((state: AppState) => state.homeReducer.customers)
  const customersAmount = useSelector((state: AppState) => state.homeReducer.customersSize)

  useEffect(() => {
    dispatch(saveCustomers())
  }, [dispatch])

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setCustomersPage(value)
    dispatch(saveCustomers(value - 1))
  }

  const handleViewClick = (customer: ICustomer) => {
    navigate(`/customers/${customer.publicId}/view`, {
      state: {
        customer,
      },
    })
  }

  const handleEditClick = () => {
    selectedCustomer && navigate(`/customers/${selectedCustomer.publicId}/edit`)
  }

  const handleCreateInvoiceClick = () => {
    selectedCustomer &&
      navigate('/invoices/add', {
        state: {
          customer: selectedCustomer,
        },
      })
  }

  const handleDeleteConfirm = () => {
    if (!selectedCustomer) {
      return
    }

    setIsDeleteDialogOpen(false)

    api
      .deleteCustomer(selectedCustomer.publicId)
      .then(() => {
        toast.success(`${selectedCustomer.name} was successfully deleted`, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        })
        dispatch(saveCustomers())
      })
      .catch((err) => errorHandler(err))

    setSelectedCustomer(undefined)
  }

  return (
    <>
      <ContentHeader title='Customers' />
      {customers.length > 0 ? (
        <Box pb={4}>
          <CustomersTable
            customers={customers}
            onEditClick={handleEditClick}
            onAddCreditCardClick={() => setIsAddCardDialogOpen(true)}
            onCreateInvoiceClick={handleCreateInvoiceClick}
            onRowClick={handleViewClick}
            onDeleteClick={() => setIsDeleteDialogOpen(true)}
            onSelectedCustomer={setSelectedCustomer}
            setCustomer={setCustomer}
          />
          {customersAmount > 15 ? (
            <PaginationNew
              size={15}
              quantity={customersAmount}
              page={customersPage}
              handleChange={handleChangePage}
            />
          ) : null}
        </Box>
      ) : (
        <NoDataDisplay mt='10rem' title='No data to display' />
      )}

      {isAuthorized('write_customer') && (
        <PlusButton tooltipText='Add new customer' handleOnClick={() => setIsDialogOpen(true)} />
      )}

      <AddCardDialog open={isAddCardDialogOpen} customer={customer} onClose={() => setIsAddCardDialogOpen(false)} />

      <CustomerManageDialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} />

      <ConfirmDialog
        isOpen={isDeleteDialogOpen}
        dialogTitle='Sure you want to delete?'
        dialogBodyContent='Deleting this item will permanently remove it from your account, and it cannot be undone.'
        buttonConfirmText='Yes, Delete'
        buttonCancelText='Cancel'
        handleClose={() => setIsDeleteDialogOpen(false)}
        handleConfirm={handleDeleteConfirm}
      />
    </>
  )
}

export default Customers
