import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Stack, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { ReactElement } from 'react';
import { isUsingMobile } from '../../helpers/utils';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

interface IModalProps {
	title?: string;
	footer?: ReactElement;
	headerButtons?: ReactElement;
	body: ReactElement;
	isModalOpen: boolean;
	handleCloseModal: (bool: boolean) => void;
	size?: string;
	floatingFooter?: boolean;
	sx?: any
}

const BaseModal = ({
	title,
	footer,
	body,
	isModalOpen,
	handleCloseModal,
	headerButtons,
	floatingFooter = false,
	size = 'small',
	sx
}: IModalProps) => {
	const handleClose = () => handleCloseModal(false);
	const isMobile = isUsingMobile();

	const getWidth = () => {
		if(isMobile) {
			return '100%';
		}

		switch (size) {
			case 'small':
				return '40%';
			case 'large':
				return '50%';
			case 'xs':
				return '100%';
		}
	};

	return (
		<Modal
			open={isModalOpen}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<>
				<Box
					sx={{
						position: 'absolute',
						right: 0,
						left: { xs: 0, md: 'auto' },
						width: { xs: '100%', md: getWidth() },
						height: { xs: '100%', md: '100vh' },
						bgcolor: 'background.paper',
						boxShadow: 24,
						paddingTop: '2rem',
						pb: { xs: '1rem', sm: 0 },
						paddingRight: 0,
						overflow: 'auto',
						...sx
					}}
				>
					<Stack direction="row" spacing={2} pr={'20px'} alignItems='center'>
						{!isMobile && (
							<Typography
								style={{ paddingLeft: '24px'}}
								id="modal-modal-title"
								variant="h6"
								component="h2"
								flexGrow={2}
								fontWeight={600}
								color="var(--secondary-grey)"
							>
								{title}
							</Typography>
						)}

						{isMobile && (
							<Box sx={{flexDirection:"column", flexGrow: 2}}>
								<Box sx={{ flexGrow: 2, justifyContent: 'left' }}>
									<Tooltip title="Back">
										<Button aria-label="back" onClick={handleClose}>
											<ChevronLeftIcon />
										</Button>
									</Tooltip>
								</Box>
								<Typography
									style={{ marginLeft: '1.5rem', marginBottom: '1rem', marginTop: '1rem' }}
									id="modal-modal-title"
									variant="h6"
									component="h2"
									flexGrow={2}
									fontWeight={600}
									color="var(--secondary-grey)"
								>
									{title}
								</Typography>
							</Box>
						)}
						{headerButtons}
						{!isMobile && (
							<Tooltip title="Close">
								<IconButton
									aria-label="close"
									onClick={handleClose}
									sx={{
										color: 'red',
										background: 'pink',
									}}
								>
									<CloseIcon />
								</IconButton>
							</Tooltip>
						)}
					</Stack>
					<Box pb={{ xs: '120px', sm: 0 }}>{body}</Box>
				</Box>

				<Box
						className="modal-footer"
						sx={{
							position: 'absolute',
							bottom: floatingFooter ? { xs: 0, sm: 'auto' } : 0,
							boxShadow: '0px -1px 6px 0px rgba(0,0,0,0.25)',
							backgroundColor: '#fff',
							width: getWidth(),
							right: 0,
							zIndex: 1
						}}
					>
					{footer}
				</Box>
			</>
		</Modal>
	);
};

export default BaseModal;
