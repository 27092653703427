import { useState, useEffect } from 'react'
import './registration-field.css'

interface IRegistrationField {
  placeholder?: string
  value?: string | number
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onIconClick?: any
  type?: string
  withIcon?: boolean
  iconPosition?: string
  icon?: string
  disabled?: boolean
  successMessage?: string
  errorMessage?: string
  validataionSchema?: boolean
  styles?: string
  name?: string
}

const RegistrationField = (props: IRegistrationField) => {
  const {
    value,
    placeholder,
    styles,
    withIcon,
    icon,
    type,
    iconPosition,
    disabled,
    successMessage,
    errorMessage,
    onIconClick,
    validataionSchema,
    onChange,
    name,
  } = props
  const [highlightInput, setHighlightInput] = useState<boolean>(false)
  useEffect(() => {
    setHighlightInput(false)
  }, [])
  const renderHightlightedStyles = () => {
    if (highlightInput) {
      return validataionSchema ? 'text-field__wrapper-success' : 'text-field__wrapper-error'
    } else {
      ;('text-field__wrapper-inactive')
    }
  }
  return (
    <label className='text-field__label'>
      <input
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={`text-field__wrapper ${styles} ${renderHightlightedStyles()}`}
        disabled={disabled}
        type={type}
        onBlur={() => setHighlightInput(true)}
        name={name}
      />
      {withIcon ? (
        <img
          src={icon}
          onClick={onIconClick}
          className={`registration-text-field__icon ${
            iconPosition === 'start' ? 'text-field__icon-start' : 'text-field__icon-end'
          }`}
        />
      ) : null}
      {highlightInput ? (
        <div
          className='text-input-message'
          style={{
            color: validataionSchema ? 'rgba(64, 129, 51, 1)' : '#ba0000',
          }}
        >
          {validataionSchema ? successMessage : errorMessage}
        </div>
      ) : null}
    </label>
  )
}

export default RegistrationField
