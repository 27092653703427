import { SyntheticEvent, useEffect, useState } from 'react'
import BaseModal from '../../../components/modal/modal'
import { IInvoice } from '../../../types/types'
import InvoicePreview from '../preview/invoicePreview'
import InvoiceModalFooter from './footer'
import InvoiceModalHeaderButtons from './headerButtons'
import InvoiceDetails from '../invoiceDetails'
import { Tab, Tabs } from '@mui/material'
import { isUsingMobile } from '../../../helpers/utils'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../redux/store'
import { saveCertainCustomer } from '../../../redux/actions/home.actions'
import { SAVE_CERTAIN_INVOICE } from '../../../redux/actions/home.actions.type'

interface IInvoiceModalProps {
  invoice: IInvoice
  showModal: boolean
  setShowModal: (showModal: boolean) => void
  onEditClick?: () => void
  onDuplicateClick?: () => void
  onRecordPaymentClick?: () => void
  onShareLinkClick?: () => void
  onDeleteClick?: () => void
  onPrintClick?: () => void
  onSendClick?: () => void
  onExportAsPDFClick?: () => void
}

const InvoiceModal = ({
  invoice,
  showModal,
  setShowModal,
  onEditClick,
  onDuplicateClick,
  onRecordPaymentClick,
  onShareLinkClick,
  onDeleteClick,
  onPrintClick,
  onSendClick,
  onExportAsPDFClick,
}: IInvoiceModalProps) => {
  const dispatch = useDispatch()
  const company = useSelector((state: AppState) => state.homeReducer.company)
  const customer = useSelector((state: AppState) => state.homeReducer.chosenCustomer)

  const [selectedTab, setSelectedTab] = useState('view')
  const handleCloseModal = () => {
    setShowModal(false)
    dispatch({
      type: SAVE_CERTAIN_INVOICE,
      payload: null,
    })
  }

  const handleSelectedTabChange = (event: SyntheticEvent, tab: string) => {
    setSelectedTab(tab)
  }

  useEffect(() => {
    dispatch(saveCertainCustomer(invoice.customerPublicId))
  }, [invoice.customerPublicId])

  return (
    <BaseModal
      title={`Invoice #${invoice.name}`}
      isModalOpen={showModal}
      handleCloseModal={handleCloseModal}
      body={
        <>
          {isUsingMobile() && (
            <Tabs
              aria-label='invoice views'
              value={selectedTab}
              scrollButtons='auto'
              variant='scrollable'
              onChange={handleSelectedTabChange}
            >
              <Tab label='View' value='view' sx={{ flexGrow: 1 }} />
              <Tab label='Detail' value='detail' sx={{ flexGrow: 1 }} />
            </Tabs>
          )}
          {selectedTab === 'view' ? (
            customer && <InvoicePreview invoice={invoice} company={company} customer={customer} />
          ) : (
            <InvoiceDetails
              invoice={invoice}
              onEditClick={onEditClick}
              onSendClick={onSendClick}
              onRecordPaymentClick={onRecordPaymentClick}
            />
          )}
        </>
      }
      headerButtons={
        <InvoiceModalHeaderButtons
          invoice={invoice}
          actions={{
            onPrintClick,
            onExportAsPDFClick,
            onEditClick,
            onDuplicateClick,
            onRecordPaymentClick,
            onShareLinkClick,
            onDeleteClick,
          }}
        />
      }
      footer={
        <InvoiceModalFooter
          onCancelClick={handleCloseModal}
          onSendClick={onSendClick}
          selectedTab={selectedTab}
          onSelectedTabChange={(evt, value) => setSelectedTab(value)}
        />
      }
      size='large'
    />
  )
}

export default InvoiceModal
