import { Action } from '../actions/actionTypes'
import {
  SAVE_PRODUCTS,
  CREATE_PRODUCT,
  CREATE_CUSTOMER,
  SAVE_CUSTOMERS,
  SAVE_CERTAIN_CUSTOMER,
  SAVE_ESTIMATES,
  CREATE_ESTIMATE,
  SAVE_CERTAIN_ESTIMATE,
  CREATE_INVOICE,
  SAVE_INVOICES,
  SAVE_CERTAIN_INVOICE,
  SAVE_COMPANY,
  SAVE_PAYMENTS,
  SAVE_OUTSTANDING_DATA,
  SAVE_OUTSTANDING_STATEMENTS,
  SAVE_ACTIVITY_STATEMENTS,
  CREATE_SCHEDULED_TEMPLATE,
  SAVE_SCHEDULED_TEMPLATES,
  SAVE_CERTAIN_TEMPLATE,
  CHANGE_IS_LOADING,
  PAY_NOW_UNAVAILABLE,
  SAVE_INVOICES_STATISTICS,
  SAVE_PURCHASE_PRODUCTS,
  SAVE_EXPENSES_ACCOUNTS,
  CREATE_PURCHASE_PRODUCT,
  SAVE_FINANCIAL_ACCOUNT,
  SAVE_FINANCIAL_ACCOUNT_TYPES,
  SAVE_ALL_FINANCIAL_ACCOUNT_TYPES,
  SAVE_VENDORS,
  SAVE_REVENUE_ACCOUNTS,
  SAVE_BILLS,
  SAVE_CERTAIN_BILL,
  SAVE_JOURNAL_ENTRIES,
  SAVE_CERTAIN_JOURNAL,
} from '../actions/home.actions.type'
import {
  ICompany,
  ICustomer,
  IEstimate,
  IProduct,
  IInvoice,
  IPayment,
  IOutstandingData,
  IOutstandingStatement,
  IActivityStatement,
  IPurchaseProduct,
  IBill,
  IJournalEntry,
  INewJournalEntry,
} from '../../types/types'

export interface IInitialState {
  products: IProduct[] | []
  customers: ICustomer[] | []
  chosenCustomer: ICustomer | null
  chosenEstimate: IEstimate | null
  chosenInvoice: any
  estimatesSize: number
  estimates: IEstimate[]
  company: null | ICompany
  invoices: IInvoice[] | []
  invoicesSize: number
  customersSize: number
  paymentsSize: number
  scheduledInvoiceSize: number
  productsSize: number
  payments: IPayment[] | []
  outstandingData: IOutstandingData | null
  outstandingStatements: IOutstandingStatement | null
  activityStatements: IActivityStatement | null
  scheduledInvoices: any
  chosenScheduledInvoice: any
  isLoading: boolean
  payNowUnavailable: boolean
  invoicesStatistics: any
  purchaseProducts: IPurchaseProduct[] | []
  financialAccount: any
  financialAccountTypes: any
  allFinancialAccountTypes: any
  expensesAccounts: any
  revenueAccounts: any
  vendors: any
  bills: IBill[]
  billsSize: number
  chosenBill: IBill | null
  journalEntries: IJournalEntry[]
  journalEntriesSize: number
  chosenJournal: INewJournalEntry | null
}

const initialState: IInitialState = {
  products: [],
  customers: [],
  chosenCustomer: null,
  chosenEstimate: null,
  chosenInvoice: null,
  estimatesSize: 0,
  estimates: [],
  invoicesSize: 0,
  paymentsSize: 0,
  scheduledInvoiceSize: 0,
  productsSize: 0,
  invoices: [],
  company: null,
  customersSize: 0,
  payments: [],
  outstandingData: null,
  outstandingStatements: null,
  activityStatements: null,
  scheduledInvoices: [],
  chosenScheduledInvoice: null,
  isLoading: false,
  payNowUnavailable: false,
  invoicesStatistics: null,
  purchaseProducts: [],
  financialAccount: null,
  financialAccountTypes: null,
  allFinancialAccountTypes: null,
  expensesAccounts: [],
  revenueAccounts: [],
  vendors: null,
  bills: [],
  billsSize: 0,
  chosenBill: null,
  journalEntries: [],
  journalEntriesSize: 0,
  chosenJournal: null,
}

const homeReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case SAVE_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        productsSize: action.size,
      }
    case CREATE_PRODUCT:
      return {
        ...state,
        products: [action.payload, ...state.products],
      }
    case SAVE_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
        customersSize: action.size,
      }
    case CREATE_CUSTOMER:
      return {
        ...state,
        chosenCustomer: action.payload,
        customers: [action.payload, ...state.customers],
      }
    case SAVE_CERTAIN_CUSTOMER:
      return {
        ...state,
        chosenCustomer: action.payload,
      }
    case SAVE_CERTAIN_ESTIMATE:
      return {
        ...state,
        chosenEstimate: action.payload,
      }
    case CREATE_ESTIMATE:
      return {
        ...state,
        estimates: [action.payload, ...state.estimates],
      }
    case SAVE_ESTIMATES:
      return {
        ...state,
        estimates: action.payload,
        estimatesSize: action.size,
      }
    case CREATE_INVOICE:
      return {
        ...state,
        invoices: [action.payload, ...state.invoices],
      }
    case SAVE_INVOICES:
      return {
        ...state,
        invoices: action.payload,
        invoicesSize: action.size,
      }
    case SAVE_CERTAIN_INVOICE: {
      return {
        ...state,
        chosenInvoice: action.payload,
      }
    }
    case SAVE_COMPANY: {
      return {
        ...state,
        company: action.payload,
      }
    }
    case SAVE_PAYMENTS: {
      return {
        ...state,
        payments: action.payload,
        paymentsSize: action.size,
      }
    }
    case PAY_NOW_UNAVAILABLE: {
      return {
        ...state,
        payNowUnavailable: action.payload,
      }
    }
    case SAVE_OUTSTANDING_DATA: {
      return {
        ...state,
        outstandingData: action.payload,
      }
    }
    case SAVE_SCHEDULED_TEMPLATES: {
      return {
        ...state,
        scheduledInvoices: action.payload,
        scheduledInvoiceSize: action.size,
      }
    }
    case SAVE_CERTAIN_TEMPLATE: {
      return {
        ...state,
        chosenScheduledInvoice: action.payload,
      }
    }
    case CREATE_SCHEDULED_TEMPLATE: {
      return {
        ...state,
        scheduledInvoices: [action.payload, ...state.scheduledInvoices],
      }
    }
    case CHANGE_IS_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      }
    }
    case SAVE_INVOICES_STATISTICS: {
      return {
        ...state,
        invoicesStatistics: action.payload,
      }
    }
    case SAVE_OUTSTANDING_STATEMENTS: {
      return {
        ...state,
        outstandingStatements: action.payload,
      }
    }
    case SAVE_ACTIVITY_STATEMENTS: {
      return {
        ...state,
        activityStatements: action.payload,
      }
    }
    case SAVE_PURCHASE_PRODUCTS:
      return {
        ...state,
        purchaseProducts: action.payload,
        purchaseProductsSize: action.size,
      }
    case SAVE_EXPENSES_ACCOUNTS:
      return {
        ...state,
        expensesAccounts: action.payload,
      }
    case CREATE_PURCHASE_PRODUCT:
      return {
        ...state,
        purchaseProducts: [action.payload, ...state.purchaseProducts],
      }
    case SAVE_FINANCIAL_ACCOUNT: {
      return {
        ...state,
        financialAccount: action.payload,
      }
    }
    case SAVE_FINANCIAL_ACCOUNT_TYPES: {
      return {
        ...state,
        financialAccountTypes: action.payload,
      }
    }
    case SAVE_ALL_FINANCIAL_ACCOUNT_TYPES: {
      return {
        ...state,
        allFinancialAccountTypes: action.payload,
      }
    }
    case SAVE_VENDORS: {
      return {
        ...state,
        vendors: action.payload,
      }
    }
    case SAVE_REVENUE_ACCOUNTS:
      return {
        ...state,
        revenueAccounts: action.payload,
      }
    case SAVE_BILLS: {
      return {
        ...state,
        bills: action.payload,
        billsSize: action.size
      }
    }
    case SAVE_CERTAIN_BILL: {
      return {
        ...state,
        chosenBill: action.payload,
      }
    }
    case SAVE_JOURNAL_ENTRIES: {
      return {
        ...state,
        journalEntries: action.payload,
        journalEntriesSize: action.size
      }
    }
    case SAVE_CERTAIN_JOURNAL: {
      return {
        ...state,
        chosenJournal: action.payload,
      }
    }
    default:
      return state
  }
}

export default homeReducer
