import { useState, useEffect } from 'react';
import ContentHeader from '../../components/content-header';
import { useDispatch } from 'react-redux';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { saveAllUsers } from '../../redux/actions/auth.actions';
import { saveRoles } from '../../redux/actions/settings.actions';
import BusinessInformation from './business-information';
import PersonalInformation from './personal-information';
import PageTab from '../../components/page-tab';
import Users from './users';
import './profile.css';

const Profile = () => {
	const [chosenTab, setChosenTab] = useState<any>('business');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const locationTab = location.pathname.split('/').pop();
	useEffect(() => {
		dispatch(saveRoles());
		dispatch(saveAllUsers());
		setChosenTab(locationTab);
	}, []);

	const handleChangeTab = (tab: string) => {
		setChosenTab(tab);
		navigate(`/profile/${tab}`);
	};
	return (
		<>
			<ContentHeader title="Profile" />
			<div className="profile-tabs">
				<PageTab
					title="Business Information"
					description="View and update important details about your business, such as contact information, address, and other relevant information."
					isChosen={chosenTab === 'business'}
					onClick={handleChangeTab.bind(null, 'business')}
				/>
				<PageTab
					title="Personal Information"
					description="Manage your personal information and change your account password for secure access to Blue Cimarron."
					isChosen={chosenTab === 'personal-information'}
					onClick={handleChangeTab.bind(null, 'personal-information')}
				/>
				<PageTab
					title="Users"
					description="Manage the users who have permissions and access to your business account."
					isChosen={chosenTab === 'users'}
					onClick={handleChangeTab.bind(null, 'users')}
				/>
			</div>
			<div className="profile-content__wrapper">
				<Routes>
					<Route path="business" element={<BusinessInformation />} />
					<Route
						path="personal-information"
						element={<PersonalInformation />}
					/>
					<Route path="users" element={<Users />} />
				</Routes>
			</div>
		</>
	);
};

export default Profile;
