import { MouseEvent, useCallback, useMemo, useState } from 'react'
import { IInvoice } from '../../types/types'
import { DataGrid, GridColDef, GridRowSpacingParams } from '@mui/x-data-grid'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { isUsingMobile } from '../../helpers/utils'
import { Box, Button, Chip, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import TableStyles from '../../components/table-card/table-styles'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { formatDate, formatDateFromNow, formatMoney } from '../../helpers/formatPrice'
import useAuthorization from '../../helpers/useAuthorization'

interface InvoicesTableProps {
  invoices: IInvoice[]
  onSelectedInvoice?: (invoice: IInvoice | undefined) => void
  onPreviewClick?: () => void
  onDuplicateClick?: () => void
  onEditClick?: () => void
  onRecordPaymentClick?: () => void
  onSendClick?: () => void
  onPrintClick?: () => void
  onExportAsPDFClick?: () => void
  onShareLinkClick?: () => void
  onDeleteClick?: () => void
  onRowClick: (invoice: IInvoice) => void
  sendLabel?: string
  disabledItems?: string[]
  isDashboardInvoiceTable?: boolean
}

const InvoicesTable = ({
  invoices,
  onSelectedInvoice,
  onDuplicateClick,
  onEditClick,
  onRecordPaymentClick,
  onSendClick,
  onPrintClick,
  onExportAsPDFClick,
  onShareLinkClick,
  onDeleteClick,
  onRowClick,
  sendLabel = 'Send',
  disabledItems = [],
  isDashboardInvoiceTable = false,
}: InvoicesTableProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openItemMenu = Boolean(anchorEl)
  const isMobile = isUsingMobile()
  const { isAuthorized } = useAuthorization()

  const handleMenuButtonClick = useCallback(
    (invoice: IInvoice) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      if (onSelectedInvoice) {
        onSelectedInvoice(invoice)
      }
      setAnchorEl(event.currentTarget)
    },
    [onSelectedInvoice],
  )

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleDuplicateClick = () => {
    handleCloseMenu()
    onDuplicateClick && onDuplicateClick()
  }

  const handleRecordPaymentClick = (invoice?: IInvoice) => (event: MouseEvent<HTMLElement>) => {
    handleCloseMenu()
    onRecordPaymentClick && onRecordPaymentClick()

    if (invoice && onSelectedInvoice) {
      onSelectedInvoice(invoice)
    }

    event.stopPropagation()
  }

  const handleDeleteClick = () => {
    handleCloseMenu()
    onDeleteClick && onDeleteClick()
  }

  const handleSendClick = () => {
    handleCloseMenu()
    onSendClick && onSendClick()
  }

  const handlePrintClick = () => {
    handleCloseMenu()
    onPrintClick && onPrintClick()
  }

  const handleExportAsPDFClick = () => {
    handleCloseMenu()
    onExportAsPDFClick && onExportAsPDFClick()
  }

  const handleShareLinkClick = () => {
    handleCloseMenu()
    onShareLinkClick && onShareLinkClick()
  }

  const getColorByStatus = (status: string) => {
    switch (status) {
      case 'PARTIAL':
        return 'warning'
      case 'SENT':
        return 'info'
      case 'PAID':
        return 'success'
      case 'OVERDUE':
        return 'error'
      case 'REFUND':
        return 'secondary'
      case 'DRAFT':
      default:
        return
    }
  }

  const columns = useMemo<GridColDef<IInvoice>[]>(
    () => [
      {
        field: 'name',
        headerName: 'ID',
        flex: 1,
        sortable: false,
        renderCell: (param) => `#${param.row.name}`,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        sortable: false,
        renderCell: (param) => {
          return isMobile ? (
            <FiberManualRecordIcon
              sx={{
                '&.MuiSvgIcon-root.MuiSvgIcon-colorSuccess': {
                  color: 'var(--success-color)',
                  borderRadius: '50%',
                  backgroundColor: 'var(--success-bg-color)',
                },
                '&.MuiSvgIcon-root.MuiSvgIcon-colorInfo': {
                  color: 'var(--info-color)',
                  borderRadius: '50%',
                  backgroundColor: 'var(--info-bg-color)',
                },
                '&.MuiSvgIcon-root.MuiSvgIcon-colorWarning': {
                  color: 'var(--warning-color)',
                  borderRadius: '50%',
                  backgroundColor: 'var(--warning-bg-color)',
                },
                '&.MuiSvgIcon-root.MuiSvgIcon-colorError': {
                  color: 'var(--error-color)',
                  borderRadius: '50%',
                  backgroundColor: 'var(--error-bg-color)',
                },
                '&.MuiSvgIcon-root.MuiSvgIcon-colorSecondary': {
                  color: 'var(--orange-color)',
                  borderRadius: '50%',
                  backgroundColor: 'var(--orange-bg-color)',
                },
                '&.MuiSvgIcon-root': {
                  backgroundColor: 'var(--secondary-grey-bg)',
                  borderRadius: '50%',
                  margin: 'auto',
                },
              }}
              color={getColorByStatus(param.row.status || 'disabled')}
            />
          ) : (
            <Chip
              sx={{
                '&.MuiChip-colorSuccess': {
                  backgroundColor: 'var(--success-bg-color)',
                  color: 'var(--success-color)',
                },
                '&.MuiChip-colorWarning': {
                  backgroundColor: 'var(--warning-bg-color)',
                  color: 'var(--warning-color)',
                },
                '&.MuiChip-colorError': {
                  backgroundColor: 'var(--error-bg-color)',
                  color: 'var(--error-color)',
                },
                '&.MuiChip-colorInfo': {
                  backgroundColor: 'var(--info-bg-color)',
                  color: 'var(--info-color)',
                },
                '&.MuiChip-colorSecondary': {
                  backgroundColor: 'var(--orange-bg-color)',
                  color: 'var(--orange-color)',
                },
              }}
              color={getColorByStatus(param.row.status || 'default')}
              label={param.row.status}
            />
          )
        },
      },
      {
        field: 'customerName',
        headerName: 'Customer Name',
        flex: isMobile ? 3 : 2,
        sortable: false,
        renderCell: (param) => {
          return (
            <Box>
              <Typography>{param.row.customerName}</Typography>
              {param.row.customerEmail && (
                <Typography
                  component='small'
                  variant='subtitle2'
                  sx={{ color: 'var(--gray3-color)' }}
                >
                  {isMobile ? formatDate(param.row.periodStart) : param.row.customerEmail}
                </Typography>
              )}
            </Box>
          )
        },
      },
      {
        field: 'total',
        headerName: 'Amount',
        flex: 2,
        maxWidth: 100,
        sortable: false,
        renderCell: (param) => {
          return param.row.total ? formatMoney(Number(param.row.total)) : ''
        },
      },
      {
        field: 'periodStart',
        headerName: 'Invoice date',
        flex: 1,
        sortable: false,
        renderCell: (param) => {
          return (
            <Box>
              <Typography>{formatDate(param.row.periodStart)}</Typography>
              {param.row.updatedAt && param.row.updatedAt !== param.row.periodStart && (
                <Typography
                  component='small'
                  variant='subtitle2'
                  sx={{ color: 'var(--gray3-color)' }}
                >
                  Updated: {formatDateFromNow(param.row.updatedAt)}
                </Typography>
              )}
            </Box>
          )
        },
      },
      {
        field: 'periodEnd',
        headerName: 'Due on',
        flex: 1,
        sortable: false,
        renderCell: (param) => {
          return <Typography>{formatDate(param.row.periodEnd)}</Typography>
        },
      },
      {
        field: 'recordPayment',
        headerName: 'Record Payment',
        flex: 2,
        sortable: false,
        renderCell: (param) => {
          return (
            <Button
              color='primary'
              variant='outlined'
              onClick={handleRecordPaymentClick(param.row)}
              disabled={['PAID', 'REFUND', 'DRAFT'].includes(param.row.status || '')}
            >
              Record a Payment
            </Button>
          )
        },
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1.5,
        sortable: false,
        renderCell: (param) => (
          <IconButton
            aria-label='more'
            id='long-button'
            aria-controls={openItemMenu ? 'long-menu' : undefined}
            aria-expanded={openItemMenu ? 'true' : undefined}
            aria-haspopup='true'
            onClick={handleMenuButtonClick(param.row)}
            sx={{
              justifyItems: 'right',
            }}
          >
            <MoreVertIcon />
          </IconButton>
        ),
      },
    ],
    [isMobile, handleMenuButtonClick, openItemMenu],
  )

  const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: 5,
    }
  }, [])

  return (
    <>
      <DataGrid
        rows={invoices}
        columns={columns}
        disableColumnMenu
        getRowId={(invoice) => invoice.publicId || ''}
        hideFooter
        getRowSpacing={getRowSpacing}
        rowHeight={65}
        columnHeaderHeight={24}
        initialState={{
          columns: {
            columnVisibilityModel: {
              name: !isMobile,
              createdAt: !isMobile,
              periodStart: !isMobile,
              periodEnd: !isMobile,
              recordPayment: !isMobile && !isDashboardInvoiceTable && isAuthorized('write_invoice'),
            },
          },
        }}
        onRowClick={(params) => onRowClick(params.row)}
        sx={TableStyles}
      />
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={openItemMenu}
        onClose={handleCloseMenu}
        sx={{
          '& .MuiMenuItem-root': {
            padding: { xs: '1rem', md: '1rem 4rem 1rem 2rem' },
          },
          '& .MuiMenuItem-root:hover': {
            backgroundColor: 'var(--secondary1-color)',
            color: 'var(--primary1-color)',
          },
        }}
      >
        {isAuthorized('write_invoice') && (
          <MenuItem onClick={handleDuplicateClick}>Duplicate</MenuItem>
        )}
        {isAuthorized('write_invoice') && (
          <MenuItem disabled={disabledItems.includes('edit')} onClick={onEditClick}>
            Edit
          </MenuItem>
        )}
        {isMobile && isAuthorized('write_invoice') && (
          <MenuItem
            disabled={disabledItems.includes('payment')}
            onClick={handleRecordPaymentClick()}
          >
            Record payment
          </MenuItem>
        )}
        {isAuthorized('write_invoice') && (
          <MenuItem disabled={disabledItems.includes('send')} onClick={handleSendClick}>
            {sendLabel}
          </MenuItem>
        )}
        <MenuItem disabled={disabledItems.includes('print')} onClick={handlePrintClick}>
          Print
        </MenuItem>
        <MenuItem disabled={disabledItems.includes('export')} onClick={handleExportAsPDFClick}>
          Export as PDF
        </MenuItem>
        <MenuItem disabled={disabledItems.includes('share')} onClick={handleShareLinkClick}>
          Share payment link
        </MenuItem>
        {isAuthorized('write_invoice') && (
          <MenuItem sx={{ color: 'var(--error-color)' }} onClick={handleDeleteClick}>
            Delete
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

export default InvoicesTable
