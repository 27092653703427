import { IconButton } from '@mui/material'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { isUsingMobile } from '../../helpers/utils'

interface IDeleteButton {
  handleOnDeleted: any
  disabled?: boolean
}

const DeleteButtonTable = (props: IDeleteButton) => {
  const { handleOnDeleted, disabled = false } = props
  const isMobile = isUsingMobile()

  return (
    <IconButton
      disabled={disabled}
      sx={{
        width: { xs: '32px', sm: '50px' },
        height: { xs: '32px', sm: '50px' },
        marginTop: '3px',
        color: 'red',
        backgroundColor: '#FAE7E7',
      }}
      onClick={handleOnDeleted}
    >
      <DeleteOutlineOutlinedIcon fontSize={isMobile ? 'small' : 'medium'} />
    </IconButton>
  )
}

export default DeleteButtonTable
