import { DataGrid, GridColDef, GridRowSpacingParams, gridClasses } from '@mui/x-data-grid'
import { ICreditCard, ICustomer } from '../../../types/types'
import { useCallback, useMemo } from 'react'
import { isUsingMobile } from '../../../helpers/utils'
import TableStyles from '../../../components/table-card/table-styles'
import { formatDate, formatExpirationDate } from '../../../helpers/formatPrice'
import { Card, CardContent, CardHeader, Chip, Typography } from '@mui/material'
import NoDataDisplay from '../../../components/noDataDisplay/noDataDisplay'

interface CreditcardsListProps {
  customer: ICustomer
}
const CreditCardsList = ({ customer }: CreditcardsListProps) => {
  const isMobile = isUsingMobile()

  const columns = useMemo<GridColDef<ICreditCard>[]>(
    () => [
      {
        field: 'default',
        headerName: '',
        flex: isMobile ? 2 : 1,
        sortable: false,
        renderCell: (param) =>
          param.row.isDefault && (
            <Chip
              sx={{
                '&.MuiChip-colorInfo': {
                  backgroundColor: 'var(--info-bg-color)',
                  color: 'var(--info-color)',
                },
              }}
              label='Default'
              color='info'
            />
          ),
      },
      {
        field: 'brand',
        headerName: 'Brand',
        flex: isMobile ? 2 : 1,
        sortable: false,
        renderCell: (param) => `${param.row.cardType}`,
      },
      {
        field: 'number',
        headerName: 'Number',
        flex: 2,
        sortable: false,
        renderCell: (param) =>
          isMobile ? param.row.lastDigits : `**** **** **** ${param.row.lastDigits}`,
      },
      {
        field: 'expirationDate',
        headerName: isMobile ? 'Exp. date' : 'Expiration Date',
        flex: 2,
        sortable: false,
        renderCell: (param) => `${formatExpirationDate(param.row.expirationDate)}`,
      },
      {
        field: 'createdAt',
        headerName: 'Added Date',
        flex: 2,
        sortable: false,
        renderCell: (param) => `${formatDate(param.row.createdAt)}`,
      },
    ],
    [],
  )

  const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: 5,
    }
  }, [])

  return (
    <Card sx={{ '&.MuiPaper-root': { boxShadow: 'none' } }}>
      <CardHeader title='Credit cards' />
      <CardContent sx={{ p: { xs: 1, sm: 2 } }}>
        {customer.creditCardList && customer.creditCardList.length > 0 ? (
          <DataGrid
            rows={customer.creditCardList || []}
            columns={columns}
            disableColumnMenu
            getRowId={(creditCard) => creditCard.publicId || ''}
            hideFooter
            getRowSpacing={getRowSpacing}
            rowHeight={65}
            columnHeaderHeight={24}
            disableRowSelectionOnClick
            initialState={{
              columns: {
                columnVisibilityModel: {
                  createdAt: !isMobile,
                },
              },
            }}
            sx={{
              ...TableStyles,
              [`& .${gridClasses.cell}`]: {
                cursor: 'normal',
                paddingX: 1,
                paddingY: { xs: 1, sm: 0 },
              },
              [`& .${gridClasses.row}:hover, & .${gridClasses.row}.Mui-hovered`]: {
                bgcolor: 'white',
              },
            }}
          />
        ) : (
          <NoDataDisplay title='No data to display' mt={0}/>
        )}
      </CardContent>
    </Card>
  )
}

export default CreditCardsList
