import {
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { ICustomer } from '../../../types/types'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'
import LanguageIcon from '@mui/icons-material/Language'

interface ContactDetailsProps {
  customer: ICustomer
}

const ContactDetails = ({ customer }: ContactDetailsProps) => {
  return (
    <Card sx={{ '&.MuiPaper-root': { boxShadow: 'none' } }}>
      <CardContent>
        <Typography sx={{ textAlign: 'center' }} component='h2' variant='h5' mb={1}>
          {customer.name}
        </Typography>
        <Divider />
        <Typography variant='h6' component='h2' mt={2}>
          Contact Details
        </Typography>
        <List>
          {customer?.primaryEmail && (
            <ListItem>
              <ListItemIcon>
                <EmailIcon />
              </ListItemIcon>
              <ListItemText primary={customer.primaryEmail} />
            </ListItem>
          )}
          {customer?.primaryPhoneNumber && (
            <ListItem>
              <ListItemIcon>
                <PhoneIcon />
              </ListItemIcon>
              <ListItemText primary={customer.primaryPhoneNumber} />
            </ListItem>
          )}
          {customer?.website && (
            <ListItem>
              <ListItemIcon>
                <LanguageIcon />
              </ListItemIcon>
              <ListItemText primary={customer.website} />
            </ListItem>
          )}
        </List>
      </CardContent>
    </Card>
  )
}

export default ContactDetails
