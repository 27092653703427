import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { IAgedReceivablesDetailed, ITimePeriod } from '../../../types/types'
import { AppState } from '../../../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { formatMoney } from '../../../helpers/formatPrice'
import PaginationNew from '../../../components/pagination-new'
import { useState } from 'react'
import { saveAgedReportsDetailed } from '../../../redux/actions/report.actions'
import moment from 'moment'
import { DEFAULT_DATE_FORMAT } from '../../../helpers/constants'
import { styled } from '@mui/styles'

const FooterTypography = styled(Typography)(() => ({
  fontSize: '16px !important',
  fontWeight: '600 !important',
  color: 'var(--green) !important',
}))

const AgedReceivablesTable = ({ filterDate }: { filterDate: any }) => {
  const dispatch = useDispatch()
  const details = useSelector((state: AppState) => state.reportReducer.agedReportDetailed)
  const general = useSelector((state: AppState) => state.reportReducer.agedReportGeneral)
  const [page, setPage] = useState(1)
  const paginationSize = useSelector(
    (state: AppState) => state.reportReducer.agedReportDetailedSize,
  )

  const getPlural = (word: string, quantity: number | undefined) => {
    return quantity === 1 ? word : `${word}s`
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    dispatch(saveAgedReportsDetailed(moment(filterDate).format(DEFAULT_DATE_FORMAT), value - 1))
  }

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          '& .MuiTableHead-root .MuiTableCell-root': { fontWeight: 600, fontSize: 16 },
          '& .MuiTableBody-root .MuiTableCell-root .MuiTypography-root': { fontSize: 14 },
          '& .MuiTableCell-root': { color: 'var(--secondary-grey)' },
          boxShadow: '0 4px 8px rgba(77, 94, 128, 0.08)',
          borderRadius: '8px',
          border: 'solid 1px var(--secondary-grey-bg)',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Customer Name</TableCell>
              <TableCell>0-30 days overdue</TableCell>
              <TableCell>31-60 days overdue</TableCell>
              <TableCell>61-90 days overdue</TableCell>
              <TableCell>90+ days overdue</TableCell>
              <TableCell>Total unpaid</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {details.map((item: IAgedReceivablesDetailed, index: number) => (
              <TableRow
                key={index}
                sx={{
                  '&:nth-of-type(odd)': {
                    backgroundColor: 'var(--background-color)',
                  },
                }}
              >
                <TableCell>{item.customerName}</TableCell>
                {item.periods.map((period: ITimePeriod, periodIndex: number) => (
                  <TableCell key={periodIndex + period.timePeriod}>
                    {period.amount ? <Typography>{formatMoney(period.amount)}</Typography> : '-'}
                    {period.quantity ? (
                      <Typography color='var(--gray3-color)'>{`${period.quantity} ${getPlural(
                        'invoice',
                        period.quantity,
                      )}`}</Typography>
                    ) : null}
                  </TableCell>
                ))}
                <TableCell>
                  <Typography>{formatMoney(item.total)}</Typography>
                  <Typography color='var(--gray3-color)'>{`${item.quantity} ${getPlural(
                    'invoice',
                    item?.quantity,
                  )}`}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell>
                <FooterTypography>
                  Total unpaid
                </FooterTypography>
                <Typography color='var(--gray3-color)' fontSize={12}>
                  Overdue invoices numbers
                </Typography>
              </TableCell>
              {general?.periods.length
                ? general.periods.map((period: ITimePeriod, periodIndex: number) => (
                    <TableCell key={periodIndex}>
                      <FooterTypography>
                        {period.amount ? formatMoney(period.amount) : '$ 0.00'}
                      </FooterTypography>
                      <Typography fontSize={14} color='var(--gray3-color)'>{`${
                        period.quantity
                      } ${getPlural('invoice', general?.quantity)}`}</Typography>
                    </TableCell>
                  ))
                : null}
              <TableCell>
                <FooterTypography>
                  {general?.amount ? formatMoney(general.amount) : '$ 0.00'}
                </FooterTypography>
                <Typography fontSize={14} color='var(--gray3-color)'>{`${
                  general?.quantity
                } ${getPlural('invoice', general?.quantity)}`}</Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {paginationSize > 10 ? (
        <PaginationNew
          size={10}
          quantity={paginationSize}
          page={page}
          handleChange={handleChangePage}
        />
      ) : null}
    </>
  )
}

export default AgedReceivablesTable
