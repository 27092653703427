import ProfitLossKpis from './kpis'
import ProfitLossTable from './table'

const ProfitLoss = ({ from, to }: { from: string; to: string }) => {
  return (
    <>
      <ProfitLossKpis mb={2} />
      <ProfitLossTable from={from} to={to} />
    </>
  )
}

export default ProfitLoss
