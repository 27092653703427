import { Box, Grid, InputAdornment, TextField } from '@mui/material'
import { ICustomer } from '../../../types/types'
import FlagIcon from '@mui/icons-material/Flag'

interface ShippingAddressFormProps {
  customer: ICustomer
  setCustomer: (customer: any) => void
}

const ShippingAddressForm = ({ customer, setCustomer }: ShippingAddressFormProps) => {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextField
            label='Ship to'
            placeholder='Enter a name'
            InputLabelProps={{
              shrink: true,
            }}
            value={customer.shippingName}
            onChange={(e) =>
              setCustomer({
                ...customer,
                shippingName: e.target.value,
              })
            }
            required
            fullWidth
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            label='Phone number'
            placeholder='Enter your Phone Number'
            InputLabelProps={{
              shrink: true,
            }}
            value={customer.shippingPhone}
            onChange={(e) =>
              setCustomer({
                ...customer,
                shippingPhone: e.target.value,
              })
            }
            required
            fullWidth
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            label='Address line 1'
            placeholder='Building Number, Street Address, Apartment...'
            InputLabelProps={{
              shrink: true,
            }}
            value={customer.shippingAddress}
            onChange={(e) =>
              setCustomer({
                ...customer,
                shippingAddress: e.target.value,
              })
            }
            required
            fullWidth
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            label='Address line 2'
            placeholder='Building Number, Street Address, Apartment...'
            InputLabelProps={{
              shrink: true,
            }}
            value={customer.shippingAddressTwo || ''}
            onChange={(e) =>
              setCustomer({
                ...customer,
                shippingAddressTwo: e.target.value,
              })
            }
            fullWidth
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            label='City'
            placeholder='Enter your city or locality'
            InputLabelProps={{
              shrink: true,
            }}
            value={customer.shippingCity}
            onChange={(e) => setCustomer({ ...customer, shippingCity: e.target.value })}
            required
            fullWidth
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            label='State'
            placeholder='Enter your State abbreviation'
            InputLabelProps={{
              shrink: true,
            }}
            value={customer.shippingState}
            onChange={(e) => setCustomer({ ...customer, shippingState: e.target.value })}
            required
            fullWidth
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            type='number'
            label='Postal/Zip code'
            placeholder='Enter your zip code'
            InputLabelProps={{
              shrink: true,
            }}
            value={customer.shippingPostalCode}
            onChange={(e) =>
              setCustomer({
                ...customer,
                shippingPostalCode: e.target.value,
              })
            }
            required
            fullWidth
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            label='Country'
            value={customer.shippingCountry}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <FlagIcon />
                </InputAdornment>
              ),
            }}
            fullWidth
            required
            disabled
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default ShippingAddressForm
