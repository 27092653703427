import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { VALID_EMAIL } from '../../helpers/constants'
import { saveAccessToken } from '../../redux/actions/auth.actions'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router'
import api from '../../api/api'
import greyCheck from '../../assets/icons/grey-check.png'
import greenCheck from '../../assets/icons/green-check.png'
import signUpFirstImage from '../../assets/images/signUpFirst.png'
import signUpSecondImage from '../../assets/images/signUpSecond.png'
import signUpThirdImage from '../../assets/images/signUpThird.png'
import RegistrationField from '../../components/registration-field'
import { errorHandler } from '../../helpers/errorHandler'
import { changeIsLoading } from '../../redux/actions/home.actions'
import { SAVE_MCC_TYPES } from '../../redux/actions/auth.actions.type'
import { AppState } from '../../redux/store'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material'
import { validateEmail } from '../../helpers/utils'

interface ISignUp {
  formErrors: any
  setFormErrors: any
  handleMouseDownPassword: any
  handleSignInClickPage: any
  isVerified: any
  setIsVerified: (value: any) => void
}

const signUp = ({
  formErrors,
  setFormErrors,
  handleMouseDownPassword,
  handleSignInClickPage,
  isVerified,
  setIsVerified,
}: // handleButtonClick,
// handleSwitchSingView,
ISignUp) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isLoading = useSelector((state: AppState) => state.homeReducer.isLoading)
  const [signUpStep, setSignUpStep] = useState<string>('email')
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showPasswordRepeat, setShowPasswordRepeat] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    fullName: '',
    companyName: '',
    typeOfBusiness: '',
    sizeOfOrganization: '',
    confirmationCode: '',
  })
  const [errors, setErrors] = useState({
    fullName: false,
    passwordMin: true,
    passwordchar: true,
    passwordUpLow: true,
    password: false,
    confirmPassword: false,
  })

  useEffect(() => {
    dispatch(changeIsLoading(false))
  }, [])

  useEffect(() => {
    api
      .getMCCTypes()
      .then((res) => {
        dispatch({
          type: SAVE_MCC_TYPES,
          payload: res.data.body,
        })
      })
      .catch((err) => console.log(err))
  }, [])

  const handleChangeValues = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    value === 'password' && validatePassword(e.target.value)
    // value === 'fullName' && validateFullName(e.target.value)
    setInitialValues({
      ...initialValues,
      [value]: e.target.value,
    })
  }

  const resetForm = () => {
    setInitialValues({
      email: '',
      password: '',
      confirmPassword: '',
      fullName: '',
      companyName: '',
      typeOfBusiness: '',
      sizeOfOrganization: '',
      confirmationCode: '',
    })
  }

  async function signUp() {
    try {
      await Auth.signUp({
        username: initialValues.email,
        password: initialValues.password,
        attributes: {
          email: initialValues.email,
          name: initialValues.fullName,
        },
      })
      setSignUpStep('confirm')
    } catch (error: any) {
      toast(error.message, {
        autoClose: 3000,
        hideProgressBar: true,
        className: 'error-toast',
      })
      resetForm()
      setSignUpStep('email')
    } finally {
      dispatch(changeIsLoading(false))
    }
  }

  async function signIn() {
    try {
      const user = await Auth.signIn(initialValues.email, initialValues.password)
      dispatch(saveAccessToken(user.signInUserSession.idToken.jwtToken))
      sessionStorage.setItem('token', user.signInUserSession.idToken.jwtToken)
      dispatch(changeIsLoading(false))
      navigate('/dashboard')
    } catch (error) {
      dispatch(changeIsLoading(false))
      errorHandler(error)
    }
  }

  async function confirmCode() {
    try {
      await Auth.confirmSignUp(initialValues.email, initialValues.confirmationCode)
      setIsVerified(true)
      const timeoutId = setTimeout(() => {
        signIn()
      }, 2000)
      return () => clearTimeout(timeoutId)
    } catch (error: any) {
      dispatch(changeIsLoading(false))
      toast(error.message, {
        autoClose: 3000,
        hideProgressBar: true,
        className: 'error-toast',
      })
      setInitialValues({
        ...initialValues,
        confirmationCode: '',
      })
    }
  }

  const handleGetStartedClick = () => {
    signUp()
  }

  const handleConfirmClick = () => {
    dispatch(changeIsLoading(true))
    confirmCode()
  }

  const renderButtonText = (chosenTab: string) => {
    switch (chosenTab) {
      case 'email':
        return 'Continue'
      case 'password':
        return 'Register Now'
      default:
        return 'Confirm account'
    }
  }

  const renderSubtitleText = (chosenTab: string) => {
    switch (chosenTab) {
      case 'email':
        return 'Let´s get started with a few simple steps'
      case 'password':
        return 'Fill the fields with more details'
      default:
        return 'To continue verify your account'
    }
  }

  const handleClickVisibilityIcon = () => {
    setShowPassword(!showPassword)
  }

  const handleClickVisibilityRepeatIcon = () => {
    setShowPasswordRepeat(!showPasswordRepeat)
  }

  const handleButtonClick = () => {
    if (signUpStep === 'email') {
      setSignUpStep('password')
    }
    if (signUpStep === 'password') {
      handleGetStartedClick()
      setSignUpStep('confirm')
    }
    if (signUpStep === 'confirm') {
      handleConfirmClick()
    }
  }

  const handleDisableButton = () => {
    if (isLoading) true
    if (signUpStep === 'email') {
      return !VALID_EMAIL.test(initialValues.email)
    } else if (signUpStep === 'password') {
      return (
        initialValues.password !== initialValues.confirmPassword ||
        initialValues.password === '' ||
        errors.passwordUpLow ||
        errors.passwordMin ||
        errors.passwordchar ||
        initialValues.fullName.length < 3
      )
    } else {
      return !initialValues.confirmationCode
    }
  }
  const handlePressEnter = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter' && !handleDisableButton()) {
      e.preventDefault()
      handleButtonClick()
    }
  }

  const validateField = (
    field: string,
    value: string,
    validationFunction: (value: string) => boolean,
  ) => {
    const isValid = validationFunction(value)
    setErrors({ ...errors, [field]: !isValid })
  }

  const handleBlur = (
    field: string,
    value: string,
    validationFunction: (value: string) => boolean,
  ) => {
    validateField(field, value, validationFunction)
  }

  const validateFullName = (value: string) => {
    let fullNameVar = true

    // Check if the length of the full name is at least 3 characters
    if (value.length >= 3) {
      fullNameVar = false
    }

    // Update the errors state, retaining existing errors
    setErrors({
      ...errors,
      fullName: fullNameVar,
    })

    return !fullNameVar
  }

  // Define your validation functions for each field here
  const validatePassword = (value: any): boolean => {
    let passwordMin = true
    let passwordUpLow = true
    let passwordchar = true

    // Check if the length of the full name is at least 8 characters
    if (value.length >= 8) {
      passwordMin = false
    }

    // Check if the full name contains both uppercase and lowercase characters
    if (/[A-Z]/.test(value) && /[a-z]/.test(value)) {
      passwordUpLow = false
    }

    // Check if the full name contains at least one number
    if (/\d/.test(value)) {
      passwordchar = false
    }

    // Update the errors state
    setErrors({
      fullName: errors.fullName,
      passwordMin,
      passwordUpLow,
      passwordchar,
      password: errors.password, // Assuming these are not changing
      confirmPassword: errors.confirmPassword, // Assuming these are not changing
    })

    return !passwordMin && !passwordUpLow && !passwordchar
  }

  const validateConfirmPassword = (value: string): boolean => {
    let confirmPassword = false

    if (value !== initialValues.password) {
      confirmPassword = true
    }

    setErrors({
      ...errors,
      confirmPassword: true,
    })
    return !confirmPassword
  }

  return (
    <>
      <>
        <div style={{ flexDirection: 'column' }}>
          <Typography
            mt={2}
            id='modal-modal-title'
            variant='h4'
            fontWeight={700}
            color='var(--dark-grey-title)'
          >
            {signUpStep === 'confirm' ? 'Verify your Account' : 'Welcome to Blue Cimarron!'}
          </Typography>
          <Typography
            mt={3}
            id='modal-modal-subtitle'
            fontWeight={400}
            color='var(--light-grey-title)'
            style={{ marginBottom: '20px' }}
          >
            {renderSubtitleText(signUpStep)}
          </Typography>
        </div>
        <div>
          <form onKeyDown={handlePressEnter}>
            {signUpStep === 'email' && (
              <TextField
                style={{ marginBottom: '20px' }}
                error={!!formErrors['email']}
                helperText={formErrors['email']}
                required
                type='email'
                id='outlined-required'
                label='Email'
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                value={initialValues.email}
                onBlur={() => validateEmail(initialValues.email, setFormErrors)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeValues(e, 'email')
                }
              />
            )}
            {signUpStep === 'password' && (
              <>
                <TextField
                  style={{ marginBottom: '20px' }}
                  label='Full Name'
                  fullWidth
                  value={initialValues.fullName}
                  required
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeValues(e, 'fullName')
                  }
                  onBlur={() => handleBlur('fullName', initialValues.fullName, validateFullName)}
                  error={errors.fullName}
                  helperText={errors.fullName ? 'Must be minimum 3 symbols' : ''}
                  id='full-name-input'
                />
                <div style={{ marginBottom: '20px' }}>
                  <FormControl variant='outlined' fullWidth>
                    <InputLabel htmlFor='outlined-adornment-password'>Password</InputLabel>
                    <OutlinedInput
                      error={!!formErrors['password']}
                      required
                      id='outlined-adornment-password'
                      type={showPassword ? 'text' : 'password'}
                      value={initialValues.password}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleChangeValues(e, 'password')
                      }}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickVisibilityIcon}
                            onMouseDown={handleMouseDownPassword}
                            edge='end'
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label='Password'
                    />
                    <FormHelperText>{formErrors['password']}</FormHelperText>
                  </FormControl>
                </div>
                <div style={{ marginBottom: '20px' }}>
                  <FormControl variant='outlined' fullWidth>
                    <InputLabel htmlFor='outlined-adornment-password'>Confirm Password</InputLabel>
                    <OutlinedInput
                      required
                      id='outlined-adornment-password'
                      type={showPasswordRepeat ? 'text' : 'password'}
                      value={initialValues.confirmPassword}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeValues(e, 'confirmPassword')
                      }
                      onBlur={() =>
                        handleBlur(
                          'confirmPassword',
                          initialValues.confirmPassword,
                          validateConfirmPassword,
                        )
                      }
                      error={errors.confirmPassword}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickVisibilityRepeatIcon}
                            onMouseDown={handleMouseDownPassword}
                            edge='end'
                          >
                            {showPasswordRepeat ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label='Password'
                    />
                    <FormHelperText style={{ color: '#d32f2f' }}>
                      {errors.confirmPassword && 'Passwords do not match'}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div style={{ marginBottom: '20px' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                      <img
                        style={{ width: '16px', height: '16px' }}
                        src={errors.passwordMin ? greyCheck : greenCheck}
                      />
                    </div>
                    <div>8 characters minimum</div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                      <img
                        style={{ width: '16px', height: '16px' }}
                        src={errors.passwordUpLow ? greyCheck : greenCheck}
                      />
                    </div>
                    <div>At least 1 uppercase and 1 lowercase</div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                      <img
                        style={{ width: '16px', height: '16px' }}
                        src={errors.passwordchar ? greyCheck : greenCheck}
                      />
                    </div>
                    <div>A number</div>
                  </div>
                </div>
              </>
            )}
            {signUpStep === 'confirm' && (
              <RegistrationField
                placeholder='Confirmation code'
                styles='sign-up__input'
                value={initialValues.confirmationCode}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeValues(e, 'confirmationCode')
                }
                validataionSchema={!!initialValues.confirmationCode}
                errorMessage='Is required'
                name='sign-up-confirmation-code'
              />
            )}
          </form>
          <Button
            sx={{
              fontWeight: 600,
              backgroundColor: isVerified ? '#7cbd7c' : undefined,
              color: isVerified ? 'white' : undefined,
              '&.verified': {
                backgroundColor: '#7cbd7c',
                '&:hover': {
                  // Add hover styles when verified
                },
              },
            }}
            color={isVerified ? 'success' : 'primary'}
            variant='contained'
            onClick={handleButtonClick}
            fullWidth
            name='sign-up-button'
            disabled={handleDisableButton()}
          >
            {isVerified ? (
              <>
                <span className='check-mark'>✓ Verified</span>
              </>
            ) : (
              renderButtonText(signUpStep)
            )}
          </Button>
        </div>
        <div></div>
        <div>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
            <Typography>{'Have an Account?'}</Typography>
            <span
              className='highlighted-text'
              onClick={() => handleSignInClickPage()}
              id='register-now__link'
              style={{ marginLeft: '8px' }}
            >
              <Typography>Sign in</Typography>
            </span>
          </Box>
        </div>
      </>
    </>
  )
}

export default signUp
