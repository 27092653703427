import { Grid, TextField } from '@mui/material'
import SelectorMui from '../../../components/selector-mui'
import DateField from '../../../components/date-field'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import { AppState } from '../../../redux/store'
import { INewBill, IVendor } from '../../../types/types'

interface ManageHeaderProps {
  onChangeField: any
  bill: INewBill
}
const FormHeader = ({ onChangeField, bill }: ManageHeaderProps) => {
  const vendors = useSelector((state: AppState) => state.homeReducer.vendors)

  const getVendorOptions = useMemo(() => {
    if (!vendors) {
      return []
    }

    return vendors.map((vendor: IVendor) => {
      return { title: vendor.name, value: vendor.publicId }
    })
  }, [vendors])

  return (
    <Grid container spacing={2}>
      <Grid container item md={6} gap={2} flexDirection='column'>
        <Grid item>
          <SelectorMui
            options={getVendorOptions}
            value={bill.vendorPublicId || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeField(e, 'vendorPublicId')
            }
            placeholder='Select a vendor'
            label='Vendor'
            fullWidth
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            label='Bill ID'
            placeholder='Enter ID number'
            value={bill?.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, 'name')}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            label='P.O/S.O number'
            placeholder='Enter number'
            value={bill?.poso}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, 'poso')}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <Grid container item md={6} gap={2} flexDirection='column' pt='0 !important'>
        <Grid item flexGrow={2} m={0} p={'0 !important'}></Grid>

        <Grid item>
          <DateField
            fullWidth
            label='Bill date'
            value={bill?.date}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, 'date', true)}
          />
        </Grid>

        <Grid item>
          <DateField
            startDate={bill?.date}
            endDate={bill?.dueDate}
            fullWidth
            label={'Due date'}
            value={bill?.dueDate}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, 'dueDate', true)}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FormHeader
