import { Box, Card, CardContent, Chip, Grid, Stepper, Typography } from '@mui/material'
import { IScheduledInvoice, IScheduler } from '../../types/types'
import { formatMoney } from '../../helpers/formatPrice'
import ScheduleStep from './steps/schedule'
import PaymentStep from './steps/payment'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../redux/store'
import { saveCertainCustomer } from '../../redux/actions/home.actions'
import { useEffect } from 'react'
import NotificationStep from './steps/notification'
import CreationStep from './steps/creation'
import moment from 'moment'

interface SubscriptionSettingsProps {
  scheduledInvoice: IScheduledInvoice
  setScheduler: (scheduler: IScheduler) => void
  setPaymentMethod: (paymentMethod: string) => void
  setSendTo: (sendTo: string) => void
  onEditInvoiceClick?: () => void
}

const SubscriptionSettings = ({
  scheduledInvoice,
  onEditInvoiceClick,
  setPaymentMethod,
  setSendTo,
  setScheduler,
}: SubscriptionSettingsProps) => {
  const dispatch = useDispatch()
  const chosenCustomer = useSelector((state: AppState) => state.homeReducer.chosenCustomer)

  useEffect(() => {
    dispatch(saveCertainCustomer(scheduledInvoice?.customerPublicId))
  }, [scheduledInvoice])

  const getColorByStatus = (status: string) => {
    switch (status) {
      case 'STOPPED':
        return 'error'
      case 'SENT':
        return 'info'
      case 'ACTIVE':
        return 'success'
      case 'ENDED':
        return 'warning'
      case 'DRAFT':
      default:
        return
    }
  }

  const renderEndDate = (endDate: string) => {
    return endDate ? moment(endDate).format('MMM DD, YYYY') : 'Never'
  }

  return (
    <>
      <Box m={2}>
        <Card
          sx={{
            '& .MuiTypography-root': { color: 'var(--grey-fonts)' },
            '& .MuiTypography-subtitle1': { color: 'var(--secondary-grey)' },
            '& .MuiChip-colorSuccess': {
              backgroundColor: 'var(--success-bg-color)',
              color: 'var(--success-color)',
            },
            '& .MuiChip-colorWarning': {
              backgroundColor: 'var(--warning-bg-color)',
              color: 'var(--warning-color)',
            },
            '& .MuiChip-colorError': {
              backgroundColor: 'var(--error-bg-color)',
              color: 'var(--error-color)',
            },
            '& .MuiChip-colorInfo': {
              backgroundColor: 'var(--info-bg-color)',
              color: 'var(--info-color)',
            },
            '& .MuiChip-colorSecondary': {
              backgroundColor: 'var(--orange-bg-color)',
              color: 'var(--orange-color)',
            },
          }}
        >
          <CardContent>
            <Grid container>
              <Grid item xs={6} sm={2}>
                <Typography>Status</Typography>
                <Chip
                  color={getColorByStatus(scheduledInvoice.status || '')}
                  label={scheduledInvoice.status}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography>Customer Name</Typography>
                <Typography variant='subtitle1'>{scheduledInvoice.customerName}</Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Typography>Amount</Typography>
                <Typography variant='subtitle1'>
                  {formatMoney(Number(scheduledInvoice.total))}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                {scheduledInvoice.scheduler && (
                  <>
                    <Typography>End date</Typography>
                    <Typography variant='subtitle1'>
                      {renderEndDate(scheduledInvoice.scheduler.endDate)}
                    </Typography>
                  </>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      <Box m={2} mb={10}>
        <Stepper
          orientation='vertical'
          sx={{
            '& .MuiStepLabel-label, & .MuiStepLabel-label.Mui-active': {
              color: 'var(--success-color)',
              fontWeight: 'bold',
            },
          }}
        >
          <CreationStep
            scheduledInvoice={scheduledInvoice}
            onEditInvoiceClick={onEditInvoiceClick}
          />
          <ScheduleStep scheduledInvoice={scheduledInvoice} setScheduler={setScheduler} />
          <PaymentStep
            scheduledInvoice={scheduledInvoice}
            setPaymentMethod={setPaymentMethod}
            customer={chosenCustomer}
          />
          <NotificationStep scheduledInvoice={scheduledInvoice} setSendTo={setSendTo} customer={chosenCustomer} />
        </Stepper>
      </Box>
    </>
  )
}

export default SubscriptionSettings
