import { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { saveScheduledInvoiceTemplates } from '../../redux/actions/home.actions'
import { AppState } from '../../redux/store'
import PaginationNew from '../../components/pagination-new'
import { useNavigate } from 'react-router-dom'
import ContentHeader from '../../components/content-header'
import PlusButton from '../../components/plus-button/plus-button'
import ListFilters from '../../components/list-filters'
import NoDataDisplay from '../../components/noDataDisplay/noDataDisplay'
import { Box } from '@mui/material'
import ScheduledInvoicesTable from './subscriptionsTable'
import { IScheduledInvoice, IScheduler } from '../../types/types'
import { errorHandler } from '../../helpers/errorHandler'
import api from '../../api/api'
import ConfirmDialog from '../../components/dialog/dialog'
import ReferenceMenu from './reference-menu'
import { isUsingMobile } from '../../helpers/utils'
import SubscriptionModal from './modal/subscriptionModal'
import useAuthorization from '../../helpers/useAuthorization'
import { successToastNotification } from '../../helpers/toastNotification'

const Subscriptions = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const [subscriptionsPage, setSubscriptionsPage] = useState(1)
  const [selectedFilter, setSelectedFilter] = useState('')
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [selectedScheduledInvoice, setSelectedScheduledInvoice] =
    useState<IScheduledInvoice | null>(null)
  const scheduledInvoices = useSelector((state: AppState) => state.homeReducer.scheduledInvoices)
  const isMobile = isUsingMobile()
  const [showSettings, setShowSettings] = useState<boolean>(true)
  const { isAuthorized } = useAuthorization()

  const selectedFilterRef = useRef(selectedFilter)
  const subscriptionsPageRef = useRef(subscriptionsPage)

  const scheduledInvoicesSize = useSelector(
    (state: AppState) => state.homeReducer.scheduledInvoiceSize,
  )

  useEffect(() => {
    selectedFilterRef.current = selectedFilter
  }, [selectedFilter])

  useEffect(() => {
    subscriptionsPageRef.current = subscriptionsPage
  }, [subscriptionsPage])

  useEffect(() => {
    dispatch(saveScheduledInvoiceTemplates())
  }, [])

  const handleCreateClick = () => {
    navigate('/recurring-invoices/add', {
      state: {
        recurringInvoice: true,
      },
    })
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setSubscriptionsPage(value)
    dispatch(saveScheduledInvoiceTemplates(value - 1, selectedFilter))
  }

  const handleSelectedFilterChange = (event: SyntheticEvent, filter: string) => {
    setSelectedFilter(filter)
    setSubscriptionsPage(1)
    dispatch(saveScheduledInvoiceTemplates(0, filter))
  }

  const handleViewClick = (scheduledInvoice: IScheduledInvoice) => {
    setShowSettings(scheduledInvoice.status === 'DRAFT' && !scheduledInvoice.scheduler)
    setSelectedScheduledInvoice(scheduledInvoice)
    setShowModal(true)
  }

  const handleEditClick = () => {
    if (!selectedScheduledInvoice) {
      return
    }

    navigate(`/recurring-invoices/add/${selectedScheduledInvoice.publicId}/editselectedFilter`, {
      state: {
        recurringInvoice: true,
      },
    })
  }

  const handleChangeStatusClick = () => {
    if (!selectedScheduledInvoice) {
      return
    }

    if (selectedScheduledInvoice.status === 'STOPPED') {
      handleActivateClick()
    } else {
      handleStopClick()
    }
  }

  const handleDuplicateClick = () => {
    selectedScheduledInvoice &&
      api
        .duplicateScheduledInvoiceTemplate(selectedScheduledInvoice.publicId)
        .then(() => {
          successToastNotification('Subscription duplicated')
          dispatch(saveScheduledInvoiceTemplates(subscriptionsPage - 1, selectedFilter))
        })
        .catch((err) => errorHandler(err))
  }

  const handleDeleteClick = () => {
    setIsDeleteDialogOpen(true)
  }

  const handleDeleteConfirm = () => {
    if (!selectedScheduledInvoice) {
      return
    }

    setIsDeleteDialogOpen(false)

    api
      .deleteScheduledInvoiceTemplates(selectedScheduledInvoice.publicId)
      .then(() => {
        successToastNotification('Recurring invoice was successfully deleted')
        dispatch(saveScheduledInvoiceTemplates(subscriptionsPage - 1, selectedFilter))
      })
      .catch((err) => errorHandler(err))

    setSelectedScheduledInvoice(null)
  }

  const handleActivateClick = (scheduledInvoice?: IScheduledInvoice) => {
    const scheduler = scheduledInvoice
      ? scheduledInvoice.scheduler
      : selectedScheduledInvoice?.scheduler

    if (!scheduler) {
      return
    }

    api
      .activateRecurringInvoice(scheduler.publicId)
      .then(() => {
        dispatch(saveScheduledInvoiceTemplates(subscriptionsPageRef.current - 1, selectedFilterRef.current))
        successToastNotification('Recurring invoice was successful activated')
      })
      .catch((err) => errorHandler(err))
  }

  const handleStopClick = (scheduledInvoice?: IScheduledInvoice) => {
    const publicId = scheduledInvoice
      ? scheduledInvoice.publicId
      : selectedScheduledInvoice?.publicId

    if (!publicId) {
      return
    }

    api
      .stopRecurringInvoice(publicId)
      .then(() => {
        successToastNotification('Recurring invoice was stopped')
        dispatch(saveScheduledInvoiceTemplates(subscriptionsPageRef.current - 1, selectedFilterRef.current))
      })
      .catch((err) => errorHandler(err))
  }

  const getDisabledItems = () => {
    if (!selectedScheduledInvoice || !selectedScheduledInvoice.status) {
      return []
    }

    switch (selectedScheduledInvoice.status) {
      case 'ENDED':
        return ['edit', 'changeStatus', 'duplicate']
      case 'DRAFT':
        return selectedScheduledInvoice.scheduler ? [] : ['changeStatus']
    }

    return []
  }

  return (
    <>
      <ContentHeader title={<Box>Recurring invoices {isMobile && <ReferenceMenu />}</Box>} />

      <ListFilters
        filters={[
          { title: 'All', value: '' },
          { title: 'Active', value: 'ACTIVE' },
          { title: 'Ended', value: 'ENDED' },
          { title: 'Stopped', value: 'STOPPED' },
          { title: 'Draft', value: 'DRAFT' },
        ]}
        selectedFilter={selectedFilter}
        onSelectedFilterChange={handleSelectedFilterChange}
      />

      {scheduledInvoices.length > 0 ? (
        <Box pb={4}>
          <ScheduledInvoicesTable
            scheduledInvoices={scheduledInvoices}
            onRowClick={handleViewClick}
            onEditClick={handleEditClick}
            onChangeStatusClick={handleChangeStatusClick}
            setSelectedScheduledInvoice={setSelectedScheduledInvoice}
            onDuplicateClick={handleDuplicateClick}
            onDeleteClick={handleDeleteClick}
            disabledItems={getDisabledItems()}
            onStopClick={handleStopClick}
            onActivateClick={handleActivateClick}
            changeStatusText={
              ['STOPPED', 'DRAFT'].includes(selectedScheduledInvoice?.status || '')
                ? 'Activate'
                : 'Stop subscription'
            }
          />
          {scheduledInvoicesSize > 15 ? (
            <PaginationNew
              size={15}
              quantity={scheduledInvoicesSize}
              page={subscriptionsPage}
              handleChange={handleChangePage}
            />
          ) : null}
        </Box>
      ) : (
        <NoDataDisplay
          title='No data to display'
          description='Add recurring invoices and schedule dates and automatic payments.'
        />
      )}

      {isAuthorized('write_invoice') && (
        <PlusButton tooltipText='Add Subscription' handleOnClick={handleCreateClick} />
      )}

      <ConfirmDialog
        isOpen={isDeleteDialogOpen}
        dialogTitle='Sure you want to delete?'
        dialogBodyContent={
          'Deleting this item will permanently remove it from your account, ' +
          'and it cannot be undone.'
        }
        buttonConfirmText='Yes, Delete'
        buttonCancelText='Cancel'
        handleClose={() => setIsDeleteDialogOpen(false)}
        handleConfirm={handleDeleteConfirm}
      />

      {selectedScheduledInvoice && (
        <SubscriptionModal
          showModal={showModal}
          setShowModal={setShowModal}
          setScheduler={(scheduler: IScheduler) =>
            setSelectedScheduledInvoice({ ...selectedScheduledInvoice, scheduler })
          }
          setPaymentMethod={(paymentMethod: string) =>
            setSelectedScheduledInvoice({ ...selectedScheduledInvoice, paymentMethod })
          }
          setSendTo={(sendTo: string) =>
            setSelectedScheduledInvoice({ ...selectedScheduledInvoice, sendTo })
          }
          onDeleteClick={handleDeleteClick}
          onEditInvoiceClick={handleEditClick}
          onStopClick={handleStopClick}
          onActivateClick={handleActivateClick}
          scheduledInvoice={selectedScheduledInvoice}
          showSettings={showSettings}
        />
      )}
    </>
  )
}

export default Subscriptions
