import { TextField, Typography } from '@mui/material'
import {
  validateAddress,
  validateCanEmptyField,
  validateCommonField,
  validateState,
} from '../../../helpers/utils'
import SquareCheckbox from '../../../components/square-checkbox'

interface CustomerBillingInfoTabProps {
  newCustomer: any
  formErrors: any
  setFormErrors: any
  onChangeNewCustomer: any
  isShippingTheSame: any
  onIsShippingSameClick: any
}

const CustomerBillingInfoTab = ({
  newCustomer,
  formErrors,
  setFormErrors,
  onChangeNewCustomer,
  isShippingTheSame,
  onIsShippingSameClick,
}: CustomerBillingInfoTabProps) => {
  return (
    <>
      <TextField
        label='Address line 1'
        required
        placeholder='Build number, Street address, Apartment...'
        sx={{ marginBottom: '25px' }}
        InputProps={{
          sx: {
            '& fieldset': {
              borderColor: 'var(--secondary-grey)',
            },
          },
        }}
        value={newCustomer?.billingAddress}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          validateAddress(e.target.value, 'billingAddress', setFormErrors, 'Address line')
          onChangeNewCustomer(e, 'billingAddress', 'shippingAddress')
        }}
        error={!!formErrors['billingAddress']}
        helperText={formErrors['billingAddress']}
      />
      <TextField
        label='Address line 2'
        placeholder='Build number, Street address, Apartment...'
        sx={{ marginBottom: '25px' }}
        InputProps={{
          sx: {
            '& fieldset': {
              borderColor: 'var(--secondary-grey)',
            },
          },
        }}
        value={newCustomer?.billingAddressTwo}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          validateCanEmptyField(e.target.value, 'billingAddressTwo', setFormErrors, 'Address line')
          onChangeNewCustomer(e, 'billingAddressTwo', 'shippingAddressTwo')
        }}
        error={!!formErrors['billingAddressTwo']}
        helperText={formErrors['billingAddressTwo']}
      />
      <TextField
        label='City'
        required
        placeholder='Enter your city'
        sx={{ marginBottom: '25px' }}
        InputProps={{
          sx: {
            '& fieldset': {
              borderColor: 'var(--secondary-grey)',
            },
          },
        }}
        value={newCustomer?.billingCity}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          validateCommonField(e.target.value, 'billingCity', setFormErrors, 'City', 3)
          onChangeNewCustomer(e, 'billingCity', 'shippingCity')
        }}
        error={!!formErrors['billingCity']}
        helperText={formErrors['billingCity']}
      />
      <TextField
        label='State'
        required
        sx={{ marginBottom: '25px' }}
        InputProps={{
          sx: {
            '& fieldset': {
              borderColor: 'var(--secondary-grey)',
            },
          },
        }}
        placeholder='Enter your state abbreviation'
        value={newCustomer?.billingState}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          validateState(e.target.value, setFormErrors)
          onChangeNewCustomer(e, 'billingState', 'shippingState')
        }}
        error={!!formErrors['billingState']}
        helperText={formErrors['billingState']}
      />
      <TextField
        label='Postal/Zip code'
        required
        sx={{ marginBottom: '25px' }}
        InputProps={{
          sx: {
            '& fieldset': {
              borderColor: 'var(--secondary-grey)',
            },
          },
        }}
        placeholder='Enter your zipcode'
        value={newCustomer?.billingPostalCode}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          validateCommonField(
            e.target.value,
            'billingPostalCode',
            setFormErrors,
            'Postal/Zip code',
            5,
          )
          onChangeNewCustomer(e, 'billingPostalCode', 'shippingPostalCode')
        }}
        error={!!formErrors['billingPostalCode']}
        helperText={formErrors['billingPostalCode']}
      />
      <TextField
        sx={{ marginBottom: '25px' }}
        InputProps={{
          sx: {
            '& fieldset': {
              borderColor: 'var(--secondary-grey)',
            },
          },
        }}
        label='Country'
        value='United States'
      />
      <label className='shipping-address__checkbox' onClick={onIsShippingSameClick}>
        <SquareCheckbox isChecked={isShippingTheSame} />
        <Typography>My Shipping info is the same as Billing info</Typography>
      </label>
    </>
  )
}

export default CustomerBillingInfoTab
