import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import api from '../../../api/api'
import PageHeader from '../../../components/page-header/page-header'
import { errorHandler } from '../../../helpers/errorHandler'
import { getCdnUrl, isFormValid, isUsingMobile } from '../../../helpers/utils'
import { changeIsCompleteBusiness } from '../../../redux/actions/auth.actions'
import { SAVE_MCC_TYPES, SHOW_USIO_NOTIFICATION } from '../../../redux/actions/auth.actions.type'
import { getCompany } from '../../../redux/actions/home.actions'
import { SAVE_COMPANY } from '../../../redux/actions/home.actions.type'
import { AppState } from '../../../redux/store'
import { ICompany } from '../../../types/types'
import AccountInfoForm from './account-info'
import ContactDetailsForm from './contact-details'
import PhysicalAddressForm from './physical-address'
import UploadPicture from './upload-picture'
import { successToastNotification } from '../../../helpers/toastNotification'

const MAX_LOGO_SIZE = 1228800

type AccordionState = {
  'account-info': boolean
  'physical-address': boolean
  'contact-details': boolean
}

const BusinessInfoManage = (props: any) => {
  const { ctaCallback, pageHeaderBackNavigation = '/business' } = props
  const isOnBoarding = useSelector((state: AppState) => state.authReducer.isOnBoarding)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [hasEmptyValues, setHasEmptyValues] = useState(false)
  const [formErrors, setFormErrors] = useState({
    name: '',
    mccPublicId: '',
    ownerEmail: '',
    companySize: '',
  })

  const companyState = useSelector((state: AppState) => state.homeReducer.company)
  const mccTypes = useSelector((state: AppState) => state.authReducer.mccTypes)

  const [company, setCompany] = useState<ICompany>({
    name: '',
    country: 'United States',
    state: '',
    city: '',
    postalCode: '',
    addressOne: '',
    addressTwo: '',
    phone: '',
    fax: '',
    mobile: '',
    website: '',
    companySize: '',
    ownerEmail: '',
  })

  const [accordionState, setAccordionState] = useState<AccordionState>({
    'account-info': true,
    'physical-address': true,
    'contact-details': true,
  })
  useEffect(() => {
    api
      .getMCCTypes()
      .then((res) => {
        dispatch({
          type: SAVE_MCC_TYPES,
          payload: res.data.body,
        })
      })
      .catch((err) => console.log(err))
  }, [])

  const handleSaveCompanyClick = () => {
    if (companyState) {
      api
        .editCompany(company)
        .then(() => {
          dispatch(getCompany())
          navigate('/business')
          successToastNotification(`${companyState?.name} was successfully edited`)
          if (ctaCallback) {
            ctaCallback()
          }
          dispatch(changeIsCompleteBusiness(true))
        })
        .catch((err) => errorHandler(err))
    } else {
      api
        .createCompany({
          ...company,
          companySize: company.companySize || 'SMALL',
          name: company.name || 'Unknown',
          addressOne: company.addressOne || null,
          addressTwo: company.addressTwo || null,
          city: company.city || null,
          postalCode: company.postalCode || null,
          phone: company.phone || '',
          mobile: company.mobile || '',
          mccPublicId: company.mccPublicId || mccTypes[0].publicId,
        })
        .then((res) => {
          successToastNotification('Your account was activated successfully')

          dispatch({
            type: SAVE_COMPANY,
            payload: res.data.body,
          })

          dispatch({
            type: SHOW_USIO_NOTIFICATION,
            payload: true,
          })

          ctaCallback && ctaCallback()

          dispatch(changeIsCompleteBusiness(true))
        })
        .catch(errorHandler)
    }
  }

  const handleUploadPictureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]

    if (!file) {
      return
    }

    if (file.size > MAX_LOGO_SIZE) {
      toast.error(`File size exceeds the limit of 1.2 MB.`, {
        position: 'top-right',
        autoClose: 6000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      })
    } else {
      const formData = new FormData()

      formData.append('file', file)

      api
        .uploadCompanyLogo(formData)
        .then(() => {
          dispatch(getCompany())
          toast.success('Business picture was updated', {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          })
        })
        .catch((err) => {
          errorHandler(err)
        })
    }
  }

  const isMobile = isUsingMobile()

  const handleAccordionChange = (key: keyof AccordionState) => () => {
    setAccordionState({
      ...accordionState,
      [key]: !accordionState[key],
    })
  }

  useEffect(() => {
    if (companyState) {
      setCompany({
        ...companyState,
      })
    }
  }, [companyState])

  useEffect(() => {
    const expandAccordion = !isUsingMobile()
    setAccordionState({
      'account-info': expandAccordion,
      'physical-address': expandAccordion,
      'contact-details': expandAccordion,
    })
  }, [])

  useEffect(() => {
    setHasEmptyValues(
      !company.name ||
        !company.ownerEmail ||
        !company.mccPublicId ||
        !company.companySize ||
        !company.addressOne ||
        !company.city ||
        !company.state ||
        !company.postalCode,
    )
  }, [
    company.name,
    company.ownerEmail,
    company.mccPublicId,
    company.companySize,
    company.addressOne,
    company.city,
    company.state,
    company.postalCode,
  ])

  return (
    <>
      {company && (
        <>
          {isMobile && (
            <Box sx={{ justifyContent: 'left' }}>
              <Tooltip title='Back'>
                <Button size='large' aria-label='back' onClick={ctaCallback}>
                  <ChevronLeftIcon />
                </Button>
              </Tooltip>
            </Box>
          )}
          <PageHeader
            title='Business Info'
            backNavigation={pageHeaderBackNavigation}
            confirmText='Save'
            backText='Cancel'
            onConfirm={handleSaveCompanyClick}
            ctaCallback={ctaCallback}
            disabled={!isFormValid(formErrors) || (isOnBoarding && hasEmptyValues)}
          />

          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item sm={2} xs={12}>
                  <UploadPicture
                    accept='image/*'
                    avatarSrc={getCdnUrl(companyState?.logoFileName)}
                    onChange={handleUploadPictureChange}
                  />
                </Grid>
                <Grid item sm={10} xs={12}>
                  <Box component='form'>
                    <Accordion
                      sx={{ marginBottom: 2 }}
                      expanded={accordionState['account-info']}
                      onChange={handleAccordionChange('account-info')}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Account information</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <AccountInfoForm
                          formErrors={formErrors}
                          setFormErrors={setFormErrors}
                          company={company}
                          setCompany={setCompany}
                        />
                      </AccordionDetails>
                    </Accordion>

                    <Accordion
                      sx={{ marginBottom: 2 }}
                      expanded={accordionState['physical-address']}
                      onChange={handleAccordionChange('physical-address')}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Physical address</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <PhysicalAddressForm company={company} setCompany={setCompany} />
                      </AccordionDetails>
                    </Accordion>

                    <Accordion
                      expanded={accordionState['contact-details']}
                      onChange={handleAccordionChange('contact-details')}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Contact details</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ContactDetailsForm company={company} setCompany={setCompany} />
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </>
      )}
    </>
  )
}

export default BusinessInfoManage
