import { Grid, Typography } from '@mui/material';

interface IContactDetails {
	company: any;
}

const PhysicalAddress = (props: IContactDetails) => {
	return (
		<>
			<Grid container>
				<Grid item sm={6}>
					<Typography variant="subtitle1">Address line 1</Typography>
					<Typography variant="subtitle2" color="text.secondary">
						{props.company?.addressOne ? props.company.addressOne : '-'}
					</Typography>
					<Typography variant="subtitle1">City</Typography>
					<Typography variant="subtitle2" color="text.secondary">
						{props.company?.city ? props.company.city : '-'}
					</Typography>
					<Typography variant="subtitle1">Postal/Zip code</Typography>
					<Typography variant="subtitle2" color="text.secondary">
						{props.company?.postalCode ? props.company.postalCode : '-'}
					</Typography>
				</Grid>
				<Grid item sm={6}>
					<Typography variant="subtitle1">Address line 2</Typography>
					<Typography variant="subtitle2" color="text.secondary">
						{props.company?.addressTwo ? props.company.addressTwo : '-'}
					</Typography>
					<Typography variant="subtitle1">State</Typography>
					<Typography variant="subtitle2" color="text.secondary">
						{props.company?.state ? props.company.state : '-'}
					</Typography>
					<Typography variant="subtitle1">Country</Typography>
					<Typography variant="subtitle2" color="text.secondary">
						{props.company?.country ? props.company.country : '-'}
					</Typography>
				</Grid>
			</Grid>
		</>
	);
};

export default PhysicalAddress;
