import { Radio, TextField, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import api from '../../api/api'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useState } from 'react'
import { getAccountSubscription } from '../../redux/actions/home.actions'
import { useDispatch } from 'react-redux'
import { isUsingMobile } from '../../helpers/utils'
import { successToastNotification } from '../../helpers/toastNotification'

interface IUnsubscribeDialog {
  isOpen: boolean
  dialogTitle?: string
  dialogBodyContent?: string
  buttonConfirmText?: string
  buttonCancelText?: string
  handleConfirm: () => void
  handleClose: () => void
}

export default function UnsubscribeDialog(props: IUnsubscribeDialog) {
  const {
    isOpen,
    dialogTitle,
    dialogBodyContent,
    buttonConfirmText,
    buttonCancelText,
    handleClose,
  } = props

  const [selectedOption, setSelectedOption] = useState<string>('')
  const [otherReason, setOtherReason] = useState('')
  const dispatch = useDispatch()

  const isMobile = isUsingMobile()

  const handleOptionChange = (value: string) => {
    setSelectedOption(value)
  }

  const handleOtherReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtherReason(event.target.value)
  }

  const handleConfirmClick = () => {
    api
      .cancelAccountSubscription(selectedOption !== 'other' ? selectedOption : otherReason)
      .then(() => {
        handleClose()
        successToastNotification(`Your subscription was successfully canceled`)
        const fetchData = async () => {
          try {
            await dispatch(getAccountSubscription())
          } catch (error) {
            console.error('Error fetching Subscription data', error)
          }
        }

        fetchData()
      })
      .catch((error: any) => {
        console.error('Error:', error)
      })
  }

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose} aria-labelledby='responsive-dialog-title'>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            padding: !isMobile ? '30px 80px' : '30px 0px',
          }}
        >
          <DialogTitle id='responsive-dialog-title'>{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText textAlign='center'>{dialogBodyContent}</DialogContentText>
          </DialogContent>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <FormControlLabel
              control={
                <Radio
                  checked={selectedOption === 'technicalIssues'}
                  onChange={() => handleOptionChange('technicalIssues')}
                />
              }
              label='Technical issues'
            />
            <FormControlLabel
              control={
                <Radio
                  checked={selectedOption === 'tooExpensive'}
                  onChange={() => handleOptionChange('tooExpensive')}
                />
              }
              label='Too expensive'
            />
            <FormControlLabel
              control={
                <Radio
                  checked={selectedOption === 'switchingProduct'}
                  onChange={() => handleOptionChange('switchingProduct')}
                />
              }
              label='Switching to another product'
            />
            <FormControlLabel
              control={
                <Radio
                  checked={selectedOption === 'shuttingDown'}
                  onChange={() => handleOptionChange('shuttingDown')}
                />
              }
              label='Shutting down the company'
            />
            <FormControlLabel
              control={
                <Radio
                  checked={selectedOption === 'notSureHowToUse'}
                  onChange={() => handleOptionChange('notSureHowToUse')}
                />
              }
              label='Not sure how to use the App'
            />
            <FormControlLabel
              control={
                <Radio
                  checked={selectedOption === 'missingFeatures'}
                  onChange={() => handleOptionChange('missingFeatures')}
                />
              }
              label='Missing features I need'
            />
            <FormControlLabel
              control={
                <Radio
                  checked={selectedOption === 'other'}
                  onChange={() => handleOptionChange('other')}
                />
              }
              label='Other'
            />
          </div>
          {selectedOption === 'other' && (
            <TextField
              label='Anything you want to share? (optional)'
              variant='outlined'
              multiline
              rows={2}
              fullWidth
              value={otherReason}
              onChange={handleOtherReasonChange}
            />
          )}
          <div style={{ marginTop: '5px' }}>
            <DialogActions>
              <Button
                onClick={handleClose}
                style={{ borderColor: 'blue', borderWidth: 2, borderStyle: 'solid' }}
              >
                <Typography sx={{ fontWeight: '500' }}>{buttonConfirmText}</Typography>
              </Button>
              <Button
                onClick={handleConfirmClick}
                style={{
                  borderColor: 'red',
                  borderWidth: 2,
                  borderStyle: 'solid',
                  background: 'red',
                }}
              >
                <Typography sx={{ color: 'white', fontWeight: '500' }}>
                  {buttonCancelText}
                </Typography>
              </Button>
            </DialogActions>
          </div>
        </div>
      </Dialog>
    </div>
  )
}
