import React, { useEffect, useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid } from '@mui/material'
import Play from '../../../assets/images/play.png'
import Payments from '../../../assets/images/payments.png'
import Request from '../../../assets/images/request_quote.png'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import './FAQ.css'

import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../redux/store'
import { saveFaq } from '../../../redux/actions/home.actions'
import Loader from '../../../components/loader/loader'
import { isUsingMobile } from '../../../helpers/utils'
import { saveUSIOLink } from '../../../redux/actions/auth.actions'

const FAQ = () => {
  const dispatch = useDispatch()
  const [selectedButton, setSelectedButton] = useState(0)
  const [loading, setLoading] = useState(true)
  const faqData = useSelector((state: AppState) => state.authReducer.faqData)
  const usioLink = useSelector((state: AppState) => state.authReducer.usioLink)

  const usioTagRegex = /<USIO>(.*?)<\/USIO>/g

  const renderCustomLinksInFAQ = (text: string) => {
    if (!text) return null
    return renderCustomLinks(text)
  }

  const renderCustomLinks = (text: string) => {
    return text.split(usioTagRegex).map((part, index) => {
      if (index % 3 === 1) {
        return (
          <a key={index} href={usioLink} target='_blank' rel='noopener noreferrer'>
            {part}
          </a>
        )
      } else {
        return <span key={index}>{part}</span>
      }
    })
  }

  const isMobile = isUsingMobile()

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        await dispatch(saveFaq())

        setLoading(false)
      } catch (error) {
        console.error('Error fetching FAQ data', error)
        setLoading(false)
      }
    }

    fetchData()
  }, [dispatch])


  useEffect(() => {
    dispatch(saveUSIOLink())
  }, [])

  const handleButtonClick = (buttonId: any) => {
    setSelectedButton(buttonId === selectedButton ? null : buttonId)
  }

  const truncateText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`
    }
    return text
  }

  if (loading) {
    return <Loader />
  }

  return (
    <div>
      <div
        style={{
          color: 'var(--secondary-grey)',
          fontSize: '22px',
          fontWeight: '600',
          marginBottom: '15px',
        }}
      >
        How can we help you?
      </div>
      <div style={{ display: 'flex', flexDirection: !isMobile ? 'row' : 'column' }}>
        <div className='custom-choice-container'>
          {faqData &&
            faqData.content.length > 0 &&
            faqData.content.map((item: any, index: any) => (
              <Grid item xs={12} sm={4} key={index}>
                <div
                  className={`custom-choice ${selectedButton === index ? 'selected' : ''}`}
                  onClick={() => handleButtonClick(index)}
                >
                  {item.name === 'How to get started' && (
                    <img src={Play} alt='Play' style={{ marginTop: '20px', marginLeft: '24px' }} />
                  )}
                  {item.name === 'Invoicing' && (
                    <img
                      src={Request}
                      alt='request'
                      style={{ marginTop: '15px', marginLeft: '24px' }}
                    />
                  )}
                  {item.name === 'Payment Processing' && (
                    <img
                      src={Payments}
                      alt='payment'
                      style={{ marginTop: '15px', marginLeft: '24px' }}
                    />
                  )}
                  <span className='span-choice'>Questions about</span>
                  <span
                    style={{
                      maxWidth: '100%',
                      color: '#0039FF',
                      fontSize: '16px',
                      lineHeight: '24px',
                      display: 'block',
                      fontWeight: 600,
                      textAlign: 'left',
                      marginLeft: '20px',
                    }}
                    title={item.name}
                  >
                    {truncateText(item.name, 30)}
                  </span>
                </div>
              </Grid>
            ))}
        </div>

        <div style={{ width: !isMobile ? '75%' : '100%' }}>
          <Grid item xs={12} sm={8}>
            {selectedButton !== null &&
              faqData &&
              faqData.content.length > 0 &&
              faqData?.content[selectedButton].faqs.map((faqItem: any, index: any) => (
                <Accordion
                  key={index}
                  sx={{
                    '&.MuiPaper-root': {
                      boxShadow: '0 2px 6px var(--secondary-grey-bg)',
                      m: '0 0 1rem 0',
                      borderRadius: '10px',
                    },
                    '& .MuiAccordionSummary-root': { borderRadius: '10px' },
                    '& .MuiCollapse-root': { borderRadius: '10px' },
                    '&.MuiAccordion-root::before': {
                      background: 'none',
                    },
                  }}
                >
                  <AccordionSummary
                    sx={{ square: 'true' }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index + 1}a-content`}
                    id={`panel${index + 1}a-header`}
                  >
                    <Typography style={{ fontWeight: 600, color: '#4D5E80' }}>
                      {renderCustomLinksInFAQ(faqItem.name)}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography style={{ fontWeight: 400, color: '#4D5E80' }}>
                      {renderCustomLinksInFAQ(faqItem.description)}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default FAQ
