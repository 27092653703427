import {
  Card,
  FormControl,
  Grid, SelectChangeEvent,
  TextField
} from "@mui/material";
import { ICustomer, IInvoice } from "../../types/types";
import DateField from "../date-field";
import SelectorMui from "../selector-mui";

interface ICustomerSectionProps {
  customerItems: any;
  currentPublicId: string;
  handleChangeFields: (e: SelectChangeEvent<string>, value: string, isDate?: boolean) => void;
  setIsNewCustomerDialogOpen: (isNewCustomer: boolean) => void;
  newEstimate: any;
  newInvoice?: IInvoice;
  chosenCustomer: ICustomer | null;
  publicId: string | undefined;
  isInvoice?: boolean;
  startDate?: string;
  endDate?: string;

}

const CustomerSectionMobile = (props: ICustomerSectionProps) => {
  const {
    customerItems,
    currentPublicId,
    handleChangeFields,
    newEstimate,
    newInvoice,
    publicId,
    isInvoice,
    setIsNewCustomerDialogOpen,
    startDate,
    endDate,
  } = props;

  return (
    <Card sx={{
      width: '100%',
      boxShadow: '0px 5px 20px rgba(70, 101, 137, 0.35)',
      paddingTop: '20px',
      marginBottom: '20px'
    }}>
      <Grid item xs={12} sm={6} flexDirection="column" display="flex" margin='20px'>
        <FormControl sx={{ marginBottom: '20px' }}>
          <SelectorMui
            isAddButton
            addButtonText="Add new customer"
            options={customerItems}
            value={currentPublicId}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeFields(e, 'customerPublicId')
            }
            onAddIconClick={() => setIsNewCustomerDialogOpen(true)}
            placeholder={isInvoice ? "Bill to" : "Estimate To"}
            label={isInvoice ? "Bill to" : "Estimate To"}
            name="estimate-manage-customer-selector"
          />
        </FormControl>
        {publicId && (
          <TextField
            variant="outlined"
            label="Estimate ID"
            placeholder="Enter ID number"
            disabled
            value={isInvoice ? newInvoice?.name : newEstimate?.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeFields(e, 'name')
            }
          />
        )}
        <div style={{marginTop:"16px"}}>
        <TextField
          InputProps={{
            sx: {
              '& fieldset': {
                borderColor: "var(--secondary-grey)"
              },
            },
          }}
          label="P.O/S.O number"
          placeholder="Enter number"
          value={isInvoice ? newInvoice?.poso : newEstimate?.poso}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeFields(e, 'poso')
          }
          InputLabelProps={{ shrink: true }}
        />
        </div>
      </Grid>
      <Grid xs={12} md={6} container item sx={{margin: '20px', width: 'auto'}}>
        <Grid container flexDirection="column" spacing={2}>
        <Grid item>
            <DateField
              fullWidth
              label={isInvoice ? "Invoice date" : "Estimate Date"}
              value={isInvoice ? newInvoice?.periodStart : newEstimate?.periodStart}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeFields(e, 'periodStart', true)
              }
            />
          </Grid>
          <Grid item>
            <DateField
              startDate={isInvoice ? newInvoice?.periodStart : newEstimate?.periodStart}
              endDate={isInvoice ? newInvoice?.periodEnd : newEstimate?.periodEnd}
              fullWidth
              label={isInvoice ? "Payment due" : "Expiration Date"}
              value={isInvoice ? newInvoice?.periodEnd : newEstimate?.periodEnd}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeFields(e, 'periodEnd', true)
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CustomerSectionMobile;
