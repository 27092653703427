import { MouseEvent } from 'react'
import { isUsingMobile } from '../../../helpers/utils'
import { Button, Grid, ToggleButton, ToggleButtonGroup } from '@mui/material'

interface SubscriptionModalFooterProps {
  onSelectedTabChange?: (evt: MouseEvent<HTMLElement>, value: string) => void
  onCancelClick?: () => void
  selectedTab?: string
}

const SubscriptionModalFooter = ({
  onSelectedTabChange,
  onCancelClick,
  selectedTab,
}: SubscriptionModalFooterProps) => {
  const isMobile = isUsingMobile()
  return (
    <Grid
      container
      justifyContent={{ xs: 'normal', md: 'right' }}
      alignItems={{ xs: 'normal', md: 'center' }}
      flexDirection={{ xs: 'column-reverse', md: 'row' }}
    >
      {!isMobile && (
        <Grid item padding={{ xs: '1rem', md: '1rem' }} flexGrow={2}>
          <ToggleButtonGroup
            color='primary'
            value={selectedTab}
            exclusive
            onChange={onSelectedTabChange}
            aria-label='Platform'
            sx={{
              '& .MuiButtonBase-root': {
                backgroundColor: 'var(--secondary1-color)',
                color: 'var(--primary1-color)',
                padding: '6px 16px',
                boxShadow:
                  '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
              },
              '& .MuiButtonBase-root.Mui-selected': {
                backgroundColor: 'var(--primary1-color)',
                color: '#fff',
              },
              '& .MuiButtonBase-root.Mui-selected:hover': {
                backgroundColor: 'rgb(58, 28, 178)',
                boxShadow:
                  '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
              },
            }}
          >
            <ToggleButton value='view'>Preview</ToggleButton>
            <ToggleButton value='settings'>Settings</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      )}
      <Grid item padding={{ xs: '1rem 1rem 0 1rem', md: '1rem' }}>
        {isMobile && (
          <Button
            color='primary'
            variant='outlined'
            onClick={onCancelClick}
            sx={{ mb: 2 }}
            fullWidth
          >
            Cancel
          </Button>
        )}
      </Grid>
    </Grid>
  )
}

export default SubscriptionModalFooter
