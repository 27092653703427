import { MouseEvent, MouseEventHandler, useCallback, useMemo, useState } from 'react'
import { isUsingMobile } from '../../../helpers/utils'
import { ICreditCard, ICustomer, INewCreditCard } from '../../../types/types'
import { DataGrid, GridColDef, GridRowSpacingParams, gridClasses } from '@mui/x-data-grid'
import { Box, Button, Chip, IconButton, Link, Menu, MenuItem } from '@mui/material'
import { formatDate, formatExpirationDate, formatUSIOExpDate } from '../../../helpers/formatPrice'
import TableStyles from '../../../components/table-card/table-styles'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import api from '../../../api/api'
import { saveCertainCustomer } from '../../../redux/actions/home.actions'
import { useDispatch, useSelector } from 'react-redux'
import { successToastNotification } from '../../../helpers/toastNotification'
import ConfirmDialog from '../../../components/dialog/dialog'
import NewCreditCardForm from '../../invoices/record-payment/newCreditCardForm'
import { AppState } from '../../../redux/store'
import { errorHandler } from '../../../helpers/errorHandler'
import NoDataDisplay from '../../../components/noDataDisplay/noDataDisplay'

interface CreditCardsFormProps {
  customer: ICustomer
}

const CreditCardsForm = ({ customer }: CreditCardsFormProps) => {
  const dispatch = useDispatch()
  const isMobile = isUsingMobile()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openItemMenu = Boolean(anchorEl)
  const [selectedCreditCard, setSelectedCreditCard] = useState<ICreditCard>()
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState<boolean>(false)
  const [isNewCard, setIsNewCard] = useState(false)
  const [newCreditCard, setNewCreditCard] = useState<INewCreditCard>({
    number: '',
    expirationDate: '',
    cvv: '',
    name: '',
    customerPublicId: customer.publicId,
  })
  const [formErrors, setFormErrors] = useState({
    cardId: '',
  })
  const merchantKey = useSelector((state: AppState) => state.authReducer.usioApiKey)

  const handleMenuButtonClick = useCallback(
    (creditCard: ICreditCard) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      setSelectedCreditCard(creditCard)
      setAnchorEl(event.currentTarget)
    },
    [setSelectedCreditCard],
  )

  const columns = useMemo<GridColDef<ICreditCard>[]>(
    () => [
      {
        field: 'default',
        headerName: '',
        flex: isMobile ? 2 : 1,
        sortable: false,
        valueGetter: (param) => param.row.isDefault,
        type: 'boolean',
        renderCell: (param) =>
          param.row.isDefault && (
            <Chip
              sx={{
                '&.MuiChip-colorInfo': {
                  backgroundColor: 'var(--info-bg-color)',
                  color: 'var(--info-color)',
                },
              }}
              label='Default'
              color='info'
            />
          ),
      },
      {
        field: 'brand',
        headerName: 'Brand',
        flex: isMobile ? 2 : 1,
        sortable: false,
        renderCell: (param) => `${param.row.cardType}`,
      },
      {
        field: 'number',
        headerName: 'Number',
        flex: 2,
        sortable: false,
        renderCell: (param) => `**** **** **** ${param.row.lastDigits}`,
      },
      {
        field: 'expirationDate',
        headerName: isMobile ? 'Exp. date' : 'Expiration Date',
        flex: 2,
        sortable: false,
        renderCell: (param) => `${formatExpirationDate(param.row.expirationDate)}`,
      },
      {
        field: 'createdAt',
        headerName: 'Added Date',
        flex: 2,
        sortable: false,
        renderCell: (param) => `${formatDate(param.row.createdAt)}`,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1.5,
        sortable: false,
        renderCell: (param) => (
          <IconButton
            aria-label='more'
            id='long-button'
            aria-controls={openItemMenu ? 'long-menu' : undefined}
            aria-expanded={openItemMenu ? 'true' : undefined}
            aria-haspopup='true'
            onClick={handleMenuButtonClick(param.row)}
            sx={{
              justifyItems: 'right',
            }}
          >
            <MoreVertIcon />
          </IconButton>
        ),
      },
    ],
    [],
  )

  const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: 5,
    }
  }, [])

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleSetAsDefaultClick = () => {
    api
      .setCreditCardAsDefault(selectedCreditCard?.publicId)
      .then(() => {
        successToastNotification('The default credit card was changed successfully')
        dispatch(saveCertainCustomer(customer.publicId))
        handleCloseMenu()
      })
      .catch((err) => errorHandler(err))
  }

  const handleDeleteClick = () => {
    setIsOpenDeleteDialog(true)
    handleCloseMenu()
  }

  const handleDeleteConfirmation = () => {
    api.deleteCreditCard(selectedCreditCard?.publicId).then(() => {
      successToastNotification('The credit card was deleted successfully')
      dispatch(saveCertainCustomer(customer.publicId))
      handleDeleteCancel()
    })
  }

  const handleDeleteCancel = () => {
    setIsOpenDeleteDialog(false)
  }

  const handleAddCreditCard = () => {
    api
      .generateUSIOToken({
        merchantKey,
        paymentType: 'CreditCard',
        emailAddress: customer.primaryEmail,
        cardNumber: newCreditCard.number.split(' ').join(''),
        expDate: formatUSIOExpDate(newCreditCard.expirationDate),
        cvv: newCreditCard.cvv,
      })
      .then((res) => {
        api
          .createCreditCard({
            customerId: customer.publicId,
            cardType: res.data.cardBrand,
            lastDigits: res.data.last4,
            expirationDate: res.data.expDate,
            cardToken: res.data.token,
            name: newCreditCard.name,
          })
          .then((res) => {
            successToastNotification(`The credit card ${res.data.cardType} was successfully added`)
            dispatch(saveCertainCustomer(customer?.publicId))
            setIsNewCard(false)
          })
          .catch((err) => errorHandler(err))
      })
      .catch((err) => errorHandler(err))
  }

  return (
    <>
      {customer.creditCardList && customer.creditCardList.length > 0 ? (
        <DataGrid
          rows={customer.creditCardList || []}
          columns={columns}
          disableColumnMenu
          getRowId={(creditCard) => creditCard.publicId || ''}
          hideFooter
          getRowSpacing={getRowSpacing}
          rowHeight={65}
          columnHeaderHeight={24}
          disableRowSelectionOnClick
          initialState={{
            columns: {
              columnVisibilityModel: {
                number: !isMobile,
                createdAt: !isMobile,
              },
            },
            sorting: {
              sortModel: [{ field: 'default', sort: 'desc' }],
            },
          }}
          sx={{
            ...TableStyles,
            [`& .${gridClasses.cell}`]: {
              cursor: 'normal',
              paddingX: 1,
              paddingY: { xs: 1, sm: 0 },
            },
            [`& .${gridClasses.row}:hover, & .${gridClasses.row}.Mui-hovered`]: {
              bgcolor: 'white',
            },
          }}
        />
      ) : (
        <NoDataDisplay title='No data to display' mt={0} />
      )}

      <Link
        component='button'
        underline='none'
        color={isNewCard ? 'gray' : 'primary'}
        onClick={(e: MouseEvent) => {
          e.preventDefault()
          setIsNewCard(!isNewCard)
        }}
      >
        {`${isNewCard ? '-' : '+'} Add a new card`}
      </Link>

      {isNewCard && (
        <Box>
          <NewCreditCardForm
            creditCard={newCreditCard}
            setCreditCard={setNewCreditCard}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            showSaveFuturePayments={false}
            mb={2}
          />
          <Box>
            <Button variant='outlined' onClick={() => setIsNewCard(false)} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button variant='contained' onClick={handleAddCreditCard}>
              Confirm
            </Button>
          </Box>
        </Box>
      )}

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={openItemMenu}
        onClose={handleCloseMenu}
        sx={{
          '& .MuiMenuItem-root': {
            padding: { xs: '1rem', md: '1rem 4rem 1rem 2rem' },
          },
          '& .MuiMenuItem-root:hover': {
            backgroundColor: 'var(--secondary1-color)',
            color: 'var(--primary1-color)',
          },
        }}
      >
        <MenuItem onClick={handleSetAsDefaultClick}>Set as default</MenuItem>
        <MenuItem sx={{ color: 'var(--error-color)' }} onClick={handleDeleteClick}>
          Delete
        </MenuItem>
      </Menu>

      <ConfirmDialog
        isOpen={isOpenDeleteDialog}
        dialogTitle='Sure you want to delete?'
        dialogBodyContent='Deleting this item will permanently remove it from your account, and it cannot be undone.'
        buttonConfirmText='Yes, Delete'
        buttonCancelText='Cancel'
        handleClose={handleDeleteCancel}
        handleConfirm={handleDeleteConfirmation}
      />
    </>
  )
}

export default CreditCardsForm
