import { useSelector } from 'react-redux'
import { AppState } from '../redux/store'

export default function useRootState() {
  const currentCompany = useSelector((state: AppState) => state.homeReducer.company)

  const isRootAccount = () => {
    return currentCompany && currentCompany.isRoot
  }

  return { isRootAccount }
}
