import { Box, Card, TextField } from "@mui/material";

interface IFooter {
  footer?: string;
  handleOnChange?: any;
  placeholderText?: string
}

const FooterMobile = ({footer, handleOnChange, placeholderText}:IFooter) => {
  return (
    <Card sx={{
      width: '100%',
      boxShadow: '0px 5px 20px rgba(70, 101, 137, 0.35)',
      paddingTop: '20px',
      paddingBottom: '50px',
      marginBottom: '20px',
      marginTop: '20px'
    }}>
      <Box ml="40px" mr="40px">
        <TextField
          sx={{color: 'var--gray3-color'}}
          fullWidth
          InputProps={{
            sx: {
              '& fieldset': {
                borderColor: "var(--secondary-grey)"
              },
            },
          }}
          label="Footer"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder={placeholderText}
          multiline
          rows={4}
          value={footer}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleOnChange(e, 'footer')
          }
        />
      </Box>
    </Card>

  )
}

export default FooterMobile
