import { Grid, Typography } from '@mui/material'
import { ICustomer } from '../../types/types'
import { formatMoney, formatPhoneNumber } from '../../helpers/formatPrice'
import { AppState } from '../../redux/store'
import { useSelector } from 'react-redux'

interface StatementHeaderProps {
  customer: ICustomer
  type: string
}

const StatementHeader = ({ customer, type }: StatementHeaderProps) => {
  const outstandingStatements = useSelector(
    (state: AppState) => state.homeReducer.outstandingStatements,
  )

  return (
    <Grid container p={2} sx={{ color: 'var(--secondary-grey)', ml: '14px' }}>
      <Grid item md={7} sx={{ '& .MuiTypography-body1': { fontSize: '14px' } }}>
        <Typography fontWeight='bold' fontSize='16px !important'>
          Bill to
        </Typography>
        <Typography>{customer.name}</Typography>
        <Typography>{customer.billingAddress}</Typography>
        <Typography mb={1}>
          {`${customer.billingCity}, ${customer.billingState}	${customer.billingPostalCode}, ${customer.billingCountry}`}
        </Typography>
        <Typography>{formatPhoneNumber(customer.primaryPhoneNumber)}</Typography>
        <Typography>{customer.primaryEmail}</Typography>
      </Grid>
      {type === 'OUTSTANDING' && (
        <>
          <Grid item md={3} pr={1}>
            <Typography textAlign='right' fontWeight='bold'>
              Overdue
            </Typography>
            <Typography textAlign='right' fontWeight='bold'>
              Not paid yet
            </Typography>
          </Grid>
          <Grid
            item
            md={2}
            sx={{
              '& .MuiTypography-body1': { fontSize: '14px', lineHeight: '24px' },
            }}
          >
            <Typography>{formatMoney(outstandingStatements?.overdue)}</Typography>
            <Typography>{formatMoney(outstandingStatements?.notYetDue)}</Typography>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default StatementHeader
