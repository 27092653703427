/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import { Box } from '@mui/material'
import moment from 'moment'
import { ReactInstance, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import api from '../../../api/api'
import ButtonsFooter from '../../../components/buttons-footer/buttons-footer'
import FooterInput from '../../../components/footer/footer-desktop'
import FooterMobile from '../../../components/footer/footer-mobile'
import HeaderCreate from '../../../components/header/headerCreate'
import { AddItemButton } from '../../../components/plus-button/add-item-button'
import ClearItemsButtons from '../../../components/plus-button/clear-items-button'
import PreviewNew from '../../../components/preview-new'
import TotalSection from '../../../components/total-section/total-section'
import TotalSectionMobile from '../../../components/total-section/total-section-mobile'
import { errorHandler } from '../../../helpers/errorHandler'
import { formatPrice } from '../../../helpers/formatPrice'
import { isUsingMobile } from '../../../helpers/utils'
import {
  createNewInvoice,
  createNewScheduledInvoice,
  saveCertainCustomer,
  saveCertainInvoice,
  saveCertainSheduledInvoice,
  saveCustomers,
  saveInvoices,
  saveProducts,
  saveScheduledInvoiceTemplates,
} from '../../../redux/actions/home.actions'
import { AppState } from '../../../redux/store'
import { ICustomer, IInvoiceProductList } from '../../../types/types'
import CustomerManageDialog from '../../estimate-manage/customer-manage-dialog'
import ManageSectionMobile from '../../estimate-manage/estimage-manage-mobile'
import ManageSection from '../../estimate-manage/manage-section'
import EstimateTable from '../../estimate-manage/estimate-table'
import './invoice-manage.css'

export const paymentDueOptions = [
  { title: 'On Receipt', value: 0 },
  { title: 'Within 1 Day', value: 1 },
  { title: 'Within 7 Days', value: 7 },
  { title: 'Within 14 Days', value: 14 },
  { title: 'Within 30 Days', value: 30 },
  { title: 'Within 45 Days', value: 45 },
  { title: 'Within 60 Days', value: 60 },
  { title: 'Within 90 Days', value: 90 },
]

interface State {
  customer?: ICustomer
  recurringInvoice?: any
}

const InvoiceManage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const state = location?.state as State
  const { publicId } = useParams()
  const products = useSelector((state: AppState) => state.homeReducer.products)
  const customers = useSelector((state: AppState) => state.homeReducer.customers)
  const invoices = useSelector((state: AppState) => state.homeReducer.invoices)
  const chosenInvoice = useSelector((state: AppState) => state.homeReducer.chosenInvoice)
  const isRecurringInvoice = window.location.href.includes('recurring-invoices')
  const chosenScheduledInvoice = useSelector(
    (state: AppState) => state.homeReducer.chosenScheduledInvoice,
  )
  const preview = useRef<ReactInstance | null>(null)
  const chosenCustomer = useSelector((state: AppState) => state.homeReducer.chosenCustomer)
  const [isProductDialogOpen, setIsProductDialogOpen] = useState<boolean>(false)
  const [newInvoice, setNewInvoice] = useState<any>({
    name: '',
    customerPublicId: '',
    invoiceProductList: [
      {
        product: {
          publicId: '',
        },
        description: '',
        quantity: 1,
        price: 0,
        amount: 0,
      },
    ],
    currency: 'USD',
    periodStart: moment().format('YYYY-MM-DD HH:mm'),
    periodEnd: moment().format('YYYY-MM-DD HH:mm'),
    discounts: null,
    subTotal: null,
    total: null,
    poso: '',
    number: 'INV1',
    subheading: null,
    footer: '',
    memo: null,
    type: null,
  })
  const [customerItems, setCustomerItems] = useState<any>([])
  const [isNewCustomerDialogOpen, setIsNewCustomerDialogOpen] = useState<boolean>(false)
  const subtotal = newInvoice.invoiceProductList
    .filter((item: any) => item.price)
    .reduce((acc: any, item: any, index: number) => {
      acc += item.price * item.quantity
      return acc
    }, 0)

  useEffect(() => {
    dispatch(saveProducts())
    dispatch(saveCustomers())
  }, [])

  useEffect(() => {
    if (state?.recurringInvoice && publicId) {
      dispatch(saveCertainSheduledInvoice(publicId))
    } else {
      if (publicId) {
        dispatch(saveCertainInvoice(publicId))
      }
    }
    if (invoices.length) {
      setNewInvoice({
        ...newInvoice,
        number: `INV${invoices.length + 1}`,
      })
    }
  }, [publicId, invoices])

  useEffect(() => {
    if (customers.length) {
      const menuItemsArray = customers.map((customer: ICustomer) => {
        return { value: customer.publicId, title: customer.name }
      })
      setCustomerItems(menuItemsArray)
    }
  }, [customers])
  useEffect(() => {
    if (publicId && chosenScheduledInvoice && state?.recurringInvoice) {
      const changedSubscription = {
        ...chosenScheduledInvoice,
        invoiceProductList: chosenScheduledInvoice.scheduledInvoiceProductList,
        periodEnd: chosenScheduledInvoice.paymentDue < 0 ? 0 : chosenScheduledInvoice.paymentDue,
      }
      delete changedSubscription.scheduledInvoiceProductList
      delete changedSubscription.paymentDue
      setNewInvoice(changedSubscription)
    } else if (publicId && chosenInvoice && !state?.recurringInvoice) {
      setNewInvoice(chosenInvoice)
    }
  }, [chosenInvoice, chosenScheduledInvoice, publicId, state])

  const handleSaveClick = () => {
    const changedInvoiceProductList = newInvoice.invoiceProductList.length
      ? newInvoice.invoiceProductList.filter((product: IInvoiceProductList) => product.price)
      : []
    const changedInvoice = {
      ...newInvoice,
      total: subtotal,
      subTotal: subtotal,
      customerPublicId: currentPublicId,
      invoiceProductList: changedInvoiceProductList,
    }

    const changedRecurringInvoice = {
      name: newInvoice.name,
      poso: newInvoice.poso,
      customerPublicId: currentPublicId,
      scheduler: null,
      scheduledInvoiceProductList: changedInvoiceProductList,
      currency: 'USD',
      subTotal: subtotal,
      total: subtotal,
      memo: null,
      paymentDue: newInvoice.periodEnd ? 1 : 0,
    }

    if (state?.recurringInvoice) {
      if (publicId) {
        api
          .editScheduledInvoiceTemplate(changedRecurringInvoice, chosenScheduledInvoice?.publicId)
          .then((res) => {
            toast.success(`Invoice was successfully edited`, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
            })
            dispatch(saveScheduledInvoiceTemplates())
            navigate('/recurring-invoices')
          })
          .catch((err) => errorHandler(err))
      } else {
        dispatch(createNewScheduledInvoice(changedRecurringInvoice, navigate))
      }
    } else {
      if (publicId) {
        api
          .editInvoice(chosenInvoice?.publicId, changedInvoice)
          .then((res) => {
            toast.success(`Invoice was successfully edited`, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
            })
            dispatch(saveInvoices())
            navigate(`/invoices`)
          })
          .catch((err) => errorHandler(err))
      } else {
        dispatch(createNewInvoice(changedInvoice, navigate))
      }
    }
  }

  const handleChangeFields = (e: any, value: string, isDate?: boolean) => {
    if (value === 'customerPublicId' && e.target.value) {
      dispatch(saveCertainCustomer(e.target.value))
    }
    setNewInvoice({
      ...newInvoice,
      [value]:
        !state?.recurringInvoice && isDate ? moment(e).format('YYYY-MM-DD HH:mm') : e.target.value,
    })
  }

  const handleChangeEstimateTable = (
    e: React.ChangeEvent<HTMLInputElement>,
    productIndex: number,
    value: string,
  ) => {
    const changedProductsList = [...newInvoice.invoiceProductList]
    if (value === 'productName') {
      const chosenProduct = products.find((product) => product.publicId === e.target.value)
      changedProductsList.splice(productIndex, 1, {
        ...newInvoice.invoiceProductList[productIndex],
        product: {
          publicId: chosenProduct?.publicId,
        },
        description: chosenProduct?.description,
        price: chosenProduct?.price,
        quantity: 1,
      })
    } else {
      changedProductsList.splice(productIndex, 1, {
        ...newInvoice.invoiceProductList[productIndex],
        [value]: value === 'price' ? formatPrice(e.target.value) : e.target.value,
      })
    }
    setNewInvoice({
      ...newInvoice,
      invoiceProductList: changedProductsList,
    })
  }

  const handleClearAllItems = () => {
    setNewInvoice({
      ...newInvoice,
      invoiceProductList: [
        {
          product: {
            publicId: '',
          },
          description: '',
          quantity: 1,
          price: 0,
          amount: 0,
        },
      ],
    })
  }

  const handleDeleteLine = (productIndex: number) => {
    setNewInvoice({
      ...newInvoice,
      invoiceProductList: newInvoice.invoiceProductList.filter(
        (item: IInvoiceProductList, itemIndex: number) => itemIndex !== productIndex,
      ),
    })
  }

  const handleAddItem = (product?: any) => {
    setNewInvoice({
      ...newInvoice,
      invoiceProductList: [
        ...newInvoice.invoiceProductList,
        {
          product: {
            publicId: '',
          },
          description: '',
          quantity: 1,
          price: 0,
          amount: 0,
        },
      ],
    })
  }

  const currentPublicId = newInvoice.customerPublicId

  const handleCancel = () => {
    if (state?.recurringInvoice) {
      navigate(`/recurring-invoices`)
    } else {
      navigate(`/invoices`)
    }
  }

  const handleDialogClose = () => {
    setIsProductDialogOpen(false)
  }

  const isDisabledSaveButton = useMemo(() => {
    const hasEmptyCustomer = !newInvoice.customerPublicId
    const hasEmptyPeriodStart = isRecurringInvoice
      ? false
      : !newInvoice.periodStart && newInvoice.periodStart !== undefined
    const hasEmptyPeriodEnd = isRecurringInvoice
      ? false
      : !newInvoice.periodEnd && newInvoice.periodStart !== undefined
    const isEmptyProductList = newInvoice.invoiceProductList.length === 0
    const hasEmptyProductPublicId = newInvoice.invoiceProductList.some(
      (product: any) =>
        !product.product.publicId || !product.quantity || product.quantity <= 0 || !product.price,
    )
    const isWrongDates = isRecurringInvoice ? false : newInvoice.periodEnd < newInvoice.periodStart

    return (
      hasEmptyCustomer ||
      hasEmptyPeriodStart ||
      hasEmptyPeriodEnd ||
      isEmptyProductList ||
      hasEmptyProductPublicId ||
      isWrongDates
    )
  }, [
    newInvoice.customerPublicId,
    newInvoice.periodStart,
    newInvoice.periodEnd,
    newInvoice.invoiceProductList,
    newInvoice.periodEnd,
    newInvoice.periodStart,
  ])

  const isMobile = isUsingMobile()

  const getTitle = () => {
    if (isRecurringInvoice) {
      return newInvoice.publicId ? 'Edit Recurring Invoice' : 'New Recurring Invoice'
    } else {
      return newInvoice.publicId ? 'Edit Invoice' : 'New Invoice'
    }
  }

  return (
    <>
      <HeaderCreate
        title={getTitle()}
        saveTextButton='Save'
        cancelTextButton='Cancel'
        handleCancel={handleCancel}
        handleAcceptClick={handleSaveClick}
        isDisabledSaveButton={isDisabledSaveButton}
      />
      <div className={!isMobile ? 'estimate-manage__content' : 'estimate-manage__content_mobile'}>
        <div className={!isMobile ? 'estimate-manage__create' : ''}>
          {!isMobile ? (
            <ManageSection
              handleDeleteLine={handleDeleteLine}
              customerItems={customerItems}
              currentPublicId={currentPublicId}
              handleChangeFields={handleChangeFields}
              setIsNewCustomerDialogOpen={setIsNewCustomerDialogOpen}
              newInvoice={newInvoice}
              chosenCustomer={chosenCustomer}
              publicId={publicId}
              subtotal={subtotal}
              setIsProductDialogOpen={setIsProductDialogOpen}
              isProductDialogOpen={isProductDialogOpen}
              handleDialogClose={handleDialogClose}
              table={
                <EstimateTable
                  productsList={newInvoice?.invoiceProductList}
                  onChange={handleChangeEstimateTable}
                  handleDeleteLine={handleDeleteLine}
                  setIsProductDialogOpen={setIsProductDialogOpen}
                  isProductDialogOpen={isProductDialogOpen}
                  handleDialogClose={handleDialogClose}
                />
              }
              isInvoice
            />
          ) : (
            <ManageSectionMobile
              handleDeleteLine={handleDeleteLine}
              customerItems={customerItems}
              currentPublicId={currentPublicId}
              handleChangeFields={handleChangeFields}
              setIsNewCustomerDialogOpen={setIsNewCustomerDialogOpen}
              newInvoice={newInvoice}
              chosenCustomer={chosenCustomer}
              publicId={publicId}
              subtotal={subtotal}
              setIsProductDialogOpen={setIsProductDialogOpen}
              isProductDialogOpen={isProductDialogOpen}
              handleDialogClose={handleDialogClose}
              isInvoice
              table={
                <EstimateTable
                  productsList={newInvoice?.invoiceProductList}
                  onChange={handleChangeEstimateTable}
                  handleDeleteLine={handleDeleteLine}
                  setIsProductDialogOpen={setIsProductDialogOpen}
                  isProductDialogOpen={isProductDialogOpen}
                  handleDialogClose={handleDialogClose}
                />
              }
            />
          )}

          <div
            className={
              isMobile ? 'estimate-manage__buttons-mobile' : 'estimate-manage__table-buttons'
            }
          >
            <Box mr='10px'>
              <AddItemButton
                handleOnClick={handleAddItem}
                text='Add a line'
                sx={{ marginRight: '10px' }}
              ></AddItemButton>
            </Box>
            <ClearItemsButtons
              handleOnClick={handleClearAllItems}
              text='Clear all items'
            ></ClearItemsButtons>
          </div>
          {!isMobile && (
            <div className='estimate-manage__padding'>
              <div className='divider estimate-manage__divider'></div>
            </div>
          )}
          {isMobile ? (
            <TotalSectionMobile subtotal={subtotal} />
          ) : (
            <TotalSection subtotal={subtotal} />
          )}
          {!isMobile && (
            <div className='estimate-manage__padding'>
              <div className='divider estimate-manage__divider'></div>
            </div>
          )}
          {isMobile ? (
            <FooterMobile
              footer={newInvoice.footer}
              handleOnChange={handleChangeFields}
              placeholderText='Enter a footer for current invoice (some discount, tax, thank you notes)'
            />
          ) : (
            <FooterInput
              footer={newInvoice.footer}
              handleOnChange={handleChangeFields}
              placeholderText='Enter a footer for current invoice (some discount, tax, thank you notes)'
            />
          )}
          {isMobile && (
            <ButtonsFooter
              saveTextButton='Save'
              cancelTextButton='Cancel'
              handleCancel={handleCancel}
              handleAcceptClick={handleSaveClick}
              isDisabledSaveButton={isDisabledSaveButton}
            />
          )}
        </div>
      </div>
      <div style={{ display: 'none' }}>
        <PreviewNew
          item={state?.recurringInvoice ? null : chosenInvoice}
          preview={preview}
          customer={chosenCustomer}
        />
      </div>
      <CustomerManageDialog
        open={isNewCustomerDialogOpen}
        onClose={() => setIsNewCustomerDialogOpen(false)}
      />
    </>
  )
}

export default InvoiceManage
