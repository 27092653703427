import { Box, IconButton, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { AppState } from '../../redux/store'
import { useEffect, useState } from 'react'
import { saveUSIOApiKey } from '../../redux/actions/auth.actions'
import { getInvoiceByPublicId } from '../../redux/actions/home.actions'
import InvoicePreview from '../invoices/preview/invoicePreview'
import { formatPrice, formatUSIOExpDate } from '../../helpers/formatPrice'
import api from '../../api/api'
import { generalErrorHandler } from '../../helpers/errorHandler'
import { successToastNotification } from '../../helpers/toastNotification'
import { isUsingMobile } from '../../helpers/utils'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import logo from '../../assets/images/logo.png'
import logoMobile from '../../assets/images/logo-bc-condensed.png'
import PayNowCreditCardForm from './credit-card-form'
import PayNowInfo from './pay-now-info'

const PayNow = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const publicId = location.pathname.split('/').pop()
  const chosenInvoice = useSelector((state: AppState) => state.homeReducer.chosenInvoice)
  const chosenCustomer = useSelector((state: AppState) => state.homeReducer.chosenCustomer)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showInvoice, setShowInvoice] = useState<boolean>(false)
  const isPayNowUnavailable = useSelector((state: AppState) => state.homeReducer.payNowUnavailable)
  const merchantKey = useSelector((state: AppState) => state.authReducer.usioApiKey)
  const isMobile = isUsingMobile()

  const company = {
    name: chosenInvoice?.companyName,
    addressOne: chosenInvoice?.companyAddress,
    city: chosenInvoice?.companyCity,
    state: chosenInvoice?.companyState,
    postalCode: chosenInvoice?.companyPostalCode,
    country: chosenInvoice?.companyCountry,
    phone: chosenInvoice?.companyPhone,
    website: chosenInvoice?.companyWebsite,
    logoFileName: chosenInvoice?.companyLogo,
  }

  const [paymentData, setPaymentData] = useState({
    cardNumber: '',
    expDate: '',
    cvv: '',
    amount: 0,
    cardName: '',
  })

  useEffect(() => {
    if (!isPayNowUnavailable) {
      dispatch(getInvoiceByPublicId(publicId))
      dispatch(saveUSIOApiKey(publicId))
    }
  }, [publicId])

  useEffect(() => {
    setPaymentData({
      ...paymentData,
      amount: chosenInvoice ? Number(chosenInvoice?.total) - Number(chosenInvoice?.amountPaid) : 0,
    })
  }, [chosenInvoice])

  const handleChangePaymentData = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setPaymentData({
      ...paymentData,
      [value]: value === 'amount' ? formatPrice(e.target.value) : e.target.value,
    })
  }

  const handlePayNowClick = () => {
    setIsLoading(true)

    const usioRequest = {
      merchantKey,
      paymentType: 'CreditCard',
      emailAddress: chosenCustomer?.primaryEmail,
      cardNumber: paymentData.cardNumber.split(' ').join(''),
      expDate: formatUSIOExpDate(paymentData.expDate),
      cvv: paymentData.cvv,
    }

    api
      .generateUSIOToken(usioRequest)
      .then((res) => {
        if (res.data.status === 'FAILED') {
          generalErrorHandler(res.data.message)
          setIsLoading(false)
        } else {
          api
            .makeUnathorizedPayment({
              customerPublicId: chosenCustomer?.publicId,
              amount: formatPrice(paymentData.amount),
              invoicePublicId: chosenInvoice?.publicId,
              paymentType: 'CREDIT_CARD',
              usioToken: res.data.token,
              cardType: res.data.cardBrand,
              lastDigits: res.data.last4,
              expirationDate: res.data.expDate,
            })
            .then((res) => {
              if (res.data.body.status === 'FAILED') {
                generalErrorHandler(res.data.body.message)
              } else {
                successToastNotification(
                  `Payment for invoice #${chosenInvoice.name} was successfully recorded.`,
                )
                handleClearAllItemsClick()
                dispatch(getInvoiceByPublicId(publicId))
                setIsLoading(false)
              }
            })
            .catch((err) => {
              generalErrorHandler(err.message)
              setIsLoading(false)
            })
        }
      })
      .catch((err) => {
        generalErrorHandler(err.message)
        setIsLoading(false)
      })
  }

  const handleClearAllItemsClick = () => {
    setPaymentData({
      cardNumber: '',
      expDate: '',
      cvv: '',
      amount: chosenInvoice ? Number(chosenInvoice?.total) - Number(chosenInvoice?.amountPaid) : 0,
      cardName: '',
    })
  }

  return isPayNowUnavailable ? (
    <PayNowInfo
      title='The payment has been successfully completed.'
      message='You have already made a payment. Thank you!'
    />
  ) : (
    <Box sx={{ position: 'absolute', width: '100%', height: '100%', left: 0 }}>
      {chosenInvoice && !showInvoice && (
        <Box
          sx={{
            position: 'absolute',
            right: 0,
            left: { xs: 0, md: 'auto' },
            width: { xs: '100%', md: isMobile ? '100%' : '40%' },
            height: '100%',
            boxSizing: 'border-box',
            bgcolor: 'background.paper',
            boxShadow: 24,
            paddingTop: '2rem',
            p: '1rem',
            overflow: 'auto',
            mb: '-2rem',
          }}
        >
          {isMobile && <img src={logoMobile} width={200} />}
          <Typography component='h2' variant='h5' mb={2}>
            {`Record a payment for Invoice #${chosenInvoice.name}`}
          </Typography>
          <Typography mb={2} fontWeight='bold'>
            Card details
          </Typography>
          <PayNowCreditCardForm
            payment={paymentData}
            invoice={chosenInvoice}
            onChange={handleChangePaymentData}
            onConfirm={handlePayNowClick}
            setShowInvoice={setShowInvoice}
            onReset={handleClearAllItemsClick}
            isLoading={isLoading}
          />
        </Box>
      )}

      {!showInvoice && chosenInvoice && !isMobile && (
        <Box mt={2} sx={{ width: '50%', ml: '4%' }}>
          <img src={logo} width='32' style={{ float: 'left', margin: '0 1rem 0 1rem' }} />
          <Typography component='h2' variant='h5' ml={2}>
            Invoice #{chosenInvoice.name}
          </Typography>
          {chosenCustomer && (
            <InvoicePreview invoice={chosenInvoice} company={company} customer={chosenCustomer} />
          )}
        </Box>
      )}

      {showInvoice && isMobile && (
        <Box>
          <IconButton aria-label='back' onClick={() => setShowInvoice(false)}>
            <ArrowBackIosNewIcon />
          </IconButton>
          <InvoicePreview invoice={chosenInvoice} company={company} customer={chosenCustomer} />
        </Box>
      )}
    </Box>
  )
}

export default PayNow
