import { Grid, Typography } from '@mui/material'
import DashboardTooltip from '../../components/dashboard-tooltip'
import DahboardOverdueBar from './dashboard-overdue-bar'
import { IOutstandingStatistic } from '../../types/types'
import { isUsingMobile } from '../../helpers/utils'
import { useSelector } from 'react-redux'
import { AppState } from '../../redux/store'
import { formatMoney } from '../../helpers/formatPrice'

const OverdueInvoicesByCustomer = ({ totalAmount }: { totalAmount: number }) => {
  const isMobile = isUsingMobile()

  const overdueStatistics = useSelector(
    (state: AppState) => state.dashboardReducer.overdueStatistic,
  )

  const chartsAmount = overdueStatistics.filter(
    (statistic: IOutstandingStatistic) => statistic.amount != null,
  ).length

  const maxInvoicesAmount =
    overdueStatistics &&
    overdueStatistics.length &&
    Math.max(...overdueStatistics.map((data: any) => data.quantity))

  const disabledColors = [
    'linear-gradient(180deg, #83CBBB 0%, #B0D49C 100%)',
    'linear-gradient(180deg, #9D8EDC 0%, #9CAFF4 100%)',
    'linear-gradient(180deg, #90A1E6 0%, #37CBEB 100%, #8ED6E8 100%)',
    'linear-gradient(180deg, #ECA08C 0%, #EEC390 100%)',
    'linear-gradient(180deg, #E981A6 0%, #FBAA97 100%)',
  ]

  const disabledOverdueInvoices: DisabledOverdueInvoice[] = [
    {
      color: 'linear-gradient(180deg, #83CBBB 0%, #B0D49C 100%)',
      height: 75,
    },
    {
      color: 'linear-gradient(180deg, #9D8EDC 0%, #9CAFF4 100%)',
      height: 129,
    },
    {
      color: 'linear-gradient(180deg, #90A1E6 0%, #37CBEB 100%, #8ED6E8 100%)',
      height: 98,
    },
    {
      color: 'linear-gradient(180deg, #ECA08C 0%, #EEC390 100%)',
      height: 63,
    },
    {
      color: 'linear-gradient(180deg, #E981A6 0%, #FBAA97 100%)',
      height: 145,
    },
  ]

  const colorsOptions = ['#17a5e6', '#7919FF', '#D91668', '#FF6633', '#FFCB33']

  return (
    <>
      {!isMobile ? (
        <div style={{ position: 'relative' }}>
          <Grid
            container
            sx={{
              backgroundColor: 'white',
              borderRadius: '10px',
              paddingX: '50px',
              paddingBottom: '50px',
            }}
          >
            <Grid item className='dashboard__block'>
              <Grid item xs={6}>
                <Typography color='var(--secondary-grey)' mt='50px' variant='h5' fontWeight='600'>
                  {'Overdue Invoices by customer'}
                </Typography>
              </Grid>

              <Grid item xs={6} mt='50px' mr='50px' ml='200px'>
                <DashboardTooltip
                  text={`The graph shows all overdue invoices of 90+ days. Each color indicate to a specific period (previous section).
                        The customer list shows the owner of the overdue invoice for a specific period of time for the largest amount.`}
                />
              </Grid>
            </Grid>

            <Grid
              item
              sx={{
                filter: chartsAmount < 5 ? 'blur(4px)' : 'none',
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '50px',
                flexDirection: isMobile ? 'column' : 'row',
              }}
            >
              <Grid item xs={3} style={{ marginBottom: isMobile ? '50px' : '0px' }}>
                <Typography mb='30px' color='var(--secondary-grey)' variant='h4' fontWeight='400'>
                  {formatMoney(Number(totalAmount))}
                </Typography>
                {chartsAmount
                  ? overdueStatistics
                      .slice(0, chartsAmount)
                      .map((data: IOutstandingStatistic, dataIndex: number) => (
                        <OutstandingCustomer
                          key={dataIndex}
                          color={colorsOptions[dataIndex]}
                          text={`${data.customerName} - $${data.customerAmount}`}
                        />
                      ))
                  : null}
                {disabledColors.slice(chartsAmount).map((data: string, dataIndex: number) => (
                  <OutstandingCustomer
                    key={dataIndex + data}
                    color={data}
                    text='User name - $0,00.00'
                    isDisabled
                  />
                ))}
              </Grid>
              <Grid item xs={9} className='dashboard-outstanding-chart__wrapper'>
                {chartsAmount
                  ? overdueStatistics
                      .slice(0, chartsAmount)
                      .map((data: IOutstandingStatistic, dataIndex: number) => (
                        <DahboardOverdueBar
                          key={dataIndex + dataIndex}
                          color={colorsOptions[dataIndex]}
                          maxInvoicesAmount={maxInvoicesAmount}
                          title={data.quantity || 0}
                          disabledColors={disabledColors}
                          barIndex={dataIndex}
                          disabledHeight={15}
                        />
                      ))
                  : null}
                {disabledOverdueInvoices
                  .slice(chartsAmount)
                  .map((data: DisabledOverdueInvoice, dataIndex: number) => {
                    return (
                      <DahboardOverdueBar
                        key={dataIndex + data.color}
                        color={data.color}
                        maxInvoicesAmount={maxInvoicesAmount}
                        title={0}
                        isDisabled
                        disabledHeight={data.height}
                        disabledColors={disabledColors}
                        barIndex={dataIndex}
                      />
                    )
                  })}
              </Grid>
            </Grid>
          </Grid>
          {chartsAmount < 5 ? (
            <div
              className='dashboard-outstanding-chart__disabled'
              style={{
                position: 'absolute',
                height: '140px',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div>Not enough data to display on the graph</div>
            </div>
          ) : null}
        </div>
      ) : (
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: '10px',
            padding: '0 10px 10px',
            width: '100%',
            position: 'relative',
          }}
        >
          <Typography color='var(--secondary-grey)' mt='50px' variant='h5' fontWeight='600'>
            {'Overdue Invoices by customer'}
          </Typography>
          <Grid
            item
            sx={{
              filter: chartsAmount < 5 ? 'blur(4px)' : 'none',
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '50px',
              flexDirection: isMobile ? 'column' : 'row',
            }}
          >
            <Grid item xs={3} style={{ marginBottom: isMobile ? '50px' : '0px' }}>
              <Typography mb='30px' color='var(--secondary-grey)' variant='h4' fontWeight='400'>
                {formatMoney(Number(totalAmount))}
              </Typography>
              {chartsAmount
                ? overdueStatistics
                    .slice(0, chartsAmount)
                    .map((data: IOutstandingStatistic, dataIndex: number) => (
                      <OutstandingCustomer
                        key={dataIndex}
                        color={colorsOptions[dataIndex]}
                        text={`${data.customerName} - $${data.customerAmount}`}
                      />
                    ))
                : null}
              {disabledColors.slice(chartsAmount).map((data: string, dataIndex: number) => (
                <OutstandingCustomer
                  key={dataIndex + data}
                  color={data}
                  text='User name - $0,000.00'
                  isDisabled
                />
              ))}
            </Grid>
            <Grid item xs={9} className='dashboard-outstanding-chart__wrapper'>
              {chartsAmount
                ? overdueStatistics
                    .slice(0, chartsAmount)
                    .map((data: IOutstandingStatistic, dataIndex: number) => (
                      <DahboardOverdueBar
                        key={dataIndex + dataIndex}
                        color={colorsOptions[dataIndex]}
                        maxInvoicesAmount={maxInvoicesAmount}
                        title={data.quantity || 0}
                        disabledColors={disabledColors}
                        barIndex={dataIndex}
                        disabledHeight={15}
                      />
                    ))
                : null}
              {disabledOverdueInvoices
                .slice(chartsAmount)
                .map((data: DisabledOverdueInvoice, dataIndex: number) => {
                  return (
                    <DahboardOverdueBar
                      key={dataIndex + data.color}
                      color={data.color}
                      maxInvoicesAmount={maxInvoicesAmount}
                      title={0}
                      isDisabled
                      disabledHeight={data.height}
                      disabledColors={disabledColors}
                      barIndex={dataIndex}
                    />
                  )
                })}
            </Grid>
          </Grid>
          {chartsAmount < 5 ? (
            <div
              className='dashboard-outstanding-chart__disabled'
              style={{
                position: 'absolute',
                height: '140px',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div>Not enough data to display on the graph</div>
            </div>
          ) : null}
        </div>
      )}
    </>
  )
}

interface IOutstandingCustomer {
  color: string
  text: string
  isDisabled?: boolean
}

const OutstandingCustomer = (props: IOutstandingCustomer) => {
  const { color, text, isDisabled } = props
  return (
    <div className='dashboard-outstanding-customer'>
      <div style={{ background: color }} className='dashboard-outstanding-customer-color'></div>
      <div className={isDisabled ? 'dashboard-outstanding-customer-disabled-color' : ''}>
        <Typography sx={{ color: 'var(--secondary-grey)' }}>{text}</Typography>
      </div>
    </div>
  )
}

interface DisabledOverdueInvoice {
  color: string
  height: number
}

export default OverdueInvoicesByCustomer
