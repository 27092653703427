import { formatMoney } from '../../helpers/formatPrice'

interface PreviewDetailsProps {
  items: any[]
  accentColor: string
}

const PreviewDetails = ({ items, accentColor }: PreviewDetailsProps) => {
  return (
    <table style={{ width: '100%', borderCollapse: 'collapse', borderSpacing: 0 }}>
      <thead
        style={{
          color: '#161313',
          verticalAlign: 'middle',
        }}
      >
        <tr
          style={{
            backgroundColor: '#f7f8fa',
            display: 'table-row',
            verticalAlign: 'middle',
            outline: 0,
            color: accentColor,
            fontWeight: 600,
            fontSize: 12,
          }}
        >
          <td style={{ padding: 16 }}>Item</td>
          <td style={{ padding: 16 }}>Description</td>
          <td style={{ padding: 16 }}>Quantity</td>
          <td style={{ padding: 16 }}>Price</td>
          <td style={{ padding: 16 }}>Amount</td>
        </tr>
      </thead>
      <tbody>
        {items && items.length
          ? items.map((row: any) => (
              <tr
                key={row.publicId}
                style={{
                  borderBottom: '1px solid #D9D9D9',
                  color: '#4d5e80',
                  fontSize: 11,
                }}
              >
                <td style={{ padding: 14 }}>{row.product.name}</td>
                <td style={{ padding: 14 }}>{row.product.description}</td>
                <td style={{ padding: 14 }}>{row.quantity}</td>
                <td style={{ padding: 14 }}>{formatMoney(row.price)}</td>
                <td style={{ padding: 14 }}>{formatMoney(row.quantity * row.price)}</td>
              </tr>
            ))
          : null}
      </tbody>
    </table>
  )
}

export default PreviewDetails
