import { Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material'
import SelectorMui from '../../../components/selector-mui'
import DeleteButtonTable from '../../../components/delete-icon/deleteIcon'
import { INewBill, INewBillToProduct } from '../../../types/types'
import useAuthorization from '../../../helpers/useAuthorization'

interface BillFormTableProps {
  bill: INewBill
  onChangeBillItem: any
  onDeleteItem: any
  products: any
}

const BillFormTable = ({ bill, onChangeBillItem, onDeleteItem, products }: BillFormTableProps) => {
  const { isAuthorized } = useAuthorization()

  return (
    <Table>
      <TableHead>
        <TableRow sx={{ backgroundColor: 'var(--background-color)' }}>
          <TableCell width='30%'>Item</TableCell>
          <TableCell width='30%'>Description</TableCell>
          <TableCell width='10%'>Quantity</TableCell>
          <TableCell width='10%'>Price</TableCell>
          <TableCell width='10%'>Amount</TableCell>
          <TableCell width='10%'></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {bill.items.map((item: INewBillToProduct, index: number) => {
          return (
            <TableRow key={index}>
              <TableCell>
                <SelectorMui
                  isAddButton={isAuthorized('write_purchase_product')}
                  addButtonText='Add new product'
                  options={products}
                  value={item?.purchaseProductPublicId || ''}
                  onChange={onChangeBillItem(index, 'purchaseProductPublicId')}
                  onAddIconClick={() => console.log('Create new product')}
                  placeholder='Select a Service/Product'
                  fullWidth
                />
              </TableCell>
              <TableCell>
                <TextField
                  fullWidth
                  placeholder='Enter a description'
                  value={item.description}
                  onChange={onChangeBillItem(index, 'description')}
                />
              </TableCell>
              <TableCell>
                <TextField
                  fullWidth
                  type='number'
                  placeholder='Enter quantity'
                  value={item.quantity}
                  onChange={onChangeBillItem(index, 'quantity')}
                />
              </TableCell>
              <TableCell>
                <TextField
                  fullWidth
                  type='number'
                  placeholder='Enter price'
                  value={item.price}
                  onChange={onChangeBillItem(index, 'price')}
                />
              </TableCell>
              <TableCell>
                <TextField
                  fullWidth
                  type='number'
                  placeholder='Enter amount'
                  value={item.price * item.quantity}
                  disabled
                />
              </TableCell>
              <TableCell>
                <DeleteButtonTable handleOnDeleted={onDeleteItem(index)}></DeleteButtonTable>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default BillFormTable
