import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { saveReferrals } from '../../redux/actions/home.actions'

import downloadIcon from '../../assets/icons/Icon-button-download.png'
import ContentHeader from '../../components/content-header'
import { isUsingMobile } from '../../helpers/utils'
import { Box } from '@mui/system'
import ReferralsTable from './referrals-table'
import PlusButton from '../../components/plus-button/plus-button'
import DeleteDialog from '../../components/delete-referral-dialog/delete-referral-dialog'
import ReferralsModal from './referrals-modal'
import { successToastNotification } from '../../helpers/toastNotification'
import api from '../../api/api'
import CreateReferralModal from './create-referral-modal'
import ReferenceMenu from './reference-menu'
import { saveAs } from 'file-saver'
import { Tooltip } from '@mui/material'
import { AppState } from '../../redux/store'
import NoDataDisplay from '../../components/noDataDisplay/noDataDisplay'

const Referrals = () => {
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState('')
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false)
  const [referralSelected, setReferralSelected] = useState<any>()
  const referralsData = useSelector((state: AppState) => state.authReducer.referralsData).content

  const isMobile = isUsingMobile()

  const handleViewClick = () => {
    setShowModal('EDIT')
  }

  const handleCreateClick = () => {
    setShowModal('CREATE')
  }

  const handleDownloadClick = () => {
    api
      .createExcel()
      .then((response) => {
        const blob = response.data

        const fetchData = async () => {
          try {
            saveAs(blob, 'referral_data.xlsx')
          } catch (error) {
            console.error('Error while fetching referrals', error)
          }
        }

        fetchData()
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  const handleDeleteClick = () => {
    api
      .deleteReferral(referralSelected.publicId)
      .then(() => {
        setShowModal('')
        successToastNotification(`Referral deleted`)
        const fetchData = async () => {
          try {
            await dispatch(saveReferrals())
          } catch (error) {
            console.error('Error fetching Referrals data', error)
          }
        }

        fetchData()
      })
      .catch((error: any) => {
        console.error('Error:', error)
      })
  }

  const handleResendClick = () => {
    api
      .resendEmail(referralSelected.publicId)
      .then(() => {
        setShowModal('')
        successToastNotification(`Mail sended successfully`)
        const fetchData = async () => {
          try {
            await dispatch(saveReferrals())
          } catch (error) {
            console.error('Error fetching Referrals data', error)
          }
        }

        fetchData()
      })
      .catch((error: any) => {
        console.error('Error:', error)
      })
  }

  useEffect(() => {
    dispatch(saveReferrals())
  }, [dispatch])

  return (
    <>
      <div style={{ display: 'flex', marginTop: !isMobile ? '0px' : '10px' }}>
        <ContentHeader title={<Box>Referrals{isMobile && <ReferenceMenu />}</Box>} />
      </div>
      <div
        onClick={handleDownloadClick}
        style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }}
      >
        <Tooltip title='Download referrals Excel' arrow>
          <img src={downloadIcon} />
        </Tooltip>
      </div>
      <Box pb={4}>
        {referralsData && referralsData.length > 0 ? (
          <ReferralsTable
            onRowClick={handleViewClick}
            onDeleteClick={handleDeleteClick}
            onResendClick={handleResendClick}
            setReferralSelected={setReferralSelected}
          />
        ) : (
          <NoDataDisplay
            title='No data to display'
            description='Add Candidates for Blue Cimarron and Monitor Their Application Status.'
          />
        )}
      </Box>
      <PlusButton tooltipText='Add Referral' handleOnClick={handleCreateClick} />
      {showModal === 'EDIT' && (
        <ReferralsModal
          showModal={showModal}
          setShowModal={setShowModal}
          resendEmail={handleResendClick}
          referral={referralSelected}
          handleDeleteClick={handleDeleteClick}
        />
      )}
      {showModal === 'CREATE' && (
        <CreateReferralModal showModal={showModal} setShowModal={setShowModal} />
      )}
      <DeleteDialog
        isOpen={isOpenDeleteDialog}
        dialogTitle='Delete referral?'
        buttonConfirmText='Yes'
        buttonCancelText='Back'
        handleClose={() => setIsOpenDeleteDialog(false)}
        handleDelete={handleDeleteClick}
      />
    </>
  )
}

export default Referrals
