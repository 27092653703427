import { Box, Button, Typography } from '@mui/material'
import { ILoginValues } from '../../pages/sign-in/sign-in'
import RegistrationField from '../registration-field'
import { useState } from 'react'
import { VALID_PASSWORD } from '../../helpers/constants'
import 'react-toastify/dist/ReactToastify.css'
import visibilityIcon from '../../assets/icons/visibility.png'
import visibilityOffIcon from '../../assets/icons/visibility-off.png'

interface IChangePassword {
  initialValues: ILoginValues
  handleChangeValues: (e: React.ChangeEvent<HTMLInputElement>, value: string) => void
  handleButtonClick: any
  handleSignInClickPage: any
}

const ChangePassword = ({
  initialValues,
  handleChangeValues,
  handleButtonClick,
  handleSignInClickPage,
}: IChangePassword) => {
  const [showResetPassword, setShowResetPassword] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)

  const handleClickIconConfirmPass = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const handleClickIconResetPass = () => {
    setShowResetPassword(!showResetPassword)
  }

  return (
    <>
      <Typography
        mt={2}
        id='modal-modal-title'
        variant='h4'
        fontWeight={700}
        color='var(--dark-grey-title)'
      >
        Reset password
      </Typography>
      <Typography
        mt={3}
        id='modal-modal-subtitle'
        fontWeight={400}
        color='var(--light-grey-title)'
        style={{ marginBottom: '20px' }}
      >
        Please choose a new password
      </Typography>
      <RegistrationField
        placeholder='New password'
        styles='sign-in__input'
        withIcon
        icon={showResetPassword ? visibilityOffIcon : visibilityIcon}
        type={showResetPassword ? 'text' : 'password'}
        onIconClick={handleClickIconResetPass}
        iconPosition='end'
        value={initialValues.resetPassword}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChangeValues(e, 'resetPassword')
        }
        validataionSchema={VALID_PASSWORD.test(initialValues.resetPassword)}
        // eslint-disable-next-line max-len
        errorMessage='Invalid Format'
        name='sign-in-reset-pass'
      />
      <RegistrationField
        placeholder='Confirm new password'
        styles='sign-in__input'
        withIcon
        icon={showConfirmPassword ? visibilityOffIcon : visibilityIcon}
        type={showConfirmPassword ? 'text' : 'password'}
        onIconClick={handleClickIconConfirmPass}
        iconPosition='end'
        value={initialValues.confirmPassword}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChangeValues(e, 'confirmPassword')
        }
        validataionSchema={VALID_PASSWORD.test(initialValues.confirmPassword)}
        // eslint-disable-next-line max-len
        errorMessage='Invalid Format'
        name='sign-in-confirm-password'
      />
      <div>
        <Button
          sx={{ fontWeight: 600 }}
          color='primary'
          variant='contained'
          onClick={handleButtonClick}
          fullWidth
        >
          Change password
        </Button>
      </div>
      <div>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
            <Typography>{'Do you want to use a different account?'}</Typography>
            <span
              className='highlighted-text'
              onClick={() => handleSignInClickPage()}
              id='register-now__link'
              style={{ marginLeft: '8px' }}
            >
              <Typography>Sign in</Typography>
            </span>
          </Box>
        </div>
    </>
  )
}

export default ChangePassword
