import './square-checkbox.css';

interface ISquareCheckbox {
	isChecked: boolean;
}

const SquareCheckbox = (props: ISquareCheckbox) => {
	const { isChecked } = props;
	return (
		<div className="square-checkbox-unckecked">
			{isChecked ? <div className="square-checkbox-checked"></div> : null}
		</div>
	);
};

export const CirclecCheckbox = (props: ISquareCheckbox) => {
	const { isChecked } = props;
	return (
		<div
			className={`circle-checkbox ${isChecked ? 'circle-checkbox-active' : ''}`}
		></div>
	);
};

export default SquareCheckbox;
