import { Avatar, Box, Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { getCdnUrl } from '../../helpers/utils'
import { AppState } from '../../redux/store'
import { ICompany } from '../../types/types'

interface CompanySectionProps {
  mb?: number | string
}

const CompanySection = ({ mb }: any) => {
  const company = useSelector<AppState, ICompany | null>(
    (state: AppState) => state.homeReducer.company,
  )

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mb={mb}>
      <Grid
        marginLeft={'30px'}
        color={'var(--grey-fonts)'}
        item
        xs={8}
        sm={6}
        flexDirection='column'
      >
        <Typography fontWeight={600}>{company?.name}</Typography>
        <Typography fontSize={'14px'}>{company?.addressOne}</Typography>
        <Typography fontSize={'14px'}>
          {company?.city}, {company?.state}, {company?.postalCode}, {company?.country}
        </Typography>
        <Typography marginTop={'20px'} fontSize={'14px'}>
          {company?.phone}
        </Typography>
        <Typography fontSize={'14px'}>{company?.website}</Typography>
      </Grid>
      <Grid marginRight={'30px'} item xs={4} sm={6}>
        <Avatar sx={{ width: 100, height: 100 }} src={getCdnUrl(company?.logoFileName)} />
      </Grid>
    </Box>
  )
}

export default CompanySection
