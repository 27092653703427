import { Box, TextField } from '@mui/material'
import React from 'react'

interface IFooter {
  footer?: string
  handleOnChange?: any
  placeholderText?: string
}

const FooterInput = ({ footer, handleOnChange, placeholderText }: IFooter) => {
  return (
    <Box ml='40px' mr='40px'>
      <TextField
        sx={{ color: 'var--gray3-color' }}
        fullWidth
        InputProps={{
          sx: {
            '& fieldset': {
              borderColor: 'var(--secondary-grey)',
            },
          },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        label='Footer'
        placeholder={placeholderText}
        multiline
        rows={4}
        value={footer}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChange(e, 'footer')}
      />
    </Box>
  )
}

export default FooterInput
