import { Box } from '@mui/material'
import ContentHeader from '../../components/content-header'
import { isUsingMobile } from '../../helpers/utils'
import ReferenceMenu from './reference-menu'
import BillFilters from './filters'
import NoDataDisplay from '../../components/noDataDisplay/noDataDisplay'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../redux/store'
import BillsTable from './table'
import { IBill, INewBillPayment, IVendor } from '../../types/types'
import { SyntheticEvent, useEffect, useState } from 'react'
import { saveBills } from '../../redux/actions/home.actions'
import { useNavigate } from 'react-router-dom'
import { successToastNotification } from '../../helpers/toastNotification'
import { errorHandler, generalErrorHandler } from '../../helpers/errorHandler'
import api from '../../api/api'
import useAuthorization from '../../helpers/useAuthorization'
import PlusButton from '../../components/plus-button/plus-button'
import ConfirmDialog from '../../components/dialog/dialog'
import ListFilters from '../../components/list-filters'
import moment from 'moment'
import PaginationNew from '../../components/pagination-new'
import BillPaymentModal from './payment/modal'
import { AxiosResponse } from 'axios'
import ViewBillDialog from './view-bill-dialog'

const Bills = () => {
  const dispatch = useDispatch()
  const isMobile = isUsingMobile()
  const navigate = useNavigate()
  const { isAuthorized } = useAuthorization()
  const bills = useSelector((state: AppState) => state.homeReducer.bills)
  const billsSize = useSelector((state: AppState) => state.homeReducer.billsSize)
  const [selectedBill, setSelectedBill] = useState<IBill | null>()
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState<boolean>(false)
  const [isOpenViewDialog, setIsOpenViewDialog] = useState<boolean>(false)
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState('')
  const [page, setPage] = useState(1)

  useEffect(() => {
    dispatch(saveBills())
  }, [dispatch])

  const handleViewClick = (bill: IBill) => {
    if (showPaymentModal === false) {
      setSelectedBill(bill)
      setIsOpenViewDialog(true)
    }
  }

  const handleDeleteClick = () => {
    setIsOpenDeleteDialog(true)
  }

  const handleEditClick = () => {
    selectedBill && navigate(`/bills/${selectedBill.publicId}/edit`)
  }

  const handleDuplicateClick = () => {
    if (!selectedBill) {
      return
    }

    api
      .duplicateBill(selectedBill.publicId)
      .then(() => {
        dispatch(saveBills())
        successToastNotification('Bill was successfully duplicated')
      })
      .catch((err) => errorHandler(err))
  }

  const handleCloseDeleteDialog = () => {
    setSelectedBill(null)
    setIsOpenDeleteDialog(false)
  }

  const handleDeleteBill = () => {
    setIsOpenDeleteDialog(false)
    api
      .deleteBill(selectedBill?.publicId)
      .then(() => {
        successToastNotification(`${selectedBill?.name} was successfully deleted`)
        dispatch(saveBills())
      })
      .catch((err) => errorHandler(err))
    setSelectedBill(null)
  }

  const handleSelectedStatusChange = (event: SyntheticEvent, status: string) => {
    setSelectedStatus(status)
    dispatch(saveBills(page - 1, 15, status))
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    dispatch(saveBills(value - 1, 15, selectedStatus, '', '', ''))
  }

  const handlePaymentConfirm = (payment: INewBillPayment) => () => {
    selectedBill &&
      api
        .recordBillPayment(selectedBill.publicId, {
          date: moment(payment.date).format('YYYY-MM-DD HH:mm'),
          amount: payment.amount,
          method: payment.method,
          checkNumber: payment.checkNumber,
          financialAccountPublicId: payment.financialAccountPublicId,
        })
        .then((res: AxiosResponse) => {
          if (res.data.body.status === 'FAILED') {
            generalErrorHandler(res.data.body.message)
          } else {
            successToastNotification('The payment was stored successfully')
            setSelectedStatus('')
            dispatch(saveBills())
            setShowPaymentModal(false)
            setSelectedBill(undefined)
          }
        })
        .catch((err) => errorHandler(err))
  }

  const handleSearchClick = (from: Date, to: Date, vendor: IVendor) => () => {
    dispatch(
      saveBills(
        page - 1,
        15,
        selectedStatus,
        from ? moment(from).startOf('day').format('YYYY-MM-DDTHH:mm') : '',
        to ? moment(to).endOf('day').format('YYYY-MM-DDTHH:mm') : '',
        vendor?.publicId || '',
      ),
    )
  }

  const getDisabledItems = () => {
    if (!selectedBill || !selectedBill.status) {
      return []
    }

    if(selectedBill.status === 'PAID') {
        return ['edit']
    }

    return []
  }

  return (
    <>
      <ContentHeader title={<Box>Bills {isMobile && <ReferenceMenu />}</Box>} />

      <ListFilters
        filters={[
          { title: 'All', value: '' },
          { title: 'Partial', value: 'PARTIAL' },
          { title: 'Paid', value: 'PAID' },
          { title: 'Overdue', value: 'OVERDUE' },
          { title: 'Open', value: 'OPEN' },
        ]}
        selectedFilter={selectedStatus}
        onSelectedFilterChange={handleSelectedStatusChange}
      />

      <BillFilters onSearchClick={handleSearchClick} />

      {bills && bills.length > 0 ? (
        <Box pb={4}>
          <BillsTable
            onRowClick={handleViewClick}
            setSelectedBill={setSelectedBill}
            selectedBill={selectedBill}
            onDeleteClick={handleDeleteClick}
            onEditClick={handleEditClick}
            onDuplicateClick={handleDuplicateClick}
            disabledItems={getDisabledItems()}
            onRecordPaymentClick={() => setShowPaymentModal(true)}
          />
          {billsSize > 15 ? (
            <PaginationNew
              size={15}
              quantity={billsSize}
              page={page}
              handleChange={handleChangePage}
            />
          ) : null}
        </Box>
      ) : (
        <NoDataDisplay
          mt={10}
          title='No data to display'
          description='Create bills from vendors and mark them as paid to easily monitor and manage your expenses.'
        />
      )}

      {isAuthorized('write_bill') && (
        <PlusButton tooltipText='Add Bill' handleOnClick={() => navigate('/bills/add')} />
      )}

      <ConfirmDialog
        isOpen={isOpenDeleteDialog}
        dialogTitle='Sure you want to delete?'
        dialogBodyContent='Deleting this vendor will permanently remove it from your account, and it cannot be undone.'
        buttonConfirmText='Yes, Delete'
        buttonCancelText='Cancel'
        handleClose={handleCloseDeleteDialog}
        handleConfirm={handleDeleteBill}
      />

      {selectedBill && (
        <BillPaymentModal
          showModal={showPaymentModal}
          setShowModal={setShowPaymentModal}
          bill={selectedBill}
          onPaymentConfirm={handlePaymentConfirm}
        />
      )}

      {isOpenViewDialog && selectedBill && (
        <ViewBillDialog
          showModal={isOpenViewDialog}
          onEditClick={() => navigate(`/bills/${selectedBill.publicId}/edit`)}
          onDeleteClick={handleDeleteClick}
          onDuplicateClick={handleDuplicateClick}
          setShowModal={setIsOpenViewDialog}
          bill={selectedBill}
        />
      )}
    </>
  )
}

export default Bills
