import { Button, Grid } from '@mui/material'
import { useMemo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import api from '../../api/api'
import ContentHeader from '../../components/content-header'
import ConfirmDialog from '../../components/dialog/dialog'
import BaseModal from '../../components/modal/modal'
import NoDataDisplay from '../../components/noDataDisplay/noDataDisplay'
import PlusButton from '../../components/plus-button/plus-button'
import { errorHandler } from '../../helpers/errorHandler'
import { formatPrice } from '../../helpers/formatPrice'
import { isUsingMobile } from '../../helpers/utils'
import { createNewProduct, saveProducts } from '../../redux/actions/home.actions'
import { AppState } from '../../redux/store'
import { INewProduct, IProduct } from '../../types/types'
import './products.css'
import ProductTable from './productTable'
import ProductView from './productView'
import useAuthorization from '../../helpers/useAuthorization'
import { successToastNotification } from '../../helpers/toastNotification'

const Products = () => {
  const dispatch = useDispatch()
  const { isAuthorized } = useAuthorization()
  const products = useSelector((state: AppState) => state.homeReducer.products)
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState<boolean>(false)
  const [isProductManageDialogOpen, setIsProductManageDialogOpen] = useState<boolean>(false)
  const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null)
  const [currentProduct, setCurrentProduct] = useState<INewProduct>({
    name: '',
    price: '',
    description: '',
    accountPublicId: '',
  })

  const [formErrors, setFormErrors] = useState({
    name: '',
    price: '',
    accountPublicId: '',
  })

  const isDisabledSaveButton = useMemo(() => {
    return (
      Object.values(formErrors).some((error) => error !== '') ||
      !currentProduct.name ||
      !currentProduct.price ||
      !currentProduct.accountPublicId
    )
  }, [formErrors, currentProduct.name, currentProduct.price, currentProduct.accountPublicId])

  const resetErrors = () => {
    setFormErrors({ name: '', price: '', accountPublicId: '' })
  }

  const handleCancel = () => {
    setSelectedProduct(null)
    setIsProductManageDialogOpen(false)
    resetErrors()
  }

  const handleCreateProductClick = () => {
    setIsProductManageDialogOpen(true)
  }

  useEffect(() => {
    dispatch(saveProducts())
  }, [])

  const handleDeleteProduct = () => {
    setIsOpenDeleteDialog(false)
    api
      .deleteProduct(selectedProduct?.publicId)
      .then(() => {
        successToastNotification(`${selectedProduct?.name} was successfully deleted`)
        dispatch(saveProducts())
      })
      .catch((err) => errorHandler(err))
    setSelectedProduct(null)
  }

  const handleAcceptClick = () => {
    if (selectedProduct) {
      api
        .editProduct(
          { ...currentProduct, price: formatPrice(currentProduct.price) },
          selectedProduct.publicId,
        )
        .then(() => {
          successToastNotification(`${selectedProduct.name} was successfully edited`)
          dispatch(saveProducts())
        })
        .catch((err) => errorHandler(err))
    } else {
      dispatch(
        createNewProduct({
          ...currentProduct,
          price: formatPrice(currentProduct.price),
        }),
      )
    }
    setIsProductManageDialogOpen(false)
  }

  const handleCloseDialog = () => {
    setSelectedProduct(null)
    setIsOpenDeleteDialog(false)
  }

  const Footer = () => {
    return (
      <Grid container>
        <Grid
          container
          justifyContent={{ xs: 'normal', md: 'right' }}
          alignItems={{ xs: 'normal', md: 'center' }}
          flexDirection={{ xs: 'column-reverse', md: 'row' }}
        >
          <Grid item padding={{ xs: '1rem', md: '0rem' }}>
            <Button color='primary' variant='outlined' onClick={handleCancel} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item padding={{ xs: '1rem 1rem 0 1rem', md: '1rem' }}>
            <Button
              disabled={isDisabledSaveButton}
              color='primary'
              variant='contained'
              onClick={handleAcceptClick}
              fullWidth
            >
              {selectedProduct ? 'Edit product' : 'Add new product'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      {products && products.length > 0 ? (
        <>
          <ContentHeader title='Products &amp; Services' />
          <ProductTable
            products={products}
            setIsOpenDeleteDialog={setIsOpenDeleteDialog}
            setIsProductManageDialogOpen={setIsProductManageDialogOpen}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
          />
        </>
      ) : (
        <>
          <NoDataDisplay
            mt='10rem'
            title='No data to display'
            description='Add Products &amp; Services you buy from vendors<br /> to bills for purchases and include items you sell to<br /> customers on invoices for sales.'
          />
        </>
      )}
      <ConfirmDialog
        isOpen={isOpenDeleteDialog}
        dialogTitle='Sure you want to delete?'
        dialogBodyContent='Deleting this item will permanently remove it from your account, and it cannot be undone.'
        buttonConfirmText='Yes, Delete'
        buttonCancelText='Cancel'
        handleClose={handleCloseDialog}
        handleConfirm={handleDeleteProduct}
      />
      <BaseModal
        size={isUsingMobile() ? 'xs' : 'small'}
        title={selectedProduct ? 'Edit product or service' : 'Add a product or service'}
        body={
          <ProductView
            currentProduct={currentProduct}
            selectedProduct={selectedProduct}
            setCurrentProduct={setCurrentProduct}
            setFormErrors={setFormErrors}
            formErrors={formErrors}
          />
        }
        isModalOpen={isProductManageDialogOpen}
        handleCloseModal={handleCancel}
        footer={<Footer />}
      />
      {isAuthorized('write_product') && (
        <PlusButton
          tooltipText='Add a Product or Service'
          handleOnClick={handleCreateProductClick}
        />
      )}
    </>
  )
}

export default Products
