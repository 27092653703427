import { Box, Button, Typography } from '@mui/material'
import { ILoginValues } from '../../pages/sign-in/sign-in'
import RegistrationField from '../registration-field'
import { useState } from 'react'
import { VALID_PASSWORD } from '../../helpers/constants'
import 'react-toastify/dist/ReactToastify.css'
import visibilityIcon from '../../assets/icons/visibility.png'
import visibilityOffIcon from '../../assets/icons/visibility-off.png'

interface IOTP {
  initialValues: ILoginValues
  handleChangeValues: (e: React.ChangeEvent<HTMLInputElement>, value: string) => void
  handleButtonClick: any
  handleForgotPasswordClick: any
}

const OTP = ({
  initialValues,
  handleChangeValues,
  handleButtonClick,
  handleForgotPasswordClick,
}: IOTP) => {
  const [showResetPassword, setShowResetPassword] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)

  const handleClickIconConfirmPass = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const handleClickIconResetPass = () => {
    setShowResetPassword(!showResetPassword)
  }

  return (
    <>
      <Typography
        mt={2}
        id='modal-modal-title'
        variant='h4'
        fontWeight={700}
        color='var(--dark-grey-title)'
      >
        Reset password
      </Typography>
      <Typography
        mt={3}
        id='modal-modal-subtitle'
        fontWeight={400}
        color='var(--light-grey-title)'
        style={{ marginBottom: '20px' }}
      >
        Please enter your verification code and select a new password
      </Typography>
      <RegistrationField
        placeholder='Verification code'
        styles='sign-in__input'
        value={initialValues.otp}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeValues(e, 'otp')}
        validataionSchema={initialValues.otp.length === 6}
        errorMessage='Wrong format'
        name='sign-in-reset-otp'
      />
      <RegistrationField
        placeholder='New password'
        styles='sign-in__input'
        withIcon
        icon={showResetPassword ? visibilityOffIcon : visibilityIcon}
        type={showResetPassword ? 'text' : 'password'}
        onIconClick={handleClickIconResetPass}
        iconPosition='end'
        value={initialValues.resetPassword}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChangeValues(e, 'resetPassword')
        }
        validataionSchema={VALID_PASSWORD.test(initialValues.resetPassword)}
        // eslint-disable-next-line max-len
        errorMessage='Invalid Format'
        name='sign-in-reset-pass'
      />
      <RegistrationField
        placeholder='Confirm new password'
        styles='sign-in__input'
        withIcon
        icon={showConfirmPassword ? visibilityOffIcon : visibilityIcon}
        type={showConfirmPassword ? 'text' : 'password'}
        onIconClick={handleClickIconConfirmPass}
        iconPosition='end'
        value={initialValues.confirmPassword}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChangeValues(e, 'confirmPassword')
        }
        validataionSchema={VALID_PASSWORD.test(initialValues.confirmPassword)}
        // eslint-disable-next-line max-len
        // errorMessage='Must be min 8 chars, uppercase, number, and one special character'
        errorMessage='Invalid Format'
        name='sign-in-confirm-password'
      />
      <div>
        <Button
          sx={{ fontWeight: 600 }}
          color='primary'
          variant='contained'
          onClick={handleButtonClick}
          fullWidth
        >
          Reset password
        </Button>
      </div>
      <div>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
          <Typography>{'Can´t find the email?'}</Typography>
          <span
            className='highlighted-text'
            onClick={() => handleForgotPasswordClick()}
            id='register-now__link'
            style={{ marginLeft: '8px' }}
          >
            <Typography>Resend email</Typography>
          </span>
        </Box>
      </div>
    </>
  )
}

export default OTP
