import { useEffect, useState } from 'react'
import { IFinancialAccount } from '../../types/types'
import ReportsFilters from './filters'
import moment from 'moment'
import api from '../../api/api'
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  TextField,
} from '@mui/material'
import { isUsingMobile } from '../../helpers/utils'
import DownloadButton from '../../components/filters/download-button'
import { saveAccountTransaction } from '../../redux/actions/report.actions'
import { useDispatch } from 'react-redux'

interface JournalEntryFiltersProps {
  onSearchClick: (account: any) => () => void
  onDownloadClick: any
  performRequest: (from: string, to: string) => void
  setFilterPeriod: (filterPeriod: any) => void
  filterPeriod: any
}

const DetailedReportingFilters = ({
  onSearchClick,
  onDownloadClick,
  setFilterPeriod,
  filterPeriod,
}: JournalEntryFiltersProps) => {
  const isMobile = isUsingMobile()
  const dispatch = useDispatch()

  const [showFilters, setShowFilters] = useState(false)

  const [filters, setFilters] = useState({
    account: undefined,
    // status: 'ACCRUAL',
  })

  return (
    <>
      <Card sx={{ '&.MuiPaper-root': { boxShadow: 'none' }, mb: '16px' }} style={{ width: '100%' }}>
        <CardContent sx={{ pb: '16px !important' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            <FilterInputs isMobile={isMobile} filters={filters} setFilters={setFilters} />
            <div style={{ display: 'flex', cursor: 'pointer' }}></div>
            <Grid item flexGrow={2} mb={isUsingMobile() ? 2 : undefined}>
              <ReportsFilters
                setFilterPeriod={setFilterPeriod}
                filterPeriod={filterPeriod}
                performRequest={(from: any, to: any) =>
                  dispatch(
                    saveAccountTransaction(
                      0,
                      '',
                      moment(from).format('YYYY-MM-DD'),
                      moment(to).format('YYYY-MM-DD'),
                    ),
                  )
                }
                onUpdateClick={() =>
                  dispatch(
                    saveAccountTransaction(
                      0,
                      '',
                      moment(filterPeriod.from).format('YYYY-MM-DD'),
                      moment(filterPeriod.to).format('YYYY-MM-DD'),
                      filters.account,
                    ),
                  )
                }
              />
            </Grid>
          </div>
        </CardContent>
      </Card>
    </>
  )
}

interface FilterInputsProps {
  filters: any
  setFilters: (filters: any) => void
  isMobile: boolean
}

const FilterInputs = ({ filters, setFilters, isMobile }: FilterInputsProps) => {
  const [isAccountsListOpen, setIsAccountsListOpen] = useState(false)
  const [accountsList, setAccountsList] = useState<readonly IFinancialAccount[]>([])
  const loading = isAccountsListOpen && accountsList.length === 0

  useEffect(() => {
    if (!loading) {
      return undefined
    }

    api.getFinancialAccount(0, 999).then((res) => {
      setAccountsList([...res.data.body.content])
    })
  }, [loading])

  return (
    <>
      <FormControl fullWidth={isMobile}>
        <Autocomplete
          open={isAccountsListOpen}
          onOpen={() => {
            setIsAccountsListOpen(true)
          }}
          onClose={() => {
            setIsAccountsListOpen(false)
          }}
          isOptionEqualToValue={(option, value) => option.publicId === value.publicId}
          getOptionLabel={(option) => option.name || ''}
          options={accountsList}
          loading={loading}
          onChange={(e, account) => setFilters({ ...filters, account: account?.publicId })}
          sx={{ width: { sm: 350 } }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label='Accounts'
              InputLabelProps={{ shrink: true }}
              placeholder='Select account'
            />
          )}
        />
      </FormControl>

      {/* <Box sx={{ width: { xs: '100%', sm: 350 } }}>
        <SelectorMui
          fullWidth
          label='Report Type'
          options={[
            { value: 'ACCRUAL', title: 'Accrual (Paid & Unpaid)' },
            { value: 'CASH_BASIS', title: 'Cash Basis (Paid)' },
            { value: 'CASH_ONLY', title: 'Cash Only' },
          ]}
          value={filters.status}
          placeholder='Select report type'
          onChange={(e: SelectChangeEvent) => {
            setFilters({ ...filters, status: e.target.value })
          }}
        /> 
      </Box>*/}
    </>
  )
}

export default DetailedReportingFilters
