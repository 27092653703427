import { Tab, Tabs } from '@mui/material'
import BaseModal from '../../../components/modal/modal'
import InvoicePreview from '../../invoices/preview/invoicePreview'
import { isUsingMobile } from '../../../helpers/utils'
import { useEffect, useState } from 'react'
import { IScheduledInvoice, IScheduler } from '../../../types/types'
import SubscriptionModalHeaderButtons from './headerButtons'
import SubscriptionModalFooter from './footer'
import SubscriptionSettings from '../subscriptionSettings'
import { saveCertainCustomer } from '../../../redux/actions/home.actions'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../redux/store'
import useAuthorization from '../../../helpers/useAuthorization'

interface SubscriptionModalProps {
  showModal: boolean
  scheduledInvoice: IScheduledInvoice
  setScheduler: (scheduler: IScheduler) => void
  setPaymentMethod: (paymentMethod: string) => void
  setSendTo: (endTo: string) => void
  setShowModal: (showModal: boolean) => void
  onActivateClick?: () => void
  onStopClick?: () => void
  onDeleteClick?: () => void
  onEditInvoiceClick?: () => void
  onEditScheduleClick?: () => void
  onEditPaymentClick?: () => void
  onEditSendingClick?: () => void
  showSettings?: boolean
}

const SubscriptionModal = ({
  scheduledInvoice,
  showModal,
  onActivateClick,
  onStopClick,
  onDeleteClick,
  onEditInvoiceClick,
  setScheduler,
  setPaymentMethod,
  setSendTo,
  setShowModal,
  showSettings,
}: SubscriptionModalProps) => {
  const [selectedTab, setSelectedTab] = useState('view')
  const dispatch = useDispatch()
  const {isAuthorized} = useAuthorization();
  const company = useSelector((state: AppState) => state.homeReducer.company)
  const customer = useSelector((state: AppState) => state.homeReducer.chosenCustomer)

  useEffect(() => {
    dispatch(saveCertainCustomer(scheduledInvoice.customerPublicId))
  }, [scheduledInvoice.customerPublicId])

  useEffect(() => {
    setSelectedTab(showSettings ? 'settings' : 'view');
  }, [showSettings]);

  const handleCloseModal = () => {
    setShowModal(false)
  }

  return (
    <BaseModal
      title={`Subscription for ${scheduledInvoice.customerName}`}
      isModalOpen={showModal}
      handleCloseModal={handleCloseModal}
      body={
        <>
          {isUsingMobile() && (
            <Tabs
              aria-label='subscrption views'
              value={selectedTab}
              scrollButtons='auto'
              variant='scrollable'
              onChange={(e, tab: string) => setSelectedTab(tab)}
            >
              <Tab label='View' value='view' sx={{ flexGrow: 1 }} />
              <Tab label='Settings' value='settings' sx={{ flexGrow: 1 }} />
            </Tabs>
          )}
          {selectedTab === 'view' ? (
            customer && (
              <InvoicePreview invoice={scheduledInvoice} company={company} customer={customer} />
            )
          ) : (
            <SubscriptionSettings
              setScheduler={setScheduler}
              scheduledInvoice={scheduledInvoice}
              setPaymentMethod={setPaymentMethod}
              setSendTo={setSendTo}
              onEditInvoiceClick={onEditInvoiceClick}
            />
          )}
        </>
      }
      headerButtons={
        isAuthorized('write_invoice') && (
          <SubscriptionModalHeaderButtons
            scheduledInvoice={scheduledInvoice}
            actions={{
              onActivateClick,
              onStopClick,
              onDeleteClick,
            }}
          />
        )
      }
      footer={
        <SubscriptionModalFooter
          onSelectedTabChange={(evt, value) => setSelectedTab(value)}
          onCancelClick={handleCloseModal}
          selectedTab={selectedTab}
        />
      }
      size='large'
    />
  )
}

export default SubscriptionModal
