import { Card, CardContent, Typography } from '@mui/material';
import { IEstimate } from '../../../types/types';
import { formatDate } from '../../../helpers/formatPrice';

interface CustomerCardProps {
	estimate: IEstimate;
}

const CustomerCard = ({ estimate }: CustomerCardProps) => {
	return (
		<Card
			sx={{
				mb: 1,
				color: 'var(--secondary-grey)',
				'& .MuiTypography-root': { fontSize: '14px' },
			}}
		>
			<CardContent>
				<Typography>Estimated to</Typography>
				<Typography mb={1} color="var(--grey-fonts)">
					{estimate.customerName}
				</Typography>
				<Typography>Estimate ID</Typography>
				<Typography mb={1} color="var(--grey-fonts)">
					{estimate.name}
				</Typography>
				<Typography>P.O./S.O. Number</Typography>
				<Typography mb={1} color="var(--grey-fonts)">
					{estimate.poso}
				</Typography>
				<Typography>Estimate Date</Typography>
				<Typography mb={1} color="var(--grey-fonts)">
					{formatDate(estimate.periodStart)}
				</Typography>
				<Typography>Expiration Date</Typography>
				<Typography color="var(--grey-fonts)">
					{formatDate(estimate.periodEnd)}
				</Typography>
			</CardContent>
		</Card>
	);
};

export default CustomerCard;
