import { Card, CardContent, Grid, Typography } from '@mui/material'
import { styled } from '@mui/styles'
import { useSelector } from 'react-redux'
import { AppState } from '../../../redux/store'
import { formatMoney } from '../../../helpers/formatPrice'

const KpiLabel = styled(Typography)(() => ({
  '&.MuiTypography-root': {
    color: 'var(--secondary-grey)',
    fontSize: 14,
  },
}))

const KpiValue = styled(Typography)(() => ({
  '&.MuiTypography-root': {
    color: 'var(--secondary-grey)',
    fontSize: 24,
    fontWeight: 600,
  },
}))

const KpiContainer = styled(Card)(() => ({
  '&.MuiPaper-root': {
    boxShadow: 'none',
  },
}))

const ProfitLossKpis = ({ mb }: { mb?: string | number }) => {
  const data = useSelector((state: AppState) => state.reportReducer.profitAndLoss)

  return (
    <KpiContainer sx={{ mb }}>
      <CardContent sx={{ px: 10 }}>
        <Grid container justifyContent='space-between'>
          <Grid item>
            <KpiLabel>Income</KpiLabel>
            <KpiValue>{formatMoney(data?.totalIncome)}</KpiValue>
          </Grid>
          <Grid item p={2}>
            <Typography fontSize={24} component='span'>
              -
            </Typography>
          </Grid>
          <Grid item>
            <KpiLabel>Cost of Goods Sold</KpiLabel>
            <KpiValue>{formatMoney(data?.totalCostOfGoodsSold)}</KpiValue>
          </Grid>
          <Grid item p={2}>
            <Typography fontSize={24} component='span'>
              -
            </Typography>
          </Grid>
          <Grid item>
            <KpiLabel>Operating Expenses</KpiLabel>
            <KpiValue>{formatMoney(data?.totalOperatingExpenses)}</KpiValue>
          </Grid>
          <Grid item p={2}>
            <Typography fontSize={24} component='span'>
              =
            </Typography>
          </Grid>
          <Grid item>
            <KpiLabel>Net Profit</KpiLabel>
            <KpiValue
              sx={{
                color:
                  data && data?.netProfit >= 0
                    ? 'var(--green) !important'
                    : 'var(--error-color) !important',
              }}
            >
              {formatMoney(data?.netProfit)}
            </KpiValue>
          </Grid>
        </Grid>
      </CardContent>
    </KpiContainer>
  )
}

export default ProfitLossKpis
