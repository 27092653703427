/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
	aws_project_region: 'us-east-1',
	aws_cognito_region: 'us-east-1',
	aws_cognito_identity_pool_id: process.env.REACT_APP_IDENTITY_POOL,
	aws_user_pools_id: process.env.REACT_APP_USER_POOL,
	aws_user_pools_web_client_id: process.env.REACT_APP_WEB_CLIENT,
	oauth: {},
	aws_cognito_username_attributes: ['EMAIL'],
	aws_cognito_social_providers: [],
	aws_cognito_signup_attributes: ['EMAIL'],
	aws_cognito_mfa_configuration: 'OFF',
	aws_cognito_mfa_types: ['SMS'],
	aws_cognito_password_protection_settings: {
		passwordPolicyMinLength: 8,
		passwordPolicyCharacters: [],
	},
	aws_cognito_verification_mechanisms: ['EMAIL'],
};

export default awsmobile;
