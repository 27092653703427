import { Divider, Grid, Typography } from '@mui/material'
import { ICompany, IInvoice, IScheduledInvoice } from '../../../types/types'
import { formatDate, formatPhoneNumber } from '../../../helpers/formatPrice'
import { getCdnUrl } from '../../../helpers/utils'

interface IInvoicePreviewHeaderProps {
  company?: ICompany | null
  invoice: IInvoice | IScheduledInvoice
  customer?: any
}

const InvoicePreviewHeader = ({ company, invoice, customer }: IInvoicePreviewHeaderProps) => {
  return (
    <>
      <Grid container p={2} sx={{ color: 'var(--gray3-color)' }}>
        <Grid item sx={{ '& .MuiTypography-body1': { fontSize: '14px' } }} sm={8}>
          {company && (
            <>
              <Typography fontWeight='bold' fontSize='16px !important'>
                {company.name}
              </Typography>
              <Typography>{company.addressOne}</Typography>
              <Typography>{company.addressTwo}</Typography>
              <Typography mb={1}>
                {company.city}, {company.state} {company.postalCode}, {company.country}
              </Typography>
              <Typography>{formatPhoneNumber(company.phone)}</Typography>
              <Typography>{company.website}</Typography>
            </>
          )}
        </Grid>
        <Grid item sm={4}>
          {company?.templateLogoFileName && (
            <img src={getCdnUrl(company?.templateLogoFileName)} alt='company logo' style={{ width: 220 }} />
          )}
        </Grid>
      </Grid>
      <Divider />
      <Grid container p={2} sx={{ color: 'var(--secondary-grey)' }}>
        <Grid item md={7} sx={{ '& .MuiTypography-body1': { fontSize: '14px' } }}>
          <Typography fontWeight='bold' fontSize='16px !important'>
            Invoiced to
          </Typography>
          <Typography>{customer.name}</Typography>
          <Typography>{customer?.billingAddress}</Typography>
          <Typography mb={1}>
            {customer?.billingCity}, {customer?.billingState} {customer?.billingPostalCode},{' '}
            {customer?.billingCountry}
          </Typography>
          <Typography>{invoice.customerEmail}</Typography>
        </Grid>
        <Grid item md={3} pr={1}>
          <Typography textAlign='right' fontWeight='bold'>
            Invoice ID
          </Typography>
          <Typography textAlign='right' fontWeight='bold'>
            Invoice Date
          </Typography>
          <Typography textAlign='right' fontWeight='bold'>
            Expiration Date
          </Typography>
        </Grid>
        <Grid
          item
          md={2}
          sx={{
            '& .MuiTypography-body1': { fontSize: '14px', lineHeight: '24px' },
          }}
        >
          <Typography>{'name' in invoice ? `#${invoice.name}` : 'Auto-generated'}</Typography>
          <Typography>
            {'periodStart' in invoice ? formatDate(invoice.periodStart) : 'Auto-generated'}
          </Typography>
          <Typography>
            {'periodEnd' in invoice ? formatDate(invoice.periodEnd) : 'Auto-generated'}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default InvoicePreviewHeader
