import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { IFilterItem } from '../../types/types'
import { Grid, IconButton, Tab, Tabs, Tooltip } from '@mui/material'
import { SyntheticEvent } from 'react'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { isUsingMobile } from '../../helpers/utils'

interface IListsTopBar {
  filters: IFilterItem[]
  selectedFilter: string
  onFilterClick?: () => void
  onSelectedFilterChange: (event: SyntheticEvent, filter: string) => void
}

const ListFilters = ({
  filters,
  selectedFilter,
  onSelectedFilterChange,
  onFilterClick,
}: IListsTopBar) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Grid container>
      <Grid item xs={12} md={isMobile ? 12 : 8} sx={{ mb: isMobile ? 2 : 0 }}>
        <Tabs
          aria-label='filters'
          onChange={onSelectedFilterChange}
          sx={{
            backgroundColor: '#fff',
            borderRadius: '0.5rem',
            display: 'inline-flex',
            mb: 2,
            maxWidth: '100%',
            overflowX: 'auto',
          }}
          scrollButtons='auto'
          variant={isMobile ? 'scrollable' : 'fullWidth'}
          value={selectedFilter}
          allowScrollButtonsMobile
        >
          {filters.map((item: IFilterItem, index: number) => {
            return (
              <Tab
                value={item.value}
                key={index}
                label={item.title}
                sx={item.large ? { textWrap: 'nowrap', flexGrow: 2 } : undefined}
              />
            )
          })}
        </Tabs>
      </Grid>
      {onFilterClick && !isUsingMobile() && (
        <Grid item xs={12} md={4} flexDirection='column' display='flex' alignItems='end'>
          <Tooltip title='Filter'>
            <IconButton
              aria-label='filter'
              sx={{ backgroundColor: 'var(--secondary1-color)', color: 'var(--primary1-color)' }}
              onClick={onFilterClick}
            >
              <FilterAltOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  )
}

export default ListFilters
