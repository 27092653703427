import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  MenuItem,
  Tooltip,
  Typography,
  IconButton,
  Menu,
  Card,
  Chip,
} from '@mui/material'
import { useSelector } from 'react-redux'
import BaseModal from '../../../components/modal/modal'
import SubscribeDialog from '../../../pages/subscribe-dialog'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import { noop } from 'lodash'
import { useEffect, useState } from 'react'
import { AppState } from '../../../redux/store'
import { isUsingMobile } from '../../../helpers/utils'
import { formatDate } from '../../../helpers/formatPrice'

interface ISubscriptionModalProps {
  showModal: boolean
  setShowModal: (showModal: boolean) => void
  onRefundClick?: () => void
  setIsOpenDeleteDialog: (value: boolean) => void
  setIsOpenResumeDialog: (value: boolean) => void
}

const SubscriptionModal = ({
  showModal,
  setShowModal,
  setIsOpenDeleteDialog,
  setIsOpenResumeDialog,
}: ISubscriptionModalProps) => {
  const handleCloseModal = () => {
    setShowModal(false)
  }
  const [isExecutable, setIsExecutable] = useState(true)
  const [bankInformationExpanded, setBankInformationExpanded] = useState(true)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isMoreActionsMenuOpen = Boolean(anchorEl)
  const [openSubscribeDialog, setIsSubscribeDialogOpen] = useState(false)

  const isMobile = isUsingMobile()

  const subscriptionData = useSelector((state: AppState) => state.authReducer.subscriptionData)

  useEffect(() => {
    if (subscriptionData.length > 0 && subscriptionData[0].scheduler?.executable !== undefined) {
      setIsExecutable(subscriptionData[0].scheduler?.executable)
    }
  }, [subscriptionData])

  const { cardType, cardToken, expirationDate } = subscriptionData[0].creditCard

  const formattedCardNumber = `**** **** **** ${cardToken.slice(-4)}`
  const formattedExpirationDate = `${expirationDate.slice(0, 2)}/${expirationDate.slice(2)}`
  const billingDate = isExecutable ? subscriptionData[0].scheduler.nextExecutionDate : subscriptionData[0].scheduler.endDate
  const billingText = isExecutable ? 'Next billing date' : 'End date'

  const changePayment = () => {
    setIsSubscribeDialogOpen(true)
  }

  const handleDialogClose = () => setIsSubscribeDialogOpen(false)

  const handleCancelClick = () => {
    setIsOpenDeleteDialog(true)
    setShowModal(false)
  }

  const handleResumeClick = () => {
    setIsOpenResumeDialog(true)
    setShowModal(false)
  }

  const handleMoreActionsMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <BaseModal
      title='Subscription'
      isModalOpen={showModal}
      size='large'
      headerButtons={
        <>
          <Tooltip title='More options'>
            <IconButton
              aria-label='more'
              onClick={(event) => setAnchorEl(event.currentTarget)}
              sx={{
                color: 'var(--primary1-color)',
                background: 'var(--secondary1-color)',
              }}
            >
              <MoreVertOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Menu
            id='more-actions-menu'
            anchorEl={anchorEl}
            open={isMoreActionsMenuOpen}
            onClose={handleMoreActionsMenuClose}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            transformOrigin={{
              vertical: -12,
              horizontal: 'right',
            }}
            sx={{
              '& .MuiMenuItem-root': {
                padding: { xs: '1rem', md: '1rem 4rem 1rem 2rem' },
              },
              '& .MuiMenuItem-root:hover': {
                backgroundColor: 'var(--secondary1-color)',
                color: 'var(--primary1-color)',
              },
            }}
          >
            {isExecutable ? (
              <MenuItem onClick={handleCancelClick} style={{ color: 'red' }}>
                Stop subscription
              </MenuItem>
            ) : (
              <MenuItem onClick={handleResumeClick} style={{ color: 'green' }}>
                Resume subscription
              </MenuItem>
            )}
          </Menu>
        </>
      }
      body={
        <>
          <Box m={2}>
            <Card
              sx={{
                color: 'var(--secondary-grey)',
                mb: 1,
                '& .MuiTypography-root': { fontSize: '14px' },
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: !isMobile ? 'center' : "flex-start",
                  height: '70px',
                  padding: '0 20px',
                  flexDirection: !isMobile ? 'row' : 'column',
                  marginTop: !isMobile ? '0' : "10px",
                }}
              >
                <div style={{ display: 'flex' }}>
                  <div style={{ marginRight: '15px', marginBottom: "5px" }}>Montly subscription</div>
                  <div>
                    {isExecutable ? (
                      <Chip
                        sx={{
                          backgroundColor: 'var(--success-bg-color)',
                          color: 'var(--success-color)',
                        }}
                        label='Active'
                      />
                    ) : (
                      <Chip
                        sx={{
                          backgroundColor: 'var(--error-bg-color)',
                          color: 'var(--error-color)',
                        }}
                        label='Canceled'
                      />
                    )}
                  </div>
                </div>
                {!isMobile ? (
                  <div style={{ marginLeft: 'auto' }}>
                    {billingText}: {formatDate(billingDate)}
                  </div>
                ) : (
                  <div>
                    {billingText}: {formatDate(billingDate)}
                  </div>
                )}
              </div>
            </Card>
            <Accordion
              expanded={bankInformationExpanded}
              onChange={(evt, value) => setBankInformationExpanded(value)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Typography fontWeight='bold' fontSize={'1.1rem'} style={{ color: '#4D5E80' }}>
                  Payment Method
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid item flexGrow={2} mb={2}>
                    <Typography fontWeight='bold' style={{ color: '#4D5E80' }}>
                      Credit card
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#4D5E80' }}>
                      {cardType} {formattedCardNumber} Exp. date {formattedExpirationDate}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <div
              style={{ color: '#0039FF', fontWeight: 'bold', marginTop: '10px', cursor: 'pointer' }}
              onClick={changePayment}
            >
              Change payment method
            </div>
          </Box>
          <SubscribeDialog
            open={openSubscribeDialog}
            setIsSubscribeDialogOpen={setIsSubscribeDialogOpen}
            onClose={handleDialogClose || noop}
          />
        </>
      }
      footer={
        <Grid
          container
          justifyContent={{ xs: 'normal', md: 'right' }}
          alignItems={{ xs: 'normal', md: 'center' }}
          flexDirection={{ xs: 'column-reverse', md: 'row' }}
        >
          <Grid item padding={{ xs: '1rem', md: '0rem' }}>
            <Button color='primary' variant='outlined' fullWidth onClick={handleCloseModal}>
              Cancel
            </Button>
          </Grid>
          <Grid item padding={{ xs: '1rem 1rem 0 1rem', md: '1rem' }}>
            <Button color='primary' variant='contained' onClick={handleCloseModal} fullWidth>
              Save changes
            </Button>
          </Grid>
        </Grid>
      }
      handleCloseModal={handleCloseModal}
    />
  )
}

export default SubscriptionModal
