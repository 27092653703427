import { Button, Grid } from '@mui/material'
import BaseModal from '../../../components/modal/modal'
import PaymentsFiltersForm from './form'
import { useEffect, useState } from 'react'
import { IPaymentsFilters } from '../../../types/types'

interface PaymentsFiltersModalProps {
  showModal: boolean
  setShowModal: (showModal: boolean) => void
  onFiltersConfirm?: (filters: IPaymentsFilters) => void
  paymentsFilters: IPaymentsFilters
  setPaymentsFilters: (values: IPaymentsFilters) => void
}

const PaymentsFiltersModal = ({
  showModal,
  setShowModal,
  onFiltersConfirm,
  paymentsFilters,
  setPaymentsFilters,
}: PaymentsFiltersModalProps) => {

  const handleCloseModal = () => {
    setShowModal(false)
  }

  return (
    <BaseModal
      title='Filters'
      isModalOpen={showModal}
      body={<PaymentsFiltersForm filters={paymentsFilters} setFilters={setPaymentsFilters} />}
      footer={
        <Grid
          container
          justifyContent={{ xs: 'normal', md: 'right' }}
          alignItems={{ xs: 'normal', md: 'center' }}
          flexDirection={{ xs: 'column-reverse', md: 'row' }}
        >
          <Grid item padding={{ xs: '1rem', md: '0rem' }}>
            <Button color='primary' variant='outlined' onClick={handleCloseModal} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item padding={{ xs: '1rem 1rem 0 1rem', md: '1rem' }}>
            <Button
              color='primary'
              variant='contained'
              onClick={() => onFiltersConfirm && onFiltersConfirm(paymentsFilters)}
              fullWidth
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      }
      handleCloseModal={handleCloseModal}
    />
  )
}
export default PaymentsFiltersModal
