import { SxProps, TextField } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import './date-field.css'
interface IDateField {
  value?: string
  onChange?: any
  label?: string
  fullWidth?: boolean
  startDate?: string
  endDate?: string
  placeholder?: string
  sx?: SxProps
  minDate?: any
}

const DateField = (props: IDateField) => {
  const {
    value,
    onChange,
    label,
    fullWidth = false,
    endDate,
    startDate = '',
    placeholder = '',
    sx,
    minDate,
  } = props

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        minDate={minDate ? new Date(minDate) : undefined}
        label={label}
        value={value}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            fullWidth={fullWidth}
            {...params}
            placeholder={placeholder}
            error={!!endDate && endDate < startDate}
            helperText={
              !!endDate && endDate < startDate
                ? 'The expiration date should be equal or greater than the invoice date'
                : ''
            }
          />
        )}
        InputAdornmentProps={{
          style: {
            position: 'absolute',
            right: 10,
          },
        }}
        InputProps={{
          sx: {
            '& fieldset': {
              borderColor: 'var(--secondary-grey)',
            },
            ...sx,
          },
        }}
      />
    </LocalizationProvider>
  )
}

export default DateField
