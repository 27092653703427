import { Avatar, Card, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { getCdnUrl } from "../../helpers/utils";
import { AppState } from "../../redux/store";
import { ICompany } from "../../types/types";


const CompanySectionMobile = () => {
  const company = useSelector<AppState, ICompany | null>(
		(state: AppState) => state.homeReducer.company
	);

  return (
    <Card sx={{display: "flex", justifyContent: "space-between", marginBottom: '20px', paddingBottom: '20px', paddingTop: '20px', boxShadow: '0px 5px 20px rgba(70, 101, 137, 0.35)'}}>
      <Grid marginLeft={'30px'} color={'var(--grey-fonts)'} item xs={8} sm={6} flexDirection="column">
        <Typography fontWeight={600}>{company?.name}</Typography>
        <Typography fontSize={'14px'}>{company?.addressOne}</Typography>
        <Typography fontSize={'14px'}>
          {company?.city}, {company?.state}, {company?.postalCode}, {company?.country}
        </Typography>
        <Typography marginTop={'20px'} fontSize={'14px'}>{company?.phone}</Typography>
        <Typography fontSize={'14px'}>{company?.website}</Typography>
      </Grid>
      <Grid marginRight={'30px'} item xs={4} sm={6}>
        <Avatar
          sx={{ width: 50, height: 50}}
          src={getCdnUrl(company?.logoFileName)}
        />
      </Grid>
		</Card>
  )
}

export default CompanySectionMobile
