import { createStore, combineReducers, applyMiddleware } from 'redux';
import homeReducer from './reducers/home.reducer';
import authReducer from './reducers/auth.reducer';
import settingsReducer from './reducers/settings.reducer';
import dashboardReducer from './reducers/dashboard.reducer';
import reportReducer from './reducers/report.reducer';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { Action } from './actions/actionTypes';

const reducers = combineReducers({
	homeReducer,
	authReducer,
	settingsReducer,
	dashboardReducer,
	reportReducer,
});

export type AppState = ReturnType<typeof reducers>;

const store = createStore(
	reducers,
	applyMiddleware(thunk as ThunkMiddleware<AppState, Action>)
);

export default store;
