import { Grid, Typography } from '@mui/material';
import { capitalizeFirst } from '../../../helpers/utils';

interface IContactDetails {
	company: any;
}

const AccountInfo = (props: IContactDetails) => {
	return (
		<>
			<Grid container>
				<Grid item sm={6}>
					<Typography variant="subtitle1">Type of business</Typography>
					<Typography variant="subtitle2" color="text.secondary">
						{props.company?.typeOfBusiness ? props.company.typeOfBusiness : '-'}
					</Typography>
				</Grid>

				<Grid item sm={6}>
					<Typography variant="subtitle1">Type of organization</Typography>
					<Typography variant="subtitle2" color="text.secondary">
						{props.company?.companySize
							? capitalizeFirst(props.company.companySize)
							: '-'}
					</Typography>
				</Grid>
			</Grid>
		</>
	);
};

export default AccountInfo;
