import { useState } from 'react';
import './dashboard-overdue-bar.css';

interface IOverdueBar {
	color: string;
	title: number;
	maxInvoicesAmount: number;
	isDisabled?: boolean;
	disabledHeight?: number;
	disabledColors: string[];
	barIndex: number;
}

const DahboardOverdueBar = (props: IOverdueBar) => {
	const {
		color,
		title,
		maxInvoicesAmount,
		isDisabled,
		disabledHeight,
		disabledColors,
		barIndex,
	} = props;
	const [isHovering, setIsHovering] = useState<boolean>(false);
	const columnWidth = 145;
	const itemHeight =
		isDisabled || title === 0
			? disabledHeight
			: (title * columnWidth) / maxInvoicesAmount;
	const handleMouseOver = () => {
		setIsHovering(true);
	};

	const handleMouseOut = () => {
		setIsHovering(false);
	};
	return (
		<div
			className="dashboard__outstanding-bar"
			style={{
				background: title === 0 ? disabledColors[barIndex] : color,
				height: itemHeight,
				cursor: isDisabled || title === 0 ? 'default' : 'pointer',
			}}
			onMouseOver={handleMouseOver}
			onMouseOut={handleMouseOut}
		>
			{isHovering && !isDisabled ? (
				<div className="dashboard__outstanding-bar-tooltip">
					{`${title} ${title === 1 ? 'invoice' : 'invoices'}`}
				</div>
			) : null}
		</div>
	);
};

export default DahboardOverdueBar;
