import { Box } from '@mui/material'
import { ReactComponent as InvoiceTemplate } from '../../assets/images/invoiceTemplate.svg'
import { ReactComponent as InvoiceTemplateWithBanner } from '../../assets/images/invoiceTemplateWithBanner.svg'

interface TemplatePreviewProps {
  hexColor: string
  showBanner: boolean
}

const TemplatePreview = ({ hexColor, showBanner }: TemplatePreviewProps) => {
  return (
    <Box mt={{ xs: 1, sm: 0 }}>
      {showBanner ? (
        <InvoiceTemplateWithBanner fill={hexColor} width='100%' />
      ) : (
        <InvoiceTemplate fill={hexColor} width='100%' />
      )}
    </Box>
  )
}

export default TemplatePreview
