export const SAVE_USER = 'SAVE_USER';
export const SAVE_ACCESS_TOKEN = 'SAVE_ACCESS_TOKEN';
export const SAVE_CURRENT_USER = 'SAVE_CURRENT_USER';
export const SAVE_ALL_USERS = 'SAVE_ALL_USERS';
export const SAVE_MCC_TYPES = 'SAVE_MCC_TYPES';
export const SAVE_USIO_API_KEY = 'SAVE_USIO_API_KEY';
export const SHOW_USIO_NOTIFICATION = 'SHOW_USIO_NOTIFICATION';
export const IS_COMPANY_LOADED = 'IS_COMPANY_LOADED';
export const HAVE_SUBSCRIPTION = 'HAVE_SUBSCRIPTION';
export const SUBSCRIPTION_DATA = 'SUBSCRIPTION_DATA';
export const REFERRALS_DATA = 'REFERRALS_DATA';
export const SAVE_FAQ = 'SAVE_FAQ';
export const SAVE_USIO_LINK = 'SAVE_USIO_LINK';
export const IS_ONBOARDING = 'IS_ONBOARDING';
export const IS_COMPLETE_BUSINESS = 'IS_COMPLETE_BUSINESS';
