import { Grid, InputAdornment, TextField } from '@mui/material'
import usaFlagIcon from '../../../assets/icons/usa-flag.svg'
import { validateAddress, validateCommonField, validateState } from '../../../helpers/utils'
import useAuthorization from '../../../helpers/useAuthorization'

interface BillingStepProps {
  currentVendor: any
  handleChangeNewVendor: any
  formErrors: any
  setFormErrors: any
}

const BillingStep = ({
  currentVendor,
  handleChangeNewVendor,
  formErrors,
  setFormErrors,
}: BillingStepProps) => {
  const { isAuthorized } = useAuthorization()

  return (
    <Grid container gap={2}>
      <Grid item xs={12} pl={1} pr={1}>
        <TextField
          label='Address line 1'
          placeholder='Building Number, Street Address, Apartment...'
          fullWidth
          InputLabelProps={{ shrink: true }}
          required
          disabled={!isAuthorized('write_vendor')}
          value={currentVendor.addressOne}
          onBlur={(e) => {
            validateAddress(e.target.value, 'addressOne', setFormErrors, 'Address line 1')
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleChangeNewVendor(e, 'addressOne')
            validateAddress(e.target.value, 'addressOne', setFormErrors, 'Address line 1')
          }}
          error={!!formErrors['addressOne']}
          helperText={formErrors['addressOne']}
        />
      </Grid>
      <Grid item xs={12} pl={1} pr={1}>
        <TextField
          label='Address line 2'
          placeholder='Building Number, Street Address, Apartment...'
          fullWidth
          disabled={!isAuthorized('write_vendor')}
          InputLabelProps={{ shrink: true }}
          value={currentVendor.addressTwo || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleChangeNewVendor(e, 'addressTwo')
          }}
        />
      </Grid>
      <Grid item xs={12} pl={1} pr={1}>
        <TextField
          label='City'
          placeholder='Enter your city'
          fullWidth
          InputLabelProps={{ shrink: true }}
          required
          disabled={!isAuthorized('write_vendor')}
          value={currentVendor.city}
          onBlur={(e) => {
            validateCommonField(e.target.value, 'city', setFormErrors, 'city', 3)
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleChangeNewVendor(e, 'city')
            validateCommonField(e.target.value, 'city', setFormErrors, 'city', 3)
          }}
          error={!!formErrors['city']}
          helperText={formErrors['city']}
        />
      </Grid>
      <Grid item xs={12} pl={1} pr={1}>
        <TextField
          label='State'
          placeholder='Enter your state'
          fullWidth
          InputLabelProps={{ shrink: true }}
          required
          disabled={!isAuthorized('write_vendor')}
          value={currentVendor.state}
          onBlur={(e) => {
            validateState(e.target.value, setFormErrors, 'state')
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleChangeNewVendor(e, 'state')
            validateState(e.target.value, setFormErrors, 'state')
          }}
          inputProps={{ maxLength: 2 }}
          error={!!formErrors['state']}
          helperText={formErrors['state']}
        />
      </Grid>
      <Grid item xs={12} pl={1} pr={1}>
        <TextField
          label='Postal/Zip code'
          type='number'
          placeholder='Enter your zip code'
          fullWidth
          InputLabelProps={{ shrink: true }}
          required
          disabled={!isAuthorized('write_vendor')}
          value={currentVendor.postalCode}
          onBlur={(e) => {
            validateCommonField(e.target.value, 'postalCode', setFormErrors, 'Postal/Zip Code', 5)
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleChangeNewVendor(e, 'postalCode')
            validateCommonField(e.target.value, 'postalCode', setFormErrors, 'Postal/Zip Code', 5)
          }}
          error={!!formErrors['postalCode']}
          helperText={formErrors['postalCode']}
        />
      </Grid>
      <Grid item xs={12} pl={1} pr={1}>
        <TextField
          label='Country'
          fullWidth
          InputLabelProps={{ shrink: true }}
          required
          disabled
          value='United States'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <img src={usaFlagIcon} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  )
}

export default BillingStep
