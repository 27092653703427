import {
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { styled } from '@mui/system'

import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { ReactNode, useState } from 'react'
import { formatDate, formatMoney } from '../../../helpers/formatPrice'
import { useSelector } from 'react-redux'
import { AppState } from '../../../redux/store'
import { isUsingMobile } from '../../../helpers/utils'

const BalanceSheetTable = ({ from, to }: { from: string; to: string }) => {
  const data = useSelector((state: AppState) => state.reportReducer.balanceSheet)
  const isMobile = isUsingMobile()

  return (
    <ReportsTable component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={isMobile ? undefined : 300}>Accounts</TableCell>
            {!isMobile && (
              <>
                <TableCell>-</TableCell>
                <TableCell>-</TableCell>
                <TableCell>-</TableCell>
                <TableCell>-</TableCell>
              </>
            )}
          </TableRow>
        </TableHead>

        <TableBody>
          <OutstandingRow style={{ borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
            <OutstandingCell style={{ backgroundColor: '#f7f8fa' }}>
              <Typography variant='h1'>Assets</Typography>
            </OutstandingCell>
            {!isMobile ? (
              <>
                <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
                <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
                <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
                <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
                <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
              </>
            ) : (
              <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
            )}
          </OutstandingRow>
        </TableBody>

        <TableBody>
          <CollapsibleRow title='Total Cash' totalAmount={data?.totalCashAndBank}>
            <Table>
              <TableBody>
                {data?.cashAndBankByAccount.map((item: any, index: any) => (
                  <TableRow
                    key={item.account}
                    sx={
                      index === data?.cashAndBankByAccount.length - 1
                        ? { '& .MuiTableCell-root': { border: 'none' } }
                        : undefined
                    }
                  >
                    <TableCell width={isMobile ? '60%' : 300} sx={{ pl: { xs: 4, sm: 8 } }}>
                      {item.account}
                    </TableCell>
                    {!isMobile && (
                      <>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </>
                    )}
                    <TableCell width={isMobile ? undefined : 200} sx={{ pl: 2 }}>
                      {formatMoney(item.totalAmount)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CollapsibleRow>
        </TableBody>

        <TableBody>
          <CollapsibleRow
            title='Total Other Short Term Assets'
            totalAmount={data?.totalOtherShortTermAssets}
          >
            <Table>
              <TableBody>
                {data?.otherShortTermAssetsByAccount.map((item: any, index: any) => (
                  <TableRow
                    key={item.account}
                    sx={
                      index === data?.otherShortTermAssetsByAccount.length - 1
                        ? { '& .MuiTableCell-root': { border: 'none' } }
                        : undefined
                    }
                  >
                    <TableCell width={isMobile ? '60%' : 300} sx={{ pl: { xs: 4, sm: 8 } }}>
                      {item.account}
                    </TableCell>
                    {!isMobile && (
                      <>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </>
                    )}
                    <TableCell width={isMobile ? undefined : 200} sx={{ pl: 2 }}>
                      {formatMoney(item.totalAmount)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CollapsibleRow>
        </TableBody>

        <TableBody>
          <CollapsibleRow
            title='Total Other Long Term Assets'
            totalAmount={data?.totalOtherLongTermAssets}
          >
            <Table>
              <TableBody>
                {data?.otherLongTermAssetsByAccount.map((item: any, index: any) => (
                  <TableRow
                    key={item.account}
                    sx={
                      index === data?.otherLongTermAssetsByAccount.length - 1
                        ? { '& .MuiTableCell-root': { border: 'none' } }
                        : undefined
                    }
                  >
                    <TableCell width={isMobile ? '60%' : 300} sx={{ pl: { xs: 4, sm: 8 } }}>
                      {item.account}
                    </TableCell>
                    {!isMobile && (
                      <>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </>
                    )}
                    <TableCell width={isMobile ? undefined : 200} sx={{ pl: 2 }}>
                      {formatMoney(item.totalAmount)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CollapsibleRow>
        </TableBody>

        <TableBody>
          <CollapsibleRow
            title='Total Other Current Assets'
            totalAmount={data?.totalOtherCurrentAssets}
          >
            <Table>
              <TableBody>
                {data?.otherCurrentAssetsByAccount.map((item: any, index: any) => (
                  <TableRow
                    key={item.account}
                    sx={
                      index === data?.otherCurrentAssetsByAccount.length - 1
                        ? { '& .MuiTableCell-root': { border: 'none' } }
                        : undefined
                    }
                  >
                    <TableCell width={isMobile ? '60%' : 300} sx={{ pl: { xs: 4, sm: 8 } }}>
                      {item.account}
                    </TableCell>
                    {!isMobile && (
                      <>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </>
                    )}
                    <TableCell width={isMobile ? undefined : 200} sx={{ pl: 2 }}>
                      {formatMoney(item.totalAmount)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CollapsibleRow>
        </TableBody>

        <TableBody>
          <OutstandingRow>
            <OutstandingCell
              width={isMobile ? '60%' : 300}
              color={data && data.totalAssets > 0 ? 'var(--green)' : 'var(--error-color)'}
            >
              <Typography variant='h1'>Total Assets</Typography>
            </OutstandingCell>
            {!isMobile && (
              <>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </>
            )}
            <OutstandingCell
              width={isMobile ? undefined : 200}
              sx={{ pl: 2 }}
              color={data && data.totalAssets > 0 ? 'var(--green)' : 'var(--error-color)'}
            >
              <Typography variant='h1'>{formatMoney(data?.totalAssets)}</Typography>
            </OutstandingCell>
          </OutstandingRow>
        </TableBody>
      </Table>
      <Table>
        {' '}
        <TableBody>
          <OutstandingRow style={{ borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
            <OutstandingCell
              style={{ backgroundColor: '#f7f8fa' }}
              // width={isMobile ? '60%' : "100%"}
            >
              <Typography variant='h1'>Liabilities</Typography>
            </OutstandingCell>
            {!isMobile && (
              <>
                <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
                <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
                <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
                <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
                <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
              </>
            )}
          </OutstandingRow>
        </TableBody>
      </Table>
      <Table>
        <TableBody>
          <CollapsibleRow
            title='Total Current Liabilities'
            totalAmount={data?.totalCurrentLiabilities}
          >
            <Table>
              <TableBody>
                {data?.currentLiabilitiesByAccount.map((item: any, index: any) => (
                  <TableRow
                    key={item.account}
                    sx={
                      index === data?.currentLiabilitiesByAccount.length - 1
                        ? { '& .MuiTableCell-root': { border: 'none' } }
                        : undefined
                    }
                  >
                    <TableCell width={isMobile ? '60%' : 300} sx={{ pl: { xs: 4, sm: 8 } }}>
                      {item.account}
                    </TableCell>
                    {!isMobile && (
                      <>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </>
                    )}
                    <TableCell width={isMobile ? undefined : 200} sx={{ pl: 2 }}>
                      {formatMoney(item.totalAmount)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CollapsibleRow>
        </TableBody>
      </Table>
      <Table>
        <TableBody>
          <CollapsibleRow
            title='Total Other Short Term Liabilities'
            totalAmount={data?.totalOtherShortTermLiabilities}
          >
            <Table>
              <TableBody>
                {data?.otherShortTermLiabilitiesByAccount.map((item: any, index: any) => (
                  <TableRow
                    key={item.account}
                    sx={
                      index === data?.otherShortTermLiabilitiesByAccount.length - 1
                        ? { '& .MuiTableCell-root': { border: 'none' } }
                        : undefined
                    }
                  >
                    <TableCell width={isMobile ? '60%' : 300} sx={{ pl: { xs: 4, sm: 8 } }}>
                      {item.account}
                    </TableCell>
                    {!isMobile && (
                      <>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </>
                    )}
                    <TableCell width={isMobile ? undefined : 200} sx={{ pl: 2 }}>
                      {formatMoney(item.totalAmount)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CollapsibleRow>
        </TableBody>
      </Table>
      <Table>
        <TableBody>
          <CollapsibleRow
            title='Total Other Long Term Liabilities'
            totalAmount={data?.totalOtherLongTermLiabilities}
          >
            <Table>
              <TableBody>
                {data?.otherLongTermLiabilitiesByAccount.map((item: any, index: any) => (
                  <TableRow
                    key={item.account}
                    sx={
                      index === data?.otherShortTermLiabilitiesByAccount.length - 1
                        ? { '& .MuiTableCell-root': { border: 'none' } }
                        : undefined
                    }
                  >
                    <TableCell width={isMobile ? '60%' : 300} sx={{ pl: { xs: 4, sm: 8 } }}>
                      {item.account}
                    </TableCell>
                    {!isMobile && (
                      <>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </>
                    )}
                    <TableCell width={isMobile ? undefined : 200} sx={{ pl: 2 }}>
                      {formatMoney(item.totalAmount)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CollapsibleRow>
        </TableBody>
      </Table>
      <Table>
        <TableBody>
          <OutstandingRow>
            <OutstandingCell
              width={isMobile ? '60%' : 300}
              color={data && data.totalLiabilities > 0 ? 'var(--green)' : 'var(--error-color)'}
            >
              <Typography variant='h1'>Total Liabilities</Typography>
            </OutstandingCell>
            {!isMobile && (
              <>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </>
            )}
            <OutstandingCell
              width={isMobile ? undefined : 200}
              sx={{ pl: 2 }}
              color={data && data.totalLiabilities > 0 ? 'var(--green)' : 'var(--error-color)'}
            >
              <Typography variant='h1'>{formatMoney(data?.totalLiabilities)}</Typography>
            </OutstandingCell>
          </OutstandingRow>
        </TableBody>
      </Table>

      <Table>
        <TableBody>
          <OutstandingRow style={{ borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
            <OutstandingCell
              style={{ backgroundColor: '#f7f8fa' }}
              // width={isMobile ? '60%' : "100%"}
            >
              <Typography variant='h1'>Equity</Typography>
            </OutstandingCell>
            {!isMobile && (
              <>
                <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
                <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
                <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
                <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
                <TableCell style={{ backgroundColor: '#f7f8fa' }}></TableCell>
              </>
            )}
          </OutstandingRow>
        </TableBody>
      </Table>

      <Table>
        <TableBody>
          <CollapsibleRow title='Other Equities' totalAmount={data?.totalOtherEquity}>
            <Table>
              <TableBody>
                {data?.equityByAccount.map((item: any, index: any) => (
                  <TableRow
                    key={item.account}
                    sx={
                      index === data?.equityByAccount.length - 1
                        ? { '& .MuiTableCell-root': { border: 'none' } }
                        : undefined
                    }
                  >
                    <TableCell width={isMobile ? '60%' : 300} sx={{ pl: { xs: 4, sm: 8 } }}>
                      {item.account}
                    </TableCell>
                    {!isMobile && (
                      <>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </>
                    )}
                    <TableCell width={isMobile ? undefined : 200} sx={{ pl: 2 }}>
                      {formatMoney(item.totalAmount)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CollapsibleRow>
        </TableBody>
      </Table>
      <Table>
        <TableBody>
          <OutstandingRow>
            <OutstandingCell 
              width={isMobile ? '60%' : 300} 
              sx={{ pl: 2 }}
            >
              <span>Net Profit</span>
            </OutstandingCell>
            {!isMobile && (
              <>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </>
            )}
            <OutstandingCell 
              width={isMobile ? undefined : 200} 
              sx={{ pl: 2 }}
            >
              <span>{formatMoney(data?.totalRetainedEarningsActualYear)}</span>
            </OutstandingCell>
          </OutstandingRow>
        </TableBody>
      </Table>
      <Table>
        <TableBody>
          <OutstandingRow>
            <OutstandingCell 
              width={isMobile ? '60%' : 300} 
              sx={{ pl: 2 }}
            >
              <span>Total Retained Earnings</span>
            </OutstandingCell>
            {!isMobile && (
              <>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </>
            )}
            <OutstandingCell 
              width={isMobile ? undefined : 200} 
              sx={{ pl: 2 }}
            >
              <span>{formatMoney(data?.totalRetainedEarningsLastYear)}</span>
            </OutstandingCell>
          </OutstandingRow>
        </TableBody>
      </Table>
      <Table>
        <TableBody>
          <OutstandingRow>
            <OutstandingCell
              width={isMobile ? '60%' : 300}
              color={data && data.totalEquity > 0 ? 'var(--green)' : 'var(--error-color)'}
            >
              <Typography variant='h1'>Total Equity</Typography>
            </OutstandingCell>
            {!isMobile && (
              <>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </>
            )}
            <OutstandingCell
              width={isMobile ? undefined : 200}
              sx={{ pl: 2 }}
              color={data && data.totalEquity > 0 ? 'var(--green)' : 'var(--error-color)'}
            >
              <Typography variant='h1'>{formatMoney(data?.totalEquity)}</Typography>
            </OutstandingCell>
          </OutstandingRow>
        </TableBody>
      </Table>
    </ReportsTable>
  )
}

const ReportsTable = (props: any) => (
  <TableContainer
    sx={{
      '& .MuiTableHead-root .MuiTableCell-root': { fontWeight: 600, fontSize: 16 },
      '& .MuiTableBody-root .MuiTableCell-root': { fontSize: 14 },
      '& .MuiTableCell-root': { color: 'var(--secondary-grey)' },
      boxShadow: '0 4px 8px rgba(77, 94, 128, 0.08)',
      borderRadius: '8px',
      border: 'solid 1px var(--secondary-grey-bg)',
    }}
    {...props}
  >
    {props.children}
  </TableContainer>
)

const OutstandingCell = styled(TableCell)((props) => ({
  '&.MuiTableCell-root h1': {
    fontWeight: 600,
    fontSize: 16,
    color: props.color,
  },
  '&.MuiTableCell-root .MuiTypography-body1': {
    color: 'var(--gray3-color)',
    fontSize: 12,
  },
}))

const OutstandingRow = styled(TableRow)(() => ({
  '& .MuiTableCell-root': {
    border: 'none',
    backgroundColor: 'white',
  },
}))

const CollapsibleRow = ({
  title,
  totalAmount = 0,
  children,
}: {
  title: string
  totalAmount?: number
  children: ReactNode
}) => {
  const [open, setOpen] = useState(false)
  const isMobile = isUsingMobile()

  return (
    <>
      <TableRow sx={{ '& .MuiTableCell-root': { backgroundColor: 'var(--background-color)' } }}>
        <TableCell width={isMobile ? '60%' : 300}>
          {totalAmount !== 0 && (
            <IconButton size='small' onClick={() => setOpen(!open)} sx={{ mr: 1 }}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
          {title}
        </TableCell>
        {!isMobile && (
          <>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </>
        )}
        <TableCell width={isMobile ? undefined : 200}>{formatMoney(totalAmount)}</TableCell>
      </TableRow>
      <TableRow sx={{ '& .MuiTableCell-root': { backgroundColor: 'var(--background-color)' } }}>
        <TableCell sx={{ p: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            {children}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default BalanceSheetTable
