import {
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { IPaymentsFilters } from '../../../types/types'
import CheckIcon from '@mui/icons-material/Check'

interface PaymentsFiltersFormProps {
  filters: IPaymentsFilters
  setFilters: (filters: IPaymentsFilters) => void
}

const PaymentsFiltersForm = ({ filters, setFilters }: PaymentsFiltersFormProps) => {
  const handleStatusChange = (status: string) => {
    const statuses = filters.statuses?.includes(status)
      ? filters.statuses?.filter((item) => {
          return item !== status
        })
      : [...filters.statuses, status]

    setFilters({
      ...filters,
      statuses,
    })
  }

  const clearFilters = () => {
    setFilters({
      statuses: [],
      paymentMethod: '',
      fromDate: null,
      toDate: null,
      search: '',
    })
  }

  const statuses = [
    { name: 'Paid', key: 'PAID' },
    { name: 'Failed', key: 'FAILED' },
    { name: 'Refunded', key: 'REFUNDED' },
  ]

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box component='form' my={2} mx={3}>
        <Box mb={3}>
          <Typography mb={2} fontWeight='bold'>
            Customer or Invoice ID
          </Typography>

          <TextField
            required
            placeholder='Search by customer or Invoice ID...'
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            value={filters.search}
            onChange={(e) => {
              setFilters({ ...filters, search: e.target.value })
            }}
          />
        </Box>
        <Box mb={3}>
          <Typography mb={2} fontWeight='bold'>
            Date range
          </Typography>
          <Grid container gap={'5px'}>
            <Grid item flexGrow={1}>
              <DatePicker
                label='From'
                onChange={(value) => setFilters({ ...filters, fromDate: value })}
                value={filters.fromDate}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
            <Grid item flexGrow={1}>
              <DatePicker
                label='To'
                onChange={(value) => setFilters({ ...filters, toDate: value })}
                value={filters.toDate}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={3}>
          <Typography mb={2} fontWeight='bold'>
            Payment Method
          </Typography>
          <FormControl fullWidth>
            <InputLabel id='payment-method-label'>Payment Method</InputLabel>
            <Select
              labelId='payment-method-label'
              value={filters.paymentMethod}
              label='Payment Method'
              onChange={(e) => setFilters({ ...filters, paymentMethod: e.target.value })}
            >
              <MenuItem value=''>None</MenuItem>
              <MenuItem value='CREDIT_CARD'>Credit Card</MenuItem>
              <MenuItem value='CASH'>Cash</MenuItem>
              <MenuItem value='CHECK'>Check</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <Typography mb={2} fontWeight='bold'>
            Status
          </Typography>
          <Stack
            spacing={1}
            direction='row'
            sx={{
              '& .MuiChip-filledSuccess': { backgroundColor: 'var(--success-bg-color)' },
              '& .MuiChip-filledSuccess:hover': {
                backgroundColor: 'var(--secondary-grey)',
                color: '#fff',
              },
            }}
          >
            {statuses.map((status) => (
              <Chip
                label={status.name}
                key={status.key}
                icon={filters.statuses.includes(status.key) ? <CheckIcon /> : undefined}
                color={filters.statuses.includes(status.key) ? 'success' : 'default'}
                variant={filters.statuses.includes(status.key) ? 'filled' : 'outlined'}
                onClick={() => handleStatusChange(status.key)}
              />
            ))}
          </Stack>
          <div style={{display: "flex", marginTop: "20px", color: "#0039ff", cursor: "pointer"}} onClick={clearFilters}>Clear All</div>
        </Box>
      </Box>
    </LocalizationProvider>
  )
}

export default PaymentsFiltersForm
