import { Box, Button, Grid } from '@mui/material';

interface IButtonsFooter {
	saveTextButton?: string;
	cancelTextButton?: string;
	handleCancel?: () => void;
	handleAcceptClick?: () => void;
	isDisabledSaveButton?: boolean;
}

const ButtonsFooter = (props: IButtonsFooter) => {
	const {
		saveTextButton,
		cancelTextButton,
		handleAcceptClick,
		handleCancel,
		isDisabledSaveButton,
	} = props;

	return (
		<Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
			<Box sx={{ display: 'flex', marginBottom: '20px', flexDirection: 'column', width: '100%' }}>
				<Grid py='10px'>
					<Button
						sx={{ fontWeight:600}}
						disabled={isDisabledSaveButton}
						color="primary"
						variant="contained"
						onClick={handleAcceptClick}
						fullWidth
					>
						{saveTextButton}
					</Button>
				</Grid>
				<Grid py='10px'>
          <Button
            sx={{ border: '2px solid', fontWeight:600, ':hover': {borderWidth : '2px'} }}
            color="primary"
            variant="outlined"
            onClick={handleCancel}
            fullWidth
          >
            {cancelTextButton}
          </Button>
        </Grid>
			</Box>
		</Box>
	);
};

export default ButtonsFooter;
