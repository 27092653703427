import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material'
import { MouseEvent, useState } from 'react'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

const ReferenceMenuReferral = () => {
  const [openReference, setOpenReference] = useState(false)
  const [anchorReference, setAnchorReference] = useState<null | HTMLElement>(null)

  const handleReferenceButton = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorReference(event.currentTarget)
    setOpenReference(true)
  }

  const handleCloseReference = () => {
    setAnchorReference(null)
    setOpenReference(false)
  }
  return (
    <>
      <IconButton aria-label='reference' onClick={handleReferenceButton}>
        <HelpOutlineIcon />
      </IconButton>

      <Menu open={openReference} anchorEl={anchorReference} onClose={handleCloseReference}>
        <MenuItem>
          <Typography>References</Typography>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <FiberManualRecordIcon
              sx={{
                '&.MuiSvgIcon-colorSuccess': {
                  color: 'var(--success-color)',
                  borderRadius: '50%',
                  backgroundColor: 'var(--success-bg-color)',
                },
              }}
              color='success'
            />
          </ListItemIcon>
          <ListItemText>Subscribed</ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <FiberManualRecordIcon
              sx={{
                '&.MuiSvgIcon-colorWarning': {
                  color: 'var(--warning-color)',
                  borderRadius: '50%',
                  backgroundColor: 'var(--warning-bg-color)',
                },
              }}
              color='warning'
            />
          </ListItemIcon>
          <ListItemText>Sent</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}

export default ReferenceMenuReferral
