import { useSelector } from 'react-redux'
import { AppState } from '../../../redux/store'
import ActivityInvoiceCards from './activityInvoices'
import ActivityPaymentCards from './activityPayments'
import ActivityRefundCards from './activityRefunds'

const ActivityStatementCards = () => {
  const activityStatements = useSelector((state: AppState) => state.homeReducer.activityStatements)

  return (
    <>
      <ActivityInvoiceCards activityStatements={activityStatements} />
      <ActivityPaymentCards activityStatements={activityStatements} />
      <ActivityRefundCards activityStatements={activityStatements} />
    </>
  )
}

export default ActivityStatementCards
