import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import BaseModal from '../../../components/modal/modal'
import {
  COMMON_FIELD_VALID,
  CUSTOMER_NAME_VALID,
  CUSTOMER_PRIMARY_NAME_VALID,
  LONG_FIELD_VALID,
  PHONE_VALID,
  POSTAL_CODE_VALID,
  STATE_FIELD,
  URL_VALID,
  VALID_EMAIL,
} from '../../../helpers/constants'
import { deformatPhoneNumber } from '../../../helpers/formatPrice'
import { createNewCustomer } from '../../../redux/actions/home.actions'
import { IProduct } from '../../../types/types'
import './customer-manage-dialog.css'
import CustomerModalFooter from './footer'
import CustomerModalBody from './modal'
import { isUsingMobile } from '../../../helpers/utils'

interface ICustomerManageDialog {
  open: boolean
  customer?: IProduct
  onClose: () => void
}

const CustomerManageDialog = (props: ICustomerManageDialog) => {
  const isMobile = isUsingMobile()
  const dispatch = useDispatch()
  const { open, onClose } = props
  const [dialogStep, setDialogStep] = useState<number>(0)
  const [isShippingTheSame, setIsShippingTheSame] = useState<boolean>(false)
  const [newCustomer, setNewCustomer] = useState({
    name: '',
    primaryFirstName: '',
    primaryLastName: '',
    primaryEmail: '',
    primaryPhoneNumber: '',
    accountNumber: '',
    website: '',
    notes: '',
    currency: '',
    billingAddress: '',
    billingAddressTwo: '',
    billingCountry: 'United States',
    billingState: '',
    billingCity: '',
    billingPostalCode: '',
    shippingName: '',
    shippingAddress: '',
    shippingAddressTwo: '',
    shippingCountry: '',
    shippingCity: '',
    shippingState: '',
    shippingPostalCode: '',
    shippingPhone: '',
    deliveryInstructions: '',
  })
  useEffect(() => {
    setNewCustomer({
      name: '',
      primaryFirstName: '',
      primaryLastName: '',
      primaryEmail: '',
      primaryPhoneNumber: '',
      accountNumber: '',
      website: '',
      notes: '',
      currency: '',
      billingAddress: '',
      billingAddressTwo: '',
      billingCountry: 'United States',
      billingState: '',
      billingCity: '',
      billingPostalCode: '',
      shippingName: '',
      shippingAddress: '',
      shippingAddressTwo: '',
      shippingCountry: '',
      shippingCity: '',
      shippingState: '',
      shippingPostalCode: '',
      shippingPhone: '',
      deliveryInstructions: '',
    })
    setDialogStep(0)
  }, [open])
  const [formErrors, setFormErrors] = useState({
    name: '',
    primaryFirstName: '',
    primaryLastName: '',
    email: '',
    primaryPhoneNumber: '',
    website: '',
    billingAddress: '',
    billingAddressTwo: '',
    billingState: '',
    billingPostalCode: '',
    shippingName: '',
    shippingPhone: '',
    shippingAddress: '',
    shippingAddressTwo: '',
    shippingCity: '',
    shippingState: '',
    shippingPostalCode: '',
  })

  const handleSaveCustomer = async () => {
    dispatch(
      createNewCustomer({
        ...newCustomer,
        primaryPhoneNumber: deformatPhoneNumber(newCustomer.primaryPhoneNumber),
        shippingPhone: deformatPhoneNumber(newCustomer.shippingPhone),
      }),
    )
    onClose()
  }

  const handleChangeNewCustomer = (e: any, value: string, additionalValue?: string) => {
    if (
      (value === 'billingAddress' ||
        value === 'billingAddressTwo' ||
        value === 'billingCity' ||
        value === 'billingState' ||
        value === 'billingPostalCode') &&
      isShippingTheSame &&
      additionalValue
    ) {
      setNewCustomer({
        ...newCustomer,
        [value]: e.target.value,
        [additionalValue]: e.target.value,
      })
    } else {
      setNewCustomer({
        ...newCustomer,
        [value]: e.target.value,
      })
    }
  }
  const handleEnableNextButton = () => {
    switch (dialogStep) {
      case 0:
        return (
          CUSTOMER_NAME_VALID.test(newCustomer.name) &&
          VALID_EMAIL.test(newCustomer.primaryEmail) &&
          CUSTOMER_PRIMARY_NAME_VALID.test(newCustomer.primaryFirstName) &&
          CUSTOMER_PRIMARY_NAME_VALID.test(newCustomer.primaryLastName) &&
          PHONE_VALID.test(deformatPhoneNumber(newCustomer.primaryPhoneNumber)) &&
          (!newCustomer.website || URL_VALID.test(newCustomer.website))
        )
      case 1:
        return (
          LONG_FIELD_VALID.test(newCustomer.billingAddress) &&
          (!newCustomer.billingAddressTwo ||
            LONG_FIELD_VALID.test(newCustomer.billingAddressTwo)) &&
          COMMON_FIELD_VALID.test(newCustomer.billingCity) &&
          STATE_FIELD.test(newCustomer.billingState) &&
          newCustomer.billingState.length === 2 &&
          POSTAL_CODE_VALID.test(newCustomer.billingPostalCode)
        )
      case 2:
        return (
          (!newCustomer.shippingName || LONG_FIELD_VALID.test(newCustomer.shippingName)) &&
          (!newCustomer.shippingPhone ||
            PHONE_VALID.test(deformatPhoneNumber(newCustomer.shippingPhone))) &&
          (!newCustomer.shippingAddress || LONG_FIELD_VALID.test(newCustomer.shippingAddress)) &&
          (!newCustomer.shippingAddressTwo ||
            LONG_FIELD_VALID.test(newCustomer.shippingAddressTwo)) &&
          (!newCustomer.shippingCity || COMMON_FIELD_VALID.test(newCustomer.shippingCity)) &&
          (!newCustomer.shippingState || STATE_FIELD.test(newCustomer.shippingState)) &&
          (!newCustomer.shippingPostalCode ||
            POSTAL_CODE_VALID.test(newCustomer.shippingPostalCode))
        )
      default:
        return true
    }
  }
  const handlePreviousClick = () => setDialogStep(dialogStep - 1)
  const handleClickNextButton = () => setDialogStep(dialogStep + 1)
  const handleIsShippingSameClick = () => {
    if (!isShippingTheSame) {
      setNewCustomer({
        ...newCustomer,
        shippingAddress: newCustomer.billingAddress,
        shippingAddressTwo: newCustomer.billingAddressTwo,
        shippingCity: newCustomer.billingCity,
        shippingState: newCustomer.billingState,
        shippingCountry: newCustomer.billingCountry,
        shippingPostalCode: newCustomer.billingPostalCode,
      })
    } else {
      setNewCustomer({
        ...newCustomer,
        shippingAddress: '',
        shippingAddressTwo: '',
        shippingCity: '',
        shippingState: '',
        shippingPostalCode: '',
        shippingCountry: '',
      })
    }
    setIsShippingTheSame(!isShippingTheSame)
  }

  return (
    <BaseModal
      title='Add new customer'
      body={
        <CustomerModalBody
          setFormErrors={setFormErrors}
          formErrors={formErrors}
          dialogStep={dialogStep}
          newCustomer={newCustomer}
          handleChangeNewCustomer={handleChangeNewCustomer}
          handleIsShippingSameClick={handleIsShippingSameClick}
          isShippingTheSame={isShippingTheSame}
          handlePreviousClick={handlePreviousClick}
          handleSaveCustomer={handleSaveCustomer}
          handleEnableNextButton={handleEnableNextButton}
          handleClickNextButton={handleClickNextButton}
        />
      }
      footer={
        <CustomerModalFooter
          dialogStep={dialogStep}
          handlePreviousClick={handlePreviousClick}
          handleClickNextButton={handleClickNextButton}
          handleEnableNextButton={handleEnableNextButton}
          handleSaveCustomer={handleSaveCustomer}
        />
      }
      isModalOpen={open}
      handleCloseModal={onClose}
      size={isMobile ? 'xs' : 'small'}
    ></BaseModal>
  )
}

export default CustomerManageDialog
