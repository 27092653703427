import {
	Box,
	FormControlLabel,
	FormGroup,
	TextField,
	Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../api/api';
import { errorHandler } from '../../helpers/errorHandler';
import { validateEmail, validateName } from '../../helpers/utils';
import { getPermissions } from '../../redux/actions/settings.actions';
import { AppState } from '../../redux/store';
import { IRole, IUser } from '../../types/types';
import { IOSSwitch } from '../toogle/toogle';

interface IUserViewProps {
	publicId: string;
	setEditedUser: (user: IUser) => void;
	editedUser: IUser;
	setFormErrors: any;
	formErrors: any;
	isEdit: boolean;
}

const UserView = (props: IUserViewProps) => {
	const {
		publicId,
		setEditedUser,
		editedUser,
		setFormErrors,
		formErrors,
		isEdit,
	} = props;
	const [chosenRole, setChosenRole] = useState<IRole | null>(null);
	const roles = useSelector((state: AppState) => state.settingsReducer.roles);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!isEdit) {
			if (!editedUser.role?.publicId) {
				setFormErrors((prevState: any) => ({
					...prevState,
					role: 'Role is required',
				}));
			} else {
				setFormErrors((prevState: any) => ({ ...prevState, role: '' }));
			}
		}
	}, [editedUser.role?.publicId]);

	const handleSwitchChange = (role: IRole) => {
		setEditedUser({ ...editedUser, role: role });
		setChosenRole(role);
	};

	useEffect(() => {
		dispatch(getPermissions());
		if (publicId) {
			api
				.getUserByPublicId(publicId)
				.then((response) => {
					const user: IUser = response.data.body;
					setEditedUser(user);
					if (user.role?.publicId) {
						const role: IRole | undefined = roles.find(
							(role: IRole) => role.publicId === user.role?.publicId
						);
						setChosenRole(role || null);
					}
				})
				.catch((error) => {
					errorHandler(error);
				});
		}
	}, [publicId]);

	return (
		<Box component="form" my={2} mx={3}>
			<Box mb={3}>
				<TextField
					error={!!formErrors['name']}
					helperText={formErrors['name']}
					required
					id="outlined-required"
					label="Full name"
					InputLabelProps={{
						shrink: true,
					}}
					fullWidth
					defaultValue={editedUser?.name || ''}
					value={editedUser?.name}
					onBlur={() => validateName(editedUser?.name, 'name', setFormErrors, 'Full name')}
					onChange={(e) => {
						setEditedUser({ ...editedUser, name: e.target.value });
						validateName(e.target.value, 'name', setFormErrors, 'Full name');
					}}
				/>
			</Box>
			<Box mb={3}>
				<TextField
					error={!!formErrors['email']}
					helperText={formErrors['email']}
					required
					type="email"
					id="outlined-required"
					label="Email"
					InputLabelProps={{
						shrink: true,
					}}
					fullWidth
					defaultValue={editedUser?.email || ''}
					value={editedUser?.email}
					onBlur={() => validateEmail(editedUser?.email, setFormErrors)}
					onChange={(e) => {
						setEditedUser({ ...editedUser, email: e.target.value });
						validateEmail(e.target.value, setFormErrors);
					}}
				/>
			</Box>
			<Box mb={3}>
				<Typography color="rgba(0, 0, 0, 0.6)" fontSize="14px">
					Select a Role <span>*</span>
				</Typography>
				<FormGroup>
					{roles?.map((role: IRole) => (
						<FormControlLabel
							key={role.name}
							control={<IOSSwitch sx={{ m: 1 }} />}
							checked={role.name === chosenRole?.name}
							onChange={() => handleSwitchChange(role)}
							label={role.name}
						/>
					))}
				</FormGroup>
			</Box>
		</Box>
	);
};

export default UserView;
