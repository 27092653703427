import { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { saveVendors } from '../../redux/actions/home.actions'
import { AppState } from '../../redux/store'
import ContentHeader from '../../components/content-header'
import PlusButton from '../../components/plus-button/plus-button'
import NoDataDisplay from '../../components/noDataDisplay/noDataDisplay'

import { Box } from '@mui/material'
import VendorsTable from './vendors-table'
import { IVendor } from '../../types/types'
import useAuthorization from '../../helpers/useAuthorization'
import SuccessDialog from '../../components/assitance-dialog/success-dialog'
import VendorsCreateDialog from './dialog/vendors-create-dialog'

import './vendors.css'
import VendorFilters from './filters'
import ConfirmDialog from '../../components/dialog/dialog'
import { successToastNotification } from '../../helpers/toastNotification'
import { errorHandler } from '../../helpers/errorHandler'
import api from '../../api/api'
import VendorsEditDialog from './dialog/vendors-edit-dialog'
import ImportDialog from '../../components/import-dialog/import-dialog'
import ImportConfirmDialog from './import-confirm-dialog/import-confirm-dialog'
import saveAs from 'file-saver'

const Vendors = () => {
  const dispatch = useDispatch()
  const [selectedVendor, setSelectedVendor] = useState<any>()
  const [isOpenImportDialog, setIsOpenImportDialog] = useState(false)
  const [isVendorCreateDialogOpen, setIsVendorCreateDialogOpen] = useState<boolean>(false)
  const [isVendorEditDialogOpen, setIsVendorEditDialogOpen] = useState<boolean>(false)
  const [assistanceSuccess, setAssistanceSuccess] = useState(false)
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState<boolean>(false)
  const vendors = useSelector((state: AppState) => state.homeReducer.vendors)
  const [isImportFormatCorrect, setIsImportFormatCorrect] = useState<boolean | null>(null)
  const [importData, setImportData] = useState<any[]>([])

  const { isAuthorized } = useAuthorization()
  const preview = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    dispatch(saveVendors())
  }, [])

  const handleSearchClick = (search: string, selectedType: string) => () => {
    dispatch(saveVendors(0, 15, search, selectedType))
  }

  const handleImportClick = () => {
    setIsOpenImportDialog(true)
  }

  const handleDownloadClick = () => {
    api
      .exportVendors()
      .then((response) => {
        const blob = response.data

        const fetchData = async () => {
          try {
            saveAs(blob, 'vendors.xlsx')
          } catch (error) {
            console.error('Error while fetching vendor data', error)
          }
        }

        fetchData()
      })
      .catch((error) => {
        console.error('Error:', error)
      })

    return null
  }

  const handleCreateClick = () => {
    setIsVendorCreateDialogOpen(true)
  }

  const handleEditClick = (vendor: IVendor) => {
    setSelectedVendor(vendor)
    setIsVendorEditDialogOpen(true)
  }

  const handleDeleteClick = () => {
    setIsOpenDeleteDialog(true)
  }

  const handleCloseDeleteDialog = () => {
    setSelectedVendor(null)
    setIsOpenDeleteDialog(false)
  }

  const handleDeleteVendor = () => {
    setIsOpenDeleteDialog(false)
    api
      .deleteVendor(selectedVendor?.publicId)
      .then(() => {
        successToastNotification(`${selectedVendor?.name} was successfully deleted`)
        dispatch(saveVendors())
      })
      .catch((err) => errorHandler(err))
    setSelectedVendor(null)
  }

  const processImportData = (dataRows: string[]) => {
    const data: any[] = []

    dataRows.forEach((row) => {
      const values = row.split(',')

      if (values.length === 10) {
        data.push({
          businessName: values[0].trim().replaceAll('"', ''),
          firstName: values[1].trim().replaceAll('"', ''),
          lastName: values[2].trim().replaceAll('"', ''),
          addressOne: values[3].trim().replaceAll('"', ''),
          addressTwo: values[4].trim().replaceAll('"', ''),
          phone: values[5].trim().replaceAll('"', ''),
          mobile: values[6].trim().replaceAll('"', ''),
          website: values[7].trim().replaceAll('"', ''),
          emailAddress: values[8].trim().replaceAll('"', ''),
          type: values[9].trim().replaceAll('"', ''),
        })
      } else {
        console.error('Invalid row format:', row)
      }
    })

    setImportData(data)
  }

  return (
    <>
      <ContentHeader title={<Box>Vendors</Box>} />

      <VendorFilters
        onSearchClick={handleSearchClick}
        onDownloadClick={handleDownloadClick}
        onExportClick={handleImportClick}
      />

      {vendors && vendors.length > 0 ? (
        <div ref={preview}>
          <VendorsTable
            vendorsInTable={vendors}
            onRowClick={handleEditClick}
            setSelectedVendor={setSelectedVendor}
            onDeleteClick={handleDeleteClick}
          />
        </div>
      ) : (
        <NoDataDisplay
          title='No data to display'
          description='Add vendors to your account and effortlessly track expenses by creating bills.'
        />
      )}

      {isAuthorized('write_invoice') && (
        <PlusButton tooltipText='Add Vendor' handleOnClick={handleCreateClick} />
      )}

      <VendorsCreateDialog
        open={isVendorCreateDialogOpen}
        onClose={() => setIsVendorCreateDialogOpen(false)}
      />

      <VendorsEditDialog
        open={isVendorEditDialogOpen}
        onClose={() => setIsVendorEditDialogOpen(false)}
        vendor={selectedVendor}
      />

      <ImportDialog
        isOpen={isOpenImportDialog}
        dialogTitle='Upload a file you want to import'
        dialogBodyContent='Fill in the fields in the sample file, save it and upload this file to the system.'
        handleClose={() => setIsOpenImportDialog(false)}
        setAssistanceSuccess={setAssistanceSuccess}
        csvData={
          '"Business Name","First Name","Last Name","Address One","Address Two",Phone,Mobile,"Web Site","Email Address",Type\n"Vendor 1",John,Doe,"Street 1",,,,,,REGULAR\n"Vendor 2",John,Doe,"Street 1",,,,,,REGULAR\n"Vendor 3",John,Doe,"Street 1",,,,,,REGULAR'
        }
        expectedHeader={
          '"Business Name","First Name","Last Name","Address One","Address Two",Phone,Mobile,"Web Site","Email Address",Type'
        }
        processImportData={processImportData}
        setIsFormatCorrect={setIsImportFormatCorrect}
        confirmDialog={
          <ImportConfirmDialog
            isOpen={
              isImportFormatCorrect ? importData.length > 0 && importData !== undefined : false
            }
            dialogTitle='Confirm import'
            dialogBodyContent='Please confirm that the columns match the entered values.'
            handleClose={() => setImportData([])}
            vendors={importData}
            handleCloseExport={() => setIsOpenImportDialog(false)}
          />
        }
      />

      {assistanceSuccess && (
        <SuccessDialog isOpen={assistanceSuccess} setAssistanceSuccess={setAssistanceSuccess} />
      )}

      <ConfirmDialog
        isOpen={isOpenDeleteDialog}
        dialogTitle='Sure you want to delete?'
        dialogBodyContent='Deleting this vendor will permanently remove it from your account, and it cannot be undone.'
        buttonConfirmText='Yes, Delete'
        buttonCancelText='Cancel'
        handleClose={handleCloseDeleteDialog}
        handleConfirm={handleDeleteVendor}
      />
    </>
  )
}

export default Vendors
