import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, Box, CssBaseline, IconButton, Theme, Toolbar, Typography } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Drawer from '@mui/material/Drawer'
import Stack from '@mui/material/Stack'
import { makeStyles } from '@mui/styles'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/loader/loader'
import Sidebar from '../../components/sidebar'
import { getCdnUrl, isUsingMobile } from '../../helpers/utils'
import Onboarding from '../../pages/onboarding/onboarding'
import { useNavigate } from 'react-router-dom'
import {
  changeIsCompleteBusiness,
  changeIsOnboarding,
  saveCurrentUser,
  saveUSIOApiKey,
} from '../../redux/actions/auth.actions'
import {
  getCompany,
  saveAccountSubscription,
  saveCustomers,
  saveProducts,
} from '../../redux/actions/home.actions'
import { AppState } from '../../redux/store'
import AppRoutes from '../../routes/AppRoutes'
import './home.css'
import USIONotification from './usioNotification'
import SidemenuSwitch from './sidemenu-switch'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import LogoutIcon from '@mui/icons-material/Logout'
import { SAVE_COMPANY } from '../../redux/actions/home.actions.type'
import { Auth } from 'aws-amplify'
import SubscriptionButton from '../../components/subscription-button'

const Home = () => {
  const dispatch = useDispatch()
  const companyDetails = useSelector((state: AppState) => state.homeReducer.company)
  const currentUser = useSelector((state: AppState) => state.authReducer.cheddarUser)
  const isOnBoarding = useSelector((state: AppState) => state.authReducer.isOnBoarding)
  const haveSubscription = useSelector((state: AppState) => state.authReducer.haveSubscription)
  const [isLoadingLocal, setIsLoadingLocal] = useState(false)
  const smSidebarWidth = 246
  const xsSidebarWidth = 70

  const isCompleteBusiness = useSelector((state: AppState) => state.authReducer.isCompleteBusiness)
  const isLoading = useSelector((state: AppState) => state.homeReducer.isLoading)

  const [showBusinessOnBoarding, setShowBusinessOnBoarding] = useState(false)

  const isMobile = isUsingMobile()
  const [isFullMenu, setIsFullMenu] = useState(!isMobile)
  const [isSidebarMenuVisible, setIsSidebarMenuVisible] = useState(!isMobile)
  const [anchorEl, setAnchorEl] = useState(null)
  const navigate = useNavigate()

  async function signOut() {
    try {
      await Auth.signOut()
      sessionStorage.removeItem('token')
      dispatch({
        type: SAVE_COMPANY,
        payload: null,
      })
      navigate('/sign-in')
    } catch (error) {
      console.error(error)
    }
  }

  const handleMenuItemClick = (option: any) => {
    option === 'Log Out' && signOut()
    option === 'Profile' && navigate('/profile/settings')
    handleClose()
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleMenuButtonClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  useEffect(() => {
    if ((haveSubscription || companyDetails?.isRoot) && isCompleteBusiness) {
      dispatch(changeIsOnboarding(false))
    }
  }, [haveSubscription, isCompleteBusiness, companyDetails, dispatch])

  useEffect(() => {
    dispatch(changeIsCompleteBusiness(companyDetails !== null))
  }, [companyDetails])

  useEffect(() => {
    dispatch(saveCurrentUser())

    let cancel = false
    setIsLoadingLocal(true)

    Promise.all([dispatch(saveAccountSubscription()), dispatch(getCompany())]).then(() => {
      setTimeout(() => {
        if (!cancel) {
          setIsLoadingLocal(false)
        }
      }, 1) // wait for next render to avoid blinking behavior
    })

    if (companyDetails) {
      dispatch(saveProducts())
      dispatch(saveCustomers())
    }

    return () => {
      cancel = true
    }
  }, [])

  useEffect(() => {
    if (!isOnBoarding) {
      dispatch(saveUSIOApiKey())
    }
  }, [isOnBoarding])

  const getMarginLeft = () => {
    if (isFullMenu && !isMobile) {
      return smSidebarWidth
    }

    if (isSidebarMenuVisible && !isFullMenu) {
      return xsSidebarWidth
    }

    if (!isMobile) {
      return xsSidebarWidth
    }

    return 0
  }

  const getWidth = () => {
    if (isFullMenu && !isMobile) {
      return `calc(100% - ${smSidebarWidth}px)`
    }

    if (isSidebarMenuVisible && !isFullMenu) {
      return `calc(100% - ${xsSidebarWidth}px)`
    }

    if (!isMobile) {
      return `calc(100% - ${xsSidebarWidth}px)`
    }

    return undefined
  }

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      [theme.breakpoints.up('xl')]: {
        display: 'flex',
        width: '100%',
      },
    },
    content: {
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    contentShift: {
      marginLeft: getMarginLeft(),
      width: getWidth(),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  }))

  const classes = useStyles()

  const showFullMenu = () => {
    setIsFullMenu(true)
  }

  const handleDrawerVisible = () => {
    setIsSidebarMenuVisible(true)
  }

  const handleClickOutside = () => {
    if (isMobile) {
      setIsFullMenu(false)
      setIsSidebarMenuVisible(false)
    }
  }

  const handleSubItemClick = () => {
    if (isMobile) {
      setIsSidebarMenuVisible(false)
      setIsFullMenu(false)
    }
  }

  return (
    <div className={classes.root}>
      {!isOnBoarding ? (
        <>
          {isLoading && <Loader />}
          <CssBaseline />
          <AppBar
            color='inherit'
            position='fixed'
            sx={{ boxShadow: 'none', backgroundColor: 'var(--background-color)' }}
          >
            <Toolbar>
              <IconButton
                color='primary'
                aria-label='open drawer'
                onClick={handleDrawerVisible}
                edge='start'
              >
                <MenuIcon />
              </IconButton>
              <div
                style={{
                  display: 'flex',
                  margin: '10px 0 10px 0',
                  padding: '16px',
                  marginLeft: 'auto',
                  background: 'white',
                  height: '47px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '8px',
                  marginRight: '13px',
                  cursor: 'pointer',
                }}
              >
                <div
                  style={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    backgroundColor: '#E5EBFF',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '8px',
                  }}
                >
                  <span
                    onClick={() => navigate('/profile/settings')}
                    style={{ color: '#0039FF', fontSize: '16px', fontWeight: 'bold' }}
                  >
                    {currentUser && currentUser?.name.charAt(0).toUpperCase()}
                  </span>
                </div>
                <div
                  onClick={() => navigate('/profile/settings')}
                  style={{
                    marginRight: '8px',
                    marginLeft: '8px',
                    color: '#99A6BF',
                    fontSize: '16px',
                  }}
                >
                  {currentUser && currentUser?.name}
                </div>
                <IconButton
                  aria-label='more'
                  id='long-button'
                  aria-haspopup='true'
                  onClick={handleMenuButtonClick}
                  sx={{
                    justifyItems: 'right',
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                  <MenuItem
                    style={{ color: '#0039FF', fontSize: '14px' }}
                    onClick={() => handleMenuItemClick('Profile')}
                  >
                    Profile settings
                  </MenuItem>
                  <MenuItem
                    style={{ color: '#CC1414', fontSize: '14px' }}
                    onClick={() => handleMenuItemClick('Log Out')}
                  >
                    <LogoutIcon style={{ marginRight: '2px', height: '20px', width: '20px' }} />
                    Log out
                  </MenuItem>
                </Menu>
              </div>
            </Toolbar>
          </AppBar>
          <Box
            sx={{
              width: isFullMenu ? smSidebarWidth - 9 : xsSidebarWidth + 9,
              position: 'absolute',
              left: 0,
              top: 0,
              height: '100%',
              '&:hover .sidemenuSwitch': {
                display: 'flex',
              },
            }}
          >
            <Drawer
              variant={isMobile ? 'persistent' : 'permanent'}
              open={isSidebarMenuVisible}
              sx={{
                '& .MuiPaper-root': {
                  height: { xs: 'calc(100vh - 15px)', sm: 'calc(100vh - 30px)' },
                  borderRadius: isFullMenu ? '0 32px 32px 0' : '32px',
                  marginLeft: { xs: 1, sm: '1rem' },
                  marginTop: { xs: 1, sm: '1rem' },
                  boxShadow: '0 8px 40px rgba(77, 94, 128, 0.15)',
                  paddingBottom: '1rem',
                },
              }}
            >
              <Toolbar sx={{ height: '134px', padding: !isFullMenu ? '0 !important' : undefined }}>
                <Stack
                  direction='row'
                  alignItems='center'
                  flexGrow={2}
                  justifyContent='center'
                  spacing={2}
                >
                  {companyDetails && (
                    <Avatar
                      alt='logo'
                      src={getCdnUrl(companyDetails.logoFileName)}
                      onClick={showFullMenu}
                    />
                  )}
                  {isFullMenu && (
                    <Typography
                      component='h1'
                      variant='h5'
                      style={{ maxWidth: '124px' }}
                      flexGrow={2}
                    >
                      {companyDetails?.name}
                    </Typography>
                  )}
                </Stack>
              </Toolbar>
              <div onClick={showFullMenu} style={{ flexGrow: 1 }}>
                <Sidebar open={isFullMenu} onSubItemClick={handleSubItemClick} />
              </div>
              <SubscriptionButton isFullMenu={isFullMenu} onClick={() => navigate('/subs')} />
            </Drawer>
            {isSidebarMenuVisible && (
              <SidemenuSwitch
                width={24}
                onClick={() => setIsFullMenu(!isFullMenu)}
                isOpen={isFullMenu}
              />
            )}
          </Box>
          <main className={`${classes.content} ${classes.contentShift}`}>
            <Toolbar />

            {companyDetails && companyDetails?.merchantStatus !== 'Onboarded' && (
              <Box mb='1rem'>
                <USIONotification message='In order to complete the registration of your business and be able to receive payments, fill out' />
              </Box>
            )}

            <Box onClick={handleClickOutside}>
              <AppRoutes />
            </Box>
          </main>
        </>
      ) : (
        <>
          {isLoading && <Loader />}
          <CssBaseline />
          <Drawer
            variant={isMobile ? 'persistent' : 'permanent'}
            open={isSidebarMenuVisible}
            sx={{
              '& .MuiPaper-root': {
                borderRadius: isFullMenu ? '0 32px 32px 0' : '32px 32px 0 0',
                marginLeft: { xs: 1, sm: '1rem' },
                marginTop: { xs: 1, sm: '1rem' },
                boxShadow: '0 8px 40px rgba(77, 94, 128, 0.15)',
                paddingBottom: '1rem',
              },
            }}
          >
            <div onClick={showFullMenu} style={{ flexGrow: 1 }}>
              <Sidebar open={isFullMenu} />
            </div>
            <SubscriptionButton isFullMenu={isFullMenu} />
          </Drawer>
          {!isLoadingLocal && (
            <>
              <CssBaseline />
              <AppBar
                color='inherit'
                position='fixed'
                sx={{ boxShadow: 'none', backgroundColor: 'var(--background-color)' }}
              >
                <Toolbar>
                  <IconButton
                    color='primary'
                    aria-label='open drawer'
                    onClick={handleDrawerVisible}
                    edge='start'
                  >
                    <MenuIcon />
                  </IconButton>
                  <div
                    style={{
                      display: 'flex',
                      margin: '10px 0 10px 0',
                      padding: '16px',
                      marginLeft: 'auto',
                      background: 'white',
                      height: '47px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '8px',
                      marginRight: '13px',
                      cursor: 'pointer',
                    }}
                  >
                    <div
                      style={{
                        width: '30px',
                        height: '30px',
                        borderRadius: '50%',
                        backgroundColor: '#E5EBFF',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: '8px',
                      }}
                    >
                      <span
                        onClick={() => navigate('/profile/settings')}
                        style={{ color: '#0039FF', fontSize: '16px', fontWeight: 'bold' }}
                      >
                        {currentUser && currentUser?.name.charAt(0).toUpperCase()}
                      </span>
                    </div>
                    <div
                      onClick={() => navigate('/profile/settings')}
                      style={{
                        marginRight: '8px',
                        marginLeft: '8px',
                        color: '#99A6BF',
                        fontSize: '16px',
                      }}
                    >
                      {currentUser && currentUser?.name}
                    </div>
                    <IconButton
                      aria-label='more'
                      id='long-button'
                      aria-haspopup='true'
                      onClick={handleMenuButtonClick}
                      sx={{
                        justifyItems: 'right',
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                      <MenuItem
                        style={{ color: '#CC1414', fontSize: '14px' }}
                        onClick={() => handleMenuItemClick('Log Out')}
                      >
                        <LogoutIcon style={{ marginRight: '2px', height: '20px', width: '20px' }} />
                        Log out
                      </MenuItem>
                    </Menu>
                  </div>
                </Toolbar>
              </AppBar>
              <Onboarding
                isFullMenu={isFullMenu}
                isDrawerOpen={isSidebarMenuVisible}
                drawerWidth={smSidebarWidth}
                showBusinessOnBoarding={showBusinessOnBoarding}
                setShowBusinessOnBoarding={setShowBusinessOnBoarding}
              />
            </>
          )}
        </>
      )}
    </div>
  )
}

export default Home
