import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useEffect, useState } from 'react'
import AccountInfoForm from './forms/accountInfoForm'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../redux/store'
import { saveCertainCustomer } from '../../redux/actions/home.actions'
import BillingAddressForm from './forms/billingAddressForm'
import ShippingAddressForm from './forms/shippingAddressForm'
import CreditCardsForm from './forms/creditCardsForm'
import ContentHeader from '../../components/content-header'
import api from '../../api/api'
import { successToastNotification } from '../../helpers/toastNotification'
import { isUsingMobile } from '../../helpers/utils'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

type AccordionState = {
  'account-info': boolean
  'billing-address': boolean
  'shipping-address': boolean
  'credit-cards': boolean
}

const CustomerManage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { publicId } = useParams()
  const [customer, setCustomer] = useState<any>()
  const isMobile = isUsingMobile()

  const chosenCustomer = useSelector((state: AppState) => state.homeReducer.chosenCustomer)
  const company = useSelector((state: AppState) => state.homeReducer.company)

  const [accordionState, setAccordionState] = useState<AccordionState>({
    'account-info': !isMobile,
    'billing-address': !isMobile,
    'shipping-address': !isMobile,
    'credit-cards': !isMobile,
  })

  const handleAccordionChange = (key: keyof AccordionState) => () => {
    setAccordionState({
      ...accordionState,
      [key]: !accordionState[key],
    })
  }

  useEffect(() => {
    if (publicId) {
      dispatch(saveCertainCustomer(publicId))
    }
  }, [publicId])

  useEffect(() => {
    if (chosenCustomer) {
      setCustomer({ ...chosenCustomer })
    }
  }, [chosenCustomer])

  const handleSaveCustomer = () => {
    api.editCustomer(customer, publicId).then(() => {
      successToastNotification(`${chosenCustomer?.name} was successfully edited`)
      navigate(`/customers/${publicId}/view`)
    })
  }

  return (
    <>
      <IconButton
        onClick={() => navigate(`/customers/${publicId}/view`)}
        color='primary'
        sx={{ backgroundColor: 'var(--secondary1-color)' }}
      >
        {isMobile ? <ArrowBackIosNewIcon /> : <ArrowBackIcon />}
      </IconButton>

      <ContentHeader
        title='Profile'
        component={
          !isMobile ? (
            <Box>
              <Button
                variant='outlined'
                onClick={() => navigate(`/customers/${publicId}/view`)}
                sx={{ mr: 1 }}
              >
                Cancel
              </Button>
              <Button variant='contained' onClick={handleSaveCustomer}>
                Save
              </Button>
            </Box>
          ) : undefined
        }
      />

      <Card>
        <CardContent>
          <Box component='form'>
            <Accordion
              sx={{ marginBottom: 2 }}
              expanded={accordionState['account-info']}
              onChange={handleAccordionChange('account-info')}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Account information</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {customer && <AccountInfoForm customer={customer} setCustomer={setCustomer} />}
              </AccordionDetails>
            </Accordion>

            <Accordion
              sx={{ marginBottom: 2 }}
              expanded={accordionState['billing-address']}
              onChange={handleAccordionChange('billing-address')}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Billing address</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {customer && <BillingAddressForm customer={customer} setCustomer={setCustomer} />}
              </AccordionDetails>
            </Accordion>

            <Accordion
              sx={{ marginBottom: 2 }}
              expanded={accordionState['shipping-address']}
              onChange={handleAccordionChange('shipping-address')}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Shipping address</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {customer && <ShippingAddressForm customer={customer} setCustomer={setCustomer} />}
              </AccordionDetails>
            </Accordion>

            {company?.merchantStatus === 'Onboarded' && (
              <Accordion
                expanded={accordionState['credit-cards']}
                onChange={handleAccordionChange('credit-cards')}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Credit Cards</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: { xs: 1, sm: 2 } }}>
                  {customer && <CreditCardsForm customer={customer} />}
                </AccordionDetails>
              </Accordion>
            )}
          </Box>
        </CardContent>
      </Card>

      {isMobile ? (
        <Box mt={2}>
          <Button variant='contained' onClick={handleSaveCustomer} fullWidth sx={{ mb: 1 }}>
            Save
          </Button>
          <Button
            variant='outlined'
            onClick={() => navigate(`/customers/${publicId}/view`)}
            fullWidth
          >
            Cancel
          </Button>
        </Box>
      ) : undefined}
    </>
  )
}

export default CustomerManage
