import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import FileIcon from '@mui/icons-material/ViewListOutlined'
import { ReactElement, useState } from 'react'
import './import-dialog.css'
import { isUsingMobile } from '../../helpers/utils'

interface IImportDialog {
  isOpen: boolean
  dialogTitle?: string
  dialogBodyContent?: string
  buttonConfirmText?: string
  buttonCancelText?: string
  handleClose: () => void
  setAssistanceSuccess: (value: boolean) => void
  csvData: string
  expectedHeader: string
  processImportData: any
  confirmDialog: ReactElement
  setIsFormatCorrect: any
}

export default function ImportDialog(props: IImportDialog) {
  const {
    isOpen,
    dialogTitle,
    dialogBodyContent,
    handleClose,
    csvData,
    expectedHeader,
    processImportData,
    confirmDialog,
    setIsFormatCorrect,
  } = props

  const [uploadedFile, setUploadedFile] = useState<File[]>([])

  const formatFileSize = (sizeInBytes: number) => {
    const fileSizeInKB = sizeInBytes / 1024
    return fileSizeInKB.toFixed(2) + ' KB'
  }

  const handleClickDownload = () => {
    const blob = new Blob([csvData], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.href = url
    a.download = 'example.csv'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }

  const isMobile = isUsingMobile()

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files && files.length > 0) {
      const newFile = files[0]
      setUploadedFile([newFile])
      setIsFormatCorrect(null)
    }
  }

  const removeFile = () => {
    setUploadedFile([])
    const fileInput = document.getElementById('file-input') as HTMLInputElement
    if (fileInput) {
      fileInput.value = ''
    }
  }

  const handleConfirmClick = () => {
    if (uploadedFile.length > 0) {
      const reader = new FileReader()

      reader.onload = (e) => {
        try {
          const content = e.target?.result as string

          if (content) {
            const rows = content.split('\n').map((row) => row.trim())

            if (rows.length > 0) {
              const header = rows[0]

              if (header === expectedHeader) {
                setIsFormatCorrect(true)
                processImportData(rows.slice(1))
              } else {
                setIsFormatCorrect(false)
              }
            }
          }
        } catch (error) {
          console.error('Error reading file:', error)
        }
      }

      reader.readAsText(uploadedFile[0])
    }
  }
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    // Puedes cambiar el estilo del contenedor cuando se arrastra sobre él
    event.currentTarget.style.border = '2px dashed #008000'
  }

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    removeFile()
    event.currentTarget.style.border = '2px dashed #0039FF'
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    removeFile()
    event.currentTarget.style.border = '2px dashed #0039FF'

    const files = event.dataTransfer.files
    if (files.length > 0) {
      const newFiles: File[] = Array.from(files)
      setUploadedFile((prevFiles) => [...prevFiles, ...newFiles])
    }
  }

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose} aria-labelledby='responsive-dialog-title'>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            padding: !isMobile ? '30px 80px' : '30px 10px',
          }}
        >
          <DialogTitle id='responsive-dialog-title'>{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div style={{ textAlign: 'center' }}>{dialogBodyContent}</div>
            </DialogContentText>
          </DialogContent>

          <div
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            style={{
              marginTop: '20px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              border: '2px dashed #0039FF',
              borderRadius: '8px',
              background: '#E5EBFF',
              padding: '20px',
            }}
          >
            <div style={{ color: '#4D5E80', fontWeight: 600, marginBottom: '20px' }}>Drop .csv</div>
            <input
              accept='.csv'
              style={{ display: 'none' }}
              id='file-input'
              type='file'
              onChange={handleFileChange}
            />
            <label htmlFor='file-input'>
              <Button
                variant='contained'
                component='span'
                startIcon={<UploadOutlinedIcon />}
                fullWidth
              >
                Choose File
              </Button>
            </label>
            <div style={{ display: 'flex', color: '#0039FF', marginTop: '20px' }}>
              <DownloadOutlinedIcon />
              <div
                onClick={handleClickDownload}
                style={{ color: '#0039FF', fontWeight: 600, cursor: 'pointer' }}
              >
                Download sample file
              </div>
            </div>
          </div>
          {uploadedFile.map((file, index) => {
            if (!file.name.toLowerCase().endsWith('.csv')) {
              return (
                <div
                  key={index}
                  style={{
                    position: 'relative',
                    marginLeft: '10px',
                    marginRight: '5px',
                    marginTop: '10px',
                    padding: '5px',
                    color: 'red',
                  }}
                >
                  File format not allowed. Please upload a .csv file.
                </div>
              )
            }

            return (
              <div
                key={index}
                style={{
                  display: 'flex',
                  background: '#fff',
                  border: '1px solid #DFE3EB',
                  borderRadius: '8px',
                  marginTop: '10px',
                  padding: '5px',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '15px',
                  }}
                >
                  <FileIcon />
                </div>
                <div style={{ marginRight: '15px' }}>{file.name}</div>
                <div style={{ marginRight: '15px' }}>{formatFileSize(file.size)}</div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'red',
                    cursor: 'pointer',
                    marginLeft: 'auto',
                  }}
                  onClick={() => removeFile()}
                >
                  <DeleteOutlineIcon style={{ marginRight: '5px' }} />
                </div>
              </div>
            )
          })}

          <div
            style={{
              marginTop: '30px',
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <div>
              <Button
                sx={{ ':hover': { borderWidth: '2px' } }}
                color='primary'
                variant='contained'
                onClick={handleConfirmClick}
                fullWidth
              >
                Continue
              </Button>
            </div>
          </div>
          {confirmDialog}
        </div>
      </Dialog>
    </div>
  )
}
