import { useEffect } from 'react';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from 'react-router-dom';
import SignIn from './pages/sign-in';
import { makeStyles } from '@mui/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import Amplify from 'aws-amplify';
import { saveAccessToken, saveUser } from './redux/actions/auth.actions';
import { useDispatch } from 'react-redux';
import awsconfig from './aws-exports';
import SignUp from './pages/sign-up';
import PayNow from './pages/pay-now';
import Home from './pages/home';
import jwt from 'jwt-decode';
import './App.css';
import { Container } from '@mui/material';

Amplify.configure(awsconfig);
const mainCSSVariables = getComputedStyle(document.documentElement);

const theme = createTheme({
	palette: {
		primary: {
			main: mainCSSVariables.getPropertyValue('--primary1-color').trim(),
			contrastText: '#fff',
		},
		secondary: {
			main: mainCSSVariables.getPropertyValue('--secondary1-color').trim(),
			contrastText: '#000',
		},
		success: {
			main: mainCSSVariables.getPropertyValue('--success-color').trim(),
			contrastText: '#4D5E80',
		},
	},
	typography: {
		fontFamily: 'var(--font-family)',
	},
});

const useStyles = makeStyles({
	wrapper: {
		height: '100vh',
		[theme.breakpoints.up('md')]: {
			justifyContent: 'center',
		},
		backgroundColor: 'var(--background-color)',
	},
});

const App = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	useEffect(() => {
		if (sessionStorage.getItem('token')) {
			const token = sessionStorage.getItem('token');
			dispatch(saveAccessToken(token));
			const decoded = jwt(token || '');
			dispatch(saveUser(decoded));
		}
	}, []);
	return (
		<Router>
			<ThemeProvider theme={theme}>
				<div className={classes.wrapper}>
					<Container
						sx={{
							paddingBottom: { xs: 1},
							backgroundColor: 'var(--background-color)',
						}}
					>
						<Routes>
							<Route path="/payment/:invoiceId" element={<PayNow />} />
							<Route path="/sign-in" element={<SignIn />} />
							<Route path="/sign-up" element={<SignUp />} />
							<Route path="/*" element={<Home />} />
							<Route path="/" element={<Navigate to="/sign-in" />} />
						</Routes>
						<ToastContainer />
					</Container>
				</div>
			</ThemeProvider>
		</Router>
	);
};

export default App;
