import { Button, Grid, Step, StepContent, StepLabel, Typography } from '@mui/material'
import { IScheduledInvoice } from '../../../types/types'
import { isUsingMobile } from '../../../helpers/utils'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { formatDate } from '../../../helpers/formatPrice'
import { formatTime } from '../../../helpers/formatDate'
import { paymentDueList } from '../helper'
import useAuthorization from '../../../helpers/useAuthorization'

interface CreationStepProps {
  scheduledInvoice: IScheduledInvoice
  onEditInvoiceClick?: () => void
}

const CreationStep = ({ scheduledInvoice, onEditInvoiceClick }: CreationStepProps) => {
  const isMobile = isUsingMobile()
  const { isAuthorized } = useAuthorization()

  return (
    <Step key='creation' expanded={true}>
      <StepLabel icon={<CheckCircleIcon color='success' />}>Invoice creation</StepLabel>
      <StepContent>
        <Grid container spacing={!isMobile ? 1 : undefined}>
          <Grid item sm={9}>
            <Typography>
              <Typography component='span' fontWeight='bold'>
                Invoice created:
              </Typography>{' '}
              on {formatDate(scheduledInvoice.createdAt || '')} at{' '}
              {formatTime(scheduledInvoice.createdAt)}
            </Typography>
            <Typography>
              <Typography component='span' fontWeight='bold'>
                Payment term:
              </Typography>{' '}
              {paymentDueList.get(String(scheduledInvoice?.paymentDue))}
            </Typography>
          </Grid>
          <Grid item sm={3}>
            {isAuthorized('write_invoice') && (
              <Button
                sx={{ mt: { xs: 2, sm: 0 } }}
                variant='outlined'
                onClick={onEditInvoiceClick}
                fullWidth
                disabled={scheduledInvoice.status === 'ENDED'}
              >
                Edit invoice
              </Button>
            )}
          </Grid>
        </Grid>
      </StepContent>
    </Step>
  )
}

export default CreationStep
