import { Box, Button, Grid, Tooltip } from '@mui/material'
import { useMemo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import api from '../../api/api'
import ContentHeader from '../../components/content-header'
import ConfirmDialog from '../../components/dialog/dialog'
import BaseModal from '../../components/modal/modal'
import NoDataDisplay from '../../components/noDataDisplay/noDataDisplay'
import PlusButton from '../../components/plus-button/plus-button'
import { errorHandler } from '../../helpers/errorHandler'
import { isUsingMobile } from '../../helpers/utils'
import { createNewPurchaseProduct, savePurchaseProducts } from '../../redux/actions/home.actions'
import { AppState } from '../../redux/store'
import ProductTable from './productTable'
import ProductView from './productView'
import useAuthorization from '../../helpers/useAuthorization'
import { INewPurchaseProduct, IPurchaseProduct } from '../../types/types'
import { successToastNotification } from '../../helpers/toastNotification'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

const PurchaseProducts = () => {
  const dispatch = useDispatch()
  const { isAuthorized } = useAuthorization()
  const products = useSelector((state: AppState) => state.homeReducer.purchaseProducts)
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState<boolean>(false)
  const [isProductManageDialogOpen, setIsProductManageDialogOpen] = useState<boolean>(false)
  const [selectedProduct, setSelectedProduct] = useState<IPurchaseProduct | null>(null)
  const [currentProduct, setCurrentProduct] = useState<INewPurchaseProduct>({
    name: '',
    price: 0,
    description: '',
  })

  const [formErrors, setFormErrors] = useState({
    name: '',
    price: '',
    accountPublicId: '',
  })

  const isDisabledSaveButton = useMemo(() => {
    return (
      Object.values(formErrors).some((error) => error !== '') ||
      !currentProduct.name ||
      !currentProduct.price ||
      !currentProduct.accountPublicId
    )
  }, [formErrors, currentProduct.name, currentProduct.price, currentProduct.accountPublicId])

  const resetErrors = () => {
    setFormErrors({ name: '', price: '', accountPublicId: '' })
  }

  const handleCancel = () => {
    setSelectedProduct(null)
    setIsProductManageDialogOpen(false)
    resetErrors()
  }

  const handleCreateProductClick = () => {
    setIsProductManageDialogOpen(true)
  }

  useEffect(() => {
    dispatch(savePurchaseProducts())
  }, [])

  const handleDeleteProduct = () => {
    setIsOpenDeleteDialog(false)
    api
      .deletePurchaseProduct(selectedProduct?.publicId)
      .then(() => {
        successToastNotification(`${selectedProduct?.name} was successfully deleted`)
        dispatch(savePurchaseProducts())
      })
      .catch((err) => errorHandler(err))
    setSelectedProduct(null)
  }

  const handleAcceptClick = () => {
    if (selectedProduct) {
      api
        .editPurchaseProduct(
          { ...currentProduct, price: currentProduct.price },
          selectedProduct.publicId,
        )
        .then(() => {
          successToastNotification(`${selectedProduct.name} was successfully edited`)
          dispatch(savePurchaseProducts())
        })
        .catch((err) => errorHandler(err))
    } else {
      dispatch(
        createNewPurchaseProduct({
          ...currentProduct,
          price: currentProduct.price,
        }),
      )
    }
    setIsProductManageDialogOpen(false)
  }

  const handleCloseDialog = () => {
    setSelectedProduct(null)
    setIsOpenDeleteDialog(false)
  }

  const Footer = () => {
    return (
      <Grid container>
        <Grid
          container
          justifyContent={{ xs: 'normal', md: 'right' }}
          alignItems={{ xs: 'normal', md: 'center' }}
          flexDirection={{ xs: 'column-reverse', md: 'row' }}
        >
          <Grid item padding={{ xs: '1rem', md: '0rem' }}>
            <Button color='primary' variant='outlined' onClick={handleCancel} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item padding={{ xs: '1rem 1rem 0 1rem', md: '1rem' }}>
            <Button
              disabled={isDisabledSaveButton}
              color='primary'
              variant='contained'
              onClick={handleAcceptClick}
              fullWidth
            >
              {selectedProduct ? 'Edit product' : 'Add new product'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      {products && products.length > 0 ? (
        <>
          <ContentHeader
            title={
              <Box>
                Products &amp; Services{' '}
                <Tooltip
                  sx={{ verticalAlign: 'middle' }}
                  title='Products and services that you buy from vendors, this are used as items on Bills to record those purchases'
                >
                  <HelpOutlineIcon />
                </Tooltip>
              </Box>
            }
          />
          <ProductTable
            products={products}
            setIsOpenDeleteDialog={setIsOpenDeleteDialog}
            setIsProductManageDialogOpen={setIsProductManageDialogOpen}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
          />
        </>
      ) : (
        <>
          <NoDataDisplay
            mt='10rem'
            title='No data to display'
            description='Add products and services that you buy from vendors, these are used as items on Bills to record the purchases.'
          />
        </>
      )}
      <ConfirmDialog
        isOpen={isOpenDeleteDialog}
        dialogTitle='Sure you want to delete?'
        dialogBodyContent='Deleting this item will permanently remove it from your account, and it cannot be undone.'
        buttonConfirmText='Yes, Delete'
        buttonCancelText='Cancel'
        handleClose={handleCloseDialog}
        handleConfirm={handleDeleteProduct}
      />
      <BaseModal
        size={isUsingMobile() ? 'xs' : 'small'}
        title={selectedProduct ? 'Edit product or service' : 'Add a product or service'}
        body={
          <ProductView
            currentProduct={currentProduct}
            selectedProduct={selectedProduct}
            setCurrentProduct={setCurrentProduct}
            setFormErrors={setFormErrors}
            formErrors={formErrors}
          />
        }
        isModalOpen={isProductManageDialogOpen}
        handleCloseModal={handleCancel}
        footer={<Footer />}
      />
      {isAuthorized('write_purchase_product') && (
        <PlusButton
          tooltipText='Add a Product or Service'
          handleOnClick={handleCreateProductClick}
        />
      )}
    </>
  )
}

export default PurchaseProducts
