import { TextField as  TextFieldMui} from '@mui/material';
import { NumericFormat, PatternFormat } from 'react-number-format';
import dollarIcon from '../../assets/icons/dollar.svg';
import LabelTextField from '../label-text-field';
import TextField from '../text-field/text-field';

import './amount-field.css';

const TextFieldAmount = (props: any) => {
	return <TextField {...props} />;
};

const LabeledAmountField = (props: any) => {
	return (
		<LabelTextField
			{...props}
			withIcon
			icon={dollarIcon}
			iconPosition="start"
			iconStyles="amount-field-label__icon"
		/>
	);
};

export const AmountField = (props: any) => {
	return (
		<NumericFormat
			customInput={TextFieldAmount}
			thousandSeparator=","
			allowNegative={false}
			decimalScale={2}
			{...props}
		/>
	);
};

export const LabelAmountField = (props: any) => {
	return (
		<NumericFormat
			customInput={LabeledAmountField}
			thousandSeparator=","
			allowNegative={false}
			decimalScale={2}
			{...props}
		/>
	);
};

export const LabelPhoneField = (props: any) => {
	return (
		<PatternFormat
			customInput={TextFieldMui}
			format="###-###-####"
			mask="_"
			placeholder="000-000-0000"
			{...props}
		/>
	);
};

export const IDField = (props: any) => {
	return (
		<PatternFormat
			customInput={TextFieldMui}
			format="###-##-####"
			mask="_"
			{...props}
		/>
	);
};

export const PhoneField = (props: any) => {
	return (
		<NumericFormat
			customInput={LabelTextField}
			format="### ### ####"
			mask="_"
			placeholder="000-000-0000"
			{...props}
		/>
	);
};
