import { Box, Card, CardContent, Grid, Link, Typography } from '@mui/material'
import logo from '../../assets/images/logo-bc.png'
import check from '../../assets/icons/payment-done.png'
import { isUsingMobile } from '../../helpers/utils'

interface IPayNowInfo {
  title: string
  message: string
}

const PayNowInfo = (props: IPayNowInfo) => {
  const isMobile = isUsingMobile()

  const { title, message } = props
  return (
    <Box
      sx={{
        width: { xs: '90%', sm: '60%' },
        margin: { xs: '5% auto 0 auto', sm: '10% auto 0 auto' },
      }}
    >
      <Card>
        <CardContent>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              p: { xs: '1rem', sm: '3rem' },
            }}
          >
            <img src={check} width={93} alt='logo blue cimarron' />
            <Typography
              sx={{
                color: '#27AE60',
                fontWeight: 600,
                fontSize: { xs: 18, sm: 24 },
                textAlign: { xs: 'center', sm: 'left' },
                mb: 1,
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                color: 'var(--secondary-grey)',
                textAlign: { xs: 'center', sm: 'left' },
                mb: '4rem',
              }}
            >
              {message}
            </Typography>
            <Box sx={{ width: '100%', backgroundColor: 'var(--secondary1-color)' }}>
              <Grid container flexDirection={{ xs: 'column', sm: 'row' }}>
                <Grid item flexGrow={1}>
                  <img
                    src={logo}
                    width={200}
                    style={{ margin: isMobile ? '0 auto' : undefined, display: 'block' }}
                  />
                </Grid>
                <Grid item flexGrow={1} px={2} py={3}>
                  <Typography
                    sx={{
                      color: 'var(--primary1-color)',
                      fontSize: 14,
                      fontWeight: 600,
                      textAlign: { xs: 'center', sm: 'right' },
                      lineHeight: { xs: 1.5, sm: 2 },
                    }}
                  >
                    Invoicing to Insight: Your Financial Journey Simplified
                  </Typography>
                  <Typography textAlign={{ xs: 'center', sm: 'right' }}>
                    <Link
                      target='_blank'
                      href='https://www.bluecimarron.com'
                      sx={{ textDecoration: 'none' }}
                    >
                      www.bluecimarron.com
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

export default PayNowInfo
