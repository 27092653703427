import { Box, IconButton } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { MouseEvent } from 'react'
import { isUsingMobile } from '../../helpers/utils'

interface SidemenuSwitchProps {
  width: number | string
  onClick: (event: MouseEvent<HTMLElement>) => void
  isOpen: boolean
}

const SidemenuSwitch = ({ width, onClick, isOpen }: SidemenuSwitchProps) => {
  const isMobile = isUsingMobile()

  return (
    <Box
      className='sidemenuSwitch'
      sx={{
        alignItems: 'stretch',
        display: 'none',
        width,
        position: 'absolute',
        top: isMobile ? '52px' : '60px',
        right: isMobile ? (isOpen ? '-18px' : '-9px') : isOpen ? '-40px' : '-17px',
        height: '100px',
        zIndex: 9999,
      }}
    >
      <IconButton
        onClick={onClick}
        size='small'
        sx={{
          p: 0,
          color: 'var(--primary1-color)',
          backgroundColor: '#fff',
          borderRadius: '0 8px 8px 0',
          '&.MuiIconButton-root:hover': {
            backgroundColor: '#fff',
          },
        }}
      >
        {isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </IconButton>
    </Box>
  )
}

export default SidemenuSwitch
