import { useSelector } from 'react-redux'
import { AppState } from '../../redux/store'
import { MouseEvent, useCallback, useMemo, useState } from 'react'
import { isUsingMobile } from '../../helpers/utils'
import useAuthorization from '../../helpers/useAuthorization'
import { DataGrid, GridColDef, GridRowSpacingParams } from '@mui/x-data-grid'
import { IJournalEntry } from '../../types/types'

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Button, Chip, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { formatDate, formatMoney } from '../../helpers/formatPrice'
import TableStyles from '../../components/table-card/table-styles'

interface JournalEntriesTableProps {
  selectedJournalEntry?: IJournalEntry | null
  setSelectedJournalEntry: (entry: IJournalEntry) => void
  onRowClick: (entry: IJournalEntry) => void
  onDeleteClick: () => void
  onEditClick: () => void
  onDuplicateClick: () => void
  onMarkAsReviewedClick: (entry?: IJournalEntry) => void
}

const JournalEntriesTable = ({
  selectedJournalEntry,
  setSelectedJournalEntry,
  onRowClick,
  onEditClick,
  onDeleteClick,
  onDuplicateClick,
  onMarkAsReviewedClick,
}: JournalEntriesTableProps) => {
  const journalEntries = useSelector((state: AppState) => state.homeReducer.journalEntries)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openItemMenu = Boolean(anchorEl)
  const isMobile = isUsingMobile()
  const { isAuthorized } = useAuthorization()

  const handleMenuButtonClick = useCallback(
    (entry: IJournalEntry) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      setSelectedJournalEntry(entry)
      setAnchorEl(event.currentTarget)
    },
    [setSelectedJournalEntry],
  )

  const handleMarkAsReviewedClick =
    (journalEntry?: IJournalEntry) => (event: MouseEvent<HTMLElement>) => {
      setAnchorEl(null)

      if (journalEntry) {
        onMarkAsReviewedClick(journalEntry)
      } else if (selectedJournalEntry) {
        onMarkAsReviewedClick(selectedJournalEntry)
      }

      event.stopPropagation()
    }

  const columns = useMemo<GridColDef<IJournalEntry>[]>(
    () => [
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        sortable: false,
        renderCell: (param) => {
          return isMobile ? (
            <FiberManualRecordIcon
              sx={{
                '&.MuiSvgIcon-root.MuiSvgIcon-colorSuccess': {
                  color: 'var(--success-color)',
                  borderRadius: '50%',
                  backgroundColor: 'var(--success-bg-color)',
                },
                '&.MuiSvgIcon-root.MuiSvgIcon-colorError': {
                  color: 'var(--error-color)',
                  borderRadius: '50%',
                  backgroundColor: 'var(--error-bg-color)',
                },
              }}
              color={param.row.reviewed ? 'success' : 'error'}
            />
          ) : (
            <Chip
              sx={{
                '&.MuiChip-colorSuccess': {
                  backgroundColor: 'var(--success-bg-color)',
                  color: 'var(--success-color)',
                },
                '&.MuiChip-colorWarning': {
                  backgroundColor: 'var(--warning-bg-color)',
                  color: 'var(--warning-color)',
                },
                '&.MuiChip-colorError': {
                  backgroundColor: 'var(--error-bg-color)',
                  color: 'var(--error-color)',
                },
                '&.MuiChip-colorInfo': {
                  backgroundColor: 'var(--info-bg-color)',
                  color: 'var(--info-color)',
                },
                '&.MuiChip-colorSecondary': {
                  backgroundColor: 'var(--orange-bg-color)',
                  color: 'var(--orange-color)',
                },
              }}
              color={param.row.reviewed ? 'success' : 'error'}
              label={param.row.reviewed ? 'Reviewed' : 'Not Reviewed'}
            />
          )
        },
      },
      {
        field: 'date',
        headerName: 'Date',
        flex: 1,
        sortable: false,
        renderCell: (param) => {
          return <Typography>{formatDate(param.row.date)}</Typography>
        },
      },
      {
        field: 'descripition',
        headerName: 'Description',
        flex: 3,
        sortable: false,
        renderCell: (param) => {
          return <Typography>{param.row.description}</Typography>
        },
      },
      {
        field: 'amount',
        headerName: 'Amount',
        flex: isMobile ? 1 : 2,
        sortable: false,
        renderCell: (param) => {
          return <Typography>{formatMoney(param.row.amount)}</Typography>
        },
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1.5,
        sortable: false,
        renderCell: (param) => {
          return param.row.reviewed ? (
            <Button variant='outlined' color='error' onClick={handleMarkAsReviewedClick(param.row)} sx={{ width: '130px' }}>
              Undo Review
            </Button>
          ) : (
            <Button variant='outlined' onClick={handleMarkAsReviewedClick(param.row)} sx={{ width: '130px' }}>
              Mark as Reviewed
            </Button>
          )
        },
      },
      {
        field: 'moreActions',
        headerName: '',
        flex: 1,
        sortable: false,
        renderCell: (param) => (
          <IconButton
            aria-label='more'
            id='long-button'
            aria-controls={openItemMenu ? 'long-menu' : undefined}
            aria-expanded={openItemMenu ? 'true' : undefined}
            aria-haspopup='true'
            onClick={handleMenuButtonClick(param.row)}
            sx={{
              justifyItems: 'center',
            }}
          >
            <MoreVertIcon />
          </IconButton>
        ),
      },
    ],
    [isMobile, openItemMenu, handleMenuButtonClick],
  )

  const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: 5,
    }
  }, [])

  const handleDuplicateClick = () => {
    setAnchorEl(null)
    onDuplicateClick()
  }

  const handleDeleteClick = () => {
    setAnchorEl(null)
    onDeleteClick()
  }

  return (
    <>
      <DataGrid
        rows={journalEntries}
        columns={columns}
        disableColumnMenu
        getRowId={(row) => row.publicId || ''}
        onRowClick={(params) => onRowClick(params.row)}
        hideFooter
        getRowSpacing={getRowSpacing}
        rowHeight={65}
        initialState={{
          columns: {
            columnVisibilityModel: {
              account: !isMobile,
              date: !isMobile,
              dueDate: !isMobile,
              actions: !isMobile && isAuthorized('write_bill'),
              moreActions: isAuthorized('write_bill'),
            },
          },
        }}
        sx={TableStyles}
      />
      <Menu
        id='actions-menu'
        anchorEl={anchorEl}
        open={openItemMenu}
        onClose={() => setAnchorEl(null)}
        sx={{
          '& .MuiMenuItem-root': {
            padding: { xs: '1rem', md: '1rem 4rem 1rem 2rem' },
          },
          '& .MuiMenuItem-root:hover': {
            backgroundColor: 'var(--secondary1-color)',
            color: 'var(--primary1-color)',
          },
        }}
      >
        <MenuItem onClick={onEditClick}>Edit</MenuItem>
        <MenuItem onClick={handleDuplicateClick}>Duplicate</MenuItem>
        {isMobile && <MenuItem onClick={handleMarkAsReviewedClick()}>Mark as Reviewed</MenuItem>}
        <MenuItem sx={{ color: 'var(--error-color)' }} onClick={handleDeleteClick}>
          Delete
        </MenuItem>
      </Menu>
    </>
  )
}

export default JournalEntriesTable
