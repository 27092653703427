import { useDispatch, useSelector } from 'react-redux'
import ContentHeader from '../../components/content-header'
import { AppState } from '../../redux/store'
import { useNavigate, useParams } from 'react-router-dom'
import { ReactInstance, SyntheticEvent, useEffect, useRef, useState } from 'react'
import {
  changeIsLoading,
  saveCertainCustomer,
  saveInvoices,
} from '../../redux/actions/home.actions'
import { Button, IconButton, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { IInvoice, INewPayment } from '../../types/types'
import { errorHandler, generalErrorHandler } from '../../helpers/errorHandler'
import api from '../../api/api'
import { toast } from 'react-toastify'
import { useReactToPrint } from 'react-to-print'
import InvoiceModal from '../invoices/modal/invoiceModal'
import PaymentModal from '../invoices/record-payment/paymentModal'
import { downloadPDF } from '../../helpers/downloadPdf'
import moment from 'moment'
import { AxiosResponse } from 'axios'
import CustomerActivity from './customerActivity'
import ConfirmDialog from '../../components/dialog/dialog'
import CustomerProfile from './profile/customerProfile'
import { isUsingMobile } from '../../helpers/utils'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { successToastNotification } from '../../helpers/toastNotification'
import useAuthorization from '../../helpers/useAuthorization'

const CustomerView = () => {
  const { publicId } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isAuthorized } = useAuthorization()
  const [invoicesPage, setInvoicesPage] = useState(1)
  const [selectedTab, setSelectedTab] = useState('activity')
  const [selectedInvoice, setSelectedInvoice] = useState<IInvoice>()
  const [showInvoiceModal, setShowInvoiceModal] = useState(false)
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [isEnrolAlertOpen, setIsEnrolAlertOpen] = useState(false)
  const [isDeleteInvoiceDialogOpen, setIsDeleteInvoiceDialogOpen] = useState(false)
  const preview = useRef<ReactInstance | null>(null)
  const [selectedFilter, setSelectedFilter] = useState('')
  const isMobile = isUsingMobile()

  const company = useSelector((state: AppState) => state.homeReducer.company)
  const chosenCustomer = useSelector((state: AppState) => state.homeReducer.chosenCustomer)
  const invoices = useSelector((state: AppState) => state.homeReducer.invoices)
  const invoicesSize = useSelector((state: AppState) => state.homeReducer.invoicesSize)
  const merchantKey = useSelector((state: AppState) => state.authReducer.usioApiKey)

  useEffect(() => {
    dispatch(saveCertainCustomer(publicId))
    dispatch(saveInvoices(0, selectedFilter, '', '', '', '', '', '', publicId))
  }, [publicId])

  const handleViewInvoiceClick = (invoice: IInvoice) => {
    setShowInvoiceModal(true)
    setSelectedInvoice(invoice)
  }

  const handleDuplicateInvoiceClick = () => {
    if (!selectedInvoice) {
      return
    }

    api
      .duplicateInvoice(selectedInvoice.publicId)
      .then(() => {
        dispatch(saveInvoices(0, selectedFilter, '', '', '', '', '', '', publicId))
        successToastNotification('Invoice was successfully duplicated')
      })
      .catch((err) => errorHandler(err))
  }

  const handleEditInvoiceClick = () => {
    selectedInvoice && navigate(`/invoices/add/${selectedInvoice.publicId}/edit`)
  }

  const handleRecordPaymentClick = () => {
    setShowPaymentModal(true)
  }

  const handleSendInvoiceClick = () => {
    if (!selectedInvoice || !company) {
      return
    }

    if (company.merchantStatus !== 'Onboarded') {
      setIsEnrolAlertOpen(true)
    } else {
      api
        .sendInvoice(selectedInvoice.publicId, {
          email: selectedInvoice.customerEmail,
        })
        .then(() => {
          dispatch(saveInvoices(0, selectedFilter, '', '', '', '', '', '', publicId))
          successToastNotification(
            `Invoice was successfully sent to ${selectedInvoice.customerEmail}`,
          )
        })
        .catch((err) => errorHandler(err))
    }
  }

  const printInvoice = useReactToPrint({
    content: () => preview?.current,
    onBeforePrint: () => {
      dispatch(changeIsLoading(true))
    },
    onAfterPrint: () => {
      dispatch(changeIsLoading(false))
    },
  })

  const handlePrintClick = () => {
    printInvoice()
  }

  const handleShareLinkClick = () => {
    if (!selectedInvoice) {
      return
    }

    navigator.clipboard.writeText(
      `${
        window.location.host.includes('localhost')
          ? 'http://localhost:3000'
          : 'https://app.bluecimarron.com'
      }/payment/${selectedInvoice.publicId}`,
    )

    successToastNotification(
      `Pay now link for invoice #${selectedInvoice.name} was copied to the clipboard`,
    )
  }

  const handleExportAsPDFClick = () => {
    downloadPDF(preview, 'invoice')
  }

  const handleDeleteInvoiceClick = () => {
    setIsDeleteInvoiceDialogOpen(true)
  }

  const handlePaymentConfirm = (payment: INewPayment) => () => {
    if (!selectedInvoice) {
      return
    }

    if (payment.processing === 'MANUAL') {
      createManualPayment(payment, selectedInvoice)
    } else {
      if (payment.card) {
        createNewCardPayment(payment, selectedInvoice)
      } else {
        createStoredCardPayment(payment, selectedInvoice)
      }
    }
  }

  const createManualPayment = (payment: INewPayment, selectedInvoice: IInvoice) => {
    api
      .recordPayment(selectedInvoice.publicId, {
        date: moment().format('YYYY-MM-DD HH:mm'),
        amount: payment.amount,
        method: payment.method,
        memo: '',
      })
      .then((res: AxiosResponse) => {
        if (res.data.body.status === 'FAILED') {
          generalErrorHandler(res.data.body.message)
        } else {
          setSelectedFilter('')
          dispatch(saveInvoices(0, selectedFilter, '', '', '', '', '', '', publicId))
          setShowPaymentModal(false)
          setSelectedInvoice(undefined)
        }
      })
      .catch((err) => errorHandler(err))
  }

  const createNewCardPayment = (payment: INewPayment, selectedInvoice: IInvoice) => {
    const expDate = `${payment.card?.expirationDate.slice(
      0,
      2,
    )}20${payment.card?.expirationDate.slice(3)}`

    api
      .generateUSIOToken({
        merchantKey,
        paymentType: 'CreditCard',
        emailAddress: selectedInvoice.customerEmail || '',
        cardNumber: payment.card?.number.split(' ').join(''),
        expDate: expDate,
        cvv: payment.card?.cvv,
      })
      .then((res) => {
        if (res.data.status === 'FAILED') {
          toast(res.data.message, {
            autoClose: 3000,
            hideProgressBar: true,
            className: 'error-toast',
          })
        } else {
          api
            .makePayment({
              customerPublicId: payment.customerPublicId,
              amount: payment.amount,
              invoicePublicId: selectedInvoice.publicId,
              paymentType: 'CREDIT_CARD',
              usioToken: res.data.token,
              cardType: res.data.cardBrand,
              lastDigits: res.data.last4,
              expirationDate: res.data.expDate,
              save: payment.card?.saveCard,
              cardName: payment.card?.name,
            })
            .then((res: any) => {
              if (res.data.body.status === 'FAILED') {
                toast(res.data.body.message, {
                  autoClose: 3000,
                  hideProgressBar: true,
                  className: 'error-toast',
                })
              } else {
                setSelectedFilter('')
                dispatch(saveInvoices(0, selectedFilter, '', '', '', '', '', '', publicId))
                setShowPaymentModal(false)
                setSelectedInvoice(undefined)
              }
            })
            .catch((err) => errorHandler(err))
        }
      })
  }

  const createStoredCardPayment = (payment: INewPayment, selectedInvoice: IInvoice) => {
    api
      .makeTokenizedPayment({
        customerPublicId: payment.customerPublicId,
        amount: payment.amount,
        invoicePublicId: selectedInvoice.publicId,
        paymentType: 'CREDIT_CARD',
        cardId: payment.cardId,
      })
      .then((res: AxiosResponse) => {
        if (res.data.body.status === 'FAILED') {
          toast(res.data.body.message, {
            autoClose: 3000,
            hideProgressBar: true,
            className: 'error-toast',
          })
        } else {
          setSelectedFilter('')
          dispatch(saveInvoices(0, selectedFilter, '', '', '', '', '', '', publicId))
          setShowPaymentModal(false)
          setSelectedInvoice(undefined)
        }
      })
      .catch((err) => errorHandler(err))
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setInvoicesPage(value)
    dispatch(saveInvoices(value - 1, selectedFilter, '', '', '', '', '', '', publicId))
  }

  const handleSelectedFilterChange = (event: SyntheticEvent, filter: string) => {
    setSelectedFilter(filter)
    dispatch(saveInvoices(invoicesPage - 1, filter, '', '', '', '', '', '', publicId))
  }

  const handleDeleteInvoiceConfirm = () => {
    if (!selectedInvoice) {
      return
    }

    setIsDeleteInvoiceDialogOpen(false)

    api
      .deleteInvoice(selectedInvoice.publicId)
      .then(() => {
        successToastNotification(`${selectedInvoice.name} was successfully deleted`)
        dispatch(saveInvoices())
      })
      .catch((err) => errorHandler(err))

    setSelectedInvoice(undefined)
  }

  return (
    <>
      <IconButton
        onClick={() => navigate('/customers')}
        color='primary'
        sx={{ backgroundColor: 'var(--secondary1-color)' }}
      >
        {isMobile ? <ArrowBackIosNewIcon /> : <ArrowBackIcon />}
      </IconButton>
      <ContentHeader
        title={chosenCustomer?.name}
        component={
          !isMobile && selectedTab === 'profile' && isAuthorized('write_customer') ? (
            <Button
              variant='contained'
              onClick={() => navigate(`/customers/${chosenCustomer?.publicId}/edit`)}
            >
              Edit
            </Button>
          ) : undefined
        }
      />
      <ToggleButtonGroup
        color='primary'
        value={selectedTab}
        exclusive
        onChange={(e, value) => setSelectedTab(value)}
        aria-label='Platform'
        sx={{
          '& .MuiButtonBase-root': {
            backgroundColor: 'var(--secondary1-color)',
            color: 'var(--primary1-color)',
            padding: '6px 16px',
            boxShadow:
              '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
          },
          '& .MuiButtonBase-root.Mui-selected': {
            backgroundColor: 'var(--primary1-color)',
            color: '#fff',
          },
          '& .MuiButtonBase-root.Mui-selected:hover': {
            backgroundColor: 'rgb(58, 28, 178)',
            boxShadow:
              '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
          },
        }}
      >
        <ToggleButton value='activity'>Activity</ToggleButton>
        <ToggleButton value='profile'>Profile</ToggleButton>
      </ToggleButtonGroup>

      {chosenCustomer && (
        <Box mt={2}>
          {selectedTab === 'activity' && (
            <CustomerActivity
              chosenCustomer={chosenCustomer}
              selectedFilter={selectedFilter}
              onSelectedFilterChange={handleSelectedFilterChange}
              invoices={invoices}
              invoicesSize={invoicesSize}
              invoicesPage={invoicesPage}
              selectedInvoice={selectedInvoice}
              setSelectedInvoice={setSelectedInvoice}
              onChangePage={handleChangePage}
              actions={{
                onViewInvoiceClick: handleViewInvoiceClick,
                onDuplicateInvoiceClick: handleDuplicateInvoiceClick,
                onEditInvoiceClick: handleEditInvoiceClick,
                onRecordPaymentClick: handleRecordPaymentClick,
                onSendInvoiceClick: handleSendInvoiceClick,
                onPrintClick: handlePrintClick,
                onExportAsPDFClick: handleExportAsPDFClick,
                onDeleteInvoiceClick: handleDeleteInvoiceClick,
                onShareLinkClick: handleShareLinkClick,
              }}
            />
          )}
          {selectedTab === 'profile' && <CustomerProfile customer={chosenCustomer} />}
        </Box>
      )}

      {isMobile && selectedTab === 'profile' && isAuthorized('write_customer') && (
        <Button
          sx={{ mt: 2 }}
          variant='contained'
          onClick={() => navigate(`/customers/${chosenCustomer?.publicId}/edit`)}
          fullWidth
        >
          Edit
        </Button>
      )}

      {selectedInvoice && (
        <InvoiceModal
          showModal={showInvoiceModal}
          onEditClick={handleEditInvoiceClick}
          setShowModal={setShowInvoiceModal}
          onDuplicateClick={handleDuplicateInvoiceClick}
          onRecordPaymentClick={handleRecordPaymentClick}
          onShareLinkClick={handleShareLinkClick}
          onDeleteClick={handleDeleteInvoiceClick}
          onPrintClick={handlePrintClick}
          onExportAsPDFClick={handleExportAsPDFClick}
          onSendClick={handleSendInvoiceClick}
          invoice={selectedInvoice}
        />
      )}

      {selectedInvoice && (
        <PaymentModal
          showModal={showPaymentModal}
          setShowModal={setShowPaymentModal}
          invoice={selectedInvoice}
          onPaymentConfirm={handlePaymentConfirm}
        />
      )}

      <ConfirmDialog
        isOpen={isEnrolAlertOpen}
        dialogTitle='Invoice can not be sent'
        dialogBodyContent={
          <>
            <Typography>
              You must fill out the USIO form to receive credit card payments.
            </Typography>
            <Typography>
              Please use legal names and the registered business address for instant approval.
            </Typography>
          </>
        }
        buttonConfirmText='Ok'
        confirmColor='var(--primary1-color)'
        handleClose={() => setIsEnrolAlertOpen(false)}
        handleConfirm={() => setIsEnrolAlertOpen(false)}
      />

      <ConfirmDialog
        isOpen={isDeleteInvoiceDialogOpen}
        dialogTitle='Sure you want to delete?'
        dialogBodyContent='Deleting this item will permanently remove it from your account, and it cannot be undone.'
        buttonConfirmText='Yes, Delete'
        buttonCancelText='Cancel'
        handleClose={() => setIsDeleteInvoiceDialogOpen(false)}
        handleConfirm={handleDeleteInvoiceConfirm}
      />
    </>
  )
}

export default CustomerView
