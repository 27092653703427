import {
  Box,
  Button,
  FormControl,
  Grid,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Step,
  StepContent,
  StepLabel,
  Typography,
} from '@mui/material'
import { ICreditCard, ICustomer, INewCreditCard, IScheduledInvoice } from '../../../types/types'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import { isUsingMobile } from '../../../helpers/utils'
import { useEffect, useState } from 'react'
import PaymentMethodSelect from '../../invoices/record-payment/select'
import SelectorMui from '../../../components/selector-mui'
import NewCreditCardForm from '../../invoices/record-payment/newCreditCardForm'
import api from '../../../api/api'
import { errorHandler } from '../../../helpers/errorHandler'
import { toast } from 'react-toastify'
import { AppState } from '../../../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { saveScheduledInvoiceTemplates } from '../../../redux/actions/home.actions'
import useAuthorization from '../../../helpers/useAuthorization'
import USIONotification from '../../home/usioNotification'

interface PaymentStepProps {
  scheduledInvoice: IScheduledInvoice
  customer?: ICustomer | null
  setPaymentMethod?: (paymentMethod: string) => void
}

const PaymentStep = ({ scheduledInvoice, customer, setPaymentMethod }: PaymentStepProps) => {
  const isMobile = isUsingMobile()
  const dispatch = useDispatch()
  const { isAuthorized } = useAuthorization()
  const [isEditMode, setIsEditMode] = useState(false)
  const [isNewCard, setIsNewCard] = useState(false)
  const [newCreditCard, setNewCreditCard] = useState<INewCreditCard | undefined>({
    number: '',
    expirationDate: '',
    cvv: '',
    name: '',
    saveCard: false,
    customerPublicId: customer?.publicId,
  })
  const [formErrors, setFormErrors] = useState<any>({
    cardId: '',
  })
  const merchantKey = useSelector((state: AppState) => state.authReducer.usioApiKey)
  const company = useSelector((state: AppState) => state.homeReducer.company)
  const [selectedCard, setSelectedCard] = useState<string>()

  useEffect(() => {
    if (!scheduledInvoice.paymentMethod) {
      if (company?.merchantStatus === 'Onboarded') {
        setPaymentMethod && setPaymentMethod('auto')
      } else {
        setPaymentMethod && setPaymentMethod('manual')
      }
    }
  }, [])

  const handleEditClick = () => {
    setIsEditMode(true)
  }

  const handleSaveClick = () => {
    if (scheduledInvoice.paymentMethod === 'auto') {
      if (newCreditCard) {
        const expDate = `${newCreditCard.expirationDate.slice(
          0,
          2,
        )}20${newCreditCard.expirationDate.slice(3)}`

        const usioRequest = {
          merchantKey,
          paymentType: 'CreditCard',
          emailAddress: customer?.primaryEmail,
          cardNumber: newCreditCard.number.split(' ').join(''),
          expDate,
          cvv: newCreditCard.cvv,
        }

        api.generateUSIOToken(usioRequest).then((res) => {
          if (res.data.status === 'failure') {
            toast(res.data.message, {
              autoClose: 3000,
              hideProgressBar: true,
              className: 'error-toast',
            })
          } else {
            const creditCardData = {
              customerId: customer?.publicId,
              cardType: res.data.cardBrand,
              lastDigits: res.data.last4,
              expirationDate: res.data.expDate,
              cardToken: res.data.token,
              name: newCreditCard.name,
            }
            api
              .createCreditCard(creditCardData)
              .then((res) => {
                api
                  .addCardToScheduledTempalte(scheduledInvoice.publicId, {
                    publicId: res.data.body.publicId,
                  } as ICreditCard)
                  .then(() => {
                    dispatch(saveScheduledInvoiceTemplates())
                    setIsEditMode(false)
                  })
                  .catch((err) => errorHandler(err))
              })
              .catch((err) => errorHandler(err))
          }
        })
      } else {
        api
          .addCardToScheduledTempalte(scheduledInvoice.publicId, {
            publicId: selectedCard,
          } as ICreditCard)
          .then(() => {
            dispatch(saveScheduledInvoiceTemplates())
            setIsEditMode(false)
          })
          .catch((err) => errorHandler(err))
      }
    } else {
      api
        .editScheduledInvoiceTemplate(
          {
            ...scheduledInvoice,
            paymentMethod: scheduledInvoice.paymentMethod?.toLowerCase(),
          },
          scheduledInvoice.publicId,
          selectedCard ? ({ publicId: selectedCard } as ICreditCard) : undefined,
        )
        .then(() => {
          dispatch(saveScheduledInvoiceTemplates())
          setIsEditMode(false)
        })
        .catch((err) => errorHandler(err))
    }
  }

  useEffect(() => {
    setSelectedCard(scheduledInvoice.creditCard?.publicId)
  }, [])

  useEffect(() => {
    if (isNewCard) {
      setNewCreditCard({
        number: '',
        expirationDate: '',
        cvv: '',
        name: '',
        saveCard: true,
        customerPublicId: customer?.publicId,
      })
      setFormErrors({
        number: '',
        expirationDate: '',
        cvv: '',
        name: '',
      })
      setSelectedCard(undefined)
    } else {
      setSelectedCard(scheduledInvoice.creditCard?.publicId)
      setNewCreditCard(undefined)
      setFormErrors({ cardId: '' })
    }
  }, [isNewCard])

  return (
    <Step key='payment' expanded={true}>
      <StepLabel
        icon={
          scheduledInvoice.paymentMethod ? (
            <CheckCircleIcon color='success' />
          ) : (
            <CircleOutlinedIcon color='success' />
          )
        }
      >
        Invoice payment
      </StepLabel>
      <StepContent>
        {isEditMode ? (
          <Box>
            {isMobile ? (
              <FormControl fullWidth>
                <Select
                  sx={{ mb: 2 }}
                  value={scheduledInvoice.paymentMethod?.toUpperCase() || 'AUTO'}
                  onChange={(e) =>
                    setPaymentMethod && setPaymentMethod(e.target.value.toLowerCase())
                  }
                >
                  <MenuItem value='AUTO' disabled={company?.merchantStatus !== 'Onboarded'}>
                    Credit Card
                  </MenuItem>
                  <MenuItem value='MANUAL'>Manual Payment</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <>
                {company?.merchantStatus !== 'Onboarded' && (
                  <USIONotification closable={false} message='To be able to add credit cards fill out' />
                )}
                <PaymentMethodSelect
                  value={scheduledInvoice.paymentMethod?.toUpperCase() || 'AUTO'}
                  disableAuto={company?.merchantStatus !== 'Onboarded'}
                  autoHelpText={
                    <>
                      <Typography>
                        Blue Cimarron will send a credit card pre-authorization request to the
                        customer.
                      </Typography>
                      <Typography>Your customer:</Typography>
                      <ul>
                        <li>
                          They can easily view the invoice amount, billing frequency, and duration.
                        </li>
                        <li>
                          They have the choice to manually pay individual invoices if they prefer.
                        </li>
                      </ul>
                    </>
                  }
                  manualHelpText={
                    <>
                      <Typography>
                        The customer will have to pay for each invoice manually.
                      </Typography>
                      <ul>
                        <li>
                          Customers will have visibility of the invoice amount they need to pay.
                        </li>
                        <li>
                          They can choose to make manual credit card payments for each invoice.
                        </li>
                      </ul>
                    </>
                  }
                  onChange={(e: React.ChangeEvent, value: string) =>
                    setPaymentMethod && setPaymentMethod(value.toLowerCase())
                  }
                />
              </>
            )}
            {scheduledInvoice.paymentMethod === 'auto' && (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <SelectorMui
                  label='Select a Card'
                  placeholder='Select a Card'
                  error={!!formErrors['cardId']}
                  helperText={formErrors['cardId']}
                  defaultItem='None'
                  options={
                    customer?.creditCardList?.map((card: ICreditCard) => {
                      return {
                        value: card.publicId || '',
                        title: `**** **** **** ${card.lastDigits} - ${card.name}`,
                      }
                    }) || []
                  }
                  onBlur={() => {
                    setFormErrors((prevState: any) => {
                      return {
                        ...prevState,
                        cardId:
                          !selectedCard && !newCreditCard
                            ? 'Should select at least one card'
                            : undefined,
                      }
                    })
                  }}
                  onChange={(e: SelectChangeEvent) => {
                    setFormErrors((prevState: any) => {
                      return {
                        ...prevState,
                        cardId: !e.target.value ? 'Should select at least one card' : undefined,
                      }
                    })
                    setSelectedCard(e.target.value)
                  }}
                  value={selectedCard}
                  sx={{ mb: 1 }}
                />
                <Link
                  component='button'
                  underline='none'
                  color={isNewCard ? 'grey' : 'blue'}
                  onClick={() => {
                    setIsNewCard(!isNewCard)
                  }}
                  sx={{ mb: isNewCard ? 0 : 2 }}
                >
                  {`${isNewCard ? '-' : '+'} Add a new card`}
                </Link>
                {isNewCard && newCreditCard && (
                  <NewCreditCardForm
                    formErrors={formErrors}
                    setFormErrors={setFormErrors}
                    creditCard={newCreditCard}
                    setCreditCard={setNewCreditCard}
                    showSaveFuturePayments={false}
                    mb={0}
                  />
                )}
              </Box>
            )}
            <Button
              variant='outlined'
              onClick={() => {
                setIsEditMode(false)
              }}
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button variant='contained' onClick={handleSaveClick}>
              Save payment
            </Button>
          </Box>
        ) : (
          <Grid container spacing={!isMobile ? 1 : undefined}>
            <Grid item sm={9}>
              <Typography component='span' fontWeight='bold'>
                {scheduledInvoice.paymentMethod === 'auto' && 'Automatic payments: '}
                {scheduledInvoice.paymentMethod === 'manual' && 'Manual payments: '}
              </Typography>
              {scheduledInvoice.paymentMethod === 'auto' &&
                'credit card pre-authorization will be requested on the invoice.'}
              {scheduledInvoice.paymentMethod === 'manual' &&
                'your customer will manually pay each recurring invoice.'}
              {scheduledInvoice.paymentMethod === 'auto' && (
                <Typography>
                  The receipt will then be automatically emailed for future invoices when the credit
                  card is charged.
                </Typography>
              )}
            </Grid>
            <Grid item sm={3}>
              {isAuthorized('write_invoice') && (
                <Button
                  sx={{ mt: { xs: 2, sm: 0 } }}
                  variant='outlined'
                  onClick={handleEditClick}
                  fullWidth
                  disabled={scheduledInvoice.status === 'ENDED'}
                >
                  {scheduledInvoice.paymentMethod ? 'Edit payment' : 'Create payment'}
                </Button>
              )}
            </Grid>
          </Grid>
        )}
      </StepContent>
    </Step>
  )
}

export default PaymentStep
