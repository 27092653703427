import { Box, Card, CardContent, Typography } from '@mui/material'
import { ReactElement } from 'react'
import { isUsingMobile } from '../../helpers/utils'

interface IGeneralDataBlock {
  title: string
  value: string
  subtitle?: ReactElement | string
}

const ActivityCard = (props: IGeneralDataBlock) => {
  const { title, value, subtitle } = props
  const isMobile = isUsingMobile()

  return (
    <>
      <Card sx={{ '&.MuiPaper-root': { boxShadow: 'none' } }}>
        <CardContent sx={{ padding: { xs: '0.5rem !important', sm: '1rem !important' } }}>
          <Box display={isMobile ? 'block' : 'flex'} alignItems='baseline'>
            <Typography
              component={!isMobile ? 'span' : 'p'}
              mr={!isMobile ? 1 : undefined}
              fontSize={'14px'}
              textAlign={!isMobile ? 'left' : 'center'}
            >
              {title}
            </Typography>
            <Typography
              variant='h5'
              flexGrow={2}
              textAlign={!isMobile ? 'right' : 'center'}
              component={!isMobile ? 'span' : 'p'}
            >
              {value}
            </Typography>
          </Box>
          {subtitle && (
            <Typography variant='subtitle1' color='text.secondary'>
              {subtitle}
            </Typography>
          )}
        </CardContent>
      </Card>
    </>
  )
}

export default ActivityCard
