import { Card, CardContent, Typography } from '@mui/material'
import { IActivityStatement, IOustandingInvoice, IPaymentRecord } from '../../../types/types'
import { formatDate, formatMoney } from '../../../helpers/formatPrice'
import { getDayOrDays, isUsingMobile } from '../../../helpers/utils'

interface ActivityInvoiceCardsProps {
  activityStatements: IActivityStatement | null
}

const ActivityInvoiceCards = ({ activityStatements }: ActivityInvoiceCardsProps) => {
  const isMobile = isUsingMobile()

  const getColorByStatus = (status: string) => {
    switch (status) {
      case 'PARTIAL':
        return 'var(--warning-color)'
      case 'SENT':
        return 'var(--info-color)'
      case 'PAID':
        return 'var(--success-color)'
      case 'OVERDUE':
        return 'var(--error-color)'
      case 'REFUND':
        return 'var(--orange-color)'
      case 'DRAFT':
      default:
        return
    }
  }

  const getPaymentDate = (invoice: IOustandingInvoice) => {
    if (invoice && invoice.status === 'PARTIAL' && invoice.paymentRecords.length) {
      return invoice.paymentRecords.map((paymentRecord: IPaymentRecord) =>
        formatDate(paymentRecord.date),
      )
    }

    if (invoice && invoice.status === 'SENT') {
      return `sent ${
        invoice.sentTime ? invoice.sentTime + ` ${getDayOrDays(invoice.sentTime)} ago` : 'today'
      }`
    }

    if (invoice && invoice.status === 'OVERDUE') {
      return `${
        invoice.overdueTime
          ? invoice.overdueTime + ` ${getDayOrDays(invoice.overdueTime)} overdue`
          : 'overdue today'
      }`
    }
  }

  return (
    <>
      <Typography variant='h5' textAlign={isMobile ? 'center' : undefined}>
        Invoices
      </Typography>

      {activityStatements &&
        activityStatements.invoices.map((item) => {
          return (
            <Card
              key={item.publicId}
              sx={{
                color: 'var(--secondary-grey)',
                mb: 1,
                '& .MuiTypography-root': { fontSize: '14px' },
              }}
            >
              <CardContent>
                <Typography>Invoice ID</Typography>
                <Typography color='var(--grey-fonts)' mb={1}>
                  #{item.name}
                </Typography>
                <Typography>Status</Typography>
                <Typography color={getColorByStatus(item.status)} mb={1}>
                  {item.status}
                </Typography>
                <Typography>Amount</Typography>
                <Typography color='var(--grey-fonts)' mb={1}>
                  {formatMoney(item.total)}
                </Typography>
                <Typography>Created</Typography>
                <Typography color='var(--grey-fonts)' mb={1}>
                  {formatDate(item.periodStart)}
                </Typography>
                <Typography>Payment date</Typography>
                <Typography color='var(--grey-fonts)'>
                  <Typography>{formatDate(item.periodEnd)}</Typography>
                  <Typography color={item.status === 'OVERDUE' ? 'red' : undefined}>
                    {getPaymentDate(item)}
                  </Typography>
                </Typography>
              </CardContent>
            </Card>
          )
        })}
    </>
  )
}

export default ActivityInvoiceCards
