/* eslint-disable max-len */
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Business from '../components/business/business'
import Preferences from '../pages/preferences/preferences'
import Roles from '../components/roles/roles'
import Customers from '../pages/customers'
import Dashboard from '../pages/dashboard'
import EstimateManage from '../pages/estimate-manage'
import Estimates from '../pages/estimates'
import Invoices from '../pages/invoices'
import InvoiceManage from '../pages/invoices/invoice-manage'
import Payments from '../pages/payments/payments'
import Products from '../pages/products'
import Profile from '../pages/profile'
import Users from '../pages/profile/users'
import Subscriptions from '../pages/subscriptions'
import Reports from '../pages/customers-reports'
import BusinessInfoManage from '../pages/profile/business-info-manage'
import CustomerView from '../pages/customers/customerView'
import CustomerManage from '../pages/customers/customerManage'
import CustomerStatements from '../pages/customer-statements/customerStatements'
import useAuthorization from '../helpers/useAuthorization'
import Settings from '../pages/profile/settings'
import Subscription from '../pages/subscription'
import Referrals from '../pages/referrals'
import PurchaseProducts from '../pages/purchase-products/products'
import ChartAccount from '../pages/chart-accounts'
import Vendors from '../pages/vendors'
import Bills from '../pages/bills/bills'
import BillManage from '../pages/bills/form/form'
import FinancialStatements from '../pages/financial-statements/financial-statements'
import DetailedReporting from '../pages/detailed-reporting/detailed-reporting'
import JournalEntries from '../pages/journal-entries/journalEntries'
import JournalEntriesManage from '../pages/journal-entries/journalEntriesManage'

const AppRouter = () => {
  const { isAuthorized } = useAuthorization()
  return (
    <Routes>
      {isAuthorized('read_invoice') && <Route path='/dashboard' element={<Dashboard />} />}
      {!isAuthorized('read_invoice') && isAuthorized('manage_referrals') && (
        <Route path='/dashboard' element={<Referrals />} />
      )}
      {isAuthorized('read_estimate') && <Route path='/estimates' element={<Estimates />} />}
      {isAuthorized('read_invoice') && <Route path='/invoices' element={<Invoices />} />}
      {isAuthorized('read_invoice') && (
        <Route path='/recurring-invoices' element={<Subscriptions />} />
      )}
      {isAuthorized('read_invoice') && (
        <Route path='/customer-statements' element={<CustomerStatements />} />
      )}
      {isAuthorized('read_invoice') && <Route path='/customers-reports' element={<Reports />} />}
      {isAuthorized('read_invoice') && <Route path='/financial-statements' element={<FinancialStatements />} />}
      {/* {isAuthorized('read_invoice') && <Route path='/detailed-reporting' element={<DetailedReporting />} />} */}
      {isAuthorized('read_invoice') && <Route path='/account-transaction' element={<DetailedReporting />} />}
      {isAuthorized('read_payments') && <Route path='/payments' element={<Payments />} />}
      {isAuthorized('read_product') && <Route path='/products' element={<Products />} />}
      {isAuthorized('read_customer') && <Route path='/customers' element={<Customers />} />}
      <Route path='/profile/*' element={<Profile />} />
      <Route path='/subs' element={<Subscription />} />
      {(isAuthorized('manage_users') || isAuthorized('manage_referrals')) && (
        <Route path='/referrals' element={<Referrals />} />
      )}
      <Route path='/profile/settings' element={<Settings />} />
      {isAuthorized('write_company') && (
        <Route path='/business/edit' element={<BusinessInfoManage />} />
      )}
      {isAuthorized('read_company') && <Route path='/business' element={<Business />} />}
      {isAuthorized('manage_users') && <Route path='/roles/*' element={<Roles />} />}
      {isAuthorized('manage_users') && <Route path='/users/*' element={<Users />} />}
      {isAuthorized('write_company') && <Route path='/preferences/*' element={<Preferences />} />}
      {isAuthorized('write_invoice') && <Route path='/invoices/add' element={<InvoiceManage />} />}
      {isAuthorized('write_estimate') && (
        <Route path='/estimates/add/*' element={<EstimateManage />} />
      )}
      {isAuthorized('write_invoice') && (
        <Route path='/recurring-invoices/add/*' element={<InvoiceManage />} />
      )}
      {isAuthorized('write_customer') && (
        <Route path='/customers/:publicId/edit' element={<CustomerManage />} />
      )}
      {isAuthorized('write_estimate') && (
        <Route path='/estimates/add/:publicId/edit' element={<EstimateManage />} />
      )}
      {isAuthorized('write_invoice') && (
        <Route path='/invoices/add/:publicId/edit' element={<InvoiceManage />} />
      )}
      {isAuthorized('write_invoice') && (
        <Route path='/recurring-invoices/add/:publicId/edit' element={<InvoiceManage />} />
      )}
      {isAuthorized('read_customer') && (
        <Route path='/customers/:publicId/view/' element={<CustomerView />} />
      )}
      {isAuthorized('read_purchase_product') && (
        <Route path='/purchase-products' element={<PurchaseProducts />} />
      )}
      {isAuthorized('read_invoice') && (
        <Route path='/chart-of-accounts' element={<ChartAccount />} />
      )}
      {isAuthorized('read_vendor') && <Route path='/vendors' element={<Vendors />} />}
      {isAuthorized('read_bill') && <Route path='/bills' element={<Bills />} />}
      {isAuthorized('write_bill') && (
        <Route path='/bills/add' element={<BillManage />} />
      )}
      {isAuthorized('write_bill') && (
        <Route path='/bills/:publicId/edit' element={<BillManage />} />
      )}
      {isAuthorized('read_journal_entry') && <Route path='/journal-entries' element={<JournalEntries />} />}
      {isAuthorized('read_journal_entry') && <Route path='/journal-entries/add' element={<JournalEntriesManage />} />}
      {isAuthorized('read_journal_entry') && <Route path='/journal-entries/:publicId/edit' element={<JournalEntriesManage />} />}
    </Routes>
  )
}

export default AppRouter
