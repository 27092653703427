import { useCallback, useMemo, useState } from 'react'
import { IScheduledInvoice, IScheduler } from '../../types/types'
import { DataGrid, GridColDef, GridRowSpacingParams } from '@mui/x-data-grid'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { isUsingMobile } from '../../helpers/utils'
import { Box, Chip, IconButton, Menu, MenuItem, Typography, Button } from '@mui/material'
import TableStyles from '../../components/table-card/table-styles'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { formatDate, formatMoney } from '../../helpers/formatPrice'
import { daysOfMonth, daysOfWeek, months } from './helper'
import moment from 'moment'
import useAuthorization from '../../helpers/useAuthorization'

interface SubscriptionsTableProps {
  scheduledInvoices: IScheduledInvoice[]
  setSelectedScheduledInvoice: (scheduledInvoice: IScheduledInvoice | null) => void
  onRowClick: (scheduledInvoice: IScheduledInvoice) => void
  onEditClick?: () => void
  onChangeStatusClick?: () => void
  onDuplicateClick?: () => void
  onDeleteClick?: () => void
  onActivateClick?: (scheduledInvoice: IScheduledInvoice) => void
  onStopClick?: (scheduledInvoice: IScheduledInvoice) => void
  disabledItems?: string[]
  changeStatusText?: string
}

const SubscriptionsTable = ({
  scheduledInvoices,
  setSelectedScheduledInvoice,
  onRowClick,
  onEditClick,
  onChangeStatusClick,
  onDuplicateClick,
  onDeleteClick,
  onActivateClick,
  onStopClick,
  changeStatusText = 'Activate',
  disabledItems = [],
}: SubscriptionsTableProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openItemMenu = Boolean(anchorEl)
  const isMobile = isUsingMobile()
  const { isAuthorized } = useAuthorization()

  const handleMenuButtonClick = useCallback(
    (scheduledInvoice: IScheduledInvoice) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      setSelectedScheduledInvoice(scheduledInvoice)
      setAnchorEl(event.currentTarget)
    },
    [setSelectedScheduledInvoice],
  )

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleDeleteClick = () => {
    handleCloseMenu()
    onDeleteClick && onDeleteClick()
  }

  const handleEditClick = () => {
    handleCloseMenu()
    onEditClick && onEditClick()
  }

  const handleChangeStatusClick = () => {
    handleCloseMenu()
    onChangeStatusClick && onChangeStatusClick()
  }

  const handleDuplicateClick = () => {
    handleCloseMenu()
    onDuplicateClick && onDuplicateClick()
  }

  const handleSetScheduleClick =
    (scheduledInvoice: IScheduledInvoice) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()

      if (scheduledInvoice.status === 'DRAFT' && !scheduledInvoice.scheduler) {
        onRowClick(scheduledInvoice)
      } else {
        scheduledInvoice.status === 'DRAFT' && onActivateClick && onActivateClick(scheduledInvoice)
        scheduledInvoice.status === 'STOPPED' && onActivateClick && onActivateClick(scheduledInvoice)
        scheduledInvoice.status === 'ACTIVE' && onStopClick && onStopClick(scheduledInvoice)
      }
    }

  const renderDuration = (scheduler: IScheduler) => {
    switch (scheduler.type) {
      case 'DAILY':
        return 'Daily'
      case 'WEEKLY':
        return `Weekly every ${daysOfWeek[String(scheduler.dayOfWeek)]}`
      case 'MONTHLY':
        return `Monthly on the ${daysOfMonth[String(scheduler.day)]}`
      case 'YEARLY':
        return `Yearly on ${months[String(scheduler.month)]} ${daysOfMonth[String(scheduler.day)]}`
    }
  }

  const renderEndDate = (endDate: string) => {
    return endDate ? moment(endDate).format('MMM DD, YYYY') : 'Never'
  }

  const getColorByStatus = (status: string) => {
    switch (status) {
      case 'SENT':
        return 'info'
      case 'ACTIVE':
        return 'success'
      case 'STOPPED':
        return 'error'
      case 'ENDED':
        return 'warning'
      case 'DRAFT':
      default:
        return
    }
  }

  const getActionColor = (subscription: IScheduledInvoice) => {
    if (subscription.status === 'DRAFT' && !subscription.scheduler) {
      return 'primary'
    }

    if (subscription.status === 'DRAFT' && subscription.scheduler) {
      return 'success'
    }

    if (subscription.status === 'ACTIVE') {
      return 'error'
    }

    if (subscription.status === 'STOPPED') {
      return 'success'
    }
  }

  const getActionName = (subscription: IScheduledInvoice) => {
    if (subscription.status === 'DRAFT' && !subscription.scheduler) {
      return 'SCHEDULE'
    }

    if (subscription.status === 'DRAFT' && subscription.scheduler) {
      return 'ACTIVATE'
    }

    if (subscription.status === 'ACTIVE') {
      return 'STOP'
    }

    if (subscription.status === 'STOPPED') {
      return 'ACTIVATE'
    }
  }

  const columns = useMemo<GridColDef<IScheduledInvoice>[]>(
    () => [
      {
        field: 'status',
        headerName: 'Status',
        flex: isMobile ? 1 : 2,
        sortable: false,
        renderCell: (param) => {
          return isMobile ? (
            <FiberManualRecordIcon
              sx={{
                '&.MuiSvgIcon-root': {
                  color: 'var(--secondary-grey)',
                  backgroundColor: 'var(--secondary-grey-bg)',
                  borderRadius: '50%',
                  margin: 'auto',
                },
                '&.MuiSvgIcon-root.MuiSvgIcon-colorSuccess': {
                  color: 'var(--success-color)',
                  borderRadius: '50%',
                  backgroundColor: 'var(--success-bg-color)',
                },
                '&.MuiSvgIcon-root.MuiSvgIcon-colorError': {
                  color: 'var(--error-color)',
                  borderRadius: '50%',
                  backgroundColor: 'var(--error-bg-color)',
                },
                '&.MuiSvgIcon-root.MuiSvgIcon-colorWarning': {
                  color: 'var(--warning-color)',
                  borderRadius: '50%',
                  backgroundColor: 'var(--warning-bg-color)',
                },
              }}
              color={getColorByStatus(param.row.status || 'disabled')}
            />
          ) : (
            <Chip
              sx={{
                '&.MuiChip-colorSuccess': {
                  backgroundColor: 'var(--success-bg-color)',
                  color: 'var(--success-color)',
                },
                '&.MuiChip-colorError': {
                  backgroundColor: 'var(--error-bg-color)',
                  color: 'var(--error-color)',
                },
                '&.MuiChip-colorInfo': {
                  backgroundColor: 'var(--info-bg-color)',
                  color: 'var(--info-color)',
                },
                '&.MuiChip-colorWarning': {
                  backgroundColor: 'var(--warning-bg-color)',
                  color: 'var(--warning-color)',
                },
              }}
              color={getColorByStatus(param.row.status || 'default')}
              label={param.row.status}
            />
          )
        },
      },
      {
        field: 'customerName',
        headerName: 'Customer Name',
        flex: 3,
        sortable: false,
        renderCell: (param) => {
          return (
            <Box>
              <Typography>{param.row.customerName}</Typography>
              {param.row.customerEmail && (
                <Typography
                  component='small'
                  variant='subtitle2'
                  sx={{ color: 'var(--gray3-color)' }}
                >
                  {isMobile
                    ? param.row.scheduler
                      ? 'Until ' + renderEndDate(param.row.scheduler.endDate)
                      : '-'
                    : param.row.customerEmail}
                </Typography>
              )}
            </Box>
          )
        },
      },
      // {
      //   field: 'lastExecutionDate',
      //   headerName: 'Last Invoice',
      //   flex: 2,
      //   sortable: false,
      //   renderCell: (param) => {
      //     return param.row.scheduler?.lastExecutionDate
      //       ? formatDate(param.row.scheduler?.lastExecutionDate)
      //       : '-'
      //   },
      // },
      {
        field: 'nextExecutionDate',
        headerName: 'Next Invoice',
        flex: 2,
        sortable: false,
        renderCell: (param) => {
          return param.row.scheduler?.nextExecutionDate
            ? formatDate(param.row.scheduler?.nextExecutionDate)
            : '-'
        },
      },
      {
        field: 'duration',
        headerName: 'Frequency',
        flex: 2,
        sortable: false,
        renderCell: (param) => {
          return param.row.scheduler ? renderDuration(param.row.scheduler) : '-'
        },
      },
      {
        field: 'endDate',
        headerName: 'End Date',
        flex: 2,
        sortable: false,
        renderCell: (param) => {
          return param.row.scheduler ? renderEndDate(param.row.scheduler.endDate) : '-'
        },
      },
      {
        field: 'invoiceAmount',
        headerName: 'Amount',
        flex: 2,
        sortable: false,
        renderCell: (param) => {
          return formatMoney(Number(param.row.total))
        },
      },
      {
        field: 'cta',
        headerName: 'Actions',
        flex: 2,
        sortable: false,
        renderCell: (param) => {
          if (param.row.status === 'ENDED') {
            return null
          }
          return (
            <Button
              style={{ width: '150px' }}
              color={getActionColor(param.row)}
              variant='outlined'
              onClick={handleSetScheduleClick(param.row)}
            >
              {getActionName(param.row)}
            </Button>
          )
        },
      },
      {
        field: ' ',
        headerName: ' ',
        flex: 1.5,
        sortable: false,
        renderCell: (param) => (
          <IconButton
            aria-label='more'
            id='long-button'
            aria-controls={openItemMenu ? 'long-menu' : undefined}
            aria-expanded={openItemMenu ? 'true' : undefined}
            aria-haspopup='true'
            onClick={handleMenuButtonClick(param.row)}
            sx={{
              justifyItems: 'right',
            }}
          >
            <MoreVertIcon />
          </IconButton>
        ),
      },
    ],
    [isMobile, handleMenuButtonClick, openItemMenu],
  )

  const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: 5,
    }
  }, [])

  return (
    <>
      <DataGrid
        rows={scheduledInvoices}
        columns={columns}
        disableColumnMenu
        getRowId={(scheduledInvoice) => scheduledInvoice.publicId || ''}
        hideFooter
        getRowSpacing={getRowSpacing}
        rowHeight={65}
        columnHeaderHeight={24}
        initialState={{
          columns: {
            columnVisibilityModel: {
              lastExecutionDate: !isMobile,
              nextExecutionDate: !isMobile,
              endDate: !isMobile,
              duration: !isMobile,
              cta: !isMobile,
              actions: isAuthorized('write_invoice'),
            },
          },
        }}
        onRowClick={(params) => onRowClick(params.row)}
        sx={TableStyles}
      />
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={openItemMenu}
        onClose={handleCloseMenu}
        sx={{
          '& .MuiMenuItem-root': {
            padding: { xs: '1rem', md: '1rem 4rem 1rem 2rem' },
          },
          '& .MuiMenuItem-root:hover': {
            backgroundColor: 'var(--secondary1-color)',
            color: 'var(--primary1-color)',
          },
        }}
      >
        {isAuthorized('write_invoice') && (
          <MenuItem onClick={handleEditClick} disabled={disabledItems.includes('edit')}>
            Edit
          </MenuItem>
        )}
        {isAuthorized('write_invoice') && (
          <MenuItem
            onClick={handleChangeStatusClick}
            disabled={disabledItems.includes('changeStatus')}
          >
            {changeStatusText}
          </MenuItem>
        )}
        {isAuthorized('write_invoice') && (
          <MenuItem onClick={handleDuplicateClick} disabled={disabledItems.includes('duplicate')}>
            Duplicate
          </MenuItem>
        )}
        {isAuthorized('write_invoice') && (
          <MenuItem sx={{ color: 'var(--error-color)' }} onClick={handleDeleteClick}>
            Delete
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

export default SubscriptionsTable
