import { Box, Typography } from '@mui/material'
import SelectorMui from '../../components/selector-mui'
import CashInflowChart from './cash-inflow-chart'
import { useSelector } from 'react-redux'
import { AppState } from '../../redux/store'
import { useEffect, useState } from 'react'
import { isUsingMobile } from '../../helpers/utils'
import { formatMoney } from '../../helpers/formatPrice'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const CashFlowCard = () => {
  const isMobile = isUsingMobile()
  const cashInflow = useSelector((state: AppState) => state.dashboardReducer.cashInflow)
  const totalRevenue = useSelector((state: AppState) => state.dashboardReducer.totalRevenue)
  const [cashInflowRange, setCashInflowRange] = useState<number>(3)

  const selectorOptions = [
    { value: 3, title: 'Last 3 months' },
    { value: 6, title: 'Last 6 months' },
    { value: 9, title: 'Last 9 months' },
    { value: 12, title: 'Last 12 months' },
  ]

  const changeCashInflowRange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCashInflowRange(Number(e.target.value))
  }

  useEffect(() => {
    if (cashInflow) {
      const renderChartAmount = () => {
        if (!cashInflow.points.length) return 3
        const restFromThree = cashInflow.points.length % 3
        switch (restFromThree) {
          case 0:
            return cashInflow.points.length
          case 1:
            return cashInflow.points.length + 2
          default:
            return cashInflow.points.length + 1
        }
      }

      setCashInflowRange(renderChartAmount())
    }
  }, [cashInflow])

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        px: '50px',
        paddingTop: '1px',
        paddingBottom: '50px',
        borderRadius: '10px',
        marginBottom: '20px',
        '@media screen and (max-width: 767px)': { paddingLeft: '10px', paddingRight: '10px' },
      }}
    >
      <div className='dashboard-inflow__header'>
        <div className='dashboard__block-title'>
          <Typography color='var(--secondary-grey)' variant='h5' fontWeight='600'>
            {'Cash Flow '}{' '}
          </Typography>
          <Typography color='var(--gray3-color)' variant='h6' fontWeight='400'>
            (coming in your business)
          </Typography>
        </div>
        <div style={{ marginTop: !isMobile ? '0' : '30px' }}>
          <SelectorMui
            sx={{ width: '200px' }}
            options={
              cashInflow && cashInflow.points.length
                ? selectorOptions.slice(0, Math.ceil(cashInflow.points.length / 3))
                : [{ value: 3, title: 'Last 3 months' }]
            }
            value={cashInflowRange}
            onChange={changeCashInflowRange}
            disabled={!cashInflow?.points.length}
            placeholder={'Report Dates'}
            label={'Report Dates'}
          />
        </div>
      </div>
      <div className='dashboard-inflow__chart-block'>
        <div className='dashboard-inflow__amount-block'>
          <div className='dashboard-inflow__amount'>
            <Typography color='var(--secondary-grey)' variant='h4' fontWeight='400'>
              {formatMoney(totalRevenue?.totalRevenue)}
            </Typography>
          </div>
        </div>
        <div className='dashboard-inflow__chart'>
          <CashInflowChart
            chartPoints={
              cashInflow && cashInflow.points.length
                ? cashInflow.points.slice(0, cashInflowRange)
                : []
            }
            chartRange={cashInflowRange}
          />
        </div>
      </div>
    </Box>
  )
}

export default CashFlowCard
