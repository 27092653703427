import { Box } from '@mui/system'
import ListFilters from '../../components/list-filters'
import CustomerStats from './customerStats'
import InvoicesTable from '../invoices/invoicesTable'
import PaginationNew from '../../components/pagination-new'
import NoDataDisplay from '../../components/noDataDisplay/noDataDisplay'
import { ICustomer, IInvoice } from '../../types/types'
import { SyntheticEvent } from 'react'

interface CustomerActivityProps {
  chosenCustomer: ICustomer
  selectedFilter: string
  onSelectedFilterChange: (event: SyntheticEvent, filter: string) => void
  invoices: IInvoice[]
  invoicesSize: number
  invoicesPage: number
  selectedInvoice?: IInvoice
  setSelectedInvoice: (selectedInvoice: IInvoice | undefined) => void
  onChangePage: (event: React.ChangeEvent<unknown>, value: number) => void
  actions: any
}

const CustomerActivity = ({
  chosenCustomer,
  selectedFilter,
  onSelectedFilterChange,
  invoices,
  invoicesSize,
  invoicesPage,
  selectedInvoice,
  setSelectedInvoice,
  onChangePage,
  actions,
}: CustomerActivityProps) => {
  const {
    onViewInvoiceClick,
    onDuplicateInvoiceClick,
    onEditInvoiceClick,
    onRecordPaymentClick,
    onSendInvoiceClick,
    onPrintClick,
    onExportAsPDFClick,
    onDeleteInvoiceClick,
    onShareLinkClick,
  } = actions

  const getDisabledItems = () => {
    if (!selectedInvoice || !selectedInvoice.status) {
      return []
    }

    switch (selectedInvoice.status) {
      case 'PAID':
        return ['edit', 'payment', 'send', 'export', 'share']
      case 'OVERDUE':
        return ['edit']
      case 'DRAFT':
        return ['payment', 'share']
    }

    return []
  }

  return (
    <>
      <CustomerStats stats={chosenCustomer.customerStatistics} />
      <ListFilters
        filters={[
          { title: 'All', value: '' },
          { title: 'Partial', value: 'PARTIAL' },
          { title: 'Sent', value: 'SENT' },
          { title: 'Paid', value: 'PAID' },
          { title: 'Overdue', value: 'OVERDUE' },
          { title: 'Draft', value: 'DRAFT' },
          { title: 'Refund', value: 'REFUND' },
        ]}
        selectedFilter={selectedFilter}
        onSelectedFilterChange={onSelectedFilterChange}
      />

      {invoices.length > 0 ? (
        <Box pb={4}>
          <InvoicesTable
            invoices={invoices}
            onRowClick={onViewInvoiceClick}
            onDuplicateClick={onDuplicateInvoiceClick}
            onEditClick={onEditInvoiceClick}
            onRecordPaymentClick={onRecordPaymentClick}
            onSendClick={onSendInvoiceClick}
            onPrintClick={onPrintClick}
            onExportAsPDFClick={onExportAsPDFClick}
            onDeleteClick={onDeleteInvoiceClick}
            onShareLinkClick={onShareLinkClick}
            onSelectedInvoice={setSelectedInvoice}
            disabledItems={getDisabledItems()}
            sendLabel={selectedInvoice && selectedInvoice.status === 'DRAFT' ? 'Send' : 'Resend'}
          />{' '}
          {invoicesSize > 15 ? (
            <PaginationNew
              size={15}
              quantity={invoicesSize}
              page={invoicesPage}
              handleChange={onChangePage}
            />
          ) : null}
        </Box>
      ) : (
        <NoDataDisplay title='No data to display' />
      )}
    </>
  )
}

export default CustomerActivity
