import moment from 'moment'
import DateField from '../../../components/date-field'
import { Box, Button, Card, CardContent, IconButton, Stack } from '@mui/material'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import { useDispatch } from 'react-redux'
import {
  saveAgedReportsDetailed,
  saveAgedReportsGeneral,
} from '../../../redux/actions/report.actions'
import api from '../../../api/api'
import { errorHandler } from '../../../helpers/errorHandler'
import { DEFAULT_DATE_FORMAT } from '../../../helpers/constants'

const AgedReceivablesFilters = ({
  mb,
  filterData,
  setFilterData,
}: {
  mb: number
  filterData: any
  setFilterData: any
}) => {
  const dispatch = useDispatch()

  const handleChangeAgedDate = (date: string) => {
    setFilterData({
      ...filterData,
      date: date,
    })
  }

  const handleUpdateClick = () => {
    dispatch(saveAgedReportsDetailed(moment(filterData.date).format(DEFAULT_DATE_FORMAT)))
    dispatch(saveAgedReportsGeneral(moment(filterData.date).format(DEFAULT_DATE_FORMAT)))
  }

  const handleGenerateExcelReportClick = () => {
    api
      .generateExcel(moment(filterData.date).format(DEFAULT_DATE_FORMAT))
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `aged-receivables.xlsx`)
        document.body.appendChild(link)
        link.click()
      })
      .catch((err) => errorHandler(err))
  }

  return (
    <Card sx={{ mb, boxShadow: 'none' }}>
      <CardContent sx={{ pb: '16px !important' }}>
        <Stack direction='row' spacing={2}>
          <DateField value={filterData.date || ''} onChange={handleChangeAgedDate} />
          <Button
            size='large'
            variant='contained'
            onClick={handleUpdateClick}
            sx={{ lineHeight: '16px' }}
          >
            Update report
          </Button>
          <Box flexGrow={2} alignSelf='center' display='flex' justifyContent={'end'}>
            <IconButton
              aria-label='download'
              onClick={handleGenerateExcelReportClick}
              color='primary'
              size='large'
              sx={{ display: 'flex' }}
            >
              <DownloadOutlinedIcon />
            </IconButton>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default AgedReceivablesFilters
