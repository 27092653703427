import { useEffect, useState } from 'react'
import { isUsingMobile } from '../../../helpers/utils'
import { ICustomer, IScheduledInvoice } from '../../../types/types'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  InputLabel,
  Step,
  StepContent,
  StepLabel,
  TextField,
  Typography,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../redux/store'
import api from '../../../api/api'
import { saveScheduledInvoiceTemplates } from '../../../redux/actions/home.actions'
import { errorHandler } from '../../../helpers/errorHandler'
import useAuthorization from '../../../helpers/useAuthorization'

interface NotificationStepProps {
  scheduledInvoice: IScheduledInvoice
  customer?: ICustomer | null
  setSendTo: (sendTo: string) => void
}

const NotificationStep = ({ scheduledInvoice, customer, setSendTo }: NotificationStepProps) => {
  const isMobile = isUsingMobile()
  const dispatch = useDispatch()
  const { isAuthorized } = useAuthorization()
  const user = useSelector((state: AppState) => state.authReducer.user)
  const [isEditMode, setIsEditMode] = useState(false)

  useEffect(() => {
    if (!scheduledInvoice.sendTo && isEditMode) {
      setSendTo(customer?.primaryEmail || '')
    }
  }, [isEditMode])

  const handleSaveClick = () => {
    api
      .editScheduledInvoiceTemplate(
        {
          ...scheduledInvoice,
          sendTo: scheduledInvoice.sendTo,
        },
        scheduledInvoice.publicId,
      )
      .then(() => {
        dispatch(saveScheduledInvoiceTemplates())
        setIsEditMode(false)
      })
      .catch((err) => errorHandler(err))
  }

  return (
    <Step key='notification' expanded={true}>
      <StepLabel
        icon={
          scheduledInvoice.scheduler ? (
            <CheckCircleIcon color='success' />
          ) : (
            <CircleOutlinedIcon color='success' />
          )
        }
      >
        Invoice sending
      </StepLabel>
      <StepContent sx={{ pt: 1 }}>
        {isEditMode ? (
          <Box>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                label='Send from'
                value={user.email}
                disabled
                placeholder='Enter the source account'
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                label='Send to'
                value={scheduledInvoice.sendTo}
                onChange={(e) => setSendTo(e.target.value)}
                placeholder='Enter the destination account'
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
            <Button
              variant='outlined'
              onClick={() => {
                setIsEditMode(false)
              }}
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button variant='contained' onClick={handleSaveClick}>
              Save sending
            </Button>
          </Box>
        ) : (
          <Grid container spacing={!isMobile ? 1 : undefined}>
            <Grid item sm={9}>
              {scheduledInvoice.sendTo ? (
                <>
                  <Typography>
                    <Typography component='span' fontWeight='bold'>
                      Automatic sending:
                    </Typography>
                    {` email the invoice automatically to ${scheduledInvoice.sendTo} until your customer pre-authorizes payments.`}
                  </Typography>
                  <Typography>
                    A receipt will be automatically emailed whenever their credit card is charged.
                  </Typography>
                </>
              ) : (
                <>
                  <Typography>
                    <Typography component='span' fontWeight='bold'>
                      Automatic sending:
                    </Typography>{' '}
                    the invoice will be emailed to your customer automatically.
                  </Typography>
                  <Typography>
                    A receipt will be automatically emailed whenever their credit card is charged.
                  </Typography>
                  <Typography>
                    <Typography component='span' fontWeight='bold'>
                      Invoice and receipt:
                    </Typography>{' '}
                    the invoice will be automatically sent until your customer pre-authorizes credit
                    card charges.
                  </Typography>
                  <Typography>
                    The Receipt will be automatically sent once their credit card is charged.
                  </Typography>
                </>
              )}
            </Grid>
            <Grid item sm={3}>
              {isAuthorized('write_invoice') && (
                <Button
                  sx={{ mt: { xs: 2, sm: 0 } }}
                  variant='outlined'
                  onClick={() => setIsEditMode(true)}
                  fullWidth
                  disabled={scheduledInvoice.status === 'ENDED'}
                >
                  {scheduledInvoice.scheduler ? 'Edit sending' : 'Create sending'}
                </Button>
              )}
            </Grid>
          </Grid>
        )}
      </StepContent>
    </Step>
  )
}

export default NotificationStep
