import { Button, Grid } from '@mui/material'

interface JournalEntryModalFooterProps {
  onCancelClick?: () => void
  onEditClick?: () => void
}

const JournalEntryModalFooter = ({ onCancelClick, onEditClick }: JournalEntryModalFooterProps) => {
  return (
    <Grid
      container
      justifyContent={{ xs: 'normal', md: 'right' }}
      alignItems={{ xs: 'normal', md: 'center' }}
      flexDirection={{ xs: 'column-reverse', md: 'row' }}
      spacing={2}
      p={2}
    >
      <Grid item>
        <Button color='primary' variant='outlined' onClick={onCancelClick} fullWidth>
          Cancel
        </Button>
      </Grid>
      <Grid item>
        <Button color='primary' variant='contained' onClick={onEditClick} fullWidth>
          Edit
        </Button>
      </Grid>
    </Grid>
  )
}

export default JournalEntryModalFooter
