import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import DeleteButtonTable from '../../../components/delete-icon/deleteIcon'
import { INewJournalEntryItem, INewJournalEntry } from '../../../types/types'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../redux/store'
import { useEffect, useState } from 'react'
import { saveFinancialAccount } from '../../../redux/actions/home.actions'

interface JournalFormTableProps {
  journal: INewJournalEntry
  onChangeAccountItem: any
  onDeleteItem: any
}

const JournalFormTable = ({
  journal,
  onChangeAccountItem,
  onDeleteItem,
}: JournalFormTableProps) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const [options, setOptions] = useState<any[]>([])
  const financialAccount = useSelector((state: AppState) => state.homeReducer.financialAccount)

  useEffect(() => {
    dispatch(saveFinancialAccount())
  }, [dispatch])

  useEffect(() => {
    if (financialAccount && financialAccount.content) {

      const activeAccounts = financialAccount.content.filter((account: any) => account.active);

      const categorizedAccounts = activeAccounts.reduce((acc: any, account: any) => {
        if (!acc[account.type.category]) {
          acc[account.type.category] = []
        }
        acc[account.type.category].push(account)
        return acc
      }, {})

      const newOptions = Object.entries(categorizedAccounts).map(([category, accounts]) => ({
        label: category,
        options: Array.isArray(accounts)
          ? accounts.map((acc) => ({
              value: acc.publicId,
              label: acc.name,
            }))
          : [],
      }))

      setOptions(newOptions)
      setIsLoading(false)
    }
  }, [financialAccount])

  return (
    <Table>
      <TableHead>
        <TableRow sx={{ backgroundColor: 'var(--background-color)' }}>
          <TableCell width='30%'>Account</TableCell>
          <TableCell width='25%'>Description</TableCell>
          <TableCell width='15%'>Debit</TableCell>
          <TableCell width='15%'>Credit</TableCell>
          <TableCell width='10%'></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {journal.items.map((item: INewJournalEntryItem, index: number) => {
          const disableDebit = !!item.debitAmount
          const disableCredit = !!item.creditAmount
          return (
            <TableRow key={index}>
              <TableCell>
                {isLoading ? (
                  <div></div>
                ) : (
                  <>
                    <FormControl fullWidth>
                      <InputLabel id='account-label'>Select an account</InputLabel>
                      <Select
                        required
                        fullWidth
                        labelId='account-label'
                        label='Select an account'
                        placeholder='Select an account'
                        value={item.accountPublicId}
                        onChange={onChangeAccountItem(index, 'accountPublicId')}
                      >
                        {options.map((category) => [
                          <MenuItem
                            key={category.label}
                            disabled
                            style={{ fontWeight: 'bold', color: 'black', opacity: 1 }}
                          >
                            {category.label}
                          </MenuItem>,
                          ...category.options.map((option: any) => (
                            <MenuItem
                              key={option.label}
                              value={option.value}
                              style={{ marginLeft: '5px' }}
                            >
                              {option.label}
                            </MenuItem>
                          )),
                        ])}
                      </Select>
                    </FormControl>
                  </>
                )}
              </TableCell>
              <TableCell>
                <TextField
                  fullWidth
                  placeholder='Enter a description'
                  value={item.description}
                  onChange={onChangeAccountItem(index, 'description')}
                />
              </TableCell>
              <TableCell>
                <TextField
                  fullWidth
                  type='number'
                  placeholder='0'
                  value={item.debitAmount !== 0 ? item.debitAmount : undefined}
                  onChange={onChangeAccountItem(index, 'debitAmount')}
                  disabled={disableCredit}
                />
              </TableCell>
              <TableCell>
                <TextField
                  fullWidth
                  type='number'
                  placeholder='0'
                  value={item.creditAmount !== 0 ? item.creditAmount : undefined}
                  onChange={onChangeAccountItem(index, 'creditAmount')}
                  disabled={disableDebit}
                />
              </TableCell>
              <TableCell>
                <DeleteButtonTable
                  disabled={journal.items.length <= 2}
                  handleOnDeleted={onDeleteItem(index)}
                ></DeleteButtonTable>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default JournalFormTable
