import { Grid, TextField } from '@mui/material'
import { validateEmail, validateName, validatePhoneNumber } from '../../../helpers/utils'
import useAuthorization from '../../../helpers/useAuthorization'

interface PersonalInfoStepProps {
  currentVendor: any
  handleChangeNewVendor: any
  formErrors: any
  setFormErrors: any
}

const PersonalInfoStep = ({
  currentVendor,
  handleChangeNewVendor,
  formErrors,
  setFormErrors,
}: PersonalInfoStepProps) => {
  const { isAuthorized } = useAuthorization()

  return (
    <Grid container gap={2}>
      <Grid item xs={12} pl={1} pr={1}>
        <TextField
          label='First name'
          placeholder='Enter an first name'
          fullWidth
          InputLabelProps={{ shrink: true }}
          required
          disabled={!isAuthorized('write_vendor')}
          value={currentVendor.firstName}
          onBlur={(e) => {
            validateName(e.target.value, 'firstName', setFormErrors, 'First name')
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleChangeNewVendor(e, 'firstName')
            validateName(e.target.value, 'firstName', setFormErrors, 'First name')
          }}
          error={!!formErrors['firstName']}
          helperText={formErrors['firstName']}
        />
      </Grid>
      <Grid item xs={12} pl={1} pr={1}>
        <TextField
          label='Last name'
          placeholder='Enter an last name'
          fullWidth
          InputLabelProps={{ shrink: true }}
          required
          disabled={!isAuthorized('write_vendor')}
          value={currentVendor.lastName}
          onBlur={(e) => {
            validateName(e.target.value, 'lastName', setFormErrors, 'Last name')
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleChangeNewVendor(e, 'lastName')
            validateName(e.target.value, 'lastName', setFormErrors, 'Last name')
          }}
          error={!!formErrors['lastName']}
          helperText={formErrors['lastName']}
        />
      </Grid>
      <Grid item xs={12} pl={1} pr={1}>
        <TextField
          label='Email address'
          placeholder='Enter an email address'
          fullWidth
          InputLabelProps={{ shrink: true }}
          required
          disabled={!isAuthorized('write_vendor')}
          value={currentVendor.emailAddress}
          onBlur={(e) => {
            validateEmail(e.target.value, setFormErrors, 'emailAddress')
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleChangeNewVendor(e, 'emailAddress')
            validateEmail(e.target.value, setFormErrors, 'emailAddress')
          }}
          error={!!formErrors['emailAddress']}
          helperText={formErrors['emailAddress']}
        />
      </Grid>
      <Grid item xs={12} pl={1} pr={1}>
        <TextField
          label='Phone number'
          placeholder='Enter a phone number'
          fullWidth
          InputLabelProps={{ shrink: true }}
          required
          disabled={!isAuthorized('write_vendor')}
          value={currentVendor.phone}
          onBlur={(e) => {
            validatePhoneNumber(e.target.value, setFormErrors, 'phone')
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleChangeNewVendor(e, 'phone')
            validatePhoneNumber(e.target.value, setFormErrors, 'phone')
          }}
          error={!!formErrors['phone']}
          helperText={formErrors['phone']}
        />
      </Grid>
    </Grid>
  )
}

export default PersonalInfoStep
