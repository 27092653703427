import { Bar } from 'react-chartjs-2'
import { ICashInflowPoint } from '../../../types/types'
import { chartMonths } from '../helpers'
import moment from 'moment'

import './cash-inflow-chart.css'
import { formatMoney } from '../../../helpers/formatPrice'

interface ICashInflowChart {
  chartPoints: ICashInflowPoint[]
  chartRange: number
}

const CashInflowChart = (props: ICashInflowChart) => {
  const { chartPoints, chartRange } = props

  const renderLabels = () => {
    if (chartPoints && chartPoints.length) {
      const labelMonths = chartPoints
        .map((point: ICashInflowPoint) => {
          const monthValue = point.month.slice(5, 7)
          return chartMonths.get(monthValue)
        })
      const getPreviousMonth = (monthDifference: number) => {
        return moment(chartPoints[0].month, 'YYYY-MM')
          .subtract(monthDifference, 'month')
          .format('MMM')
      }
      const renderDisabledData = () => {
        if (chartRange - chartPoints.length === 1) {
          return [getPreviousMonth(1), ...labelMonths]
        } else {
          return [getPreviousMonth(2), getPreviousMonth(1), ...labelMonths]
        }
      }
      return chartPoints.length < chartRange ? renderDisabledData() : labelMonths
    } else {
      return [
        moment().subtract(2, 'month').format('MMM'),
        moment().subtract(1, 'month').format('MMM'),
        moment().format('MMM'),
      ]
    }
  }

  const renderData = () => {
    if (chartPoints && chartPoints.length) {
      const netChartData = chartPoints.map((point: ICashInflowPoint) => point.cashInflow).reverse()
      if (chartPoints.length < chartRange) {
        return chartRange - chartPoints.length === 1
          ? [0, ...netChartData]
          : [0, 0, ...netChartData]
      } else {
        return netChartData
      }
    } else {
      return new Array(3).fill(null).map(() => 0)
    }
  }

  const generateGradientColor = () => {
    return {
      bgEnabled: '#17A5E6',
      bgDisabled: '#9CAFF4',
    }
  }

  const renderChartColors = () => {
    if (chartPoints && chartPoints.length) {
      const dataChartBars = new Array(chartPoints.length).fill(generateGradientColor().bgEnabled)
      return chartPoints.length < chartRange
        ? chartRange - chartPoints.length === 1
          ? [generateGradientColor().bgDisabled, ...dataChartBars]
          : [
              generateGradientColor().bgDisabled,
              generateGradientColor().bgDisabled,
              ...dataChartBars,
            ]
        : dataChartBars
    } else {
      return new Array(3).fill('#9CAFF4')
    }
  }

  const renderBarThickness = () => {
    const screenWidth = window.innerWidth

    return screenWidth < 1120
      ? screenWidth < 756
        ? // Adjust the values based on your preference for smaller screens
          chartRange === 3
          ? 50
          : chartRange === 6
          ? 20
          : chartRange === 9
          ? 10
          : chartRange === 12
          ? 5
          : 40
        : // Adjust the values based on your preference for larger screens
        chartRange === 3
        ? 150
        : chartRange === 6
        ? 80
        : chartRange === 9
        ? 40
        : chartRange === 12
        ? 25
        : 40
      : // Adjust the values based on your preference for larger screens
      chartRange === 3
      ? 210
      : chartRange === 6
      ? 115
      : chartRange === 9
      ? 64
      : chartRange === 12
      ? 40
      : 40
  }

  const data = {
    labels: renderLabels(),
    datasets: [
      {
        barThickness: renderBarThickness(),
        data: renderData(),
        backgroundColor: renderChartColors(),
        fill: 'start',
      },
    ],
  }

  return (
    <div className='cash-inflow__chart-wrapper'>
      <Bar
        data={data}
        width={800}
        height={300}
        options={{
					maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  return formatMoney(Number(context.raw)) || ''
                },
              },
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                font: {
                  size: 14,
                  family: 'Inter',
                },
                color: '#9ca3af',
              },
            },
            y: {
              border: { dash: [12, 8] },
              grid: {
                color: 'rgba(193, 193, 193, 0.4)',
              },
              ticks: {
                font: {
                  size: 14,
                  family: 'Inter',
                },
                color: '#9ca3af',
                callback(tickValue) {
                  return `$${tickValue}`
                },
              },
            },
          },
        }}
      />

      {chartPoints.length ? null : (
        <div className='cahs-inflow__no-data'>Not enough data to display on the graph</div>
      )}
    </div>
  )
}

export default CashInflowChart
