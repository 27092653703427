import { Button, Grid } from '@mui/material'
import useAuthorization from '../../../helpers/useAuthorization'

interface IFooter {
  isDisabledSaveButton: boolean
  vendor?: any
  handleAcceptClick: () => void
  handleCancel: () => void
}

const DialogEditFooter = ({ handleCancel, isDisabledSaveButton, handleAcceptClick }: IFooter) => {
  const { isAuthorized } = useAuthorization()

  return (
    <Grid container>
      <Grid
        container
        justifyContent={{ xs: 'normal', md: 'right' }}
        alignItems={{ xs: 'normal', md: 'center' }}
        flexDirection={{ xs: 'column-reverse', md: 'row' }}
      >
        <Grid item padding={{ xs: '1rem', md: '1rem' }}>
          <Button color='primary' variant='outlined' onClick={handleCancel} fullWidth>
            {isAuthorized('write_vendor') ? 'Cancel' : 'Close'}
          </Button>
        </Grid>
        {isAuthorized('write_vendor') && (
          <Grid item padding={{ xs: '1rem 1rem 0 1rem', md: '1rem' }}>
            <Button
              disabled={isDisabledSaveButton}
              color='primary'
              variant='contained'
              onClick={handleAcceptClick}
              fullWidth
            >
              Save
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default DialogEditFooter
