import { Box, Grid, TextField } from '@mui/material'
import { validateCommonField } from '../../../helpers/utils';
import { ICardCustomerInformation } from '../../../types/types';

interface ICardHoldInformationsProps {
  customerCardInfo: ICardCustomerInformation;
  setCustomerCardInfo: (customerCardInfo: ICardCustomerInformation) => void;
  formErrors: any;
  setFormErrors: (formErrors: any) => void;
}

const CardHoldInformations = (props: ICardHoldInformationsProps) => {
  const { customerCardInfo, setCustomerCardInfo, formErrors, setFormErrors } = props;
  return (
    <Box mt={2}>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            label='First name *'
            fullWidth
            value={customerCardInfo.firstName}
            inputProps={{ inputMode: 'text', maxLength: 19, minLength: 2}}
            placeholder='Enter first name'
            sx={{ mb: 2 }}
            InputLabelProps={{ shrink: true }}
            error={!!formErrors['firstName']}
            helperText={formErrors['firstName']}
            onChange={(e) => {
              setCustomerCardInfo({ ...customerCardInfo, firstName: e.target.value })
              validateCommonField(e.target.value.replaceAll(' ', ''), 'firstName', setFormErrors, "First name", 2)
            }
          }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label='Last name *'
            fullWidth
            value={customerCardInfo.lastName}
            inputProps={{ inputMode: 'text', maxLength: 19, minLength: 2}}
            placeholder='Enter last name'
            sx={{ mb: 2 }}
            InputLabelProps={{ shrink: true }}
            error={!!formErrors['lastName']}
            helperText={formErrors['lastName']}
            onChange={(e) => {
              setCustomerCardInfo({ ...customerCardInfo, lastName: e.target.value })
              validateCommonField(e.target.value.replaceAll(' ', ''), 'lastName', setFormErrors, "Last name", 2)
            }
          }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label='Address line 1 *'
            fullWidth
            value={customerCardInfo.address1}
            inputProps={{ inputMode: 'text', maxLength: 39, minLength: 2}}
            placeholder='Building Number, Street Address, Apartment...'
            sx={{ mb: 2 }}
            InputLabelProps={{ shrink: true }}
            error={!!formErrors['address1']}
            helperText={formErrors['address1']}
            onChange={(e) => {
              setCustomerCardInfo({ ...customerCardInfo, address1: e.target.value })
              validateCommonField(e.target.value.replaceAll(' ', ''), 'address1', setFormErrors, "address 1", 2)
            }
          }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label='Address line 2'
            fullWidth
            value={customerCardInfo.address2}
            inputProps={{ inputMode: 'text', maxLength: 39, minLength: 2}}
            placeholder='Building Number, Street Address, Apartment...'
            sx={{ mb: 2 }}
            InputLabelProps={{ shrink: true }}
            error={!!formErrors['address1']}
            helperText={formErrors['address1']}
            onChange={(e) => {
              setCustomerCardInfo({ ...customerCardInfo, address2: e.target.value })
            }
          }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label='City *'
            fullWidth
            value={customerCardInfo.city}
            inputProps={{ inputMode: 'text', maxLength: 39, minLength: 2}}
            placeholder='Enter your city or locality'
            sx={{ mb: 2 }}
            InputLabelProps={{ shrink: true }}
            error={!!formErrors['city']}
            helperText={formErrors['city']}
            onChange={(e) => {
              setCustomerCardInfo({ ...customerCardInfo, city: e.target.value })
              validateCommonField(e.target.value.replaceAll(' ', ''), 'city', setFormErrors, "city", 2)
            }
          }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label='State *'
            fullWidth
            value={customerCardInfo.state}
            inputProps={{ inputMode: 'text', maxLength: 2, minLength: 2}}
            placeholder='Enter your state abbreviation'
            sx={{ mb: 2 }}
            InputLabelProps={{ shrink: true }}
            error={!!formErrors['state']}
            helperText={formErrors['state']}
            onChange={(e) => {
              setCustomerCardInfo({ ...customerCardInfo, state: e.target.value })
              validateCommonField(e.target.value.replaceAll(' ', ''), 'state', setFormErrors, "state", 2)
            }
          }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label='Postal/Zip code *'
            fullWidth
            value={customerCardInfo.zip}
            inputProps={{ inputMode: 'text', maxLength: 10, minLength: 4}}
            placeholder='Enter your zip code'
            sx={{ mb: 2 }}
            InputLabelProps={{ shrink: true }}
            error={!!formErrors['zip']}
            helperText={formErrors['zip']}
            onChange={(e) => {
              setCustomerCardInfo({ ...customerCardInfo, zip: e.target.value })
              validateCommonField(e.target.value.replaceAll(' ', ''), 'zip', setFormErrors, "zip", 4)
            }
          }
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default CardHoldInformations
