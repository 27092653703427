import CustomerSectionMobile from '../../components/customer/customer-section-mobile';
import { ICustomer, IInvoice } from '../../types/types';
import CompanySectionMobile from '../customers/company-section-mobile';

interface IManageMobile {
  customerItems: any;
  currentPublicId: string;
  handleChangeFields: (e: any, value: string, isDate?: boolean) => void;
  setIsNewCustomerDialogOpen: (isNewCustomer: boolean) => void;
  newEstimate?: any;
  newInvoice?: IInvoice;
  chosenCustomer: ICustomer | null;
  publicId: string | undefined;
  handleDeleteLine: any;
  subtotal: any;
  isProductDialogOpen: boolean;
  handleDialogClose: () => void;
  setIsProductDialogOpen: ((open: boolean) => void | undefined);
  table: any;
  isInvoice?: boolean;
}

export const ManageSectionMobile = (props: IManageMobile) => {
  const {
    customerItems,
    currentPublicId,
    handleChangeFields,
    setIsNewCustomerDialogOpen,
    newEstimate,
    newInvoice,
    chosenCustomer,
    publicId,
    table,
    isInvoice
  } = props;

  return (
    <>
      <CompanySectionMobile />
      <CustomerSectionMobile
        customerItems={customerItems}
        currentPublicId={currentPublicId}
        handleChangeFields={handleChangeFields}
        setIsNewCustomerDialogOpen={setIsNewCustomerDialogOpen}
        newEstimate={newEstimate}
        newInvoice={newInvoice}
        chosenCustomer={chosenCustomer}
        publicId={publicId}
        isInvoice={isInvoice}
      />
      {table}
    </>
  );
}

export default ManageSectionMobile;
