import { useSelector } from 'react-redux'
import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import { Doughnut } from 'react-chartjs-2'
import { AppState } from '../../../redux/store'
import { ITimePeriod } from '../../../types/types'
import { Chart as ChartJS, Tooltip, ArcElement, Title, Legend } from 'chart.js'
import AgedReceivableKpi from './kpi'
import { isUsingMobile } from '../../../helpers/utils'

ChartJS.register(ArcElement, Title, Tooltip, Legend)

const AgedReceivablesKpis = ({ mb }: { mb: number }) => {
  const isMobile = isUsingMobile()
  const agedReportsGeneral = useSelector((state: AppState) => state.reportReducer.agedReportGeneral)
  const withChartData = agedReportsGeneral?.periods.some((period: ITimePeriod) => period.amount)
  const maxInvoicesAmount =
    agedReportsGeneral &&
    agedReportsGeneral?.periods.length &&
    Math.max(...agedReportsGeneral.periods.map((data: ITimePeriod) => data.amount))

  const chartColors = ['#17A5E6', '#7919FF', '#D91668', '#FFCB33']
  const disableChartColors = ['#9CAFF4', '#8ED6E8', '#EEC390', '#FBAA97']

  const data = {
    labels: ['0-30 days', '31-60 days', '61-90 days', '90+ days'],
    datasets: [
      {
        cutout: 50,
        data: withChartData
          ? agedReportsGeneral?.periods.map((period: ITimePeriod) => period.amount)
          : [1, 1, 1, 1],
        backgroundColor: withChartData ? chartColors : disableChartColors,
        borderWidth: 0,
        maintainAspectRatio: false,
      },
    ],
  }

  return (
    <Card sx={{ boxShadow: 'none', mb }}>
      <CardContent sx={{ pb: '16px !important' }}>
        {isMobile && (
          <Typography textAlign='center' fontWeight={600} mb={2}>
            Overdue Invoices
          </Typography>
        )}
        <Grid container justifyContent='space-evenly' spacing={4}>
          <Grid item flexGrow={1}>
            <Box width={'150px'} sx={{ m: '0 auto' }}>
              <Doughnut
                data={data}
                options={{
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
            </Box>
          </Grid>
          {agedReportsGeneral && agedReportsGeneral.periods
            ? agedReportsGeneral.periods.map((period: ITimePeriod, periodIndex: number) => (
                <Grid
                  item
                  key={periodIndex + period.timePeriod}
                  flexGrow={1}
                  xs={isMobile ? 6 : undefined}
                  alignSelf='end'
                  pt='0 !important'
                  mb='1.5rem'
                >
                  <AgedReceivableKpi
                    title={period.timePeriod + (isMobile ? '' : ' Overdue')}
                    subtitle={period.quantity || 0}
                    amount={period.amount || 0}
                    maxInvoicesAmount={maxInvoicesAmount || 0}
                    isDisabled={!period.amount}
                    periodIndex={periodIndex}
                  />
                </Grid>
              ))
            : null}
        </Grid>
      </CardContent>
    </Card>
  )
}

export default AgedReceivablesKpis
