import { DataGrid, GridColDef, GridRowSpacingParams } from '@mui/x-data-grid'
import { IPayment } from '../../types/types'
import { isUsingMobile } from '../../helpers/utils'
import { useCallback, useMemo, useState } from 'react'
import { Box, Chip, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import TableStyles from '../../components/table-card/table-styles'
import { formatDate, formatMoney } from '../../helpers/formatPrice'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import useAuthorization from '../../helpers/useAuthorization'

interface PaymentsTableProps {
  payments: IPayment[]
  selectedPayment: IPayment
  onRowClick: (payment: IPayment) => void
  onSelectedPayment: ({ payment }: any) => void
  onRefundClick?: () => void
  onResendReceiptClick?: () => void
  onViewInvoiceClick?: () => void
}

const PaymentsTable = ({
  payments,
  selectedPayment,
  onRowClick,
  onSelectedPayment,
  onRefundClick,
  onResendReceiptClick,
  onViewInvoiceClick,
}: PaymentsTableProps) => {
  const isMobile = isUsingMobile()
  const { isAuthorized } = useAuthorization()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openItemMenu = Boolean(anchorEl)

  const getColorByStatus = (status: string) => {
    switch (status) {
      case 'PAID':
        return 'success'
      case 'FAILED':
        return 'error'
      case 'REFUNDED':
        return 'secondary'
      default:
        return
    }
  }

  const handleMenuButtonClick = useCallback(
    (payment: IPayment) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      onSelectedPayment({ payment })
      setAnchorEl(event.currentTarget)
    },
    [onSelectedPayment],
  )

  const columns = useMemo<GridColDef<IPayment>[]>(
    () => [
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        sortable: false,
        renderCell: (param) => {
          return isMobile ? (
            <FiberManualRecordIcon
              sx={{
                '&.MuiSvgIcon-colorSuccess': {
                  color: 'var(--success-color)',
                  borderRadius: '50%',
                  backgroundColor: 'var(--success-bg-color)',
                },
                '&.MuiSvgIcon-colorInfo': {
                  color: 'var(--info-color)',
                  borderRadius: '50%',
                  backgroundColor: 'var(--info-bg-color)',
                },
                '&.MuiSvgIcon-colorWarning': {
                  color: 'var(--warning-color)',
                  borderRadius: '50%',
                  backgroundColor: 'var(--warning-bg-color)',
                },
                '&.MuiSvgIcon-colorError': {
                  color: 'var(--error-color)',
                  borderRadius: '50%',
                  backgroundColor: 'var(--error-bg-color)',
                },
                '&.MuiSvgIcon-colorSecondary': {
                  color: 'var(--orange-color)',
                  borderRadius: '50%',
                  backgroundColor: 'var(--orange-bg-color)',
                },
                '&.MuiSvgIcon-root': {
                  margin: 'auto',
                },
              }}
              color={getColorByStatus(param.row.status || 'disabled')}
            />
          ) : (
            <Chip
              sx={{
                '&.MuiChip-colorSuccess': {
                  backgroundColor: 'var(--success-bg-color)',
                  color: 'var(--success-color)',
                },
                '&.MuiChip-colorWarning': {
                  backgroundColor: 'var(--warning-bg-color)',
                  color: 'var(--warning-color)',
                },
                '&.MuiChip-colorError': {
                  backgroundColor: 'var(--error-bg-color)',
                  color: 'var(--error-color)',
                },
                '&.MuiChip-colorInfo': {
                  backgroundColor: 'var(--info-bg-color)',
                  color: 'var(--info-color)',
                },
                '&.MuiChip-colorSecondary': {
                  backgroundColor: 'var(--orange-bg-color)',
                  color: 'var(--orange-color)',
                },
              }}
              color={getColorByStatus(param.row.status || 'default')}
              label={param.row.status}
            />
          )
        },
      },
      {
        field: 'customerName',
        headerName: 'Customer Name',
        flex: 3,
        sortable: false,
        renderCell: (param) => {
          return (
            <Box>
              <Typography>{param.row.customerName}</Typography>
              {param.row.customerEmail && !isMobile && (
                <Typography
                  component='small'
                  variant='subtitle2'
                  sx={{ color: 'var(--gray3-color)' }}
                >
                  {param.row.customerEmail}
                </Typography>
              )}
              {isMobile && (
                <Typography
                  component='small'
                  variant='subtitle2'
                  sx={{ color: 'var(--gray3-color)' }}
                >
                  {formatDate(param.row.date)}
                </Typography>
              )}
            </Box>
          )
        },
      },
      {
        field: 'invoiceDate',
        headerName: 'Invoice Date',
        flex: 2,
        sortable: false,
        renderCell: (param) => formatDate(param.row.invoice.periodStart),
      },
      {
        field: 'paymentMethod',
        headerName: 'Payment Method',
        flex: 2,
        sortable: false,
        renderCell: (param) => {
          return param.row.method === 'CREDIT_CARD' ? param.row.cardType : param.row.method
        },
      },
      {
        field: 'paymentAmount',
        headerName: 'Payment Amount',
        flex: 2,
        sortable: false,
        renderCell: (param) => formatMoney(param.row.amount),
      },
      {
        field: 'paymentDate',
        headerName: 'Payment Date',
        flex: 2,
        sortable: false,
        renderCell: (param) => formatDate(param.row.date),
      },
      {
        field: 'invoiceId',
        headerName: 'Invoice ID',
        flex: 2,
        sortable: false,
        renderCell: (param) => param.row.invoice.name,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1.5,
        sortable: false,
        renderCell: (param) => (
          <IconButton
            aria-label='more'
            id='long-button'
            aria-controls={openItemMenu ? 'long-menu' : undefined}
            aria-expanded={openItemMenu ? 'true' : undefined}
            aria-haspopup='true'
            onClick={handleMenuButtonClick(param.row)}
            sx={{
              justifyItems: 'right',
            }}
          >
            <MoreVertIcon />
          </IconButton>
        ),
      },
    ],
    [isMobile],
  )

  const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: 5,
    }
  }, [])

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleResendReceiptClick = () => {
    handleCloseMenu();
    onResendReceiptClick && onResendReceiptClick()
  }

  return (
    <>
      <DataGrid
        rows={payments}
        columns={columns}
        getRowId={(payment) => payment.publicId || ''}
        disableColumnMenu
        hideFooter
        getRowSpacing={getRowSpacing}
        rowHeight={65}
        columnHeaderHeight={24}
        onRowClick={(params) => onRowClick(params.row)}
        initialState={{
          columns: {
            columnVisibilityModel: {
              invoiceDate: !isMobile,
              paymentMethod: !isMobile,
              paymentDate: !isMobile,
              invoiceId: !isMobile,
            },
          },
        }}
        sx={TableStyles}
      />
      {selectedPayment && (
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={openItemMenu}
          onClose={handleCloseMenu}
          sx={{
            '& .MuiMenuItem-root': {
              padding: { xs: '1rem', md: '1rem 4rem 1rem 2rem' },
            },
            '& .MuiMenuItem-root:hover': {
              backgroundColor: 'var(--secondary1-color)',
              color: 'var(--primary1-color)',
            },
          }}
        >
          <MenuItem
            onClick={onRefundClick}
            disabled={
              selectedPayment.method !== 'CREDIT_CARD' ||
              selectedPayment.status === 'FAILED' ||
              selectedPayment.status === 'REFUNDED'
            }
          >
            Make a refund
          </MenuItem>
          <MenuItem
            onClick={handleResendReceiptClick}
            disabled={
              selectedPayment.method !== 'CREDIT_CARD' ||
              selectedPayment.status === 'FAILED' ||
              selectedPayment.status === 'REFUNDED'
            }
          >
            Resend Receipt
          </MenuItem>
          {isAuthorized('read_invoice') && (
            <MenuItem onClick={onViewInvoiceClick}>View invoice</MenuItem>
          )}
        </Menu>
      )}
    </>
  )
}

export default PaymentsTable
