import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { isUsingMobile } from '../../../helpers/utils'

import './import-confirm-dialog.css'
import { successToastNotification } from '../../../helpers/toastNotification'
import { generalErrorHandler } from '../../../helpers/errorHandler'
import api from '../../../api/api'

interface IImportConfirmDialog {
  isOpen: boolean
  dialogTitle?: string
  dialogBodyContent?: string
  buttonConfirmText?: string
  buttonCancelText?: string
  handleClose: () => void
  vendors: any
  handleCloseExport: () => void
}

export default function ImportConfirmDialog(props: IImportConfirmDialog) {
  const { isOpen, dialogTitle, dialogBodyContent, vendors, handleClose, handleCloseExport } = props

  const isMobile = isUsingMobile()

  const handleConfirmClick = () => {
    api
      .importVendors({ items: vendors })
      .then(() => {
        successToastNotification('Vendors imported successfuly')
      })
      .catch((err: any) => generalErrorHandler('Something went wrong, please try again'))
    handleClose()
    handleCloseExport()
  }

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
        maxWidth={isMobile ? 'sm' : 'lg'}
        fullWidth
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            padding: !isMobile ? '30px 80px' : '30px 10px',
            width: '100%',
          }}
        >
          <DialogTitle id='responsive-dialog-title'>{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div style={{ textAlign: 'center' }}>{dialogBodyContent}</div>
            </DialogContentText>
          </DialogContent>
          <DialogContent style={{ width: '100%' }}>
            <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
              <thead>
                <tr>
                  <th style={{ color: '#4D5E80', textAlign: 'center', fontWeight: 600 }}>
                    Business Name
                  </th>
                  <th style={{ color: '#4D5E80', textAlign: 'center', fontWeight: 600 }}>
                    First Name
                  </th>
                  <th style={{ color: '#4D5E80', textAlign: 'center', fontWeight: 600 }}>
                    Last Name
                  </th>
                  <th style={{ color: '#4D5E80', textAlign: 'center', fontWeight: 600 }}>
                    Address One
                  </th>
                  <th style={{ color: '#4D5E80', textAlign: 'center', fontWeight: 600 }}>
                    Address Two
                  </th>
                  <th style={{ color: '#4D5E80', textAlign: 'center', fontWeight: 600 }}>Phone</th>
                  <th style={{ color: '#4D5E80', textAlign: 'center', fontWeight: 600 }}>Mobile</th>
                  <th style={{ color: '#4D5E80', textAlign: 'center', fontWeight: 600 }}>
                    Web Site
                  </th>
                  <th style={{ color: '#4D5E80', textAlign: 'center', fontWeight: 600 }}>
                    Email Address
                  </th>
                  <th style={{ color: '#4D5E80', textAlign: 'center', fontWeight: 600 }}>Type</th>
                </tr>
              </thead>
              <tbody>
                {vendors &&
                  vendors.map((item: any, index: number) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: index % 2 === 0 ? '#F7F8FA' : '#fff',
                      }}
                    >
                      <td style={{ textAlign: 'center', fontWeight: 400 }}>{item.businessName}</td>
                      <td style={{ textAlign: 'center', fontWeight: 400 }}>{item.firstName}</td>
                      <td style={{ textAlign: 'center', fontWeight: 400 }}>{item.lastName}</td>
                      <td style={{ textAlign: 'center', fontWeight: 400 }}>{item.addressOne}</td>
                      <td style={{ textAlign: 'center', fontWeight: 400 }}>{item.addressTwo}</td>
                      <td style={{ textAlign: 'center', fontWeight: 400 }}>{item.phone}</td>
                      <td style={{ textAlign: 'center', fontWeight: 400 }}>{item.mobile}</td>
                      <td style={{ textAlign: 'center', fontWeight: 400 }}>{item.website}</td>
                      <td style={{ textAlign: 'center', fontWeight: 400 }}>{item.emailAddress}</td>
                      <td style={{ textAlign: 'center', fontWeight: 400 }}>{item.type}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </DialogContent>
          <div
            style={{
              marginTop: '5px',
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <div>
              <Button
                sx={{ ':hover': { borderWidth: '2px' } }}
                color='primary'
                variant='contained'
                onClick={handleConfirmClick}
                fullWidth
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  )
}
