import { FormControl, FormHelperText, InputLabel, MenuItem, Theme } from '@mui/material'
import Select from '@mui/material/Select'
import { SxProps } from '@mui/system'
import { IMenuItem } from '../../types/types'
import { buttonStyles, useStyles } from './style'
import { FocusEventHandler, MouseEventHandler, ReactNode } from 'react'
interface ISelectMui {
  options: IMenuItem[]
  isAddButton?: boolean
  addButtonText?: string
  disabled?: boolean
  value?: string | number
  onChange?: any
  onClick?: MouseEventHandler
  onAddIconClick?: () => void
  placeholder?: string
  label?: string
  name?: string
  sx?: SxProps<Theme>
  error?: boolean
  helperText?: ReactNode
  defaultItem?: string
  onBlur?: FocusEventHandler
  fullWidth?: boolean
}

const SelectorMui = (props: ISelectMui) => {
  const {
    options,
    isAddButton,
    addButtonText,
    disabled,
    value,
    onChange,
    onClick,
    onAddIconClick,
    placeholder,
    label,
    name,
    sx = {},
    error,
    helperText,
    defaultItem,
    onBlur,
    fullWidth,
  } = props
  const classes = useStyles()
  const items = options.map((option: IMenuItem) => (
    <MenuItem
      value={option.value}
      sx={{ color: 'var(--secondary-grey)' }}
      classes={{
        root: classes.menuItem,
        selected: classes.selectedMuiItem,
      }}
      key={option.value}
    >
      {option.title}
    </MenuItem>
  ))

  if (defaultItem) {
    items.push(
      <MenuItem
        value=''
        classes={{
          root: classes.menuItem,
          selected: classes.selectedMuiItem,
        }}
      >
        {defaultItem}
      </MenuItem>,
    )
  }

  return (
    <FormControl error={error} fullWidth={fullWidth}>
      <InputLabel id={`${name}-label`} shrink>{label}</InputLabel>
      <Select
        sx={{ color: 'var(--secondary-grey)', '& legend': { maxWidth: '100%' }, ...sx }}
        value={`${value}`}
        labelId={`${name}-label`}
        onChange={onChange}
        onClick={onClick}
        onBlur={onBlur}
        displayEmpty
        name={name}
        label={label}
        inputProps={{
          sx: {
            '& fieldset': {
              borderColor: 'var(--secondary-grey)',
            },
          },
        }}
        MenuProps={{
          classes: {
            root: classes.menuList,
            paper: classes.paperProps,
            list: classes.menuListBlock,
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        disabled={disabled}
        renderValue={
          value !== '' ? undefined : () => <div style={{ color: '#9ca3af' }}>{placeholder}</div>
        }
      >
        {items.length ? (
          items
        ) : (
          <MenuItem
            value=''
            classes={{
              root: classes.menuItem,
              selected: classes.selectedMuiItem,
            }}
          >
            None
          </MenuItem>
        )}
        {isAddButton && (
          <MenuItem value='' sx={buttonStyles} onClick={onAddIconClick}>
            {addButtonText}
          </MenuItem>
        )}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default SelectorMui
