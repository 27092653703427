import { Button, Grid } from "@mui/material"

interface IFooter {
  isDisabledSaveButton: boolean
  vendor?: any
  handleAcceptClick: () => void
  handleCancel: () => void
  dialogStep?: number
}

const DialogCreateFooter = ({
  handleCancel,
  isDisabledSaveButton,
  handleAcceptClick,
  dialogStep,
}: IFooter) => {
  return (
    <Grid container>
      <Grid
        container
        justifyContent={{ xs: 'normal', md: 'right' }}
        alignItems={{ xs: 'normal', md: 'center' }}
        flexDirection={{ xs: 'column-reverse', md: 'row' }}
      >
        <Grid item padding={{ xs: '1rem', md: '0rem' }}>
          <Button color='primary' variant='outlined' onClick={handleCancel} fullWidth>
            Cancel
          </Button>
        </Grid>
        <Grid item padding={{ xs: '1rem 1rem 0 1rem', md: '1rem' }}>
          <Button
            disabled={isDisabledSaveButton}
            color='primary'
            variant='contained'
            onClick={handleAcceptClick}
            fullWidth
          >
            {dialogStep !== 2 ? 'Next' : 'Add'}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DialogCreateFooter
