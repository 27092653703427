import { Button, Grid } from "@mui/material"

interface IModalFooter {
  dialogStep?: number
  handlePreviousClick?: () => void
  handleSaveCustomer?: () => void
  handleEnableNextButton: () => boolean
  handleClickNextButton?: () => void
}

const CustomerModalFooter = ({
  dialogStep,
  handlePreviousClick,
  handleSaveCustomer,
  handleEnableNextButton,
  handleClickNextButton,
}: IModalFooter) => {
  return (
    <Grid container>
      <Grid
        container
        justifyContent={{ xs: 'normal', md: 'right' }}
        alignItems={{ xs: 'normal', md: 'center' }}
        flexDirection={{ xs: 'column-reverse', md: 'row' }}
      >
        {dialogStep !== 0 && (
          <Grid
            item
            paddingX={{ xs: '1rem' }}
            paddingBottom={{ xs: '1rem' }}
            padding={{ md: '0rem' }}
          >
            <Button color='primary' variant='outlined' onClick={handlePreviousClick} fullWidth>
              Previous step
            </Button>
          </Grid>
        )}
        <Grid item padding='1rem'>
          {dialogStep === 3 ? (
            <Button color='primary' variant='contained' onClick={handleSaveCustomer} fullWidth>
              Save customer
            </Button>
          ) : (
            <Button
              color='primary'
              variant='contained'
              onClick={handleClickNextButton}
              fullWidth
              disabled={!handleEnableNextButton()}
            >
              Next step
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CustomerModalFooter
