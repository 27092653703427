import { SyntheticEvent, useEffect, useState } from 'react'
import BillModalFooter from './footer'
import BillModalHeaderButtons from './headerButtons'
import { Tab, Tabs } from '@mui/material'
import { isUsingMobile } from '../../helpers/utils'
import { useDispatch, useSelector } from 'react-redux'
import { IBill } from '../../types/types'
import BaseModal from '../../components/modal/modal'
import BillPreview from './billPreview'
import BillDetails from './billDetails'

interface IBillModalProps {
  bill: IBill
  showModal: boolean
  setShowModal: (showModal: boolean) => void
  onEditClick?: () => void
  onDeleteClick?: () => void
  onSendClick?: () => void
  onDuplicateClick: () => void
}

const ViewBillDialog = ({
  bill,
  showModal,
  setShowModal,
  onEditClick,
  onDeleteClick,
  onDuplicateClick,
  onSendClick,
}: IBillModalProps) => {
  const [selectedTab, setSelectedTab] = useState('view')
  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleSelectedTabChange = (event: SyntheticEvent, tab: string) => {
    setSelectedTab(tab)
  }

  return (
    <BaseModal
      title={`Bill ${bill.name ? `#${bill.name}` : '<Unkown ID>'}`}
      isModalOpen={showModal}
      handleCloseModal={handleCloseModal}
      body={
        <>
          {isUsingMobile() && (
            <Tabs
              aria-label='Bill views'
              value={selectedTab}
              scrollButtons='auto'
              variant='scrollable'
              onChange={handleSelectedTabChange}
            >
              <Tab label='View' value='view' sx={{ flexGrow: 1 }} />
              <Tab label='Detail' value='detail' sx={{ flexGrow: 1 }} />
            </Tabs>
          )}
          {selectedTab === 'view' ? (
            bill && <BillPreview bill={bill} />
          ) : (
            <BillDetails bill={bill} onEditClick={onEditClick} />
          )}
        </>
      }
      headerButtons={
        <BillModalHeaderButtons
          bill={bill}
          actions={{
            onDuplicateClick,
            onDeleteClick,
          }}
        />
      }
      footer={
        <BillModalFooter
          onCancelClick={handleCloseModal}
          onSendClick={onSendClick}
          selectedTab={selectedTab}
          onEditClick={onEditClick}
          onSelectedTabChange={(value: any) => setSelectedTab(value)}
          status={bill.status}
        />
      }
      size='large'
    />
  )
}

export default ViewBillDialog
