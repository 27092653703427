import moment from 'moment'

export const formatPrice = (price: any) => {
  if (price === 0 || price === '0') {
    return 0
  }
  return price && price != 0 ? String(price).split(',').join('') : ''
}

export const thousandPrice = (price: any) => {
  return price && price !== 0 ? price.toLocaleString() : '0.00'
}

export const formatMoney = (price: number | undefined) => {
  if (!price) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(0)
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(price)
}

export const formatDate = (dateString: string) => {
  return moment(dateString).format('MMM DD, YYYY')
}

export const formatDateFromNow = (dateString: string) => {
  return moment(dateString).fromNow()
}

export const formatPhoneNumber = (phoneNumber: any) => {
  if (!phoneNumber) {
    return ''
  }

  const neededValue = phoneNumber?.toString()

  return [neededValue.slice(0, 3), neededValue.slice(3, 6), neededValue.slice(6)].join('-')
}

export const deformatPhoneNumber = (phoneNumber: any) => {
  return String(phoneNumber).split('-').join('')
}

export const formatExpirationDate = (date: string) => {
  if (!date) {
    return ''
  }

  return `${date.slice(0, 2)}/${date.slice(4)}`
}

export const formatUSIOExpDate = (expDate: string) => {
  return `${expDate.slice(0, 2)}20${expDate.slice(3)}`
}
