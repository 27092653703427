/* eslint-disable no-unused-vars */
import { Auth } from 'aws-amplify'
import { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { SAVE_COMPANY } from '../../redux/actions/home.actions.type'
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined'
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import './sidebar.css'
import { HAVE_SUBSCRIPTION } from '../../redux/actions/auth.actions.type'
import { changeIsCompleteBusiness, changeIsOnboarding } from '../../redux/actions/auth.actions'
import useAuthorization from '../../helpers/useAuthorization'
import { AppState } from '../../redux/store'

interface ISubItem {
  subItem: ISidebarSubItem
  onSubItemClick?: (route: string) => void
}

const SubItem = (props: ISubItem) => {
  const { subItem, onSubItemClick } = props
  const navigate = useNavigate()
  const location = useLocation()
  const match = location.pathname.split('/')[1] === subItem.route
  return (
    <div
      className={`sidebar-subitem ${match ? 'subitem__active' : ''}`}
      id={`${subItem.name.split(' ').join('-')}-sidebar-subitem`}
      onClick={() => {
        onSubItemClick && onSubItemClick(subItem.route)
        navigate(`/${subItem.name === 'Profile' ? subItem.route + '/business' : subItem.route}`)
      }}
    >
      {subItem.name}
    </div>
  )
}

interface ISidebardSubItems {
  subItems: ISidebarSubItem[] | undefined
  onSubItemClick?: (route: string) => void
}

const SidebarSubItems = (props: ISidebardSubItems) => {
  const { isAuthorized } = useAuthorization()
  const { subItems, onSubItemClick } = props

  return (
    <div className='sidebar-subitems__wrapper'>
      {subItems &&
        subItems.map((subItem: ISidebarSubItem) => {
          return (
            isAuthorized(subItem.permission) && (
              <SubItem subItem={subItem} onSubItemClick={onSubItemClick} key={subItem.name} />
            )
          )
        })}
    </div>
  )
}

interface ISidebarSubItem {
  name: string
  permission: string
  route: string
}

interface ISidebarItem {
  title: string
  icon: ReactElement
  isCollapsable?: boolean
  isOpen?: boolean
  subItems?: ISidebarSubItem[] | undefined
  onClick?: () => void
  onSubItemClick?: (route: string) => void
}

interface ISidebarProps {
  open: boolean
  onSubItemClick?: (route: string) => void
}

const SidebarItem = (props: ISidebarItem) => {
  const { title, icon, isCollapsable, isOpen, subItems, onClick, onSubItemClick } = props
  const location = useLocation()
  const locationHasReports = location.pathname.split('/').includes('reports')
  const endOfRoute = locationHasReports ? 'reports' : location.pathname.split('/').pop()
  const isChosenTab =
    (subItems && subItems.some((subItem) => location.pathname.split('/')[1] === subItem.route)) ||
    endOfRoute === title.toLowerCase()
  return (
    <>
      <div
        className={`sideber-item__wrapper ${isChosenTab ? 'sidebar-item__active' : ''}`}
        onClick={onClick}
        id={`${title.split(' ').join('-')}-sidebar-item`}
      >
        <div className='sidebar-item__info'>
          {icon}
          <div className='sidebar-item__title'>{title}</div>
        </div>
        {isCollapsable ? isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> : null}
      </div>
      {isCollapsable && isOpen ? (
        <SidebarSubItems onSubItemClick={onSubItemClick} subItems={subItems} />
      ) : null}
    </>
  )
}

const Sidebar = ({ open, onSubItemClick }: ISidebarProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isOnBoarding = useSelector((state: AppState) => state.authReducer.isOnBoarding)
  const { isAuthorized, isAuthorizedByAtLeastOne } = useAuthorization()
  const [isRevenuOpen, setIsRevenueOpen] = useState<boolean>(false)
  const [isCustomersOpen, setIsCustomersOpen] = useState<boolean>(false)
  const [isBusinessOpen, setIsBusinessOpen] = useState<boolean>(false)
  const [isReferralsOpen, setIsReferralsOpen] = useState<boolean>(false)
  const [isBookkeeppingOpen, setIsBookkeepingOpen] = useState<boolean>(false)
  const [isPurchasesOpen, setIsPurchasesOpen] = useState<boolean>(false)
  const [isReportsOpen, setIsReportsOpen] = useState<boolean>(false)

  const handleRevenueClick = () => {
    setIsCustomersOpen(false)
    setIsBusinessOpen(false)
    setIsReferralsOpen(false)
    setIsRevenueOpen(!isRevenuOpen)
    setIsBookkeepingOpen(false)
    setIsPurchasesOpen(false)
    setIsReportsOpen(false)
  }

  const handleCustomersClick = () => {
    setIsCustomersOpen(!isCustomersOpen)
    setIsBusinessOpen(false)
    setIsRevenueOpen(false)
    setIsReferralsOpen(false)
    setIsBookkeepingOpen(false)
    setIsPurchasesOpen(false)
    setIsReportsOpen(false)
  }

  const handleBusinessClick = () => {
    setIsRevenueOpen(false)
    setIsCustomersOpen(false)
    setIsReferralsOpen(false)
    setIsBusinessOpen(!isBusinessOpen)
    setIsBookkeepingOpen(false)
    setIsPurchasesOpen(false)
    setIsReportsOpen(false)
  }

  const handleReferralsClick = () => {
    setIsRevenueOpen(false)
    setIsCustomersOpen(false)
    setIsReferralsOpen(!isReferralsOpen)
    setIsBusinessOpen(false)
    setIsBookkeepingOpen(false)
    setIsPurchasesOpen(false)
    setIsReportsOpen(false)
  }

  const handleBookkeepingClick = () => {
    setIsRevenueOpen(false)
    setIsCustomersOpen(false)
    setIsReferralsOpen(false)
    setIsBusinessOpen(false)
    setIsBookkeepingOpen(!isBookkeeppingOpen)
    setIsPurchasesOpen(false)
    setIsReportsOpen(false)
  }

  const handlePurchasesClick = () => {
    setIsRevenueOpen(false)
    setIsCustomersOpen(false)
    setIsReferralsOpen(false)
    setIsBusinessOpen(false)
    setIsPurchasesOpen(!isPurchasesOpen)
    setIsBookkeepingOpen(false)
    setIsReportsOpen(false)
  }

  const handleReportsClick = () => {
    setIsRevenueOpen(false)
    setIsCustomersOpen(false)
    setIsReferralsOpen(false)
    setIsBusinessOpen(false)
    setIsPurchasesOpen(false)
    setIsBookkeepingOpen(false)
    setIsReportsOpen(!isReportsOpen)
  }

  const resetReduxOnboardingData = () => {
    dispatch(changeIsCompleteBusiness(false))
    dispatch(changeIsOnboarding(true))
    dispatch({
      type: HAVE_SUBSCRIPTION,
      payload: false,
    })
  }

  async function signOut() {
    try {
      await Auth.signOut()
      sessionStorage.removeItem('token')
      dispatch({
        type: SAVE_COMPANY,
        payload: null,
      })
      resetReduxOnboardingData()
      navigate('/sign-in')
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className='sidebar__wrapper'>
      <div className={open ? 'sidebar__open' : undefined}>
        <div className='sidebar__items-wrapper'>
          {isAuthorized('read_invoice') && (
            <SidebarItem
              title='Dashboard'
              icon={<DashboardCustomizeOutlinedIcon />}
              onClick={() => navigate(`/dashboard`)}
              onSubItemClick={onSubItemClick}
            />
          )}
          {isAuthorizedByAtLeastOne(['read_estimate', 'read_invoice', 'read_payments']) && (
            <SidebarItem
              title='Revenue'
              icon={<AccountBalanceWalletOutlinedIcon />}
              isCollapsable
              isOpen={isRevenuOpen}
              subItems={[
                { name: 'Estimates', permission: 'read_estimate', route: 'estimates' },
                { name: 'Invoices', permission: 'read_invoice', route: 'invoices' },
                {
                  name: 'Recurring invoices',
                  permission: 'read_invoice',
                  route: 'recurring-invoices',
                },
                { name: 'Payments', permission: 'read_payments', route: 'payments' },
              ]}
              onClick={handleRevenueClick}
              onSubItemClick={onSubItemClick}
            />
          )}
          {isAuthorizedByAtLeastOne(['read_customer', 'read_invoice']) && (
            <SidebarItem
              title='Customers'
              icon={<GroupAddOutlinedIcon />}
              isCollapsable
              isOpen={isCustomersOpen}
              subItems={[
                { name: 'Customers', permission: 'read_customer', route: 'customers' },
                {
                  name: 'Customer Statements',
                  permission: 'read_invoice',
                  route: 'customer-statements',
                },
              ]}
              onClick={handleCustomersClick}
              onSubItemClick={onSubItemClick}
            />
          )}
          {isAuthorizedByAtLeastOne(['read_purchase_product', 'read_vendor']) && (
            <SidebarItem
              title='Purchases'
              icon={<ShoppingCartOutlinedIcon />}
              isCollapsable
              isOpen={isPurchasesOpen}
              subItems={[
                {
                  name: 'Products & Services',
                  permission: 'read_purchase_product',
                  route: 'purchase-products',
                },
                { name: 'Bills', permission: 'read_bill', route: 'bills' },
                { name: 'Vendors', permission: 'read_vendor', route: 'vendors' },
              ]}
              onClick={handlePurchasesClick}
              onSubItemClick={onSubItemClick}
            />
          )}
          {isAuthorized('read_financial_account') && (
            <SidebarItem
              title='Bookkeeping'
              icon={<AccountBalanceIcon />}
              isCollapsable
              isOpen={isBookkeeppingOpen}
              subItems={[
                {
                  name: 'Chart of accounts',
                  permission: 'read_financial_account',
                  route: 'chart-of-accounts',
                },
                {
                  name: 'Journal Entries',
                  permission: 'read_journal_entry',
                  route: 'journal-entries',
                },
              ]}
              onClick={handleBookkeepingClick}
              onSubItemClick={onSubItemClick}
            />
          )}
          {isAuthorized('read_invoice') && (
            <SidebarItem
              title='Reports'
              icon={<AssignmentOutlinedIcon />}
              isCollapsable
              isOpen={isReportsOpen}
              subItems={[
                {
                  name: 'Customers Report',
                  permission: 'read_invoice',
                  route: 'customers-reports',
                },
                {
                  name: 'Financial Statements',
                  permission: 'read_invoice',
                  route: 'financial-statements',
                },
                // {
                //   name: 'Detailed Reporting',
                //   permission: 'read_invoice',
                //   route: 'detailed-reporting',
                // },
                {
                  name: 'Account Transactions',
                  permission: 'read_invoice',
                  route: 'account-transaction',
                },
              ]}
              onClick={handleReportsClick}
              onSubItemClick={onSubItemClick}
            />
          )}
          {isAuthorized('manage_referrals') && !isAuthorized('manage_users') && (
            <SidebarItem
              title='Referrals'
              icon={<GroupAddOutlinedIcon />}
              isCollapsable
              isOpen={isReferralsOpen}
              subItems={[
                { name: 'Invitations', permission: 'manage_referrals', route: 'referrals' },
              ]}
              onClick={handleReferralsClick}
              onSubItemClick={onSubItemClick}
            />
          )}
          {isAuthorizedByAtLeastOne([
            'default',
            'read_product',
            'manage_users',
            'write_company',
          ]) && (
            <SidebarItem
              title='Business'
              icon={<WorkOutlineOutlinedIcon />}
              isCollapsable
              isOpen={isBusinessOpen}
              subItems={[
                { name: 'Business Info', permission: 'default', route: 'business' },
                { name: 'Products & Services', permission: 'read_product', route: 'products' },
                { name: 'Roles & Permissions', permission: 'manage_users', route: 'roles' },
                { name: 'Users', permission: 'manage_users', route: 'users' },
                { name: 'Preferences', permission: 'write_company', route: 'preferences' },
                { name: 'Referrals', permission: 'manage_referrals', route: 'referrals' },
              ]}
              onClick={handleBusinessClick}
              onSubItemClick={onSubItemClick}
            />
          )}
          {isOnBoarding && (
            <SidebarItem
              title='Sign out'
              icon={<ExitToAppIcon />}
              onSubItemClick={onSubItemClick}
              onClick={() => signOut()}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Sidebar
