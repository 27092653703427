import {
  Box,
  Button,
  Grid,
  Step,
  StepContent,
  StepLabel,
  TextField,
  Typography,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import { ChangeEvent, useEffect, useState } from 'react'
import { isUsingMobile } from '../../../helpers/utils'
import { IScheduledInvoice, IScheduler } from '../../../types/types'
import {
  dayOfMonthOptions,
  dayOfWeekMenuItems,
  daysOfMonth,
  daysOfWeek,
  endTypeOptions,
  monthOptions,
  months,
  typeOptions,
} from '../helper'
import moment from 'moment'
import SelectorMui from '../../../components/selector-mui'
import { DEFAULT_DATE_FORMAT, USIO_DATE_FORMAT } from '../../../helpers/constants'
import api from '../../../api/api'
import { toast } from 'react-toastify'
import { errorHandler } from '../../../helpers/errorHandler'
import DateField from '../../../components/date-field'
import { useDispatch } from 'react-redux'
import { saveScheduledInvoiceTemplates } from '../../../redux/actions/home.actions'
import useAuthorization from '../../../helpers/useAuthorization'

interface ScheduleStepProps {
  scheduledInvoice: IScheduledInvoice
  setScheduler: (scheduler: IScheduler) => void
}

const ScheduleStep = ({ scheduledInvoice, setScheduler }: ScheduleStepProps) => {
  const isMobile = isUsingMobile()
  const [isEditMode, setIsEditMode] = useState(false)
  const dispatch = useDispatch()
  const { isAuthorized } = useAuthorization()

  const [newScheduler, setNewScheduler] = useState<any>({
    custom: false,
    customRepetition: 1,
    day: moment().date(),
    dayOfWeek: moment().day(),
    endDate: '',
    executionsPlaned: 1,
    firstExecutionDate: moment().format(DEFAULT_DATE_FORMAT),
    month: moment().month() + 1,
    invoicePublicId: '',
    lastExecutionDate: moment().format(DEFAULT_DATE_FORMAT),
    nextExecutionDate: moment().format(DEFAULT_DATE_FORMAT),
    timeZone: 'America/New_York',
    endType: 'NEVER',
    type: 'DAILY',
  })

  const renderSchedulerData = (scheduler: IScheduler | null) => {
    if (!scheduler) {
      return ''
    }

    switch (scheduler.type) {
      case 'WEEKLY':
        return `every ${daysOfWeek[String(scheduler.dayOfWeek)]}`
      case 'MONTHLY':
        return `on the ${daysOfMonth[String(scheduler.day)]} day of the month`
      case 'YEARLY':
        return `every ${months[String(scheduler.month)]} on the ${
          daysOfMonth[String(scheduler.day)]
        } day of the month`
      default:
        return 'every day of the week'
    }
  }

  const calculateStartDate = (
    type: string = newScheduler.type,
    day: number = newScheduler.day,
    dayOfWeek: number = newScheduler.dayOfWeek,
    month: number = newScheduler.month,
  ) => {
    const currentDate = moment().date()
    const currentMonth = moment().month()
    const currentYear = moment().year()
    switch (type) {
      case 'WEEKLY': {
        const today = moment().day()
        if (today <= dayOfWeek) {
          return moment().day(dayOfWeek).format(DEFAULT_DATE_FORMAT)
        } else {
          return moment().add(1, 'weeks').day(dayOfWeek).format(DEFAULT_DATE_FORMAT)
        }
      }
      case 'MONTHLY': {
        if (day >= currentDate) {
          return moment().date(day).format(DEFAULT_DATE_FORMAT)
        } else {
          return moment()
            .date(day)
            .month(currentMonth + 1)
            .format(DEFAULT_DATE_FORMAT)
        }
      }
      case 'YEARLY': {
        const requestedDate = moment()
          .year(currentYear)
          .month(month - 1)
          .date(day)
          .format(DEFAULT_DATE_FORMAT)
        if (moment().isAfter(requestedDate)) {
          return moment(requestedDate)
            .year(currentYear + 1)
            .format(DEFAULT_DATE_FORMAT)
        } else {
          return requestedDate
        }
      }
      default:
        return moment().format(DEFAULT_DATE_FORMAT)
    }
  }

  const handleEditClick = () => {
    setIsEditMode(true)
  }

  const handleSaveSchedulerClick = () => {
    const newSchedulerRequest = {
      ...newScheduler,
      invoicePublicId: scheduledInvoice.publicId,
      type: newScheduler.type,
      firstExecutionDate: moment(newScheduler.firstExecutionDate).format(USIO_DATE_FORMAT),
      day: moment(newScheduler.firstExecutionDate).date(),
    }

    if (scheduledInvoice.scheduler) {
      api
        .editScheduler(scheduledInvoice.scheduler?.publicId, newSchedulerRequest)
        .then(() => {
          toast.success('Scheduler was successfully edited', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          })
          setIsEditMode(false)
          setScheduler(newSchedulerRequest)
          dispatch(saveScheduledInvoiceTemplates())
        })
        .catch((err) => errorHandler(err))
    } else {
      api
        .createScheduler(newSchedulerRequest)
        .then((res) => {
          toast.success('Scheduler was successfully created', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          })
          setIsEditMode(false)
          dispatch(saveScheduledInvoiceTemplates())
          setScheduler(res.data.body)
        })
        .catch((err) => errorHandler(err))
    }
  }

  useEffect(() => {
    if (scheduledInvoice.scheduler) {
      setNewScheduler({
        ...scheduledInvoice.scheduler,
      })
    }
  }, [scheduledInvoice.scheduler])

  useEffect(() => {
    if (newScheduler.endType === 'ON' && !newScheduler.endDate) {
      newScheduler.endDate = moment()
    } else if (newScheduler.endType !== 'ON') {
      newScheduler.endDate = undefined
    }
  }, [newScheduler.endType])

  return (
    <Step key='subscription' expanded={true}>
      <StepLabel
        icon={
          scheduledInvoice.scheduler ? (
            <CheckCircleIcon color='success' />
          ) : (
            <CircleOutlinedIcon color='success' />
          )
        }
      >
        Recurring invoice
      </StepLabel>
      <StepContent>
        {isEditMode ? (
          <Box>
            <SelectorMui
              label='Repeat this invoice'
              placeholder='Repeat this invoice'
              options={typeOptions}
              value={newScheduler?.type}
              onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                setNewScheduler({ ...newScheduler, type: e.target.value })
              }
              sx={{ mb: 2 }}
            />
            {newScheduler.type === 'WEEKLY' && (
              <SelectorMui
                label='Choose a day'
                placeholder='Choose a day'
                options={dayOfWeekMenuItems}
                value={newScheduler?.dayOfWeek}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  setNewScheduler({
                    ...newScheduler,
                    firstExecutionDate: calculateStartDate(
                      newScheduler.type,
                      newScheduler.day,
                      Number(e.target.value),
                      newScheduler.month,
                    ),
                    dayOfWeek: e.target.value,
                  })
                }
                sx={{ mb: 2 }}
              />
            )}
            {(newScheduler.type === 'YEARLY' || newScheduler.type === 'MONTHLY') && (
              <Grid container spacing={1} mb={2}>
                {newScheduler.type === 'YEARLY' && (
                  <Grid item xs={6}>
                    <SelectorMui
                      label='Choose month'
                      placeholder='Choose month'
                      options={monthOptions}
                      value={Number(newScheduler.month)}
                      onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                        setNewScheduler({
                          ...newScheduler,
                          firstExecutionDate: calculateStartDate(
                            newScheduler.type,
                            newScheduler.day,
                            newScheduler.dayOfWeek,
                            Number(e.target.value),
                          ),
                          month: e.target.value,
                        })
                      }
                    />
                  </Grid>
                )}
                {(newScheduler.type === 'MONTHLY' || newScheduler.type === 'YEARLY') && (
                  <Grid item xs={6}>
                    <SelectorMui
                      label='Choose date'
                      placeholder='Choose date'
                      options={dayOfMonthOptions}
                      value={Number(newScheduler.day)}
                      onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                        setNewScheduler({
                          ...newScheduler,
                          firstExecutionDate: calculateStartDate(
                            newScheduler.type,
                            Number(e.target.value),
                            newScheduler.dayOfWeek,
                            newScheduler.month,
                          ),
                          day: e.target.value,
                        })
                      }
                    />
                  </Grid>
                )}
              </Grid>
            )}
            <DateField
              label='First invoice on'
              value={newScheduler.firstExecutionDate}
              fullWidth
              onChange={(date: string) => {
                setNewScheduler({ ...newScheduler, firstExecutionDate: date })
              }}
              sx={{ mb: 2 }}
            />
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <SelectorMui
                  label='End date'
                  placeholder='End date'
                  options={endTypeOptions}
                  value={newScheduler?.endType}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                    setNewScheduler({ ...newScheduler, endType: e.target.value })
                  }
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={6}>
                {newScheduler.endType === 'AFTER' && (
                  <TextField
                    fullWidth
                    label='Number of invoices'
                    placeholder='Enter number of invoices'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={newScheduler.executionsPlaned}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setNewScheduler({ ...newScheduler, executionsPlaned: e.target.value })
                    }
                  />
                )}
                {newScheduler.endType === 'ON' && (
                  <DateField
                    fullWidth
                    label='End date'
                    placeholder='Select a date'
                    value={newScheduler.endDate}
                    onChange={(date: string) =>
                      setNewScheduler({
                        ...newScheduler,
                        endDate: moment(date).format(USIO_DATE_FORMAT),
                      })
                    }
                  />
                )}
              </Grid>
            </Grid>
            <Button
              variant='outlined'
              onClick={() => {
                setIsEditMode(false)
              }}
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button variant='contained' onClick={handleSaveSchedulerClick}>
              Save schedule
            </Button>
          </Box>
        ) : (
          <Grid container spacing={!isMobile ? 1 : undefined}>
            <Grid item sm={9}>
              {scheduledInvoice.scheduler && (
                <>
                  <Typography>
                    <Typography component='span' fontWeight='bold'>
                      {`Repeat ${scheduledInvoice.scheduler?.type.toLowerCase()}:`}
                    </Typography>{' '}
                    {renderSchedulerData(scheduledInvoice.scheduler)}
                  </Typography>
                  <Typography>
                    <Typography component='span' fontWeight='bold'>
                      Last invoice:
                    </Typography>{' '}
                    {scheduledInvoice.scheduler?.endDate
                      ? moment(scheduledInvoice.scheduler?.endDate).format('MMM DD, YYYY')
                      : '-'}
                  </Typography>
                </>
              )}
            </Grid>
            <Grid item sm={3}>
              {isAuthorized('write_invoice') && (
                <Button
                  sx={{ mt: { xs: 2, sm: 0 } }}
                  variant='outlined'
                  onClick={handleEditClick}
                  fullWidth
                  disabled={scheduledInvoice.status === 'ENDED'}
                >
                  {scheduledInvoice.scheduler ? 'Edit schedule' : 'Create schedule'}
                </Button>
              )}
            </Grid>
          </Grid>
        )}
      </StepContent>
    </Step>
  )
}

export default ScheduleStep
