import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRowSpacingParams,
  GridTreeNode,
} from '@mui/x-data-grid'
import { useDispatch, useSelector } from 'react-redux'
import { IReferralsData } from '../../types/types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import TableStyles from '../../components/table-card/table-styles'
import '../../components/table-card/table-card.css'
import { Box, Chip, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { formatDate } from '../../helpers/formatPrice'
import { isUsingMobile } from '../../helpers/utils'
import { AppState } from '../../redux/store'
import Loader from '../../components/loader/loader'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { saveReferrals } from '../../redux/actions/home.actions'

interface ReferralsTableTableProps {
  onDeleteClick?: () => void
  onResendClick?: () => void
  onRowClick: () => void
  setReferralSelected: (values: any) => void
}

const getColorByStatus = (status: string) => {
  switch (status) {
    case 'SENT':
      return 'warning'
    case 'PENDING':
      return 'error'
    case 'SUBSCRIBED':
      return 'success'
  }
}

const ReferralsTable = ({
  onDeleteClick,
  onResendClick,
  onRowClick,
  setReferralSelected,
}: ReferralsTableTableProps) => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [loading, setLoading] = useState(true)

  const referralsData = useSelector((state: AppState) => state.authReducer.referralsData).content

  const openItemMenu = Boolean(anchorEl)
  const isMobile = isUsingMobile()

  const isReferralsActive = (referralsData: any) => {
    return (
      referralsData &&
      referralsData.length > 0 &&
      referralsData[0].scheduler?.executable !== undefined &&
      referralsData[0].scheduler?.executable
    )
  }

  const isActive = isReferralsActive(referralsData)

  const handleMenuButtonClick = useCallback(
    (row: any) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      setReferralSelected(row)
      setAnchorEl(event.currentTarget)
    },
    [],
  )

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleDeleteClick = () => {
    handleCloseMenu()
    onDeleteClick && onDeleteClick()
  }

  const handleResendClick = () => {
    handleCloseMenu()
    onResendClick && onResendClick()
  }

  const handleView = () => {
    handleCloseMenu()
    onRowClick && onRowClick()
  }

  const handleRowClick = (row: any) => {
    setReferralSelected(row)
    onRowClick && onRowClick()
  }

  const columns = useMemo<GridColDef<IReferralsData>[]>(
    () => [
      {
        field: 'status',
        headerName: 'Status',
        flex: !isMobile ? 2 : 1,
        sortable: false,
        renderCell: (param) => (
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            {isMobile ? (
              <FiberManualRecordIcon
                sx={{
                  '&.MuiSvgIcon-colorSuccess': {
                    color: 'var(--success-color)',
                    borderRadius: '50%',
                    backgroundColor: 'var(--success-bg-color)',
                  },
                  '&.MuiSvgIcon-colorWarning': {
                    color: 'var(--warning-color)',
                    borderRadius: '50%',
                    backgroundColor: 'var(--warning-bg-color)',
                  },
                  '&.MuiSvgIcon-colorError': {
                    color: 'var(--error-color)',
                    borderRadius: '50%',
                    backgroundColor: 'var(--error-bg-color)',
                  },
                  '&.MuiSvgIcon-root': {
                    margin: 'auto',
                  },
                }}
                color={getColorByStatus(param.row.status || '')}
              />
            ) : (
              <Chip
                sx={{
                  '&.MuiChip-colorSuccess': {
                    backgroundColor: 'var(--success-bg-color)',
                    color: 'var(--success-color)',
                  },
                  '&.MuiChip-colorWarning': {
                    backgroundColor: 'var(--warning-bg-color)',
                    color: 'var(--warning-color)',
                  },
                  '&.MuiChip-colorError': {
                    backgroundColor: 'var(--secondary-grey-bg)',
                    color: 'var(--secondary-grey)',
                  },
                }}
                color={getColorByStatus(param.row.status || '')}
                label={param.row.status}
              />
            )}
          </div>
        ),
      },
      {
        field: 'referredName',
        headerName: 'Referred Name',
        flex: !isMobile ? 2 : 4,
        sortable: false,
        renderCell: (param) => {
          return (
            <Box>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography>{`${param.row.firstName} ${param.row.lastName}`}</Typography>
                {!isMobile && <Typography style={{ color: '#99A6BF', fontSize: '14px' }}>
                  {param.row.emailAddress}
                </Typography>}
              </div>
            </Box>
          )
        },
      },
      {
        field: 'dates',
        headerName: 'Dates',
        flex: !isMobile ? 2 : 3,
        sortable: false,
        renderCell: (param) => {
          return (
            <Box>
              {param.row.status === 'SUBSCRIBED' && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography style={{ color: '#99A6BF', fontSize: '14px' }}>
                    Subscribed on
                  </Typography>
                  <Typography>{formatDate(param.row.subscriptionDate || '')}</Typography>
                </div>
              )}
              {param.row.status === 'SENT' && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography style={{ color: '#99A6BF', fontSize: '14px' }}>Invited on</Typography>
                  <Typography>{formatDate(param.row.sentDate || '')}</Typography>
                </div>
              )}
              {param.row.status === 'PENDING' && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography style={{ color: '#99A6BF', fontSize: '14px' }}>Created at</Typography>
                  <Typography>{formatDate(param.row.createdAt || '')}</Typography>
                </div>
              )}
            </Box>
          )
        },
      },
      {
        field: 'actions',
        headerName: ' ',
        flex: !isMobile ? 1 : 1.5,
        sortable: false,
        renderCell: (param) => (
          <IconButton
            aria-label='more'
            id='long-button'
            aria-controls={openItemMenu ? 'long-menu' : undefined}
            aria-expanded={openItemMenu ? 'true' : undefined}
            aria-haspopup='true'
            onClick={handleMenuButtonClick(param.row)}
            sx={{
              justifyItems: 'center',
            }}
          >
            <MoreVertIcon />
          </IconButton>
        ),
      },
    ],
    [handleMenuButtonClick, openItemMenu, isMobile, isActive],
  )

  const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: 5,
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        await dispatch(saveReferrals())
        setLoading(false)
      } catch (error) {
        console.error('Error fetching Referrals data', error)
        setLoading(false)
      }
    }

    fetchData()
  }, [dispatch])

  return (
    <>
      {!loading ? (
        <>
          <DataGrid
            rows={referralsData}
            columns={columns}
            disableColumnMenu
            getRowId={(referralsData) => referralsData.publicId || ''}
            hideFooter
            getRowSpacing={getRowSpacing}
            rowHeight={65}
            onRowClick={(params) => handleRowClick(params.row)}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  name: !isMobile,
                  createdAt: !isMobile,
                  customerEmail: !isMobile,
                },
              },
            }}
            getCellClassName={(
              params: GridCellParams<any, IReferralsData, IReferralsData, GridTreeNode>,
            ) => {
              return params.field !== 'actions' ? 'rowStyle' : ''
            }}
            sx={TableStyles}
          />
          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={openItemMenu}
            onClose={handleCloseMenu}
            sx={{
              '& .MuiMenuItem-root': {
                padding: { xs: '1rem', md: '1rem 4rem 1rem 2rem' },
              },
              '& .MuiMenuItem-root:hover': {
                backgroundColor: 'var(--secondary1-color)',
                color: 'var(--primary1-color)',
              },
            }}
          >
            <MenuItem onClick={handleView}>Edit</MenuItem>
            <MenuItem onClick={handleResendClick}>Resend email</MenuItem>

            <MenuItem sx={{ color: 'var(--error-color)' }} onClick={handleDeleteClick}>
              Delete
            </MenuItem>
          </Menu>
        </>
      ) : (
        <Loader />
      )}
    </>
  )
}

export default ReferralsTable
