import {
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import DeleteButtonTable from '../../../components/delete-icon/deleteIcon'
import { INewJournalEntryItem, INewJournalEntry } from '../../../types/types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { saveFinancialAccount } from '../../../redux/actions/home.actions'
import { AppState } from '../../../redux/store'

interface JournalFormCardsProps {
  journal: INewJournalEntry
  onChangeAccountItem: any
  onDeleteItem: any
}

const JournalFormCards = ({
  journal,
  onChangeAccountItem,
  onDeleteItem,
}: JournalFormCardsProps) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const [options, setOptions] = useState<any[]>([])
  const financialAccount = useSelector((state: AppState) => state.homeReducer.financialAccount)

  useEffect(() => {
    dispatch(saveFinancialAccount())
  }, [dispatch])

  useEffect(() => {
    if (financialAccount && financialAccount.content) {
      const categorizedAccounts = financialAccount.content.reduce((acc: any, account: any) => {
        if (!acc[account.type.category]) {
          acc[account.type.category] = []
        }
        acc[account.type.category].push(account)
        return acc
      }, {})

      const newOptions = Object.entries(categorizedAccounts).map(([category, accounts]) => ({
        label: category,
        options: Array.isArray(accounts)
          ? accounts.map((acc) => ({
              value: acc.publicId,
              label: acc.name,
            }))
          : [],
      }))

      setOptions(newOptions)
      setIsLoading(false)
    }
  }, [financialAccount])

  return (
    <>
      {journal.items.map((item: INewJournalEntryItem, index: number) => {
        const disableDebit = !!item.debitAmount
        const disableCredit = !!item.creditAmount
        return (
          <Card
            key={index}
            sx={{ '&.MuiPaper-root': { boxShadow: '0 5px 10px 0 var(--secondary-grey-bg)' } }}
          >
            <CardContent>
              <Grid container spacing={2} flexDirection='column'>
                <Grid item>
                  {isLoading ? (
                    <div></div>
                  ) : (
                    <FormControl sx={{ width: '100%' }}>
                      <InputLabel id='account-label'>
                        Select an account
                      </InputLabel>
                      <Select
                        labelId='account-label'
                        label='Select an account'
                        required
                        fullWidth
                        value={item.accountPublicId ? item.accountPublicId : ''}
                        onChange={onChangeAccountItem(index, 'accountPublicId')}
                      >
                        {options.map((category) => [
                          <MenuItem
                            key={category.label}
                            disabled
                            style={{ fontWeight: 'bold', color: 'black', opacity: 1 }}
                          >
                            {category.label}
                          </MenuItem>,
                          ...category.options.map((option: any) => (
                            <MenuItem
                              key={option.label}
                              value={option.value}
                              style={{ marginLeft: '5px' }}
                            >
                              {option.label}
                            </MenuItem>
                          )),
                        ])}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
                <Grid item>
                  <TextField
                    label='Description'
                    placeholder='Enter a description'
                    value={item.description}
                    onChange={onChangeAccountItem(index, 'description')}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label='Debit'
                      placeholder='0'
                      type='number'
                      value={item.debitAmount !== 0 ? item.debitAmount : undefined}
                      onChange={onChangeAccountItem(index, 'debitAmount')}
                      disabled={disableCredit}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label='Credit'
                      placeholder='0'
                      type='number'
                      value={item.creditAmount !== 0 ? item.creditAmount : undefined}
                      onChange={onChangeAccountItem(index, 'creditAmount')}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      disabled={disableDebit}
                    />
                  </Grid>
                </Grid>

                <Grid item container flexDirection='row-reverse'>
                  <DeleteButtonTable handleOnDeleted={onDeleteItem(index)}></DeleteButtonTable>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )
      })}
    </>
  )
}

export default JournalFormCards
