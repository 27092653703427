import { Alert, Box, Button, Grid, InputAdornment, TextField } from '@mui/material'
import { SyntheticEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import api from '../../../api/api'
import visibilityOffIcon from '../../../assets/icons/visibility-off.png'
import visibilityIcon from '../../../assets/icons/visibility.png'
import { errorHandler, generalErrorHandler } from '../../../helpers/errorHandler'
import { saveCurrentUser } from '../../../redux/actions/auth.actions'
import ListFilters from '../../../components/list-filters'
import { AppState } from '../../../redux/store'
import './settings.css'
import ContentHeader from '../../../components/content-header'
import { isUsingMobile } from '../../../helpers/utils'
import RegistrationField from '../../../components/registration-field'
import { VALID_PASSWORD } from '../../../helpers/constants'
import { useNavigate } from 'react-router-dom'
import usaFlagIcon from '../../../assets/icons/usa-flag.svg'
import FlagIcon from '@mui/icons-material/Flag'
import { Auth } from 'aws-amplify'
import { changeIsLoading } from '../../../redux/actions/home.actions'
import { height } from '@mui/system'

const Settings = () => {
  const currentUser = useSelector((state: AppState) => state.authReducer.cheddarUser)
  const dispatch = useDispatch()
  const [state, setState] = useState({
    name: '',
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  })
  const [selectedPage, setSelectedPage] = useState('Profile')
  const [codeSended, setCodeSended] = useState(false)
  const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState({
    oldPassword: '',
    password: '',
    confirmPassword: '',
    code: '',
  })
  const [localUserData, setLocalUserData] = useState({
    name: currentUser?.name || '',
    phone: currentUser?.phone || '',
    city: currentUser?.city || '',
    state: currentUser?.state || '',
    postalCode: currentUser?.postalCode || '',
  })

  const handleFieldChange = (field: any, value: any) => {
    setLocalUserData({
      ...localUserData,
      [field]: value,
    })
  }

  const navigate = useNavigate()

  const isMobile = isUsingMobile()

  const handleClickVisibilityCurrentIcon = () => {
    setShowCurrentPassword(!showCurrentPassword)
  }

  const handleClickVisibilityIcon = () => {
    setShowPassword(!showPassword)
  }

  const handleClickConfirmIcon = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const handleChangeValues = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setInitialValues({
      ...initialValues,
      [value]: e.target.value,
    })
  }

  useEffect(() => {
    dispatch(saveCurrentUser())
  }, [])

  useEffect(() => {
    if (currentUser) {
      setState({
        ...state,
        name: currentUser.name,
      })
    }
  }, [currentUser])

  const handleSelectedFilterChange = (event: SyntheticEvent, page: string) => {
    !codeSended
      ? setSelectedPage(page)
      : page === 'Password'
      ? setSelectedPage('Reset')
      : setSelectedPage(page)
  }

  const isResetPasswordButtonEnabled = () => {
    return (
      initialValues.password.trim() !== '' &&
      initialValues.confirmPassword.trim() !== '' &&
      VALID_PASSWORD.test(initialValues.password) &&
      VALID_PASSWORD.test(initialValues.confirmPassword)
    )
  }

  const handleUpdateUser = () => {
    if (currentUser) {
      api
        .updateUser(currentUser.publicId, {
          email: currentUser?.email,
          name: localUserData.name !== '' ? localUserData.name : currentUser?.name,
          phone: localUserData.phone !== '' ? localUserData.phone : currentUser?.phone,
          city: localUserData.city !== '' ? localUserData.city : currentUser?.city,
          state: localUserData.state !== '' ? localUserData.state : currentUser?.state,
          postalCode:
            localUserData.postalCode !== '' ? localUserData.postalCode : currentUser?.postalCode,
          rolePublicId: currentUser?.role.publicId,
        })
        .then(() => {
          toast.success(`User name was successfully edited`, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          })
          dispatch(saveCurrentUser())
        })
        .catch((err) => errorHandler(err))
    }
  }

  const sendCode = () => {
    dispatch(changeIsLoading(true))
    Auth.forgotPassword(currentUser?.email)
      .then((res) => {
        dispatch(changeIsLoading(false))
        setSelectedPage('Reset')
        setCodeSended(true)
      })
      .catch((error) => {
        dispatch(changeIsLoading(false))
        toast.error(error?.message || 'An error occurred', {
          autoClose: 3000,
          hideProgressBar: true,
          className: 'error-toast',
        })
      })
  }

  const resetValues = () => {
    setInitialValues({
      code: '',
      password: '',
      oldPassword: '',
      confirmPassword: '',
    })
  }

  const handleResetPasswordClick = () => {
    if (initialValues.password !== initialValues.confirmPassword) {
      toast.error(`Passwords mismatch, please try again.`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      })
      return
    }
    dispatch(changeIsLoading(true))
    Auth.forgotPasswordSubmit(currentUser?.email, initialValues.code, initialValues.password)
      .then((res) => {
        dispatch(changeIsLoading(false))
        toast.success(`The password was reset successfully`, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        })
        resetValues()
        setCodeSended(false)
      })
      .catch((error) => {
        dispatch(changeIsLoading(false))
        generalErrorHandler(error.message)
      })
  }

  async function changePassword(oldPassword: string, newPassword: string) {
    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(user, oldPassword, newPassword)
      toast.success('Password changed successfully', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      })
    } catch (err: any) {
      toast.error(err?.message || 'An error occurred while changing the password', {
        autoClose: 3000,
        hideProgressBar: true,
        className: 'error-toast',
      })
    }
  }

  return (
    <>
      <ContentHeader title={<Box>Profile</Box>} />
      <div style={{ marginTop: '10px' }}>
        <ListFilters
          filters={[
            { title: 'Personal Info', value: 'Profile' },
            { title: 'Password Reset', value: 'Password' },
          ]}
          selectedFilter={selectedPage}
          onSelectedFilterChange={handleSelectedFilterChange}
        />
      </div>
      {selectedPage === 'Profile' ? (
        <>
          <Box style={{ background: 'white', padding: '40px ', borderRadius: '9px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Box mb={isMobile ? 0 : 3}>
                  <TextField
                    className='whiteBackgroundTextField'
                    placeholder='Enter your name'
                    required
                    type='text'
                    id='outlined-required'
                    label='Full name'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    value={localUserData?.name}
                    onChange={(e) => handleFieldChange('name', e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box>
                  <TextField
                    placeholder='Enter your email'
                    required
                    type='email'
                    id='outlined-required'
                    label='Email'
                    disabled
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    value={currentUser?.email}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box mb={isMobile ? 0 : 3}>
                  <TextField
                    className='whiteBackgroundTextField'
                    placeholder='Enter a phone number'
                    type='numeric'
                    id='outlined-required'
                    label='Phone number'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    value={localUserData?.phone}
                    onChange={(e) => handleFieldChange('phone', e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box>
                  <TextField
                    className='whiteBackgroundTextField'
                    placeholder='Enter your city or locality'
                    type='text'
                    id='outlined-required'
                    label='City'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    value={localUserData?.city}
                    onChange={(e) => handleFieldChange('city', e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box mb={isMobile ? 0 : 3}>
                  <TextField
                    className='whiteBackgroundTextField'
                    placeholder='Enter your state'
                    type='text'
                    id='outlined-required'
                    label='State'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    value={localUserData?.state}
                    onChange={(e) => handleFieldChange('state', e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box>
                  <TextField
                    className='whiteBackgroundTextField'
                    placeholder='Enter your zip code'
                    type='text'
                    id='outlined-required'
                    label='Postal/Zip code'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    value={localUserData?.postalCode}
                    onChange={(e) => handleFieldChange('postalCode', e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box>
                  <TextField
                    placeholder='United States'
                    type='text'
                    id='outlined-required'
                    label='Country'
                    disabled
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    value={currentUser?.country}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          {(currentUser?.country === 'United States' || !currentUser?.country) ? (
                            <img
                              src={usaFlagIcon}
                              alt='USA Flag'
                              style={{ width: '24px', height: '24px' }}
                            />
                          ) : (
                            <FlagIcon />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <div
              style={{
                marginTop: '30px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: isMobile ? 'center' : 'flex-start',
              }}
            >
              <Button
                variant='outlined'
                style={{ marginRight: '20px' }}
                onClick={() => navigate('/dashboard')}
              >
                Cancel
              </Button>
              <Button variant='contained' onClick={handleUpdateUser}>
                Save Changes
              </Button>
            </div>
          </Box>
        </>
      ) : selectedPage === 'Reset' ? (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              background: 'white',
              borderRadius: '9px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                width: isMobile ? '100%' : '50%',
              }}
            >
              <Box style={{ background: 'white', padding: '40px ', borderRadius: '9px' }}>
                <div style={{ marginBottom: '30px' }}>
                  <Alert severity='success'>{`We just sent a verification code over to ${currentUser?.email}`}</Alert>
                </div>
                <div style={{ marginBottom: '30px' }}>
                  <TextField
                    className='whiteBackgroundTextField'
                    placeholder='Enter verification code'
                    label='Verification code'
                    type='text'
                    id='outlined-required'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeValues(e, 'code')
                    }
                    name='sign-in-reset-otp'
                  />
                </div>
                <RegistrationField
                  placeholder='Password'
                  styles='sign-up__input'
                  withIcon
                  icon={showPassword ? visibilityOffIcon : visibilityIcon}
                  type={showPassword ? 'text' : 'password'}
                  onIconClick={handleClickVisibilityIcon}
                  iconPosition='end'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeValues(e, 'password')
                  }
                  validataionSchema={VALID_PASSWORD.test(initialValues.password)}
                  errorMessage='Must be min 8 chars, uppercase, number, no special'
                  name='sign-up-password'
                />
                <RegistrationField
                  placeholder='Confirm Password'
                  styles='sign-up__input'
                  withIcon
                  icon={showConfirmPassword ? visibilityOffIcon : visibilityIcon}
                  type={showConfirmPassword ? 'text' : 'password'}
                  onIconClick={handleClickConfirmIcon}
                  iconPosition='end'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeValues(e, 'confirmPassword')
                  }
                  validataionSchema={VALID_PASSWORD.test(initialValues.confirmPassword)}
                  errorMessage='Must be min 8 chars, uppercase, number, no special'
                  name='sign-up-confirm-password'
                />
                <div
                  style={{
                    marginTop: '20px',
                    display: 'flex',
                    justifyContent: isMobile ? 'center' : 'flex-start',
                  }}
                >
                  <Button
                    variant='outlined'
                    style={{ marginRight: '20px' }}
                    onClick={() => sendCode()}
                  >
                    Resend email
                  </Button>
                  <Button
                    variant='contained'
                    disabled={!isResetPasswordButtonEnabled()}
                    onClick={() => handleResetPasswordClick()}
                  >
                    Reset password
                  </Button>
                </div>
              </Box>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              background: 'white',
              borderRadius: '9px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                width: isMobile ? '100%' : '50%',
              }}
            >
              <Box style={{ background: 'white', padding: '40px ', borderRadius: '9px' }}>
                <RegistrationField
                  placeholder='Enter current password'
                  styles='sign-up__input'
                  withIcon
                  icon={showCurrentPassword ? visibilityOffIcon : visibilityIcon}
                  type={showCurrentPassword ? 'text' : 'password'}
                  onIconClick={handleClickVisibilityCurrentIcon}
                  iconPosition='end'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeValues(e, 'oldPassword')
                  }
                  validataionSchema={VALID_PASSWORD.test(initialValues.oldPassword)}
                  errorMessage='Must be min 8 chars, uppercase, number, no special'
                  name='sign-up-password'
                />
                <RegistrationField
                  placeholder='Enter new password'
                  styles='sign-up__input'
                  withIcon
                  icon={showPassword ? visibilityOffIcon : visibilityIcon}
                  type={showPassword ? 'text' : 'password'}
                  onIconClick={handleClickVisibilityIcon}
                  iconPosition='end'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeValues(e, 'password')
                  }
                  validataionSchema={VALID_PASSWORD.test(initialValues.password)}
                  errorMessage='Must be min 8 chars, uppercase, number, no special'
                  name='sign-up-password'
                />
                <RegistrationField
                  placeholder='Confirm new password'
                  styles='sign-up__input'
                  withIcon
                  icon={showConfirmPassword ? visibilityOffIcon : visibilityIcon}
                  type={showConfirmPassword ? 'text' : 'password'}
                  onIconClick={handleClickConfirmIcon}
                  iconPosition='end'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeValues(e, 'confirmPassword')
                  }
                  validataionSchema={VALID_PASSWORD.test(initialValues.confirmPassword)}
                  errorMessage='Must be min 8 chars, uppercase, number, no special'
                  name='sign-up-confirm-password'
                />
                <div
                  style={{
                    marginTop: '30px',
                    display: 'flex',
                    justifyContent: isMobile ? 'center' : 'flex-start',
                  }}
                >
                  <Button
                    variant='outlined'
                    style={{ marginRight: '20px' }}
                    onClick={() => sendCode()}
                  >
                    I forgot my password
                  </Button>
                  <Button
                    variant='contained'
                    disabled={!isResetPasswordButtonEnabled()}
                    onClick={() =>
                      changePassword(initialValues.oldPassword, initialValues.password)
                    }
                  >
                    Change password
                  </Button>
                </div>
              </Box>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default Settings
