import Pagination from '@mui/material/Pagination';
import { makeStyles } from '@mui/styles';
import PaginationItem from '@mui/material/PaginationItem';

const useStyles = makeStyles({
	buttonRoot: {
		color: '#9CA3AF',
		fontFamily: 'var(--inter-font) !important',
		$selected: {
			backgroundColor: 'transparent !important',
		},
	},
	itemRoot: {
		fontWeight: 'bold !important',
		width: '32px !important',
		height: '32px !important',
		backgroundColor: '#fff !important',
		border: '2px solid var(--background-color) !important',
		color: 'var(--secondary-grey) !important',
		'&:hover': {
			backgroundColor: 'transparent !important',
		},
		'&:active': {
			backgroundColor: 'transparent !important',
		},
		'&:focus': {
			backgroundColor: 'transparent !important',
		},
	},
	selected: {
		border: '2px solid var(--primary1-color) !important',
		backgroundColor: 'transparent !important',
		color: 'var(--primary1-color) !important',
		fontWeight: 'bold !important',
	},
});

interface IPaginationNew {
	quantity: number;
	size: number;
	page: number;
	handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
}

const PaginationNew = (props: IPaginationNew) => {
	const { quantity, size, page, handleChange } = props;
	const classes = useStyles();
	const amountOfPages = Math.ceil(quantity / size);
	return (
		<Pagination
			count={amountOfPages}
			shape="rounded"
			size="small"
			page={page}
			onChange={handleChange}
			sx={{ marginBottom: '1rem' }}
			renderItem={(item) => (
				<PaginationItem
					{...item}
					classes={{ selected: classes.selected, root: classes.itemRoot }}
				/>
			)}
		/>
	);
};

export default PaginationNew;
