import { Button, Grid } from '@mui/material'
import { isUsingMobile } from '../../../helpers/utils'
import useAuthorization from '../../../helpers/useAuthorization'

interface IEstimateModalFooterProps {
  onSendClick?: () => void
  onCancelClick?: () => void
}

const EstimateModalFooter = ({ onSendClick, onCancelClick }: IEstimateModalFooterProps) => {
  const { isAuthorized } = useAuthorization()

  return (
    <Grid
      container
      justifyContent={{ xs: 'normal', md: 'right' }}
      alignItems={{ xs: 'normal', md: 'center' }}
      flexDirection={{ xs: 'column-reverse', md: 'row' }}
    >
      <Grid item padding={{ xs: '1rem', md: '1rem' }} flexGrow={2}></Grid>
      <Grid item padding={{ xs: '1rem 1rem 0 1rem', md: '1rem' }}>
        {isAuthorized('write_estimate') && (
          <Button
            color='primary'
            variant='contained'
            onClick={onSendClick}
            sx={{ mb: { xs: 1, sm: 0 } }}
            fullWidth
          >
            Send Estimate
          </Button>
        )}
        {isUsingMobile() && (
          <Button color='primary' variant='outlined' onClick={onCancelClick} fullWidth>
            Cancel
          </Button>
        )}
      </Grid>
    </Grid>
  )
}

export default EstimateModalFooter
