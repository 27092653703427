import { useState, useEffect, SyntheticEvent } from 'react'
import { savePayments, saveCustomers, saveCertainInvoice } from '../../redux/actions/home.actions'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../redux/store'
import PaginationNew from '../../components/pagination-new'
import ContentHeader from '../../components/content-header'
import { IPayment, ICustomer, IPaymentsFilters } from '../../types/types'
import moment from 'moment'
import ListFilters from '../../components/list-filters'
import PaymentsTable from './paymentsTable'
import PaymentModal from './modal/paymentModal'
import { errorHandler } from '../../helpers/errorHandler'
import { toast } from 'react-toastify'
import api from '../../api/api'
import NoDataDisplay from '../../components/noDataDisplay/noDataDisplay'
import { Box } from '@mui/system'
import PaymentsFiltersModal from './filters-modal/paymentsFiltersModal'
import { IconButton, Tooltip } from '@mui/material'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { isUsingMobile } from '../../helpers/utils'
import { useNavigate } from 'react-router-dom'
import ReferenceMenu from './reference-menu'
import { successToastNotification } from '../../helpers/toastNotification'

export interface Filters {
  customerId: string
  status: string[]
  method: string[]
  fromDate: string
  toDate: string
  invoiceName: string
  creditCardTypes: string[]
}

const Payments = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const payments = useSelector((state: AppState) => state.homeReducer.payments)
  const [chosenPayment, setChosenPayment] = useState<any>(null)
  const [paymentsPage, setPaymentsPage] = useState(1)
  const [selectedFilter, setSelectedFilter] = useState('')
  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false)
  const [showModal, setShowModal] = useState(false)
  const [filters, setFilters] = useState<any>({
    customerId: '',
    status: [],
    method: [],
    fromDate: '',
    toDate: '',
    invoiceName: '',
    creditCardTypes: [],
  })
  const [paymentsFilters, setPaymentsFilters] = useState<IPaymentsFilters>({
    statuses: [],
    paymentMethod: '',
    fromDate: null,
    toDate: null,
    search: '',
  })

  const paymentsSize = useSelector((state: AppState) => state.homeReducer.paymentsSize)
  const customers = useSelector((state: AppState) => state.homeReducer.customers)
  const isMobile = isUsingMobile()

  useEffect(() => {
    dispatch(savePayments())
    dispatch(saveCustomers())
  }, [dispatch])

  const handleFilterModalClick = () => setIsFilterModalOpen(true)

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    const customerId =
      filters.customerId && customers && customers.length
        ? customers.filter((customer: ICustomer) => customer.name === filters.customerId)[0]
            .publicId
        : ''
    const fromDate = paymentsFilters.fromDate ? moment(paymentsFilters.fromDate).format('YYYY-MM-DDTHH:mm') : ''
    const toDate = paymentsFilters.toDate ? moment(paymentsFilters.toDate).format('YYYY-MM-DDTHH:mm') : ''

    setPaymentsPage(value)

    dispatch(
      savePayments(
        value - 1,
        customerId,
        paymentsFilters.statuses.toString(),
        paymentsFilters.paymentMethod && paymentsFilters.paymentMethod.toString(),
        filters.invoiceName,
        fromDate,
        toDate,
        filters.creditCardTypes.toString(),
        paymentsFilters.search
      ),
    )
  }

  const handleFiltersConfirm = (filters: IPaymentsFilters) => {
    const fromDate = filters.fromDate ? moment(filters.fromDate).format('YYYY-MM-DDTHH:mm') : ''
    const toDate = filters.toDate ? moment(filters.toDate).format('YYYY-MM-DDTHH:mm') : ''

    setPaymentsPage(1)
    setSelectedFilter("")
    dispatch(
      savePayments(
        0,
        '',
        filters?.statuses?.toString(),
        filters.paymentMethod,
        '',
        fromDate,
        toDate,
        '',
        filters?.search,
      ),
    )
    setIsFilterModalOpen(false)
  }

  const handleSelectedFilterChange = (event: SyntheticEvent, filter: string) => {
    setSelectedFilter(filter)
    setFilters({
      ...filters,
      status: [filter],
    })
    dispatch(savePayments(0, '', filter))
  }

  const handleResendReceipt = () => {
    chosenPayment &&
      api
        .sendReceipt(chosenPayment.payment.publicId)
        .then(() =>
          successToastNotification('The receipt for this payment was resent successfully'),
        )
        .catch((err) => errorHandler(err))
  }

  const handleRowClick = (payment: IPayment) => {
    setChosenPayment({
      payment,
    })
    setShowModal(true)
  }

  const handleRefundClick = () => {
    if (!chosenPayment) {
      return
    }

    const refundData = {
      publicId: chosenPayment.payment.publicId,
      amount: chosenPayment.payment.amount,
    }

    api
      .refundUSIOPayment(refundData)
      .then(() => {
        toast.success(`The payment was successfully refunded`, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        })
        dispatch(savePayments())
      })
      .catch((err) => errorHandler(err))
  }

  const handleViewInvoiceClick = () => {
    dispatch(saveCertainInvoice(chosenPayment?.payment.invoice.publicId))
    navigate(`/invoices`)
  }

  return (
    <>
      <ContentHeader
        title='Payments'
        component={
          isMobile ? (
            <Box display='flex' justifyContent='space-between' flexGrow={2}>
              <ReferenceMenu />
              <Tooltip title='Filter'>
                <IconButton
                  aria-label='filter'
                  sx={{
                    backgroundColor: 'var(--secondary1-color)',
                    color: 'var(--primary1-color)',
                  }}
                  onClick={handleFilterModalClick}
                >
                  <FilterAltOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
          ) : undefined
        }
      />

      <ListFilters
        filters={[
          { title: 'All', value: '' },
          { title: 'Paid', value: 'PAID' },
          { title: 'Failed', value: 'FAILED' },
          { title: 'Refunded', value: 'REFUNDED' },
        ]}
        selectedFilter={selectedFilter}
        onFilterClick={handleFilterModalClick}
        onSelectedFilterChange={handleSelectedFilterChange}
      />

      {payments.length > 0 ? (
        <Box pb={4}>
          <PaymentsTable
            selectedPayment={chosenPayment?.payment}
            onSelectedPayment={setChosenPayment}
            payments={payments}
            onRowClick={handleRowClick}
            onRefundClick={handleRefundClick}
            onResendReceiptClick={handleResendReceipt}
            onViewInvoiceClick={handleViewInvoiceClick}
          />

          {paymentsSize > 15 ? (
            <PaginationNew
              size={15}
              quantity={paymentsSize}
              page={paymentsPage}
              handleChange={handleChangePage}
            />
          ) : null}
        </Box>
      ) : (
        <NoDataDisplay title='No data to display' />
      )}

      <PaymentsFiltersModal
        showModal={isFilterModalOpen}
        setShowModal={setIsFilterModalOpen}
        onFiltersConfirm={handleFiltersConfirm}
        setPaymentsFilters={setPaymentsFilters}
        paymentsFilters={paymentsFilters}
      />

      {chosenPayment && (
        <PaymentModal
          payment={chosenPayment.payment}
          showModal={showModal}
          setShowModal={setShowModal}
          onResendReceiptClick={handleResendReceipt}
          onRefundClick={handleRefundClick}
          onViewInvoiceClick={handleViewInvoiceClick}
        />
      )}
    </>
  )
}

export default Payments
