import { SAVE_ROLES, GET_PERMISSIONS } from './settings.action.type';
import { Dispatch } from 'redux';
import { Action } from '../actions/actionTypes';
import { errorHandler } from '../../helpers/errorHandler';
import api from '../../api/api';

export const saveRoles = () => (dispatch: Dispatch<Action>) => {
	api
		.getRoles()
		.then((res) => {
			dispatch({
				type: SAVE_ROLES,
				payload: res.data.body.content,
			});
		})
		.catch((err) => errorHandler(err));
};

export const getPermissions = () => (dispatch: Dispatch<Action>) => {
	api
		.getPermissions()
		.then((res) => {
			dispatch({
				type: GET_PERMISSIONS,
				payload: res.data.body.content,
			});
		})
		.catch((err) => errorHandler(err));
};
