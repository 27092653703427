import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  saveCustomersReportDetailed,
  saveCustomersReportGeneral,
} from '../../../redux/actions/report.actions'
import { AppState } from '../../../redux/store'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { ICustomerReportDetailed } from '../../../types/types'
import { formatMoney } from '../../../helpers/formatPrice'
import PaginationNew from '../../../components/pagination-new'

interface CustomersRevenueProps {
  filterPeriod: any
}

const CustomersRevenue = ({ filterPeriod }: CustomersRevenueProps) => {
  const dispatch = useDispatch()

  const general = useSelector((state: AppState) => state.reportReducer.customersReportGeneral)
  const details = useSelector((state: AppState) => state.reportReducer.customersReportDetailed)

  const [page, setPage] = useState(1)
  const paginationSize = useSelector((state: AppState) => state.reportReducer.customersDetailedSize)

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    dispatch(saveCustomersReportDetailed(filterPeriod.from, filterPeriod.to, value - 1))
  }

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          '& .MuiTableHead-root .MuiTableCell-root': { fontWeight: 600, fontSize: 16 },
          '& .MuiTableBody-root .MuiTableCell-root .MuiTypography-root': { fontSize: 14 },
          '& .MuiTableCell-root': { color: 'var(--secondary-grey)' },
          boxShadow: '0 4px 8px rgba(77, 94, 128, 0.08)',
          borderRadius: '8px',
          border: 'solid 1px var(--secondary-grey-bg)',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Customer name</TableCell>
              <TableCell>All Income</TableCell>
              <TableCell>Paid Income</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {details.map((row: ICustomerReportDetailed, index: number) => (
              <TableRow
                key={index}
                sx={{
                  '&:nth-of-type(odd)': {
                    backgroundColor: 'var(--background-color)',
                  },
                }}
              >
                <TableCell>{row.customerName}</TableCell>
                <TableCell>{formatMoney(row.invoiceTotal)}</TableCell>
                <TableCell>{formatMoney(row.paidTotal)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell>
                <Typography fontSize={16} fontWeight={600} color='var(--green)'>
                  Total revenue
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontSize={16} fontWeight={600} color='var(--green)'>
                  {formatMoney(general?.invoiceTotal)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontSize={16} fontWeight={600} color='var(--green)'>
                  {formatMoney(general?.paidTotal)}
                </Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {paginationSize > 15 && (
        <PaginationNew
          size={15}
          quantity={paginationSize}
          page={page}
          handleChange={handleChangePage}
        />
      )}
    </>
  )
}

export default CustomersRevenue
