import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../redux/store'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { ISettledDepositReport } from '../../types/types'
import { formatShortenDate } from '../../helpers/formatDate'
import moment from 'moment'
import { formatMoney } from '../../helpers/formatPrice'
import { useState } from 'react'
import PaginationNew from '../../components/pagination-new'
import { saveSettledDeposit } from '../../redux/actions/report.actions'
import { styled } from '@mui/styles'

const FooterTypography = styled(Typography)(() => ({
  fontSize: '16px !important',
  fontWeight: '600 !important',
  color: 'var(--green) !important',
}))

const MerchantFees = () => {
  const dispatch = useDispatch()
  const details = useSelector((state: AppState) => state.reportReducer.settledDeposit)
  const general = useSelector((state: AppState) => state.reportReducer.settledDepositSummary)
  const [page, setPage] = useState(1)
  const paginationSize = useSelector((state: AppState) => state.reportReducer.settledDepositSize)

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    dispatch(saveSettledDeposit(value - 1))
  }

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          '& .MuiTableHead-root .MuiTableCell-root': { fontWeight: 600, fontSize: 16 },
          '& .MuiTableBody-root .MuiTableCell-root .MuiTypography-root': { fontSize: 14 },
          '& .MuiTableCell-root': { color: 'var(--secondary-grey)' },
          boxShadow: '0 4px 8px rgba(77, 94, 128, 0.08)',
          borderRadius: '8px',
          border: 'solid 1px var(--secondary-grey-bg)',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Est Settled date</TableCell>
              <TableCell>Sales Date(s)</TableCell>
              <TableCell>Total Sales</TableCell>
              <TableCell>Total Refunds</TableCell>
              <TableCell>Fees</TableCell>
              <TableCell>Est. Net deposits</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {details.map((row: ISettledDepositReport, index: number) => (
              <TableRow
                key={index}
                sx={{
                  '&:nth-of-type(odd)': {
                    backgroundColor: 'var(--background-color)',
                  },
                }}
              >
                <TableCell>
                  <Typography>{formatShortenDate(row.settledDate)}</Typography>
                  <Typography>{moment(row.settledDate).format('dddd')}</Typography>
                </TableCell>
                <TableCell>
                  {row.submitDates.split(',').map((date: string, dateIndex: number) => (
                    <Typography key={dateIndex}>{formatShortenDate(date)}</Typography>
                  ))}
                </TableCell>
                <TableCell>{formatMoney(row.amount)}</TableCell>
                <TableCell>{formatMoney(row.refunded)}</TableCell>
                <TableCell>{formatMoney(row.fee)}</TableCell>
                <TableCell>{formatMoney(row.netDeposit)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell>
                <FooterTypography>Totals</FooterTypography>
              </TableCell>
              <TableCell></TableCell>
              <TableCell>
                <FooterTypography>{formatMoney(general?.totalPaid)}</FooterTypography>
              </TableCell>
              <TableCell>
                <FooterTypography>{formatMoney(general?.totalRefunded)}</FooterTypography>
              </TableCell>
              <TableCell>
                <FooterTypography>{formatMoney(general?.totalFee)}</FooterTypography>
              </TableCell>
              <TableCell>
                <FooterTypography>{formatMoney(general?.totalNetDeposit)}</FooterTypography>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {paginationSize > 15 ? (
        <PaginationNew
          size={15}
          quantity={paginationSize}
          page={page}
          handleChange={handleChangePage}
        />
      ) : null}
    </>
  )
}

export default MerchantFees
