import { Grid, Typography } from '@mui/material';
import { formatMoney } from '../../../helpers/formatPrice';
import { IEstimate } from '../../../types/types';

interface IEstimatePreviewFooter {
	estimate: IEstimate;
}

const EstimatePreviewFooter = ({ estimate }: IEstimatePreviewFooter) => {
	return (
		<>
			<Grid container p={2} spacing={2}>
				<Grid item md={6}>
					<Typography fontWeight="bold">Notes</Typography>
					<Typography sx={{ fontSize: '14px' }}>{estimate.memo}</Typography>
				</Grid>
				<Grid item md={4}>
					<Typography textAlign="right" fontWeight="bold">
						Subtotal
					</Typography>
				</Grid>
				<Grid item md={2}>
					<Typography>{formatMoney(Number(estimate.subTotal))}</Typography>
				</Grid>
			</Grid>
			<Grid
				container
				sx={{ backgroundColor: 'var(--background-color)' }}
				spacing={1}
			>
				<Grid item md={6}></Grid>
				<Grid item md={4}>
					<Typography fontWeight="bold">ESTIMATED TOTAL</Typography>
				</Grid>
				<Grid item md={2}>
					<Typography fontWeight="bold">
						{formatMoney(estimate.total)}
					</Typography>
				</Grid>
			</Grid>
		</>
	);
};

export default EstimatePreviewFooter;
