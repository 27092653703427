import { TextField } from '@mui/material'
import {
  validateEmail,
  validateName,
  validatePhoneNumber,
  validateWebsite,
} from '../../../helpers/utils'
import { LabelPhoneField } from '../../../components/amount-field/amount-field'
import { deformatPhoneNumber } from '../../../helpers/formatPrice'
import { PHONE_VALID } from '../../../helpers/constants'

interface CustomerAccountInfoTabProps {
  newCustomer: any
  formErrors: any
  setFormErrors: any
  onChangeNewCustomer: any
}

const CustomerAccountInfoTab = ({
  newCustomer,
  formErrors,
  setFormErrors,
  onChangeNewCustomer,
}: CustomerAccountInfoTabProps) => {
  return (
    <>
      <TextField
        label='Business name'
        required
        sx={{ marginBottom: '25px' }}
        InputProps={{
          sx: {
            '& fieldset': {
              borderColor: 'var(--secondary-grey)',
            },
          },
        }}
        placeholder='Enter a business name'
        value={newCustomer?.name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChangeNewCustomer(e, 'name')
          validateName(e.target.value, 'name', setFormErrors, 'Business name')
        }}
        error={!!formErrors['name']}
        helperText={formErrors['name']}
      />
      <TextField
        label='First name'
        required
        sx={{ marginBottom: '25px' }}
        InputProps={{
          sx: {
            '& fieldset': {
              borderColor: 'var(--secondary-grey)',
            },
          },
        }}
        placeholder='Enter your name'
        value={newCustomer?.primaryFirstName}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChangeNewCustomer(e, 'primaryFirstName')
          validateName(e.target.value, 'primaryFirstName', setFormErrors, 'First name')
        }}
        error={!!formErrors['primaryFirstName']}
        helperText={formErrors['primaryFirstName']}
      />
      <TextField
        label='Last name'
        required
        sx={{ marginBottom: '25px' }}
        InputProps={{
          sx: {
            '& fieldset': {
              borderColor: 'var(--secondary-grey)',
            },
          },
        }}
        placeholder='Enter your last name'
        value={newCustomer?.primaryLastName}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChangeNewCustomer(e, 'primaryLastName')
          validateName(e.target.value, 'primaryLastName', setFormErrors, 'Last name')
        }}
        error={!!formErrors['primaryLastName']}
        helperText={formErrors['primaryLastName']}
      />
      <TextField
        label='Email'
        required
        sx={{ marginBottom: '25px' }}
        InputProps={{
          sx: {
            '& fieldset': {
              borderColor: 'var(--secondary-grey)',
            },
          },
        }}
        placeholder='Enter your email'
        value={newCustomer?.primaryEmail}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChangeNewCustomer(e, 'primaryEmail')
          validateEmail(e.target.value, setFormErrors)
        }}
        error={!!formErrors['email']}
        helperText={formErrors['email']}
      />
      <LabelPhoneField
        label='Phone number'
        required
        InputProps={{
          sx: {
            '& fieldset': {
              borderColor: 'var(--secondary-grey)',
            },
          },
        }}
        sx={{ marginBottom: '25px' }}
        styles='business-info__field'
        value={newCustomer?.primaryPhoneNumber}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          validatePhoneNumber(e.target.value, setFormErrors)
          onChangeNewCustomer(e, 'primaryPhoneNumber')
        }}
        isValid={
          !newCustomer?.primaryPhoneNumber ||
          PHONE_VALID.test(deformatPhoneNumber(newCustomer?.primaryPhoneNumber))
        }
      />
      <TextField
        sx={{ marginBottom: '25px' }}
        InputProps={{
          sx: {
            '& fieldset': {
              borderColor: 'var(--secondary-grey)',
            },
          },
        }}
        label='Website'
        placeholder='Enter your website'
        value={newCustomer?.website}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          validateWebsite(e.target.value, setFormErrors)
          onChangeNewCustomer(e, 'website')
        }}
        error={!!formErrors['website']}
        helperText={formErrors['website']}
      />
    </>
  )
}

export default CustomerAccountInfoTab
