import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@mui/material'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import React, { ReactNode, useEffect } from 'react'

interface PaymentMethodSelectProps {
  value: string
  autoHelpText?: ReactNode
  manualHelpText?: ReactNode
  onChange: (e: React.ChangeEvent, value: string) => void
  disableAuto?: boolean
}

const PaymentMethodSelect = ({
  value,
  onChange,
  autoHelpText,
  manualHelpText,
  disableAuto = false,
}: PaymentMethodSelectProps) => {
  return (
    <Grid container p={2}>
      <RadioGroup
        aria-labelledby='payment-method'
        defaultValue='AUTO'
        value={value}
        onChange={onChange}
        sx={{ flexDirection: 'row' }}
      >
        <Grid item xs={6}>
          <Card sx={{ m: 1, backgroundColor: 'var(--background-color)' }}>
            <CardContent>
              <Tooltip
                title={
                  disableAuto
                    ? 'In order to complete the registration of your business and be able to receive payments, fill out USIO Enrollment Form'
                    : undefined
                }
              >
                <FormControlLabel
                  value='AUTO'
                  control={
                    <Radio
                      disabled={disableAuto}
                      sx={{ alignSelf: 'start', position: 'absolute' }}
                    />
                  }
                  label={
                    <>
                      <Box textAlign='center' padding='1rem 2rem'>
                        <CreditCardIcon
                          sx={{
                            fontSize: '4rem',
                            color: disableAuto ? 'var(--gray3-color)' : 'var(--primary1-color)',
                          }}
                        />
                      </Box>
                      <Box ml={2}>
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            mb: 1,
                            color: disableAuto ? 'var(--gray3-color)' : 'var(--primary1-color)',
                          }}
                        >
                          Charge a credit card
                        </Typography>
                        <Typography
                          component='small'
                          variant='subtitle1'
                          sx={{
                            color: disableAuto ? 'var(--gray3-color)' : 'var(--secondary-grey)',
                            fontSize: '14px',
                          }}
                        >
                          {autoHelpText
                            ? autoHelpText
                            : "You can record a payment by adding your customer's credit card details in Blue Cimarron."}
                        </Typography>
                      </Box>
                    </>
                  }
                />
              </Tooltip>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card sx={{ m: 1, backgroundColor: 'var(--background-color)' }}>
            <CardContent>
              <FormControlLabel
                value='MANUAL'
                control={<Radio sx={{ alignSelf: 'start', position: 'absolute' }} />}
                label={
                  <>
                    <Box textAlign='center' padding='1rem 2rem'>
                      <AttachMoneyIcon color='primary' sx={{ fontSize: '4rem' }} />
                    </Box>
                    <Box ml={2}>
                      <Typography color='primary' sx={{ fontWeight: 'bold', mb: 1 }}>
                        Record a manual payment
                      </Typography>
                      <Typography
                        component='small'
                        variant='subtitle1'
                        sx={{ color: 'var(--secondary-grey)', fontSize: '14px' }}
                      >
                        {manualHelpText
                          ? manualHelpText
                          : 'You can record a payment you have already received with cash, check or bank account.'}
                      </Typography>
                    </Box>
                  </>
                }
              />
            </CardContent>
          </Card>
        </Grid>
      </RadioGroup>
    </Grid>
  )
}

export default PaymentMethodSelect
