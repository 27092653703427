import { Box } from '@mui/material'
import smLogo from '../../assets/images/logo-bc-condensed.png'
import xsLogo from '../../assets/images/logo.png'

interface SubscriptionButtonProps {
  isFullMenu: boolean
  onClick?: () => void
}

const SubscriptionButton = ({ isFullMenu, onClick }: SubscriptionButtonProps) => {
  const isActive = location.pathname.split('/')[1] == 'subs'

  return (
    <Box
      sx={{
        boxShadow: '0 4px 8px var(--secondary-grey-bg)',
        border: `solid 1px var(--${isActive ? 'primary1-color' : 'secondary-grey-bg'})`,
        backgroundColor: isActive ? 'var(--secondary1-color)' : 'auto',
        width: isFullMenu ? 200 : 30,
        alignSelf: 'center',
        padding: isFullMenu ? '0.5rem 1rem' : '4px',
        borderRadius: '8px',
        '&:hover': {
          borderColor: 'var(--primary1-color)',
          cursor: 'pointer',
        },
      }}
    >
      <img
        src={isFullMenu ? smLogo : xsLogo}
        style={{ maxWidth: '100%', verticalAlign: 'sub' }}
        onClick={onClick}
      />
    </Box>
  )
}

export default SubscriptionButton
