import { useDispatch, useSelector } from 'react-redux'
import ContentHeader from '../../components/content-header'
import JournalEntryFilters from './filters'
import { saveJournalEntries } from '../../redux/actions/home.actions'
import { useEffect, useState } from 'react'
import useAuthorization from '../../helpers/useAuthorization'
import PlusButton from '../../components/plus-button/plus-button'
import { useNavigate } from 'react-router-dom'
import NoDataDisplay from '../../components/noDataDisplay/noDataDisplay'
import { AppState } from '../../redux/store'
import { Box } from '@mui/material'
import JournalEntriesTable from './table'
import { IJournalEntry } from '../../types/types'
import ConfirmDialog from '../../components/dialog/dialog'
import api from '../../api/api'
import { successToastNotification } from '../../helpers/toastNotification'
import { errorHandler } from '../../helpers/errorHandler'
import PaginationNew from '../../components/pagination-new'
import JournalEntryViewModal from './viewModal/viewModal'
import saveAs from 'file-saver'

const JournalEntries = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { isAuthorized } = useAuthorization()

  const [page, setPage] = useState(1)
  const [selectedJournalEntry, setSelectedJournalEntry] = useState<IJournalEntry | null>()
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState<boolean>(false)
  const [isOpenViewDialog, setIsOpenViewDialog] = useState<boolean>(false)
  const journalEntries = useSelector((state: AppState) => state.homeReducer.journalEntries)
  const journalEntriesSize = useSelector((state: AppState) => state.homeReducer.journalEntriesSize)

  useEffect(() => {
    dispatch(saveJournalEntries())
  }, [dispatch])

  const handleSearchClick = (account: string, status: string) => () => {
    dispatch(saveJournalEntries(page - 1, 15, account, status))
  }

  const handleEditClick = () => {
    selectedJournalEntry && navigate(`/journal-entries/${selectedJournalEntry.publicId}/edit`)
  }

  const handleDeleteClick = () => {
    setIsOpenDeleteDialog(true)
  }

  const handleCloseDeleteDialog = () => {
    setSelectedJournalEntry(null)
    setIsOpenDeleteDialog(false)
  }

  const handleDeleteJournalEntry = () => {
    setIsOpenDeleteDialog(false)

    api
      .deleteJournalEntry(selectedJournalEntry?.publicId)
      .then(() => {
        successToastNotification(`${selectedJournalEntry?.description} was successfully deleted`)
        dispatch(saveJournalEntries())
      })
      .catch((err) => errorHandler(err))

    setSelectedJournalEntry(null)
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    dispatch(saveJournalEntries(value - 1, 15))
  }

  const handleViewClick = (entry: IJournalEntry) => {
    setSelectedJournalEntry(entry)
    setIsOpenViewDialog(true)
  }

  const handleDuplicateClick = () => {
    if (!selectedJournalEntry) {
      return
    }

    api
      .duplicateJournalEntry(selectedJournalEntry.publicId)
      .then(() => {
        dispatch(saveJournalEntries())
        successToastNotification('Journal Entry was successfully duplicated')
      })
      .catch((err) => errorHandler(err))
  }

  const handleMarkAsReviewedClick = (journalEntry?: IJournalEntry) => {
    if (!journalEntry) {
      return
    }

    if (journalEntry.reviewed) {
      api
        .undoReviewJournalEntry(journalEntry.publicId)
        .then(() => {
          dispatch(saveJournalEntries())
          successToastNotification('Journal Entry was successfully updated')
        })
        .catch((err) => errorHandler(err))
    } else {
      api
        .reviewJournalEntry(journalEntry.publicId)
        .then(() => {
          dispatch(saveJournalEntries())
          successToastNotification('Journal Entry was successfully updated')
        })
        .catch((err) => errorHandler(err))
    }
  }

  const handleDownloadClick = () => {
    api
      .exportJournalEntries()
      .then((response) => {
        const blob = response.data

        const fetchData = async () => {
          try {
            saveAs(blob, 'journalEntries.xlsx')
          } catch (error) {
            console.error('Error while fetching journal entries data', error)
          }
        }

        fetchData()
      })
      .catch((error) => {
        console.error('Error:', error)
      })

    return null
  }

  return (
    <>
      <ContentHeader title='Journal Entries' />

      <JournalEntryFilters onSearchClick={handleSearchClick} onDownloadClick={handleDownloadClick} />

      {journalEntries && journalEntries.length > 0 ? (
        <Box>
          <JournalEntriesTable
            setSelectedJournalEntry={setSelectedJournalEntry}
            selectedJournalEntry={selectedJournalEntry}
            onRowClick={handleViewClick}
            onDeleteClick={handleDeleteClick}
            onEditClick={handleEditClick}
            onDuplicateClick={handleDuplicateClick}
            onMarkAsReviewedClick={handleMarkAsReviewedClick}
          />
          {journalEntriesSize > 15 ? (
            <PaginationNew
              size={15}
              quantity={journalEntriesSize}
              page={page}
              handleChange={handleChangePage}
            />
          ) : null}
        </Box>
      ) : (
        <NoDataDisplay
          mt={10}
          title='No data to display'
          description='Add new entries to streamline your bookeeping and generate reports automatically.'
        />
      )}

      <ConfirmDialog
        isOpen={isOpenDeleteDialog}
        dialogTitle='Sure you want to delete?'
        dialogBodyContent='Deleting this vendor will permanently remove it from your account, and it cannot be undone.'
        buttonConfirmText='Yes, Delete'
        buttonCancelText='Cancel'
        handleClose={handleCloseDeleteDialog}
        handleConfirm={handleDeleteJournalEntry}
      />

      {isOpenViewDialog && selectedJournalEntry && (
        <JournalEntryViewModal
          showModal={isOpenViewDialog}
          onEditClick={() => navigate(`/journal-entries/${selectedJournalEntry.publicId}/edit`)}
          onDeleteClick={handleDeleteClick}
          onDuplicateClick={handleDuplicateClick}
          setShowModal={setIsOpenViewDialog}
          journalEntry={selectedJournalEntry}
        />
      )}

      {isAuthorized('write_journal_entry') && (
        <PlusButton
          tooltipText='Add Journal Entries'
          handleOnClick={() => navigate('/journal-entries/add')}
        />     
      )}
    </>
  )
}

export default JournalEntries
