import {
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from '@mui/material'
import { styled } from '@mui/styles'
import { IActivityStatement, IOustandingInvoice, IPaymentRecord } from '../../../types/types'
import { formatDate, formatMoney } from '../../../helpers/formatPrice'
import { getDayOrDays } from '../../../helpers/utils'

interface ActivityInvoicesProps {
  activityStatements: IActivityStatement | null
  mb?: number | string
}

const ActivityInvoices = ({ activityStatements, mb }: ActivityInvoicesProps) => {
  const TableHeadTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'var(--background-color)',
      fontWeight: 'bold',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const getChipColorByStatus = (status: string) => {
    switch (status) {
      case 'PARTIAL':
        return 'warning'
      case 'SENT':
        return 'info'
      case 'PAID':
        return 'success'
      case 'OVERDUE':
        return 'error'
      case 'REFUNDED':
        return 'secondary'
      case 'DRAFT':
      default:
        return
    }
  }

  const getPaymentDate = (invoice: IOustandingInvoice) => {
    if (invoice && invoice.status === 'PARTIAL' && invoice.paymentRecords.length) {
      return invoice.paymentRecords.map((paymentRecord: IPaymentRecord) =>
        formatDate(paymentRecord.date),
      )
    }

    if (invoice && invoice.status === 'SENT') {
      return `sent ${
        invoice.sentTime ? invoice.sentTime + ` ${getDayOrDays(invoice.sentTime)} ago` : 'today'
      }`
    }

    if (invoice && invoice.status === 'OVERDUE') {
      return `${
        invoice.overdueTime
          ? invoice.overdueTime + ` ${getDayOrDays(invoice.overdueTime)} overdue`
          : 'overdue today'
      }`
    }
  }

  return (
    <Box mb={mb}>
      <Typography variant='h5' pb={1}>
        Invoices
      </Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableHeadTableCell>Status</TableHeadTableCell>
            <TableHeadTableCell>Invoice ID</TableHeadTableCell>
            <TableHeadTableCell>Amount</TableHeadTableCell>
            <TableHeadTableCell>Created</TableHeadTableCell>
            <TableHeadTableCell>Payment date</TableHeadTableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            '& .MuiChip-colorSuccess': {
              backgroundColor: 'var(--success-bg-color)',
              color: 'var(--success-color)',
            },
            '& .MuiChip-colorWarning': {
              backgroundColor: 'var(--warning-bg-color)',
              color: 'var(--warning-color)',
            },
            '& .MuiChip-colorError': {
              backgroundColor: 'var(--error-bg-color)',
              color: 'var(--error-color)',
            },
            '& .MuiChip-colorInfo': {
              backgroundColor: 'var(--info-bg-color)',
              color: 'var(--info-color)',
            },
            '& .MuiChip-colorSecondary': {
              backgroundColor: 'var(--orange-bg-color)',
              color: 'var(--orange-color)',
            },
          }}
        >
          {activityStatements &&
            activityStatements.invoices.map((item) => {
              return (
                <TableRow key={item.publicId}>
                  <TableCell>
                    <Chip label={item.status} color={getChipColorByStatus(item.status)} />
                  </TableCell>
                  <TableCell>#{item.name}</TableCell>
                  <TableCell>{formatMoney(item.total)}</TableCell>
                  <TableCell>{formatDate(item.periodStart)}</TableCell>
                  <TableCell>
                    <Typography>{formatDate(item.periodEnd)}</Typography>
                    <Typography color={item.status === 'OVERDUE' ? 'red' : undefined}>
                      {getPaymentDate(item)}
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    </Box>
  )
}

export default ActivityInvoices
