import { Grid } from '@mui/material'
import { ICustomer } from '../../../types/types'
import { isUsingMobile } from '../../../helpers/utils'
import ContactDetails from './contactDetails'
import BillingAddress from './billingAddress'
import ShippingAddress from './shippingAddress'
import CreditCardsList from './creditCardsList'

interface CustomerProfileProps {
  customer: ICustomer
}

const CustomerProfile = ({ customer }: CustomerProfileProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item sm={4} xs={12}>
        <ContactDetails customer={customer} />
      </Grid>
      <Grid item sm={8} xs={12}>
        <Grid container direction='column' rowSpacing={isUsingMobile() ? 2 : 0}>
          <Grid item>
            <BillingAddress customer={customer} />
          </Grid>
          <Grid item>
            <ShippingAddress customer={customer} />
          </Grid>
          <Grid item>
            <CreditCardsList customer={customer} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CustomerProfile
