import { Alert, Box, Grid, TextField, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { capitalizeFirst, validateCommonField, validateEmail } from '../../../helpers/utils'
import { AppState } from '../../../redux/store'
import Autocomplete from '@mui/material/Autocomplete'
import { COMPANY_SIZES } from '../../../helpers/constants'
import { useEffect, useMemo } from 'react'

const AccountInfoForm = (props: any) => {
  const { company, setCompany, setFormErrors, formErrors } = props
  const isOnBoarding = useSelector((state: AppState) => state.authReducer.isOnBoarding)
  const mccTypes = useSelector((state: AppState) => state.authReducer.mccTypes)
  const authReducer = useSelector((state: AppState) => state.authReducer)

  const selectedValue = useMemo(
    () => (mccTypes ? mccTypes.find((v) => v.publicId === company.mccPublicId) : null),
    [company.mccPublicId],
  )

  useEffect(() => {
    if (isOnBoarding) {
      setCompany({ ...company, ownerEmail: authReducer.user.email })
    }
  }, [])

  return (
    <Box>
      {!isOnBoarding && (
        <Box mb={2}>
          <Alert severity='info'>
            <Typography component='small' variant='body2'>
              Is not editable because these data have already been verified by the payment system.
            </Typography>
          </Alert>
        </Box>
      )}

      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <Box mb={3}>
            <TextField
              label='Company name'
              value={company?.name}
              fullWidth
              required={isOnBoarding}
              disabled={!isOnBoarding}
              onChange={(e) => {
                validateCommonField(
                  e.target.value.replaceAll(' ', ''),
                  'name',
                  setFormErrors,
                  'Company name',
                  3,
                ),
                  setCompany({
                    ...company,
                    name: e.target.value,
                  })
              }}
              error={!!formErrors['name']}
              helperText={formErrors['name']}
            />
          </Box>
          <Box>
            {isOnBoarding ? (
              <Autocomplete
                value={selectedValue}
                disabled={!isOnBoarding}
                disablePortal
                options={mccTypes}
                onChange={(event: any, selected: any | null) => {
                  setCompany({
                    ...company,
                    mccPublicId: selected ? selected.publicId : null,
                  })
                }}
                getOptionLabel={(option) => option?.name || ''}
                renderInput={(params) => (
                  <TextField required={isOnBoarding} {...params} label='Type of business' />
                )}
              />
            ) : (
              <TextField
                label='Type of Business'
                value={company?.typeOfBusiness}
                fullWidth
                disabled={true}
              />
            )}
          </Box>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Box mb={3}>
            <TextField
              label='Company email'
              value={company.ownerEmail}
              fullWidth
              disabled
              required={isOnBoarding}
              error={!!formErrors['ownerEmail']}
              helperText={formErrors['ownerEmail']}
            />
          </Box>
          <Box>
            {isOnBoarding ? (
              <Autocomplete
                fullWidth
                value={capitalizeFirst(company?.companySize)}
                disablePortal
                disabled={!isOnBoarding}
                options={COMPANY_SIZES.map((size) => capitalizeFirst(size))}
                onChange={(event: any, newValue: any | null) =>
                  setCompany({
                    ...company,
                    companySize: newValue.toUpperCase(),
                  })
                }
                renderInput={(params) => (
                  <TextField {...params} required={isOnBoarding} label='Type of organization' />
                )}
              />
            ) : (
              <TextField
                label='Type of organization'
                value={company?.companySize}
                fullWidth
                disabled={true}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AccountInfoForm
