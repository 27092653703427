import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import { CardDateInput, CardNumberInput } from '../../components/card-inputs/card-inputs'
import { isUsingMobile } from '../../helpers/utils'

interface PayNowCreditCardFormProps {
  payment: any
  invoice: any
  onChange: any
  onConfirm: any
  onReset: any
  setShowInvoice: any
  isLoading: boolean
}

const PayNowCreditCardForm = ({
  payment,
  invoice,
  onChange,
  onConfirm,
  onReset,
  setShowInvoice,
  isLoading,
}: PayNowCreditCardFormProps) => {
  const isMobile = isUsingMobile()

  const handleEnablePayNowButton = () => {
    return (
      payment.cardNumber &&
      payment.expDate &&
      payment.cvv.length >= 3 &&
      Number(payment.amount) <= Number(invoice?.total) - Number(invoice?.amountPaid)
    )
  }

  return (
    <Box>
      <Grid container spacing={2} mb={2}>
        <Grid item width='100%'>
          <CardNumberInput
            label='Card number'
            value={payment.cardNumber}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e, 'cardNumber')}
          />
        </Grid>
        <Grid item xs={6}>
          <CardDateInput
            label='Expiry date'
            value={payment.expDate}
            onChange={(e) => {
              onChange(e, 'expDate')
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label='CVV code'
            fullWidth
            value={payment.cvv}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChange(e, 'cvv')
            }}
          />
        </Grid>
        <Grid item width='100%'>
          <TextField
            label='Name on the card'
            fullWidth
            value={payment.cardName}
            placeholder='Insert the name on the card'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChange(e, 'cardName')
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <Typography mb={2} fontWeight='bold'>
        Amount
      </Typography>
      <TextField
        label='Payment amount'
        value={payment.amount}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 4 }}
        InputLabelProps={{ shrink: true }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e, 'amount')}
        fullWidth
      />
      {isMobile && (
        <Button sx={{ mt: 1 }} onClick={() => setShowInvoice(true)}>
          View Invoice
        </Button>
      )}
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          display: 'flex',
          justifyContent: 'end',
          padding: '1rem',
          boxShadow: '0px -1px 6px 0px rgba(0,0,0,0.25)',
          backgroundColor: '#fff',
          boxSizing: 'border-box',
          right: 0,
          width: '100%',
          flexDirection: { xs: 'column-reverse', sm: 'row' },
          gap: 2,
        }}
      >
        <Button variant='outlined' onClick={onReset} sx={{ mr: { xs: 0, sm: 1 } }}>
          Clear all items
        </Button>
        <Button
          variant='contained'
          disabled={!handleEnablePayNowButton() || isLoading}
          onClick={onConfirm}
        >
          Pay now
        </Button>
      </Box>
    </Box>
  )
}

export default PayNowCreditCardForm
