import { Box, Stack, TextField, Typography } from '@mui/material'
import { ChangeEvent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { validateDescription, validateName } from '../../../helpers/utils'
import { getPermissions } from '../../../redux/actions/settings.actions'
import { AppState } from '../../../redux/store'
import { IPermission, IRole } from '../../../types/types'
import PermissionChip from './permission-chip'

interface RoleFormProps {
  formErrors: { name: string; description: string; permissions: string }
  setFormErrors: (errors: { name: string; description: string; permissions: string }) => void
  role: IRole
  setRole: (role: IRole) => void
  isEditable: boolean
}

const RoleForm = ({
  formErrors,
  setFormErrors,
  role,
  setRole,
  isEditable = true,
}: RoleFormProps) => {
  const dispatch = useDispatch()
  const permissions = useSelector((state: AppState) => state.settingsReducer.permissions)

  useEffect(() => {
    if (role) {
      setRole(role)
    }
  }, [role])

  useEffect(() => {
    dispatch(getPermissions())
  }, [dispatch])

  const handleClickPermission = (permission: IPermission) => () => {
    const isSelected = role?.permissions?.find((p) => p.publicId === permission.publicId)

    const updatedRole = {
      ...role,
      permissions: isSelected
        ? role?.permissions?.filter((p) => p.publicId !== permission.publicId)
        : [...role.permissions, permission],
    }

    setRole(updatedRole)
  }

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRole({ ...role, name: e.target.value })
  }

  const handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRole({ ...role, description: e.target.value })
  }

  return (
    <Box component='form' my={2} mx={3}>
      <Box mb={3}>
        <TextField
          label='Name'
          error={!!formErrors['name']}
          helperText={formErrors['name']}
					disabled={!isEditable}
          value={role?.name}
          InputLabelProps={{
            shrink: true,
          }}
          onBlur={() => validateName(role?.name, 'Name', setFormErrors, 'Name')}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleNameChange(e)
            validateName(e.target.value, 'Name', setFormErrors, 'Name')
          }}
          required
          fullWidth
        />
      </Box>
      <Box mb={2}>
        <TextField
          label='Description'
          error={!!formErrors['description']}
          helperText={formErrors['description']}
					disabled={!isEditable}
          value={role?.description}
          InputLabelProps={{
            shrink: true,
          }}
          onBlur={() => validateDescription(role?.description, setFormErrors)}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleDescriptionChange(e)
            validateDescription(e.target.value, setFormErrors)
          }}
          multiline
          required
          fullWidth
        />
      </Box>
      <Box mb={3}>
        <Typography mb={1}>Permissions</Typography>
        <Box>
          <Stack direction={{ xs: 'column', sm: 'row' }} flexWrap='wrap' gap={1}>
            {permissions?.map((permission: IPermission) => (
              <PermissionChip
                key={permission.publicId}
                permission={permission}
                role={role}
                onClick={isEditable ? handleClickPermission : undefined}
              />
            ))}
          </Stack>
        </Box>
      </Box>
    </Box>
  )
}

export default RoleForm
