import { Box, Button, Grid, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material'
import { IBill } from '../../types/types'
import { formatDate } from '../../helpers/formatPrice'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import { formatTime } from '../../helpers/formatDate'
import { isUsingMobile } from '../../helpers/utils'

interface BillDetailsProps {
  bill: IBill
  onEditClick?: () => void
  onSendClick?: () => void
  onRecordPaymentClick?: () => void
}

const BillDetails = ({ bill, onEditClick }: BillDetailsProps) => {
  const isMobile = isUsingMobile()

  const billCreation = bill.history.find((item) => item.action === 'CREATED')

  return (
    <>
      <Box m={2}>
        <Stepper
          orientation='vertical'
          sx={{
            '& .MuiStepLabel-label, & .MuiStepLabel-label.Mui-active': {
              color: 'var(--success-color)',
              fontWeight: 'bold',
            },
          }}
        >
          <Step key='creation' expanded={true}>
            <StepLabel
              icon={
                bill.dueDate ? (
                  <CheckCircleIcon color='success' />
                ) : (
                  <CircleOutlinedIcon color='success' />
                )
              }
            >
              Bill creation
            </StepLabel>
            <StepContent>
              <Grid container spacing={!isMobile ? 1 : undefined}>
                <Grid item sm={9}>
                  {billCreation && (
                    <Typography mb={2}>
                      <Typography component='span' fontWeight='bold'>
                        Bill created on:
                      </Typography>{' '}
                      {formatDate(billCreation.date)} at {formatTime(billCreation.date)} by{' '}
                      {`${bill.vendor.firstName} ${bill.vendor.lastName}`}
                    </Typography>
                  )}
                  {billCreation && bill.dueDate && (
                    <Typography mb={2}>
                      <Typography component='span' fontWeight='bold'>
                        Due date:
                      </Typography>{' '}
                      on {formatDate(bill.dueDate)} at {formatTime(bill.dueDate)}
                    </Typography>
                  )}
                  <Button
                    variant='outlined'
                    onClick={onEditClick}
                    disabled={bill.dueDate === 'OVERDUE' || bill.dueDate === 'PAID' || bill.status === 'PAID'}
                    sx={{ p: '0.5rem 4rem' }}
                  >
                    Edit Bill
                  </Button>
                </Grid>
              </Grid>
            </StepContent>
          </Step>

          {bill.payments.map((payment, index) => {
            const isLastPayment = bill.status === 'PAID' && index === bill.payments.length - 1
            return (
              <Step key={`payment-${index}`} expanded={true}>
                <StepLabel
                  icon={<CheckCircleIcon color={isLastPayment ? 'success' : undefined} />}
                  sx={{
                    '&, & .MuiStepLabel-label': {
                      color: isLastPayment ? 'success' : 'var(--secondary-grey)',
                    },
                  }}
                >
                  {isLastPayment ? 'Bill Payment' : 'Partial Payment'}
                </StepLabel>
                <StepContent>
                  <Grid container spacing={!isMobile ? 1 : undefined}>
                    <Grid item sm={9}>
                      <Typography>
                        <Typography component='span' fontWeight='bold'>
                          Payment date:
                        </Typography>{' '}
                        {formatDate(payment.date)} at {formatTime(payment.date)}
                      </Typography>

                      <Typography>
                        <Typography component='span' fontWeight='bold'>
                          Payment method:
                        </Typography>{' '}
                        {payment.method}
                      </Typography>
                    </Grid>
                  </Grid>
                </StepContent>
              </Step>
            )
          })}
        </Stepper>
      </Box>
    </>
  )
}

export default BillDetails
