import { Card, CardContent, Grid, Typography } from '@mui/material'
import { formatPhoneNumber } from '../../../helpers/formatPrice'
import { ICompany } from '../../../types/types'
import { getCdnUrl } from '../../../helpers/utils'

interface CompanyCardProps {
  company?: ICompany | null
}

const CompanyCard = ({ company }: CompanyCardProps) => {
  return (
    <Card
      sx={{
        mb: 1,
        color: 'var(--secondary-grey)',
        '& .MuiTypography-root': { fontSize: '14px' },
      }}
    >
      {company && (
        <CardContent>
          <Grid container>
            <Grid item xs={8}>
              <Typography>{company.name}</Typography>
              <Typography>{company.addressOne}</Typography>
              <Typography>{company.addressTwo}</Typography>
              <Typography mb={1}>
                {company.city}, {company.state} {company.postalCode}, {company.country}
              </Typography>
              <Typography>{formatPhoneNumber(company.phone)}</Typography>
              <Typography>{company.website}</Typography>
            </Grid>
            <Grid item xs={4}>
              {company?.templateLogoFileName && (
                <img
                  src={getCdnUrl(company?.templateLogoFileName)}
                  alt='company logo'
                  style={{ width: '100%' }}
                />
              )}
            </Grid>
          </Grid>
        </CardContent>
      )}
    </Card>
  )
}

export default CompanyCard
