import { Card, CardContent, Typography } from '@mui/material'
import { IActivityStatement } from '../../../types/types'
import { formatDate, formatMoney } from '../../../helpers/formatPrice'
import { formatTime } from '../../../helpers/formatDate'
import { isUsingMobile } from '../../../helpers/utils'

interface ActivityRefundCardsProps {
  activityStatements: IActivityStatement | null
}

const ActivityRefundCards = ({ activityStatements }: ActivityRefundCardsProps) => {
  const isMobile = isUsingMobile()

  const renderPaymentMethod = (refund: any) => {
    switch (refund.method) {
      case 'CASH':
        return 'Cash'
      case 'CHECK':
        return 'Check'
      case 'CREDIT_CARD':
        return refund.cardType
    }
  }

  const renderPaymentDetails = (refund: any) => {
    if (refund.method === 'CREDIT_CARD') {
      return `**** **** **** ${refund?.lastDigits}`
    }

    if (refund.method === 'CHECK') {
      return refund.checkNumber
    }
  }

  return (
    <>
      <Typography variant='h5' textAlign={isMobile ? 'center' : undefined}>
        Refunds
      </Typography>

      {activityStatements &&
        activityStatements.refunds.map((refund) => {
          return (
            <Card
              key={refund.publicId}
              sx={{
                color: 'var(--secondary-grey)',
                mb: 1,
                '& .MuiTypography-root': { fontSize: '14px' },
              }}
            >
              <CardContent>
                <Typography>Invoice ID</Typography>
                <Typography color='var(--grey-fonts)' mb={1}>
                  <Typography>#{refund.invoice.name}</Typography>
                  <Typography>{formatDate(refund.invoice.date)}</Typography>
                </Typography>
                <Typography>Amount</Typography>
                <Typography color='var(--grey-fonts)' mb={1}>
                  {formatMoney(refund.amount)}
                </Typography>
                <Typography>Method</Typography>
                <Typography color='var(--grey-fonts)' mb={1}>
                  {renderPaymentMethod(refund)}
                </Typography>
                <Typography>Payment details</Typography>
                <Typography color='var(--grey-fonts)' mb={1}>
                  {renderPaymentDetails(refund)}
                </Typography>
                <Typography>Refund date</Typography>
                <Typography color='var(--grey-fonts)'>
                  <Typography>{formatDate(refund.date)}</Typography>
                  <Typography color={refund.status === 'OVERDUE' ? 'red' : undefined}>
                    {formatTime(refund.date)}
                  </Typography>
                </Typography>
              </CardContent>
            </Card>
          )
        })}
    </>
  )
}

export default ActivityRefundCards
