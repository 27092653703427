import {
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Typography,
} from '@mui/material';
import { MouseEvent, useState } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const ReferenceMenu = () => {
	const [openReference, setOpenReference] = useState(false);
	const [anchorReference, setAnchorReference] = useState<null | HTMLElement>(
		null
	);

	const handleReferenceButton = (event: MouseEvent<HTMLButtonElement>) => {
		setAnchorReference(event.currentTarget);
		setOpenReference(true);
	};

	const handleCloseReference = () => {
		setAnchorReference(null);
		setOpenReference(false);
	};
	return (
		<>
			<IconButton aria-label="reference" onClick={handleReferenceButton}>
				<HelpOutlineIcon />
			</IconButton>

			<Menu
				open={openReference}
				anchorEl={anchorReference}
				onClose={handleCloseReference}
				MenuListProps={{ disableListWrap: true }}
			>
				<MenuItem>
					<Typography>References</Typography>
				</MenuItem>
				<MenuItem>
					<ListItemIcon>
						<FiberManualRecordIcon
							sx={{
								'&.MuiSvgIcon-colorSuccess': {
									color: 'var(--success-color)',
									borderRadius: '50%',
									backgroundColor: 'var(--success-bg-color)',
								},
							}}
							color="success"
						/>
					</ListItemIcon>
					<ListItemText>Paid</ListItemText>
				</MenuItem>
				<MenuItem>
					<ListItemIcon>
						<FiberManualRecordIcon
							sx={{
								'&.MuiSvgIcon-colorWarning': {
									color: 'var(--warning-color)',
									borderRadius: '50%',
									backgroundColor: 'var(--warning-bg-color)',
								},
							}}
							color="warning"
						/>
					</ListItemIcon>
					<ListItemText>Partial</ListItemText>
				</MenuItem>
				<MenuItem>
					<ListItemIcon>
						<FiberManualRecordIcon
							sx={{
								'&.MuiSvgIcon-colorError': {
									color: 'var(--error-color)',
									borderRadius: '50%',
									backgroundColor: 'var(--error-bg-color)',
								},
							}}
							color="error"
						/>
					</ListItemIcon>
					<ListItemText>Overdue</ListItemText>
				</MenuItem>
				<MenuItem>
					<ListItemIcon>
						<FiberManualRecordIcon
							sx={{
								'&.MuiSvgIcon-colorInfo': {
									color: 'var(--info-color)',
									borderRadius: '50%',
									backgroundColor: 'var(--info-bg-color)',
								},
							}}
							color="info"
						/>
					</ListItemIcon>
					<ListItemText>Sent</ListItemText>
				</MenuItem>
				<MenuItem>
					<ListItemIcon>
						<FiberManualRecordIcon
							sx={{
								'&.MuiSvgIcon-colorDisabled': {
									color: 'var(--secondary-grey)',
									borderRadius: '50%',
									backgroundColor: 'var(--secondary-grey-bg)',
								},
							}}
							color="disabled"
						/>
					</ListItemIcon>
					<ListItemText>Draft</ListItemText>
				</MenuItem>
				<MenuItem>
					<ListItemIcon>
						<FiberManualRecordIcon
							sx={{
								'&.MuiSvgIcon-colorSecondary': {
									color: 'var(--orange-color)',
									borderRadius: '50%',
									backgroundColor: 'var(--orange-bg-color)',
								},
							}}
							color="secondary"
						/>
					</ListItemIcon>
					<ListItemText>Refund</ListItemText>
				</MenuItem>
			</Menu>
		</>
	);
};

export default ReferenceMenu;
