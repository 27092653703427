import { Dispatch } from 'redux'
import { Action } from '../actions/actionTypes'
import api from '../../api/api'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  SAVE_PRODUCTS,
  CREATE_PRODUCT,
  SAVE_CUSTOMERS,
  CREATE_CUSTOMER,
  SAVE_CERTAIN_CUSTOMER,
  SAVE_ESTIMATES,
  CREATE_ESTIMATE,
  SAVE_CERTAIN_ESTIMATE,
  CREATE_INVOICE,
  SAVE_INVOICES,
  SAVE_CERTAIN_INVOICE,
  SAVE_COMPANY,
  SAVE_PAYMENTS,
  SAVE_OUTSTANDING_DATA,
  SAVE_OUTSTANDING_STATEMENTS,
  SAVE_ACTIVITY_STATEMENTS,
  CREATE_SCHEDULED_TEMPLATE,
  SAVE_SCHEDULED_TEMPLATES,
  SAVE_CERTAIN_TEMPLATE,
  CHANGE_IS_LOADING,
  PAY_NOW_UNAVAILABLE,
  SAVE_INVOICES_STATISTICS,
  HAVE_SUBSCRIPTION,
  SUBSCRIPTION_DATA,
  SAVE_FAQ,
  REFERRALS_DATA,
  SAVE_FINANCIAL_ACCOUNT,
  SAVE_FINANCIAL_ACCOUNT_TYPES,
  SAVE_ALL_FINANCIAL_ACCOUNT_TYPES,
  SAVE_VENDORS,
  SAVE_PURCHASE_PRODUCTS,
  CREATE_PURCHASE_PRODUCT,
  SAVE_EXPENSES_ACCOUNTS,
  SAVE_REVENUE_ACCOUNTS,
  SAVE_BILLS,
  SAVE_CERTAIN_BILL,
  SAVE_JOURNAL_ENTRIES,
  SAVE_CERTAIN_JOURNAL,
} from './home.actions.type'
import { IS_COMPANY_LOADED } from './auth.actions.type'
import { ICustomer, IEstimate, IProduct, IPurchaseProduct } from '../../types/types'
import { errorHandler } from '../../helpers/errorHandler'
import { successToastNotification } from '../../helpers/toastNotification'

export const changeIsLoading = (state: boolean) => ({
  type: CHANGE_IS_LOADING,
  payload: state,
})

export const saveOutstandingStatements =
  (customerPublicId: string | undefined) => (dispatch: any) => {
    try {
      api.getOutstandingStatements(customerPublicId).then((res) => {
        dispatch(saveCertainCustomer(customerPublicId))
        dispatch({
          type: SAVE_OUTSTANDING_STATEMENTS,
          payload: res.data.body,
        })
      })
    } catch (err) {
      errorHandler(err)
    }
  }

export const saveActivityStatements =
  (customerPublicId: string | undefined, fromDate: string, toDate: string) => (dispatch: any) => {
    try {
      api.getActivityStatements(customerPublicId, fromDate, toDate).then((res) => {
        dispatch(saveCertainCustomer(customerPublicId))
        dispatch({
          type: SAVE_ACTIVITY_STATEMENTS,
          payload: res.data.body,
        })
      })
    } catch (err) {
      errorHandler(err)
    }
  }

export const saveCertainCustomer =
  (publicId: string | undefined) => (dispatch: Dispatch<Action>) => {
    api
      .getCertainCustomer(publicId)
      .then((res) => {
        dispatch({
          type: SAVE_CERTAIN_CUSTOMER,
          payload: res.data.body,
        })
      })
      .catch((err) => errorHandler(err))
  }

export const saveCertainEstimate = (publicId: string | undefined) => (dispatch: any) => {
  api
    .getCertainEstimate(publicId)
    .then((res) => {
      dispatch(saveCertainCustomer(res.data.body.customerPublicId))
      dispatch({
        type: SAVE_CERTAIN_ESTIMATE,
        payload: res.data.body,
      })
    })
    .catch((err) => errorHandler(err))
}

export const saveCertainInvoice = (publicId: string | undefined) => (dispatch: any) => {
  api
    .getCertainInvoice(publicId)
    .then((res) => {
      dispatch(saveCertainCustomer(res.data.body.customerPublicId))
      dispatch({
        type: SAVE_CERTAIN_INVOICE,
        payload: res.data.body,
      })
    })
    .catch((err) => errorHandler(err))
}

export const saveProducts =
  (page = 0) =>
  (dispatch: Dispatch<Action>) => {
    api
      .getProducts(page)
      .then((res) => {
        dispatch({
          type: SAVE_PRODUCTS,
          payload: res.data.body.content,
          size: res.data.body.totalElements,
        })
      })
      .catch((err) => errorHandler(err))
  }

export const createNewProduct =
  (product: IProduct, navigate?: any) => (dispatch: Dispatch<Action>) => {
    api
      .createProduct(product)
      .then((res: any) => {
        dispatch({
          type: CREATE_PRODUCT,
          payload: res.data.body,
        })
        toast.success(`${product.name} was successfully created`, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        })
        if (navigate) {
          navigate('/products')
        }
      })
      .catch((err: any) => errorHandler(err))
  }

export const createNewPurchaseProduct =
  (product: IPurchaseProduct) => (dispatch: Dispatch<Action>) => {
    api
      .createPurchaseProduct(product)
      .then((res: any) => {
        dispatch({
          type: CREATE_PURCHASE_PRODUCT,
          payload: res.data.body,
        })

        successToastNotification(`${product.name} was successfully created`)
      })
      .catch((err: any) => errorHandler(err))
  }

export const saveCustomers =
  (page = 0) =>
  (dispatch: Dispatch<Action>) => {
    api
      .getCustomers(page)
      .then((res) => {
        dispatch({
          type: SAVE_CUSTOMERS,
          payload: res.data.body.content,
          size: res.data.body.totalElements,
        })
      })
      .catch((err) => errorHandler(err))
  }

export const createNewCustomer =
  (customer: ICustomer, navigate?: any) => async (dispatch: Dispatch) => {
    try {
      const res = await api.createCustomer(customer)
      dispatch({
        type: CREATE_CUSTOMER,
        payload: res.data.body,
      })

      toast.success(`${customer.name} was successfully created`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      })

      if (navigate) {
        navigate('/customers')
      }
    } catch (err) {
      errorHandler(err)
    }
  }

export const saveEstimates =
  (page = 0, statuses = '') =>
  (dispatch: Dispatch<Action>) => {
    api
      .getEstimates(page, statuses)
      .then((res) => {
        dispatch({
          type: SAVE_ESTIMATES,
          payload: res.data.body.content,
          size: res.data.body.totalElements,
        })
      })
      .catch((err) => errorHandler(err))
  }

export const saveInvoicesStatistics = () => (dispatch: Dispatch<Action>) => {
  api
    .getInvoicesStatistics()
    .then((res) => {
      dispatch({
        type: SAVE_INVOICES_STATISTICS,
        payload: res.data.body,
      })
    })
    .catch((err: any) => errorHandler(err))
}

export const createNewEstimate = (estimate: IEstimate, navigate?: any) => (dispatch: Dispatch) => {
  api
    .createEstimate(estimate)
    .then(() => {
      dispatch({
        type: CREATE_ESTIMATE,
        payload: estimate,
      })
      toast.success(`Estimate was successfully created`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      })
      if (navigate) {
        navigate('/estimates')
      }
    })
    .catch((err: any) => errorHandler(err))
}

export const createNewInvoice = (invoice: any, navigate?: any) => (dispatch: Dispatch) => {
  api
    .createInvoice(invoice)
    .then(() => {
      dispatch({
        type: CREATE_INVOICE,
        payload: invoice,
      })
      toast.success(`Invoice was successfully created`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      })
      if (navigate) {
        navigate('/invoices')
      }
    })
    .catch((err: any) => errorHandler(err))
}

export const createNewScheduledInvoice = (template: any, navigate: any) => (dispatch: Dispatch) => {
  api
    .createScheduledInvoiceTemplate(template)
    .then((res) => {
      dispatch({
        type: CREATE_SCHEDULED_TEMPLATE,
        payload: res.data.body,
      })
      toast.success(`Recurring invoice was successfully created`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      })
      if (navigate) {
        navigate(`/recurring-invoices`, {
          state: {
            recurringInvoice: true,
          },
        })
      }
    })
    .catch((err) => errorHandler(err))
}

export const saveScheduledInvoiceTemplates =
  (page = 0, statuses = '') =>
  (dispatch: Dispatch<Action>) => {
    api
      .getScheduledInvoiceTemplate(page, statuses)
      .then((res) => {
        dispatch({
          type: SAVE_SCHEDULED_TEMPLATES,
          payload: res.data.body.content,
          size: res.data.body.totalElements,
        })
      })
      .catch((err) => errorHandler(err))
  }

export const saveCertainSheduledInvoice = (publicId: string | undefined) => (dispatch: any) => {
  api
    .getCertainScheduledInvoiceTemplate(publicId)
    .then((res) => {
      dispatch(saveCertainCustomer(res.data.body.customerPublicId))
      dispatch({
        type: SAVE_CERTAIN_TEMPLATE,
        payload: res.data.body,
      })
    })
    .catch((err) => errorHandler(err))
}

export const saveInvoices =
  (
    page = 0,
    statuses = '',
    invoiceName = '',
    customerName = '',
    fromDate = '',
    toDate = '',
    fromAmount = '',
    toAmount = '',
    customerId = '',
  ) =>
  (dispatch: Dispatch<Action>) => {
    api
      .getInvoices(
        page,
        statuses,
        invoiceName,
        customerName,
        fromDate,
        toDate,
        fromAmount,
        toAmount,
        customerId,
      )
      .then((res) => {
        dispatch({
          type: SAVE_INVOICES,
          payload: res.data.body.content,
          size: res.data.body.totalElements,
        })
      })
      .catch((err) => errorHandler(err))
  }

export const getCompany = () => (dispatch: Dispatch<Action>) => {
  return api
    .getCompany()
    .then((res) => {
      dispatch({
        type: SAVE_COMPANY,
        payload: res.data.body,
      })
    })
    .catch((err) => console.log(err))
    .finally(() => {
      dispatch({
        type: IS_COMPANY_LOADED,
        payload: true,
      })
    })
}

export const saveAccountSubscription = () => (dispatch: Dispatch<Action>) => {
  return api
    .getAccountSubscription()
    .then((res) => {
      dispatch({
        type: HAVE_SUBSCRIPTION,
        payload: res.data.body.length > 0,
      })
    })
    .catch((err) => console.log(err))
}

export const getAccountSubscription = () => (dispatch: Dispatch<Action>) => {
  return api
    .getAccountSubscription()
    .then((res) => {
      dispatch({
        type: SUBSCRIPTION_DATA,
        payload: res.data.body,
      })
    })
    .catch((err) => console.log(err))
}

export const saveFinancialAccount =
  (page = 0, size = 999, raw = '', isActive?: boolean, typePublicId?: string) =>
  (dispatch: Dispatch<Action>) => {
    return api
      .getFinancialAccount(page, size, raw, isActive, typePublicId)
      .then((res) => {
        dispatch({
          type: SAVE_FINANCIAL_ACCOUNT,
          payload: res.data.body,
        })
      })
      .catch((err) => console.log(err))
  }

export const saveFinancialAccountTypes =
  (page = 0, size = '', category = '') =>
  (dispatch: Dispatch<Action>) => {
    return api
      .getFinancialAccountTypes(page, size, category)
      .then((res) => {
        dispatch({
          type: SAVE_FINANCIAL_ACCOUNT_TYPES,
          payload: res.data.body,
        })
      })
      .catch((err) => console.log(err))
  }

  export const saveAllFinancialAccountTypes =
  (page = 0, size = '', category = '') =>
  (dispatch: Dispatch<Action>) => {
    return api
      .getFinancialAccountTypes(page, size, category)
      .then((res) => {
        dispatch({
          type: SAVE_ALL_FINANCIAL_ACCOUNT_TYPES,
          payload: res.data.body,
        })
      })
      .catch((err) => console.log(err))
  }

export const saveVendors = (page = 0, size = 15, search = '', type = '') => (dispatch: Dispatch<Action>) => {
  return api
    .getVendors(page, size, search, type)
    .then((res) => {
      dispatch({
        type: SAVE_VENDORS,
        payload: res.data.body.content,
      })
    })
    .catch((err) => console.log(err))
}

export const saveFaq =
  (page = 0, size = '', showFaqs = 'true') =>
  (dispatch: Dispatch<Action>) => {
    return api
      .getFaq(page, size, showFaqs)
      .then((res) => {
        dispatch({
          type: SAVE_FAQ,
          payload: res.data.body,
        })
      })
      .catch((err) => console.log(err))
  }

export const saveReferrals =
  (page = 0, size = 10) =>
  (dispatch: Dispatch<Action>) => {
    return api
      .getRefferrals(page, size)
      .then((res) => {
        dispatch({
          type: REFERRALS_DATA,
          payload: res.data.body,
        })
      })
      .catch((err) => console.log(err))
  }

export const savePayments =
  (
    page = 0,
    customerId = '',
    statuses = '',
    methods = '',
    invoiceName = '',
    fromDate = '',
    toDate = '',
    creditCardType = '',
    search = '',
  ) =>
  (dispatch: Dispatch<Action>) => {
    api
      .getPayments(
        page,
        customerId,
        statuses,
        methods,
        invoiceName,
        fromDate,
        toDate,
        creditCardType,
        search,
      )
      .then((res) => {
        dispatch({
          type: SAVE_PAYMENTS,
          payload: res.data.body.content,
          size: res.data.body.totalElements,
        })
      })
      .catch((err) => errorHandler(err))
  }

export const saveOutstandingData = (customerId: string) => (dispatch: Dispatch<Action>) => {
  api
    .getOutstandingInvoices(customerId)
    .then((res) => {
      dispatch({
        type: SAVE_OUTSTANDING_DATA,
        payload: res.data.body,
      })
    })
    .catch((err) => errorHandler(err))
}

const getCustomerByPublicID = (customerId: string | undefined) => (dispatch: any) => {
  api
    .getCustomerByPublicId(customerId)
    .then((res) => {
      dispatch({
        type: SAVE_CERTAIN_CUSTOMER,
        payload: res.data.body,
      })
    })
    .catch((err) => errorHandler(err))
}

export const getInvoiceByPublicId = (invoiceId: string | undefined) => (dispatch: any) => {
  api
    .getInvoiceByPublicId(invoiceId)
    .then((res) => {
      dispatch(getCustomerByPublicID(res.data.body.customerPublicId))
      dispatch({
        type: SAVE_CERTAIN_INVOICE,
        payload: res.data.body,
      })
    })
    .catch((err) => {
      if (err.response.data.errors[0].code.includes('STATUS_IS_NOT_ALLOWED')) {
        dispatch({
          type: PAY_NOW_UNAVAILABLE,
          payload: true,
        })
      } else {
        errorHandler(err)
      }
    })
}

export const savePurchaseProducts =
  (page = 0) =>
  (dispatch: Dispatch<Action>) => {
    api
      .getPurchaseProducts(page)
      .then((res) => {
        dispatch({
          type: SAVE_PURCHASE_PRODUCTS,
          payload: res.data.body.content,
          size: res.data.body.totalElements,
        })
      })
      .catch((err) => errorHandler(err))
  }

export const saveExpensesAccounts =
  (page = 0, size = 999, raw = '', isActive?: boolean) =>
  (dispatch: Dispatch<Action>) => {
    return api
      .getFinancialAccount(page, size, raw, isActive, undefined, 'EXPENSES')
      .then((res) => {
        dispatch({
          type: SAVE_EXPENSES_ACCOUNTS,
          payload: res.data.body.content,
        })
      })
      .catch((err) => console.log(err))
  }

export const saveRevenueAccounts =
  (page = 0, size = 999, raw = '', isActive?: boolean) =>
  (dispatch: Dispatch<Action>) => {
    return api
      .getFinancialAccount(page, size, raw, isActive, undefined, 'REVENUE')
      .then((res) => {
        dispatch({
          type: SAVE_REVENUE_ACCOUNTS,
          payload: res.data.body.content,
        })
      })
      .catch((err) => console.log(err))
  }

  export const saveBills =
  (
    page = 0,
    size = 15,
    status = '',
    fromDate = '',
    toDate = '',
    vendorId = '',
  ) =>
  (dispatch: Dispatch<Action>) => {
    api
      .getBills(
        page,
        size,
        status,
        fromDate,
        toDate,
        vendorId,
      )
      .then((res) => {
        dispatch({
          type: SAVE_BILLS,
          payload: res.data.body.content,
          size: res.data.body.totalElements,
        })
      })
      .catch((err) => errorHandler(err))
  }

  export const saveCertainBill = (publicId: string | undefined) => (dispatch: any) => {
    api
      .getCertainBill(publicId)
      .then((res) => {
        dispatch({
          type: SAVE_CERTAIN_BILL,
          payload: res.data.body,
        })
      })
      .catch((err) => errorHandler(err))
  }

  export const saveJournalEntries =
  (
    page = 0,
    size = 15,
    account = '',
    status = ''
  ) =>
  (dispatch: Dispatch<Action>) => {
    api
      .getJournalEntries(
        page,
        size,
        account,
        status
      )
      .then((res) => {
        dispatch({
          type: SAVE_JOURNAL_ENTRIES,
          payload: res.data.body.content,
          size: res.data.body.totalElements,
        })
      })
      .catch((err) => errorHandler(err))
  }

  export const saveCertainJournal = (publicId: string | undefined) => (dispatch: any) => {
    api
      .getCertainJournal(publicId)
      .then((res) => {
        dispatch({
          type: SAVE_CERTAIN_JOURNAL,
          payload: res.data.body,
        })
      })
      .catch((err) => errorHandler(err))
  }
