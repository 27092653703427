import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const errorHandler = (err: any) => {
	toast(err.response.data.errors[0].description, {
		autoClose: 3000,
		hideProgressBar: true,
		className: 'error-toast',
	});
};

export const generalErrorHandler = (errorMessage: string) => {
	toast(errorMessage, {
		autoClose: 3000,
		hideProgressBar: true,
		className: 'error-toast',
	});
};
