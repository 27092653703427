import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRowSpacingParams,
  GridTreeNode,
} from '@mui/x-data-grid'
import { IVendor } from '../../types/types'
import { useCallback, useMemo, useState } from 'react'
import TableStyles from '../../components/table-card/table-styles'
import '../../components/table-card/table-card.css'
import { Box, IconButton, Menu, MenuItem, Typography, Button, Tooltip } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { isUsingMobile } from '../../helpers/utils'
import { formatMoney, formatPhoneNumber } from '../../helpers/formatPrice'
import useAuthorization from '../../helpers/useAuthorization'

interface VendorsTableProps {
  vendorsInTable: IVendor[]
  onRowClick: (vendor: IVendor) => void
  setSelectedVendor: (value: IVendor) => void
  onDeleteClick: () => void
}

const VendorsTable = ({
  vendorsInTable,
  onRowClick,
  setSelectedVendor,
  onDeleteClick,
}: VendorsTableProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { isAuthorized } = useAuthorization()

  const openItemMenu = Boolean(anchorEl)
  const isMobile = isUsingMobile()

  const handleMenuButtonClick = useCallback(
    (vendor: IVendor) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      setSelectedVendor(vendor)
      setAnchorEl(event.currentTarget)
    },
    [setSelectedVendor],
  )

  const handleDeleteClick = () => {
    setAnchorEl(null)
    onDeleteClick()
  }

  const isvendorsInTableActive = (vendorsInTable: any) => {
    return (
      vendorsInTable &&
      vendorsInTable.length > 0 &&
      vendorsInTable.active !== undefined &&
      vendorsInTable.active
    )
  }

  const isActive = isvendorsInTableActive(vendorsInTable)

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const columns = useMemo<GridColDef<IVendor>[]>(
    () => [
      {
        field: 'business',
        headerName: 'Business name',
        flex: 3,
        sortable: false,
        renderCell: (param) => {
          return (
            <Box>
              <Typography>{param.row.name}</Typography>
            </Box>
          )
        },
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 4,
        sortable: false,
        renderCell: (param) => {
          return (
            <Box>
              <Typography>{`${param.row.firstName} ${param.row.lastName}`}</Typography>
              <Typography fontSize={14}>{`${param.row.emailAddress}`}</Typography>
            </Box>
          )
        },
      },
      {
        field: 'phone',
        headerName: 'Phone number',
        flex: 2,
        sortable: false,
        renderCell: (param) => {
          return (
            <Box>
              <Typography>{formatPhoneNumber(param.row.phone)}</Typography>
            </Box>
          )
        },
      },
      {
        field: 'serviceType',
        headerName: 'Service Type',
        flex: 4,
        sortable: false,
        renderCell: (param) => {
          return (
            <Box width={250}>
              <Tooltip title={param.row.mccCode?.name}>
                <Typography fontSize={12} noWrap={true}>
                  {param.row.mccCode?.name}
                </Typography>
              </Tooltip>
            </Box>
          )
        },
      },
      {
        field: 'amount',
        headerName: 'Amount',
        flex: 2,
        sortable: false,
        renderCell: (param) => {
          return (
            <Box>
              <Typography>{formatMoney(0)}</Typography>
            </Box>
          )
        },
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 2,
        sortable: false,
        renderCell: () => {
          return (
            <Box>
              <Button variant='outlined'>Create a Bill</Button>
            </Box>
          )
        },
      },
      {
        field: 'moreActions',
        headerName: ' ',
        flex: 1,
        sortable: false,
        renderCell: (param) => (
          <IconButton
            aria-label='more'
            id='long-button'
            aria-controls={openItemMenu ? 'long-menu' : undefined}
            aria-expanded={openItemMenu ? 'true' : undefined}
            aria-haspopup='true'
            onClick={handleMenuButtonClick(param.row)}
            sx={{
              justifyItems: 'center',
            }}
          >
            <MoreVertIcon />
          </IconButton>
        ),
      },
    ],
    [handleMenuButtonClick, openItemMenu, isMobile, isActive],
  )

  const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: 5,
    }
  }, [])

  return (
    <>
      <DataGrid
        rows={vendorsInTable}
        columns={columns}
        disableColumnMenu
        getRowId={(vendorsInTable) => vendorsInTable.publicId || ''}
        onRowClick={(params) => onRowClick(params.row)}
        hideFooter
        getRowSpacing={getRowSpacing}
        rowHeight={65}
        initialState={{
          columns: {
            columnVisibilityModel: {
              actions: !isMobile,
              serviceType: !isMobile,
              phone: !isMobile,
              name: !isMobile,
              moreActions: isAuthorized('write_vendor'),
            },
          },
        }}
        getCellClassName={(params: GridCellParams<any, IVendor, IVendor, GridTreeNode>) => {
          return params.field !== 'actions' ? 'rowStyle' : ''
        }}
        sx={TableStyles}
      />
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={openItemMenu}
        onClose={handleCloseMenu}
        sx={{
          '& .MuiMenuItem-root': {
            padding: { xs: '1rem', md: '1rem 4rem 1rem 2rem' },
          },
          '& .MuiMenuItem-root:hover': {
            backgroundColor: 'var(--secondary1-color)',
            color: 'var(--primary1-color)',
          },
        }}
      >
        <MenuItem sx={{ color: 'var(--error-color)' }} onClick={handleDeleteClick}>
          Delete
        </MenuItem>
      </Menu>
    </>
  )
}

export default VendorsTable
