import { Box, Button, IconButton, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { formatDate } from '../../../helpers/formatPrice'
import ReportFiltersModal from '../../../components/report-filters/modal'

interface CustomersRevenueFiltersMenuProps {
  onUpdateClick: () => void
  setFilterPeriod: (filterPeriod: any) => void
  filterPeriod: any
}

const CustomersRevenueFiltersMenu = ({
  onUpdateClick,
  setFilterPeriod,
  filterPeriod,
}: CustomersRevenueFiltersMenuProps) => {
  const [isModalOpen, setIsFiltersModalOpen] = useState<boolean>(false)

  return (
    <>
      <Stack direction='row' spacing={2}>
        <Box flexGrow={2} alignSelf='center' display='flex' justifyContent={'end'}>
          {filterPeriod.from && filterPeriod.to && (
            <Typography sx={{ alignSelf: 'center', fontSize: 12, color: 'var(--gray3-color)' }}>
              {formatDate(filterPeriod.from)} - {formatDate(filterPeriod.to)}
            </Typography>
          )}
          <IconButton
            aria-label='download'
            onClick={() => setIsFiltersModalOpen(true)}
            color='primary'
            size='large'
            sx={{ display: 'flex' }}
          >
            <FilterAltOutlinedIcon />
          </IconButton>
        </Box>
        <Button size='large' variant='contained' onClick={onUpdateClick}>
          Update report
        </Button>
      </Stack>
      <ReportFiltersModal
        isModalOpen={isModalOpen}
        onClose={() => setIsFiltersModalOpen(false)}
        onConfirm={(from, to) => {
          setFilterPeriod(() => {
            return { from, to }
          })
        }}
      />
    </>
  )
}

export default CustomersRevenueFiltersMenu
